import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import sha256 from 'crypto-js/sha256';
import hex from 'crypto-js/enc-hex';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import {
  IForgotPasswordRequest,
  ILoginRequest,
  ILoginResponse,
  IResetPasswordRequest,
  ISocialLoginRequest,
} from '../interfaces/auth';

import { HttpService } from './http.service';
import { UserService } from './user.service';
import { NotificationService } from './notification.service';
import { ClevertapService } from './clevertap.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public isAuthorized = new BehaviorSubject(false);
  public $loggedIn = new BehaviorSubject(false);

  public verified: boolean = false;

  public id: string;
  public permissions = [];

  constructor(
    private httpService: HttpService,
    private route: ActivatedRoute,
    private userService: UserService,
    private cookieService: CookieService,
    private notificationService: NotificationService,
    private clevertapService: ClevertapService,
    public router: Router,
  ) {
  }

  public validate(
    token: string = localStorage.getItem('token'),
    options: { refreshToken?: string; user_id?: string } = {},
  ) {
    if (token != undefined && token != 'undefined') {
      let redirectLink = localStorage.getItem('redirect_to');
      let fragment = localStorage.getItem('fragment');
      let query = localStorage.getItem('queryParams');

      if (!token) {
        this.clear();
        if (redirectLink) {
          localStorage.setItem('redirect_to', redirectLink);
          localStorage.setItem('fragment', fragment);
          localStorage.setItem('queryParams', query);
        }
        return false;
      }

      const { exp, scopes } = this.decode(token);
      const currentTime = Date.now() / 1000;

      if (exp < currentTime) {
      }

      if (options?.user_id) {
        localStorage.setItem('id', options.user_id);
        this.id = options.user_id;
      } else {
        this.id = localStorage.getItem('id');
      }

      this.permissions = scopes;

      this.httpService.ACCESS_TOKEN = token;

      localStorage.setItem('token', token);

      if (options.refreshToken) {
        localStorage.setItem('refresh_token', options.refreshToken);
      }

      this.$loggedIn.next(true);

      return true;
    }
  }

  public login(data: ILoginRequest) {
    return this.httpService
      .post(`/oauth/token`, data)
      .pipe(map(this._handleSuccessfulLogin.bind(this)));
  }

  public signup(data: any) {
    return this.httpService.post(`/register`, data);
  }

  public deleteAccount(reason: string) {
    return this.httpService.delete(`/users/me`, {
      deactivation_reason: reason,
    });
  }

  public socialLogin(data: ISocialLoginRequest) {
    let formData: any = {
      ...data,
      clevertap_identity: this.clevertapService.getNewClevertap_identity(),
    };
    this.clevertapService.createUserProfile(formData, '');
    return this.httpService
      .post(`/register/social`, formData)
      .pipe(map(this._handleSuccessfulLogin.bind(this)));
  }

  public forgotPassword(data: IForgotPasswordRequest) {
    return this.httpService.post(`/forgotten-password`, data);
  }

  public resetPassword(data: IResetPasswordRequest) {
    return this.httpService.post(`/reset-password`, data);
  }

  public refreshAccessToken() {
    return this.httpService.post(`/oauth/token`, {
      grant_type: 'refresh_token',
      // client_id: environment.api.client_id,
      refresh_token: localStorage.getItem('refresh_token'),
      scopes: '*',
    });
  }

  public clear() {
    //let clevertap_anony_id = localStorage.getItem("your_temp_id");
    let my_id = localStorage.getItem('id');

    this.httpService
      .post(`/user-logout`, { user_id: my_id })
      .subscribe((res) => {
      });

    let selectedLang = localStorage.getItem('lang');
    let uuid = localStorage.getItem('uuid');
    let isWebview = this.clevertapService.isWebView;
    localStorage.clear();

    this.cookieService.deleteAll('/auth');
    this.httpService.ACCESS_TOKEN = undefined;
    localStorage.setItem('lang', selectedLang);
    localStorage.setItem('uuid', uuid);
    this.isAuthorized.next(false);
    this.$loggedIn.next(false);
    if (isWebview) {
      localStorage.setItem('deviceIdentity', 'mobile_device');
    }
  }

  public logoutClear() {
    let my_id = localStorage.getItem('id');

    this.httpService
      .post(`/user-logout`, { user_id: my_id })
      .subscribe((res) => {
        let selectedLang = localStorage.getItem('lang');
        localStorage.clear();

        this.cookieService.deleteAll('/auth');
        this.httpService.ACCESS_TOKEN = undefined;
        localStorage.setItem('lang', selectedLang);
        this.isAuthorized.next(false);
        this.$loggedIn.next(false);
        this.router.navigate(['/'], {
          queryParams: this.route.snapshot.queryParams,
          queryParamsHandling: 'merge',
        });
        // location.reload();
      });
  }

  _handleSuccessfulLogin(response: ILoginResponse) {
    const validated = this.validate(response.access_token, {
      user_id: response.user_id.toString(),
      refreshToken: response.refresh_token,
    });

    if (!validated) {
      return throwError('Failed to set access token');
    }

    // generate hashed email for AppsFlyer
    var hash = sha256(response.email);
    let hashedEmail = hash.toString(hex);

    // Fire the AppsFlyer event
    (window as any).AF('pba', 'setCustomerUserId', hashedEmail);

    this.userService.getCleverTapAction();

    if ((window as any).nsWebViewBridge) {
      (window as any).nsWebViewBridge.emit('access', true);
      (window as any).nsWebViewBridge.on(
        'device',
        (payload: { os?: string; uuid?: string; token: string }) => {
          const data = {
            source: payload.os,
            locale:
              navigator.languages && navigator.languages.length
                ? navigator.languages[0]
                : navigator.language || 'en',
            timezone: moment().format('ZZ'),
            device_id: payload.uuid,
            device_token: payload.token,
          };

          this.userService.update(data).subscribe(
            () => {
            },
            (err) => {
            },
          );
        },
      );
    }

    if ((window as any)?.ReactNativeWebView) {
      (window as any).ReactNativeWebView.postMessage(
        JSON.stringify({ access: true }),
      );

      window.addEventListener(
        'message',
        (event) => {
          const deviceInfo = JSON.parse(event?.data);
          if (deviceInfo?.eventName === 'device') {
            const data = {
              source: deviceInfo?.data?.os,
              locale:
                navigator.languages && navigator.languages.length
                  ? navigator.languages[0]
                  : navigator.language || 'en',
              timezone: moment().format('ZZ'),
              device_id: deviceInfo?.data?.uuid,
              device_token: deviceInfo?.data?.token,
            };
            this.userService.update(data).subscribe(
              () => {
                this.httpService
                  .post(`/users/me/fcm-token`, { token: data?.device_token })
                  .subscribe((response) => {
                  });
              },
              (err) => {
              },
            );
          }
        },
        true,
      );
    }

    return {
      success: true,
      onboarding: response.has_onboarded,
      roleUser: response,
      user_id: response.user_id,
      existingUser: response.existingUser,
    };
  }

  private decode(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }

  public userEmail(data: any) {
    return this.httpService.post(`/user-email`, { token: data });
  }

  public inMaintenance() {
    if (environment.MAINTENANCE_MODE) {
      return true;
    } else {
      return false;
    }
  }

  public checkMailVerification(email) {
    return this.httpService.get(`/check-email-verified/${email}`);
  }

  public hsLogin(payload) {
    return this.httpService.post(`/hs-login`, payload).pipe(
      tap((response: ILoginResponse) => {
        this._handleSuccessfulLogin(response);
      }),
    );
  }

  public hsRegister(payload) {
    return this.httpService.post(`/hs-register`, payload);
  }
}
