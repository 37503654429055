export type TopRegion = {
  label: string,
  params: {
    city: string,
    country: string,
    full_address: string,
    latitude: number,
    longitude: number,
    state: string
  }
}

export const TopCountries = [
  {
    label: 'United Kingdom',
    params: {
      city: 'London',
      country: 'United Kingdom',
      full_address: 'London, UK',
      latitude: 51.5072178,
      longitude: -0.1275862,
      state: 'England'
    }
  },
  {
    label: 'Germany',
    params: {
      'city': '',
      'country': 'Germany',
      'full_address': 'Germany',
      'latitude': 51.165691,
      'longitude': 10.451526,
      'state': ''
    }
  },
  {
    label: 'China',
    params: {
      'availability': [
        'Anytime'
      ],
      'city': '',
      'country': 'China',
      'full_address': 'China',
      'latitude': 35.86166,
      'longitude': 104.195397,
      'noOfGuests': 0,
      'state': ''
    }
  },
  {
    label: 'France',
    params: {
      'city': '',
      'country': 'France',
      'full_address': 'France',
      'latitude': 46.227638,
      'longitude': 2.213749,
      'state': ''
    }
  },
  {
    'label': 'United Arab Emirates',
    'params': {
      'city': '',
      'country': 'United Arab Emirates',
      'full_address': 'United Arab Emirates',
      'latitude': 23.424076,
      'longitude': 53.847818,
      'state': ''
    }
  },
  {
    'label': 'United States',
    'params': {
      'city': '',
      'country': 'United States',
      'full_address': 'United States',
      'latitude': 37.09024,
      'longitude': -95.712891,
      'state': ''
    }
  },
  {
    'label': 'Italy',
    'params': {
      'city': '',
      'country': 'Italy',
      'full_address': 'Italy',
      'latitude': 41.87194,
      'longitude': 12.56738,
      'state': ''
    }
  },
  {
    'label': 'Netherlands',
    'params': {
      'city': '',
      'country': 'Netherlands',
      'full_address': 'Netherlands',
      'latitude': 52.132633,
      'longitude': 5.291265999999999,
      'state': ''
    }
  },
  {
    'label': 'Malaysia',
    'params': {
      'city': '',
      'country': 'Malaysia',
      'full_address': 'Malaysia',
      'latitude': 4.210484,
      'longitude': 101.975766,
      'state': ''
    }
  },
  {
    'label': 'Ireland',
    'params': {
      'city': '',
      'country': 'Ireland',
      'full_address': 'Ireland',
      'latitude': 53.41291,
      'longitude': -8.24389,
      'state': ''
    }
  },
  {
    'label': 'Switzerland',
    'params': {
      'city': '',
      'country': 'Switzerland',
      'full_address': 'Switzerland',
      'latitude': 46.818188,
      'longitude': 8.227511999999999,
      'state': ''
    }
  },
  {
    'label': 'Singapore',
    'params': {
      'city': '',
      'country': 'Singapore',
      'full_address': 'Singapore',
      'latitude': 1.352083,
      'longitude': 103.819836,
      'state': ''
    }
  },
  {
    'label': 'Turkey',
    'params': {
      'city': '',
      'country': 'Türkiye',
      'full_address': 'Türkiye',
      'latitude': 38.963745,
      'longitude': 35.243322,
      'state': ''
    }
  },
  {
    'label': 'Australia',
    'params': {
      'city': '',
      'country': 'Australia',
      'full_address': 'Australia',
      'latitude': -25.274398,
      'longitude': 133.775136,
      'state': ''
    }
  },
  {
    'label': 'Japan',
    'params': {
      'city': '',
      'country': 'Japan',
      'full_address': 'Japan',
      'latitude': 36.204824,
      'longitude': 138.252924,
      'state': ''
    }
  },
  {
    'label': 'Belgium',
    'params': {
      'city': '',
      'country': 'Belgium',
      'full_address': 'Belgium',
      'latitude': 50.503887,
      'longitude': 4.469936,
      'state': ''
    }
  },
  {
    'label': 'Qatar',
    'params': {
      'city': '',
      'country': 'Qatar',
      'full_address': 'Qatar',
      'latitude': 25.354826,
      'longitude': 51.183884,
      'state': ''
    }
  },
  {
    'label': 'Saudi Arabia',
    'params': {
      'city': '',
      'country': 'Saudi Arabia',
      'full_address': 'Saudi Arabia',
      'latitude': 23.885942,
      'longitude': 45.079162,
      'state': ''
    }
  },
  {
    'label': 'Chile',
    'params': {
      'city': '',
      'country': 'Chile',
      'full_address': 'Chile',
      'latitude': -35.675147,
      'longitude': -71.542969,
      'state': ''
    }
  },
  {
    'label': 'Brazil',
    'params': {
      'availability': [
        'Anytime'
      ],
      'city': '',
      'country': 'Brazil',
      'full_address': 'Brazil',
      'latitude': -14.235004,
      'longitude': -51.92528,
      'noOfGuests': 0,
      'state': ''
    }
  }
];

export const TopCities = [
  {
    'label': 'London',
    'params': {
      'city': 'London',
      'country': 'United Kingdom',
      'full_address': 'London, UK',
      'latitude': 51.5072178,
      'longitude': -0.1275862,
      'state': 'England'
    }
  },
  {
    'label': 'Manchester',
    'params': {
      'city': 'Manchester',
      'country': 'United Kingdom',
      'full_address': 'Manchester, UK',
      'latitude': 53.4807593,
      'longitude': -2.2426305,
      'state': 'England'
    }
  },
  {
    'label': 'Dubai',
    'params': {
      'city': 'Dubai',
      'country': 'United Arab Emirates',
      'full_address': 'Dubai - United Arab Emirates',
      'latitude': 25.2048493,
      'longitude': 55.2707828,
      'state': 'Dubai'
    }
  },
  {
    'label': 'Paris',
    'params': {
      'city': 'Paris',
      'country': 'France',
      'full_address': 'Paris, France',
      'latitude': 48.856614,
      'longitude': 2.3522219,
      'state': 'Île-de-France'
    }
  },
  {
    'label': 'Frankfurt',
    'params': {
      'city': 'Frankfurt',
      'country': 'Germany',
      'full_address': 'Frankfurt, Germany',
      'latitude': 50.1109221,
      'longitude': 8.6821267,
      'state': 'Hessen'
    }
  },
  {
    'label': 'Abu dhabi',
    'params': {
      'city': 'Abu Dhabi',
      'country': 'United Arab Emirates',
      'full_address': 'Abu Dhabi - United Arab Emirates',
      'latitude': 24.453884,
      'longitude': 54.3773438,
      'state': 'Abu Dhabi'
    }
  },
  {
    'label': 'Munich',
    'params': {
      'city': 'Munich',
      'country': 'Germany',
      'full_address': 'Munich, Germany',
      'latitude': 48.1351253,
      'longitude': 11.5819806,
      'state': 'Bavaria'
    }
  },
  {
    'label': 'Dublin',
    'params': {
      'city': 'Dublin',
      'country': 'Ireland',
      'full_address': 'Dublin, Ireland',
      'latitude': 53.3498053,
      'longitude': -6.2603097,
      'state': 'County Dublin'
    }
  },
  {
    'label': 'Berlin',
    'params': {
      'city': 'Berlin',
      'country': 'Germany',
      'full_address': 'Berlin, Germany',
      'latitude': 52.52000659999999,
      'longitude': 13.404954,
      'state': 'Berlin'
    }
  },
  {
    'label': 'Edinburgh',
    'params': {
      'city': 'Edinburgh',
      'country': 'United Kingdom',
      'full_address': 'Edinburgh, UK',
      'latitude': 55.953252,
      'longitude': -3.188267,
      'state': 'Scotland'
    }
  },
  {
    'label': 'Singapore',
    'params': {
      'city': '',
      'country': 'Singapore',
      'full_address': 'Singapore',
      'latitude': 1.352083,
      'longitude': 103.819836,
      'state': ''
    }
  },
  {
    'label': 'Guangzhou',
    'params': {
      'city': 'Guangzhou',
      'country': 'China',
      'full_address': 'Guangzhou, Guangdong Province, China',
      'latitude': 23.1290799,
      'longitude': 113.26436,
      'state': 'Guangdong Province'
    }
  },
  {
    'label': 'Wuhan',
    'params': {
      'city': 'Wuhan',
      'country': 'China',
      'full_address': 'Wuhan, Hubei, China',
      'latitude': 30.5927599,
      'longitude': 114.30525,
      'state': 'Hubei'
    }
  },
  {
    'label': 'Shanghai',
    'params': {
      'city': 'Shanghai',
      'country': 'China',
      'full_address': 'Shanghai, China',
      'latitude': 31.230416,
      'longitude': 121.473701,
      'state': 'Shanghai'
    }
  },
  {
    'label': 'Kuala Lumpur',
    'params': {
      'city': 'Kuala Lumpur',
      'country': 'Malaysia',
      'full_address': 'Kuala Lumpur, Federal Territory of Kuala Lumpur, Malaysia',
      'latitude': 3.1319197,
      'longitude': 101.6840589,
      'state': 'Federal Territory of Kuala Lumpur'
    }
  },
  {
    'label': 'Zürich',
    'params': {
      'city': 'Zürich',
      'country': 'Switzerland',
      'full_address': 'Zürich, Switzerland',
      'latitude': 47.3768866,
      'longitude': 8.541694,
      'state': 'Zurich'
    }
  },
  {
    'label': 'Birmingham',
    'params': {
      'city': 'Birmingham',
      'country': 'United Kingdom',
      'full_address': 'Birmingham, UK',
      'latitude': 52.48624299999999,
      'longitude': -1.890401,
      'state': 'England'
    }
  },
  {
    'label': 'Amsterdam',
    'params': {
      'city': 'Amsterdam',
      'country': 'Netherlands',
      'full_address': 'Amsterdam, Netherlands',
      'latitude': 52.3675734,
      'longitude': 4.9041389,
      'state': 'North Holland'
    }
  },
  {
    'label': 'Xi\'an',
    'params': {
      'city': 'Xi\'An',
      'country': 'China',
      'full_address': 'Xi\'An, Shaanxi, China',
      'latitude': 34.2658138,
      'longitude': 108.9540936,
      'state': 'Shaanxi'
    }
  },
  {
    'label': 'Doha',
    'params': {
      'city': 'Doha',
      'country': 'Qatar',
      'full_address': 'Doha, Qatar',
      'latitude': 25.2854473,
      'longitude': 51.53103979999999,
      'state': 'Doha Municipality'
    }
  }
];
