import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UpdateService } from 'src/app/core/services/update.service';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {
  dropdownStatus = false;
  selectedLanguage = 'en';
  selecetdLanguageFile = '/assets/flags/uk_flag.png';
  selectedLanguageName = 'English';
  url;

  @Input('showLanguageName') showLanguageName: boolean = true;

  constructor(
    private languageService: LanguageService,
    private translate: TranslateService,
    private updateService: UpdateService,
    public router: Router
  ) {
    this.url = router.url;
  }

  ngOnInit(): void {
    let loadLang = this.languageService.GetLanguage() + '';

    if (loadLang == 'null' || loadLang == '') {
      this.selecetdLanguageFile = '/assets/flags/uk_flag.png';
      this.translate.setDefaultLang('en');
      this.translate.use('en');
      this.selectedLanguageName = 'English';
    } else {
      this.loadSelectedLanguage(loadLang);

      this.translate.setDefaultLang(loadLang);
      this.translate.use(loadLang);
      this.selectedLanguage = loadLang;
    }

    this.languageService.languageUpdates.subscribe((lang) => {
      this.loadSelectedLanguage(lang);
      this.selectedLanguage = lang;
    });

    this.selectedLanguage = this.languageService.GetLanguage();
  }

  loadSelectedLanguage(loadLang) {
    switch (loadLang) {
      case 'en':
        this.selecetdLanguageFile = '/assets/flags/uk_flag.png';
        this.selectedLanguageName = 'English';
        break;
      case 'it':
        this.selecetdLanguageFile = '/assets/flags/itly_flag.jpg';
        this.selectedLanguageName = 'Italian';

        break;
      case 'es':
        this.selecetdLanguageFile = '/assets/flags/spain_flag.jpg';
        this.selectedLanguageName = 'Spanish';

        break;
      case 'pt':
        this.selecetdLanguageFile = '/assets/flags/portugal_flag.jpg';
        this.selectedLanguageName = 'Portugese';

        break;
      case 'tr':
        this.selecetdLanguageFile = '/assets/flags/turkey_flag.png';
        this.selectedLanguageName = 'Turkish';

        break;
      case 'ar':
        this.selecetdLanguageFile = '/assets/flags/saudia_flag.png';
        this.selectedLanguageName = 'Arabic';

        break;
      case 'zh':
        this.selecetdLanguageFile = '/assets/flags/china_flag.jpg';
        this.selectedLanguageName = 'Chinese';

        break;
      default:
        this.selecetdLanguageFile = '/assets/flags/uk_flag.png';
        this.selectedLanguageName = 'English';

        break;
    }
    // if (loadLang == "en")
    //   this.selecetdLanguageFile = "/assets/flags/uk_flag.png";
    // else if (loadLang == "it")
    //   this.selecetdLanguageFile = "/assets/flags/itly_flag.jpg";
    // else if (loadLang == "es")
    //   this.selecetdLanguageFile = "/assets/flags/spain_flag.jpg";
    // else if (loadLang == "pt")
    //   this.selecetdLanguageFile = "/assets/flags/portugal_flag.jpg";
    // else if (loadLang == "tr")
    //   this.selecetdLanguageFile = "/assets/flags/turkey_flag.png";
    // else if (loadLang == "ar")
    //   this.selecetdLanguageFile = "/assets/flags/saudia_flag.png";
    // else if (loadLang == "zh")
    //   this.selecetdLanguageFile = "/assets/flags/china_flag.jpg";
    // else
    //   this.selecetdLanguageFile = "/assets/flags/uk_flag.png";
  }

  activateDropdown() {
    // this.dropdownStatus = true;
  }

  deActivateDropdown() {
    this.dropdownStatus = false;
  }

  selectLanguage() {
    this.dropdownStatus = false;
    // $(".dropdown-content").hide();
  }

  public changeLanguage(lang, imageName) {
    this.selectedLanguage = lang;
    this.selecetdLanguageFile = imageName;
    this.languageService.SetLanguage(this.selectedLanguage);
    this.languageService.setDirection(this.selectedLanguage);
    this.useLanguage(this.selectedLanguage);

    this.updateService.checkForUpdate();
    this.dropdownStatus = false;
  }

  public useLanguage(language: string): void {
    this.translate.use(language);
  }
}
