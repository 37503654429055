<div id="add-home-space-main-head"></div>
<div class="explore-section-main-container">
  <app-explore-hero [region]="region"></app-explore-hero>
  <app-explore-navigation
    (loadMoreByCategory)="loadHomeByCategory($event)"
    (filter)="loadHomeByFilter($event)"
  ></app-explore-navigation>
  <div #cardList id="card-list-1">
    <app-explore-cards
      [hideLoadMore]="!showLoadMore"
      [homeList]="homeList"
      (loadMore)="loadMoreHomeList()"
      [loading]="loading"
    ></app-explore-cards>
  </div>
</div>

<div
  *ngIf="(videoBackdrop && !fromThirdPlace) || modalBackdrop"
  class="bix__modal-backdrop"
></div>

<div
  *ngIf="
    (premiumAccountPopup ||
      earnTokenPopup ||
      homeBenefitsPopup ||
      creditcardbookingPopup ||
      videoBackdrop ||
      newcardbookingPopup) &&
    isDesktopView
  "
  class="bix__modal-backdrop"
></div>

<app-bottom-sheet
  *ngIf="!isDesktopView"
  [closeHeader]="false"
  (closed)="closeVideoPopup()"
  #bottomSheet="bottomSheet"
  [defaultHeight]="'448px'"
  [defaultMaxHeight]="'80%'"
>
  <div bottomSheet-body *ngIf="bottomSheet._visible">
    <div class="add_home_sheet">
      <div class="header">
        <p>
          {{ "Why adding" | translate }}
          <span class="highlight">{{ "your home" | translate }}</span>
          {{
            "benefits
                    you?" | translate
          }}
        </p>
        <img src="../../../../../assets/images/knowledge_slide2.png" alt="" />
      </div>
      <p>
        {{
          "Adding your home listing to our network lets you swap homes with other members of our community, any
                where," | translate
        }}
        <span class="highlight">{{ " " + "for free" | translate }}</span
        >!
      </p>
      <p>
        {{ "On top of that, you will" | translate }}
        <span class="highlight">{{ " earn HS Points" | translate }}</span>
        {{
          "which can be used towards other rewards, and even to rent out properties, hassle free, from members
                as well!" | translate
        }}
      </p>
      <button class="btn-block" (click)="handleAddHome('addhome')">
        {{ "Add Your Home" | translate }}
      </button>
    </div>
  </div>
</app-bottom-sheet>

<div
  class="add-home-modal home-benefit-modal"
  *ngIf="homeBenefitsPopup && isDesktopView"
>
  <div class="close-section">
    <div class="skip-and-close">
      <span class="close-popup" (click)="closeBenefitsPopup()"
        ><img src="/assets/images/cancel_ic.svg"
      /></span>
    </div>
  </div>

  <div class="add_home_sheet">
    <div class="header">
      <p>
        {{ "Why adding" | translate }}
        <span class="highlight">{{ "your home" | translate }}</span>
        {{ "benefits you?" | translate }}
      </p>
      <img src="../../../../../assets/images/knowledge_slide2.png" alt="" />
    </div>
    <p>
      {{
        "Adding your home listing to our network lets you swap homes with other members of our community, any
            where," | translate
      }}
      <span class="highlight">{{ " " + "for free" | translate }}</span
      >!
    </p>
    <p>
      {{ "On top of that, you will" | translate }}
      <span class="highlight">{{ " earn HS Points" | translate }}</span>
      {{
        "which can be used towards other rewards, and even to rent out properties, hassle free, from members as
            well!" | translate
      }}
    </p>
  </div>

  <div class="add-home-btn-main">
    <div class="add-home-btn-head" (click)="handleAddHome('addhome')">
      <button type="button" class="add-home-btn-popup">
        {{ "Add Your Home" | translate }}
      </button>
    </div>
  </div>
</div>

<app-bottom-sheet
  *ngIf="!isDesktopView && setVideoWatched && !fromThirdPlace"
  [closeHeader]="false"
  (closed)="closeConfirmPopup()"
  #bottomSheetReward="bottomSheet"
  [defaultHeight]="'448px'"
  [defaultMaxHeight]="'80%'"
>
  <!-- <div bottomSheet-header>{{"Welcome Video" | translate}}</div> -->
  <div bottomSheet-body *ngIf="bottomSheetReward._visible">
    <div class="add_home_sheet">
      <div class="header">
        <p>
          {{ "Congrats! Here is a" | translate }}
          <span class="highlight">{{ "5 HS Point gift" | translate }}</span>
          {{ "for watching!" | translate }}
        </p>
        <img src="../../../../../assets/images/knowledge_slide2.png" alt="" />
      </div>
      <p>
        {{ "There are many ways to earn" | translate }}
        <span class="highlight">{{ "free HS Points" | translate }}</span>
        {{ "within the Holiday Swap App" | translate }}!
      </p>
      <p>
        {{ "Complete actions on your" | translate }}
        <span class="highlight">{{ "Progress Tracker" | translate }}</span>
        {{
          "to earn HS Points and book homes around the world for free!"
            | translate
        }}
      </p>
      <button class="btn-block" (click)="handleAddHome('earntoken')">
        {{ "Earn HS Points" | translate }}
      </button>
    </div>
  </div>
</app-bottom-sheet>

<div
  class="add-home-modal home-reward-modal"
  *ngIf="showRewardPopup && isDesktopView && !fromThirdPlace"
>
  <div class="close-section">
    <div class="skip-and-close">
      <span class="close-popup" (click)="closeConfirmPopup()">
        <img alt="" src="/assets/images/cancel_ic.svg"
      /></span>
    </div>
  </div>

  <div class="add_home_sheet">
    <div class="header">
      <div>
        <p>{{ "Congrats!" | translate }}</p>
        <p>
          {{ "Here is a" | translate }}
          <span class="highlight">{{ "5 HS Point gift" | translate }}</span>
          {{
            "for
                    watching!" | translate
          }}
        </p>
      </div>
      <img src="../../../../../assets/images/knowledge_slide2.png" alt="" />
    </div>
    <p class="mt-25 text-left">
      {{ "There are many ways to earn" | translate }}
      <span class="highlight">{{ "free HS Points" | translate }}</span>
      {{ "within the Holiday Swap App" | translate }}!
    </p>
    <p class="text-left">
      {{ "Complete actions on your" | translate }}
      <span class="highlight">{{ "Progress Tracker" | translate }}</span>
      {{
        "to earn HS Points and book homes around the world for free!"
          | translate
      }}
    </p>
  </div>

  <div class="add-home-btn-main">
    <div class="add-home-btn-head" (click)="handleAddHome('earntoken')">
      <button type="button" class="add-home-btn-popup earn-token">
        {{ "Earn HS Points" | translate }}
      </button>
    </div>
  </div>
</div>

<app-bottom-sheet
  *ngIf="!isDesktopView"
  [closeHeader]="false"
  (closed)="closeVideoPopup()"
  #earnTokenBottomSheet="bottomSheet"
  [defaultHeight]="'448px'"
  [defaultMaxHeight]="'80%'"
>
  <!-- <div bottomSheet-header>{{"Welcome Video" | translate}}</div> -->
  <div bottomSheet-body *ngIf="earnTokenBottomSheet._visible">
    <div class="add_home_sheet">
      <div class="header">
        <p>
          {{ "How does accumulating" | translate }}
          <span class="highlight">{{ "more HS points" | translate }}</span>
          {{ "benefit you?" | translate }}
        </p>
        <img src="../../../../../assets/images/knowledge_slide2.png" alt="" />
      </div>
      <p>
        {{ "Buy HS Points to book homes around the world" | translate }}
        <span class="highlight">{{ "for 90% cheaper" | translate }} </span
        >{{ "than hotels!" | translate }}
      </p>
      <p>
        {{ "Complete actions on your" | translate }}
        <span class="highlight"> {{ "Progress Tracker" | translate }}</span>
        {{
          "to earn HS points and book homes around the world for free!"
            | translate
        }}
      </p>
      <div class="btn_flex">
        <button
          class="btn-block btn-outline"
          (click)="handleAddHome('earntoken')"
        >
          {{ "Earn HS Points" | translate }}
        </button>
        <button class="btn-block" (click)="handleAddHome('buytokens')">
          {{ "Buy HS Points" | translate }}
        </button>
      </div>
    </div>
  </div>
</app-bottom-sheet>

<div
  class="add-home-modal home-benefit-modal"
  *ngIf="earnTokenPopup && isDesktopView"
>
  <div class="close-section">
    <div class="skip-and-close">
      <span class="close-popup" (click)="closeEarnTokenPopup()"
        ><img src="/assets/images/cancel_ic.svg"
      /></span>
    </div>
  </div>

  <div class="add_home_sheet">
    <div class="header">
      <p>
        {{ "How does accumulating" | translate }}
        <span class="highlight">{{ "more HS Points" | translate }}</span>
        {{ "benefit you?" | translate }}
      </p>
      <img src="../../../../../assets/images/knowledge_slide2.png" alt="" />
    </div>
    <p>
      {{ "Buy HS Points to book homes around the world" | translate }}
      <span class="highlight">{{ "for 90% cheaper" | translate }} </span
      >{{ "than hotels!" | translate }}
    </p>
    <p>
      {{ "Complete actions on your" | translate }}
      <span class="highlight"> {{ "Progress Tracker" | translate }}</span>
      {{
        "to earn HS Points and book homes around the world for free!"
          | translate
      }}
    </p>
  </div>

  <div class="add-home-btn-main">
    <div class="add-home-btn-head btn_flex">
      <button
        class="add-home-btn-popup btn-outline"
        (click)="handleAddHome('earntoken')"
      >
        {{ "Earn HS Points" | translate }}
      </button>
      <button class="add-home-btn-popup" (click)="handleAddHome('buytokens')">
        {{ "Buy HS Points" | translate }}
      </button>
    </div>
  </div>
</div>

<app-bottom-sheet
  *ngIf="!isDesktopView"
  [closeHeader]="false"
  (closed)="closeVideoPopup()"
  #premiumAccountBottomSheet="bottomSheet"
  [defaultHeight]="'448px'"
  [defaultMaxHeight]="'80%'"
>
  <!-- <div bottomSheet-header>{{"Welcome Video" | translate}}</div> -->
  <div bottomSheet-body *ngIf="premiumAccountBottomSheet._visible">
    <div class="add_home_sheet">
      <div class="header">
        <div>
          <p>{{ "Ready to unleash the" | translate }}</p>
          <span class="highlight">{{ "power of premium?" | translate }}</span>
        </div>
        <div>
          <img src="../../../../../assets/images/knowledge_slide2.png" alt="" />
        </div>
      </div>
      <p>
        {{ "Start a 14-day free trial of Holiday Swap premium" | translate
        }}<b>{{ "now" | translate }}</b> {{ "to swap homes" | translate }}
        <span class="highlight">{{ "for free" | translate }} </span
        >{{ "& avoid accompanying costs when travelling!" | translate }}
      </p>
      <p>
        {{ "Get unlimited" | translate }}<b> {{ "home swaps" | translate }} </b>
        {{ " on Holiday Swap and stay around the world" | translate }}
        <span class="highlight">
          {{ "for only $10 a month!" | translate }}</span
        >
        {{
          "Risk-free,
                cancel-any-time guarantee!" | translate
        }}
      </p>
      <button class="btn-block" (click)="handleAddHome('premiumaccount')">
        {{ "Go Premium" | translate }}
      </button>
    </div>
  </div>
</app-bottom-sheet>

<div
  class="add-home-modal home-benefit-modal"
  *ngIf="premiumAccountPopup && isDesktopView"
>
  <div class="close-section">
    <div class="skip-and-close">
      <span class="close-popup" (click)="closePremiumAccountPopup()"
        ><img src="/assets/images/cancel_ic.svg"
      /></span>
    </div>
  </div>

  <div class="add_home_sheet">
    <div class="header">
      <p>
        {{ "Ready to unleash the" | translate }}
        <span class="highlight">{{ "power of premium?" | translate }}</span>
      </p>
      <img src="../../../../../assets/images/knowledge_slide2.png" alt="" />
    </div>
    <p>
      {{ "Start a 14-day free trial of Holiday Swap premium" | translate }}
      <b>{{ "now" | translate }}</b> {{ "to swap homes" | translate }}
      <span class="highlight">{{ "for free" | translate }} </span
      >{{ "& avoid accompanying costs when travelling!" | translate }}
    </p>
    <p>
      {{ "Get unlimited" | translate }} <b> {{ "home swaps" | translate }}</b>
      {{ " on Holiday Swap and stay around the world" | translate }}
      <span class="highlight"> {{ "for only $10 a month!" | translate }}</span>
      {{
        "Risk-free,
            cancel-any-time guarantee!" | translate
      }}
    </p>
  </div>

  <div class="add-home-btn-main">
    <div class="add-home-btn-head" (click)="handleAddHome('premiumaccount')">
      <button
        type="button"
        class="add-home-btn-popup"
        [class.btn-pe-none]="block_btn"
      >
        {{ "Go Premium" | translate }}
      </button>
    </div>
  </div>
</div>

<!-- add home form popup -->

<div class="add-home-form-modal" *ngIf="openAddHomeForm">
  <div class="close-section">
    <div class="skip-and-close">
      <span class="close-popup" (click)="addSpaceModal.exitClick()">
        <img alt="close icon" src="/assets/images/cancel_ic.svg"
      /></span>
    </div>
  </div>

  <div class="add_home_sheet h-100">
    <app-add-home-modal
      #addSpaceModal
      (exitModal)="closeAddFormPopup()"
      [editData]="editHomeData"
    >
    </app-add-home-modal>
  </div>
</div>

<div
  class="add-home-modal home-benefit-modal"
  *ngIf="creditcardbookingPopup && isDesktopView"
>
  <div class="close-section">
    <div class="skip-and-close">
      <span class="close-popup" (click)="closecreditcardbookingPopup()"
        ><img src="/assets/images/cancel_ic.svg"
      /></span>
    </div>
  </div>

  <div class="add_home_sheet">
    <div class="header">
      <p>
        {{ "You asked," | translate }}
        <span class="highlight">{{
          "we
                    listened!" | translate
        }}</span>
        {{ "Credit card bookings coming soon!" | translate }}
      </p>
    </div>
    <p>
      {{
        "At Holiday Swap, we want our Owners to have the best of both worlds, with both HS Points AND dollars powering our amazing booking platform."
          | translate
      }}
    </p>
    <p>
      {{ "We also believe in full transparency" | translate }}
      <span class="highlight">{{ "(no hidden fees)" | translate }},</span>
      {{
        "so please remember to factor in cleaning and other costs
            into your nightly pricing!" | translate
      }}
    </p>
  </div>

  <div class="add-home-btn-main">
    <div class="add-home-btn-head" (click)="handleAddHome('creditcardbooking')">
      <button
        type="button"
        class="add-home-btn-popup"
        [class.btn-pe-none]="block_btn"
      >
        {{ "Add your home" | translate }}
      </button>
    </div>
  </div>
</div>

<app-bottom-sheet
  *ngIf="!isDesktopView"
  [closeHeader]="false"
  (closed)="closeVideoPopup()"
  #creditcardbookingtBottomSheet="bottomSheet"
  [defaultHeight]="'448px'"
  [defaultMaxHeight]="'80%'"
>
  <div bottomSheet-body *ngIf="creditcardbookingtBottomSheet._visible">
    <div class="add_home_sheet">
      <div class="header">
        <div>
          <p>
            {{ "You asked," | translate }}
            <span class="highlight">{{
              "we
                            listened!" | translate
            }}</span>
            {{ "Credit card bookings coming soon!" | translate }}
          </p>
        </div>
      </div>
      <p>
        {{
          "At Holiday Swap, we want our Owners to have the best of both worlds, with both HS Points AND dollars powering our amazing booking platform."
            | translate
        }}
      </p>
      <p>
        {{ "We also believe in full transparency" | translate }}
        <span class="highlight">{{ "(no hidden fees)" | translate }},</span>
        {{
          "so please remember to factor in cleaning and other
                costs into your nightly pricing!" | translate
        }}
      </p>
      <button class="btn-block" (click)="handleAddHome('creditcardbooking')">
        {{ "Add your home" | translate }}
      </button>
    </div>
  </div>
</app-bottom-sheet>
