import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ElementRef,
  AfterViewInit,
  HostListener,
} from '@angular/core';
import {
  StripeError,
  Stripe,
  StripeCardElement,
  StripeElements,
  StripeCardNumberElement,
  StripeCardExpiryElement,
  StripeCardCvcElement,
} from '@stripe/stripe-js';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { AuthService } from '../../services/auth.service';
import { CardService } from '../../services/card.service';
import { StripeService } from '../../services/stripe.service';
import { ThemeService } from '../../../core/services/theme.service';
import { SnackbarService } from '../../services/snackbar.service';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  NgModel,
  Validators,
} from '@angular/forms';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';

declare var $: any;
@Component({
  selector: 'app-new-stripe-add-card',
  templateUrl: './new-stripe-add-card.component.html',
  styleUrls: ['./new-stripe-add-card.component.scss'],
})
export class NewStripeAddCardComponent implements OnInit, AfterViewInit {
  //   public card: {
  //     name?: string,
  //     postalcode?: string,
  //     country?:string
  //   } = {};
  card: FormGroup;
  @ViewChild(GooglePlaceDirective) googlePlaceDirective: GooglePlaceDirective;
  public error: string | StripeError;
  public loading = false;
  public state = 0;
  public focus = false;
  public cardNumber;

  @Input() set loaded(status: boolean) {
    if (status) {
      if (!this.cardElement) {
        this.stripe = this.stripeService.stripe;
      }
    }
  }

  @Output() public onSuccess = new EventEmitter();
  public token: any;
  private stripe: Stripe;
  private cardElement: StripeCardElement;
  private cardExpiryElement: StripeCardExpiryElement;
  private cardCvcElement: StripeCardCvcElement;

  private elements: StripeElements;
  private cardNumberElement: StripeCardNumberElement;
  private scrollTimeout: any;
  @ViewChild('cardNumberRef') cardNumberRef: ElementRef;
  @ViewChild('cardNumberErrorRef') cardNumberErrorRef: ElementRef;
  @ViewChild('cardExpiryRef') cardExpiryRef: ElementRef;
  @ViewChild('cardExpiryErrorRef') cardExpiryErrorRef: ElementRef;
  @ViewChild('cardCVCRef') cardCVCRef: ElementRef;
  @ViewChild('cardCVCErrorRef') cardCVCErrorRef: ElementRef;

  public options = {
    types: ['(regions)'],
  } as Options;

  constructor(
    private cardService: CardService,
    private stripeService: StripeService,
    public authService: AuthService,
    private themeService: ThemeService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private snackbarService: SnackbarService,
    public fb: FormBuilder,
    public translate: TranslateService
  ) {
    this.card = this.fb.group({
      name: ['', [Validators.required]],
      postalcode: [
        '',
        [
          Validators.required,
          Validators.maxLength(10),
          Validators.minLength(2),
        ],
      ],
      country: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {}

  @HostListener('window:scroll', ['$event'])
  @HostListener('window:wheel', ['$event'])
  @HostListener('window:touchmove', ['$event'])
  onScroll(event: Event): void {
    $('.pac-container').css('visibility', 'hidden');
    clearTimeout(this.scrollTimeout);
    this.scrollTimeout = setTimeout(() => {
      const newTop =
        $('#autocompleteInput').offset().top +
        $('#autocompleteInput').outerHeight();
      $('.pac-container').css('top', newTop + 'px');
      $('.pac-container').css('visibility', 'visible');
    }, 200);
  }

  ngAfterViewInit() {
    this.elements = this.stripe?.elements({
      fonts: [
        {
          // integrate your font into stripe
          cssSrc:
            'https://fonts.googleapis.com/css?family=Poppins:400,600&display=swap',
        },
      ],
    });

    this._createStripeElements();
  }

  public handleAddressChange({ formatted_address }) {
    this.card.get('country').setValue(formatted_address);
  }

  public async onSubmit() {
    console.log('$$$$ onSubmit');
    this.loading = true;

    const payment_method = await this._createCardPaymentMethod();
    // setTimeout(()=> {
    // 	const payment_token = this.createPaymentToken(this.elements);

    // }, 1000)

    if (this.card.get('name')?.value.trim().length <= 0) {
      this.card.controls.name.setErrors({ invalid: true });
    }
    this.card.markAllAsTouched();

    if (this.card.invalid) {
      this.loading = false;
      return;
    }
    if (!payment_method) {
      this.loading = false;
      return;
    }

    this.cardService
      .create({
        payment_method_id: payment_method,
        full_name: this.card.get('name').value,
      })
      .subscribe(
        (response: any) => {
          this.onSuccess.emit(true);

          this.googleAnalyticsService.eventEmitter(
            'TK_AddCardComplete',
            'Tokens',
            'TK_AddCardSuccess'
          );
        },
        (err) => {
          // this.loading = false;

          this.snackbarService.show({
            message: err,
            type: 'success',
            config: {
              class: 'centered',
            },
          });
        }
      )
      .add(() => {
        this.loading = false;
      });
  }

  private async _createStripeElements() {
    if (!this.elements) {
      return;
    }
    const style = {
      base: {
        iconColor: '#275efe',
        color: this.themeService.theme === 'dark' ? '#000' : '#000',
        fontWeight: 500,
        fontFamily: 'Poppins, sans-serif',
        fontSize: '14px',

        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    };

    this.cardNumber = this.elements.create('cardNumber', {
      showIcon: true,
      iconStyle: 'solid',
      style,
    });
    this.cardExpiryElement = this.elements.create('cardExpiry', {
      style,
    });
    this.cardCvcElement = this.elements.create('cardCvc', {
      style,
    });

    this.cardNumber.mount(this.cardNumberRef.nativeElement);
    this.cardExpiryElement.mount(this.cardExpiryRef.nativeElement);
    this.cardCvcElement.mount(this.cardCVCRef.nativeElement);

    // const paymentElement = this.elements.create('payment')
    // paymentElement.mount('#payment-element')

    // create card element
    // this.cardElement = this.elements.create("card", {
    // 	iconStyle: "solid",
    // 	// hidePostalCode : true,
    // 	style: {
    // 		base: {
    // 			iconColor: "#275efe",
    // 			color: this.themeService.theme === "dark" ? "#000" : "#000",
    // 			fontWeight: 500,
    // 			fontFamily: "Poppins, sans-serif",
    // 			fontSize: "14px",
    // 			// lineHeight: "1.5",
    // 			// padding: "15px",

    // 			// fontSmoothing: "antialiased",

    // 			"::placeholder": {
    // 				color: "#999999"
    // 			},

    // 			":-webkit-autofill": {
    // 				color: "#fce883"
    // 			}
    // 		},
    // 		invalid: {
    // 			iconColor: "#ea4343",
    // 			color: "#ea4343"
    // 		}
    // 	}
    // });

    // // this.cardElement.mount("#card-element");

    // this.cardElement.on("focus", () => {
    // 	this.focus = true;
    // });

    // this.cardElement.on("blur", () => {
    // 	this.focus = false;
    // });
    // // listen for card element changes
    this.cardNumber.on('change', (event) => {
      const displayError = document.getElementById(
        this.cardNumberErrorRef.nativeElement
      );
      if (displayError) {
        if (event.error) {
          displayError.textContent = this.translate.instant(
            event.error.message
          );
        } else {
          displayError.textContent = '';
        }
      }
    });

    this.cardExpiryElement.on('change', (event) => {
      const displayError = document.getElementById('expiry-error');
      if (displayError) {
        if (event.error) {
          displayError.textContent = this.translate.instant(
            event.error.message
          );
        } else if (event.empty) {
          displayError.textContent = 'This field is required';
        } else {
          displayError.textContent = '';
        }
      }
    });

    this.cardCvcElement.on('change', (event) => {
      const displayError = document.getElementById('cvc-error');
      if (displayError) {
        if (event.error) {
          displayError.textContent = this.translate.instant(
            event.error.message
          );
        } else if (event.empty) {
          displayError.textContent = 'This field is required';
        } else {
          displayError.textContent = '';
        }
      }
    });
  }

  private async createPaymentToken(element) {
    try {
      await this.stripe.createToken(this.cardNumber).then((result) => {
        if (result.error) {
          // Inform the user if there was an error
          return result.error;
        } else {
          // Send the token to your server
          localStorage.setItem('stripe-token', JSON.stringify(result.token));
          return result.token;
        }
      });
    } catch (err) {
      return false;
    }
  }

  private async _createCardPaymentMethod() {
    try {
      const result = await this.stripe.createPaymentMethod({
        type: 'card',
        card: this.cardNumber,
        billing_details: {
          name: this.card.get('name')?.value,
          address: {
            line1: this.card.get('country')?.value,
            postal_code: this.card.get('postalcode')?.value,
          },
        },
      });
      return result.paymentMethod ? result.paymentMethod.id : false;
      // payment_method_id: result.paymentMethod.id,
    } catch (err) {
      return false;
    }
  }
}
