<div class="explore-hero-section">
    <img
      class="banner-image"
      src="assets/images/Brand-Page-Banner.jpg"
      alt="home-hero-banner"
    />
  
    <div class="explore-hero-section-container">
      <div class="explore-hero-section-content">
        <h1 class="explore-hero-title">
            Holiday Swap brand ambassador
program
        </h1>
        <h5 style='font-weight:500' class='mb-5'>Are you ready to join us, take the travel industry to the next level and get compensated handsomely? You are in the right place!</h5>
      </div>
    </div>
  </div>

  <section class="weAreLookingFor">
    <h2>Who are we <span>looking for </span>?</h2>
    <div class="d-flex weAreLookingForWrapper">
      <div class="rightBorder">
        <div class="weAreLookingForCard">
          <img src="assets/images/Person-Star.svg"/>
          <h3>Influencers</h3>
          <h6>Do you have loyal fans who love amazing travel experiences? Let&apos;s divlaborate!</h6>
        </div>
      </div>
      <div class="rightBorder">
        <div class="weAreLookingForCard">
          <img src="assets/images/Union.svg"/>
          <h3>Companies</h3>
          <h6>At Holiday Swap, we love to partner with brands that share our core values and who represent customers who love to travel!</h6>
        </div>
      </div>
      <div >
        <div class="weAreLookingForCard">
          <img src="assets/images/Person-Rotate.svg"/>
          <h3>Passionate people</h3>
          <h6>If you are passionate about travel and would like to help take Holiday Swap to the next level, we are ready to divlaborate with you, no matter your background or experience!</h6>
        </div>
    </div>
    </div>
  </section>

<div class="contentSwitch reverse">
    <img src="assets/images/How-To-Participate.png"/>
    <div class="contentSwitchContent">
        <h2 class="contentSwitchContentTitle">How to <span>participate</span> in the program?</h2>
        <p class="">Get in touch with us using the form below or DM us on Instagram. We look forward to hearing from you!</p>
    </div>
</div>
<div class="contentSwitch reverse" style="margin-top: 16px;">
    <img src="assets/images/Download-App.png"/>
    <div class="contentSwitchContent">
        <h2 class="contentSwitchContentTitle">Download the <span>app now!</span></h2>
        <p class="">Get the Holiday Swap app and join millions of happy traveller's!        </p>
        <div class="d-flex justify-content-center justify-content-lg-start downloadAppWithQrLinks" style="height: 52px;">
            <a href="https://apps.apple.com/gb/app/holiday-swa-p-home-exchange/id1313713150" target="_blank" rel="noreferrer">
              <img
                style="height: 100%; width: 155px"
                src="assets/images/Apple-Store-New.png"
                alt="Apple store"
                class="downloadAppWithQrLinksApple"
              />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.holidayswap&hl=en&gl=US" style="margin-left: 8px;" target="_blank" rel="noreferrer">
              <img
              style="height: 100%; width:155px"
                src="assets/images/Play-Store-New.png"
                alt="Play store"
                class="downloadAppWithQrLinksApple"
              />
            </a>
          </div>
    </div>
</div>


<div class="contentSwitch">
    <img src="assets/images/Power-Of-Prmium.jpg"/>
    <div class="contentSwitchContent">
        <h2 class="contentSwitchContentTitle">Ready to unleash the <span>power of premium</span></h2>
        <p class="">Start a 14-day free trial of Holiday Swap premium and never pay a booking fee again! You'll save thousands when travelling with unlimited home swaps for only $10 per month.</p>
    </div>
</div>

<div class="contentSwitch reverse">
    <img src="assets/images/Earn-Or-Purchase-Token.jpg"/>
    <div class="contentSwitchContent">
        <h2 class="contentSwitchContentTitle">Ready to <span>earn HS Points</span> or purchase at <span>20% off ?</span></h2>
        <p class="">Buy or earn HS Points and book homes around the world for 90% cheaper than hotels!</p>
        <p class="">Act now and receive a 20% savings, or complete the achievements in your dashboard to earn free travel HS Points</p>

        <p class="">It's never been easier to book homes around the world for free!</p>


    </div>
</div>

