import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";

@Component({
	selector: "app-panel",
	templateUrl: "./panel.component.html",
	styleUrls: ["./panel.component.scss"]
})
export class PanelComponent implements OnInit {
	@Input() class: string;
	@Input() loading: boolean;
	@Input() textColor: string;
	@Input() background: string;
	@Input() shadow: boolean;

	@ViewChild("panel") panel: ElementRef;
	constructor() { }

	ngOnInit(): void {

	}
	ngAfterViewInit() {
		if (this.background) {
			this.panel.nativeElement.style.backgroundColor = this.background;
		}

		if (this.textColor) {
			this.panel.nativeElement.style.color = this.textColor;
		}
	}

}
