import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { MapboxService } from "src/app/shared/services/mapbox.service";
import { SpaceService } from "src/app/shared/services/space.service";
import { MetadataService } from "src/app/shared/services/metadata.service";
import { ExploreSpacesService } from "src/app/shared/services/explore-spaces.service";
import { LanguageService } from 'src/app/shared/services/language.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ClevertapService } from 'src/app/shared/services/clevertap.service';
import { ToastrService } from 'ngx-toastr';
import { FilterService } from 'src/app/shared/services/filter.service';
import { TranslateService } from '@ngx-translate/core';


export function debounce(delay: number = 300): MethodDecorator {
	return (_target: any, _propertyKey: string, descriptor: PropertyDescriptor) => {
		let timeout = null;

		const original = descriptor.value;

		descriptor.value = function (...args) {
			clearTimeout(timeout);
			timeout = setTimeout(() => original.apply(this, args), delay);
		};

		return descriptor;
	};
}

@Component({
	selector: 'app-search-filter-list-homes',
	templateUrl: './search-filter-list-homes.component.html',
	styleUrls: ['./search-filter-list-homes.component.scss']
})
export class SearchFilterListHomesComponent implements OnInit {

	searchedAddress: any;
	public spaceList = [];
	public loading = false;
	public locations = [];
	public location: any = {};
	isPremiumMember:boolean = false;
  	userData:any;
	public pagination = {
		total: 0,
		page: 1,
		// skip: 0,
		// limit: 5,
	};
	public filter: any = {

		purpose_ids: 522,
		// purpose_ids:null,

	};
	public params = {};

	public categories: any = {
		space_purposes: [],
	};
	showToggle: boolean = false;
	public selectedLanguage = "en";
  public wishlist = [];
  userEmail;
	constructor(
		private cookieService: CookieService,
		private mapboxService: MapboxService,
		private spaceService: SpaceService,
		private exploreService: ExploreSpacesService,
		private metadataService: MetadataService,
		private languageService: LanguageService,
		private toastr: ToastrService,
		private filterService : FilterService,
    public userService: UserService,
	public translate: TranslateService,
    private clevertapService: ClevertapService) {
			let userFullData = localStorage.getItem("userDataTEST");
			this.userData = JSON.parse(userFullData);
			if(this.userData){
				this.isPremiumMember = this.userData.subscription?.planName!='Lite' && this.userData.subscription?.is_trial == 1;
			}else{
				this.userService.currentUserSubject.subscribe(data=>{
					this.userData = data;
					this.isPremiumMember = this.userData.subscription?.planName!='Lite' && this.userData.subscription?.is_trial == 1;
				})
				// this.isPremiumMember = this.userData.subscription?.planName =='Lite' || this.userData.subscription?.is_trial == 0 ;
			}
	}

	ngOnInit(): void {

		if(localStorage.getItem("purpose_ids")) {

			this.filter.purpose_ids = JSON.parse(localStorage.getItem("purpose_ids"))
		}
		else {
			this.filter.purpose_ids = this.filterService.purposeIds;
			localStorage.setItem("purpose_ids", JSON.stringify(this.filterService.purposeIds))
		}

		if (this.cookieService.get('search-address')) {
			this.params = JSON.parse(this.cookieService.get('search-address'));
			this.location = this.params;

			this.getSpaces();
		}
		else {
			this.getFeaturedSpaces();
		}

		let loadLang = this.languageService.GetLanguage() + '';

		if (loadLang == "null" || loadLang == "") {
			this.selectedLanguage = "en";
		}
		else {
			this.selectedLanguage = loadLang;
		}

		this.metadataService.getSpaceCategories().subscribe((response: { data: any }) => {
			this.categories = response.data;
			// this.categories.space_purposes.unshift({ id: -1, name: "All" });
			// this.categories.space_purposes.push({ id: -2, name: "IBS" });
		});

		this.languageService.languageUpdates.subscribe((res) => {
			this.selectedLanguage = res;
		})

    this.userService.currentUser.subscribe((user) => {
      if (Object.keys(user).length > 0) {
        this.wishlist = user?.favourited;
      }
      });

	}

	getPersentage(amount){
		let discount = Math.round(parseInt(amount)*20/100);
		let offerAmt = amount - discount;
		return offerAmt;
	  }

	public getFeaturedSpaces() {
		this.loading = true;

		this.exploreService.getFeaturedSpaces().subscribe((res: any) => {
			this.loading = false;

			this.spaceList = [];

			this.spaceList = res.data;



		}, (err) => {
			this.loading = false;


		}).add(() => {
		});
	}

	public getSpaces() {
		this.loading = true;
		/*if (this.mapComponent) {
			this.mapComponent.clear();
		}*/
		const query = { ...this.params, ...this.pagination, purpose_ids : this.filter.purpose_ids };


		this.spaceService.getSpaces(this._getUriWithParam(query)).subscribe((res: any) => {
			this.loading = false;

			this.spaceList = res.data;






		}, (err) => {
			this.loading = false;


		}).add(() => {
		});
	}

	public onPurposeTypeChange(purpose_ids, name) {
		this.filter.purpose_ids = purpose_ids;
		this.filterService.purposeIds = purpose_ids;
		this.filterService.purposeName = name;
		this.onFilterChange();

	}

	public onFilterChange() {


		// this.filter = event;


		// convert object to url parameters
		// const query = { ...this.params, ...this.filter };

		if (this.filter.purpose_ids != -1) {
			this.filter = { ...this.filter, ...this.location };
			this.params = this.filter;
		}

		// const keys = Object.keys(this.filter);
		// const payload = {};


		// keys.filter((key) => {
		// 	return event[key] !== undefined && event[key] !== null && event[key] !== 0 && event[key].length !== 0;
		// }).map((key) => {
		// 	payload[key] = event[key];
		// });


		// this.params = payload;

		this.spaceList = [];
		this.pagination.page = 1;

		this.getSpaces();
	}

	private _getUriWithParam = (params: Record<string, any>): string => {
		const Url = new URL(window.location.href);
		const urlParams: URLSearchParams = new URLSearchParams(Url.search);
		for (const key in params) {
			if (params[key] !== undefined) {
				urlParams.set(key, params[key]);
			}
		}
		Url.search = urlParams.toString();
		return Url.toString().replace(window.location.href, "");
	}


	public onLike(space, index) {

		//this.notificationService.setFriend(space.user);
		this.spaceService.likeSpace(space.id, space.is_favourited).subscribe((response) => {
			//	space.is_favourited = !this.space.is_favourited;
			this.spaceList[index].is_favourited = !space.is_favourited;


			let message = {
				"title": "Someone liked your space",
				"text": 'Someone added your space in his favourite list'
			};
			//if (space.is_favourited) this.notificationService.sendFCMNotification(message);



		}, (err) => {
			this.toastr.error(this.translate.instant(err), "Error");

		});
	}


	public onSelect(location) {
		this.locations = [];
		this.location = location;
		this.params = this.location;
		if (this.location.full_address) {
			this.cookieService.delete('search-address');

			this.cookieService.set('search-address', JSON.stringify(this.location));

		}
		else {

		}

		this.onFilterChange();
	}

	@debounce(300)
	public autoCompleteAddress(query: string) {
		if (query.length > 1) {
			this.mapboxService.search(query).subscribe((response: any) => {
				this.locations = response.features.map((feature) => this._parseResult(feature));
			});

		} else {
			this.locations = [];
		}
	}


	private _parseResult(feature) {
		const address: any = {};
		if (feature.place_type[0] == 'place') {
			for (const result of feature.context) {
				if (result.id.includes("postcode")) {
					address.postcode = result.text;
				}
				if (result.id.includes("locality")) {
					address.line_2 = result.text;
				}
				if (result.id.includes("place")) {
					address.city = result.text;
				}
				if (result.id.includes("district")) {
					address.county = result.text;
				}
				if (result.id.includes("country")) {
					address.country = result.text;
				}

				if (result.id.includes("postcode")) {
					address.postcode = result.text;
				}
			}
		}
		// First line of address
		if (feature.text || feature.address) {
			address.street = (feature.address ? feature.address + " " : "") + (feature.text ? feature.text : "");
		}

		if (feature.id.includes("place")) {
			address.city = feature.text;
		}
        if (feature.id.includes("region")) {
            address.city = feature.text;
        }
		// Full Address
		if (feature.place_name) {
			address.full_address = feature.place_name;
		}
		// Coordinates
		if (feature.center) {
			address.longitude = feature.center[0];
			address.latitude = feature.center[1];
		}

		return address;

	}
  ngOnDestroy(): void {
	localStorage.removeItem("purpose_ids")
    if(this.wishlist && this.wishlist?.length <=0) {
      let searchData = {
        email :this.userEmail
      }
      this.clevertapService.pushCleverTapEvent('wishlist',searchData);
    }
	}

}
