import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { interval, Observable, Subject, timer } from 'rxjs';
import {
  catchError,
  debounceTime,
  delay,
  map,
  timeInterval,
} from 'rxjs/operators';
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';
import {
  ICreateSwap,
  ISwapDetails,
  ICreateInstantPay,
} from '../interfaces/swap';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class SwapService {
  public static cacheBuster$ = new Subject<void>();
  public bookingDates: any = {};
  public swap: ICreateInstantPay = {};

  constructor(private httpService: HttpService, public toastr: ToastrService) {}
  /*@Cacheable({
		cacheBusterObserver: SwapService.cacheBuster$,
	})*/
  public getSwaps() {
    return this.httpService.get(`/users/me/swaps`);
  }
  public getTripList(filter) {
    return this.httpService.get(
      `/users/me/swaps?type=${filter.type}&page=${filter.page}`
    );
  }
  public getLikedList() {
    return this.httpService.get(`/liked-spaces`);
  }

  /*	@Cacheable({
			storageStrategy: LocalStorageStrategy,
			cacheBusterObserver: SwapService.cacheBuster$,
		})*/
  public getSwap(id: number) {
    return this.httpService.get(`/swaps/${id}`);
  }

  public getExtras(id: number) {
    return this.httpService.get(`/extras?swap_id=${id}`);
  }

  public getSwapPricing(data: ISwapDetails) {
    return this.httpService
      .post(`/swaps/pricing`, data)
      .pipe(map((res: { data: any }) => res.data));
  }

  public cancel(id: number, data: { reason?: string }) {
    return this.httpService.post(`/swaps/${id}/status/cancelled`, data).pipe(
      map((response) => {
        // Update the currentUser observable
        SwapService.cacheBuster$.next();
        return response;
      })
    );
  }
  public cancelBooking(id: number, data: { reason?: string }) {
    return this.httpService.post(`/booking/${id}/status/cancelled`, data).pipe(
      map((response) => {
        // Update the currentUser observable
        SwapService.cacheBuster$.next();
        return response;
      })
    );
  }
  public reject(id: number, data: { reason?: string }) {
    return this.httpService.post(`/swaps/${id}/status/rejected`, data).pipe(
      map((response) => {
        // Update the currentUser observable
        SwapService.cacheBuster$.next();
        return response;
      })
    );
  }
  public rejectBooking(id: number, data: { reason?: string }) {
    return this.httpService.post(`/booking/${id}/status/rejected`, data).pipe(
      map((response) => {
        // Update the currentUser observable
        SwapService.cacheBuster$.next();
        return response;
      })
    );
  }
  public acceptBooking(id: number, data: {}) {
    // return this.httpService.post(`/booking/${id}/status/accepted`, data).pipe((map((response) => {
    return this.httpService.post(`/booking/${id}/accept`, data).pipe(
      map((response) => {
        // Update the currentUser observable
        SwapService.cacheBuster$.next();
        return response;
      })
    );
  }
  public accept(id: number, data: {}) {
    return this.httpService.post(`/swaps/${id}/status/accepted`, data).pipe(
      map((response) => {
        // Update the currentUser observable
        SwapService.cacheBuster$.next();
        return response;
      })
    );
  }

  public counter(id: number, data: {}) {
    return this.httpService
      .post(`/swaps/${id}/status/counter-proposal`, data)
      .pipe(
        map((response) => {
          // Update the currentUser observable
          SwapService.cacheBuster$.next();
          return response;
        })
      );
  }

  public pay(id, data) {
    return this.httpService.post(`/swaps/${id}/status/paid`, data).pipe(
      map((response) => {
        // Update the currentUser observable
        SwapService.cacheBuster$.next();
        return response;
      })
    );
  }

  public create(data: ICreateSwap) {
    return this.httpService.post(`/swaps`, data);
    //   .pipe(
    //     map((response) => {
    // 		  // Update the currentUser observable
    // 		  SwapService.cacheBuster$.next();
    // 		  return response;
    // 	  }),
    // 	  catchError((err) => {
    // 		this.toastr.error(err,'Error')
    // 		return [err];
    // 	  })

    //   );
  }
  public update(id, data: ICreateSwap) {
    return this.httpService.put(`/swaps/${id}`, data).pipe(
      map((response: { data }) => {
        // Update the currentUser observable
        SwapService.cacheBuster$.next();
        return response;
      })
    );
  }

  public instantBooking(data: ICreateInstantPay) {
    return this.httpService.post(`/instant-booking`, data).pipe(
      map((response) => {
        // Update the currentUser observable
        SwapService.cacheBuster$.next();
        return response;
      })
    );
  }

  public fiatpaymentBooking(data: ICreateInstantPay) {
    return this.httpService.post(`/fiat-booking`, data).pipe(
      map((response) => {
        SwapService.cacheBuster$.next();
        return response;
      })
    );
  }

  public saveBookingDates(dates): void {
    this.bookingDates = dates;
  }

  public getBookingDates() {
    return this.bookingDates;
  }

  public likedForUndo(id) {
    return this.httpService.get(`/like_swap/${id}`);
  }

  public dislikeForUndo(id) {
    return this.httpService.get(`/dislike_swap/${id}`);
  }

  public getUndo() {
    return this.httpService.get('/undo');
  }

  public getUndoCount() {
    return this.httpService.get('/undo_counts');
  }

  public getSwapsById(id) {
    return this.httpService.get(`/getBookingDetailById/${id}`);
  }

  public failedPayment(data: ICreateInstantPay) {
    return this.httpService.post(`/booking-secure-failed`, data);
  }

  public checkCouponCode(coupon_code, booking_amount, total_booking_days = 0) {
    return this.httpService.post(`/check-coupon-code`, {
      coupon_code: coupon_code,
      booking_amount: booking_amount,
      total_booking_days: total_booking_days,
    });
  }

  public newAcceptBooking(id: number, data: {}) {
    return this.httpService.post(`/booking/${id}/accept`, data);
  }
}
