<div [ngStyle]="{'direction': languageDirection}" class="mainWrapper" #mainContent id="main-content">
	<router-outlet></router-outlet>
	<router-outlet name="modal"></router-outlet>

	<app-snackbar></app-snackbar>

	<div *ngIf="showInstallMessage" (click)="showInstallMessage = false" class="installPopup">
		Install Holiday Swap on your iPh one: tap the share button and then Add to homescreen.
	</div>
</div>
<div class="popupBackground" *ngIf="showPopupEmail"></div>
<div class="verify-modal" *ngIf="showPopupEmail">
    <div class="close-section">
        <div class="skip-and-close"> <span class="close-popup" (click)="closeEmailPopup()">
			<img alt="" src="/assets/images/cancel_ic.svg"></span> </div>
    </div>
    <div class="verify-title">
        <h2 *ngIf="!sentSucces">{{"Email verification needed!" | translate}}</h2>
		<h2 *ngIf="sentSucces" class="mb-4">A link has been sent to your email.</h2>
    </div>
	<div class="verify-content">
		<p class="message-text-style" *ngIf="!sentSucces">{{"To continue using Holiday Swap please verify your email address." | translate}}</p>
		<p class="message-text-style" *ngIf="sentSucces" >Please validate your email to secure your experience on Holiday Swap.</p>
	</div>

	<ng-container *ngIf="!sentSucces">
		<div class="verify-btn-main">
			<div class="verify-btn-head">
				<button type="button" class="verify-btn-popup" (click)="verifyEmail()"> {{"Verify Now" | translate}} </button>
			</div>
		</div>
	</ng-container>
</div>

<app-max-home-popup [showMaxHomePopup]="showMaxHomePopup" (maxPopupEvent)="closeMaxHomePopup()"></app-max-home-popup>
