<div class="breadcrumbs">
  <div class="breadcrumbs_wrapper">
    <!-- breadcrumbs -->
    <breadcrumbs [breadcrumb]="items"></breadcrumbs>
  </div>
</div>

<div class="edit_home_wrapper">
  <div class="edit_home_header">
    <div class="back_btn">
      <span (click)="pop()"><img src="/assets/images/back_arrow.svg" /></span>
    </div>
  </div>
  <section class="image_section">
    <h3>{{ "Edit your home" | translate }}</h3>
    <div class="title">
      <p class="image_section_title">
        <span class="highlight">{{ "Earn 70 HS Points" | translate }}</span>
        {{
          "for uploading 6 images
                of your home." | translate
        }}
      </p>
      <!-- <span class="edit_btn" (click)="openEditHomePopup(1)">{{"Edit" | translate}}</span> -->
    </div>
    <div class="image_form">
      <div class="image_box">
        <img
          [src]="
            outsideViewImage.image_url
              ? outsideViewImage.image_url
              : placeholderImg
          "
          alt="place holder image"
        />

        <img
          (click)="openEditHomePopup(1)"
          src="/assets/icons/Ellipse_edit_icon.svg"
          alt="edit icon"
        />
      </div>
      <div class="address_box">
        <span>{{ space?.title }}</span>
        <div>
          <span class="edit-address-content" *ngIf="space?.address?.street_1"
            >{{ space?.address?.street_1 }},
          </span>
          <span
            class="edit-address-content"
            *ngIf="space?.address?.city || space?.address?.country"
            >{{ space?.address?.city }}, {{ space?.address?.country }}</span
          >
        </div>
        <span (click)="openEditHomePopup(4)">{{
          "Edit address" | translate
        }}</span>
      </div>
      <!-- <div class="image_box home_view">
                <img [src]="outsideViewImage.image_url?outsideViewImage.image_url:placeholderImg"
                    alt="place holder image">
                <span>{{"Cover Image" | translate}}</span>
            </div>
            <div class="image_box living_room">
                <img [src]="livingRoomImages.image_url?livingRoomImages.image_url:placeholderImg"
                    alt="place holder image">
            </div>
            <div class="image_box beadroom">
                <img [src]="bedRoomImages.image_url?bedRoomImages.image_url:placeholderImg" alt="place holder image">
            </div>
            <div class="image_box kitchen">
                <img [src]="kitchenImages.image_url?kitchenImages.image_url:placeholderImg" alt="place holder image">
            </div>
            <div class="image_box bathroom">
                <img [src]="bathRoomImages.image_url?bathRoomImages.image_url:placeholderImg" alt="place holder image">
            </div>
            <div class="image_box guest_room">
                <img [src]="guestRoomImages.image_url?guestRoomImages.image_url:placeholderImg"
                    alt="place holder image">
            </div> -->
    </div>
  </section>

  <section [hidden]="!emptyAmenities">
    <div class="highlight-card" (click)="openEditHomePopup(6)">
      <img src="/assets/icons/three_start.svg" alt="star" />
      <p>Select your home’s <span>amenities</span></p>
      <div><img src="/assets/icons/right-arrow.svg" alt="arrow" /></div>
    </div>
  </section>

  <section class="home_details_section">
    <div class="title">
      <p>{{ "Your listing details:" | translate }}</p>
      <span class="edit_btn" (click)="openEditHomePopup(12)">{{
        "Edit" | translate
      }}</span>
    </div>
    <div class="home_details_content">
      <!-- <div class="address">
                <p class="home_title">{{space?.title}}</p>
                <p class="font-14">
                    <span *ngIf="space?.address?.street_1">{{space?.address?.street_1}}, </span>
                    <span *ngIf="space?.address?.street_2">{{space?.address?.street_2}}, </span>
                    <span *ngIf="space?.address?.city">{{space?.address?.city}}, </span>
                    <span *ngIf="space?.address?.country">{{space?.address?.country}}, </span>
                    <span *ngIf="space?.address?.continent">{{space?.address?.continent}}, </span>
                    <span *ngIf="space?.address?.postcode_1">{{space?.address?.postcode_1}}</span>

                </p>
            </div> -->
      <div class="availability">
        <p class="calendar_title">
          {{
            "Please select the dates when your home is not available"
              | translate
          }}:
        </p>
        <!-- <ng-container *ngFor="let dates of unavailable_dates">
                    <p class="font-bold font-14">{{dates.started_at * 1000 | date:'MMM, dd'}} - {{dates.ended_at * 1000 | date:'MMM, dd yyyy'}}</p>
                </ng-container> -->
        <app-calendar
          *ngIf="unavailable"
          [unavailable]="unavailable"
          [min]="minDate"
          [clickDisable]="true"
          [date]="minDate"
          [destroy]="true"
          [mode]="'range'"
        ></app-calendar>
      </div>
    </div>
  </section>

  <section class="new_feature_section">
    <div class="new_feature_banner">
      <span>{{ "New feature" | translate }}</span>
    </div>
  </section>

  <section
    class="boost_wrapper"
    *ngIf="
      !space?.is_boosted && space?.status == 'live' && activeBoostCount == 0
    "
  >
    <div class="boost_desc">
      <img src="/assets/images/icons/search-boost_icon.svg" alt="" />
      <div>
        <p class="title">
          {{ "Shoot your listings to the top of search!" | translate }}
        </p>
        <!-- <p class="desc">{{"Shoot to top of search!" | translate}}</p> -->
      </div>
    </div>
    <button class="btn btn-yellow buy-btn" (click)="buyBoosterRedirection()">
      {{ "Buy now" | translate }}
    </button>
  </section>

  <section
    class="boost_wrapper"
    *ngIf="
      !space?.is_boosted && space?.status == 'live' && activeBoostCount > 0
    "
  >
    <div class="boost_desc">
      <img src="/assets/images/icons/search-boost_icon.svg" alt="" />
      <div>
        <p class="title">{{ "Boost this listing?" | translate }}</p>
        <p class="desc">{{ "Shoot to top of search!" | translate }}</p>
      </div>
    </div>
    <button class="btn btn-yellow" (click)="openConfirmPopup()">
      {{ "Boost now" | translate }}
    </button>
  </section>

  <section class="booster-section" *ngIf="space?.is_boosted">
    <div class="boosted_banner">
      <p>{{ "This listing is using a search boost!" | translate }}</p>
    </div>
  </section>

  <section class="tokens_section">
    <div>
      <section
        class="token_cash_section"
        [appLoad]="priceLoading"
        *ngIf="space.purpose_id == 521"
      >
        <h4 class="card_title">
          {{ "You’re currently using your listings to" | translate
          }}<span class="highlight_orange"> {{ "Swap" | translate }}</span>
          {{ "homes." | translate }}
        </h4>
        <div class="card_footer" [appLoad]="priceLoading">
          <p class="card_text">
            {{ "Want to edit how you’re using your listing?" | translate }}
          </p>
          <span class="edit_btn" (click)="openEditHomePopup(2)">{{
            "Edit" | translate
          }}</span>
        </div>
      </section>

      <!-- Swap and cash Money  -->
      <section
        class="token_cash_section"
        [appLoad]="priceLoading"
        *ngIf="space.purpose_id == 522 && space.payment_type_id == 561"
      >
        <h4 class="card_title">
          {{ "You’re currently using your listings to" | translate }}
          <span class="highlight_orange">{{ "Swap" | translate }}</span>
          {{ "& earn" | translate }}
          <span class="highlight_orange">{{ "Money" | translate }}</span
          >.
        </h4>
        <div class="card_footer" [appLoad]="priceLoading">
          <p class="card_text">
            {{ "Want to edit how you’re using your listing?" | translate }}
          </p>
          <span class="edit_btn" (click)="openEditHomePopup(2)">{{
            "Edit" | translate
          }}</span>
        </div>
      </section>

      <!-- Swap and HS Point  -->
      <section
        class="token_cash_section"
        [appLoad]="priceLoading"
        *ngIf="space.purpose_id == 522 && space.payment_type_id == 562"
      >
        <h4 class="card_title">
          {{ "You’re currently using your listings to" | translate }}
          <span class="highlight_orange">{{ "Swap" | translate }}</span>
          {{ "& earn" | translate }}
          <span class="highlight_orange">{{ "HS Points" | translate }}</span
          >.
        </h4>
        <div class="card_footer" [appLoad]="priceLoading">
          <p class="card_text">
            {{ "Want to edit how you’re using your listing?" | translate }}
          </p>
          <span class="edit_btn" (click)="openEditHomePopup(2)">{{
            "Edit" | translate
          }}</span>
        </div>
      </section>

      <!--  Money  -->
      <section
        class="token_cash_section"
        [appLoad]="priceLoading"
        *ngIf="space.purpose_id == 520 && space.payment_type_id == 561"
      >
        <h4 class="card_title">
          {{ "You’re currently using your listings to earn" | translate
          }}<span class="highlight_orange"> {{ "Money" | translate }}</span
          >.
        </h4>
        <div class="card_footer" [appLoad]="priceLoading">
          <p class="card_text">
            {{ "Want to edit how you’re using your listing?" | translate }}
          </p>
          <span class="edit_btn" (click)="openEditHomePopup(2)">{{
            "Edit" | translate
          }}</span>
        </div>
      </section>

      <!--  HS Point  -->
      <section
        class="token_cash_section"
        [appLoad]="priceLoading"
        *ngIf="space.purpose_id == 520 && space.payment_type_id == 562"
      >
        <h4 class="card_title">
          {{
            "You’re currently using your listings
                    to earn" | translate
          }}
          <span class="highlight_orange">{{ "HS Points" | translate }}</span
          >.
        </h4>
        <div class="card_footer" [appLoad]="priceLoading">
          <p class="card_text">
            {{ "Want to edit how you’re using your listing?" | translate }}
          </p>
          <span class="edit_btn" (click)="openEditHomePopup(2)">{{
            "Edit" | translate
          }}</span>
        </div>
      </section>

      <div class="token_wrapper" *ngIf="space.purpose_id != 521">
        <h2 class="token_header">
          {{ "Estimated monthly earnings" | translate }}
        </h2>
        <div
          class="token_container"
          [appLoad]="priceLoading"
          *ngIf="space.payment_type_id == 562 && space.purpose_id != 521"
        >
          <div class="left">
            $ <span>{{ space?.hosting_tokens }}</span>
          </div>
          <div class="right">
            <span>{{
              space?.hosting_tokens > 1
                ? ("HS Points" | translate)
                : ("HS Point" | translate)
            }}</span>
            <span>{{ "per night" | translate }}</span>
          </div>
        </div>
        <div
          class="token_container"
          [appLoad]="priceLoading"
          *ngIf="space.payment_type_id == 561 && space.purpose_id != 521"
        >
          <div class="left">
            $ <span>{{ space?.hosting_amount | number }}</span>
          </div>
          <div class="right">
            <span>{{ "USD" | translate }}</span>
            <span>{{ "per night" | translate }}</span>
          </div>
        </div>
        <div
          class="potential_wrapper"
          [appLoad]="priceLoading"
          *ngIf="space.purpose_id != 521"
        >
          <div class="left">
            $ <span>{{ this.spacePotential }}</span>
          </div>
          <div class="right">
            <span>{{ "Potential" | translate }}</span>
            <span *ngIf="!this.isNextMonth">{{
              "this month" | translate
            }}</span>
            <span *ngIf="this.isNextMonth">{{
              "upcoming month" | translate
            }}</span>
          </div>
        </div>
        <div class="token_footer">
          <p>
            {{ "Edit how much you’re getting paid." | translate }}
          </p>
          <span class="edit_btn" (click)="openEditHomePopup(9)">
            {{ "Edit" | translate }}
          </span>
        </div>
      </div>

      <div class="amenities_block_btn_wrapper token_btn show-all">
        <button class="block_btn pe-none">
          {{ "See full earnings breakdown" | translate }}
        </button>
      </div>
    </div>
  </section>

  <section class="overview_section">
    <div
      class="edit-card"
      [appLoad]="priceLoading"
      (click)="openEditHomePopup(11)"
    >
      <div class="card-heading">
        {{ "Overview" | translate }}
      </div>
      <div class="overview-desc">{{ space?.description }}</div>
      <div class="home_overview">
        <p>{{ "Beds, Bathrooms & Guests" | translate }}*</p>
        <div>
          <span class="allowance_desc"
            ><img src="../../../../assets/images/bad_bl.svg" alt="" />{{
              space.bedrooms
            }}</span
          >
          <span class="allowance_desc"
            ><img src="../../../../assets/images/shower_bl.svg" alt="" />{{
              space.bathrooms
            }}</span
          >
          <span class="allowance_desc"
            ><img src="../../../../assets/images/user_bl.svg" alt="" />
            {{ space?.guests > 0 ? ("Up to" | translate) : " " }}
            {{ space.guests }}
            {{
              space?.guests <= 0
                ? ""
                : space?.guests == 1
                ? ("guest" | translate)
                : ("guests" | translate)
            }}</span
          >
        </div>
      </div>
      <div class="navigation-icon">
        <svg
          width="8"
          height="30"
          viewBox="0 0 6 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.560383 10.2103C0.391659 10.0228 0.296875 9.7685 0.296875 9.50334C0.296875 9.23817 0.391659 8.98386 0.560383 8.79634L3.52408 5.50334L0.560383 2.21034C0.396441 2.02173 0.305725 1.76913 0.307776 1.50694C0.309827 1.24474 0.404479 0.993927 0.571346 0.808519C0.738213 0.62311 0.963945 0.517941 1.19992 0.515663C1.4359 0.513384 1.66324 0.614179 1.83298 0.796337L5.43298 4.79634C5.60171 4.98386 5.69649 5.23817 5.69649 5.50334C5.69649 5.7685 5.60171 6.02281 5.43298 6.21034L1.83298 10.2103C1.66421 10.3978 1.43533 10.5031 1.19668 10.5031C0.958035 10.5031 0.729158 10.3978 0.560383 10.2103Z"
            fill="#23262F"
          />
        </svg>
      </div>
    </div>
  </section>

  <section class="amenities_section">
    <div class="title">
      <p>{{ "Amenities" | translate }}</p>
      <span class="edit_btn" (click)="openEditHomePopup(6)">{{
        "Edit" | translate
      }}</span>
    </div>
    <div
      class="features_check_outer pe-none allAmenities1"
      [appLoad]="priceLoading"
      [class.no-amenities]="totalSelectedAmenities <= 0"
    >
      <form id="amenitiesForm1">
        <h5 class="featuresTitle" *ngIf="categories?.amenities?.length > 0">
          {{ "Features" | translate }}
        </h5>
        <ng-container
          *ngFor="let amenities of categories.amenities; let i = index"
        >
          <div class="featureCheckBox">
            <input
              type="checkbox"
              [id]="'aminti' + i"
              [value]="amenities.id"
              [checked]="amenities.isActive"
            />
            <label [for]="'aminti' + i">{{ amenities.name | translate }}</label>
          </div>
        </ng-container>

        <h5 class="featuresTitle" *ngIf="categories?.rules?.length > 0">
          {{ "Rules" | translate }}
        </h5>
        <ng-container *ngFor="let rules of categories.rules; let i = index">
          <div class="featureCheckBox">
            <input
              type="checkbox"
              [id]="'rule' + i"
              [value]="rules.id"
              [checked]="rules.isActive"
            />
            <label [for]="'rule' + i">{{ rules.name | translate }}</label>
          </div>
        </ng-container>

        <h5
          class="featuresTitle"
          *ngIf="categories?.accessibilities?.length > 0"
        >
          {{ "Accessibilities" | translate }}
        </h5>
        <ng-container
          *ngFor="
            let accessibilities of categories.accessibilities;
            let i = index
          "
        >
          <div class="featureCheckBox">
            <input
              type="checkbox"
              [id]="'access' + i"
              [value]="accessibilities.id"
              [checked]="accessibilities.isActive"
            />
            <label [for]="'access' + i">{{
              accessibilities.name | translate
            }}</label>
          </div>
        </ng-container>

        <h5 class="featuresTitle" *ngIf="categories?.safeties?.length > 0">
          {{ "health" | translate }} & {{ "safety" | translate }}
        </h5>
        <ng-container
          *ngFor="let safeties of categories.safeties; let i = index"
        >
          <div class="featureCheckBox">
            <input
              type="checkbox"
              [id]="'safeties' + i"
              [value]="safeties.id"
              [checked]="safeties.isActive"
            />
            <label [for]="'safeties' + i">{{
              safeties.name | translate
            }}</label>
          </div>
        </ng-container>

        <ng-container *ngIf="totalSelectedAmenities <= 0">
          <p>{{ "There are no amenities to show." | translate }}</p>
        </ng-container>
      </form>
    </div>
    <div
      class="amenities_block_btn_wrapper"
      [class.show-all]="showAllAmenities"
      *ngIf="!hideAmenitiesButton && totalSelectedAmenities > 0"
    >
      <div class="shadow_box"></div>
      <button class="block_btn" (click)="toggleAmenities()">
        {{
          this.showAllAmenities
            ? ("See fewer options" | translate)
            : ("Show more amenities" | translate)
        }}
      </button>
    </div>
  </section>

  <section
    class="share-home-section"
    [class.ios-fix]="checkIOS"
    *ngIf="space?.id"
  >
    <app-social-share-button
      [homeId]="spaceId"
      [imgUrl]="_albums[selectedCoverImage]?.src"
      [shareMessage]="shareMessage + '\n' + space?.deeplink_url"
      [deeplinkUrl]="space?.deeplink_url"
    ></app-social-share-button>
  </section>
</div>

<app-loading [loading]="loading"></app-loading>

<!-- add home form popup -->

<div class="add-home-form-modal edit-modal" *ngIf="openAddHomeForm">
  <div class="close-section">
    <div class="skip-and-close" [hidden]="activeStep == 11 || activeStep == 6">
      <span class="close-popup" (click)="editSpaceModal.exitClick()">
        <img alt="close icon" src="/assets/images/cancel_ic.svg"
      /></span>
    </div>
  </div>

  <div class="add_home_sheet h-100" [class.dark-theme]="darkTheme">
    <app-edit-home-modal
      #editSpaceModal
      (reOpenModal)="editSpaceModal.exitClick(); reOpenModal($event)"
      (exitModal)="closeEditFormPopup()"
      (amenitiesList)="amenitiesFormPopup()"
      [darkTheme]="darkTheme"
      [editData]="editHomeData"
      [activeStep]="activeStep"
    ></app-edit-home-modal>
    <!-- [inputstarted]="inputstarted" [disableOne]="!disableOne" -->
  </div>
</div>

<div *ngIf="openAddHomeForm" class="bix__modal-backdrop"></div>

<div *ngIf="popupbackdrop" class="bix__modal-backdrop"></div>

<div class="success-popup" *ngIf="popupbackdrop">
  <div class="icon_container">
    <svg
      width="106"
      height="106"
      viewBox="0 0 106 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="42.8594"
        cy="43.6328"
        r="40.8594"
        stroke="white"
        stroke-width="4"
      />
      <path
        d="M71.7031 69.8375L82.6235 80.5061"
        stroke="white"
        stroke-width="2"
      />
      <path
        d="M82.6406 80.4854L99.9267 97.0507"
        stroke="white"
        stroke-width="6"
        stroke-linecap="round"
      />
      <path
        d="M54.4302 23.3416L34.7239 41.1922H51.3187L31.0938 60.8153"
        stroke="#FFA300"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
  <div class="details_container">
    <span class="close-btn" (click)="closePopup()">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.822887 0.327359C1.2534 -0.10912 1.95132 -0.10912 2.38182 0.327359L8.21602 6.24236L14.0502 0.327359C14.4807 -0.10912 15.1786 -0.10912 15.6091 0.327359C16.0395 0.763838 16.0395 1.47142 15.6091 1.90789L9.77495 7.82289L15.6091 13.7379C16.0395 14.1744 16.0395 14.8819 15.6091 15.3184C15.1786 15.7547 14.4807 15.7547 14.0502 15.3184L8.21602 9.40341L2.38182 15.3184C1.95131 15.7547 1.25339 15.7547 0.822887 15.3184C0.392371 14.8819 0.392371 14.1743 0.822887 13.7379L6.65708 7.82289L0.822887 1.90789C0.392371 1.47141 0.392371 0.763824 0.822887 0.327359Z"
          fill="#FFFFFF"
        />
      </svg>
    </span>
    <!-- <p class="header">{{"Your purchase has been" | translate}} <span class="highlight">{{"made" | translate}}!</span></p> -->
    <p class="desc">
      {{
        "We see you that you’re looking for gold at the top of the searched listings! Just want to confirm this"
          | translate
      }}
      <span class="bold">{{ "search boost" | translate }}!</span>
    </p>
    <div class="success-button">
      <button class="cancel-button" (click)="closePopup()">Cancel</button>
      <button class="confirm-button" (click)="boostSpace()">Confirm</button>
      <!-- {{"Keep exploring" | translate}} -->
    </div>
  </div>
</div>
