import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ExploreSpacesService } from 'src/app/shared/services/explore-spaces.service';
import { LanguageService } from 'src/app/shared/services/language.service';

@Component({
  selector: 'app-trending-region',
  templateUrl: './trending-region.component.html',
  styleUrls: ['./trending-region.component.scss']
})
export class TrendingRegionComponent implements OnInit {
  loading: boolean = false;
  featuredLocations: any = [];
  public address: any = {};
  selectedLanguage = 'en';
  public searchedLocation: any = {};
  arb = false;

  items = [
		{
			name: 'home',
			link: '/'
		},
		{
			label: 'Trending Regions'
		}
	]

  constructor(
    private exploreService: ExploreSpacesService,
    private cookieService: CookieService,
    private router: Router,
    private languageService: LanguageService
  ) {
    this.getFeaturedLocations();
  }

  ngOnInit(): void {
    let loadLang = this.languageService.GetLanguage() + '';
    if (loadLang == "null" || loadLang == "") this.selectedLanguage = "en";
    else this.selectedLanguage = loadLang;

    this.languageService.languageUpdates.subscribe(
    (lang) => {
      this.selectedLanguage = lang;

      if(this.selectedLanguage == 'ar') {
        this.arb = true;
      }
      else {
        this.arb = false;
      }
    });
  }

  public getFeaturedLocations() {
		this.loading = true;

		this.exploreService.getFeaturedLocations().subscribe((res: any) => {
			this.loading = false;

			this.featuredLocations = res.data;

			// this.featuredRegionstSpacesEl.update();

			// this.cd.detectChanges();

		}, (err) => {
			this.loading = false;


		}).add(() => {
		});
	}




  searchResults(regionCity,longitude,latitude) {
    this.address.city_name = regionCity;
    this.address.longitude = longitude;
    this.address.latitude = latitude;
    localStorage.removeItem('searchFilters');
    this.cookieService.delete('search-address');
    this.cookieService.delete('searchFilter');
    this.cookieService.set('search-address', JSON.stringify(this.address));
    localStorage.setItem("searchlocation",JSON.stringify(this.address));
    this.saveHistory();
    this.router.navigate(["/map-view-list"]);


  }

  public saveHistory() {
    this.searchedLocation = {...this.address, country : this.address?.city_name};

    let obj = {...this.searchedLocation};
    this.exploreService.saveSearchHistory(obj).subscribe(res=>{});

    }


}
