import {
  Component,
  OnInit,
  HostListener,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { ExploreFilterModalComponent } from '../explore-filter-modal/explore-filter-modal.component';
import { ExploreSpacesService } from 'src/app/shared/services/explore-spaces.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-explore-navigation',
  templateUrl: './explore-navigation.component.html',
  styleUrls: ['./explore-navigation.component.scss'],
})
export class ExploreNavigationComponent implements OnInit {
  categories = [];
  activeCategory = 0;
  @Input() hiddenTitle: boolean = false;
  @Output() loadMoreByCategory = new EventEmitter();
  public isDesktopView: boolean = window.screen.width > 1024;
  @HostListener('window:resize', ['$event'])
  handleResize(event: KeyboardEvent) {
    this.isDesktopView = window.screen.width > 1024;
  }
  @Output() filter = new EventEmitter();

  @ViewChild('filterModal') filterModal: ExploreFilterModalComponent;

  @ViewChild('scrollDiv') scrollDiv: ElementRef;

  constructor(
    private exploreSpacesService: ExploreSpacesService,
    public router: Router
  ) {
    this.exploreSpacesService
      .getTopHundredCategories()
      .subscribe((res: any) => {
        this.categories = res.data.map((item) => ({
          id: item.id,
          src: item.categoryIcon,
          text: this.getCategory(item.categoryTitle),
        }));

        this.categories.unshift({
          src: '/assets/icons/Style_All.svg',
          text: 'All',
          id: 0,
        });
      });
  }

  ngOnInit(): void {}

  clickCategoryItem(id) {
    this.activeCategory = id;

    document
      .getElementById('card-list-homes')
      ?.scrollTo({ top: 0, behavior: 'smooth' });
    document
      .getElementById('mainOuter')
      ?.scrollTo({ top: 100, behavior: 'smooth' });
    this.loadMoreByCategory.emit(id || null);
  }

  @HostListener('document:scroll', ['$event'])
  public onViewportScroll() {}

  getCategory(fullCategory: string): string {
    return fullCategory.split(' ')[0];
  }

  skeletonCategory() {
    return new Array(15).fill(1);
  }

  openFilterModal() {
    this.filterModal.open();
  }

  closeFilterModal() {
    this.filterModal.close();
  }

  controlScrolling() {
    const scrollLeft = this.scrollDiv.nativeElement.scrollLeft;
    this.scrollDiv.nativeElement.scrollTo({
      top: 0,
      left: scrollLeft + 20,
    });
  }
}
