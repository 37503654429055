import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subscriber } from "rxjs";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Cacheable } from "ts-cacheable";
import { HttpService } from "./http.service";
import { StripeService } from "./stripe.service";

@Injectable({
	providedIn: "root"
})
export class TokenService {
	queryParams: any = {
		token_price: null,
		tokens: null
	}

	constructor(private httpService: HttpService, private stripeService: StripeService, private http: HttpClient) { }


	@Cacheable()
	public getBundles() {
		return this.httpService.get(`/tokens`);
	}

	public purchase(data: { bundle_id?: number, payment_method?: string }) {
		return new Observable((observer: Subscriber<{ status: boolean }>) => {
			this.httpService.post(`/users/me/tokens/payment-intent`, data).subscribe((response: any) => {

				if (!response.requires_action) {
					observer.next({
						status: true,
					});
					observer.complete();
					return;
				}

				this.stripeService.stripe.confirmCardPayment(response.data.client_secret).then((result) => {
					if (result.error) {
						observer.error(result.error.message);
					}
					this.httpService.post(`/users/me/tokens`, {
						bundle_id: data.bundle_id,
					}).subscribe(() => {
						observer.next({
							status: true,
						});
					}, (err) => {
						observer.error(err);
					}).add(() => {
						observer.complete();
					});
				});
			});
		});

		//return this.stripeService.payment2(data);
		//return this.httpService.post(`/users/me/tokens`, data);
	}

	public addTokens(data: { bundle_id: number }) {
		return this.httpService.post(`/users/me/tokens`, data);
	}

	public bonusTokens(entity_type, action_id, entity_id, amount, description) {

		// let random_entity_id = (Math.floor(Math.random() * 1000) + 1);

		let tokenType = {
			'user_id': localStorage.getItem("id"),
			'entity_type': entity_type,
			'entity_id': entity_id,
			'action_id': action_id,
			'amount': amount,
			'description': description,
		};
		return this.httpService.post(`/add_bonus_tokens`, tokenType);
	}

	public shareTokens(entity_type, action_id, entity_id, amount, description) {

		let tokenType = {
			"user_id": localStorage.getItem("id"),
			"entity_type": entity_type,
			"entity_id": entity_id,
			"action_id": action_id,
			"amount": amount,
			"description": description,
		  }
		return this.httpService.post(`/social-share`, tokenType);
	}

	public bonusTokensForFriend(entity_type, action_id, entity_id, amount, description) {

		// let random_entity_id = (Math.floor(Math.random() * 1000) + 1);
		let selectedFriend = JSON.parse(localStorage.getItem("selectedFriend"));

		let tokenType = {
			'user_id': selectedFriend.id,
			'entity_type': entity_type,
			'entity_id': entity_id,
			'action_id': action_id,
			'amount': amount,
			'description': description,
		};
		return this.httpService.post(`/add_bonus_tokens`, tokenType);
	}

	public geLocationInfo() {

		//return this.http.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${Longitude},${Latitude}.json?access_token=pk.eyJ1IjoiaG9saWRheXN3YXAiLCJhIjoiY2t2YXltc3prMDcxYzJvcW5lbmh6Nmg3OSJ9.Gp8bk7jP4l5Pzliq9iyF3A`);
		return this.http.get(`https://open.er-api.com/v6/latest/USD`);
		//return this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${Latitude},${Longitude}&key=pk.eyJ1IjoiaG9saWRheXN3YXAiLCJhIjoiY2t2YXltc3prMDcxYzJvcW5lbmh6Nmg3OSJ9.Gp8bk7jP4l5Pzliq9iyF3A`);
	}

}
