import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SpaceService } from 'src/app/shared/services/space.service';

@Injectable({
  providedIn: 'root'
})
export class SwipeGuard implements CanActivate, CanActivateChild {

  constructor(private spaceService: SpaceService, private router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
     return this.spaceService.getMySpaces().pipe(map((homes: any) => {

      let canRoute = (homes.filter(home => home.purpose != 'host').length > 0);
      if(!canRoute) this.router.navigateByUrl('/')
      return canRoute;

     }
     ));
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      // return this.spaceService.getMySpaces().pipe(map((homes: any) => (homes.filter(home => home.purpose != 'host').length > 0)));
      return this.spaceService.getMySpaces().pipe(map((homes: any) => {

        let canRoute = (homes.filter(home => home.purpose != 'host').length > 0);
        if(!canRoute) this.router.navigateByUrl('/')
        return canRoute;
  
       }
       ));
  }
  
}
