<!-- <div class="app-logo">
	<img src="/assets/app-logo.svg" />
</div> -->
<!-- header start -->
<!-- <div class="brand_header">
  <app-notification-header> </app-notification-header>
</div> -->
<!-- header end -->

<div class="background_wrapper">
  <div class="logo_header">
    <div class="intro_brand" routerLink="/">
      <img src="/assets/new-logo/app-logo-dark.svg" alt="">
      <!-- <img src="/assets/images/onboard/logo.svg"> -->
      <!-- <span class="brand_title">Holiday Swap</span> -->
    </div>

    <div>
      <app-language-selector></app-language-selector>
    </div>
  </div>
  <div class="reset_container">

    <div class="reset_wrapper">

      <app-panel *ngIf="step === 1" class="m-t-20 m-b-20" background="green" textColor="white">
        Password reset link was sent to {{ user.email }} follow directions in the
        email to complete your
        password reset
      </app-panel>

      <app-panel *ngIf="error?.length > 0" class="m-t-20 m-b-20" background="#bd4239" textColor="white">
        {{ error }}
      </app-panel>

      <ng-container *ngIf="step === 0">

        <form (ngSubmit)="onReset()" ngNativeValidate>
          <div class="form-header text-center">

            <div class="text-lead">{{ "Forgot your password?" | translate }}</div>
            <div class="text-meta">
              {{ "No worries! Type your email below and we will send you a link to reset your password." | translate }}
            </div>

          </div>
          <div class="input-form-field">
            <!-- <div class="input-group-addon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                  class="ai ai-Envelope">
                  <path d="M2 6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6z" />
                  <path d="M2 8l7.501 6.001a4 4 0 0 0 4.998 0L22 8" />
                </svg>
              </div> -->

            <input required class="input" [(ngModel)]="user.email" placeholder="{{'Enter your email' | translate}}"
                   type="email" name="email" id="email" autocomplete="off" required>
          </div>

          <div class="m-t-20">
            <button class="request-password-btn btn_transparant">
              {{ "Request Password" | translate }}
            </button>

            <!-- <app-button block="true" textColor="#fff" background="#000">{{"Request Password" | translate}}
              </app-button> -->

          </div>
        </form>

        <!--        <div class="sign-in-up">-->
        <!--          <div class="login_link">-->
        <!--            <p>{{"Don't have an account?" | translate}}</p>-->
        <!--            <button class="request-password-btn btn_transparant" routerLink="/auth/signup"> {{"Sign up" | translate}}</button>-->
        <!--          </div>-->
        <!--          <div class="login_link">-->
        <!--            <p>{{"Already have an account?" | translate}}</p>-->
        <!--            <button type="button" class="request-password-btn btn_transparant" routerLink="/auth/login">{{"Sign In" | translate}}</button>-->
        <!--          </div>-->
        <!--        </div>-->
      </ng-container>

      <!-- ############################################################################################# -->
      <!-- ############################################################################################# -->

      <ng-container *ngIf="step === 2">
        <form (ngSubmit)="onSubmit()" ngNativeValidate>
          <div class="form-header text-center">

            <div class="text-lead text-lead-override">{{ "Enter new password" | translate }}</div>
            <!-- <div class="text-meta">
                Update your password below.
              </div> -->

          </div>

          <!-- <div class="form-item">
              <label>email</label>

              <div class="input-group">
                <div class="input-group-addon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                    class="ai ai-LockOn">
                    <rect x="3" y="10" width="18" height="12" rx="2" />
                    <path d="M6 6a3 3 0 0 1 3-3h6a3 3 0 0 1 3 3v4H6V6z" />
                  </svg>
                </div>
                <input required class="input" [(ngModel)]="userEmail.email" placeholder="Email" type="email"
                  name="email" id="email" autocomplete="off" disabled>
              </div>
            </div> -->

          <div class="form-item">
            <!-- <label>{{ "Password" | translate }}</label> -->

            <div class="input-form-field lady-input password-form">
              <!-- <div class="input-group-addon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                    class="ai ai-LockOn">
                    <rect x="3" y="10" width="18" height="12" rx="2" />
                    <path d="M6 6a3 3 0 0 1 3-3h6a3 3 0 0 1 3 3v4H6V6z" />
                  </svg>
                </div> -->

              <input required [(ngModel)]="user.password" class="input" placeholder="{{'Password' | translate}}"
                     type="{{hidePass ? 'password' : 'text'}}" name="password" id="password" autocomplete="off">
              <span class="show-password" (click)="toggleHidePassword()">
                  <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="hidePass"></i>
                  <i class="fa fa-eye" aria-hidden="true" *ngIf="!hidePass"></i>
              </span>
            </div>

            <h6 class="password-suggestion" *ngIf="baseUrl == 'https://app.holidayswap.com'">
              {{ "Password suggestion: Minimum 8 characters at least containing one digit number" | translate }}
            </h6>

          </div>

          <div class="form-item">
            <!-- <label for="confirm_password">Confirm password</label> -->

            <div class="input-form-field input-form-field-override password-form">
              <!-- <div class="input-group-addon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                    class="ai ai-LockOn">
                    <rect x="3" y="10" width="18" height="12" rx="2" />
                    <path d="M6 6a3 3 0 0 1 3-3h6a3 3 0 0 1 3 3v4H6V6z" />
                  </svg>
                </div> -->

              <input required [(ngModel)]="user.confirm_password" class="input"
                     placeholder="{{'Confirm password' | translate}}"
                     type="{{hideConfirmPass ? 'password' : 'text'}}" autocomplete="current-password"
                     name="confirm_password" id="password" autocomplete="off">
              <span class="show-password" (click)="toggleHideConfirmPassword()">
                  <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="hideConfirmPass"></i>
                  <i class="fa fa-eye" aria-hidden="true" *ngIf="!hideConfirmPass"></i>
                </span>
            </div>
          </div>

          <div class=" m-t-20 m-b-20">
            <button class="request-password-btn">{{ "Update password" | translate }}
            </button>
          </div>
        </form>
      </ng-container>

      <!-- ############################################################################################# -->
      <!-- ############################################################################################# -->


      <ng-container *ngIf="step === 3">
        <form (ngSubmit)="onReset()" ngNativeValidate>
          <div class="form-header text-center">

            <div class="text-lead text-lead-override">{{ "Check your email!" | translate }}</div>
            <div class="text-meta">
              {{ "We just sent you a password reset link. Follow the directions in your email to sign into your account." | translate }}
            </div>
          </div>

          <div class="m-t-20">
            <button class="request-password-btn btn-margin-top" routerLink="/auth/login">
              {{ "Sign In" | translate }}
            </button>
          </div>
        </form>
      </ng-container>


    </div>

  </div>
</div>


<app-loading [absolute]="true" [loading]="loading"></app-loading>
