import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LanguageService } from 'src/app/shared/services/language.service';
import { MapboxService } from "src/app/shared/services/mapbox.service";

export function debounce(delay: number = 300): MethodDecorator {
	return (_target: any, _propertyKey: string, descriptor: PropertyDescriptor) => {
		let timeout = null;

		const original = descriptor.value;

		descriptor.value = function (...args) {
			clearTimeout(timeout);
			timeout = setTimeout(() => original.apply(this, args), delay);
		};

		return descriptor;
	};
}

@Component({
	selector: 'app-search-address-box',
	templateUrl: './search-address-box.component.html',
	styleUrls: ['./search-address-box.component.scss']
})
export class SearchAddressBoxComponent implements OnInit {
	selectedLanguage = "en";
	public locations = [];
	@Input() public location: any = {};
	@Output() public locationChange = new EventEmitter();
	@Output() public onSearchSpaces = new EventEmitter();
	@Output() public onSearchListViewSpaces = new EventEmitter();

	constructor(private mapboxService: MapboxService, private languageService: LanguageService) { }

	ngOnInit(): void {
		this.languageService.languageUpdates.subscribe(
			(lang) => {

				this.selectedLanguage = lang;
			}
		)

		this.selectedLanguage = this.languageService.GetLanguage();
	}


	public onSelect(location) {
		this.locationChange.emit(location);
		this.locations = [];
		this.location = location;
	}
	@debounce(300)
	public autoCompleteAddress(query: string) {
		if (query.length > 1) {
			this.mapboxService.search(query).subscribe((response: any) => {
				this.locations = response.features.map((feature) => this._parseResult(feature));
			});

		} else {
			this.locations = [];
		}
	}

	private _parseResult(feature) {
		const address: any = {};
		if (feature.place_type[0] == 'place') {
			for (const result of feature.context) {
				if (result.id.includes("postcode")) {
					address.postcode = result.text;
				}
				if (result.id.includes("locality")) {
					address.line_2 = result.text;
				}
				if (result.id.includes("place")) {
					address.city = result.text;
				}
				if (result.id.includes("district")) {
					address.county = result.text;
				}
				if (result.id.includes("country")) {
					address.country = result.text;
				}

				if (result.id.includes("postcode")) {
					address.postcode = result.text;
				}
			}
		}
		// First line of address
		if (feature.text || feature.address) {
			address.street = (feature.address ? feature.address + " " : "") + (feature.text ? feature.text : "");
		}

		if (feature.id.includes("place")) {
			address.city = feature.text;
		}
        if (feature.id.includes("region")) {
            address.city = feature.text;
        }
		// Full Address
		if (feature.place_name) {
			address.full_address = feature.place_name;
		}
		// Coordinates
		if (feature.center) {
			address.longitude = feature.center[0];
			address.latitude = feature.center[1];
		}

		return address;

	}

	public searchSpaces() {
		this.onSearchSpaces.emit();
	}

	public searchListViewSpaces() {
		this.onSearchListViewSpaces.emit();
	}


	public onClickSearchBox(location) {
		this.locationChange.emit(location);
		this.locations = [];
		this.location = location;
	}

	crossClickRemove() {
		this.location = {};
		this.locations = [];

	}

}
