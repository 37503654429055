import { Component, OnInit , ChangeDetectorRef, EventEmitter, Input, ViewChild } from '@angular/core';

import { SwiperOptions } from "swiper";
import { SwiperComponent } from "swiper/angular";
import { CookieService } from 'ngx-cookie-service';
import { Router } from "@angular/router";
import { ClevertapService } from 'src/app/shared/services/clevertap.service';

declare var $: any;

@Component({
  selector: 'app-featured-regions',
  templateUrl: './featured-regions.component.html',
  styleUrls: ['./featured-regions.component.scss']
})
export class FeaturedRegionsComponent implements OnInit {

   public address: any = {};

  @Input() public featuredLocations = [];
  public config: SwiperOptions = {
		direction: "horizontal",
		slidesPerView: 2.3,
		spaceBetween: 20,
		keyboard: true,
		mousewheel: true,
		scrollbar: false,
		navigation: false,
		pagination: false,
		virtual: true,
		preloadImages: false,


		breakpoints: {
			640: {
				slidesPerView: 3,
			},
			1024: {
				slidesPerView: 6.5,
			},
			1800: {
				slidesPerView: 8.5,
			}
		}
	};
	private _prevIndex;
  userEmail;
  public isRegion=true;
  @ViewChild("swiper", { static: false }) swiper?: SwiperComponent;

  constructor(private cd: ChangeDetectorRef,
      private cookieService: CookieService,
      private router: Router,
      private clevertapService: ClevertapService) { }

  ngOnInit(): void {
    let userDatastorage = localStorage.getItem("userData");
    let userData = JSON.parse(userDatastorage);
	if(userData) {
		this.userEmail = userData.email;
	}

  }

  public onSlideChange() {
		const space = this.featuredLocations[this.swiper.swiperRef.activeIndex];
	//	this.onSlideChanged.emit(space);

		this._prevIndex = this.swiper.swiperRef.activeIndex;
		if (this.featuredLocations.length === this.swiper.swiperRef.activeIndex + 1) {

		//	this.onEnd.emit();

			$(".trending_widget_main .swiper-slide ").addClass("dynamic-swipe-style");
		}

		else {

			$(".trending_widget_main .swiper-slide ").removeClass("dynamic-swipe-style");
		//	this.isLastSpace = false;
		}

	}

  public update() {
		this.swiper.swiperRef.updateSlides();
		this.swiper.swiperRef.updateSize();

		this.cd.detectChanges();

  }

  searchResults(regionCity) {
	this.address.city_name = regionCity;
	this.cookieService.delete('search-address');
	// if(this.address.full_address)
	// {
	// 	this.cookieService.delete('search-address');

	// 	this.cookieService.set('search-address', JSON.stringify(this.address));
	// this.router.navigate(["/map-view-list"]);
	// }
	// else {

	// }
	this.cookieService.set('search-address', JSON.stringify(this.address));
	this.router.navigate(["/map-view-list"]);

}

  public nextSpaceArrow()
	{
		this.swiper.swiperRef.slideNext(100);
	}

	public previousSpaceArrow()
	{
		this.swiper.swiperRef.slidePrev(100);
  }

  onImgError(event) {
	event.target.src = 'assets/images/default-cover.jpg';
}

}
