import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";

@Component({
	selector: "app-auth-layout",
	templateUrl: "./auth-layout.component.html",
	styleUrls: ["./auth-layout.component.scss"],

})
export class AuthLayoutComponent implements OnInit {
	public subscription: Subscription;
	public canGoBack = true;
	public routesWithoutNavigation: string[] = ['/auth/intro', '/auth/login', '/auth/signup', '/auth/reset', '/auth/explore-spaces']
	constructor(private router: Router) { }

	ngOnInit(): void {
		this.canGoBack = this.isNavigateBackAvailable();
		
		this.subscription = this.router.events.subscribe((val) => {
			if (val instanceof NavigationEnd) {
				this.canGoBack = this.isNavigateBackAvailable();
			}
		});
	}

	isNavigateBackAvailable() {
		return !this.routesWithoutNavigation.some(r => this.router.url.includes(r))
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

}
