<div class="background_wrapper">
    <div class="app_container">
        <div class="content_area">
            <div class="page-title">
                <h1>{{"Forgot your password?" | translate}}</h1>
                <p>{{"No worries! Type your email below and we will send you a link to reset your password" | translate}}
                </p>
            </div>
            <div class="plans_access">
                <div class="form-field">
                    <input type="text" placeholder="{{'Enter your email' | translate}}">
                </div>
                <button type="button" class="btn_large" data-bs-toggle="modal" data-bs-target="#signup_finish">{{"Request password" | translate}}</button>
            </div>
        </div>
    </div>
</div>
