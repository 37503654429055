<!-- header start -->
<!-- <div class="brand_header">
    <app-notification-header> </app-notification-header>
</div> -->
<!-- header end -->


<div class="background_wrapper">

    <div class="logo_header">
        <div class="intro_brand">
            <img src="/assets/new-logo/app-logo-dark.svg" alt="">
            <!-- <img alt="" src="/assets/images/onboard/logo.svg"> -->
            <!-- <span class="brand_title">Holiday Swap</span> -->
        </div>

        <div>
            <app-language-selector></app-language-selector>
        </div>
    </div>
    <div class="app_container_register" [class.longForm]="activeStepper == 1">

        <div class="content_area" *ngIf="activeStepper == 0">
            <!-- <app-panel *ngIf="error?.length > 0" class="m-t-20 m-b-20" background="#bd4239" textColor="white">
                {{error}}
            </app-panel> -->
            <div class="page-title">
                <h1>{{"Sign up for free" | translate}}<br> {{"and start exploring" | translate}} </h1>
            </div>
            <div class="plans_access">
                <div class="form-field">
                    <label>{{"Start by entering your email address" | translate}}</label>
                    <input type="email" [(ngModel)]="user.email" (ngModelChange)="emailValidation()" placeholder="{{'Enter your email' | translate}}" required>
                    <p class="red-alert" *ngIf="submitted && !emailBlank"> {{"Please enter email" | translate}}</p>
                    <p class="red-alert" *ngIf="continueClicked && !emailBlank"> {{"Please enter email valid email with atleast 7 characters." | translate}}</p>
                    <p class="red-alert" *ngIf="error?.length > 0">
                        {{ error }}
                    </p>
                </div>
                <button type="button" class="btn_large btn_transparant" (click)="openSignUpPopup()" [disabled]="!terms">{{"Continue" | translate}}</button>
              <div class="tc_check">
                <div class="form-check checkbox-wrapper">
                  <input class="form-check-input" [(ngModel)]="terms"
                         [ngModelOptions]="{standalone: true}" type="checkbox" id="flexCheckChecked"
                         checked>
                  <label class="form-check-label agree_text" for="flexCheckChecked">
                    {{"I agree to the Holiday Swap ." | translate}}
                    <a class="terms-url" href="https://www.holidayswap.com/terms-of-service" target="_blank">
                      {{"Terms of Service" | translate}}
                    </a>
                  </label>
                </div>
              </div>
            </div>

            <div class="form-divider">
                <p>{{"or continue with" | translate}}</p>
            </div>

            <!-- <div class="plans-area">
            <div class="payment_btn gg_btn">
                <button type="button"><i class="fa fa-google" aria-hidden="true"></i>&nbsp;Continue with Google</button>
            </div>
            <div class="payment_btn fb_btn">
               <button type="button"><i class="fa fa-facebook-square" aria-hidden="true"></i>&nbsp;Continue with Facebook</button>
           </div>
           <div class="payment_btn ap_btn">
               <button type="button"><i class="fa fa-apple" aria-hidden="true"></i>&nbsp;Continue with Apple</button>
           </div>
           <div class="login_link">
               <p>Already have an account?<a href="#"> Sign in</a></p>
           </div>
        </div> -->

            <app-social-sign-in [buttons]="true"> </app-social-sign-in>

            <div class="login_link">
                <p>{{"Already have an account?" | translate}}</p>
                <button type="button" class="btn_large btn_transparant" (click)="handleLogin()">{{"Sign In" | translate}}</button>
            </div>
        </div>



        <!-- ============================== SIGN UP FORM ============================== -->
        <div class="content_area signup_form" *ngIf="activeStepper == 1">
            <div class="previous-arrow" (click)="closeSignupModal()" [ngClass]="{'back-override': selectedLanguage == 'ar'}">
                <i class="fa fa-arrow-left common-arrow-style" aria-hidden="true"></i>
                <span>{{"Back" | translate}}</span>
            </div>

            <!-- content header strt -->
            <div class="page-title" [ngClass]="{'form-field-override': selectedLanguage == 'ar'}">
                <h1>{{"Finish signing up" | translate}}</h1>
            </div>

            <app-panel *ngIf="error?.length > 0" class="m-t-20 m-b-20" background="#bd4239" textColor="white">
                {{error}}
            </app-panel>

            <!-- content header end -->
            <div class="sign_up_form">
                <form (ngSubmit)="onSignup()" ngNativeValidate autocomplete="new-password">


                        <div class="form-field username_textcaptilized" [ngClass]="{'form-field-override': selectedLanguage == 'ar'}">
                            <label>{{"What's your first name?" | translate}}</label>
                            <input type="text" [(ngModel)]="user.first_name"  (ngModelChange)="firstNameValidation()" [ngModelOptions]="{standalone: true}"
                            placeholder="{{'Enter your first name' | translate}}" required>
                            <p class="red-alert" *ngIf="submitted && !firstnameBlank">{{"Please enter first name" | translate}}</p>
                            <p>{{"Make sure it matches the first name on Government ID." | translate}}</p>
                        </div>
                        <div class="plans_access"></div>
                        <div class="form-field username_textcaptilized" [ngClass]="{'form-field-override': selectedLanguage == 'ar'}">
                            <label>{{"What's your last name?" | translate}}</label>
                            <input type="text" [(ngModel)]="user.last_name"  (ngModelChange)="lastNameValidation()" [ngModelOptions]="{standalone: true}"
                                placeholder="{{'Enter your last name' | translate}}" required autocomplete="new-password">
                            <p class="red-alert" *ngIf="submitted && !lastnameBlank"> {{"Please enter last name" | translate}}</p>
                            <p>{{"Make sure it matches the last name on Government ID." | translate}}</p>
                        </div>

                        <div class="form-field" [ngClass]="{'form-field-override': selectedLanguage == 'ar'}">
                            <label>{{"Your password?" | translate}}</label>
                            <div class="password-form">
                                <input type="{{hidePass ? 'password' : 'text'}}" placeholder="{{'Enter your password' | translate}}" (ngModelChange)="passwordValidation()"
                                    [(ngModel)]="user.password" [ngModelOptions]="{standalone: true}" required autocomplete="new-password">
                                <span class="show-password" (click)="toggleHidePassword()">
                                    <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="hidePass"></i>
                                    <i class="fa fa-eye" aria-hidden="true" *ngIf="!hidePass"></i>
                                </span>
                            </div>
                            <p class="red-alert" *ngIf="submitted && !passwordBlank">
                                    {{"Please enter password" | translate}}
                            </p>
                            <p>{{"Use a secure password (8 characters including 1 Number)" | translate}}.</p>
                        </div>

                        <div class="form-field referrel_link" [ngClass]="{'form-field-override': selectedLanguage == 'ar'}" hidden>
                            <span (click)="toggleRefferenceInput()">{{"Referral code" | translate}}
                                <i class="fa fa-angle-down" aria-hidden="true"></i>
                            </span>

                            <div class="referal_input_container" [ngClass]="{'form-field-override': selectedLanguage == 'ar'}">
                                <input type="text" placeholder="Enter your referral code" [(ngModel)]="user.invite_code"
                                    [ngModelOptions]="{standalone: true}">
                            </div>
                        </div>

                        <div class="recaptchaInput" [ngClass]="{'form-field-override': selectedLanguage == 'ar'}" *ngIf="envPrefix == 'prod' || envPrefix == 'dev' || envPrefix=='staging'">
                            <re-captcha [(ngModel)]="token" (resolved)="resolved($event)" [ngModelOptions]="{standalone: true}" required [class.is-invalid]="!recaptchaValid"></re-captcha>
                            <p *ngIf="!recaptchaValid">{{"Recaptcha is required" | translate}}</p>
                        </div>

                        <div class="tc_check">
                            <div class="form-check" [ngClass]="{'form-field-override checkBoxRight': selectedLanguage == 'ar'}">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="termsOne"
                                    [ngModelOptions]="{standalone: true}" id="flexCheckChecked" checked>
                                <label class="form-check-label agree_text" for="flexCheckChecked">
                                    {{"Agree with our" | translate}} <a class="terms-url" target="_blank"
                                        routerLink="/auth/terms-service-policy">{{"Terms and Conditions" |
                                        translate}}.</a>
                                </label>
                            </div>
                            <p class="red-alert mt-10" *ngIf="!termsOne">
                                {{"Please accept terms and conditions to continue." | translate}}
                            </p>
                        </div>
                        <button type="button" [disabled]="!termsOne" class="btn_large btn_transparant" (click)="onSignup()">{{"Continue" | translate}}</button>
                </form>
            </div>
        </div>
        <!-- =========================== ********* ============================== -->


        <!-- <app-onboarding-notification class="notification__desktop" [userData]="signup_res" *ngIf="activeStepper == 2">
        </app-onboarding-notification> -->

    </div>
</div>



<!-- finish signup modal start -->
<!-- Modal -->
<div class="modal fade subscription_plan" id="signup_finish" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <!-- header left brand start -->
                <div class="explore_left">
                    <div class="explore_brand" [routerLink] = "['/']">
                        <span><img src="/assets/app-logo.svg"></span>
                        <span class="brand_title">Holiday Swap</span>
                    </div>
                </div>
                <!-- header left brand start -->
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="modal_container">
                    <!-- content area start -->
                    <div class="content_area">
                        <!-- content header strt -->
                        <div class="page-title">
                            <h1>{{"Finish signing up" | translate}}</h1>
                        </div>


                        <!-- <app-panel *ngIf="emailError?.length > 0 && activeStepper == 0" class="m-t-20 m-b-20" background="#bd4239"
                            textColor="white">
                            {{emailError}}
                        </app-panel> -->

                        <!-- <app-panel *ngIf="error?.length > 0 && activeStepper == 1" class="m-t-20 m-b-20" background="#bd4239"
                            textColor="white">
                            {{error}}
                        </app-panel> -->



                        <!-- content header end -->
                        <div class="sign_up_form">
                            <form (ngSubmit)="onSignup()" ngNativeValidate autocomplete="new-password">
                                <div class="form-field username_textcaptilized">
                                    <label>{{"What's your first name?" | translate}}</label>
                                    <input type="text" [(ngModel)]="user.first_name"
                                        [ngModelOptions]="{standalone: true}" (ngModelChange)="firstNameValidation()"
                                        placeholder="{{'Enter your first name' | translate}}" required class="input_textCaptilazed">
                                    <p class="red-alert" *ngIf="submitted && user?.first_name?.length <2"> {{"Please enter first name" | translate}}</p>
                                    <p>{{"Make sure it matches the first name on Government ID." | translate}}</p>
                                </div>

                                <div class="form-field username_textcaptilized">
                                    <label>{{"What's your last name?" | translate}}</label>
                                    <input type="text" [(ngModel)]="user.last_name"
                                        [ngModelOptions]="{standalone: true}" (ngModelChange)="lastNameValidation()"
                                        placeholder="{{'Enter your last name' | translate}}" required autocomplete="new-password" class="">
                                    <p class="red-alert" *ngIf="submitted && user?.last_name?.length <2"> {{"Please enter last name" | translate}}</p>
                                    <p>{{"Make sure it matches the last name on Government ID." | translate}}</p>
                                </div>

                                <div class="form-field">
                                    <label>{{"Your password?" | translate}}</label>
                                    <input type="password" placeholder="{{'Enter your password' | translate}}"
                                        [(ngModel)]="user.password" [ngModelOptions]="{standalone: true}" required autocomplete="new-password">
                                    <p>{{"Use a secure password (8 characters including 1 Number)" | translate}}</p>
                                </div>

                                <div class=" form-field referrel_link" hidden>
                                    <span>{{"Referral code" | translate}}
                                        <!-- <i class="fa fa-angle-down" aria-hidden="true"></i> -->
                                    </span>
                                    <input type="text" placeholder="Enter your referral code"
                                        [(ngModel)]="user.invite_code" [ngModelOptions]="{standalone: true}">

                                </div>
                                <div class="tc_check">
                                    <div class="form-check">
                                        <input class="form-check-input" [(ngModel)]="terms"
                                            [ngModelOptions]="{standalone: true}" type="checkbox" id="flexCheckChecked"
                                            checked>
                                        <label class="form-check-label agree_text" for="flexCheckChecked">
                                            {{"Agree with our" | translate}} <a class="terms-url"
                                                routerLink="/auth/terms-service-policy">{{"Terms and Conditions" |
                                                translate}}</a>
                                        </label>
                                    </div>
                                </div>
                                <div class="plans_access">
                                    <button type="button" [disabled]="!terms" class="btn_large"
                                        (click)="onSignup()">{{"Agree and continue"
                                        | translate}}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- finish signup modal end -->

<!-- removed to fix issue ticket 1595 -->
<!-- <app-onboarding-notification class="notification__mobile" [userData]="signup_res" *ngIf="activeStepper == 2">
</app-onboarding-notification> -->

<!-- finish signup modal start -->
<!-- Modal -->
<!-- <div class="modal fade subscription_plan-noti" id="notification_popup" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">

                <div class="explore_left">
                    <div class="explore_brand">
                        <span><img src="/assets/app-logo.svg"></span>
                        <span class="brand_title">Holiday Swap</span>
                    </div>
                </div>

                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="modal_container">

                    <div class="content_area">

                        <div class="page-title">
                            <img src="/assets/images/noti_ic_bl.svg">
                            <h1>{{"Turn on notifications?" | translate}}</h1>
                        </div>

                        <div class="notification_area">
                            <p>{{"Don’t miss important messages like check-in details and account activity." |
                                translate}}</p>
                            <p>{{"Get travel deals, personalized recommendations and many more offers." | translate}}
                            </p>
                            <div class="plans_access">
                                <button type="button" class="btn_large" data-bs-toggle="modal" data-bs-target="">
                                    {{"Yes, notify me" | translate}} </button>
                                <a>{{"Skip for now" | translate}}!</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- finish signup modal end -->

<app-loading [absolute]="true" [loading]="loading"></app-loading>
