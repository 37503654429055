<div class="app_container">
    <div class="search_filter_wrapper">

        <div class="explore_header">
            <!-- header left brand start -->
            <!-- <div class="explore_left">
           <div class="back_btn">
               <span><img src="/assets/images/back_arrow.svg"></span>
           </div>
       </div> -->
            <!-- header left brand start -->
            <!-- <div class="explore-right">
        <ul>
            <li class="notification"><a><img src="/assets/images/noti_ic_bl.svg"><span class="noti_badge"></span></a></li>

        </ul>
       </div> -->
            <app-country-notification-header> </app-country-notification-header>

        </div>
        <div class="search-filter">
            <div class="search-input">
                <input type="text" [(ngModel)]="location.full_address"
                    [ngClass]="{'search-filter_override': selectedLanguage == 'ar'}"
                    (ngModelChange)="autoCompleteAddress($event)" placeholder="{{'Search address' | translate}}">

                <div *ngIf="locations.length > 0" class="results {{ locations.length > 0 ? 'visible' : '' }}">
                    <ul>
                        <li *ngFor="let location of locations" (click)="onSelect(location)">
                            {{location.full_address}}
                        </li>
                    </ul>
                </div>

            </div>
            <div class="filter-search-btn ">
                <!-- <button type="button dropdown-toggle" data-bs-toggle="dropdown" (click)="onShow()"><img src="/assets/images/filter_ic.svg">Filter</button> -->

                <nav class="navbar navbar-expand-sm  settings-nav-head">
                    <div class="container-fluid">
                        <div class="collapse navbar-collapse " id="collapsibleNavbar">
                            <ul class="navbar-nav">

                                <li class="nav-item dropdown">
                                    <!-- <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown">{{"Settings" | translate}}</a> -->
                                    <!-- <button type="button " class=" dropdown-toggle" data-bs-toggle="dropdown" data-bs-toggle="modal" data-bs-target="#filter_modal"><img src="/assets/images/filter_ic.svg">Filter</button>  -->

                                    <button type="button" title="button" class=" dropdown-toggle d-md-block d-none"
                                        data-bs-toggle="dropdown"><img alt="" src="/assets/images/filter_ic.svg">{{"Filter" |
                                        translate}}</button>

                                    <button title="btn" type="button" class=" dropdown-toggle small_btn d-md-none d-block"
                                        data-bs-toggle="dropdown"><img alt="" src="/assets/images/Filter_icon.svg"></button>

                                    <ul class="dropdown-menu options-list-design"
                                        [ngClass]="{'list-options-show': spaceList.length > 0}"
                                        [class.left_align]="selectedLanguage == 'ar'">
                                        <!-- <li><a class="dropdown-item" href="#">Link</a></li> -->
                                        <li><a class="dropdown-item option-style"
                                                *ngFor="let purposes of categories.space_purposes"
                                                [class.active]="filter.purpose_ids == purposes.id"
                                                (click)="onPurposeTypeChange(purposes.id, purposes.name)">{{
                                                purposes.name | translate}}</a></li>
                                        <!-- <li><a class="dropdown-item option-style"  >Logout </a></li> -->
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
                <!-- <div class="select-range-box">

            <select class="select range-type-select" name="pace_type" [(ngModel)]="filter.purpose_ids" (ngModelChange)="onFilterChange()" >
                <option  disabled [value]="undefined">Select space type</option>
                <option *ngFor="let purposes of categories.space_purposes"  [value]="purposes.id">
                  <span >  {{ purposes.name }} </span> </option>
            </select>

        </div> -->

            </div>



        </div>
    </div>

    <!-- search listing start -->

    <div class="search-list-view">
        <div class="popular_widget_main">
            <div class="row_inner">
                <!-- search item start      -->
                <div class="popular_widget_item col-md-4 col-lg-4 col-sm-6 col-xs-12"
                    *ngFor="let space of spaceList; index as i">
                    <div class="card" [routerLink]="['/','view-home','home-details-ibs', space.id]">
                        <div class="popular_image">
                            <img alt="" class="lazyload" [lazyLoad]="space.mobile_image">
                            <div class="popular_action">
                                <ul>
                                    <!-- <li><a><img src="/assets/images/send_ic.svg" ></a></li> -->
                                    <li *ngIf="space?.is_favourited"><a
                                            (click)="onLike(space,i); $event.stopPropagation()"><i class="fa fa-heart"
                                                aria-hidden="true"></i></a></li>
                                    <li *ngIf="!space?.is_favourited"><a
                                            (click)="onLike(space,i); $event.stopPropagation()"><i class="fa fa-heart-o"
                                                aria-hidden="true"></i></a></li>
                                    <li><a><button type="button">{{"Book now" | translate}}</button></a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="popular_title">
                                <h5 class="card-title" *ngIf="space?.title">{{
                                    (space?.title?.length>40)?space?.title?.substring(0,40) :space?.title }}
                                    {{ (space?.title?.length>40)?"...":""}}</h5>
<!--                                <span class="rating_item" *ngIf="space?.rating?.average > 0">-->
<!--                                    <img alt="" src="/assets/images/star_ic.svg">-->
<!--                                    <span class="rating_list">{{space?.rating?.average }}</span>-->
<!--                                </span>-->
                            </div>
                            <div class="popular_des">
                                <span>{{ space?.bedrooms }} {{ space?.bedrooms > 1 ? "Bedrooms" : "Bedroom" |
                                    translate}} . {{ space?.guests }} {{ space?.guests > 1 ? "guests" :"guest" |
                                    translate}} </span>
                                <span *ngIf="space?.rating?.total_reviews > 0">{{space?.rating?.total_reviews }}
                                    {{space?.rating?.total_reviews == 1 ? "review" : "reviews" | translate}}</span>
                            </div>
                            <hr>
                            <div class="popular_footer">
                                <span *ngIf="space?.country || space?.city"><img alt="" src="/assets/images/mark_gray_ic.svg">&nbsp;{{space?.country ? space?.country+'
                                    , ' : ' '}} {{space?.city}}</span>

                                <span *ngIf="space?.address?.country || space?.address?.city"><img alt="" src="/assets/images/mark_gray_ic.svg">&nbsp;{{space?.address?.country ?
                                    space?.address?.country+' , ' : ' '}} {{space?.address?.city}}</span>
                                <span class="explore_price" [class.striked]="isPremiumMember"
                                    *ngIf="space?.hosting_tokens > 0 && space?.purpose != 'swap'"><img alt="" class="icon-img-token-side-bar" src="assets/images/Token_Plane.png">
                                    <span>{{ space?.hosting_tokens }}</span>
                                    <!-- <s *ngIf="isPremiumMember">{{ space?.hosting_tokens }}</s> -->
                                </span>
                            </div>
                        </div>
                        <div class="offer_footer" *ngIf="space?.hosting_tokens > 0 && space?.purpose != 'swap'">
                            <div class="text_part">
                                <span>{{ "Subscribed Users" | translate }} &nbsp;&nbsp;&nbsp;</span>
                                <p><span class="text_red text_big">20%</span> <span class="text_red">{{ "Discount" |
                                        translate }}</span></p>
                            </div>
                            <div class="ammount_part">
                                <span class="explore_price"><img alt="" src="/assets/images/Token_Plane.png">{{
                                    getPersentage(space?.hosting_tokens) }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- search item end      -->




                <!-- search item start      -->
                <!-- <div class="popular_widget_item col-md-4 col-lg-4 col-sm-6 col-xs-12">
            <div class="card">
                <div class="popular_image">
                <img src="/assets/images/4.jpg">
                <div class="popular_action">
                    <ul>
                        <li><a><img src="/assets/images/send_ic.svg" ></a></li>
                        <li><a><i class="fa fa-heart" aria-hidden="true"></i></a></li>
                        <li><a><i class="fa fa-heart-o" aria-hidden="true"></i></a></li>
                        <li><a><button type="button">Book Now</button></a></li>
                    </ul>
                </div>
                </div>
                <div class="card-body">
                    <div class="popular_title">
                        <h5 class="card-title">Little cotton house</h5>
                        <span class="rating_item"><img src="/assets/images/star_ic.svg" >&nbsp;&nbsp;4.2</span>
                    </div>
                    <div class="popular_des">
                        <span>3 bedrooms . 6 guests </span>
                        <span>22 reviews</span>
                    </div>
                    <hr>
                    <div class="popular_footer">
                        <span><img src="/assets/images/mark_gray_ic.svg" >&nbsp;United States, Joshua Tree</span>
                        <span class="explore_price">$1,280</span>
                    </div>
                </div>
              </div>
        </div> -->
                <!-- search item end      -->
                <!-- search item start      -->
                <!-- <div class="popular_widget_item col-md-4 col-lg-4 col-sm-6 col-xs-12">
        <div class="card">
            <div class="popular_image">
            <img src="/assets/images/4.jpg">
            <div class="popular_action">
                <ul>
                    <li><a><img src="/assets/images/send_ic.svg" ></a></li>
                    <li><a><i class="fa fa-heart" aria-hidden="true"></i></a></li>
                    <li><a><i class="fa fa-heart-o" aria-hidden="true"></i></a></li>
                    <li><a><button type="button">Book Now</button></a></li>
                </ul>
            </div>
            </div>
            <div class="card-body">
                <div class="popular_title">
                    <h5 class="card-title">Little cotton house</h5>
                    <span class="rating_item"><img src="/assets/images/star_ic.svg" >&nbsp;&nbsp;4.2</span>
                </div>
                <div class="popular_des">
                    <span>3 bedrooms . 6 guests </span>
                    <span>22 reviews</span>
                </div>
                <hr>
                <div class="popular_footer">
                    <span><img src="/assets/images/mark_gray_ic.svg" >&nbsp;United States, Joshua Tree</span>
                    <span class="explore_price">$1,280</span>
                </div>
            </div>
          </div>
    </div> -->
                <!-- search item end      -->
                <!-- search item start      -->
                <!-- <div class="popular_widget_item col-md-4 col-lg-4 col-sm-6 col-xs-12">
        <div class="card">
            <div class="popular_image">
            <img src="/assets/images/4.jpg">
            <div class="popular_action">
                <ul>
                    <li><a><img src="/assets/images/send_ic.svg" ></a></li>
                    <li><a><i class="fa fa-heart" aria-hidden="true"></i></a></li>
                    <li><a><i class="fa fa-heart-o" aria-hidden="true"></i></a></li>
                    <li><a><button type="button">Book Now</button></a></li>
                </ul>
            </div>
            </div>
            <div class="card-body">
                <div class="popular_title">
                    <h5 class="card-title">Little cotton house</h5>
                    <span class="rating_item"><img src="/assets/images/star_ic.svg" >&nbsp;&nbsp;4.2</span>
                </div>
                <div class="popular_des">
                    <span>3 bedrooms . 6 guests </span>
                    <span>22 reviews</span>
                </div>
                <hr>
                <div class="popular_footer">
                    <span><img src="/assets/images/mark_gray_ic.svg" >&nbsp;United States, Joshua Tree</span>
                    <span class="explore_price">$1,280</span>
                </div>
            </div>
        </div>
    </div> -->
                <!-- search item end      -->
            </div>
        </div>
    </div>

</div>

<app-loading [loading]="loading"></app-loading>

<div class="modal fade" id="filter_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M1.15164 1.1515C1.37667 0.926537 1.68184 0.800158 2.00004 0.800158C2.31823 0.800158 2.6234 0.926537 2.84844 1.1515L8.00004 6.3031L13.1516 1.1515C13.2623 1.03689 13.3947 0.945471 13.5412 0.88258C13.6876 0.81969 13.845 0.786586 14.0044 0.785202C14.1637 0.783817 14.3217 0.814179 14.4692 0.874516C14.6167 0.934853 14.7506 1.02396 14.8633 1.13663C14.976 1.2493 15.0651 1.38328 15.1254 1.53076C15.1858 1.67823 15.2161 1.83625 15.2147 1.99558C15.2134 2.15492 15.1803 2.31238 15.1174 2.45879C15.0545 2.60519 14.963 2.73761 14.8484 2.8483L9.69684 7.9999L14.8484 13.1515C15.067 13.3778 15.188 13.6809 15.1852 13.9956C15.1825 14.3102 15.0563 14.6112 14.8338 14.8337C14.6113 15.0562 14.3104 15.1824 13.9957 15.1851C13.6811 15.1878 13.378 15.0669 13.1516 14.8483L8.00004 9.6967L2.84844 14.8483C2.62211 15.0669 2.31899 15.1878 2.00436 15.1851C1.68972 15.1824 1.38874 15.0562 1.16625 14.8337C0.943765 14.6112 0.817562 14.3102 0.814828 13.9956C0.812093 13.6809 0.933047 13.3778 1.15164 13.1515L6.30324 7.9999L1.15164 2.8483C0.926672 2.62327 0.800293 2.3181 0.800293 1.9999C0.800293 1.68171 0.926672 1.37654 1.15164 1.1515Z"
                            fill="#23262F" />
                    </svg>
                </button>
                <h5 class="modal-title" id="exampleModalLabel">{{"Edit your search" | translate}}</h5>
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
                        <path d="M7.33005 13.593H1.02905" stroke="white" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path d="M10.1399 3.8999H16.4409" stroke="white" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M5.726 3.84601C5.72362 3.2216 5.47335 2.6237 5.03023 2.18376C4.58711 1.74383 3.98741 1.49789 3.363 1.50001C2.73859 1.49789 2.13889 1.74383 1.69577 2.18376C1.25265 2.6237 1.00238 3.2216 1 3.84601C1.00105 4.15528 1.063 4.46131 1.18232 4.74663C1.30164 5.03195 1.47599 5.29097 1.69542 5.50891C1.91484 5.72685 2.17504 5.89944 2.46117 6.01682C2.74729 6.13419 3.05374 6.19407 3.363 6.19301C3.67226 6.19407 3.97871 6.13419 4.26483 6.01682C4.55096 5.89944 4.81116 5.72685 5.03058 5.50891C5.25001 5.29097 5.42436 5.03195 5.54368 4.74663C5.663 4.46131 5.72495 4.15528 5.726 3.84601V3.84601Z"
                            stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M16.9999 13.554C16.999 13.2448 16.9372 12.9388 16.818 12.6535C16.6988 12.3682 16.5245 12.1092 16.3052 11.8912C16.0858 11.6733 15.8257 11.5007 15.5396 11.3833C15.2535 11.2659 14.9471 11.206 14.6379 11.207C14.3286 11.2059 14.022 11.2656 13.7358 11.383C13.4495 11.5003 13.1892 11.6728 12.9697 11.8908C12.7502 12.1087 12.5757 12.3678 12.4563 12.6532C12.337 12.9386 12.275 13.2447 12.2739 13.554C12.2751 13.8633 12.3372 14.1693 12.4566 14.4546C12.5761 14.7399 12.7505 14.9988 12.97 15.2167C13.1896 15.4345 13.4498 15.607 13.736 15.7242C14.0222 15.8415 14.3287 15.9012 14.6379 15.9C14.9471 15.9011 15.2534 15.8413 15.5394 15.7239C15.8254 15.6066 16.0855 15.4341 16.3048 15.2162C16.5241 14.9984 16.6984 14.7395 16.8177 14.4543C16.9369 14.1691 16.9989 13.8632 16.9999 13.554V13.554Z"
                            stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </span>
            </div>
            <div class="modal-body">
                <div class="filter_area_pp">
                    <div class="search_box">
                        <input type="text" placeholder="Los Angeles, CA, United State">
                    </div>
                    <div class="add_area">
                        <div class="add_btns"><i class="fa fa-calendar-plus-o" aria-hidden="true"></i>&nbsp;{{"Add
                            dates" | translate}}</div>
                        <div class="add_btns"><i class="fa fa-user-plus" aria-hidden="true"></i>&nbsp;{{"Add guests" |
                            translate}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
