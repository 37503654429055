import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { MetadataService } from '../../services/metadata.service';
import { SpaceService } from '../../services/space.service';
import { Location } from '@angular/common';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { BoosterService } from '../../services/booster.service';
import { SnackbarService } from '../../services/snackbar.service';

declare var $: any;
@Component({
  selector: 'app-edit-home-component',
  templateUrl: './edit-home-component.component.html',
  styleUrls: ['./edit-home-component.component.scss'],
})
export class EditHomeComponentComponent implements OnInit {
  isEditMode = false;
  public loading: boolean = false;
  public spaceId;
  public space: any = [];
  public editHomeData: any = [];
  placeholderImg = 'assets/images/file_up.png';
  public outsideViewImage: any = { tag_id: 555, image_url: '' };
  public livingRoomImages: any = { tag_id: 544, image_url: '' };
  public bedRoomImages: any = { tag_id: 541, image_url: '' };
  public kitchenImages: any = { tag_id: 552, image_url: '' };
  public bathRoomImages: any = { tag_id: 542, image_url: '' };
  public guestRoomImages: any = { tag_id: 551, image_url: '' };
  public unavailable_dates: any = [];
  public categories: any = [];
  public showAllAmenities: boolean = false;
  public tokenValue = 50;
  public amountValue = 50;
  // public tokenPrice = 1;
  public openAddHomeForm = false;
  public activeStep = 1;
  unavailable;
  public minDate = new Date();
  aminitiesForm: FormGroup;
  // public inputstarted: boolean = false;
  public darkTheme: boolean = false;
  public isSafariBrowser = false;
  public isFirefoxBrowser = false;
  public activeBoostCount = 0;
  // public disableOne: boolean = false;
  settings = {
    fill: '',
    background: '',
  };
  hasHistory: boolean = false;
  items = [
    {
      name: 'home',
      link: '/',
    },
    {
      label: 'home details',
    },
  ];
  shareMessage =
    'I just listed my Home on Holiday Swap so I can earn thousands and travel for free!  Check it out! ';
  _albums = [];
  selectedCoverImage: number = 0;
  checkIOS: boolean = false;
  // potentialvalueDisbled = true;
  spacePotential = 0;
  isNextMonth = false;
  totalSelectedAmenities = 0;
  hideAmenitiesButton = false;
  priceLoading: boolean = true;
  popupbackdrop: boolean = false;
  emptyAmenities: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spaceService: SpaceService,
    private metadataService: MetadataService,
    private fb: FormBuilder,
    private location: Location,
    private zone: NgZone,
    private googleAnalyticsService: GoogleAnalyticsService,
    private cd: ChangeDetectorRef,
    private boostService: BoosterService,
    private snackbarService: SnackbarService
  ) {
    this.hasHistory = this.router.navigated;
    this.route.params.subscribe((param) => {
      this.spaceId = param.id;
    });

    // this.aminitiesForm = this.fb.group({
    //   accessibilities: new FormArray([]),
    //   amenities: new FormArray([]),
    //   rules: new FormArray([]),
    //   safeties: new FormArray([]),
    // })
    this.checkIOS = this.isIOSDevice();
    this.isSafariBrowser = this.IsSafari();

    this.isFirefoxBrowser = this.IsFirefox();

    this.getSpaceInsights();
    this.getSpaceDetails();
    this.getSpaceCategories();
    this.getMyBoostCount();
  }

  ngOnInit(): void {
    // First let's set the colors of our sliders
    this.settings = {
      fill: '#FFA300',
      background: '#fffaef',
    };
  }

  ngAfterViewInit(): void {
    if (this.isSafariBrowser) {
      $('.app-content').css('position', 'absolute');
    }
    if (this.isFirefoxBrowser) {
      $('.app-content').css('position', 'absolute');
    }
  }

  isIOSDevice() {
    return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  }

  IsSafari() {
    var is_safari = /^(?!.*chrome).*safari/i.test(navigator.userAgent);
    return is_safari;
  }

  IsFirefox() {
    var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    return isFirefox;
  }

  getSpaceInsights() {
    this.spaceService.getSpaceInsights(this.spaceId).subscribe((res: any) => {
      this.spacePotential = res.total;
      this.isNextMonth = res.isNextMonth;
      // this.potentialvalueDisbled = true;
    });
  }

  resetSlider(type) {
    const sliders = document.querySelectorAll('.range-slider-details');
    Array.prototype.forEach.call(sliders, (slider) => {
      slider.querySelector('input').addEventListener('input', (event) => {
        slider.querySelector('span').innerHTML = event.target.value;
        // this.applyFill(event.target, slider.value, 'token');
      });

      // this.applyFillInitial(slider.querySelector('input'), this.tokenValue);
    });
  }
  generateGalleryImages(images) {
    this._albums = [];

    for (let img of images) {
      const album = {
        src: img.image_url,
        caption: '',
        thumb: img.image_url,
      };

      this._albums.push(album);
    }
  }

  // This function applies the fill to our sliders by using a linear gradient background

  public applyFill(slider, hostingvalue, type) {
    this.zone.run(() => {
      let fillColor = this.settings.fill;
      let backgroundColor = this.settings.background;

      if (type == 'amount') {
        fillColor = '#1cc91a';
        backgroundColor = '#bbefba';
      }

      const percentage =
        (100 * (hostingvalue - slider.min)) / (slider.max - slider.min);
      const bg = `linear-gradient(90deg, ${fillColor} ${percentage}%, ${backgroundColor} ${
        percentage + 0.1
      }%)`;

      slider.style.background = bg;
      this.cd.detectChanges();
    });
  }

  applyFillInitial(slider, token) {
    // Let's turn our value into a percentage to figure out how far it is in between the min and max of our input
    const percentage = (100 * (token - slider.min)) / (slider.max - slider.min);
    // now we'll create a linear gradient that separates at the above point
    // Our background color will change here
    const bg = `linear-gradient(90deg, ${this.settings.fill} ${percentage}%, ${
      this.settings.background
    } ${percentage + 0.1}%)`;
    slider.style.background = bg;
  }

  getSpaceCategories() {
    this.metadataService
      .getSpaceCategories()
      .subscribe((response: { data: any }) => {
        this.categories = response.data;

        let amnetiesOverride = this.categories.amenities.map((v) => ({
          ...v,
          isActive: false,
        }));
        this.categories.amenities = amnetiesOverride;

        let rulesOverride = this.categories.rules.map((r) => ({
          ...r,
          isActive: false,
        }));
        this.categories.rules = rulesOverride;

        let accessibiltyOverride = this.categories.accessibilities.map((r) => ({
          ...r,
          isActive: false,
        }));
        this.categories.accessibilities = accessibiltyOverride;

        let safetiesOverride = this.categories.safeties.map((s) => ({
          ...s,
          isActive: false,
        }));
        this.categories.safeties = safetiesOverride;
      });
  }

  getSpaceDetails() {
    //  this.loading = true;
    this.spaceService.getSpace(this.spaceId).subscribe((res: any) => {
      this.loading = false;
      this.space = { ...res };
      this.editHomeData = { ...res };
      this.tokenValue = this.space.hosting_tokens;
      this.amountValue = this.space.hosting_amount;
      this.priceLoading = false;
      this.resetSlider('token');
      this.resetSlider('amount');
      this.unavailable_dates = res.unavailable_dates.filter((dates) => {
        // if(dates?.status == "UNAVAILABLE") {
        return dates;
        // }
      });
      const dates = [];
      this.unavailable_dates.map((date) => {
        const start = moment.unix(date.started_at).format('YYYY-MM-DD');
        const end = moment.unix(date.ended_at).format('YYYY-MM-DD');
        dates.push(...this.enumerateDaysBetweenDates(start, end));
      });

      if (
        this.space?.amenities?.length <= 0 &&
        this.space?.accessibilities?.length <= 0 &&
        this.space?.safeties?.length <= 0 &&
        this.space?.rules?.length <= 0
      ) {
        this.emptyAmenities = true;
      }

      this.unavailable = dates;
      this.generateGalleryImages(this.space.images);

      this.patchAmenities();
      if (this.space?.payment_type_id == 561) {
        // this.applyFill(document.querySelector("#myRange2"), this.space?.hosting_amount, 'amount')
      }
      if (this.space?.payment_type_id == 562) {
        // this.applyFill(document.querySelector("#myRange1"), this.space?.hosting_tokens, 'token')
      }
      this.space.images = res.images.map((image, index) => {
        if (index == 0) {
          this.outsideViewImage.image_url = image.image_url;
        }
        if (image.tag?.id == 555) {
          this.outsideViewImage.image_url = image.image_url;
        }
        if (image.tag?.id == 544) {
          this.livingRoomImages.image_url = image.image_url;
        }
        if (image.tag?.id == 541) {
          this.bedRoomImages.image_url = image.image_url;
        }
        if (image.tag?.id == 552) {
          this.kitchenImages.image_url = image.image_url;
        }
        if (image.tag?.id == 542) {
          this.bathRoomImages.image_url = image.image_url;
        }
        if (image.tag?.id == 551) {
          this.guestRoomImages.image_url = image.image_url;
        }

        return {
          id: image.id,
          image_path: image.image_url,
          tag: image.tag.id,
          uploaded: true,
        };
      });
    });
  }

  public enumerateDaysBetweenDates(startDate, endDate) {
    startDate = moment(startDate);
    endDate = moment(endDate);

    const now = startDate;
    const dates = [];

    while (now.isBefore(endDate) || now.isSame(endDate)) {
      dates.push(now.format('YYYY-MM-DD'));
      now.add(1, 'days');
    }
    return dates;
  }

  toggleAmenities() {
    if ($('#amenitiesForm1').outerHeight() > 300) {
      if (this.showAllAmenities) {
        this.showAllAmenities = false;
        $('.allAmenities1').css('height', '300px');
        $('.amenities_block_btn_wrapper .shadow_box').css('display', 'block');
      } else {
        this.priceLoading = false;
        this.showAllAmenities = true;
        const height1 = $('#amenitiesForm1').outerHeight();

        $('.allAmenities1').css('height', height1);
        $('.allAmenities1').on('transitionend', function () {
          $('.amenities_block_btn_wrapper.show-all .shadow_box').css(
            'display',
            'none'
          );
        });
      }
    }
  }

  openEditHomePopup(activeStep) {
    this.isEditMode = false;

    this.activeStep = activeStep;
    if (activeStep == 2) {
      this.darkTheme = true;
    } else {
      this.darkTheme = false;
    }

    if (activeStep == 6) {
      this.getSpaceCategories();
    }

    this.openAddHomeForm = true;
  }

  closeEditFormPopup() {
    this.openAddHomeForm = false;
    // this.loading = true;
    this.priceLoading = true;
    this.amenitiesFormPopup();
    setTimeout(() => {
      this.getSpaceCategories();
      this.getSpaceDetails();
      this.getSpaceInsights();
    }, 300);

    // this
    // .googleAnalyticsService
    // .eventEmitter("LH_Complete", "ListHome", "LH_App_ThankYou");
  }
  amenitiesFormPopup() {
    if (
      this.space?.amenities?.length >= 0 &&
      this.space?.accessibilities?.length >= 0 &&
      this.space?.safeties?.length >= 0 &&
      this.space?.rules?.length >= 0
    ) {
      this.emptyAmenities = false;
    }

    if (this.activeStep == 6) {
      this.priceLoading = true;
    }
  }
  patchAmenities() {
    if (this.space?.amenities) {
      this.space?.amenities?.map((res1) => {
        this.categories?.amenities?.filter((res) => {
          if (res1.id == res.id) {
            res.isActive = true;
            // let selected = (this.aminitiesForm.controls['amenities'] as FormArray);
            // selected.push(new FormControl(res.id))
          }
        });
      });
    }

    if (this.space?.accessibilities) {
      this.space?.accessibilities?.map((res1) => {
        this.categories?.accessibilities?.filter((res) => {
          if (res1.id == res.id) {
            res.isActive = true;
            // let selected = (this.aminitiesForm.controls['accessibilities'] as FormArray);
            // selected.push(new FormControl(res.id))
          }
        });
      });
    }

    if (this.space?.rules) {
      this.space?.rules?.map((res1) => {
        this.categories?.rules?.filter((res) => {
          if (res1.id == res.id) {
            res.isActive = true;
            // let selected = (this.aminitiesForm.controls['rules'] as FormArray);
            // selected.push(new FormControl(res.id))
          }
        });
      });
    }

    if (this.space?.safeties) {
      this.space?.safeties?.map((res1) => {
        this.categories?.safeties?.filter((res) => {
          if (res1.id == res.id) {
            res.isActive = true;
            // let selected = (this.aminitiesForm.controls['safeties'] as FormArray);
            // selected.push(new FormControl(res.id))
          }
        });
      });
    }

    this.categories.amenities = this.categories?.amenities?.filter(
      (res) => res.isActive == true
    );
    this.categories.accessibilities = this.categories?.accessibilities?.filter(
      (res) => res.isActive == true
    );
    this.categories.rules = this.categories?.rules?.filter(
      (res) => res.isActive == true
    );
    this.categories.safeties = this.categories?.safeties?.filter(
      (res) => res.isActive == true
    );
    this.totalSelectedAmenities =
      this.categories?.amenities?.length +
      this.categories?.accessibilities?.length +
      this.categories?.rules?.length +
      this.categories?.safeties?.length;
    setTimeout(() => {
      if ($('#amenitiesForm1').outerHeight() > 300) {
        this.hideAmenitiesButton = false;
      } else {
        this.hideAmenitiesButton = true;
        $('.features_check_outer').css('height', 'auto');
      }
    }, 300);
  }

  public pop() {
    if (this.hasHistory) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnDestroy(): void {
    if (this.isSafariBrowser) $('.app-content').css('position', 'fixed');

    if (this.isFirefoxBrowser) $('.app-content').css('position', 'fixed');
  }

  getMyBoostCount() {
    this.boostService.getBoostCount().subscribe((res: any) => {
      this.activeBoostCount = res.data?.unUsedBoostCount;
    });
  }

  boostSpace() {
    this.popupbackdrop = false;
    this.loading = true;
    let data = {
      id: 1,
      space_id: this.spaceId,
    };
    this.boostService.boostSpace(data).subscribe((res: any) => {
      this.snackbarService.show({
        message: res.message,
        type: 'success',
        config: {
          class: 'centered',
        },
      });

      this.getSpaceDetails();
    });
  }

  closePopup() {
    this.popupbackdrop = false;
  }

  openConfirmPopup() {
    this.popupbackdrop = true;
  }

  buyBoosterRedirection() {
    this.router.navigate([`/view-listing/cart`]);
  }

  reOpenModal(event) {
    this.loading = true;

    this.activeStep = event;

    setTimeout(() => {
      this.openEditHomePopup(event);
      this.loading = false;
    }, 6000);
    //  this.openAddHomeForm = true;
  }
}
