import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

import { AuthService } from "src/app/shared/services/auth.service";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  public loading = false;
  public error: string;

  constructor(private authService: AuthService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
  }

  // public onReset() {
  //   this.loading = true;
  //   this.authService.forgotPassword({
  //     email: this.user.email,
  //   }).subscribe((response) => {
     
  //   }, (err) => {
  //     this.error = err;
  //   }).add(() => {
  //     this.loading = false;
  //   });
  // }
}
