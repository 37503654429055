import "hammerjs";
import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

// import * as Sentry from "@sentry/angular";
// import { BrowserTracing } from "@sentry/tracing";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import * as moment from 'moment';

import 'moment/locale/en-gb'; // English

// Set default locale to English
// moment.locale(LanguageService.parseLangForMoment(global?.localStorage.getItem('lang')) || 'en-gb');

// TODO: at this moment, we'll only support English
moment.locale('en-gb');

// Sentry.init({
// 	dsn: "https://4cd2d6a6dca64c4db9c461fd9db0b68e@o1421792.ingest.sentry.io/6768007",
// 	integrations: [
// 	  new BrowserTracing({
// 		tracingOrigins: ["localhost", "https://staging-api.holidayswap.com", "https://dev-api.holidayswap.com"],
// 		routingInstrumentation: Sentry.routingInstrumentation,
// 	  }),
// 	],

// 	// Set tracesSampleRate to 1.0 to capture 100%
// 	// of transactions for performance monitoring.
// 	// We recommend adjusting this value in production
// 	tracesSampleRate: 1.0,
//   });

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
	.catch(err => {});

