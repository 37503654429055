import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../services/user.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import isMobile from '../../utils/isMobile';
import { AuthService } from '../../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { PopupService } from '../../services/popup.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-daily-reward-popup',
  templateUrl: './daily-reward-popup.component.html',
  styleUrls: ['./daily-reward-popup.component.scss'],
})
export class DailyRewardPopupComponent implements OnInit {
  show: boolean = false;
  userEmail: string;
  isLogged: any;
  step: string = 'dailyRewardStep';
  public currentStreak = 1;
  showPassword = false;
  @ViewChild('myVideo') myVideo: ElementRef;
  public videos = [
    '/assets/images/pennybounce.gif',
    '/assets/images/coinstack.gif',
    '/assets/images/treasuremap.gif',
    '/assets/images/passport.gif',
    '/assets/images/suitcase.gif',
  ];
  dailyRewardsItems: any[] = Array.from({ length: 5 }).fill(0);
  authForm: FormGroup;
  isSubmitting = false;
  public redirect_to: string;
  public fragment: string;
  public queryParams;

  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    private authService: AuthService,
    private toastr: ToastrService,
    private popupService: PopupService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    if (localStorage.getItem('redirect_to')) {
      this.redirect_to = localStorage.getItem('redirect_to');
    }
    if (localStorage.getItem('fragment')) {
      this.fragment = localStorage.getItem('fragment');
    }
    if (localStorage.getItem('queryParams')) {
      this.queryParams = localStorage.getItem('queryParams');
    }

    const redirectPath = this.route.snapshot.queryParams.redirect_to;
    if (redirectPath) {
      this.redirect_to = redirectPath;
      localStorage.setItem('redirect_to', redirectPath);
      localStorage.setItem('fragment', this.fragment);
      localStorage.setItem('queryParams', this.queryParams);
    }
  }

  ngOnInit(): void {
    this.popupService.setDailyRewardPopupComponent(this);
    this.authForm = this.fb.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });

    this.getUserDetails();

    this.route.queryParams.subscribe((params) => {
      const openModal = params.openLoginModal;
      if (openModal && !this.isLogged) {
        this.openAuthModal();
      }
    });
  }

  openAuthModal() {
    this.step = 'authStep';
    this.show = true;
  }

  claimTokens() {
    this.router.navigate(['prizes']);
    this.show = false;
  }

  close() {
    this.show = false;
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  async continueHandler(e) {
    e.preventDefault();
    if (this.authForm.valid) {
      const values = this.authForm.value;

      const authData = {
        ...values,
        email: values?.username,
        source: isMobile.iOS()
          ? 'IOS'
          : isMobile.windows()
          ? 'windows'
          : isMobile.android()
          ? 'android'
          : isMobile.blackBerry()
          ? 'blackBery'
          : '',
        grant_type: 'password',
        client_id: '1',
        scope: '*',
      };

      try {
        this.isSubmitting = true;

        const emailVerify: any = await this.authService
          .checkMailVerification(authData.email)
          .toPromise();

        if (emailVerify?.isRegistered) {
          try {
            await this.authService.hsLogin(authData).toPromise();
            this.userService.getMe().subscribe();
            this.toastr.success("You've logged in successfully!");
            const fragment = localStorage.getItem('fragment');

            if (this.redirect_to != null && this.redirect_to != 'undefined') {
              if (fragment != 'undefined' && fragment != 'null') {
                this.router.navigate([this.redirect_to], {
                  fragment,
                  queryParams: this.route.snapshot.queryParams,
                  queryParamsHandling: 'merge',
                });
              } else {
                this.router.navigate([this.redirect_to], {
                  queryParams: this.route.snapshot.queryParams,
                  queryParamsHandling: 'merge',
                });
              }
            }
            this.show = false;
          } catch (e) {
            this.toastr.error(e, 'Error');
          }
        } else {
          try {
            await this.authService.hsRegister(authData).toPromise();
            await this.authService.hsLogin(authData).toPromise();
            this.userService.getMe().subscribe();
            this.toastr.success("You've registered successfully!");

            this.show = false;
          } catch (e) {
            console.error(e);
            this.toastr.error(e, 'Error');
          }
        }
      } finally {
        this.isSubmitting = false;
      }
    } else {
      this.validateAllFormFields(this.authForm); // {7}
    }
  }

  public getUserDetails() {
    const token = localStorage.getItem('token');

    if (token && token !== 'undefined') {
      this.isLogged = true;
      setTimeout(() => {
        this.userService.getDailyStreaks().subscribe((res: any) => {
          if (res.data?.length > 0) {
            const today = new Date();
            const latestDay = new Date(
              res.data[res.data.length - 1]?.created_at
            );
            const differenceInMilliseconds =
              today.getTime() - latestDay.getTime();
            const differenceInSeconds = differenceInMilliseconds / 1000;
            this.currentStreak = res.data[res.data.length - 1]?.day ?? 1;

            if (differenceInSeconds <= 10) {
              this.show = true;
            }
          } else {
            this.show = true;
          }
        });
      }, 3000);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
