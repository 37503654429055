import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling';

import { ButtonComponent } from './components/button/button.component';
import { LoadingComponent } from './components/loading/loading.component';
import { ThemeToggleComponent } from './components/theme-toggle/theme-toggle.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NO_ERRORS_SCHEMA } from '@angular/core';
import { PanelComponent } from './components/panel/panel.component';
import { HeaderComponent } from './components/header/header.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { RippleDirective } from './directives/ripple.directive';
import { SwiperModule } from 'swiper/angular';
import SwiperCore, {
  EffectCards,
  EffectCoverflow,
  EffectCreative,
  EffectFade,
  Navigation,
  Pagination,
  Scrollbar,
  Virtual,
} from 'swiper';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { AvatarComponent } from './components/avatar/avatar.component';
import { BadgeComponent } from './components/badge/badge.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { SpaceCardComponent } from './components/space-card/space-card.component';
import { RouterModule } from '@angular/router';
import { DragDropDirective } from './directives/drag-drop.directive';
import { ListPickerComponent } from './components/list-picker/list-picker.component';
import { FilterPipe } from './pipes/filter.pipe';
import { RatingComponent } from './components/rating/rating.component';
import { NgxMaskModule } from 'ngx-mask';
import { ActivateNextInputDirective } from './directives/activate-next-input.directive';
import { SwitchComponent } from './components/switch/switch.component';
import { MomentPipe } from './pipes/moment.pipe';
import { AccordionComponent } from './components/accordion/accordion.component';
import { AccordionPanelComponent } from './components/accordion/accordion-panel/accordion-panel.component';
import { ContenteditableDirective } from './directives/contenteditable.directive';
import { BubbleSelectionComponent } from './components/bubble-selection/bubble-selection.component';
import { ListSelectionComponent } from './components/list-selection/list-selection.component';
import { BottomSheetComponent } from './components/bottom-sheet/bottom-sheet.component';
import { TurnicatePipe } from './pipes/turnicate.pipe';
import { CalendarComponent } from './components/calendar/calendar.component';
import { IncrementComponent } from './components/increment/increment.component';
import { RangeSliderComponent } from './components/range-slider/range-slider.component';
import { HammerModule } from '@angular/platform-browser';
import { SelectComponent } from './components/select/select.component';
import { SafeResourcePipe } from './pipes/safe-resource.pipe';
import { SpaceImagesComponent } from './components/space-images/space-images.component';
import { SpaceRequirementComponent } from './components/space-requirement/space-requirement.component';
import { SpaceIncrementComponent } from './components/space-increment/space-increment.component';
import { TabBarComponent } from './components/tab-bar/tab-bar.component';
import { TabBarItemComponent } from './components/tab-bar/tab-bar-item/tab-bar-item.component';
import { AddressInputComponent } from './components/address-input/address-input.component';
import { DropPanelComponent } from './components/drop-panel/drop-panel.component';
import { DropdownTriggerForDirective } from './directives/dropdown-trigger-for';
import { OverlayModule } from '@angular/cdk/overlay';
import { DialogTriggerForDirective } from './directives/dialog-trigger-for';
import { UnixPipe } from './pipes/unix.pipe';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { PullToRefreshModule } from '@piumaz/pull-to-refresh';
import { VerifiedComponent } from './components/verified/verified.component';
import { SubscriptionsComponent } from './components/subscriptions/subscriptions.component';
import { PaymentRequestButtonComponent } from './components/payment-request-button/payment-request-button.component';
import { PaymentCardsComponent } from './components/payment-cards/payment-cards.component';
import { CardBrandSvgComponent } from './components/card-brand-svg/card-brand-svg.component';
import { AddCardComponent } from './components/add-card/add-card.component';
import { PayComponent } from './components/pay/pay.component';
import { SubscribeComponent } from './components/subscribe/subscribe.component';
import { UserLikeSpaceCardComponent } from './components/user-like-space-card/user-like-space-card.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { CoverFileDropDirective } from './directives/cover-file-drop.directive';
import { AddressCountrySearchComponent } from './components/address-country-search/address-country-search.component';
import { CountryNotificationHeaderComponent } from './components/country-notification-header/country-notification-header.component';
import { HeaderSideBarComponent } from './components/header-side-bar/header-side-bar.component';
import { BackButtonCommonComponent } from './components/back-button-common/back-button-common.component';
import { RemoveFloatPipe } from './pipes/remove-float.pipe';
import { NotificationHeaderComponent } from './components/notification-header/notification-header.component';
import { CurrencyConversionPipe } from './pipes/currency-conversion.pipe';
import { PasteTextDirective } from './directives/paste-text.directive';

import { ShareModule } from 'ngx-sharebuttons';
import { SocialShareComponent } from './social-share/social-share.component';
import { HeightResizeDirective } from './directives/height-resize.directive';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { LightboxModule } from 'ngx-lightbox';
import { StarReviewsComponent } from './components/star-reviews/star-reviews.component';
import { OnboardingNotificationComponent } from './components/onboarding-notification/onboarding-notification.component';
import { UserNotificationSettingComponent } from './components/user-notification-setting/user-notification-setting.component';
import { NotificationDialogComponent } from './components/notification-dialog/notification-dialog.component';
import { FomoNotificationPopupComponent } from './components/fomo-notification-popup/fomo-notification-popup.component';
// import { DatexPipe } from "./pipes/datex.pipe";
import { WelcomeComponent } from './components/welcome/welcome.component';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { RecaptchaComponent } from './components/re-captcha/recaptcha/recaptcha.component';
// import { Ng2GoogleRecaptchaModule } from "ng2-google-recaptcha";
import {
  RECAPTCHA_SETTINGS,
  RecaptchaFormsModule,
  RecaptchaModule,
  RecaptchaSettings,
} from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { AddHomeModalComponent } from './components/add-home-modal/add-home-modal.component';
import { HomeUnavailablityDateComponent } from './components/home-unavailablity-date/home-unavailablity-date.component';
import { PhoneNumberInputComponent } from './components/phone-number-input/phone-number-input.component';
import { PhoneOtpInputComponent } from './components/phone-otp-input/phone-otp-input.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { StarRatingSliderComponent } from './components/star-rating-slider/star-rating-slider.component';
import { MaxHomePopupComponent } from './components/max-home-popup/max-home-popup.component';
// import { ViewHomeComponentComponent } from './components/view-home-component/view-home-component.component';
// import { EditHomeComponentComponent } from './components/edit-home-component/edit-home-component.component';
// import { EditHomeModalComponent } from './components/edit-home-modal/edit-home-modal.component';
import { NewStripeAddCardComponent } from './components/new-stripe-add-card/new-stripe-add-card.component';
import { PaymentOptionsComponent } from './components/payment-options/payment-options.component';
import { FlexibleDatesComponent } from './components/flexible-dates/flexible-dates.component';
import { SocialShareButtonComponent } from './components/social-share-button/social-share-button.component';
import { LoadDirective } from './directives/load.directive';
import { HomesCardComponentComponent } from './components/homes-card-component/homes-card-component.component';
import { ViewHomeComponentComponent } from './components/view-home-component/view-home-component.component';
import { EditHomeComponentComponent } from './components/edit-home-component/edit-home-component.component';
import { EditHomeModalComponent } from './components/edit-home-modal/edit-home-modal.component';
// import { HomesCardComponentComponent } from './components/homes-card-component/homes-card-component.component';
import { TokenPaymentCardsComponent } from './components/token-payment-cards/token-payment-cards.component';
import { BackgroundimageBottomSheetComponent } from './components/backgroundimage-bottom-sheet/backgroundimage-bottom-sheet.component';
import { GoogleMapViewComponent } from './components/google-map-view/google-map-view.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { AddHomeGoogleMapsComponent } from './components/add-home-google-maps/add-home-google-maps.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { ComboBoxComponent } from './components/combo-box/combo-box.component';
import { BirthdayCalendarComponent } from './components/birthday-calendar/birthday-calendar.component';
import { BankingDetailsComponent } from './components/banking-details/banking-details.component';
import { ViewBankingDetailsComponent } from './components/view-banking-details/view-banking-details.component';
import { BookingCalendarComponent } from './components/booking-calendar/booking-calendar.component';
import { ScrollTrackerDirective } from './directives/scrollTracker.directive';
import { TipaltiPaymentDetailsComponent } from './components/tipalti-payment-details/tipalti-payment-details.component';
import { PriceCalendarComponent } from './components/price-calendar/price-calendar.component';
import { CalendarDropPanelComponent } from './components/calendar-drop-panel/calendar-drop-panel.component';
import { CustomAddressMapComponent } from './components/custom-address-map/custom-address-map.component';
import { GoogleCountryDropdownComponent } from './components/google-country-dropdown/google-country-dropdown.component';
import { TwitterShareComponent } from './components/twitter-share/twitter-share.component';
import { PennyPopupComponent } from './components/penny-popup/penny-popup.component';
import { DesktopModalComponent } from './components/desktop-modal/desktop-modal.component';
import { GridImageViewerComponent } from './components/grid-image-viewer/grid-image-viewer.component';
import { HomeCardComponent } from './components/home-card/home-card.component';
import { CookieHandlerComponent } from './components/cookie-handler/cookie-handler.component';
import { DailyRewardPopupComponent } from './components/daily-reward-popup/daily-reward-popup.component';
import { AuthModule } from '../features/auth/auth.module';
import { SocialSignInComponent } from './components/daily-reward-popup/social-sign-in/social-sign-in-popup.component';
import { DailyStreakComponent } from './components/daily-streak/daily-streak.component';
// install Swiper modules
SwiperCore.use([
  Virtual,
  Navigation,
  Pagination,
  Scrollbar,
  EffectFade,
  EffectCards,
  EffectCoverflow,
  EffectCreative,
]);

@NgModule({
  declarations: [
    ButtonComponent,
    LoadingComponent,
    ThemeToggleComponent,
    PanelComponent,
    HeaderComponent,
    DropdownComponent,
    RippleDirective,
    AvatarComponent,
    BadgeComponent,
    DialogComponent,
    ToggleComponent,
    SpaceCardComponent,
    DragDropDirective,
    ListPickerComponent,
    FilterPipe,
    RatingComponent,
    ActivateNextInputDirective,
    SwitchComponent,
    MomentPipe,
    AccordionComponent,
    AccordionPanelComponent,
    ContenteditableDirective,
    BubbleSelectionComponent,
    ListSelectionComponent,
    BottomSheetComponent,
    BackgroundimageBottomSheetComponent,
    TurnicatePipe,
    CalendarComponent,
    IncrementComponent,
    RangeSliderComponent,
    SelectComponent,
    SafeResourcePipe,
    SpaceImagesComponent,
    SpaceRequirementComponent,
    SpaceIncrementComponent,
    TabBarComponent,
    TabBarItemComponent,
    AddressInputComponent,
    DropPanelComponent,
    DialogComponent,
    DialogTriggerForDirective,
    DropdownTriggerForDirective,
    UnixPipe,
    SnackbarComponent,
    VerifiedComponent,
    SubscriptionsComponent,
    PaymentRequestButtonComponent,
    PaymentCardsComponent,
    CardBrandSvgComponent,
    AddCardComponent,
    PayComponent,
    SubscribeComponent,
    UserLikeSpaceCardComponent,
    LanguageSelectorComponent,
    CoverFileDropDirective,
    AddressCountrySearchComponent,
    CountryNotificationHeaderComponent,
    HeaderSideBarComponent,
    BackButtonCommonComponent,
    RemoveFloatPipe,
    NotificationHeaderComponent,
    CurrencyConversionPipe,
    PasteTextDirective,
    SocialShareComponent,
    HeightResizeDirective,
    BreadcrumbsComponent,
    StarReviewsComponent,
    OnboardingNotificationComponent,
    UserNotificationSettingComponent,
    NotificationDialogComponent,
    FomoNotificationPopupComponent,
    DailyStreakComponent,
    // DatexPipe,
    WelcomeComponent,
    RecaptchaComponent,
    AddHomeModalComponent,
    HomeUnavailablityDateComponent,
    PhoneNumberInputComponent,
    PhoneOtpInputComponent,
    StarRatingSliderComponent,
    MaxHomePopupComponent,
    ViewHomeComponentComponent,
    EditHomeComponentComponent,
    EditHomeModalComponent,
    NewStripeAddCardComponent,
    PaymentOptionsComponent,
    FlexibleDatesComponent,
    SocialShareButtonComponent,
    HomesCardComponentComponent,
    LoadDirective,
    TokenPaymentCardsComponent,
    GoogleMapViewComponent,
    AddHomeGoogleMapsComponent,
    ComboBoxComponent,
    BirthdayCalendarComponent,
    BankingDetailsComponent,
    ViewBankingDetailsComponent,
    BookingCalendarComponent,
    ScrollTrackerDirective,
    PriceCalendarComponent,
    CalendarDropPanelComponent,
    CustomAddressMapComponent,
    TipaltiPaymentDetailsComponent,
    GoogleCountryDropdownComponent,
    TwitterShareComponent,
    PennyPopupComponent,
    DesktopModalComponent,
    GridImageViewerComponent,
    HomeCardComponent,
    CookieHandlerComponent,
    DailyRewardPopupComponent,
    SocialSignInComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
    HammerModule,
    DragDropModule,
    ScrollingModule,
    ExperimentalScrollingModule,
    SwiperModule,
    LazyLoadImageModule,
    // Ng2GoogleRecaptchaModule,
    PullToRefreshModule,
    OverlayModule,
    TranslateModule,
    Ng2SearchPipeModule,
    RecaptchaFormsModule,
    RecaptchaModule,
    ShareModule,
    // NgxPaginationModule,
    NgxMaskModule.forRoot(),
    LightboxModule,
    RxReactiveFormsModule,
    ImageCropperModule,
    GoogleMapsModule,
    GooglePlaceModule,
  ],
  providers: [
    AddHomeModalComponent,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey,
      } as RecaptchaSettings,
    },
  ],
  exports: [
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    DragDropModule,
    OverlayModule,
    NgxMaskModule,
    ScrollingModule,
    ButtonComponent,
    LoadingComponent,
    ThemeToggleComponent,
    PanelComponent,
    HeaderComponent,
    SwiperModule,
    // Ng2GoogleRecaptchaModule,
    RecaptchaComponent,
    DropdownComponent,
    RippleDirective,
    LazyLoadImageModule,
    AvatarComponent,
    BadgeComponent,
    DialogComponent,
    ToggleComponent,
    SpaceCardComponent,
    DragDropDirective,
    CoverFileDropDirective,
    ListPickerComponent,
    RatingComponent,
    HammerModule,
    FilterPipe,
    RecaptchaFormsModule,
    RecaptchaModule,
    // DatexPipe,
    ActivateNextInputDirective,
    SwitchComponent,
    MomentPipe,
    AccordionComponent,
    AccordionPanelComponent,
    ContenteditableDirective,
    BubbleSelectionComponent,
    ListSelectionComponent,
    BottomSheetComponent,
    BackgroundimageBottomSheetComponent,
    TurnicatePipe,
    IncrementComponent,
    CalendarComponent,
    RangeSliderComponent,
    SelectComponent,
    SafeResourcePipe,
    SpaceImagesComponent,
    SpaceRequirementComponent,
    TabBarComponent,
    TabBarItemComponent,
    DialogComponent,
    DialogTriggerForDirective,
    DropdownTriggerForDirective,
    DropPanelComponent,
    PullToRefreshModule,
    UnixPipe,
    SnackbarComponent,
    VerifiedComponent,
    AddressInputComponent,
    SubscriptionsComponent,
    PaymentRequestButtonComponent,
    PaymentCardsComponent,
    CardBrandSvgComponent,
    AddCardComponent,
    PayComponent,
    SubscribeComponent,
    UserLikeSpaceCardComponent,
    TranslateModule,
    LanguageSelectorComponent,
    AddressCountrySearchComponent,
    CountryNotificationHeaderComponent,
    HeaderSideBarComponent,
    BackButtonCommonComponent,
    NotificationHeaderComponent,
    CurrencyConversionPipe,
    PasteTextDirective,
    SocialShareComponent,
    ShareModule,
    HeightResizeDirective,
    Ng2SearchPipeModule,
    // NgxPaginationModule
    BreadcrumbsComponent,
    LightboxModule,
    StarReviewsComponent,
    OnboardingNotificationComponent,
    UserNotificationSettingComponent,
    NotificationDialogComponent,
    FomoNotificationPopupComponent,
    WelcomeComponent,
    AddHomeModalComponent,
    ImageCropperModule,
    StarRatingSliderComponent,
    MaxHomePopupComponent,
    ViewHomeComponentComponent,
    EditHomeComponentComponent,
    NewStripeAddCardComponent,
    PaymentOptionsComponent,
    FlexibleDatesComponent,
    PhoneNumberInputComponent,
    SocialShareButtonComponent,
    LoadDirective,
    HomesCardComponentComponent,
    HomesCardComponentComponent,
    TokenPaymentCardsComponent,
    GoogleMapViewComponent,
    GooglePlaceModule,
    ComboBoxComponent,
    BirthdayCalendarComponent,
    AddHomeGoogleMapsComponent,
    HomeUnavailablityDateComponent,
    BookingCalendarComponent,
    ScrollTrackerDirective,
    TipaltiPaymentDetailsComponent,
    PriceCalendarComponent,
    CalendarDropPanelComponent,
    CustomAddressMapComponent,
    GoogleCountryDropdownComponent,
    PennyPopupComponent,
    TwitterShareComponent,
    DesktopModalComponent,
    GridImageViewerComponent,
    HomeCardComponent,
    CookieHandlerComponent,
    DailyRewardPopupComponent,
    SocialSignInComponent,
    DailyStreakComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedModule {}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
