import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-max-home-popup',
  templateUrl: './max-home-popup.component.html',
  styleUrls: ['./max-home-popup.component.scss']
})
export class MaxHomePopupComponent implements OnInit {

  @Input() showMaxHomePopup: boolean = false;
  @Output() public maxPopupEvent = new EventEmitter();
  constructor( public router: Router) {  }

  ngOnInit(): void {
  }

  closeAddHomePopUp() {
    this.showMaxHomePopup = false;
    this.maxPopupEvent.emit();
  }

  contactUs() {
    this.router.navigate(["/legal/contact-us"])
    this.showMaxHomePopup = false;
    this.maxPopupEvent.emit();
  }

}
