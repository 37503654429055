import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { GoogleAnalyticsService } from '../services/google-analytics.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._handleRequest(route, state);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.authService.inMaintenance()) {
      this.router.navigate(['/maintenance']);
      return false;
    } else {
      return this._handleRequest(route, state);
    }
  }
  private _handleRequest(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    if (this.authService.validate()) {
      this.googleAnalyticsService.sendGAClientId();
      return true;
    }

    let url = state.url.split('?')?.[0];

    let params = {
      ...route.queryParams,
      redirect_to: url.replace('/', ''),
      fragment: route.fragment,
    };

    let path: string;

    if (url == '/') {
      path = '/auth';
    } else {
      path = '/auth/intro';
    }

    this.router.navigate([path, { outlets: { modal: null } }], {
      queryParams: params,
    });
    return false;
  }
}
