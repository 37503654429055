<div class="explore-section-main">
  <div class="app_container">
    <div class="filter_outer" [class.isOpenFilter]="showFilterMob">
      <div class="mobile_searchBox" *ngIf="!isDesktopView">
        <div class="back-arrow" *ngIf="!isDesktopView">
          <div class="explore_left">
            <div class="explore_brand">
              <span (click)="pop()"
                ><img src="/assets/images/back_arrow.svg"
              /></span>
            </div>
          </div>
        </div>
        <div class="searchPart" (click)="showFilterMobFun()">
          <p>
            {{
              location.full_address
                ? location.full_address
                : ("Where to?" | translate)
            }}
          </p>
          <div class="addres_show">
            <span *ngIf="!location.full_address">{{
              "Anywhere" | translate
            }}</span>
            <span *ngIf="!location.full_address">.</span>
            <span *ngIf="!differntMonth && !flexibleSelected"
              >{{
                filter?.started_at
                  ? (filter.started_at | moment : "DD")
                  : "Anytime"
              }}{{
                filter?.ended_at
                  ? "-" + (filter.ended_at | moment : "DD MMM")
                  : ""
              }}</span
            >
            <span *ngIf="differntMonth && !flexibleSelected"
              >{{
                filter?.started_at
                  ? (filter.started_at | moment : "DD MMM")
                  : "Anytime"
              }}{{
                filter?.ended_at
                  ? "-" + (filter.ended_at | moment : "DD MMM")
                  : ""
              }}</span
            >
            <span *ngIf="flexibleSelected">{{ this.searchDate }}</span>
            <span>.</span>
            <span>{{
              guestNumber == 0 || guestNumber == undefined
                ? "Any guests"
                : guestNumber > 1
                ? guestNumber + " guests"
                : guestNumber + " guest"
            }}</span>
          </div>
        </div>
        <div class="filterPart">
          <div
            class="filter-search-btn navbar"
            [class.arabic]="selectedLanguage == 'ar'"
          >
            <div class="dropdown">
              <button
                type="button"
                class="dropdown-toggle"
                (click)="handleOpenFilterModal()"
              >
                <img alt="" src="/assets/images/icons/filterBtn.svg" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="desktopFilter" *ngIf="isDesktopView"></div>
    </div>
    <app-explore-navigation
      [hiddenTitle]="true"
      (loadMoreByCategory)="loadHomeByCategory($event)"
      (filter)="updateFilter($event)"
    ></app-explore-navigation>
    <div class="explore-space-main" id="mainOuter">
      <div class="home" *ngIf="spaceList.length > 0; else homeElse">
        <div class="map">
          <app-new-map-result-view
            #resultMapViewSpaces
            class="map-design-style"
            [spaces]="spaceListMap"
            [searchLocation]="location"
          ></app-new-map-result-view>
        </div>
        <div class="emptySpace" [class.isIos]="isIOSDevice()"></div>
        <div class="spaces">
          <app-map-view-home-cards-list
            #mapViewHomeCards
            [spaceList]="spaceList"
            [total]="pagination.total"
            [btnLoad]="btnLoad"
            (filterChange)="updateFilter($event)"
            (loadMoreTrigger)="loadMoreData($event)"
            [pagination]="pagination"
          ></app-map-view-home-cards-list>
        </div>
      </div>
      <ng-template #homeElse>
        <div class="no-home-available">
          <h3>No homes available!</h3>
          <a [routerLink]="['/']">Return</a>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<app-explore-filter-modal
  #filterModal
  (onFilter)="updateFilter($event)"
  (onClear)="updateFilter(null)"
></app-explore-filter-modal>

<app-loading [loading]="loading"></app-loading>
