<div class="cookie-handler-overlay" *ngIf="_visible" (click)="close()"></div>

<div class="cookie-handler-content" *ngIf="_visible && !show_setting_modal">
  <p class="cookie-description">
    By clicking "Accept All Cookies", you agree to the storing of cookies on
    your device to enhance site navigation, analyze site usage, and assist in
    our marketing efforts.
  </p>

  <div class="cookie-handler-footer">
    <span class="text-button" (click)="toggleCookieSetting()"
      >Cookies Settings</span
    >

    <div class="cookie-button-group">
      <button class="primary-button" (click)="handleReject()">
        Reject All
      </button>
      <button class="primary-button" (click)="handleSetCookie(true)">
        Accept All Cookies
      </button>
    </div>
  </div>
</div>

<div
  class="cookie-handler-content cookie-setting-modal"
  *ngIf="_visible && show_setting_modal"
>
  <img src="/assets/new-logo/app-logo.svg" alt="" />
  <h5>Privacy Preference Center</h5>
  <p class="cookie-description">This website uses cookies.</p>
  <p class="cookie-description">
    We use cookies to personalise content and ads, to provide social media
    features and to analyze our traffic. We also share information about your
    use of our site with our social media, advertising and analytics partners
    who may combine it with other information that you’ve provided to them or
    that they’ve collected from your use of their services. This consent applies
    to <a href="https://www.holidayswap.com">www.holidayswap.com</a> and
    <a href="https://app.holidayswap.com">app.holidayswap.com</a> sites.
  </p>
  <a
    href="https://cookiepedia.co.uk/giving-consent-to-cookies"
    target="_blank"
    rel="noreferrer"
    class="more-information"
  >
    More information
  </a>
  <div class="primary-button-wrapper">
    <button class="primary-button" (click)="handleSetCookie(true)">
      Allow All
    </button>
  </div>

  <h5>Manage Consent Preferences</h5>

  <div class="cookie-accordion-wrapper">
    <div class="cookie-setting-accordion" (click)="onChangAccordion('1')">
      <div class="cookie-setting-accordion-header">
        <div class="accordion-header-title">Strictly Necessary Cookies</div>
        <div class="accordion-header-action">
          <span class="action-label">Always Active</span>
        </div>
        <img
          class="accordion-header-arrow"
          [class.active]="active_accordion === '1'"
          src="/assets/icons/arrow.svg"
          alt="arrow"
        />
      </div>
      <div
        *ngIf="active_accordion === '1'"
        class="cookie-setting-accordion-body"
      >
        <p class="description">
          These cookies are necessary for the website to function and cannot be
          switched off in our systems. They are usually only set in response to
          actions made by you which amount to a request for services, such as
          setting your privacy preferences, logging in or filling in forms. You
          can set your browser to block or alert you about these cookies, but
          some parts of the site will not then work. These cookies do not store
          any personally identifiable information.
        </p>
      </div>
    </div>

    <div class="cookie-setting-accordion" (click)="onChangAccordion('2')">
      <div class="cookie-setting-accordion-header">
        <div class="accordion-header-title">Performance Cookies</div>
        <div class="accordion-header-action">
          <label class="switch">
            <input
              type="checkbox"
              name="performance"
              (change)="handleChangeOption($event)"
            />
            <span class="slider round"></span>
          </label>
        </div>
        <img
          class="accordion-header-arrow"
          [class.active]="active_accordion === '2'"
          src="/assets/icons/arrow.svg"
          alt="arrow"
        />
      </div>
      <div
        *ngIf="active_accordion === '2'"
        class="cookie-setting-accordion-body"
      >
        <p class="description">
          These cookies allow us to count visits and traffic sources so we can
          measure and improve the performance of our site. They help us to know
          which pages are the most and least popular and see how visitors move
          around the site. All information these cookies collect is aggregated
          and therefore anonymous. If you do not allow these cookies we will not
          know when you have visited our site, and will not be able to monitor
          its performance.
        </p>
      </div>
    </div>

    <div class="cookie-setting-accordion" (click)="onChangAccordion('3')">
      <div class="cookie-setting-accordion-header">
        <div class="accordion-header-title">Functional Cookies</div>
        <div class="accordion-header-action">
          <label class="switch">
            <input
              type="checkbox"
              name="functional"
              (change)="handleChangeOption($event)"
            />
            <span class="slider round"></span>
          </label>
        </div>
        <img
          class="accordion-header-arrow"
          [class.active]="active_accordion === '3'"
          src="/assets/icons/arrow.svg"
          alt="arrow"
        />
      </div>
      <div
        *ngIf="active_accordion === '3'"
        class="cookie-setting-accordion-body"
      >
        <p class="description">
          These cookies enable the website to provide enhanced functionality and
          personalisation. They may be set by us or by third party providers
          whose services we have added to our pages. If you do not allow these
          cookies then some or all of these services may not function properly.
        </p>
      </div>
    </div>

    <div class="cookie-setting-accordion" (click)="onChangAccordion('4')">
      <div class="cookie-setting-accordion-header">
        <div class="accordion-header-title">Targeting Cookies</div>
        <div class="accordion-header-action">
          <label class="switch">
            <input
              type="checkbox"
              name="targeting"
              (change)="handleChangeOption($event)"
            />
            <span class="slider round"></span>
          </label>
        </div>
        <img
          class="accordion-header-arrow"
          [class.active]="active_accordion === '4'"
          src="/assets/icons/arrow.svg"
          alt="arrow"
        />
      </div>
      <div
        *ngIf="active_accordion === '4'"
        class="cookie-setting-accordion-body"
      >
        <p class="description">
          These cookies may be set through our site by our advertising partners.
          They may be used by those companies to build a profile of your
          interests and show you relevant adverts on other sites. They do not
          store directly personal information, but are based on uniquely
          identifying your browser and internet device. If you do not allow
          these cookies, you will experience less targeted advertising.
        </p>
      </div>
    </div>
  </div>

  <div class="cookie-handler-footer">
    <div class="cookie-button-group">
      <button class="primary-button" (click)="handleReject()">
        Reject All
      </button>
      <button class="primary-button" (click)="handleSetCookie()">
        Confirm My Choices
      </button>
    </div>
  </div>
</div>
