import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment/moment';
import { SnackbarService } from '../../services/snackbar.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {
  @Input() public mode: 'single' | 'range' = 'single';
  @Input() public date: Date | string = new Date();
  @Input() public min: Date | string;
  @Input() public max: Date | string;
  @Input() public startDate: Date | string;
  @Input() public clickDisable: boolean = false;
  @Input() public alignMode: 'slide' | 'list' = 'slide';

  @Input() public unavailable: Date[] | string[] = [];

  @Input() public endDate: Date | string;
  @Input() public destroy: boolean = true;

  @Output() public dateChange = new EventEmitter();

  public dayNames = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
  public calendar = [];
  public nextCalendar = [];
  public calendarGroup = [];
  public years = [];
  public months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];
  public activeDate = moment().toDate();
  public state = '';
  public mask: any = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

  public open = false;

  constructor(
    private elementRef: ElementRef,
    private snackbarService: SnackbarService,
  ) {
  }

  ngOnChanges(changes) {
    if (
      changes.startDate &&
      changes.startDate.currentValue !== changes.startDate.previousValue
    ) {
      // this.date = this.startDate;
      this.buildCalendar();
    }

    if (
      changes.endDate &&
      changes.endDate.currentValue !== changes.endDate.previousValue
    ) {
      this.buildCalendar();
    }

    if (
      changes.unavailable &&
      changes.unavailable.currentValue !== changes.unavailable.previousValue
    ) {
      this.buildCalendar();
    }
  }

  ngOnInit() {
    this.date = moment(this.date).format('YYYY-MM-DD');
    this.activeDate = moment().toDate();

    if (this.mode === 'range') {
      // this.startDate = this.date;
    }

    this.buildCalendar();
    this.buildYears();
    this.buildCalendarGroup();
  }

  public close() {
    this.open = false;
  }

  public show() {
    this.open = true;
  }

  public onToggle() {
    this.open = !this.open;
  }

  @HostListener('document:mousedown', ['$event'])
  public onGlobalClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      // clicked outside => close dropdown list
      if (this.destroy == true) {
        this.date = null;
        this.startDate = null;
        this.endDate = null;
        this.buildCalendar();
      }
      this.open = false;
    }
  }

  public buildYears() {
    const years = [];
    for (let index = 0; index <= 1; index++) {
      years.push((this.activeDate.getFullYear() + index).toString());
    }
    this.years = years;
  }

  public setMonth(month) {
    this.state = '';
    this.activeDate = moment(this.activeDate).set('month', month).toDate();
    this.buildCalendar();
  }

  public setYear(year) {
    this.state = '';
    this.activeDate = moment(this.activeDate).set('year', year).toDate();
    this.buildCalendar();
  }

  public changeMonth(type: number) {
    const date = moment(this.activeDate);

    if (type > 0) {
      date.add(1, 'month');
    } else {
      date.subtract(1, 'month');
    }

    const visibleYear =
      type > 0 ? moment(date).add(1, 'month').year() : date.year();

    if (this.years.includes(visibleYear.toString())) {
      this.activeDate = date.toDate();

      this.buildCalendar();
    } else {
      this.snackbarService.show({
        message: 'Please select date in available years',
        type: 'success',
        config: {
          class: 'centered',
        },
      });
    }
  }

  onSelect(day: any) {
    if (day.disabled) {
      return;
    }

    if (this.mode === 'single') {
      this.date = moment(day.date).format('YYYY-MM-DD');
      this.dateChange.emit(this.date);
    } else {
      if (!this.startDate) {
        this.startDate = moment(day.date).format('YYYY-MM-DD');
      } else if (!this.endDate) {
        this.endDate = moment(day.date).format('YYYY-MM-DD');
      } else {
        this.startDate = moment(day.date).format('YYYY-MM-DD');
        this.endDate = undefined;
      }

      if (this.startDate && this.endDate) {
        if (moment(this.startDate).isAfter(moment(this.endDate))) {
          const start = this.startDate;
          const end = this.endDate;

          this.startDate = end;
          this.endDate = start;
        } else if (moment(this.startDate).isSame(moment(this.endDate), 'day')) {
          const start = this.startDate;
          const end = moment(this.startDate).add(1, 'day').startOf('day')

          this.startDate = start;
          this.endDate = end.format('YYYY-MM-DD');
        }
      }

      this.dateChange.emit({
        start: this.startDate,
        end: this.endDate,
        days:
          this.startDate && this.endDate
            ? moment(this.endDate).diff(moment(this.startDate), 'days', true)
            : 0,
      });
    }

    localStorage.setItem('flexibleSelect', 'false');
    this.buildCalendar();
    this.buildCalendarGroup();

    this.close();
  }

  public buildCalendar() {
    const startDay = moment(this.activeDate).startOf('month').startOf('week').subtract(1, 'day');
    const endDay = moment(this.activeDate).endOf('month').endOf('week').subtract(1, 'day');

    const startClone = startDay.clone();

    if (startClone.add(7, 'day').date() === 1) {
      startDay.add(1, 'week');
    }

    if (endDay.date() > 4 && endDay.date() < 7) {
      endDay.subtract(1, 'week');
    }

    this.calendar = this.generateCalendar(startDay, endDay);

    // if (this.mode === "range") {
    this.buildNextCalendar(endDay);
    // }
  }

  public buildNextCalendar(startDay: moment.Moment) {
    const month = moment(this.activeDate).add(1, 'month').month();

    const startDayOfMonth = startDay.add(1, 'day');
    const endDayOfMonth = moment(this.activeDate)
      .add(1, 'month')
      .endOf('month')
      .endOf('week');

    if (endDayOfMonth.month() !== month && endDayOfMonth.day() === 0) {
      endDayOfMonth.subtract(1, 'week');
    }

    this.nextCalendar = this.generateCalendar(startDayOfMonth, endDayOfMonth);
  }

  public buildCalendarGroup() {
    this.calendarGroup = [];
    let startDay = moment(this.activeDate).startOf('month').startOf('week').subtract(1, 'day');
    let endDay = moment(this.activeDate).endOf('month').endOf('week').subtract(1, 'day');

    let startClone = startDay.clone();
    let endClone = endDay.clone();

    if (startClone.add(7, 'day').date() === 1) {
      startDay.add(1, 'week');
    }

    if (endDay.date() > 4 && endDay.date() < 7) {
      endDay.subtract(1, 'week');
    }

    for (let i = 1; i <= 12; i++) {
      const currentMonth = moment(this.activeDate).add(i - 1, 'month');
      const calendar = this.generateCalendar(startDay, endDay, currentMonth);
      this.calendarGroup.push(calendar);

      startDay = endClone.add(1, 'day');
      endDay = moment(this.activeDate)
        .add(i, 'month')
        .endOf('month')
        .endOf('week')
        .subtract(1, 'day');

      if (endDay.month() !== currentMonth.month() && endDay.day() === 0) {
        endDay.subtract(1, 'week');
      }

      startClone = startDay.clone();
      endClone = endDay.clone();
    }
  }

  public getNextMonthDate() {
    return moment(this.activeDate).add(1, 'month');
  }

  public generateCalendar(startDay: Moment, endDay: Moment, month?: Moment): any[] {
    const calendar = [];
    const date = startDay.clone().subtract(1, 'day');

    while (date.isBefore(endDay, 'day')) {
      calendar.push({
        title: month?.format('MMMM YYYY'),
        days: Array(7)
          .fill(0)
          .map((n, i) => {
            const d = date.add(1, 'day').clone();
            const unavailable = this._isDateUnavailable(d);

            let passedDates;
            if (!passedDates) {
              passedDates = this.passedDays(d);
            }

            let disabled = false;

            if (this.max && moment(this.max).isBefore(moment(d))) {
              disabled = true;
            }

            if (this.min && moment(this.min).isAfter(moment(d))) {
              disabled = true;
            }

            if (unavailable) {
              disabled = true;
            }
            return {
              number: date.date(),
              currentMonth: date.month() === moment(this.activeDate).month(),
              isToday:
                date.month() === moment().month() &&
                date.date() === moment().date(),
              date: d,
              format: moment(d).format('YYYY-MM-DD'),
              unavailable,
              disabled,
              passedDates,
              isSelected:
                this.mode === 'single'
                  ? moment(this.date).isSame(moment(d), 'day')
                  : this._findDateRange(d),
            };
          }),
      });
    }

    return calendar;
  }

  public onDateChange(event) {
    if (event.target.value) {
      this.date = moment(event.target.value).format('YYYY-MM-DD');
      this.dateChange.emit(this.date);
      this.buildCalendar();
    }
  }

  private _isDateUnavailable(d) {
    return this.unavailable && this.unavailable.length > 0
      ? this.unavailable.some((date) => moment(date).isSame(moment(d), 'day'))
      : false;
  }

  private passedDays(d) {
    if (d < new Date()) {
      return true;
    } else {
      return false;
    }
  }

  private _findDateRange(d) {
    const start = moment(this.startDate);
    const end = moment(this.endDate);

    return this.startDate && (start.isSame(moment(d), 'day') || moment(d).isBetween(start, end, 'day', '[]'));
  }

  public resetDates() {
    // for(let d=0; d<week.days)
    this.startDate = null;
    this.endDate = null;
    this.buildCalendar();
    this.buildCalendarGroup();
  }

  public updateUnavailable(dates) {
    this.unavailable = dates;
    this.buildCalendar();
    this.buildCalendarGroup();
  }
}
