import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-google-map-view',
  templateUrl: './google-map-view.component.html',
  styleUrls: ['./google-map-view.component.scss'],
})
export class GoogleMapViewComponent implements OnInit {
  @Input() height: string = '300px';
  @Input() zoom: number = 5;
  @Input() latitude: number = 51.07612361380574;
  @Input() longitude: number = 10.208893086823068;
  currentLocation = new google.maps.LatLng(
    51.07612361380574,
    10.208893086823068
  );
  map;
  marker;
  options: google.maps.MapOptions = {
    zoom: this.zoom,
    center: { lat: 51.07612361380574, lng: 10.208893086823068 },
    gestureHandling: 'greedy',
    minZoom: +1,
    maxZoom: +22,
    zoomControl: false,
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    draggable: true,
    restriction: {
      latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
    },
  };

  markerOptions: google.maps.MarkerOptions = {
    draggable: false,
    icon: 'assets/images/marker.png',
    animation: google.maps.Animation.DROP,
  };
  markerPositions: google.maps.LatLngLiteral[] = [];
  @Output() public onReady = new EventEmitter();

  constructor() {}

  async ngOnInit(): Promise<void> {
    this.map = new google.maps.Map(
      document.getElementById('gmapUserSpace'),
      this.options
    );

    setTimeout(async () => {
      if (this.latitude == 0 && this.longitude == 0) {
        this.map.setZoom(3);
      } else {
        this.map.setZoom(this.zoom);
      }

      if (this.latitude != undefined && this.longitude != undefined) {
        this.currentLocation = new google.maps.LatLng(
          this.latitude,
          this.longitude
        );
        await this.map.panTo(this.currentLocation);
        this.setMarker();
      }
    }, 2000);
  }

  setMarker() {
    if (this.marker && this.marker.setMap) {
      this.marker.setMap(null);
    }

    this.marker = new google.maps.Marker({
      position: this.currentLocation,
      icon: 'assets/images/marker.png',
    });

    this.marker.setMap(this.map);
  }

  async flyTo(latitude, longitude) {
    this.longitude = longitude;
    this.latitude = latitude;
    this.currentLocation = new google.maps.LatLng(
      this.latitude,
      this.longitude
    );
    await this.map.panTo(this.currentLocation);
    this.setMarker();
  }
}
