import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class ExploreSpacesService {

  constructor(private httpService: HttpService) { }

  public getFeaturedSpaces() {
		return this.httpService.get(`/featured-spaces`);
  }

  public getTopHundredCategories(){
    return this.httpService.get(`/tophundred/1`)
  }


  public getFeaturedLocations() {
		return this.httpService.get(`/featured-location`);
  }

  public getSearchSpaces(filter: string, additionalFilter?: boolean) {
	const url = additionalFilter ? "/v2/search-space" : "/search-spaces-native";
		return this.httpService.get(`${url}${filter}`);
  }

  public getSpaceFortheMap(filter: string, additionalFilter?: boolean) {
	const url = additionalFilter ? "/v2/search-space-map" : "/search-spaces-map";
	return this.httpService.get(`${url}${filter}`);
  }

  public getSpaceDetails(id: number) {
		return this.httpService.get(`/space-details/${id}`).pipe(map((res: { data: any }) => res.data));
  }
  public getAllHomes() {
		return this.httpService.get(`/all-homes`);
  }

  public getSearchHistory() {
		return this.httpService.get(`/users/me/get-search-history`);
  }
  public saveSearchHistory(data: any) {
		return this.httpService.post(`/users/me/save-search`, data);
  }

  public similarSearch(){
	return this.httpService.get(`/users/me/similar-locations`);


  }
}
