import { EventEmitter, Injectable, Output } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Cacheable } from 'ts-cacheable';
import { IUser } from '../interfaces/user';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public hideCounter: boolean = true;
  public bonusToken: boolean = false;
  public allowedMaxHomes: string = '0';
  public token = '';
  public loadingToken = false;
  myInterval;
  cDateMillisecs: any;
  tDateMillisecs: any;
  currentDate: any;
  targetDate: any;
  oldDate: any;
  difference: any;
  seconds: any;
  minutes: any;
  hours: any;
  days: any;
  localMin = 10;
  localSec = 0;

  userDetailsUpdateEvent = new EventEmitter<any>();
  // @Output() languageDropStatus: EventEmitter<boolean> = new EventEmitter();
  public currentUserSubject = new BehaviorSubject<IUser>({} as IUser);
  public currentUser = this.currentUserSubject
    .asObservable()
    .pipe(distinctUntilChanged());
  public static cacheBuster$ = new Subject<void>();
  public playIntroVideo = new BehaviorSubject(false);
  private cleverAction = new Subject<void>();
  public verifyMail = new Subject();
  public maxAddHomeReached = new Subject();
  completed_action: string[] = [];
  public isAbondedcheck = false;
  public isAbondedPremiumCheck = false;

  public totalToken = new Subject<any>();

  constructor(private httpService: HttpService) {}

  // @Cacheable({
  // 	cacheBusterObserver: UserService.cacheBuster$,
  // })
  public getMe() {
    return this.httpService.get(`/users/me`).pipe(
      map((response: { data: IUser }) => {
        this.currentUserSubject.next(response.data);
        let userData = {
          id: response.data.id,
          user_name: response.data.first_name + ' ' + response.data.last_name,
          email: response.data.email,
          date_of_birth: response.data.date_of_birth,
          user_review: response.data.userreview,
          first_name: response.data.first_name,
        };
        this.allowedMaxHomes = response.data?.allowMaxHome;
        localStorage.setItem('allowMaxHome', response.data?.allowMaxHome);
        localStorage.setItem('userDataTEST', JSON.stringify(response.data));
        localStorage.setItem('userData', JSON.stringify(userData));
        return response;
      })
    );
  }

  public getDailyStreaks() {
    return this.httpService.get(`/users/me/daily-streaks`);
  }

  public getCleverTapAction() {
    return this.httpService
      .get(`/clevertap-action-get`)
      .subscribe((data: any) => {
        if (data && data?.length) {
          this.completed_action = data?.map((actionObj) => actionObj.action);
        }
      });
  }

  public getCoins() {
    if (!this.token) {
      this.loadingToken = true;
    }

    return this.getMe()
      .subscribe((data) => {
        this.token = data.data.tokens
          .toString()
          ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      })
      .add(() => {
        this.loadingToken = false;
      });
  }

  public getFriendToken(id: string) {
    return this.httpService.get(`/users/${id}`);
  }

  @Cacheable()
  public getProfile(id: string) {
    return this.httpService.get(`/users/${id}`);
  }

  public updateDashboardCover(data: any) {
    return this.httpService.put(`/users/me/dashboard-coverimage`, data).pipe(
      map((response: any) => {
        // Update the currentUser observable
        this.currentUserSubject.next(response);
        return response;
      })
    );
  }

  public update(data: any) {
    return this.httpService.put(`/users/me/`, data).pipe(
      map((response: { data: IUser }) => {
        // Update the currentUser observable

        this.currentUserSubject.next(response.data);
        return response;
      })
    );
  }

  public removeProfileImage(data: any) {
    return this.httpService.post(`/users/me/remove-image`, data).pipe(
      map((response: { data: IUser }) => {
        // Update the currentUser observable
        this.currentUserSubject.next(response.data);
        return response;
      })
    );
  }

  public addTokens(amount) {
    const user = this.getCurrentUser();
    user.tokens = parseInt(user.tokens);
    user.tokens = parseInt(user.tokens) + parseInt(amount);
    this.currentUserSubject.next(user);
  }

  public verifyUser(data: { method: string }) {
    return this.httpService.post(`/users/me/verifications`, data).pipe(
      map((response: { data }) => {
        // Update the currentUser observable
        UserService.cacheBuster$.next();
        return response;
      })
    );
  }

  public isPhoneVerified() {
    const user = this.getCurrentUser();

    return this.httpService.get(`/users/me/phone-verified`);
  }

  offerTimer() {
    this.difference = moment.duration(this.difference - 1000, 'milliseconds');
    if (this.difference <= 0) {
      this.hideCounter = true;
      this.bonusToken = false;
      this.minutes = this.difference.minutes();
      this.seconds = this.difference.seconds();
      this.stopTimer();
      this.updateUserOffer();
      // this.counterStart();
      return;
    } else {
      this.bonusToken = true;
      // this.days = Math.floor(this.hours / 24);

      // this.hours %= 24;
      // this.minutes %= 60;
      // this.seconds %= 60;
      // this.hours = this.hours < 10 ? '0' + this.hours : this.hours;
      // this.minutes = this.minutes < 10 ? '0' + this.minutes : this.minutes;
      // this.seconds = this.seconds < 10 ? '0' + this.seconds : this.seconds;
      // document.getElementById('days').innerText = this.days;
      // document.getElementById('hours').innerText = this.hours;

      // this.difference = moment.duration(this.difference - 1000, 'milliseconds');
      this.minutes = this.difference.minutes();
      this.seconds = this.difference.seconds();
      this.minutes =
        parseInt(this.minutes) < 10 ? '0' + this.minutes : this.minutes;
      this.seconds =
        parseInt(this.seconds) < 10 ? '0' + this.seconds : this.seconds;
      if (document.getElementById('mins')) {
        document.getElementById('mins').innerText = this.minutes;
      }
      if (document.getElementById('seconds')) {
        document.getElementById('seconds').innerText = this.seconds;
      }
      this.setStorage(parseInt(this.minutes), parseInt(this.seconds));
      this.hideCounter = false;
    }
  }

  setStorage(minutes, seconds) {
    localStorage.setItem('verifyLocalMin', JSON.stringify(minutes));
    localStorage.setItem('verifyLocalSec', JSON.stringify(seconds));
  }

  stopTimer() {
    clearInterval(this.myInterval);
    this.myInterval = undefined;
    this.hideCounter = true;
  }

  counterStart() {
    var duration = moment.duration({
      minutes: this.localMin,
      seconds: this.localSec,
    });

    this.currentDate = new Date();
    this.targetDate = moment(this.currentDate).add(duration);
    this.cDateMillisecs = moment(this.currentDate).valueOf();
    this.tDateMillisecs = moment(this.targetDate).valueOf();
    // this.cDateMillisecs = this.currentDate.getTime();
    // this.tDateMillisecs = this.targetDate.getTime();
    this.setStorage(this.localMin, this.localSec);
    this.difference = this.tDateMillisecs - this.cDateMillisecs;
    const localSecend = localStorage.getItem('verifyLocalSec');
    const localMinend = localStorage.getItem('verifyLocalMin');
    if (localSecend == '1' && localMinend == '0') {
      this.hideCounter = true;
      this.stopTimer();
      return;
    }
    this.myInterval = setInterval(() => {
      // clearInterval(this.myInterval);
      this.offerTimer();
    }, 1000);
  }

  updateUserOffer() {
    this.update({ subscription_offer: 0 }).subscribe((res) => {
      if (res) {
        this.getUpdatedMe();
      }
    });
  }

  getUpdatedMe() {
    this.getMe().subscribe((res: any) => {
      if (res.data.subscription_offer) {
        this.hideCounter = false;

        if (!this.myInterval) {
          this.counterStart();
        }
      } else {
        this.hideCounter = true;
      }
    });
  }

  public requestPhoneVerification(data: {
    telephone_no: string;
    country_code: string;
    phone_no: string;
  }) {
    return this.httpService.post(`/users/me/verify/phone`, data);
  }

  public confirmPhoneVerification(data: { code: number }) {
    return this.httpService.post(`/users/me/verify/phone-code`, data);
  }

  public emailVerification(data: { email: string }) {
    return this.httpService.post(`/email/verification-notification`, data);
  }

  public saveUserLogin(data) {
    return this.httpService.post(`/save-user-names`, data);
  }

  public saveUtM(data) {
    return this.httpService.post(`/save-utm`, data);
  }

  public getCurrentUser(): IUser {
    return this.currentUserSubject.value;
  }

  public getCountdown() {
    return this.httpService.get(`/users/me/countdown`);
  }

  public discountCountdown(data: { stops_at: string }) {
    return this.httpService.post(`/users/me/countdown`, data);
  }

  public contactUs(data) {
    return this.httpService.post(`/customer-support`, data);
  }

  public uninterestedSpace(data) {
    return this.httpService.post(`/users/me/uninterested-space`, data);
  }

  public getInvitationCode() {
    return this.httpService.get(`/users/me/invitation-code`);
  }

  public getProgressTrackerDetails() {
    return this.httpService.get(`/users/me/progress-tracker`);
  }

  public paypalTokenPurchase(data) {
    return this.httpService.post(`/transaction`, data);
  }

  public userDetailsActionUpdate() {
    this.userDetailsUpdateEvent.emit();
  }

  public setUserReview(reviewNo) {
    let userDataStorage = localStorage.getItem('userData');
    let userDataTestStorage = localStorage.getItem('userDataTEST');
    let userDataTest = JSON.parse(userDataTestStorage);
    let userData = JSON.parse(userDataStorage);

    let tempUserData = {
      id: userData.id,
      user_name: userData.user_name,
      first_name: userDataTest.first_name,
      email: userData.email,
      date_of_birth: userData.date_of_birth,
      user_review: reviewNo,
    };

    localStorage.setItem('userData', JSON.stringify(tempUserData));

    return this.httpService.post(`/user-review`, {
      user_id: userData.id,
      review: reviewNo,
    });
  }

  public updateProfileCompletionStatus() {
    let userDataStorage = localStorage.getItem('userData');
    let userData = JSON.parse(userDataStorage);

    return this.httpService.post(`/users/me/profile-completion`, {
      user_id: userData.id,
      profile_completion: '1',
    });
  }

  public deactivateAccount(reason: string) {
    return this.httpService.delete(`/users/me`, {
      deactivation_reason: reason,
    });
  }
  // public sendLanguageDropStatus(status: boolean){
  // 	this.languageDropStatus.emit(status);
  // }

  // public getUserReviews() {
  // 	let userDataStorage = localStorage.getItem("userData");
  // 	let userData = JSON.parse(userDataStorage);

  // 	return this.httpService.get(`/users/me/get-app-reviews/${userData.id}`);
  // }

  public clearBadgeCount(data: { email: string; clevertap_id: string }) {
    this.httpService
      .post(`/clevertap-badge-count`, data)
      .subscribe((result: any) => {});
  }

  public cleverTapAction(data) {
    return this.httpService.post(`/clevertap-action-update`, data);
  }

  public updateWelcomeStatus() {
    return this.httpService.post('/users/me/disable-popup', {
      welcome_popup: 1,
    });
  }

  public updateVideoWatch() {
    return this.httpService.post('/users/me/video-viewed', {
      video_viewed: true,
    });
  }

  public getExploreData() {
    return this.httpService.get('/users/me-explore');
  }

  public getUserAllToken() {
    return this.httpService.get(`/users/me/total-tokens`);
  }

  public postAnalytics(url = '', click_event = '') {
    return this.httpService.post('/user-journey', {
      session_id: localStorage.getItem('uuid'),
      page_url: url,
      click_event: click_event,
    });
  }

  public updateKeenCity(data) {
    return this.httpService.post('/keen-city-save', { cityIds: data });
  }

  public resendEmail(id) {
    return this.httpService.post('/resend-email-register-verification', {
      email: id,
    });
  }

  public getuserSpaceCount() {
    return this.httpService.get('/users/me/space-count');
  }

  public acceptSmsBooking(booking_id) {
    return this.httpService.post(`/booking/${booking_id}/status/sms-accept`, {
      accepted_by: 'sms',
    });
  }

  public getUserSubscriptionAndToken() {
    return this.httpService.get('/users/me/subscription-token');
  }

  public checkPennyUser(home_id, email) {
    return this.httpService.get(
      `/check-penny-user?space_id=${home_id}&email=${email}`
    );
  }

  public pennyUserRegister(data: { email: string }) {
    return this.httpService.post(`/penny-user-register`, data);
  }

  public pennyUserUpdatePopup(data: { email: string; popup_status: number }) {
    return this.httpService.post(`/update-popup-status`, data);
  }

  public getInTouch(data: {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    message: string;
  }) {
    return this.httpService.post(`/get-in-touch`, data);
  }
}
