<div class="popular_widget_main">
  <div class="trending_header">
    <span>
      {{ "My Homes" | translate }}
    </span>
    <button
      class="btn-right"
      (click)="goToSpace()"
      [class.btn-pe-none]="block_btn"
    >
      {{ "Add home" | translate }}
    </button>
  </div>

  <div class="swiper_area">
    <swiper #swiper [config]="config" (slideChange)="onSlideChange()">
      <ng-container *ngFor="let space of mySpaces; index as i">
        <ng-template swiperSlide>
          <ng-container *ngIf="mySpaces.length > 0">
            <!-- {{space|json}} -->
            <div class="popular_widget_item">
              <div class="card" *ngIf="space.id != null">
                <div class="popular_image">
                  <img
                    class="card-img-top lazyload"
                    [lazyLoad]="space.webp_image"
                    (click)="navigateToHome(space.id)"
                  />
                  <div class="popular_action">
                    <ul>
                      <li>
                        <a
                          ><button
                            type="button"
                            class="delete_btn"
                            (click)="deleteSpace(space)"
                          >
                            <i class="fa fa-trash"></i></button
                        ></a>
                      </li>
                      <li>
                        <a
                          ><button type="button" (click)="editSpace(space)">
                            {{ "Edit" | translate }}
                          </button></a
                        >
                      </li>
                      <!-- <li *ngIf="space?.is_favourited"><a (click)="onLike(space,i)"><i class="fa fa-heart" aria-hidden="true"></i></a></li>
                                      <li *ngIf="!space?.is_favourited"><a (click)="onLike(space,i)"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li> -->
                    </ul>
                  </div>
                </div>
                <div
                  class="card-body pointer"
                  (click)="navigateToHome(space.id)"
                >
                  <div class="popular_title">
                    <h5 class="card-title" *ngIf="space?.title">
                      {{
                        space?.title?.length > 40
                          ? space?.title?.substring(0, 40)
                          : space?.title
                      }}
                      {{ space?.title?.length > 40 ? "..." : "" }}
                    </h5>
                    <h5 class="card-title" *ngIf="!space?.title"></h5>

                    <!--                                      <span class="rating_item" *ngIf="space?.rating?.average > 0"><img src="/assets/images/star_ic.svg" >-->
                    <!--                                      <span class="rating_list">-->
                    <!--                                        {{space?.rating?.average }}-->
                    <!--                                      </span>-->

                    <!--                                      -->
                    <!--                                      </span>-->
                  </div>
                  <span
                    class="card-location"
                    *ngIf="space?.address?.country || space?.address?.city"
                    ><img src="/assets/images/mark_gray_ic.svg" />&nbsp;{{
                      space?.address?.country
                        ? space?.address?.country + ","
                        : " "
                    }}
                    {{ space?.address?.city }}</span
                  >
                  <div class="popular_des">
                    <span
                      >{{ space?.bedrooms }}
                      {{
                        (space?.bedrooms > 1 ? "Bedrooms" : "Bedroom")
                          | translate
                      }}
                      . {{ space?.guests }}
                      {{
                        (space?.guests > 1 ? "guests" : "guest") | translate
                      }}</span
                    >
                    <span *ngIf="space?.rating?.total_reviews > 0"
                      >{{ space?.rating?.total_reviews }}
                      {{
                        space?.rating?.total_reviews == 1
                          ? "review"
                          : ("reviews" | translate)
                      }}</span
                    >
                  </div>
                  <div class="popular_footer">
                    <span
                      class="explore_price"
                      *ngIf="
                        space?.hosting_amount >= 0 &&
                        space?.purpose != 'swap' &&
                        space.payment_type_id == 561
                      "
                    >
                      <label
                        class="tan-label"
                        *ngIf="space?.tan_home_id != null"
                        >{{ "From " | translate }}</label
                      >
                      <span>${{ space?.hosting_amount | number }}</span>
                      <span class="gray_text pl-5">
                        {{ "per night" | translate }}</span
                      >
                    </span>
                    <span
                      class="explore_price"
                      *ngIf="
                        space?.hosting_tokens > 0 &&
                        space?.purpose != 'swap' &&
                        space.payment_type_id != 561
                      "
                    >
                      <img src="/assets/images/Token_Plane.png" />
                      <span>{{ space?.hosting_tokens | number }}</span>
                      <span class="gray_text pl-5">
                        {{ "per night" | translate }}</span
                      >
                    </span>
                    <span class="swap_show" *ngIf="space?.purpose == 'swap'">
                      <img src="/assets/images/new_swap_icon.svg" alt="" />
                      <span>{{ "Swap" | translate }}</span>
                    </span>
                  </div>

                  <!-- <hr>
                                  <div class="popular_footer">

                                      <span class="explore_price" *ngIf="space?.hosting_amount > 0 && space?.purpose != 'swap' && space.payment_type_id == 561">
                                      <span>{{ space?.hosting_amount }}</span>
                                    </span>
                                    <span class="explore_price" *ngIf="space?.hosting_tokens > 0 && space?.purpose != 'swap' && space.payment_type_id != 561">
                                        <img src="/assets/images/Token_Plane.png">
                                      <span>{{ space?.hosting_tokens }}</span>

                                    </span>
                                    <span class="swap_show" *ngIf="space?.purpose == 'swap'">
                                      <img src="/assets/images/new_swap_icon.svg" alt="">
                                      <span>{{"Swap" | translate}}</span>
                                    </span>
                                    <span *ngIf="space?.address?.country || space?.address?.city"><img src="/assets/images/mark_gray_ic.svg" >&nbsp;{{space?.address?.country ? space?.address?.country +' , ' : ' '}} {{space?.address?.city}}</span>
                                  </div> -->
                </div>
              </div>

              <div
                class="card tokens_card pointer"
                [class.btn-pe-none]="block_btn"
                *ngIf="space.id == null"
                (click)="goToTokenPurchase()"
              >
                <img
                  class="half_ring"
                  src="/assets/images/half_ring.png"
                  alt=""
                />
                <p class="para1">
                  {{
                    "HS Points are the in app currency of Holiday Swap allowing you to travel the world for much less."
                      | translate
                  }}
                </p>
                <p class="text-orange font-bold para2">
                  {{ "1 HS Point = $1" | translate }}
                </p>
                <p class="text-orange para3">
                  {{ "My HS Points" | translate }}
                </p>
                <img
                  class="girl_image"
                  [class.arabic]="selectedLanguage == 'ar'"
                  src="/assets/images/girl_img_2x.png"
                  alt=""
                />
                <img
                  class="token_image"
                  [class.arabic]="selectedLanguage == 'ar'"
                  src="/assets/images/explore_card_token.png"
                  alt=""
                />
                <img
                  class="dot_arrow"
                  [class.arabic]="selectedLanguage == 'ar'"
                  src="/assets/images/dot-arrow.png"
                  alt=""
                />
              </div>
            </div>
          </ng-container>
        </ng-template>
      </ng-container>
    </swiper>
  </div>

  <div class="button_footer">
    <button class="btn-block" (click)="goToSpace()">
      {{ "Add home" | translate }}
    </button>
  </div>
</div>

<div *ngIf="modalBackdrop" class="bix__modal-backdrop dark_backdrop"></div>
<div
  class="modal home-delete-modal-main"
  id="spacedeleteModal"
  [style.max-width.px]="deviceWidth"
>
  <!-- <button type="button" class="btn-close close-popup-style" data-bs-dismiss="modal">
  </button> -->
  <div class="modal-dialog modal-lg">
    <div class="modal-content swap-request-content-box">
      <div class="modal-body swap-request-body-style">
        <div class="swap-request-box">
          <h4>
            {{
              "Are you sure you want to delete this home listing?" | translate
            }}
          </h4>
          <p>
            {{
              "Once you delete it, there's no way to bring it back." | translate
            }}
          </p>
          <div class="btn_wrapper">
            <button
              type="button"
              data-bs-dismiss="modal"
              (click)="closeBackDrop()"
              class="btn cancel-btn-style btn-common-style underlined_text"
            >
              {{ "Cancel" | translate }}
            </button>
            <button
              type="button"
              class="btn accept-btn-style btn-common-style"
              (click)="onDelete()"
            >
              {{ "Delete home" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div class="modal-parent-complete"> -->
