<div class="plans-area social_wrapper">
    <!-- <div class="payment_btn gg_btn"  (click)="onGoogle()" >
        <button type="button"  [ngClass]="{'plans-area-direOverride': selectedLanguage == 'ar', 'plans-area-override': selectedLanguage != 'ar'}"><i class="fa fa-google" aria-hidden="true"></i>&nbsp;{{"Continue with Google" |translate}}</button>
    </div> -->

    <!-- <div class="payment_btn fb_btn" (click)="onFacebook()">
       <button type="button"  [ngClass]="{'plans-area-direOverride': selectedLanguage == 'ar', 'plans-area-override': selectedLanguage != 'ar'}"><i class="fa fa-facebook-square" aria-hidden="true"></i>&nbsp;{{"Continue with Facebook" | translate}}</button>

   </div> -->
   <!-- <div class="payment_btn ap_btn" (click)="onApple()">
       <button type="button"  [ngClass]="{'plans-area-direOverride': selectedLanguage == 'ar', 'plans-area-override': selectedLanguage != 'ar'}"><i class="fa fa-apple" aria-hidden="true"></i>&nbsp;{{"Continue with Apple" | translate}}</button>
   </div> -->
   <!-- <div class="login_link">
       <p>Already have an account?<a href="#"> Sign in</a></p>
   </div> -->


    <div *ngIf="!hiddenGoggle" class="payment_btn social-btn"  (click)="onGoogle()" >
        <button type="button"  [ngClass]="{'plans-area-direOverride': selectedLanguage == 'ar', 'plans-area-override': selectedLanguage != 'ar'}"><i class="fa fa-google" aria-hidden="true"></i></button>
    </div>

    <div *ngIf="!hiddenApple" class="payment_btn social-btn"  (click)="onApple()">
        <button type="button"  [ngClass]="{'plans-area-direOverride': selectedLanguage == 'ar', 'plans-area-override': selectedLanguage != 'ar'}"><i class="fa fa-apple" aria-hidden="true"></i></button>
    </div>

</div>
<app-loading  [loading]="logged"></app-loading>
