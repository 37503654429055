<div class="explore-cards">
  <div class="loader" *ngIf="loading">
    <svg
      width="48"
      height="48"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>
        .spinner_ajPY {
          transform-origin: center;
          animation: spinner_AtaB 0.75s infinite linear;
        }
        @keyframes spinner_AtaB {
          100% {
            transform: rotate(360deg);
          }
        }
      </style>
      <path
        d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
        opacity=".25"
      />
      <path
        d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"
        class="spinner_ajPY"
      />
    </svg>
  </div>

  <div class="explore-cards-container" *ngIf="!loading">
    <div class="explore-grid" *ngIf="homeList.length">
      <app-home-card
        *ngFor="let item of homeList.slice(0, 16)"
        [space]="item"
      ></app-home-card>
    </div>

    <div class="explore-empty" *ngIf="!homeList.length">
      <h3>No homes available!</h3>
      <a href="/">Return</a>
    </div>
  </div>

  <div class="explore-cards-review-section" [class.stayAway]="!homeList.length">
    <img class="explore-bg-image" [lazyLoad]="reviewImage" />
    <div class="explore-cards-review-container">
      <h2 class="review-container-title">
        List your home on <span>Holiday Swap</span>
      </h2>
      <p class="review-container-description">
        Unleash the power of our homeowner community! Homes with confidence or
        cash in on lucrative bookings. Join us now and elevate your home-sharing
        experience!
      </p>

      <div class="explore-review-cards">
        <swiper [config]="config">
          <ng-container *ngFor="let review of reviews">
            <ng-template swiperSlide>
              <div
                swiperSlide
                class="explore-review-card"
                (click)="toggleContent(review.id)"
              >
                <div class="explore-review-card-header">
                  <img [lazyLoad]="review?.image_url" />
                  <h3>{{ review.name }}</h3>
                </div>
                <p [attr.id]="'testimonalsCard' + review.id">
                  {{ review.content }}
                </p>
              </div>
            </ng-template>
          </ng-container>
        </swiper>
      </div>

      <a routerLink="/add-home-deeplink" class="list-button">List your home</a>
    </div>
  </div>

  <div class="explore-cards-container">
    <div class="explore-grid" *ngIf="homeList.length">
      <app-home-card
        *ngFor="let item of homeList.slice(16)"
        [space]="item"
      ></app-home-card>
    </div>

    <div
      class="explore-load-more"
      *ngIf="!hideLoadMore"
      (click)="loadMore.emit()"
    >
      <button>
        Load more homes
        <svg
          *ngIf="loading"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <style>
            .spinner_ajPY {
              transform-origin: center;
              animation: spinner_AtaB 0.75s infinite linear;
            }
            @keyframes spinner_AtaB {
              100% {
                transform: rotate(360deg);
              }
            }
          </style>
          <path
            d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
            opacity=".25"
          />
          <path
            d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"
            class="spinner_ajPY"
          />
        </svg>
      </button>
    </div>
  </div>
</div>
