import { Injectable } from "@angular/core";
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpErrorResponse
} from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, filter, flatMap, mergeMap, switchMap, take, tap } from "rxjs/operators";
import { AuthService } from "../services/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "../services/user.service";
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	private isRefreshing = false;
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	constructor(private authService: AuthService,
		private route: ActivatedRoute,
		private router: Router, private userService:UserService) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const modifiedReq = req.clone({
			headers: req.headers.set("X-localization", "english"),
		});

		let authReq = modifiedReq;
		const token = localStorage.getItem("token");
    const isOutsideRequest = req.headers.get("IsOutside");

		if (token !== null && !isOutsideRequest) {
			authReq = this.addTokenHeader(req, token);
		}


		return next.handle(authReq).pipe(catchError(error => {
			if (error instanceof HttpErrorResponse && !authReq.url.includes("auth") && error.status === 401) {
				return this.handle401Error(authReq, next);
			}
			// if(error.status === 403 && error.error?.message == 'Your email address is not verified.'){
				// this.userService.verifyMail.next(true);
			// }

			return throwError(error);
		}));
	}

	private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
		if (!this.isRefreshing) {
			this.isRefreshing = true;
			this.refreshTokenSubject.next(null);
			let selectedLang= localStorage.getItem("lang")

			return this.authService.refreshAccessToken().pipe(
				switchMap((token: any) => {
					this.isRefreshing = false;

					this.authService.validate(token.access_token);

					this.refreshTokenSubject.next(token.access_token);

					return next.handle(this.addTokenHeader(request, token.access_token));
				}),
				catchError((err) => {
					this.isRefreshing = false;
					this.authService.clear();
					localStorage.clear();
					localStorage.setItem("lang", selectedLang)
					return throwError(err);
				})
			);

		}

		return this.refreshTokenSubject.pipe(
			filter(token => token !== null),
			take(1),
			switchMap((token) => next.handle(this.addTokenHeader(request, token)))
		);
	}

	private addTokenHeader(request: HttpRequest<any>, token: string) {
		return request.clone({ headers: request.headers.set("Authorization", `Bearer ${token}`) });
	}
}
