import { FormControl } from '@angular/forms';
import { LanguageService } from 'src/app/shared/services/language.service';
import { MetadataService } from 'src/app/shared/services/metadata.service';
import { Router } from '@angular/router';
import { TokenService } from 'src/app/shared/services/token.service';
import { ClevertapService } from 'src/app/shared/services/clevertap.service';
import { LocationService } from './../../services/location.service';
import { ThemeService } from 'src/app/core/services/theme.service';
import {
  AfterViewInit,
  KeyValueDiffer,
  KeyValueDiffers,
  NgZone,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from './../../services/snackbar.service';
import { SpaceService } from './../../services/space.service';
// import { Buffer } from './../../../../../node_modules/bl/node_modules/buffer/index';
import { ImageCroppedEvent } from './../../../../../node_modules/ngx-image-cropper/lib/interfaces/image-cropped-event.interface.d';
import {
  Component,
  OnInit,
  OnDestroy,
  HostListener,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { UploadService } from '../../services/upload.service';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { UserService } from '../../services/user.service';
import * as moment from 'moment';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { AddHomeGoogleMapsComponent } from '../add-home-google-maps/add-home-google-maps.component';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { TranslateService } from '@ngx-translate/core';
import { GoogleMapViewComponent } from '../google-map-view/google-map-view.component';
import { CustomAddressMapComponent } from '../custom-address-map/custom-address-map.component';
import { ComboBoxComponent } from '../combo-box/combo-box.component';
import { merge } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { countrylist } from '../../services/countrylist.service';
import { AuthService } from '../../services/auth.service';
import { ToastrService } from 'ngx-toastr';

declare var $: any;
export function debounce(delay: number = 300): MethodDecorator {
  return (
    _target: any,
    _propertyKey: string,
    descriptor: PropertyDescriptor
  ) => {
    let timeout = null;

    const original = descriptor.value;

    descriptor.value = function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => original.apply(this, args), delay);
    };

    return descriptor;
  };
}

enum AddHomeStep {
  uploadImages = 1,
  nightlyRate = 2,
  map = 3,
  address = 4,
  rightSpot = 5,
  mobileNumber = 10,
  success = 11,
}

@Component({
  selector: 'app-add-home-modal',
  templateUrl: './add-home-modal.component.html',
  styleUrls: ['./add-home-modal.component.scss'],
})
export class AddHomeModalComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() public exitModal = new EventEmitter();
  @Output() public onSuccess = new EventEmitter();
  @Input() public editData: any;
  @Input() public formSignUp: boolean = false;
  @ViewChild('rtype') rtypeRef: ComboBoxComponent;
  @ViewChild('countriesRef') countriesRef: ComboBoxComponent;
  progressWidth = '0';
  selectedCountry: string = null;
  serverErrors: any = [];
  serverErrorList = [];
  outsideClick = false;
  checkedEmail = false;

  shareMessage =
    'I just listed my Home on Holiday Swap so I can earn thousands and travel for free!  Check it out! ';
  public verification: any = {};
  public btn_block = false;
  loading = false;
  selectedAddress = false;
  public completePercentage = 0;
  activeStep = AddHomeStep.uploadImages as number;
  stepNumber = 1;
  exitClickedOnce = false;
  addressForm: FormGroup;
  homeDetailForm: FormGroup;
  hometypeForm: FormGroup;
  aminitiesForm: FormGroup;
  showImg = false;
  isPhoneVerified = false;
  userEmail;
  userData: any;
  isSpaceComplete;
  hideOtpInput = false;
  cropEnabled = false;
  slideBg;
  amountSliderBg;
  sentTime = 0;
  removeImg = false;
  renderderOnce = false;
  patchedOnce = false;
  // otpConfirmed=false;
  fileName: any;
  original_fileName: any;
  resendHide = true;
  showCropper = false;
  // localEditData:any;
  croppedImage: any = '';
  imageChangedEvent: any = '';
  placeholderImg = 'assets/images/placeholder-img.png';
  spaceDetails;
  onlyToken = false;
  originalFile: any = '';
  public location: any = {};
  public space: any = {
    id: 0,
    title: '',
    description: '',
    completed_percentage: 0,
    guests: 0,
    images: Array(6)
      .fill(0)
      .map((x) => {
        return {
          id: '',
          tag: '',
          image_path: '',
        };
      }),
    originalImages: Array(6)
      .fill(0)
      .map((x) => {
        return {
          id: '',
          tag: '',
          image_path: '',
        };
      }),
    bedrooms: 0,
    bathrooms: 0,
    amenities: [],
    accessibilities: [],
    atmospheres: [],
    unavailable_dates: [],
    address: {},
    landscapes: [],
    rules: [],
    safeties: [],
    purpose_id: 520,
    hosting_tokens: 49,
    hosting_amount: 49,
    is_instant: 0,
    dates: [],
    isAlreadyAdded: false,
    unavailable_dates_edit: [],
    flat_no: '',
    street: '',
    city: '',
    country: '',
    continent: '',
    postcode: 0,
    latitude: 0,
    longitude: 0,
    state: '',
    postcode_1: '',
    full_address: '',
    street_1: '',
    street_2: '',
    payment_type_id: 561,
    countryIso: '',
    country_code: '',
    phone_no: '',
  };

  excludeCountries: string[] = [
    'AFGHANISTAN',
    'BELARUS',
    'BURUNDI',
    'CENTRAL AFRICAN REPUBLIC',
    'CHAD',
    'CONGO',
    'CRIMEA',
    'CUBA',
    'DEMOCRATIC REPUBLIC OF CONGO',
    'DONETSK PEOPLES REPUBLIC',
    'ERITREA',
    'IRAQ',
    'IRAN',
    'LIBYA',
    'LUGANSK PEOPLES REPUBLIC',
    'MYANMAR',
    'NORTH KOREA',
    'SOMALIA',
    'SOUTH SUDAN',
    'SUDAN',
    'SYRIA',
    'RUSSIA',
    'VENEWZUELA',
    'YEMEN',
    'UKRAINE',
    'VENEZUELA',
  ];

  public payment_type = {
    cash: false,
    purpose: {
      host: false,
      swap: true,
      list: true,
      all: false,
    },
  };
  settings = {
    fill: '#FFA300',
    background: '#fffaef',
  };
  resultHide = true;
  public validTokenVal = false;
  public locations = [];
  public outsideViewImage: any = { tag_id: 555, image_url: '' };
  public livingRoomImages: any = { tag_id: 544, image_url: '' };
  public bedRoomImages: any = { tag_id: 541, image_url: '' };
  public kitchenImages: any = { tag_id: 552, image_url: '' };
  public bathRoomImages: any = { tag_id: 542, image_url: '' };
  public guestRoomImages: any = { tag_id: 551, image_url: '' };

  public original_outsideViewImage: any = { tag_id: 555, image_url: '' };
  public original_livingRoomImages: any = { tag_id: 544, image_url: '' };
  public original_bedRoomImages: any = { tag_id: 541, image_url: '' };
  public original_kitchenImages: any = { tag_id: 552, image_url: '' };
  public original_bathRoomImages: any = { tag_id: 542, image_url: '' };
  public original_guestRoomImages: any = { tag_id: 551, image_url: '' };
  clickedImg = {
    id: '',
    tagId: NaN,
    index: NaN,
    imgUrl: '',
  };
  add_btn_block: boolean = false;
  markers = [];
  public selectedLanguage = 'en';
  public categories: any = {};
  private dataDiffer: KeyValueDiffer<string, any>;
  // homeImages=[];

  public remaining: any;
  // private readonly cooldown: number = 60;

  private interval: any;

  isDesktopView: boolean = window.screen.width > 1024;
  public purpose_type: any = [];
  cardSliderShow = false;
  tokenSliderShow = false;

  public combainedAddress = '';

  @HostListener('window:resize', ['$event'])
  handleResize(event: KeyboardEvent) {
    this.isDesktopView = window.screen.width > 1024;
  }
  @ViewChild('addHomeGooglemap')
  addHomeGooglemap: AddHomeGoogleMapsComponent;

  @ViewChild('addHomeConfirm')
  addHomeConfirm: GoogleMapViewComponent;

  @ViewChild('pinSpotLocation')
  pinSpotLocation: CustomAddressMapComponent;

  inputoptions: Options = new Options({
    // types: ['geocode'],
    fields: [
      'formatted_address',
      'geometry',
      'place_id',
      'address_components',
      'type',
    ],
  });

  public countries: any = countrylist;

  markerUpdated: boolean = false;

  homeTypes: any = [
    {
      name: 'An entire place',
      desc: 'Guests have the whole place to themselves',
      checked: false,
      status: true,
      id: 565,
    },
    {
      name: 'A private room',
      desc: 'Guests sleep in a private room but some areas may be shared with you or others',
      checked: false,
      status: true,
      id: 566,
    },
    {
      name: 'A shared room',
      desc: 'Guests sleep in a room or common area that may be shared with you or others',
      checked: false,
      status: true,
      id: 567,
    },
  ];

  place_type = 565;
  places_listing_error = '';
  neighbourhood_error = '';
  description_loading = false;
  chatgptDescriptionReceived: boolean = false;
  titleFromChatGPT = '';
  descriptionFromChatGPT = '';

  constructor(
    public uploadService: UploadService,
    private spaceService: SpaceService,
    private snackbarService: SnackbarService,
    private themeService: ThemeService,
    private locationService: LocationService,
    private languageService: LanguageService,
    private metadataService: MetadataService,
    private tokenService: TokenService,
    public userService: UserService,
    private clevertapService: ClevertapService,
    public fb: FormBuilder,
    private differs: KeyValueDiffers,
    public router: Router,
    private cd: ChangeDetectorRef,
    private googleAnalyticsService: GoogleAnalyticsService,
    private zone: NgZone,
    public translate: TranslateService,
    private authService: AuthService,
    private toastr: ToastrService
  ) {
    this.progressWidth = (this.stepNumber / 6) * 100 + '%';
    this.addressForm = this.fb.group({
      city: [''],
      country: ['', [Validators.required]],
      state: [''],
      full_address: [''],
      latitude: [0],
      longitude: [0],
      postcode: [''],
      postcode_1: ['', [Validators.required]],
      street: [''],
      street_1: ['', [Validators.required]],
      street_2: [''],
    });

    this.homeDetailForm = this.fb.group({
      description: [
        '',
        [
          Validators.required,
          RxwebValidators.minLength({ value: 50 }),
          RxwebValidators.maxLength({ value: 5000 }),
        ],
      ],
      title: [
        '',
        [
          Validators.required,
          RxwebValidators.minLength({ value: 10 }),
          RxwebValidators.maxLength({ value: 500 }),
        ],
      ],
    });

    this.hometypeForm = this.fb.group({
      bathrooms: [
        '',
        [
          Validators.required,
          RxwebValidators.maxNumber({ value: 20 }),
          RxwebValidators.minNumber({ value: 1 }),
          RxwebValidators.numeric({ allowDecimal: false, isFormat: true }),
        ],
      ],
      bedrooms: [
        '',
        [
          Validators.required,
          RxwebValidators.maxNumber({ value: 20 }),
          RxwebValidators.minNumber({ value: 1 }),
          RxwebValidators.numeric({ allowDecimal: false, isFormat: true }),
        ],
      ],
      guests: [
        '',
        [
          Validators.required,
          RxwebValidators.maxNumber({ value: 20 }),
          RxwebValidators.minNumber({ value: 1 }),
          RxwebValidators.numeric({ allowDecimal: false, isFormat: true }),
        ],
      ],
    });

    this.aminitiesForm = this.fb.group({
      accessibilities: new FormArray([]),
      amenities: new FormArray([]),
      rules: new FormArray([]),
      safeties: new FormArray([]),
      neighbourhood_picks: new FormArray([]),
      places_or_listing_picks: new FormArray([]),
    });

    this.metadataService
      .getSpaceCategories()
      .subscribe((response: { data: any }) => {
        this.categories = response.data;

        let neighborhoodOverride = this.categories.neighbourhood_picks.map(
          (s) => ({ ...s, isActive: false })
        );
        this.categories.neighbourhood_picks = neighborhoodOverride;

        let placesListingOverride = this.categories.places_or_listing_picks.map(
          (s) => ({ ...s, isActive: false })
        );
        this.categories.places_or_listing_picks = placesListingOverride;
      });

    let loadLang = this.languageService.GetLanguage() + '';
    if (loadLang == 'null' || loadLang == '') {
      this.selectedLanguage = 'en';
    } else {
      this.selectedLanguage = loadLang;
    }
  }

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem('userDataTEST'));
    $('.add-home-form-modal').addClass('hide');

    if (!this.isDesktopView && this.isIOSDevice()) {
      $('.add-home-form-modal').css('padding-top', '80px');
      $('.close-section').css('top', '68px');
    }

    this.authService.checkMailVerification(this.userData.email).subscribe(
      (res: any) => {
        if (res.isVerified === false) {
          this.exitModal.emit();
          this.userService.verifyMail.next(true);
        } else {
          this.checkedEmail = true;
          $('.add-home-form-modal').removeClass('hide');
          $('.app-navigation').addClass('hide');
          $('.app-content').addClass('addHomeModal');
          if (this.editData) {
            this.dataDiffer = this.differs.find(this.editData).create();
          }
          UserService.cacheBuster$.next();
          this.userService.getMe().subscribe((user) => {
            this.userEmail = user.data?.email;
          });
          if (this.editData) {
            this.cd.detectChanges();
            this.patchEditdata(this.editData);
          }
          this.userService.isPhoneVerified().subscribe((res: any) => {
            this.isPhoneVerified = res;
          });

          // this.userEmail = userData?.email;
          // setTimeout(() => {
          // 	this.notificationService.sendMeANotification({"title": 'Congratulations!', "text": 'You have added your first home, now explore the world, make a booking/swap, and earn more bonus tokens.'});
          // }, 10000);
          this.userService
            .getProgressTrackerDetails()
            .subscribe((response: any) => {
              this.isSpaceComplete = response?.data;
            });

          merge(
            this.addressForm.get('street_1').valueChanges,
            this.addressForm.get('street_2').valueChanges,
            this.addressForm.get('city').valueChanges,
            this.addressForm.get('state').valueChanges,
            this.addressForm.get('postcode_1').valueChanges,
            this.addressForm.get('country').valueChanges
          )
            .pipe(debounceTime(500))
            .subscribe((res) => {
              this.updateMarker();

              if (
                this.titleFromChatGPT != '' &&
                this.titleFromChatGPT.toLowerCase() ==
                  this.homeDetailForm.get('title')?.value.toLowerCase()
              ) {
                this.chatgptDescriptionReceived = false;
              } else if (this.homeDetailForm.get('title')?.value == '') {
                this.chatgptDescriptionReceived = false;
              } else {
                this.chatgptDescriptionReceived = true;
              }

              if (
                this.descriptionFromChatGPT != '' &&
                this.descriptionFromChatGPT.toLowerCase() ==
                  this.homeDetailForm.get('description')?.value.toLowerCase()
              ) {
                this.chatgptDescriptionReceived = false;
              } else if (this.homeDetailForm.get('description')?.value == '') {
                this.chatgptDescriptionReceived = false;
              } else {
                this.chatgptDescriptionReceived = true;
              }
            });

          merge(
            this.hometypeForm.get('bathrooms').valueChanges,
            this.hometypeForm.get('bedrooms').valueChanges,
            this.hometypeForm.get('guests').valueChanges
          )
            .pipe(debounceTime(500))
            .subscribe((res) => {
              if (
                this.hometypeForm.dirty &&
                this.hometypeForm.valid &&
                this.hometypeForm.get('bathrooms').value > 0 &&
                this.hometypeForm.get('bedrooms').value > 0 &&
                this.hometypeForm.get('guests').value > 0
              ) {
                if (
                  this.titleFromChatGPT != '' &&
                  this.titleFromChatGPT.toLowerCase() ==
                    this.homeDetailForm.get('title')?.value.toLowerCase()
                ) {
                  this.chatgptDescriptionReceived = false;
                } else if (this.homeDetailForm.get('title')?.value == '') {
                  this.chatgptDescriptionReceived = false;
                } else {
                  this.chatgptDescriptionReceived = true;
                }

                if (
                  this.descriptionFromChatGPT != '' &&
                  this.descriptionFromChatGPT.toLowerCase() ==
                    this.homeDetailForm.get('description')?.value.toLowerCase()
                ) {
                  this.chatgptDescriptionReceived = false;
                } else if (
                  this.homeDetailForm.get('description')?.value == ''
                ) {
                  this.chatgptDescriptionReceived = false;
                } else {
                  this.chatgptDescriptionReceived = true;
                }
              }
            });

          this.aminitiesForm.controls[
            'neighbourhood_picks'
          ].valueChanges.subscribe((res) => {
            if (res?.length > 1) {
              this.neighbourhood_error = '';

              if (
                this.titleFromChatGPT != '' &&
                this.titleFromChatGPT.toLowerCase() ==
                  this.homeDetailForm.get('title')?.value.toLowerCase()
              ) {
                this.chatgptDescriptionReceived = false;
              } else if (this.homeDetailForm.get('title')?.value == '') {
                this.chatgptDescriptionReceived = false;
              } else {
                this.chatgptDescriptionReceived = true;
              }

              if (
                this.descriptionFromChatGPT != '' &&
                this.descriptionFromChatGPT.toLowerCase() ==
                  this.homeDetailForm.get('description')?.value.toLowerCase()
              ) {
                this.chatgptDescriptionReceived = false;
              } else if (this.homeDetailForm.get('description')?.value == '') {
                this.chatgptDescriptionReceived = false;
              } else {
                this.chatgptDescriptionReceived = true;
              }
            }
          });

          this.aminitiesForm.controls[
            'places_or_listing_picks'
          ].valueChanges.subscribe((res) => {
            if (res?.length > 1) {
              this.places_listing_error = '';

              if (
                this.titleFromChatGPT != '' &&
                this.titleFromChatGPT.toLowerCase() ==
                  this.homeDetailForm.get('title')?.value.toLowerCase()
              ) {
                this.chatgptDescriptionReceived = false;
              } else if (this.homeDetailForm.get('title')?.value == '') {
                this.chatgptDescriptionReceived = false;
              } else {
                this.chatgptDescriptionReceived = true;
              }

              if (
                this.descriptionFromChatGPT != '' &&
                this.descriptionFromChatGPT.toLowerCase() ==
                  this.homeDetailForm.get('description')?.value.toLowerCase()
              ) {
                this.chatgptDescriptionReceived = false;
              } else if (this.homeDetailForm.get('description')?.value == '') {
                this.chatgptDescriptionReceived = false;
              } else {
                this.chatgptDescriptionReceived = true;
              }
            }
          });

          // Fire the AppsFlyer event
          (window as any).AF('pba', 'event', {
            eventType: 'EVENT',
            eventName: 'add_home_step_1',
            eventValue: {
              description: 'Add images',
            },
          });
        }
      },
      () => {
        this.toastr.error('Checking email validation failed', 'Error');
      }
    );
  }

  isIOSDevice() {
    return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  }

  ngAfterViewInit() {
    this.loadPersentage();
    setTimeout(() => {
      this.sliderUpdate();
    }, 500);
  }
  ngDoCheck(): void {
    if (this.editData) {
      const changes = this.dataDiffer.diff(this.editData);
      if (changes && !this.patchedOnce) {
        this.patchEditdata(this.editData);
        this.patchedOnce = true;
      }
    }
  }
  onFeatureCheckboxChange(event: any, formName) {
    const selectedValues = this.aminitiesForm.controls[formName] as FormArray;
    if (event.target.checked) {
      selectedValues.push(new FormControl(event.target.value));
    } else {
      const index = selectedValues.controls.findIndex(
        (x) => x.value === event.target.value
      );
      selectedValues.removeAt(index);
    }
  }

  get dates() {
    return this.homeDetailForm.get('dates') as FormArray;
  }
  loadPersentage() {
    let persent = (this.space.hosting_tokens / 500) * 100 + '%';
    let amountPersent = (this.space.hosting_amount / 500) * 100 + '%';
    this.slideBg =
      'linear-gradient(90deg, rgb(255, 109, 46)' +
      persent +
      ', rgb(253, 240, 233)' +
      persent +
      ')';
    this.amountSliderBg =
      'linear-gradient(90deg, rgb(28, 201, 26)' +
      amountPersent +
      ', rgb(187,239,186)' +
      amountPersent +
      ')';
  }
  addDates() {
    return this.fb.group({
      id: [''],
      ended_at: [''],
      started_at: [''],
    });
  }
  public onSelect(location) {
    if (
      location.type == 'place' ||
      location.type == 'district' ||
      location.type == 'region' ||
      location.type == 'country'
    ) {
      this.snackbarService.show({
        message: this.translate.instant(
          'Please enter your home street address.'
        ),
        type: 'success',
        config: {
          class: 'centered',
        },
      });
      this.location.full_address = '';
      this.locations = [];
      this.resultHide = true;
    } else {
      this.selectedAddress = true;
      this.locations = [];
      this.location = location;
      this.resultHide = true;
      if (this.markers.length > 0) this.clear();
      this.onAddMarker(location);
      // this.flyTo(location);
      this.patchAddress(location);
    }
  }

  @debounce(300)
  async autoCompleteAddress(query) {
    this.add_btn_block = true;

    this.resultHide = false;

    let _self = this;

    $.each(query?.address_components, function () {
      if (this.types[0] == 'country') {
        let name = this.long_name;
        const excluded = _self.excludeCountries.some((country) =>
          name?.toUpperCase().includes(country.toUpperCase())
        );

        if (excluded) {
          _self.snackbarService.show({
            message: _self.translate.instant(
              'Sorry!  Your country / region is currently not supported by Holiday Swap.'
            ),
            type: 'success',
            config: {
              class: 'centered',
            },
          });
          _self.location = [];
          _self.location.full_address = '';
          _self.locations = [];
          query = '';
          return;
        }
      }
    });

    setTimeout(() => {
      if (query?.formatted_address?.length >= 1) {
        let formatted_address = query?.formatted_address;
        this.location.full_address = query?.formatted_address;
        this.resultHide = false;
        // this.addressCicked = true;
        let searchAddressComponents = query?.address_components;
        let searchGeometry = query?.geometry;

        this.markerUpdated = false;
        this._patchAddress(
          searchAddressComponents,
          searchGeometry,
          formatted_address
        );
        this.patchAddress(this.location);
        this.onAddMarker(this.location);
        this.add_btn_block = false;
      } else {
        this.resultHide = true;
        this.locations = [];
        this.location = [];
        this.patchAddress(this.locations);
      }
    }, 3000);
  }

  _patchAddress(searchAddressComponents, geometry, formatted_address) {
    let address: any = {};
    let street_number = '';
    let street_route = '';
    let stateSet: boolean = false;
    $.each(searchAddressComponents, function () {
      if (this.types[0] == 'postal_code') {
        address.postcode = this.long_name;
      }

      if (this.types[0] == 'administrative_area_level_1') {
        address.state = this.long_name;
        // stateSet = true;
      }

      if (this.types[0] == 'administrative_area_level_2') {
        address.state = this.long_name;
        // stateSet = true;
      }

      if (this.types[0] == 'country') {
        address.country = this.long_name;
      }

      if (this.types[0] == 'colloquial_area' && this.types[1] == 'locality') {
        address.city = this.long_name;
      }

      if (this.types[0] == 'sublocality_level_1') {
        address.line_2 = this.long_name;
      }

      if (this.types[0] == 'postal_town') {
        address.city = this.long_name;
      }

      if (this.types[0] == 'locality') {
        // address.line_2 = this.long_name;
        address.city = this.long_name;
      }

      if (this.types[0] == 'street_number') {
        street_number = this.long_name;
      }
      if (this.types[0] == 'route') {
        street_route = this.long_name;
      }
    });

    address.full_address = formatted_address;
    address.longitude = geometry?.location.lng();
    address.latitude = geometry?.location.lat();

    if (street_number != '' && street_route != '') {
      address.street = street_number + ' ' + street_route;
    } else if (street_number == '' && street_route != '') {
      address.street = street_route;
    }

    if (address?.length > 0) {
      this.locations = address;
      this.location = address;
    }
    this.location = address;

    // return address;
  }

  patchAddress(address) {
    this.addressForm.patchValue({
      city: address.city ? address.city : address.county ? address.county : '',
      country: address.country ? address.country : '',
      state: address.state ? address.state : '',
      full_address: address.full_address ? address.full_address : '',
      latitude: address.latitude ? address.latitude : 0,
      longitude: address.longitude ? address.longitude : 0,
      postcode: address.postcode ? address.postcode : '',
      postcode_1: address.postcode ? address.postcode : '',
      street: address.street ? address.street : '',
      street_1: address.street ? address.street : '',
      street_2: address.line_2 ? address.line_2 : '',
    });
  }

  private _parseResult(feature) {
    const address: any = {};
    if (feature.place_type[0] == 'place') {
      for (const result of feature.context) {
        if (result.id.includes('postcode')) {
          address.postcode = result.text;
        }
        if (result.id.includes('locality')) {
          address.line_2 = result.text;
        }
        if (result.id.includes('place')) {
          address.city = result.text;
        }
        if (result.id.includes('district')) {
          address.county = result.text;
        }
        if (result.id.includes('region')) {
          address.state = result.text;
        }
        if (result.id.includes('country')) {
          address.country = result.text;
        }
      }
    }
    // First line of address
    if (feature.text || feature.address) {
      address.street =
        (feature.address ? feature.address + ' ' : '') +
        (feature.text ? feature.text : '');
    }

    if (feature.place_type[0] == 'locality') {
      for (const result of feature.context) {
        if (result.id.includes('postcode')) {
          address.postcode = result.text;
        }
        if (result.id.includes('place')) {
          address.city = result.text;
        }
        if (result.id.includes('locality')) {
          address.line_2 = result.text;
        }
        if (result.id.includes('district')) {
          address.county = result.text;
        }
        if (result.id.includes('region')) {
          address.state = result.text;
        }
        if (result.id.includes('country')) {
          address.country = result.text;
        }
      }
    }
    if (feature.place_type[0] == 'neighborhood') {
      for (const result of feature.context) {
        if (result.id.includes('postcode')) {
          address.postcode = result.text;
        }
        if (result.id.includes('place')) {
          address.city = result.text;
        }
        if (result.id.includes('locality')) {
          address.line_2 = result.text;
        }
        if (result.id.includes('district')) {
          address.county = result.text;
        }
        if (result.id.includes('region')) {
          address.state = result.text;
        }
        if (result.id.includes('country')) {
          address.country = result.text;
        }
      }
    }
    if (feature.place_type[0] == 'address') {
      for (const result of feature.context) {
        if (result.id.includes('postcode')) {
          address.postcode = result.text;
        }
        if (result.id.includes('place')) {
          address.city = result.text;
        }
        if (result.id.includes('locality')) {
          address.city = result.text;
        }
        if (result.id.includes('district')) {
          address.county = result.text;
        }
        if (result.id.includes('region')) {
          address.state = result.text;
        }
        if (result.id.includes('country')) {
          address.country = result.text;
        }
      }
    }
    if (feature.place_type[0] == 'region') {
      for (const result of feature.context) {
        if (result.id.includes('postcode')) {
          address.postcode = result.text;
        }
        if (result.id.includes('place')) {
          address.city = result.text;
        }
        if (result.id.includes('district')) {
          address.county = result.text;
        }
        if (result.id.includes('region')) {
          address.state = result.text;
        }
        if (result.id.includes('country')) {
          address.country = result.text;
        }
      }
    }

    if (feature.id.includes('place')) {
      address.city = feature.text;
    }
    if (feature.id.includes('region')) {
      address.state = feature.text;
    }
    if (feature.id.includes('address')) {
      let houseNumber = feature.address;
      let position = feature.place_name.search(houseNumber);
      if (position == 0) {
        address.street =
          (feature.address ? feature.address + ' ' : '') + feature.text;
      } else {
        address.street =
          feature.text + (feature.address ? ' ' + feature.address : '');
      }
    }
    if (feature.id.includes('country')) {
      address.country = feature.text;
    }
    address.type = feature.place_type[0];
    // Full Address
    if (feature.place_name) {
      address.full_address = feature.place_name;
    }
    // Coordinates
    if (feature.center) {
      address.longitude = feature.center[0];
      address.latitude = feature.center[1];
    }

    return address;
  }

  renderMap() {
    this.renderderOnce = true;
  }
  public clear() {
    if (this.markers.length > 0) {
      this.markers.map((marker) => {
        marker.remove();
      });
      this.markers = [];
    }
  }

  public flyTo(space: { longitude: number; latitude: number }) {}

  public onAddMarker(space: { latitude: number; longitude: number }) {
    if (space.latitude && space.longitude) {
    }
  }

  sliderUpdate() {
    const settings = {
      fill: '#ff6d2e',
      background: '#fdf0e9',
    };

    setTimeout(() => {
      let elem = document.getElementById('token-slider-input');

      if (elem) {
        this.sliderTokenUpdate(elem, 'NA', 'token');
      }
      let elem2 = document.getElementById('amount-slider-input');
      if (elem) {
        this.sliderTokenUpdate(elem2, 'NA', 'amount');
      }
    }, 300);
  }

  public setSpaceInstantType() {
    if (this.space.is_instant == 1) {
      this.space.is_instant = 0;
    } else {
      if (this.space.is_instant == 0) {
        this.space.is_instant = 1;
      }
    }
  }

  openImgPopup(index, tagId, imgUrl) {
    this.clickedImg.index = index;
    this.clickedImg.tagId = tagId;
    this.clickedImg.imgUrl = imgUrl;
    this.showImg = true;
  }

  picImageFile(event) {
    let file = event.target.files[0];

    if (this.uploadService.maxFileSizeValidator(file.size, 2, 20)) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let image_uuid = uuidv4();
        let fileExt = file.name.split('.').pop();
        this.fileName = image_uuid + '.' + fileExt;
        this.original_fileName = 'original_' + image_uuid + '.' + fileExt;
        this.imageChangedEvent = event;
        this.cropEnabled = true;
        this.originalFile = file;
        // form.get('documentOrigFileName').setValue(file.name);
        // form.get('base64').setValue(reader.result);
        // form.get('documentOrigFileName').patchValue({
        //   documentOrigFileName: file.name,
        //   base64: reader.result
        // });
      };
      reader.onerror = (error) => {};
    } else {
      event.target.value = '';
      this.originalFile = '';
    }
  }
  closeCroper() {
    this.imageChangedEvent = '';
    this.cropEnabled = false;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  loadImageFailed() {
    this.closeCroper();
    this.snackbarService.show({
      message: this.translate.instant('Wrong file format selected.'),
      type: 'success',
      config: {
        class: 'centered',
      },
    });
  }
  imageLoaded() {
    this.showCropper = true;
  }
  public uploadSpaceImage() {
    this.loading = true;
    const file = new Buffer(
      this.croppedImage.replace(/^data:image\/\w+;base64,/, ''),
      'base64'
    );
    let index = this.clickedImg.index;
    let tagId = this.clickedImg.tagId;
    let fileType = this.croppedImage.split(';')[0].split('/')[1];

    this.uploadService
      .fileUpload(file, this.fileName, fileType)
      .then((result) => {
        let imagePath = result.Location.replace(
          'holidayswap-local.s3.amazonaws.com',
          'assets.holidayswap.com'
        );
        if (tagId == 555) {
          this.outsideViewImage.image_url = imagePath;
        }
        if (tagId == 544) {
          this.livingRoomImages.image_url = imagePath;
        }
        if (tagId == 541) {
          this.bedRoomImages.image_url = imagePath;
        }
        if (tagId == 552) {
          this.kitchenImages.image_url = imagePath;
        }
        if (tagId == 542) {
          this.bathRoomImages.image_url = imagePath;
        }
        if (tagId == 551) {
          this.guestRoomImages.image_url = imagePath;
        }

        this.clickedImg.imgUrl = imagePath;
        this.space.images[index] = {
          id: '',
          image_path: imagePath,
          tag: tagId,
        };

        this.uploadOriginalImage();

        this.googleAnalyticsService.eventEmitter(
          'LH_1_Done',
          'ListHome',
          'LH_App_ImagesDone'
        );
        this.cropEnabled = false;
        this.showImg = false;
        this.loading = false;
      });
  }

  uploadOriginalImage() {
    if (this.originalFile != '') {
      this.loading = true;
      const file = this.originalFile;
      let index = this.clickedImg.index;
      let tagId = this.clickedImg.tagId;
      let fileType = file.name.split('.').pop();

      this.uploadService
        .fileUpload(file, this.original_fileName, fileType)
        .then((result) => {
          let imagePath = result.Location.replace(
            'holidayswap-local.s3.amazonaws.com',
            'assets.holidayswap.com'
          );
          if (tagId == 555) {
            this.original_outsideViewImage.image_url = imagePath;
          }
          if (tagId == 544) {
            this.original_livingRoomImages.image_url = imagePath;
          }
          if (tagId == 541) {
            this.original_bedRoomImages.image_url = imagePath;
          }
          if (tagId == 552) {
            this.original_kitchenImages.image_url = imagePath;
          }
          if (tagId == 542) {
            this.original_bathRoomImages.image_url = imagePath;
          }
          if (tagId == 551) {
            this.original_guestRoomImages.image_url = imagePath;
          }

          this.clickedImg.imgUrl = imagePath;
          this.space.originalImages[index] = {
            id: '',
            image_path: imagePath,
            tag: tagId,
          };
        });
    }
  }

  moveStep(moveto) {
    const formBody = document.getElementById('add-body');
    if (formBody) {
      formBody.scrollTo({ top: 0 });
    }
    if (moveto == 'next') {
      this.validateForm();
    } else if (moveto == 'prev' && this.activeStep > 1) {
      this.activeStep--;
      this.add_btn_block = false;
    } else if (
      moveto == 'prev' &&
      this.activeStep == AddHomeStep.uploadImages
    ) {
      this.exitClick();
    }
    // this.stepNumber = this.activeStep>3?(this.activeStep-1):this.activeStep;
    this.stepNumber = this.activeStep;

    if (this.activeStep >= 3 && this.activeStep <= 5) {
      this.stepNumber = 3;
    } else if (this.activeStep >= 6 && this.activeStep <= 9) {
      this.stepNumber = 4;
    } else if (this.activeStep == AddHomeStep.mobileNumber) {
      this.stepNumber = 5;
    } else if (this.activeStep == AddHomeStep.success) {
      this.stepNumber = 6;
    }

    this.progressWidth = (this.stepNumber / 6) * 100 + '%';
    if (this.activeStep == AddHomeStep.nightlyRate) {
      setTimeout(() => {
        let elem = document.getElementById('token-slider-input');
        if (elem) {
          this.sliderTokenUpdate(elem, 'NA', 'token');
        }
        let elem2 = document.getElementById('amount-slider-input');
        if (elem) {
          this.sliderTokenUpdate(elem2, 'NA', 'amount');
        }
      }, 300);
    }

    // Fire the AppsFlyer event
    if (this.activeStep == AddHomeStep.uploadImages) {
      (window as any).AF('pba', 'event', {
        eventType: 'EVENT',
        eventName: 'add_home_step_' + this.activeStep,
        eventValue: {
          description: 'Add images',
        },
      });
    } else if (this.activeStep == AddHomeStep.nightlyRate) {
      (window as any).AF('pba', 'event', {
        eventType: 'EVENT',
        eventName: 'add_home_step_' + this.activeStep,
        eventValue: {
          description: 'Listing type, payment method and amount',
        },
      });
    } else if (this.activeStep == AddHomeStep.map) {
      (window as any).AF('pba', 'event', {
        eventType: 'EVENT',
        eventName: 'add_home_step_' + this.activeStep,
        eventValue: {
          description: 'Location picker with google autocomplete',
        },
      });
    } else if (this.activeStep == AddHomeStep.address) {
      (window as any).AF('pba', 'event', {
        eventType: 'EVENT',
        eventName: 'add_home_step_' + this.activeStep,
        eventValue: {
          description: 'Address details',
        },
      });
    } else if (this.activeStep == AddHomeStep.rightSpot) {
      (window as any).AF('pba', 'event', {
        eventType: 'EVENT',
        eventName: 'add_home_step_' + this.activeStep,
        eventValue: {
          description: 'Pin on map',
        },
      });
    } else if (this.activeStep == 6) {
      (window as any).AF('pba', 'event', {
        eventType: 'EVENT',
        eventName: 'add_home_step_' + this.activeStep,
        eventValue: {
          description: 'Space type',
        },
      });
    } else if (this.activeStep == 7) {
      (window as any).AF('pba', 'event', {
        eventType: 'EVENT',
        eventName: 'add_home_step_' + this.activeStep,
        eventValue: {
          description: 'Beds, bathrooms, geusts & amenities',
        },
      });
    } else if (this.activeStep == 8) {
      (window as any).AF('pba', 'event', {
        eventType: 'EVENT',
        eventName: 'add_home_step_' + this.activeStep,
        eventValue: {
          description: 'Neighbourhood',
        },
      });
    } else if (this.activeStep == 9) {
      (window as any).AF('pba', 'event', {
        eventType: 'EVENT',
        eventName: 'add_home_step_' + this.activeStep,
        eventValue: {
          description: 'Listing title and description',
        },
      });
    } else if (this.activeStep == AddHomeStep.mobileNumber) {
      (window as any).AF('pba', 'event', {
        eventType: 'EVENT',
        eventName: 'add_home_step_' + this.activeStep,
        eventValue: {
          description: 'Confirm mobile number',
        },
      });
    }
  }

  getCombainedAddress(): string {
    const street1 = this.addressForm.get('street_1').value;
    const street2 = this.addressForm.get('street_2').value;
    const city = this.addressForm.get('city').value;
    const state = this.addressForm.get('state').value;
    const postcode = this.addressForm.get('postcode_1').value;
    const country = this.addressForm.get('country').value;

    let address;
    if (this.addressForm.get('full_address').value) {
      address = this.addressForm.get('full_address').value;
    } else {
      address =
        street1 +
        (street2 ? ', ' + street2 : '') +
        ', ' +
        city +
        ', ' +
        state +
        ' ' +
        postcode +
        ', ' +
        country;
      // Concatenate the values into a single string
    }

    // Remove any leading or trailing commas or spaces
    address = address.replace(/^[\s,]+|[\s,]+$/g, '');

    return address;
  }

  public onRemove() {
    let index = this.clickedImg.index;

    if (this.space.images.length > 0) {
      this.space.images[index] = {
        id: '',
        image_path: '',
        tag: '',
      };
    }
    if (index == 0) {
      this.outsideViewImage = { tag_id: '', image_url: '' };
    }

    if (index == 1) {
      this.livingRoomImages = { tag_id: '', image_url: '' };
    }

    if (index == 2) {
      this.bedRoomImages = { tag_id: '', image_url: '' };
    }

    if (index == 3) {
      this.kitchenImages = { tag_id: '', image_url: '' };
    }

    if (index == 4) {
      this.bathRoomImages = { tag_id: '', image_url: '' };
    }

    if (index == 5) {
      this.guestRoomImages = { tag_id: '', image_url: '' };
    }
    // this.clickedImg.imgUrl = "";
    // this.removeImg = false;

    this.removeOriginalImage();
  }

  removeOriginalImage() {
    let index = this.clickedImg.index;
    if (this.space.originalImages.lengt > 0) {
      this.space.originalImages[index] = {
        id: '',
        image_path: '',
        tag: '',
      };
    }
    if (index == 0) {
      this.original_outsideViewImage = { tag_id: '', image_url: '' };
    }

    if (index == 1) {
      this.original_livingRoomImages = { tag_id: '', image_url: '' };
    }

    if (index == 2) {
      this.original_bedRoomImages = { tag_id: '', image_url: '' };
    }

    if (index == 3) {
      this.original_kitchenImages = { tag_id: '', image_url: '' };
    }

    if (index == 4) {
      this.original_bathRoomImages = { tag_id: '', image_url: '' };
    }

    if (index == 5) {
      this.original_guestRoomImages = { tag_id: '', image_url: '' };
    }
    this.clickedImg.imgUrl = '';
    this.removeImg = false;
  }

  gotoExplore() {
    this.exitClick();
  }

  viewListing() {
    this.router.navigate(['/view-listing']);
  }

  exitClick() {
    if (this.outsideViewImage.image_url == '' || this.activeStep <= 10) {
      this.exitModal.emit();
    } else {
      if (!this.exitClickedOnce) {
        this.exitClickedOnce = true;
        this.saveHome(true);
      }
    }
    if (this.activeStep > 10) {
      this.exitModal.emit();
      this.googleAnalyticsService.eventEmitter(
        'LH_Complete',
        'ListHome',
        'LH_App_ThankYou'
      );
    }
  }
  validateForm() {
    if (this.activeStep == AddHomeStep.uploadImages) {
      if (this.outsideViewImage.image_url != '') {
        this.activeStep++;
      } else {
        this.snackbarService.show({
          message: this.translate.instant('Cover image is mandatory'),
          type: 'success',
          config: {
            class: 'centered',
          },
        });
      }
      this.googleAnalyticsService.eventEmitter(
        'LH_EarningOptions',
        'ListHome',
        'LH_App_EarningOptions'
      );
    } else if (this.activeStep == AddHomeStep.nightlyRate) {
      this.activeStep++;

      if (this.space.payment_type_id == 561) {
        this.googleAnalyticsService.eventEmitter(
          'LH_2_Cash',
          'ListHome',
          'LH_App_EarningDone'
        );
      } else {
        this.googleAnalyticsService.eventEmitter(
          'LH_2_Tokens',
          'ListHome',
          'LH_App_EarningDone'
        );
      }
      this.googleAnalyticsService.eventEmitter(
        'LH_Location',
        'ListHome',
        'LH_App_Location'
      );
    } else if (this.activeStep == AddHomeStep.map) {
      this.activeStep++;

      this.selectedCountry = this.location.country;
      this.googleAnalyticsService.eventEmitter(
        'LH_3_Done',
        'ListHome',
        'LH_3_Done'
      );
    } else if (this.activeStep == AddHomeStep.address) {
      this.combainedAddress = this.getCombainedAddress();
      let country_address = this.addressForm.get('country').value;
      const excluded = this.excludeCountries.some((country) =>
        country_address?.toUpperCase().includes(country.toUpperCase())
      );

      if (excluded) {
        this.snackbarService.show({
          message: this.translate.instant(
            'Sorry!  Your country / region is currently not supported by Holiday Swap.'
          ),
          type: 'success',
          config: {
            class: 'centered',
          },
        });
        this.addressForm.get('country').setValue('');
        return;
      }
      this.addressForm.markAllAsTouched();
      if (this.addressForm.valid) {
        this.activeStep++;
      }

      let streetVal = this.addressForm.get('street_1').value.trim();
      let postcodeVal = this.addressForm.get('postcode_1').value.trim();
      let cityVal = this.addressForm.get('city').value.trim();
      let stateVal = this.addressForm.get('state').value.trim();
      let countryVal = this.addressForm.get('country').value.trim();

      if (!streetVal && streetVal == '') {
        return this.addressForm.get('street_1').setErrors({ required: true });
      }

      if (!cityVal && cityVal == '') {
        return this.addressForm.get('city').setErrors({ required: true });
      }

      if (!stateVal && stateVal == '') {
        return this.addressForm.get('state').setErrors({ required: true });
      }

      if (!postcodeVal && postcodeVal == '') {
        return this.addressForm.get('postcode_1').setErrors({ required: true });
      }

      if (!countryVal && countryVal == '') {
        return this.addressForm.get('country').setErrors({ required: true });
      }
      this.googleAnalyticsService.eventEmitter(
        'LH_HomeDetails',
        'ListHome',
        'LH_App_HomeDetails'
      );
    } else if (this.activeStep == 5) {
      this.activeStep++;
    } else if (this.activeStep == 6) {
      this.activeStep++;
    } else if (this.activeStep == 7) {
      this.hometypeForm.markAllAsTouched();
      if (
        this.hometypeForm.valid &&
        this.aminitiesForm.controls['places_or_listing_picks'].value?.length > 1
      ) {
        this.places_listing_error = '';
        this.activeStep++;
      } else if (
        this.aminitiesForm.controls['places_or_listing_picks'].value?.length <=
        1
      ) {
        this.places_listing_error = 'Please pick more than one places';
      }
    } else if (this.activeStep == 8) {
      if (
        this.aminitiesForm.controls['neighbourhood_picks'].value?.length > 1
      ) {
        this.neighbourhood_error = '';
        var hometypeVal = this.hometypeForm.value;

        let place_type_name = this.homeTypes.filter((data) => {
          return data.id == this.place_type;
        })[0];

        var place_picks_name = [];
        if (
          this.aminitiesForm.controls['places_or_listing_picks'].value?.length >
          0
        ) {
          this.aminitiesForm.controls['places_or_listing_picks'].value.map(
            (id) => {
              let newtype = this.categories.places_or_listing_picks.filter(
                (data) => {
                  return data.id == id;
                }
              )[0];
              place_picks_name.push(newtype.name);
            }
          );
        }

        var neighborhood_picks_name = [];
        if (
          this.aminitiesForm.controls['neighbourhood_picks'].value?.length > 0
        ) {
          this.aminitiesForm.controls['neighbourhood_picks'].value.map((id) => {
            let newtype = this.categories.neighbourhood_picks.filter((data) => {
              return data.id == id;
            })[0];
            neighborhood_picks_name.push(newtype.name);
          });
        }
        let data = {
          purpose_name:
            this.space?.purpose_id == 520
              ? 'Host'
              : this.space?.purpose_id == 521
              ? 'Swap'
              : '',
          payment_type_name:
            this.space?.payment_type_id == 561 ? 'Cash' : 'token',
          place_type_name: place_type_name?.name,
          address:
            this.addressForm?.get('full_address')?.value ||
            this.combainedAddress,
          place_picks_name: place_picks_name,
          neighborhood_picks_name: neighborhood_picks_name,
          beds: hometypeVal?.bedrooms,
          bathrooms: hometypeVal?.bathrooms,
          guests: hometypeVal?.guests,
        };

        this.description_loading = true;
        if (!this.chatgptDescriptionReceived) {
          this.spaceService
            .getSpaceDetailsWithChatGpt(data)
            .subscribe((res: any) => {
              this.titleFromChatGPT = res.data?.title;
              this.descriptionFromChatGPT = res.data?.description;
              this.homeDetailForm
                .get('description')
                .setValue(res.data?.description);
              this.homeDetailForm.get('title').setValue(res.data?.title);
            })
            .add(() => {
              this.chatgptDescriptionReceived = true;
              this.description_loading = false;
            });
        } else {
          this.description_loading = false;
        }
        this.activeStep++;
      } else {
        this.neighbourhood_error = 'Please pick more than one neighbourhood';
      }
    } else if (this.activeStep == 9) {
      this.homeDetailForm
        .get('title')
        .setValue(this.homeDetailForm.get('title').value.trim());
      this.homeDetailForm
        .get('description')
        .setValue(this.homeDetailForm.get('description').value.trim());
      this.homeDetailForm.markAllAsTouched();

      if (this.description_loading == true) {
        this.add_btn_block = true;
      } else if (
        this.description_loading == false &&
        this.homeDetailForm.valid
      ) {
        if (!this.isPhoneVerified) {
          this.activeStep++;
        } else {
          this.space.isAlreadyAdded = true;
          this.activeStep = AddHomeStep.success;
          this.saveHome();
        }
        this.add_btn_block = false;
      }
      this.googleAnalyticsService.eventEmitter(
        'LH_4_Done',
        'ListHome',
        'LH_App_HomeDetailsDone'
      );
      this.add_btn_block = false;
    } else if (this.activeStep == AddHomeStep.mobileNumber) {
      this.space.isAlreadyAdded = true;
      if (!this.isPhoneVerified) {
        if (
          this.verification.phone &&
          this.verification.phone.length > 4 &&
          this.verification.phone.length < 15
        ) {
          this.onConfirm();
        } else {
          this.snackbarService.show({
            message: this.translate.instant('Please enter valid phone number.'),
            type: 'success',
            config: {
              class: 'centered',
            },
          });
        }
      } else {
        this.add_btn_block = true;
        this.saveHome();
      }
    }
  }
  patchOtp(event) {
    this.verification.code = event;
  }
  onDatesSelected(dates) {
    this.space.dates = dates;
  }

  public onRequest() {
    var hasLeadingZero = this.hasLeadingZero(this.space?.phone_no);
    (window as any).AF('pba', 'event', {
      eventType: 'EVENT',
      eventName: 'add_home_step_11',
      eventValue: {
        description: 'Mobile phone verification code requested',
      },
    });

    if (hasLeadingZero) {
      this.snackbarService.show({
        message:
          'Hey! We see you have a zero at the start of your mobile - is this correct?  If you have any troubles, try removing the zero!',
        type: 'success',
        config: {
          class: 'centered',
        },
      });
    } else {
      if (this.sentTime < 2) {
        this._startCountdown();
      }
      if (this.sentTime < 3) {
        this.btn_block = true;
        this.hideOtpInput = true;
        this.userService
          .requestPhoneVerification({
            telephone_no: this.verification.phone,
            country_code: this.space?.country_code,
            phone_no: this.space?.phone_no,
          })
          .subscribe(
            (res: any) => {
              this.sentTime++;
            },
            (err) => {
              this.snackbarService.show({
                type: 'success',
                message: err.toString(),
                duration: 15000,
                config: {
                  class: 'centered',
                },
              });
              // this.error = err;
              // this.loading = true;
            }
          );
      }
    }
  }

  public onConfirm() {
    this.userService
      .confirmPhoneVerification({
        code: this.verification.code,
      })
      .subscribe(
        (response) => {
          // Fire the AppsFlyer event
          (window as any).AF('pba', 'event', {
            eventType: 'EVENT',
            eventName: 'add_home_step_12',
            eventValue: {
              description: 'Mobile phone verified',
            },
          });

          this.verifiedOTP();
          this.snackbarService.show({
            type: 'success',
            message: this.translate.instant(
              'Phone number successfully verified!'
            ),
            config: {
              class: 'centered',
            },
          });
          this.userService.getMe();
          this.saveHome();
          this.googleAnalyticsService.eventEmitter(
            'LH_5_Done',
            'ListHome',
            'LH_App_VerifyMobileDone'
          );

          // this.otpConfirmed = true;

          // this.tokenService.bonusTokens("Bonus", 21, 26, 10, "Bonus on account verification").subscribe(
          // 	(res) => {
          // 	});

          // this.goback()
        },
        (err) => {
          // this.error = err;
          this.snackbarService.show({
            type: 'success',
            message: this.translate.instant('Please enter valid OTP.'),
            config: {
              class: 'centered',
            },
          });
        }
      );
  }

  confirmNumberEventSendAgainVerificationClicked() {
    this.clevertapService.confirmNumberEventSendAgainVerificationClicked(
      this.verification.phone
    );
  }

  verifiedOTP() {
    this.clevertapService.confirmNumberEventOTPVerificationClicked(
      this.verification.phone
    );
  }

  private _startCountdown() {
    this.resendHide = false;
    // let currentTime = new Date();
    // let cDateMillisecs = moment(currentTime).valueOf();
    let countDownTime = moment().add(1, 'minutes');
    let tDateMillisecs = moment(countDownTime).valueOf();
    // this.remaining = this.cooldown;
    this.interval = setInterval(() => {
      let currentTime = new Date();
      let cDateMillisecs = moment(currentTime).valueOf();
      let difference = tDateMillisecs - cDateMillisecs;
      if (difference <= 0) {
        this.resendHide = true;
        this.btn_block = false;
        clearInterval(this.interval);
        return;
      } else {
        let seconds: any = Math.floor(difference / 1000);
        let minutes: any = Math.floor(seconds / 60);
        // this.days = Math.floor(this.hours / 24);

        // this.hours %= 24;
        minutes %= 60;
        seconds %= 60;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;

        this.remaining = minutes + ':' + seconds;
      }
    }, 1000);
  }
  public patchEditdata(data) {
    if (data.address === null) {
      data.address = {
        latitude: 0,
        longitude: 0,
      };
    }

    if (data.images && data.images.length > 0) {
      this.space.images = data.images.map((image, index) => {
        if (index == 0) {
          this.outsideViewImage.image_url = image.image_url;
        }
        if (image.tag?.id == 555) {
          this.outsideViewImage.image_url = image.image_url;
        }
        if (image.tag?.id == 544) {
          this.livingRoomImages.image_url = image.image_url;
        }
        if (image.tag?.id == 541) {
          this.bedRoomImages.image_url = image.image_url;
        }
        if (image.tag?.id == 552) {
          this.kitchenImages.image_url = image.image_url;
        }
        if (image.tag?.id == 542) {
          this.bathRoomImages.image_url = image.image_url;
        }
        if (image.tag?.id == 551) {
          this.guestRoomImages.image_url = image.image_url;
        }
        return {
          id: image.id,
          image_path: image.image_url,
          tag: image.tag.id,
          uploaded: true,
        };
      });

      for (let i = 0; i < this.space.images.length; i++) {
        if (this.space.images[i].image_url != '') {
          this.completePercentage += 5;
        }
      }
    }

    this.space = data;

    if (data.unavailable_dates_edit) {
      this.space['dates'] = data.unavailable_dates_edit
        ? data.unavailable_dates_edit.map((date) => {
            return {
              id: date.id,
              started_at: moment.unix(date.started_at).format('YYYY-MM-DD'),
              ended_at: moment.unix(date.ended_at).format('YYYY-MM-DD'),
            };
          })
        : [];
    } else {
      this.space['dates'] = [];
    }

    this.addressForm.patchValue({
      city: data.address.city
        ? data.address.city
        : data.address.county
        ? data.address.county
        : '',
      country: data.address.country ? data.address.country : '',
      state: data.address.state ? data.address.state : '',
      full_address: data.address.full_address ? data.address.full_address : '',
      latitude: data.address.latitude ? data.address.latitude : 0,
      longitude: data.address.longitude ? data.address.longitude : 0,
      postcode: data.address.postcode ? data.address.postcode : '',
      postcode_1: data.address.postcode ? data.address.postcode : '',
      street: data.address.street ? data.address.street : '',
      street_1: data.address.street_1 ? data.address.street_1 : '',
      street_2: data.address.street_2 ? data.address.street_2 : '',
    });
    if (data.address.full_address) {
      this.location['full_address'] = data.address.full_address;
    }

    this.hometypeForm.patchValue({
      bathrooms: data.bathrooms,
      bedrooms: data.bedrooms,
      guests: data.guests,
    });

    this.homeDetailForm.patchValue({
      description: data.description,
      title: data.title,
    });
    // this.spaceTitle = data.title;
    // if (localStorage.getItem("spaceDetails")) {
    //   this.space = JSON.parse(localStorage.getItem("spaceDetails"));
    //   this.space.id = this.spaceId;
    // }
    // this.isSpaceDetails = true;

    if (data.purpose_id == null) {
      this.space.purpose_id = 521;
    }
    if (data.purpose_id == 521) {
      this.space.is_instant = 0;
    }
    if (data.hosting_tokens == null) {
      this.space.hosting_tokens = 49;
      const settings = {
        fill: '#ff6d2e',
        background: '#fdf0e9',
      };

      // First find all our sliders
      const sliders = document.querySelectorAll('.range-slider');

      Array.prototype.forEach.call(sliders, (slider) => {
        applyFill(slider.querySelector('input'), this.space.hosting_tokens);
      });

      // This function applies the fill to our sliders by using a linear gradient background
      function applyFill(slider, hostingvalue) {
        const percentage =
          (100 * (hostingvalue - slider.min)) / (slider.max - slider.min);

        const bg = `linear-gradient(90deg, ${settings.fill} ${percentage}%, ${
          settings.background
        } ${percentage + 0.1}%)`;
        slider.style.background = bg;
      }
    }
    if (data.hosting_tokens != null) {
      setTimeout(() => {
        this.sliderUpdate();
      }, 500);
    }

    if (this.space.address != null && !this.space.address.full_address) {
      this.space.address.full_address =
        this.space.address.city + ',' + this.space.address.country;
    }

    if (data.title != null && data.title != '') {
      if (data.address != null && data.address != undefined) {
        this.space.address.full_address =
          data.address.city + ' , ' + data.address.country;
      }
    }
  }

  saveHome(onExit = false) {
    this.serverErrors = [];
    let addresVal = this.addressForm.value;
    let detailVal = this.homeDetailForm.value;
    let typeVal = this.hometypeForm.value;
    // let amienitiesVal = this.aminitiesForm.value;

    this.space.address = addresVal;
    this.space.bathrooms = typeVal.bathrooms;
    this.space.bedrooms = typeVal.bedrooms;
    this.space.description = detailVal.description.trim();
    this.space.guests = typeVal.guests;
    this.space.title = detailVal.title.trim();
    this.space.address.manual_latlng = this.markerUpdated;
    this.space.place_type = this.place_type;
    this.space.place_picks =
      this.aminitiesForm.controls['places_or_listing_picks'].value;
    this.space.neighbourhood_picks =
      this.aminitiesForm.controls['neighbourhood_picks'].value;

    this.space.flat_no = addresVal.street_2;
    this.space.street = addresVal.street;
    this.space.city = addresVal.city;
    this.space.country = addresVal.country;
    let countryCode = this.countries.filter((country) => {
      if (country.name.toLowerCase() == this.space.country.toLowerCase()) {
        return country.code;
      }
    });
    this.space.countryIso = countryCode[0]?.code ? countryCode[0]?.code : '';
    // this.space.continent= addresVal.state,
    this.space.postcode = addresVal.postcode;
    this.space.latitude = addresVal.latitude;
    this.space.longitude = addresVal.longitude;
    this.space.state = addresVal.state;
    this.space.postcode = addresVal.postcode_1;
    this.space.postcode_1 = addresVal.postcode_1;
    this.space.full_address = addresVal.full_address;
    this.space.street_1 = addresVal.street_1;
    this.space.street_2 = addresVal.street_2;

    this.selectedCountry = this.space.country;
    this.space.hosting_tokens = parseInt(
      this.space.hosting_tokens ? this.space.hosting_tokens : 49
    );
    this.space.hosting_amount = parseInt(
      this.space.hosting_amount ? this.space.hosting_amount : 49
    );

    this.space.images = this.space.images
      .filter((image) => image.image_path && image.image_path.length > 0)
      .map((image) => {
        this.completePercentage += 10;
        return {
          id: image.id,
          image_path: image.image_path,
          tag: image.tag,
        };
        // return image;
      });
    // this.space.images = this.homeImages;
    if (this.space.title) {
      this.completePercentage += 10;
    }

    if (this.space.description) {
      this.completePercentage += 10;
    }
    if (this.space?.address?.city) {
      this.completePercentage += 10;
    }

    if (
      this.space.guests > 0 &&
      this.space.bathrooms > 0 &&
      this.space.bedrooms > 0
    ) {
      this.completePercentage += 10;
    }
    // this.space.dates = [];

    if (this.space.purpose_id == 521) {
      this.space.hosting_tokens = 1;
    }
    localStorage.removeItem('spaceDetails');
    // this.addBonusToken();

    let homeData = {
      email: this.userEmail,
      phone_number: '',
      app_build: '',
      app_package: '',
      device_name: '',
      device_os: '',
      device_platform: '',
      timestamp: '',
    };
    this.clevertapService.pushCleverTapEvent('home_added', homeData);
    let moreHome = {
      email: this.userEmail,
      phone_number: '',
      app_build: '',
      app_package: '',
      device_name: '',
      device_os: '',
      device_platform: '',
      timestamp: '',
    };
    this.clevertapService.pushCleverTapEvent('add_more_homes', moreHome);
    if (this.completePercentage < 100) {
      let incomplete = {
        email: this.userEmail,
      };
      this.clevertapService.pushCleverTapEvent(
        'listing_incomplete',
        incomplete
      );
    }

    if (this.space.images.length == 6) {
      let homePhoto = {
        email: this.userEmail,
        phone_number: '',
        app_build: '',
        app_package: '',
        device_name: '',
        device_os: '',
        device_platform: '',
        timestamp: '',
      };
      this.clevertapService.pushCleverTapEvent('home_photos', homePhoto);
    }

    if (this.space.id) {
      this.spaceService.updateSpaceCompletionRate(
        this.space.id,
        this.completePercentage
      );
      this._updateSpace(this.space, onExit);
    } else {
      this._createSpace(this.space, onExit);
    }

    this.addressForm.markAllAsTouched();
    if (this.addressForm.invalid) {
      this.exitClickedOnce = false;
      return;
    }

    if (
      this.addressForm.get('postcode_1')?.value != null &&
      this.addressForm.get('postcode_1')?.value.trim() == ''
    ) {
      this.addressForm.controls.postcode_1.setErrors({ required: true });

      return;
    }

    if (
      this.addressForm.get('city')?.value != null &&
      this.addressForm.get('city')?.value.trim() == ''
    ) {
      this.addressForm.controls.city.setErrors({ required: true });

      return;
    }

    if (
      this.addressForm.get('state')?.value != null &&
      this.addressForm.get('state')?.value.trim() == ''
    ) {
      this.addressForm.controls.state.setErrors({ required: true });

      return;
    }

    if (
      this.addressForm.get('country')?.value != null &&
      this.addressForm.get('country')?.value.trim() == ''
    ) {
      this.addressForm.controls.country.setErrors({ required: true });

      return;
    }

    if (
      this.addressForm.get('description')?.value != null &&
      this.addressForm.get('description')?.value.trim() == ''
    ) {
      this.addressForm.controls.description.setErrors({ required: true });

      return;
    }
  }

  public _createSpace(space, onExit = false) {
    this.spaceService.create(space).subscribe(
      (response: { data?: any }) => {
        this.spaceDetails = response.data;
        this.spaceService.getMySpaces().subscribe();

        if (onExit) {
          this.snackbarService.show({
            message: this.translate.instant('Saved successfully.'),
            type: 'success',
            config: {
              class: 'centered',
            },
          });

          this.clearAll();
          if (this.onSuccess) {
            this.onSuccess.emit();
          }
          this.exitModal.emit();
        } else {
          if (this.activeStep < 11) {
            this.activeStep++;
          }
          if (this.activeStep == AddHomeStep.success) {
            this.stepNumber++;
            (window as any).AF('pba', 'event', {
              eventType: 'EVENT',
              eventName: 'add_home_success',
              eventValue: {
                description: 'User listed new home',
              },
            });
          }
        }
      },
      (err) => {
        // this.toastr.error(err.toString(), 'Request failed');
        this.snackbarService.show({
          message: this.translate.instant('Request failed.'),
          type: 'success',
          config: {
            class: 'centered',
          },
        });
        this.add_btn_block = false;
        if (onExit) {
          this.exitModal.emit();
        }
      }
    );
  }

  addBonusToken() {
    this.tokenService
      .bonusTokens('Bonus', 18, 20, 10, 'Bonus on creating first space')
      .subscribe((res) => {});
  }

  updateImgBtn() {
    if (!this.clickedImg.imgUrl) {
      this.snackbarService.show({
        message: this.translate.instant('Please select image to update.'),
        type: 'success',
        config: {
          class: 'centered',
        },
      });
    } else {
      this.showImg = false;
    }
  }

  public _updateSpace(space, onExit = false) {
    this.spaceService.update(this.space?.id, space).subscribe(
      (response: { data?: any }) => {
        this.spaceService.getMySpaces().subscribe();

        this.spaceDetails = response.data;
        if (onExit) {
          this.snackbarService.show({
            message: this.translate.instant('Saved successfully.'),
            type: 'success',
            config: {
              class: 'centered',
            },
          });
          this.clearAll();
          this.exitModal.emit();
          if (this.onSuccess) {
            this.onSuccess.emit();
          }
        } else {
          this.activeStep++;
        }
      },
      (err) => {
        this.snackbarService.show({
          message: err.toString(),
          type: 'success',
          config: {
            class: 'centered',
          },
        });
        this.add_btn_block = false;
        if (onExit) {
          this.exitModal.emit();
        }
      }
    );
  }

  clearAll() {
    this.space = {};
    this.addressForm.reset();
    this.homeDetailForm.reset();
    this.hometypeForm.reset();
  }
  ngOnDestroy(): void {
    this.userData = undefined;
    $('.app-navigation').removeClass('hide');
    $('.app-content').removeClass('addHomeModal');

    // if step is not last one, we fire event with step on which user canceled add home process
    if (this.stepNumber != 6) {
      // Fire the AppsFlyer event
      (window as any).AF('pba', 'event', {
        eventType: 'EVENT',
        eventName: 'add_home_canceled',
        eventValue: {
          step_ui: this.stepNumber + ' of 6',
          step: this.activeStep,
        },
      });
    }
  }

  gotoSubscription() {
    let email_verified =
      JSON.parse(localStorage.getItem('userDataTEST'))?.email_verified == true;

    if (!email_verified) {
      this.userService.verifyMail.next(true);
    } else {
      if (this.isDesktopView)
        this.router.navigate(['/auth/upgrade-subscription']);
      else this.router.navigate(['/auth/upgrade-subscription']);
    }
  }

  changeHostingToken(type) {
    switch (type) {
      case 'swap':
        if (this.space.purpose_id == 520) {
          this.space.purpose_id = 522;
          this.space.payment_type_id = 561;
        } else if (this.space.purpose_id == 522) {
          this.space.purpose_id = 520;
          this.space.payment_type_id = 561;
        } else {
          this.space.purpose_id = 522;
          this.space.payment_type_id = '';
        }
        break;
      case 'list':
        if (this.space.purpose_id == 521) {
          this.space.purpose_id = 522;
          this.space.payment_type_id = 561;
        } else if (this.space.purpose_id == 522) {
          this.space.purpose_id = 521;
          this.space.payment_type_id = '';
        } else {
          this.space.purpose_id = 520;
          this.space.payment_type_id = 561;
        }
        break;
    }
    this.setPaymentOptions();
  }

  setPaymentOptions() {
    if (this.space.purpose_id == 522) {
      this.purpose_type = [];
      this.purpose_type = [
        { id: 520, type: 'host' },
        { id: 521, type: 'swap' },
      ];
      this.payment_type.purpose.all = true;
    }

    if (this.space.purpose_id == 520) {
      this.purpose_type = [];
      this.purpose_type = [{ id: 520, type: 'host' }];
    }

    if (this.space.purpose_id == 521) {
      this.purpose_type = [];
      this.purpose_type = [{ id: 521, type: 'swap' }];
    }

    this.purpose_type.map((purpose) => {
      if (purpose.type) {
        this.payment_type.purpose[purpose.type] = true;
      }
    });

    if (
      this.payment_type.purpose.swap == true &&
      this.payment_type.purpose.host == true &&
      this.space.payment_type_id == 561
    ) {
      this.payment_type.purpose.all = true;
    }

    if (this.space.payment_type_id == 561) {
      this.cardSliderShow = true;
      this.tokenSliderShow = false;
      this.space.hosting_amount = 49;
      this.space.hosting_tokens = 0;
      this.payment_type.purpose.host = true;
      this.payment_type.purpose.swap = false;
    } else {
      this.cardSliderShow = false;
      this.tokenSliderShow = true;
      this.space.hosting_amount = 0;
      this.space.hosting_tokens = 49;
    }

    setTimeout(() => {
      this.resetSlider(this.space?.hosting_tokens, 'token');
      this.resetSlider(this.space?.hosting_amount, 'amount');
    }, 300);
  }

  changeHostingAmount($event, id) {
    if (this.payment_type.cash) {
      this.space.payment_type_id = 561;
    } else {
      this.space.payment_type_id = 562;
    }

    if (this.space.payment_type_id == 561) {
      this.space.hosting_tokens = 0;
      this.space.hosting_amount = 49;
      setTimeout(() => {
        this.resetSlider(0, 'amount');
      }, 100);
    } else {
      this.space.hosting_tokens = 49;
      this.space.hosting_amount = 0;
      setTimeout(() => {
        this.resetSlider(0, 'amount');
      }, 100);
    }
  }

  resetSlider(tokenValue, type) {
    if (type == 'token') {
      this.applyFill(
        document.getElementById('token-slider-input'),
        tokenValue,
        'token'
      );
    }

    if (type == 'amount') {
      this.applyFill(
        document.getElementById('amount-slider-input'),
        tokenValue,
        'amount'
      );
    }
  }

  resetAmountSlider(tokenValue) {
    this.applyFill(
      document.getElementById('token-slider-input'),
      tokenValue,
      'token'
    );
    this.applyFill(
      document.getElementById('amount-slider-input'),
      tokenValue,
      'amount'
    );
  }

  public applyFill(slider, hostingvalue, type) {
    this.zone.run(() => {
      let fillColor = this.settings.fill;
      let backgroundColor = this.settings.background;
      let val = this.space.hosting_tokens;
      if (type == 'amount') {
        fillColor = '#1cc91a';
        backgroundColor = '#bbefba';
        val = this.space.hosting_amount;
      }

      let percentage = (100 * (val - slider.min)) / (slider.max - slider.min);
      if (!val || val == '' || val == undefined) {
        percentage = 0;
        slider.value = 0;
      }

      const bg = `linear-gradient(90deg, ${fillColor} ${percentage}%, ${backgroundColor} ${
        percentage + 0.1
      }%)`;
      slider.style.background = bg;
      this.cd.detectChanges();
    });
  }

  sliderTokenUpdate(input, token_value, type) {
    setTimeout(() => {
      this.applyFill(input, input.value, type);
    }, 100);
  }

  handleInputValidation(obj, key, max) {
    let val = parseFloat(obj[key]);

    if (val > max) {
      obj[key] = 49;
      this.snackbarService.show({
        message: this.translate.instant(
          `Please select tokens between 1 - ${max}`
        ),
        type: 'info',
        config: {
          class: 'centered',
        },
      });
    } else if (val <= 0) {
      obj[key] = 49;
    }
    val = parseInt(obj[key].toString());
    obj[key] = val;
    try {
      this.sliderTokenUpdate(
        document.getElementById('amount-slider-input'),
        'NA',
        'amount'
      );
      this.sliderTokenUpdate(
        document.getElementById('token-slider-input'),
        'NA',
        'token'
      );
    } catch (e) {}
  }

  checkRegEx(event) {
    var keyCode = 'which' in event ? event.which : event.keyCode;
    let isNotWanted =
      keyCode == 69 || keyCode == 189 || keyCode == 110 || keyCode == 190;
    return !isNotWanted;
  }

  setAmountManualVal(event: Event, sliderTarget, hostingAmount, type) {
    let val = event.target as HTMLInputElement;
    sliderTarget.value = val.value;
    this.sliderTokenUpdate(sliderTarget, hostingAmount, type);

    let _tempVal = +val.value;

    if (_tempVal <= 0 || _tempVal > 500) {
      // this.toastr.error('Please select tokens between 1 - 500', 'Error');
      this.space.hosting_amount = 0;
      this.resetAmountSlider(this.space?.hosting_amount);
      this.validTokenVal = false;
      return;
    }

    if (!this.isInt(_tempVal)) {
      // this.toastr.error('Please select tokens between 1 - 500', 'Error');
      this.space.hosting_amount = 0;
      this.resetAmountSlider(this.space?.hosting_amount);
      this.validTokenVal = false;
      return;
    }

    this.validTokenVal = true;
    //Math.trunc(+val.value)
    //setTimeout(() => {
    this.space.hosting_amount = +parseFloat(val.value);

    this.calCulateTokenPrice();
    this.resetAmountSlider(this.space?.hosting_amount);
    //}, 500);
  }

  public calCulateTokenPrice() {
    this.validTokenVal = true;
    // if (this.isDiscount) {
    // 	// this.tokenPrice = this.tokenValue;// - tokenCal;
    // 	// this.tokenPrice = (this.tokenPrice - ((this.tokenPrice * this.discountPrice) / 100));
    // }
    // else this.tokenPrice = this.tokenValue;
  }

  isInt(n) {
    return Number(n) === n && n % 1 === 0;
  }

  setManualVal(event: Event) {
    let val = event.target as HTMLInputElement;

    let _tempVal = +val.value;

    if (_tempVal <= 0 || _tempVal > 500) {
      // this.toastr.error('Please select tokens between 1 - 500', 'Error');
      this.space.hosting_tokens = 0;
      this.resetSlider(this.space?.hosting_tokens, 'token');
      this.validTokenVal = false;
      return;
    }

    if (!this.isInt(_tempVal)) {
      // this.toastr.error('Please select tokens between 1 - 500', 'Error');
      this.space.hosting_tokens = 0;
      this.resetSlider(this.space?.hosting_tokens, 'token');
      this.validTokenVal = false;
      return;
    }

    this.validTokenVal = true;
    //Math.trunc(+val.value)
    //setTimeout(() => {
    this.space.hosting_tokens = +parseFloat(val.value);

    this.calCulateTokenPrice();
    this.resetSlider(this.space?.hosting_tokens, 'token');
    //}, 500);
  }

  addressChange(query) {
    if (query == null || query == '') {
      this.addHomeGooglemap.resetLocation();
    }
    if (query.length <= 0) {
      this.resultHide = true;
      this.locations = [];
      this.location = [];
      this.patchAddress(this.locations);
    }
  }

  onPhonenumberChange(event) {
    // form.controls['telephoneNumber'].setValue(event?.phone);
    // form.controls['telephoneCode'].setValue(event?.code);
    // form.controls['countryIso'].setValue(event?.country_code);
    // // form.controls['telephoneNumber'].setValue(event);
    this.space.country_code = event?.code;
    this.space.phone_no = event?.phone;
  }

  UpdatedPinAdd(formattedNewLocation: any) {
    const extractedInformation = formattedNewLocation.components.reduce(
      (result, component) => {
        const types = component.types;
        const longName = component.long_name;

        if (types.includes('country')) {
          result.country = longName;
        } else if (types.includes('administrative_area_level_1')) {
          result.state = longName;
        } else if (types.includes('administrative_area_level_2')) {
          result.street1 = longName;
        } else if (types.includes('administrative_area_level_3')) {
          result.street2 = longName;
        } else if (types.includes('postal_code')) {
          result.postalCode = longName;
        } else if (types.includes('locality')) {
          result.city = longName;
        }

        return result;
      },
      {
        country: '',
        street1: '',
        street2: '',
        postalCode: '',
        city: '',
        state: '',
      }
    );
    this.addressForm.get('street_1').setValue(extractedInformation.street1);
    this.addressForm.get('street_2').setValue(extractedInformation.street2);
    this.addressForm.get('country').setValue(extractedInformation.country);
    this.addressForm.get('city').setValue(extractedInformation.city);
    this.addressForm.get('postcode_1').setValue(extractedInformation.postCode);
    this.addressForm.get('state').setValue(extractedInformation.state);

    this.location.latitude = formattedNewLocation.lat;
    this.addressForm.get('latitude').setValue(formattedNewLocation.lat);
    this.location.longitude = formattedNewLocation.lng;
    this.addressForm.get('longitude').setValue(formattedNewLocation.lng);
    this.markerUpdated = true;
  }

  // onSelectCountry(country) {
  //   this.selectedCountry = this.location.country;
  //   this.countries.push(this.selectedCountry);

  //   // this.selectedCountry = this.location?.country;

  //   // this.addressForm.get("country").setValue(country);
  //   // if (this.addressForm.get("country") == null) {
  //   //   this.selectedCountry = this.location?.country;
  //   // }
  // }

  onSelectCountry(country) {
    this.selectedCountry = country.name;
    this.addressForm.get('country').setValue(country.name);
    if (this.addressForm.get('country') == null) {
      this.selectedCountry = country.name;
    }
  }

  setServerError() {
    this.serverErrors = [];
    if (this.serverErrorList.length > 0) {
      this.serverErrorList.map((errors, index) => {
        this.serverErrors.push({
          path: errors?.path,
          message: errors?.message,
        });
      });
    }
  }

  outsideClickTrigger(event) {
    if (
      !event.target?.className?.includes('combobox-input-search') &&
      !event.target.className.includes('combobox-input')
    ) {
      this.outsideClick = true;
      this.cd.detectChanges();
      this.rtypeRef?.update();

      this.countriesRef?.update();
      // this.currencyCode?.update();
    }
  }

  updateMarker() {
    const geocoder = new google.maps.Geocoder();
    // const address = `${this.addressForm.get('street_1').value} ${this.addressForm.get('street_2').value} ${this.addressForm.get('city').value} ${this.addressForm.get('state').value} ${this.addressForm.get('postcode_1').value} ${this.addressForm.get('country').value}`;
    this.combainedAddress = this.getCombainedAddress();

    // this.pinSpotLocation.updateAddress(this.combainedAddress);
    geocoder.geocode({ address: this.combainedAddress }, (results, status) => {
      if (status === 'OK') {
        const location = results[0].geometry.location;

        if (
          this.space?.address?.latitude == location.lat() &&
          this.space?.address?.longitude == location.lng()
        ) {
          this.space.address.longitude = location.lng();
          this.space.address.latitude = location.lat();
          this.space.address.formatted_address = results[0].formatted_address;
          this.location.full_address = results[0].formatted_address;
          this.addressForm
            .get('longitude')
            .setValue(this.space.address.longitude);
          this.addressForm
            .get('latitude')
            .setValue(this.space.address.latitude);
        } else {
          this.space.address.longitude = location.lng();
          this.space.address.latitude = location.lat();
          this.addressForm
            .get('longitude')
            .setValue(this.space.address.longitude);
          this.addressForm
            .get('latitude')
            .setValue(this.space.address.latitude);
        }
        // this.space.country = location

        this.addHomeConfirm.flyTo(location.lat(), location.lng());
        let address_here = {
          longitude: location.lng(),
          latitude: location.lat(),
        };
        this.addHomeGooglemap.flyToLocation(address_here);
      } else {
        // alert(111)
        // this.addHomeConfirm.flyTo(location.lat(), location.lng())
      }
    });
  }

  placeTypeChange(event) {
    if (
      this.titleFromChatGPT != '' &&
      this.titleFromChatGPT.toLowerCase() ==
        this.homeDetailForm.get('title')?.value.toLowerCase()
    ) {
      this.chatgptDescriptionReceived = false;
    } else if (this.homeDetailForm.get('title')?.value == '') {
      this.chatgptDescriptionReceived = false;
    } else {
      this.chatgptDescriptionReceived = true;
    }

    if (
      this.descriptionFromChatGPT != '' &&
      this.descriptionFromChatGPT.toLowerCase() ==
        this.homeDetailForm.get('description')?.value.toLowerCase()
    ) {
      this.chatgptDescriptionReceived = false;
    } else if (this.homeDetailForm.get('description')?.value == '') {
      this.chatgptDescriptionReceived = false;
    } else {
      this.chatgptDescriptionReceived = true;
    }
  }
  hasLeadingZero(phoneNumber) {
    // Remove any non-digit characters from the phone number
    phoneNumber = phoneNumber.replace(/\D/g, '');

    // Check if the phone number starts with a zero
    return phoneNumber.charAt(0) === '0';
  }
}
