import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-terms-privacy-service',
  templateUrl: './terms-privacy-service.component.html',
  styleUrls: ['./terms-privacy-service.component.scss']
})
export class TermsPrivacyServiceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $(document).ready(function() {
      //toggle the component with class accordion_body
      $(".card-header").click(function() {

        if ($('.accordion_body').is(':visible')) {
          $(".accordion_body").slideUp(300);
          $(".plusminus").text('+');
        }
        if ($(this).next(".accordion_body").is(':visible')) {
          $(this).next(".accordion_body").slideUp(300);
          $(this).children(".plusminus").text('+');
        } else {
          $(this).next(".accordion_body").slideDown(300);
          $(this).children(".plusminus").text('-');
        }
      });
    });
  }

}
