<div class="trending_widget_main">
    <div class="widget_header">
        <h2>{{"Trending regions" | translate}}</h2>
        <!-- <span class="view_link"><a>View all</a></span> -->
    </div>
    <div class="swiper_area">
        <swiper #swiper [config]="config" (slideChange)="onSlideChange()">
            <ng-container *ngFor="let space of featuredLocations; index as i">
                <ng-template swiperSlide>
                    <ng-container>


                        <div class="trending_widget_item" (click)="searchResults(space?.name)">
                            <div class="trending_image">
                                <!-- <img src="../../../../../assets/images/6.jpg"> -->
                                <img class="lazyload" [lazyLoad]="space.image_url" defaultImage="/assets/images/default-cover.jpg" />
                                <!-- <img  [src]="space.image_url" (error)="onImgError($event)" /> -->
                            </div>
                            <div class="trending_cont">
                                <span class="trending_title">{{space?.name}}</span>
                            </div>
                        </div>

                    </ng-container>


                </ng-template>
            </ng-container>
        </swiper>
    </div>
</div>