import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { BottomSheetComponent } from 'src/app/shared/components/bottom-sheet/bottom-sheet.component';
import { ExploreSpacesService } from 'src/app/shared/services/explore-spaces.service';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { PromoService } from 'src/app/shared/services/promo.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { SpaceService } from 'src/app/shared/services/space.service';
import { UserService } from 'src/app/shared/services/user.service';
import { EmptyMyHomesComponent } from '../../component/empty-my-homes/empty-my-homes.component';
import { KnowledgeRegionComponent } from '../../component/knowledge-region/knowledge-region.component';
import { MyHomesComponent } from '../../component/my-homes/my-homes.component';
import { PopularStayCardsComponent } from '../../component/popular-stay-cards/popular-stay-cards.component';
import { TrendingRegionCardsComponent } from '../../component/trending-region-cards/trending-region-cards.component';
import { TopCities, TopCountries } from './explore-screen.constants';
import debounce from "src/app/shared/utils/debounce";

declare var $: any;

@Component({
  selector: 'app-explore-screen',
  templateUrl: './explore-screen.component.html',
  styleUrls: ['./explore-screen.component.scss'],
})
export class ExploreScreenComponent implements OnInit, AfterViewInit {
  user: any = [];
  spaces: any = [];
  exploreData: any = [];
  loading: boolean = true;
  featuredSpaces: any = [];
  featuredLocations: any = [];
  public mySpaces = [];
  subscribeTimeout: any;
  editHomeData: any;
  subscribePopStatus = false;
  videoWatched: boolean = false;
  videoBackdrop: boolean = false;
  showWelcomeVideo = false;
  userDetails: any = [];
  subscription: any = [];
  showWelcomepopup: boolean = false;
  homeBenefitsPopup: boolean = false;
  selectedLanguage = 'en';
  arb = false;
  setVideoWatched = false;
  showRewardPopup = false;
  count;
  fromThirdPlace = false;
  canSwap: boolean = false;
  earnTokenPopup = false;
  premiumAccountPopup = false;
  creditcardbookingPopup: boolean = false;
  newcardbookingPopup: boolean = false;
  openAddHomeForm = false;
  active_homes_count = 0;
  id = localStorage.getItem('id');
  isDesktopView: boolean = window.screen.width > 1024;
  isMobileVideoView: boolean = false;

  homeList: any = [];
  homeListLastPage: number = 0;
  showLoadMore: boolean = true;
  region: any = {
    label: undefined,
    params: undefined,
  };
  searchSpaceFilter: any = null;
  additionalFilter: any = null;
  activeCategory: number = 0;

  block_btn: boolean = true;
  show_card_popup: boolean = false;
  @HostListener('window:resize', ['$event'])
  handleResize(event: KeyboardEvent) {
    this.isDesktopView = window.screen.width > 1024;
  }
  modalBackdrop = false;
  @ViewChild('featuredExploreSpacesEl')
  featuredExploreSpacesEl: PopularStayCardsComponent;
  @ViewChild('featuredRegionstSpacesEl')
  featuredRegionstSpacesEl: TrendingRegionCardsComponent;
  @ViewChild('knowledgeSwiperEl') knowledgeSwiperEl: KnowledgeRegionComponent;
  @ViewChild('myVideo') myVideo: ElementRef;
  @ViewChild('bottomSheet') bottomSheet: BottomSheetComponent;
  @ViewChild('bottomSheetReward') bottomSheetReward: BottomSheetComponent;
  @ViewChild('myhomesEl') myhomesEl: MyHomesComponent;
  @ViewChild('earnTokenBottomSheet') earnTokenBottomSheet: BottomSheetComponent;
  @ViewChild('premiumAccountBottomSheet')
  premiumAccountBottomSheet: BottomSheetComponent;
  @ViewChild('creditcardbookingtBottomSheet')
  creditcardbookingtBottomSheet: BottomSheetComponent;
  @ViewChild('newcardbookingBottomSheet')
  newcardbookingBottomSheet: BottomSheetComponent;
  @ViewChild('emptyHomes') emptyHomes: EmptyMyHomesComponent;
  @ViewChild('cardList') cardList: ElementRef;

  constructor(
    public userService: UserService,
    private exploreService: ExploreSpacesService,
    private cd: ChangeDetectorRef,
    private router: Router,
    private spaceService: SpaceService,
    private promoService: PromoService,
    private ngZone: NgZone,
    private cookieService: CookieService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    this.count = localStorage.getItem('RewardPopupCount') || 0;
    this.region.label = this.route.snapshot.paramMap.get('region');
    if (this.region.label) {
      const regionLabelLowerCase = this.region.label.toLowerCase();
      const isTopCountry = TopCountries.find(
        (item) => item.label.toLowerCase() === regionLabelLowerCase
      );
      const isTopCity = TopCities.find(
        (item) => item.label.toLowerCase() === regionLabelLowerCase
      );
      if (!isTopCity && !isTopCountry) {
        this.router.navigate(['/']);
      }
      this.region = isTopCity || isTopCountry;
    }
    this.user.tokens = 0;
    this.loading = true;

    this.getFeaturedLocations();
    if (this.region.params) {
      this.searchSpaceFilter = {
        city: this.region.params?.city || '',
        state: this.region.params?.state || '',
        country: this.region.params?.country || '',
        full_address: this.region.params?.full_address || '',
        longitude: this.region.params?.longitude || undefined,
        latitude: this.region.params?.latitude || undefined,
      };
      this.spaceService
        .searchSpace({
          ...this.searchSpaceFilter,
          page: 1,
        })
        .subscribe(
          (res: any) => {
            this.homeList = res.data;
            this.homeListLastPage = res.meta.current_page;
            this.loading = false;
          },
          () => {
            this.loading = false;
          }
        );
    } else {
      this.spaceService.getHomeByStyleList().subscribe(
        (res: any) => {
          this.homeList = res.data;
          this.homeListLastPage = res.meta.current_page;
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
    }
  }

  ngOnInit(): void {
    this.promoService.tokenUpdates.subscribe((res) => {
      this.getUserDetails();
    });
  }

  public getUserDetails() {
    UserService.cacheBuster$.next();
  }

  private _getUriWithParam = (params: Record<string, any>): string => {
    const Url = new URL(window.location.href);
    const urlParams: URLSearchParams = new URLSearchParams(Url.search);
    for (const key in params) {
      if (params[key] !== undefined) {
        urlParams.set(key, params[key]);
      }
    }
    Url.search = urlParams.toString().replace(window.location.href, '');
    const query = '?' + Url.toString().split('?')[1];
    return decodeURIComponent(query);
  };

  scrollToCardGroup() {
    const properties = this.cardList.nativeElement.getBoundingClientRect();
    document.getElementById('main-content').scrollTo({
      top: properties.top,
      behavior: 'smooth',
    });
  }

  loadMoreHomeList() {
    this.loading = true;
    if (this.region.params && !this.activeCategory) {
      this.spaceService
        .searchSpace({
          ...this.searchSpaceFilter,
          page: this.homeListLastPage + 1,
        })
        .subscribe((res: any) => {
          this.homeList = [...this.homeList, ...res.data];
          this.homeListLastPage = res.meta.current_page;
          this.showLoadMore = res.meta.current_page !== res.meta.last_page;
          this.loading = false;
        });
    } else {
      let _filter;
      if (this.searchSpaceFilter) {
        _filter = {
          ...this.additionalFilter,
          ...this.searchSpaceFilter,
        };
      } else {
        _filter = this.additionalFilter;
      }
      const filterQuery = _filter ? this._getUriWithParam(_filter) : '?';
      this.spaceService
        .getHomeByStyleList(
          filterQuery + `&page=${this.homeListLastPage + 1}`,
          this.activeCategory
        )
        .subscribe((res: any) => {
          this.homeList = [...this.homeList, ...res.data];
          this.homeListLastPage = res.meta.current_page;
          this.showLoadMore = res.meta.current_page !== res.meta.last_page;
          this.loading = false;
        });
    }
  }

  loadHomeByCategory(category) {
    const properties = document.getElementById('card-list-1');
    document
      .getElementById('main-app')
      .scrollTo({ top: properties.offsetTop - 300, behavior: 'smooth' });
    this.activeCategory = category;
    this.loading = true;
    this.scrollToCardGroup();
    this.handleHomeByStyleRequest();
  }
  @debounce(1000)
  handleHomeByStyleRequest() {
    const filterQuery = this.searchSpaceFilter
      ? this._getUriWithParam(this.searchSpaceFilter)
      : "";
    this.spaceService.getHomeByStyleList(filterQuery, this.activeCategory).subscribe(
      (res: any) => {
        this.homeList = res.data;
        this.homeListLastPage = res.meta.current_page;
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }

  loadHomeByFilter(filters) {
    this.additionalFilter = filters;
    let _filter;
    if (this.searchSpaceFilter) {
      _filter = {
        ...filters,
        ...this.searchSpaceFilter,
      };
    } else {
      _filter = filters;
    }
    const filterQuery = this._getUriWithParam(_filter);
    this.scrollToCardGroup();
    this.loading = true;
    this.spaceService
      .getHomeByStyleList(filterQuery, this.activeCategory)
      .subscribe(
        (res: any) => {
          this.homeList = res.data;
          this.homeListLastPage = res.meta.current_page;
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
  }

  public getMyHomes() {
    this.spaceService.getMySpaces().subscribe((homes: any[]) => {
      this.mySpaces = [...homes];
      if (this.mySpaces.length > 0) {
        this.mySpaces.splice(1, 0, { id: null });
      }
      this.cd.detectChanges();
      this.canSwap = homes.filter((home) => home.purpose != 'host').length > 0;
    }),
      (error) => {
        throw new Error(error);
      };
  }

  public getFeaturedSpaces() {
    // this.loading = true;
    this.exploreService
      .getFeaturedSpaces()
      .subscribe(
        (res: any) => {
          // this.loading = false;
          this.featuredSpaces = res.data;

          this.featuredExploreSpacesEl?.update();
          this.cd.detectChanges();
        },
        (err) => {
          // this.loading = false;
        }
      )
      .add(() => {});
  }

  public getFeaturedLocations() {
    this.exploreService
      .getFeaturedLocations()
      .subscribe((res: any) => {
        this.featuredLocations = res.data;

        this.featuredRegionstSpacesEl?.update();
        this.cd.detectChanges();
      })
      .add(() => {});
  }

  searchViewList() {
    localStorage.setItem('flexibleSelect', 'false');
    this.cookieService.delete('search-address');
    this.cookieService.delete('searchFilter');
    this.googleAnalyticsService.eventEmitter(
      'SR_ExplorePage',
      'Search',
      'SR_ExplorePage_Search'
    );

    this.router.navigate(['/search-homes']);
  }

  goToTokenPurchase() {
    clearTimeout(this.subscribeTimeout);
    this.subscribePopStatus = false;

    this.googleAnalyticsService.eventEmitter(
      'TK_ViewBalance',
      'Tokens',
      'TK_ViewFromExplore'
    );
  }

  togglePopup(event) {
    this.fromThirdPlace = event.thirdplace;
    this.showWelcomeVideo = true;
    this.backdropToggle(true);

    if (this.isDesktopView) {
      this.videoBackdrop = true;
      this.modalBackdrop = false;
      $('.app-content').removeClass('noscroll');
    } else {
      $('.app-content').addClass('noscroll');
      this.modalBackdrop = true;
      this.videoBackdrop = false;
    }
  }

  closeVideoPopup() {
    $('#dashboard-video-popup').hide();
    this.myVideo.nativeElement.pause();
    this.backdropToggle(false);
    this.modalBackdrop = false;
    $('.app-content').removeClass('noscroll');
    this.count = localStorage.getItem('RewardPopupCount') || 0;

    if (!this.fromThirdPlace) {
      if (this.setVideoWatched && !this.isDesktopView) {
        this.bottomSheetReward.toggle();
        this.backdropToggle(false);
      } else if (this.setVideoWatched) {
        this.showRewardPopup = true;
        this.backdropToggle(true);
      }
    } else {
      this.backdropToggle(false);
    }
  }

  public getSpaces() {
    this.loading = true;
    this.spaceService.getMySpaces().subscribe((data) => {
      this.spaces = data;
      this.loading = false;
    }),
      (err) => {
        this.loading = false;
      };
  }

  backdropToggle(status: boolean) {
    $('.app-content').scrollTop(0);
    this.videoBackdrop = status;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (!this.videoWatched && this.myVideo) {
        this.myVideo.nativeElement.addEventListener('playing', () => {
          this.userService.updateVideoWatch().subscribe(
            (resp) => {
              this.setVideoWatched = true;
            },
            (err) => {}
          );
        });
      }
    }, 5000);
  }

  toggleAddHome(popupType = '') {
    switch (popupType) {
      case 'addhome':
        if (!this.isDesktopView) {
          this.homeBenefitsPopup = true;
          this.bottomSheet.toggle();
        } else {
          this.homeBenefitsPopup = true;
          this.backdropToggle(true);
        }
        break;
      case 'earntoken':
        if (!this.isDesktopView) {
          this.earnTokenPopup = true;
          this.earnTokenBottomSheet.toggle();
        } else {
          this.earnTokenPopup = true;
          this.backdropToggle(true);
        }
        break;

      case 'premiumaccount':
        if (!this.isDesktopView) {
          this.premiumAccountPopup = true;
          this.premiumAccountBottomSheet.toggle();
        } else {
          this.premiumAccountPopup = true;
          this.backdropToggle(true);
        }
        break;

      case 'creditcardbooking':
        if (!this.isDesktopView) {
          this.creditcardbookingPopup = true;
          this.creditcardbookingtBottomSheet.toggle();
        } else {
          this.creditcardbookingPopup = true;
          this.backdropToggle(true);
        }
        break;

      case 'newdbooking':
        if (!this.isDesktopView) {
          this.newcardbookingPopup = true;
          this.newcardbookingBottomSheet.toggle();
        } else {
          this.newcardbookingPopup = true;
          this.backdropToggle(true);
        }
        break;
    }
  }

  handleAddHome(routeChoice = '') {
    switch (routeChoice) {
      case 'addhome':
        if (!this.isDesktopView) {
          this.bottomSheet.toggle();
        }

        if (
          this.active_homes_count >= 30 &&
          this.userService.allowedMaxHomes == '0'
        ) {
          this.userService.maxAddHomeReached.next(true);
        } else {
          this.showAddHomeForm(null);
        }

        this.userService
          .postAnalytics(this.router.url, 'explore_popup_add_home_clicked')
          .subscribe((res) => {});

        // Fire the AppsFlyer event
        (window as any).AF('pba', 'event', {
          eventType: 'EVENT',
          eventName: 'add_home_button_clicked',
          eventValue: {
            screen: 'explore_popup',
          },
        });
        break;

      case 'earntoken':
        this.router.navigate(['/dashboard-profile'], {
          fragment: 'achievements',
          queryParams: { previousPage: '/' },
        });
        break;

      case 'buytokens':
        this.userService.postAnalytics(
          this.router.url,
          'explore_popup_buy_tokens_clicked'
        );
        break;

      case 'premiumaccount':
        this.block_btn = false;
        if (this.isDesktopView) {
          this.router.navigate(['/settings/subscriptions']);
        } else {
          this.premiumAccountBottomSheet.toggle();
          this.router.navigate(['/settings/subscriptions']);
        }
        break;

      case 'creditcardbooking':
        if (!this.isDesktopView) {
          this.creditcardbookingtBottomSheet.toggle();
        }

        if (
          this.active_homes_count >= 30 &&
          this.userService.allowedMaxHomes == '0'
        ) {
          this.userService.maxAddHomeReached.next(true);
        } else {
          this.showAddHomeForm(null);
        }
        this.userService
          .postAnalytics(this.router.url, 'explore_popup_add_home_clicked')
          .subscribe((res) => {});

        // Fire the AppsFlyer event
        (window as any).AF('pba', 'event', {
          eventType: 'EVENT',
          eventName: 'add_home_button_clicked',
          eventValue: {
            screen: 'explore_popup',
          },
        });

        break;

      case 'newdbooking':
        if (!this.isDesktopView) {
          this.newcardbookingBottomSheet.toggle();
        }
        if (this.spaces.length == 0) {
          this.loading = false;
          this.showAddHomeForm(null);
        } else {
          this.loading = false;
          this.router.navigate([`/spaces/edit-home/${this.spaces[0]?.id}`]);
        }
        break;
    }
  }

  closeBenefitsPopup() {
    this.homeBenefitsPopup = false;
    this.backdropToggle(false);
  }

  closeEarnTokenPopup() {
    this.earnTokenPopup = false;
    this.backdropToggle(false);
  }

  closePremiumAccountPopup() {
    this.premiumAccountPopup = false;
    this.backdropToggle(false);
  }

  closecreditcardbookingPopup() {
    this.creditcardbookingPopup = false;
    this.backdropToggle(false);
  }

  closenewcardbookingPopup() {
    this.newcardbookingPopup = false;
    this.backdropToggle(false);
  }

  closeConfirmPopup() {
    this.setVideoWatched = false;
    this.showRewardPopup = false;
    this.backdropToggle(false);
    this.count = this.count + 1;
    localStorage.setItem('RewardPopupCount', this.count);
  }

  addHome() {
    this.showAddHomeForm(null);
  }

  showAddHomeForm(event) {
    if (event) {
      this.mySpaces = [];
      this.editHomeData = event;
    } else {
      this.editHomeData = undefined;
    }
    this.homeBenefitsPopup = false;
    this.creditcardbookingPopup = false;
    this.newcardbookingPopup = false;
    this.openAddHomeForm = true;
    this.backdropToggle(true);
  }

  closeAddFormPopup() {
    this.openAddHomeForm = false;
    this.backdropToggle(false);
    this.getMyHomes();
    this.getSpaces();
    this.cd.detectChanges();
  }

  afterDeleteHome(spaceId) {
    this.loading = true;
    this.spaceService.delete(spaceId).subscribe(
      (data) => {
        this.snackbarService.show({
          message: this.translate.instant('Home deleted'),
          type: 'success',
          config: {
            class: 'centered',
          },
        });
        this.ngZone.run(() => {
          this.getMyHomes();
          this.getSpaces();
          this.cd.detectChanges();
          this.loading = false;
        });
      },
      (err) => {
        this.snackbarService.show({
          message: err,
          type: 'success',
          config: {
            class: 'centered',
          },
        });
        this.loading = false;
      }
    );
  }
}
