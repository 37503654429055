import { Injectable } from '@angular/core';
import { HttpService } from "./http.service";
declare let gtag:Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor(
		private httpService: HttpService) { }

  public eventEmitter(
    eventName: string,
    eventCategory: string ,
    eventLabel: string ,
   // eventValue: string = null
   ){
         gtag('event', eventName, {
                event_category: eventCategory,
                //  eventAction: eventAction,
                event_label: eventLabel
               })


    }

  public purchaseEvent(
    bookingId: string,
    value: number,
    spaceId: number
   ){
    // this event is pushed only to Google Tag Manager
    (window as any).dataLayer.push({
      event: 'eec.purchase',
      ecommerce: {
        purchase: {
          actionField: {
            id: bookingId,        // Transaction ID. Required for purchases and refunds.
            revenue: value,     // Total transcation value (incl. tax and shipping)
            tax: 0.00,
          },
          products: [{
            id: spaceId.toString(),
            price: value,
          }]
        }
      }
    });
  }

	getGAClientId(): string | null {
		const gaCookie = document.cookie.split('; ').find(row => row.startsWith('_ga='));
		if (gaCookie) {
			return gaCookie.split('=')[1].split('.').slice(-2).join(".");
		}
		return null;
	}


  sendGAClientId() {
    const clientId = this.getGAClientId();
    if (clientId) {
        const endpoint = `/api/gaid`;
        this.httpService.post(endpoint, { clientId }).subscribe(
            error => {

            }
        );
    } else {

    }
  }
}
