<div class="autocomplete">

	<!-- <input placeholder="{{'Address' | translate}}" class="input" [(ngModel)]="location.full_address"
		(ngModelChange)="autoCompleteAddress($event)" autocomplete="new-password"> -->
		<!-- <div class="search-box-section"> 

        <input type="text" class="form-control search-iput-style" placeholder="Where are you going?" [(ngModel)]="location.full_address"
		(ngModelChange)="autoCompleteAddress($event)" autocomplete="new-password">
	
		
        </div> -->
        
        <div class="search_area">
            <div class="search_form" [ngClass]="{'search_form_dir-override': selectedLanguage == 'ar'}">
                <input type="text" class="form-input"  (click)="searchListViewSpaces()" placeholder="{{'Where are you going?' | translate}}" [(ngModel)]="location.full_address"
                (ngModelChange)="autoCompleteAddress($event)">
                <button type="button" class="search_btn"><span>Search</span><img alt="" src="/assets/images/loc_wh_ic.svg"></button>
            </div>
            <div class="results {{ locations.length > 0 ? 'visible' : '' }}">
                <ul>
                    <li *ngFor="let location of locations" >
                        {{location.full_address}}
                    </li>
                </ul>
            </div>

            <div class="map_view_btn btn_large">
                <button type="button" (click)="searchSpaces()">{{"Map View" | translate}}&nbsp;&nbsp; <img alt="" src="/assets/images/loc_wh_ic.svg"></button>
                <!-- <button type="button" (click)="searchListViewSpaces()">List View&nbsp;&nbsp; <img src="/assets/images/loc_wh_ic.svg"></button> -->

            </div>


        </div>
</div>