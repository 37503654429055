import { Component, Input, OnInit } from "@angular/core";
import { UserService } from "../../services/user.service";

@Component({
	selector: "app-avatar",
	templateUrl: "./avatar.component.html",
	styleUrls: ["./avatar.component.scss"]
})
export class AvatarComponent implements OnInit {
	@Input() public name: string = "";
	@Input() public picture: string;
	@Input() public width: number | string = 60;
	@Input() public height: number | string = 60;
	@Input() public borderRadius: string = "50%";
	@Input() public verified: boolean;
	@Input() public clickable:boolean;
	imgString:any;
    timeStamp:any= Math.floor(Math.random() * 1000) + 1;
	constructor(public userService : UserService) { }

	ngOnInit(): void {
		this.timeStamp=  Math.floor(Math.random() * 1000) + 1;
		// this.userService.currentUser.subscribe((res:any)=>{
		// 			this.picture=res?.image_url;
		// });

	}
}
