import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SpaceService } from '../../services/space.service';
import { SnackbarService } from '../../services/snackbar.service';
import { PopupService } from '../../services/popup.service';

@Component({
  selector: 'app-home-card',
  templateUrl: './home-card.component.html',
  styleUrls: ['./home-card.component.scss'],
})
export class HomeCardComponent implements OnInit {
  @Input() space: any = {};

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private spaceService: SpaceService,
    private snackbarService: SnackbarService,
    private popupService: PopupService
  ) {}

  ngOnInit(): void {}

  getPersentage(amount) {
    const discount = Math.round((parseInt(amount) * 20) / 100);
    const offerAmt = amount - discount;
    return offerAmt;
  }

  goToHomeDetail() {
    this.router.navigateByUrl('/view-home/home-details-ibs/' + this.space.id);
    window.localStorage.setItem('swapCancelRedirectUri', this.router.url);
  }

  public flooringNumber(num: number): number {
    return Math.floor(num);
  }

  public onLike(event: Event, space) {
    event.stopPropagation();
    const token = localStorage.getItem('token');
    if (token && token !== 'undefined') {
      this.spaceService.likeSpace(space.id, space.is_favourited).subscribe(
        (response) => {
          this.space.is_favourited = !space.is_favourited;

          if (this.space.is_favourited) {
            this.snackbarService.show({
              message: 'This home has been saved to your favourites!',
              type: 'success',
              config: {
                class: 'centered',
              },
            });
          } else {
            this.snackbarService.show({
              message: 'Removed home from your favourites',
              type: 'success',
              config: {
                class: 'centered',
              },
            });
          }
        },
        (err) => {
          this.toastr.error(err, 'Error');
        }
      );
    } else {
      this.popupService.openAuthModal();
    }
  }
}
