import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClevertapService } from 'src/app/shared/services/clevertap.service';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { UserService } from 'src/app/shared/services/user.service';

declare var $: any;

@Component({
  selector: 'app-congrats-list-premium',
  templateUrl: './congrats-list-premium.component.html',
  styleUrls: ['./congrats-list-premium.component.scss']
})
export class CongratsListPremiumComponent implements OnInit {
  public loading = false;
  public listHome = false;
  shareMessage = " I've just signed up with Holiday Swap to travel the world for free!  Join me and don't miss out! https://holidayswap.page.link/Afav";
  webShareUrl="https://holidayswap.page.link/Afav"
  public tryPremium = false;
  public signUpRoute = 'listHome';
  videoBackdrop = false;
  openAddHomeForm: boolean = false;
  constructor(private router : Router,private clevertapService: ClevertapService,
    private route : ActivatedRoute,
    private userService: UserService,
    private googleAnalyticsService: GoogleAnalyticsService) {
      this.signUpRoute = localStorage.getItem('signUpRoute')
      if(this.signUpRoute == 'listHome') {
        this.listHome = true;
      }
      else {
        this.tryPremium = true;
      }
  }

  ngOnInit(): void {
    		this
			.googleAnalyticsService
			.eventEmitter("SU_Complete", "SignUp", "SU_CompleteSuccess");
    // if(this.clevertapService.isWebView){
			// this
			// .googleAnalyticsService
			// .eventEmitter("SU_Complete", "SignUp", "SU_App_Complete");
		// }
    // else{
		// 	this
		// 	.googleAnalyticsService
		// 	.eventEmitter("congrats_signup_web", "Clicks", "/congrats-signup");
		// }
    this.userService.saveUtM({
      url : window.location.href,

    }).subscribe((response) => {

      // this.router.navigate(["/auth/nearly-there"]);
    });

  }

  onLogin() {

  }

  listHomes() {
    // this.router.navigate(["/auth/nearly-there"]);
    this.openAddHomeForm = true;
    this.backdropToggle(true);
  }

  tryPremiumSubscription() {
    this.router.navigate(["/auth/upgrade-subscription"], {queryParams : this.route.snapshot.queryParams, queryParamsHandling :'merge'});
  }

  onExplore() {
    this.router.navigate(["/"], {queryParams : this.route.snapshot.queryParams, queryParamsHandling :'merge'});
  }

  closeCongrats() {
    this.router.navigate(["/"], {queryParams : this.route.snapshot.queryParams, queryParamsHandling :'merge'});
  }

  closeAddFormPopup() {
    this.openAddHomeForm = false;
    this.backdropToggle(false);
    this.router.navigate(["/"], {queryParams : this.route.snapshot.queryParams, queryParamsHandling :'merge'});
  }

  backdropToggle(status : boolean) {
    $('.app-content').scrollTop(0);
    if(status) {
      // $('.app-content').css('overflow', 'hidden')
    }
    else {
      // $('.app-content').css('overflow', 'auto')
    }
    this.videoBackdrop = status;
  }

}
