import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { CardService } from '../../services/card.service';
import { SnackbarService } from '../../services/snackbar.service';
import { StripeService } from '../../services/stripe.service';
import { BottomSheetComponent } from '../bottom-sheet/bottom-sheet.component';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-payment-options',
  templateUrl: './payment-options.component.html',
  styleUrls: ['./payment-options.component.scss'],
})
export class PaymentOptionsComponent implements OnInit {
  @Input() public selected;
  @Input() public type = 'subscription';
  @Output() public onPaymentSuccessEmit = new EventEmitter();
  @Output() public onAddNewCardEmit = new EventEmitter();
  @Output() public onCardSelect = new EventEmitter();
  @Output() public onSuccess = new EventEmitter();
  @Output() public onError = new EventEmitter();
  @Input() public item: {
    type?: 'subscription' | 'bundle';
    id?: number;
    price?: number;
    quantity?: number;
    name?: string;
  } = {};

  public payment: any = { method: 1 };
  public loading: boolean = false;
  public cardPayment: boolean = true;
  public cards = [];
  public active = {};
  public paySelected: boolean = false;
  public editable = false;
  public addCard: boolean = false;
  public isabondedPremium: boolean = false;
  public processingPayment: boolean = false;
  public checkIOS = false;

  constructor(
    public cardService: CardService,
    private stripeService: StripeService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private snackbarService: SnackbarService,
    public translate: TranslateService
  ) {
    this.getCardDetails();
    this.checkIOS = this.isIOSDevice();
  }

  ngOnInit(): void {}

  isIOSDevice() {
    return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  }

  getCardDetails() {
    this.loading = true;
    this.cardService
      .get()
      .subscribe(
        (response: { data: [] }) => {
          this.cards = response.data;
        },
        (err) => {}
      )
      .add(() => {
        this.loading = false;
      });
  }

  public showCardPayment() {
    this.cardPayment = !this.cardPayment;
  }

  onPaymentSuccess($event) {
    this.cardPayment = !this.cardPayment;
    this.onPaymentSuccessEmit.emit($event);
  }

  onRemove(card) {}

  public onSelect(card) {
    this.active = card.id;
    this.payment.card = card.id;
    this.paySelected = true;
    if (this.type == 'token-purchase') {
      this.onCardSelect.emit(card);

      this.googleAnalyticsService.eventEmitter(
        'TK_SelectCard',
        'Tokens',
        'TK_SelectCardSuccess'
      );
    }

    // this.onSelected.emit(card.id);
  }

  public selectPaypalPayment() {
    this.active = 'paypal';
    this.paySelected = true;
  }

  public addNewCard() {
    this.addCard = true;
    this.onAddNewCardEmit.emit(true);

    this.googleAnalyticsService.eventEmitter(
      'TK_AddCardStart',
      'Tokens',
      'TK_AddFromManage'
    );

    this.googleAnalyticsService.eventEmitter(
      'BK_AddCreditCard',
      'Booking',
      'BK_AddedCard'
    );
  }

  public updateCards() {
    this.getCardDetails();
  }

  public onPay() {
    this.isabondedPremium = true;
    localStorage.setItem('bundleConfirm', '1');
    if (this.item.type === 'bundle') {
      return this._handleTokenPurchase();
    }
    // handle subscription
    // this.confirmPurchase_btnTxt = "Processing";
    this._handleSubscription();
  }

  private _onSuccess() {
    this.onSuccess.emit(this.item.id);
  }

  private _handleTokenPurchase() {
    this.loading = true;
    this.stripeService
      .getIntent({
        bundle_id: this.item.id,
        payment_method: this.payment.card,
      })
      .subscribe(
        async (response: any) => {
          if (!response.requires_action) {
            this._onSuccess();
            return;
          }

          const { paymentIntent, error: confirmError } =
            await this.stripeService.stripe.confirmCardPayment(
              response.data.client_secret,
              { payment_method: this.payment.card },
              { handleActions: false }
            );

          if (confirmError) {
            this.loading = false;
            // this.confirmPurchase_btnTxt = "Retry";
            this.onError.emit(confirmError.message);
          } else {
            if (paymentIntent.status === 'requires_action') {
              const { error } =
                await this.stripeService.stripe.confirmCardPayment(
                  response.data.client_secret
                );
              if (error) {
                this.loading = false;
                // this.confirmPurchase_btnTxt = "Retry";
                this.onError.emit(error.message);
              } else {
                // The payment has succeeded.
                this._onSuccess();
              }
            } else {
              this._onSuccess();
            }
          }

          this._onSuccess();
        },
        (err) => {
          // this.confirmPurchase_btnTxt = "Retry";
          this.loading = false;
          this.snackbarService.show({
            message: this.translate.instant('Transaction failed'),
            type: 'error',
          });
        }
      ),
      (err) => {
        this.loading = false;
        this.snackbarService.show({
          message: this.translate.instant('Transaction failed'),
          type: 'error',
        });
        // this.confirmPurchase_btnTxt = "Retry";
      };
  }

  private _handleSubscription() {
    //if (this.processingPayment) return;

    this.processingPayment = true;
    this.loading = true;

    this.onSuccess.emit({
      method: this.payment.card,
      id: this.item.id,
    });
    // this.loading = false;
  }
}
