import { Component, OnInit } from "@angular/core";

@Component({
	selector: "app-brand-page",
	templateUrl: "./brand-page.component.html",
	styleUrls: ["./brand-page.component.scss"],
})
export class BrandPage implements OnInit {


	constructor() { }

	ngOnInit(): void {
	}

}
