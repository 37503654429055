import { Injectable } from '@angular/core';
import { DailyRewardPopupComponent } from '../components/daily-reward-popup/daily-reward-popup.component';

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  private dailyRewardPopupComponent: DailyRewardPopupComponent;

  setDailyRewardPopupComponent(component: DailyRewardPopupComponent) {
    this.dailyRewardPopupComponent = component;
  }

  openAuthModal() {
    if (this.dailyRewardPopupComponent) {
      this.dailyRewardPopupComponent.openAuthModal();
    } else {
      console.error('DailyRewardPopupComponent not set in PopupService.');
    }
  }
}
