<div class="explore_header">
    <!-- header left brand start -->
   <div class="explore_left">
       <div class="explore_brand">
           <span class="back-arrow" (click)="pop()"><img src="/assets/images/back_arrow.svg"></span>
       </div>
   </div>
    <!-- header left brand start -->
   <!-- header right navigation start -->
   <!-- <div class="explore-right">
       <ul>
           <li class="notification"><a><img src="/assets/images/noti_ic_bl.svg"><span class="noti_badge"></span></a></li>
          
           <li class="language_dd">
               <div class="dropdown">
                   <a class="btn btn-secondary dropdown-toggle" >
                    <img src="/assets/flags/uk_flag.png">
                   </a>
                   <div class="dropdown-menu bunch_drop" aria-labelledby="dropdownMenuLink">
                     <a class="dropdown-item" >Action</a>
                     <a class="dropdown-item" >Another action</a>
                     <a class="dropdown-item" >Something else here</a>
                   </div>
                 </div>
           </li>
          
       </ul>
   </div> -->
   <!-- header right navigation end -->
</div>