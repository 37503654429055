import { Component, OnInit } from "@angular/core";
import { ExploreSpacesService } from "../../../../shared/services/explore-spaces.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserService } from "../../../../shared/services/user.service";
import { SnackbarService } from "../../../../shared/services/snackbar.service";

@Component({
	selector: "app-how-it-works",
	templateUrl: "./how-it-works-component.component.html",
	styleUrls: ["./how-it-works-component.component.scss"],
})
export class HowItWorksComponent implements OnInit {
  contactForm!: FormGroup;

  public featureSpace = [];
  constructor(
    private formBuilder: FormBuilder,
    private exploreService: ExploreSpacesService,
    private userService: UserService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group(
      {
        first_name: ['', [Validators.required]],
        last_name: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        phone: ['', [Validators.required, Validators.pattern(/^\+\d{12}$/)]],
        message: ['', [Validators.required, Validators.minLength(10)]],
      }
    );

    this.exploreService.getFeaturedSpaces().subscribe((res: any) => {
      this.featureSpace = res.data;
    });
  }

  onSubmitContact() {
    this.contactForm.markAllAsTouched();
    if (this.contactForm.valid) {
      this.userService.getInTouch(this.contactForm.value).subscribe(() => {
        this.snackbarService.show({
          message : 'Contact Request sent successfully!',
          type: 'success',
          config: {
            class : 'right'
          }
        });
        this.contactForm.reset();
      });
    }
  }
}
