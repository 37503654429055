import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeSpaceDetailsComponent } from './pages/home-space-details/home-space-details.component';
import { HomeSpacesComponent } from "./pages/home-spaces/home-spaces.component";
import { EditHomeComponentComponent } from "src/app/shared/components/edit-home-component/edit-home-component.component";


const routes: Routes = [
	{
		path: "home-space-details/:id", component: HomeSpaceDetailsComponent
	},
	{
		path: "home-spaces/:id", component: HomeSpacesComponent
	},
	{
		path:"edit-home/:id", component: EditHomeComponentComponent
	}
	// { path: ":id", component: SpaceDetailsComponent },
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class SpacesRoutingModule { }
