import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-new-map-result-view',
  templateUrl: './new-map-result-view.component.html',
  styleUrls: ['./new-map-result-view.component.scss'],
})
export class NewMapResultViewComponent implements OnInit {
  @Input() public currentmap = null;

  @Input() public spaces = [];
  @Input() public searchLocation: any = [];
  @Output() public onReady = new EventEmitter();

  options: google.maps.MapOptions = {
    zoom: 4,
    // center: {lat: 41.3359375, lng: 51.0722341},
    center: { lat: 51.07612361380574, lng: 10.208893086823068 },
    gestureHandling: 'greedy',
    minZoom: +2,
    maxZoom: +17,
    zoomControl: false,
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    draggable: true,
    restriction: {
      latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
    },
    // styles :[
    //   {
    //     elementType: "geometry",
    //     stylers: [{ color: "#f5f5f5" }],
    //   },
    //   {
    //     elementType: "labels.icon",
    //     stylers: [{ visibility: "off" }],
    //   },
    //   {
    //     elementType: "labels.text.fill",
    //     stylers: [{ color: "#616161" }],
    //   },
    //   {
    //     elementType: "labels.text.stroke",
    //     stylers: [{ color: "#f5f5f5" }],
    //   },
    //   {
    //     featureType: "administrative.land_parcel",
    //     elementType: "labels.text.fill",
    //     stylers: [{ color: "#bdbdbd" }],
    //   },
    //   {
    //     featureType: "poi",
    //     elementType: "geometry",
    //     stylers: [{ color: "#eeeeee" }],
    //   },
    //   {
    //     featureType: "poi",
    //     elementType: "labels.text.fill",
    //     stylers: [{ color: "#757575" }],
    //   },
    //   {
    //     featureType: "poi.park",
    //     elementType: "geometry",
    //     stylers: [{ color: "#e5e5e5" }],
    //   },
    //   {
    //     featureType: "poi.park",
    //     elementType: "labels.text.fill",
    //     stylers: [{ color: "#9e9e9e" }],
    //   },
    //   {
    //     featureType: "road",
    //     elementType: "geometry",
    //     stylers: [{ color: "#ffffff" }],
    //   },
    //   {
    //     featureType: "road.arterial",
    //     elementType: "labels.text.fill",
    //     stylers: [{ color: "#757575" }],
    //   },
    //   {
    //     featureType: "road.highway",
    //     elementType: "geometry",
    //     stylers: [{ color: "#dadada" }],
    //   },
    //   {
    //     featureType: "road.highway",
    //     elementType: "labels.text.fill",
    //     stylers: [{ color: "#616161" }],
    //   },
    //   {
    //     featureType: "road.local",
    //     elementType: "labels.text.fill",
    //     stylers: [{ color: "#9e9e9e" }],
    //   },
    //   {
    //     featureType: "transit.line",
    //     elementType: "geometry",
    //     stylers: [{ color: "#e5e5e5" }],
    //   },
    //   {
    //     featureType: "transit.station",
    //     elementType: "geometry",
    //     stylers: [{ color: "#eeeeee" }],
    //   },
    //   {
    //     featureType: "water",
    //     elementType: "geometry",
    //     stylers: [{ color: "#9cc0f9" }],
    //   },
    //   {
    //     featureType: "water",
    //     elementType: "labels.text.fill",
    //     stylers: [{ color: "#8ec1f9" }],
    //   },
    // ],
  };
  markerOptions: google.maps.MarkerOptions = { draggable: false };
  markerPositions: google.maps.LatLngLiteral[] = [];
  markers = [];
  markerArray = [];

  user: any = [];
  upcomingSwap: any = [];
  // markers : any = [];
  formattedaddress = ' ';
  address_options = {
    types: [],
    componentRestrictions: {
      country: ['AU'],
    },
  };
  latlng_;
  imageSrc;
  map: google.maps.Map;
  // currentLocation = new google.maps.LatLng(41.3359375, 51.0722341);
  currentLocation = new google.maps.LatLng(
    51.07612361380574,
    10.208893086823068
  );

  constructor() {
    // navigator.geolocation.getCurrentPosition((position) => {
    //   this.currentLocation = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
    // },(error) => {
    //   this.currentLocation = new google.maps.LatLng(41.3359375, 51.0722341);
    // })
  }

  async ngOnInit(): Promise<void> {
    this.map = new google.maps.Map(
      document.getElementById('gmap'),
      this.options
    );
    this.map.panTo(this.currentLocation);
    this.onReady.emit(this.map);
    await this.setSearchLocation();
    await this.plotMapContent(12);
    // this.setSearchLocation();
  }

  async plotMapContent(zoom) {
    // this.map.clear();
    if (this.spaces.length > 0) {
      this.map = new google.maps.Map(
        document.getElementById('gmap'),
        this.options
      );
      // setTimeout(() => {
      // await this.map.panTo(this.currentLocation);
      await this.onReady.emit(this.map);
      await this.setSearchLocation();
      const hashmap = {};
      this.spaces.map((swaps) => {
        if (swaps?.address) {
          let marker = new CustomMarker(
            new google.maps.LatLng(
              swaps?.address?.latitude,
              swaps?.address?.longitude
            ),
            this.map,
            swaps?.mobile_image,
            swaps,
            hashmap,
          );

          this.markerArray.push(
            new google.maps.Marker({
              position: new google.maps.LatLng(
                swaps?.address?.latitude,
                swaps?.address?.longitude
              ),
              map: this.map,
              icon: {
                url: '', // set url as ''
              },
              visible: false,
            })
          );
          this.markers.push(marker);
          this.map.setZoom(zoom);
        }
      });

      let styles = [
        {
          width: '20px',
          className: 'custom-clustericon',
        },
      ];

      let imagePath =
        'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m';
      // setTimeout(async () =>  {
      // }, 5000)
    }
  }

  async setSearchLocation(showWithMapData = false) {
    if (this.searchLocation && showWithMapData) {
      this.currentLocation = new google.maps.LatLng(
        this.searchLocation?.latitude,
        this.searchLocation?.longitude
      );
    } else {
      if (this.spaces.length > 0) {
        this.currentLocation = new google.maps.LatLng(
          this.spaces[0].address?.latitude,
          this.spaces[0].address?.longitude
        );
      } else {
        this.options.zoom = 3;
        // this.currentLocation = new google.maps.LatLng(41.3359375, 51.0722341);
        this.currentLocation = new google.maps.LatLng(
          51.07612361380574,
          10.208893086823068
        );
      }
    }
    setTimeout(() => {
      this.map.panTo(this.currentLocation);
    }, 1000);
  }

  clear() {

  }

  async setMapCenterWithSpecifiedZoom(
    address: { longitude: number; latitude: number },
    zoom: number
  ) {
    // this.map.setCenter([address.longitude, address.latitude]);
    this.currentLocation = new google.maps.LatLng(
      address?.latitude,
      address?.longitude
    );
    await this.map.panTo(this.currentLocation);
    this.map.setZoom(zoom);
    await this.plotMapContent(zoom);
  }

  async reRenderMap(spaces, zoom = 12) {
    this.spaces = spaces;
    this.markers = [];
    this.markerArray = [];
    await this.setSearchLocation(true);
    await this.plotMapContent(zoom);
  }

  async flyToLocation(address: { longitude: number; latitude: number }, zoom) {
    this.currentLocation = new google.maps.LatLng(
      address?.latitude,
      address?.longitude
    );
    this.map.setZoom(zoom);
    await this.map.panTo(this.currentLocation);
  }
}

function CustomMarker(latlng, map, imageSrc, space, hashmap) {
  this.latlng_ = latlng;
  this.imageSrc = imageSrc;
  this.currentSpace = space;
  this.hashmap = hashmap;
  // Once the LatLng and text are set, add the overlay to the map.  This will
  // trigger a call to panes_changed which should in turn call draw.
  this.setMap(map);
}

CustomMarker.prototype = new google.maps.OverlayView();

CustomMarker.prototype.draw = function () {
  // Check if the div has been created.
  var div = this.div_;
  if (!div) {
    // Create a overlay text DIV
    div = this.div_ = document.createElement('div');
    const elimg = document.createElement('img');
    const elspan = document.createElement('span');

    div.className = 'custom-marker';
    div.style.marginTop = `-35px`;
    div.style.position = 'absolute';
    div.style.display = 'flex';
    div.style.justifyContent = 'center';
    div.style.alignItems = 'center';

    elimg.style.width = '16px';
    elimg.style.height = '16px';
    elimg.style.marginRight = '4px';

    if (this.currentSpace.purpose === 'swap') {
      elimg.src = 'assets/images/new_swap_icon.svg';
      elspan.textContent = 'SWAP';
    } else {
      if (this.currentSpace.payment_type_id === 561) {
        elspan.textContent = `$${this.currentSpace.hosting_amount}`;
      } else if (this.currentSpace.payment_type_id === 562) {
        elimg.src = 'assets/images/Token_Plane.png';
        elspan.textContent = `${this.currentSpace.hosting_tokens}`;
      }
    }

    if (
      this.currentSpace.purpose === 'swap' ||
      this.currentSpace.payment_type_id === 562
    ) {
      div.appendChild(elimg);
    }
    div.appendChild(elspan);

    const homeChild = document.createElement('div');

    google.maps.event.addDomListener(div, 'click', async (event) => {
      const elementsToRemove = document.querySelectorAll('.map-home-child');
      const removalPromises = Array.from(elementsToRemove).map(function(element) {
        return new Promise<void>(function(resolve) {
          element.remove();
          resolve();
        });
      });
      await Promise.all(removalPromises);
      div.appendChild(homeChild)
      div.classList.add('map-mark-active');
      homeChild.className = 'map-home-child';
      homeChild.style.display = 'block';
      homeChild.innerHTML = `
        <div>
            <div class="mark-card">
              <button class="mark-close">✕</button>
              <a href="/view-home/home-details-ibs/${this.currentSpace.id}?returnURL=map-view-list\" class="mark-card-link">
                <div class="mark-card-link-image">
                  <img class="mark-card-link-img" alt="${this.currentSpace?.mobile_image}" src="${this.currentSpace?.mobile_image}" />
                </div>
                <div class="mark-card-content">
                  <span class="mark-card-title">${this.currentSpace?.title}</span>
                  <span class="mark-card-location">
                            ${ this.currentSpace?.address?.street_1 },
                          ${ this.currentSpace?.address?.city }
                  </span>
                  <div class="mark-card-summary">
                    <div>
                      <span class="mark-card-price">$${this.currentSpace?.hosting_amount}</span>
                      <span class="mark-card-des">avg per night</span>
                    </div>
                    <div class="mark-card-heart">
                      <i class="fa fa-heart-o" ></i>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
      `;
      const closeButton = homeChild.querySelector('.mark-close');
      closeButton.addEventListener('click', () => {
        setTimeout(() => {
          homeChild.innerHTML = '';
          homeChild.className = '';
          div.classList.remove('map-mark-active');
        }, 10)
      });
    });

    // Then add the overlay to the DOM
    var panes = this.getPanes();
    panes.overlayMouseTarget.appendChild(div);
  }

  // Position the overlay
  var point = this.getProjection().fromLatLngToDivPixel(this.latlng_);
  if (point) {
    div.style.left = point.x + 'px';
    div.style.top = point.y + 'px';
    const currentPos = this.hashmap[`${point.x}-${point.y}`];
    if (!currentPos) {
      this.hashmap[`${point.x}-${point.y}`] = 1;
      div.style.boxShadow = '0 0.8rem 1.6rem rgb(0 0 0 / 12%)';
    }
  }
};

CustomMarker.prototype.onRemove = function () {
  // Check if the overlay was on the map and needs to be removed.
  if (this.div_) {
    this.div_.parentNode.removeChild(this.div_);
    this.div_ = null;
  }
};
