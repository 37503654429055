<div class="calendar grow {{open ? 'visible' : '' }}">
	<div *ngIf="state === ''">
    <div class="calendar-body" *ngIf="alignMode === 'slide'">
      <div appRipple class="action" (click)="changeMonth(-1)">
        <img src="../../../../assets/images/primary_arrow_back.svg" alt="arrow-back" />
      </div>

      <div class="calendar-content">
        <div>
          <div class="date">
            <span appRipple class="month" data-tooltip="Change Month" (click)="state = 'select-month'">{{ activeDate
              |
              moment: 'MMMM' }}</span>
            <span appRipple class="year" data-tooltip="Change Year" (click)="state = 'select-year'">{{ activeDate |
              moment: 'YYYY' }}</span>
          </div>
          <table cellspacing="0" cellpadding="0" [class.pe-none]="clickDisable">
            <thead>
            <tr>
              <th *ngFor="let day of dayNames">{{ day }}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let week of calendar">
              <td *ngFor="let day of week.days"
                  class="{{ day.disabled ? 'disabled' : '' }}  {{ day.currentMonth ? 'current-month' : '' }} {{ day.format === startDate ? 'first' : '' }} {{ day.format === endDate ? 'last' : '' }}">

                <!--						<div class="background-select" *ngIf="day.isSelected"></div>-->
                <!--						<div class="background-unavailable" *ngIf="day.unavailable"></div>-->
                <!--						<div class="background-unavailable" *ngIf="day.disabled"></div>-->

                <div
                  appRipple
                  (click)="onSelect(day)"
                  [ngClass]="{
                'date': true,
                'unavailable': day.unavailable,
                'selected': day.isSelected,
                'disabled': day.disabled,
                'range': mode === 'range'
              }">
                  {{ day.number }}
                </div>

              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <div>
          <div class="date">
           <span appRipple class="month" data-tooltip="Change Month" (click)="state = 'select-month'">{{ getNextMonthDate()
             |
             moment: 'MMMM' }}</span>
            {{' '}}
            <span appRipple class="year" data-tooltip="Change Year" (click)="state = 'select-year'">{{ getNextMonthDate() |
              moment: 'YYYY' }}</span>
          </div>
          <table cellspacing="0" cellpadding="0" [class.pe-none]="clickDisable">
            <thead>
            <tr>
              <th *ngFor="let day of dayNames">{{ day }}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let week of nextCalendar">
              <td *ngFor="let day of week.days"
                  class="{{ day.disabled ? 'disabled' : '' }}  {{ day.currentMonth ? 'current-month' : '' }} {{ day.format === startDate ? 'first' : '' }} {{ day.format === endDate ? 'last' : '' }}">

                <!--						<div class="background-select" *ngIf="day.isSelected"></div>-->
                <!--						<div class="background-unavailable" *ngIf="day.unavailable"></div>-->
                <!--						<div class="background-unavailable" *ngIf="day.disabled"></div>-->

                <div
                  appRipple
                  (click)="onSelect(day)"
                  [ngClass]="{
                'date': true,
                'unavailable': day.unavailable,
                'selected': day.isSelected,
                'disabled': day.disabled,
                'range': mode === 'range'
              }">
                  {{ day.number }}
                </div>

              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div appRipple class="action" (click)="changeMonth(1)">
        <img src="../../../../assets/images/primary_arrow_forward.svg" alt="arrow-forward" />
      </div>
    </div>

    <div *ngIf="alignMode === 'list'">
      <div  *ngFor="let month of calendarGroup">
        <p class="list-calendar-title">
          {{ month?.[0]?.title }}
        </p>
        <table cellspacing="0" cellpadding="0" [class.pe-none]="clickDisable">
          <thead>
          <tr>
            <th *ngFor="let day of dayNames">{{ day }}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let week of month">
            <td *ngFor="let day of week.days"
                class="{{ day.disabled ? 'disabled' : '' }}  {{ day.currentMonth ? 'current-month' : '' }} {{ day.format === startDate ? 'first' : '' }} {{ day.format === endDate ? 'last' : '' }}">
              <div
                appRipple
                (click)="onSelect(day)"
                [ngClass]="{
                'date': true,
                'unavailable': day.unavailable,
                'selected': day.isSelected,
                'disabled': day.disabled,
                'range': mode === 'range'
              }">
                {{ day.number }}
              </div>

            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
	</div>

	<div *ngIf="state === 'select-year'" class="uk-grid uk-grid-collapse years">
		<div class="uk-width-1-1">
			<div (click)="state = ''">
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
					stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
					class="ai ai-ArrowLeft">
					<path d="M11 5l-7 7 7 7" />
					<path d="M4 12h16" />
				</svg>
			</div>
		</div>
		<div class="uk-width-1-4" *ngFor="let year of years">
			<div (click)="setYear(year)">{{ year }}</div>
		</div>
	</div>


	<div *ngIf="state === 'select-month'" class="uk-grid uk-grid-collapse years">
		<div class="uk-width-1-1">
			<div (click)="state = ''">
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
					stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
					class="ai ai-ArrowLeft">
					<path d="M11 5l-7 7 7 7" />
					<path d="M4 12h16" />
				</svg>
			</div>
		</div>

		<div class="uk-width-1-4" *ngFor="let month of months; let i = index">
			<div (click)="setMonth(i)">{{ month }} </div>
		</div>
	</div>
</div>
