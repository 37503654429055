import { Injectable } from "@angular/core";
import Pusher from "pusher-js";
import { environment } from "src/environments/environment";
import { HttpService } from "./http.service";
@Injectable({
	providedIn: "root"
})
export class PusherService {
	public pusher: Pusher;

	constructor(private httpService: HttpService) {
		if (!environment.production) {
			Pusher.logToConsole = true;
		}

		this.pusher = new Pusher(environment.pusher.app_key, {
			cluster: environment.pusher.cluster,
			authEndpoint: `${environment.pusher.auth_endpoint}`,
			auth: {
				headers: {
					ApiVersion: environment.api.version,
					Authorization: `Bearer ${this.httpService.ACCESS_TOKEN}`,
					Accept: "application/json"
				}
			}
		});

	}

}
