
<div class="background_wrapper">
    <div class="logo_header">
        <div class="intro_brand">
            <img src="/assets/images/onboard/logo-black.svg">
            <!-- <span class="brand_title">Holiday Swap</span> -->
        </div>
        <div class="pointer" (click)="handleSkip()">
            <img src="../../../../../assets/images/onboard/close.svg" alt="">
        </div>
    </div>
    <div class="app_container">
        <div class="content_area">
            <div class="page-title">
                <p>{{"Nearly there!" | translate}}</p>
            </div>
            <div class="page-desc">
                <p>
                    {{"Let us know which places you are most keen to go and explore! We’ll find places for you to stay during your adventure." | translate}}
                </p>
            </div>
        </div>
        <div class="trending_region_wrapper">
            <div class="bix-row trends_region_row" *ngIf="featuredLocations.length > 0">
                <ng-container *ngFor="let space of featuredLocations; let i = index">
                    <div class="col-6 col-sm-4">
                        <div class="trending_widget_item pointer" [class.arabic]="selectedLanguage == 'ar'" >
                            <input class="check-box" [(ngModel)]="space.checked" type="checkbox" id="{{space?.name}}" hidden>
                            <label for="{{space?.name}}" class="pointer"  (click)="searchResults($event, space)">
                                <div class="trending_image" >
                                    <!-- <img src="../../../../../assets/images/6.jpg"> -->
                                    <img class="lazyload" [lazyLoad]="space.image_url" defaultImage="/assets/images/default-cover.jpg" />
                                    <!-- <img  [src]="space.image_url" (error)="onImgError($event)" /> -->
                                </div>
                            </label>
                            <div class="trending_cont">
                                <span class="trending_title">{{space?.name}}</span>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="skip_footer">
            <button type="button" class="transparant" (click)="handleSkip()">{{"Skip" | translate}}</button>
            <button type="button" class="transparant" (click)="handleRegions()">{{"Continue" | translate}}</button>
        </div>
    </div>
</div>
   
   <app-loading [absolute]="true" [loading]="loading"></app-loading>