import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ILoginRequest } from 'src/app/shared/interfaces/auth';
import { AuthService } from 'src/app/shared/services/auth.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/shared/services/user.service';
import clevertap from 'clevertap-web-sdk';
import { ClevertapService } from 'src/app/shared/services/clevertap.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { v4 as uuidv4 } from 'uuid';
import { json } from '@rxweb/reactive-form-validators';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  public user: ILoginRequest = {
    username: '',
    password: '',
  };
  private loginuuid = '';
  public error: string;
  public confirmed: boolean = false;
  public loading = false;
  public userData: any;
  public redirect_to: string;
  public fragment: string;
  public queryParams;

  submitted: boolean = false;

  public emailBlank = true;
  public emailpassword = true;
  public hidePass = true;
  // private clevertapId: string = "";
  constructor(
    private authService: AuthService,
    private httpService: HttpService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private clevertapService: ClevertapService,
    private languageService: LanguageService,
    private snackbarService: SnackbarService,
    private googleAnalyticsService: GoogleAnalyticsService,
    public translate: TranslateService
  ) {
    if (localStorage.getItem('redirect_to')) {
      this.redirect_to = localStorage.getItem('redirect_to');
    }
    if (localStorage.getItem('fragment')) {
      this.fragment = localStorage.getItem('fragment');
    }
    if (localStorage.getItem('queryParams')) {
      this.queryParams = localStorage.getItem('queryParams');
    }
    let redirectPath = this.route.snapshot.queryParams.redirect_to;
    if (redirectPath) {
      this.redirect_to = redirectPath;
      localStorage.setItem('redirect_to', redirectPath);
      localStorage.setItem('fragment', this.fragment);
      localStorage.setItem('queryParams', this.queryParams);
    }
    if (localStorage.getItem('lang') == null) {
      this.languageService.SetLanguage('en');
    }
  }

  ngOnInit(): void {
    if (this.authService.validate()) {
      this.router.navigate(['/'], {
        queryParams: this.route.snapshot.queryParams,
        queryParamsHandling: 'merge',
      });
      return;
    }
    const queryParams = {
      openLoginModal: true,
      ...this.route.snapshot.queryParams,
    };

    this.route.queryParams.subscribe((params) => {
      if (params.email) {
        this.user.username = params.email;
      }
      if (params.confirmed) {
        this.confirmed = params.confirmed;
      }

      if (params.error) {
        this.snackbarService.show({
          message: this.translate.instant(params.error),
          type: 'success',
          config: {
            class: 'centered',
          },
        });
      }
    });

    if (localStorage.getItem('lang') == null) {
      this.languageService.SetLanguage('en');
    }
    if (localStorage.getItem('uuid') === 'null') {
      this.loginuuid = uuidv4();
      localStorage.setItem('uuid', this.loginuuid);
    }

    this.router.navigate(['/'], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  public onLogin() {
    this.submitted = true;

    this.userService
      .postAnalytics(this.router.url, 'login_clicked')
      .subscribe((res) => {});

    this.error = '';

    if (this.user.username && this.user.password) {
      this.loading = true;

      this.authService
        .login({
          ...this.user,
          grant_type: 'password',
          scope: '*',
        })
        .subscribe(
          (response: any) => {
            this.loading = false;
            this.getUserData(response);
            localStorage.setItem('sociallyLoggedin', '0');
            // this.clevertapId = clevertap.getCleverTapID();

            // this.router.navigate([{ outlets: { modal: ["modal", "settings", 'token-details'] } }]);
            // this.router.navigate([!response.onboarding ? "/setup" : "/dashboard"]);

            let fragment = localStorage.getItem('fragment');
            let queryParams = null;
            if (
              localStorage.getItem('queryParams') != 'undefined' &&
              typeof localStorage.getItem('queryParams') != undefined
            ) {
              queryParams = JSON.parse(localStorage.getItem('queryParams'));
            }
            if (this.redirect_to != null && this.redirect_to != 'undefined') {
              if (fragment != 'undefined' && fragment != 'null') {
                this.router.navigate([this.redirect_to], {
                  fragment: fragment,
                  queryParams: this.route.snapshot.queryParams,
                  queryParamsHandling: 'merge',
                });
              } else {
                this.router.navigate([this.redirect_to], {
                  queryParams: this.route.snapshot.queryParams,
                  queryParamsHandling: 'merge',
                });
              }
              // if(this.redirect_to.includes("promo") ) {
              // }
              // else {
              // 	this.router.navigate(["/"]);
              // }
            } else {
              this.router.navigate(['/'], {
                queryParams: this.route.snapshot.queryParams,
                queryParamsHandling: 'merge',
              });
            }
          },
          (err) => {
            this.loading = false;
            // this.error = err;
            if (err == 'Please verify your email id first and try login') {
              localStorage.setItem('signUpRes', JSON.stringify(this.user));
              localStorage.setItem('verifyLocalMin', JSON.stringify(20));
              localStorage.setItem('verifyLocalSec', JSON.stringify(0));
              localStorage.setItem('redirectFrom', 'login');
              this.router.navigate(['/auth/verify-account'], {
                queryParams: this.route.snapshot.queryParams,
                queryParamsHandling: 'merge',
              });
            } else if (err == 'Your email address is not verified.') {
              localStorage.setItem('signUpRes', JSON.stringify(this.user));
            } else {
              this.snackbarService.show({
                message: this.translate.instant(err),
                type: 'success',
                config: {
                  class: 'centered',
                },
              });

              // this.emailBlank = err;
              // this.emailpassword  = err;
            }
          }
        );
    }

    if (
      (this.user?.password).trim() == '' &&
      (this.user?.username).trim() == ''
    ) {
      // this.snackbarService.show({
      // 	message: "Please enter password",
      // 	type: 'success',
      // 	config: {
      // 		class : 'centered'
      // 	}
      // });
      this.emailpassword = false;
      this.emailBlank = false;
      return;
    }

    if ((this.user?.username).trim() == '') {
      this.emailBlank = false;
      return;
    }

    if ((this.user?.password).trim() == '') {
      this.emailpassword = false;
      return;
    }

    // if(this.clevertapService.isWebView){

    // }
    // else{
    // 	this
    // 	.googleAnalyticsService
    // 	.eventEmitter("congrats_signup_web", "Clicks", "/congrats-signup");
    // }
  }

  getUserData(responseLoginData) {
    this.userService
      .getProfile(responseLoginData.user_id)
      .subscribe(
        (response: any) => {
          if (Object.keys(response.data).length > 0) {
            this.userData = response.data;
            this.clevertapService.setClevertap_id();
            this.clevertapService.userProfileLogin(
              this.userData,
              this.userData.is_notification_on == 1 ? true : false
            );
            setTimeout(() => {
              this.clevertapService.updateClevertapId(
                this.clevertapService.getClevertap_id()
              );
            }, 4000);
          }
        },
        (err) => {}
      )
      .add(() => {
        this.loading = false;
      });
  }

  toggleHidePassword() {
    this.hidePass = !this.hidePass;
  }

  handleSignUp() {
    this.googleAnalyticsService.eventEmitter(
      'SU_LoginToEmail',
      'SignUp',
      'SU_EmailFromLogin'
    );
    if (this.route.snapshot.queryParams) {
      this.router.navigate(['/auth/signup'], {
        queryParams: this.route.snapshot.queryParams,
        queryParamsHandling: 'merge',
      });
    } else {
      this.router.navigate(['/auth/signup']);
    }
  }

  handlePassword() {
    if (this.user.password.trim() != '') {
      this.emailpassword = true;
    }
  }

  handleEmail() {
    if (this.user.username.trim() != '') {
      this.emailBlank = true;
    }
  }
}
