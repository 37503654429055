import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { LanguageService } from 'src/app/shared/services/language.service';

@Component({
  selector: 'app-add-space-landing',
  templateUrl: './add-space-landing.component.html',
  styleUrls: ['./add-space-landing.component.scss']
})
export class AddSpaceLandingComponent implements OnInit {

  selectedLanguage = "en";
  isDesktopView: boolean = (window.screen.width > 1024);
	@HostListener('window:resize', ['$event'])
	handleResize(event: KeyboardEvent) {
		this.isDesktopView = (window.screen.width > 1024);
	}

  constructor(private router: Router, private languageService: LanguageService) { }


  ngOnInit(): void {
    localStorage.removeItem("spaceDetails");

    let loadLang = this.languageService.GetLanguage() + '';

    if (loadLang == "null" || loadLang == "") {
      this.selectedLanguage = "en";
    }
    else {
      this.selectedLanguage = loadLang;
    }
  }

  public goToAddSpace() {
    if(this.isDesktopView)
      this.router.navigate(["/spaces/add-home"]);
    else
      this.router.navigate([{ outlets: { modal: ["modal", "spaces", 'add-home'] } }]);
  }

  public pop() {
    this.router.navigate(['me/my-homes']).then(() => this.router.navigate([{ outlets: { modal: null } }]));
  }

}
