<div class="back-arrow" *ngIf="!isDesktopView">
  <!--    <app-back-button-common> </app-back-button-common>-->
  <span class="icon_img" (click)="handleBackByClosing()">
    <img src="assets/images/icons/x-close-icon.svg" alt="x-close" />
  </span>
</div>

<div
  class="content_area"
  id="search_area"
  [ngClass]="isInHeader ? 'content_area_map-view-page' : ''"
>
  <!-- content header strt -->
  <div class="content_header">
    <h2 class="text-center" *ngIf="!isDesktopView && !isInHeader">
      {{
        focusedElement
          ? TITLE[focusedElement]
          : ("Find your next stay" | translate)
      }}
    </h2>

    <div class="desktopFilter">
      <form class="search-homes-form" (submit)="searchViewList()">
        <div class="location-search-input">
          <div class="input-group has_icon">
            <input
              ngx-google-places-autocomplete
              id="input-location"
              class="form-control search_input_field"
              [ngClass]="isInHeader && 'height-46-px'"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="location.full_address"
              (onAddressChange)="autoCompleteAddress($event)"
              placeholder="{{ 'Search for places to stay' | translate }}"
              [options]="inputoptions"
              autocorrect="off"
              (focus)="
                handleFocusingOnElement(FormElementsEnum.Location);
                handleFocusOnLocation(true)
              "
              (blur)="
                handleFocusingOnElement(null); handleFocusOnLocation(false)
              "
              (keydown.enter)="autoCompleteAddressByEnter()"
            />
            <span
              class="icon_img"
              (click)="
                handleFocusingOnElement(FormElementsEnum.Location);
                handleFocusOnLocation(true);
                handleFocusOnInputLocation()
              "
              ><img
                src="assets/images/icons/location-marker-secondary.svg"
                alt=""
            /></span>
          </div>
          <div
            class="results"
            [class.visible]="locations.length > 0"
            [hidden]="resultHide"
          >
            <ul class="mb-0">
              <li
                *ngFor="let location of locations"
                (click)="onSelect(location, true)"
              >
                {{ location.full_address }}
              </li>
            </ul>
            <ul
              class="mb-0"
              *ngIf="locations.length <= 0 && historyLocations.length > 0"
            >
              <li class="titleTxt">{{ "Recent searches" | translate }}</li>
              <li
                *ngFor="let location of historyLocations"
                (click)="onSelect(location, false)"
              >
                {{ location.full_address }}
              </li>
            </ul>
          </div>
        </div>
        <div class="date-input">
          <div class="calender_wrap"  #calendarWrapper>
            <!-- <label for="" class="label_txt">{{'When?' | translate}}</label> -->
            <div class="input-group has_icon" (click)="showCalenderFun()">
              <input
                type="text"
                class="form-control search_input_field text-overflow"
                [ngClass]="isInHeader && 'height-46-px'"
                [placeholder]="'Select dates' | translate"
                readonly
                name=""
                autocorrect="off"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="searchDate"
                (focus)="handleFocusingOnElement(FormElementsEnum.Dates)"
                (keydown.enter)="searchViewList()"
              />
              <span class="icon_img calender"
                ><img src="assets/images/icons/calendar-secondary.svg" alt=""
              /></span>
            </div>
            <div
              class="calender_dropBox"
              id="calenderDropBox"
              [hidden]="!showCalender"
            >
              <h3 class="calender_dropBox_title">Choose Dates:</h3>
              <app-calendar
                #appCalendar
                (dateChange)="onDateSelected($event)"
                [alignMode]="'slide'"
                mode="range"
                [startDate]="filter.started_at"
                [endDate]="filter.ended_at"
                [destroy]="false"
                [min]="minDate"
                [max]="maxDate"
              >
              </app-calendar>

              <div class="calenderFooter">
                <button
                  *ngIf="!tempDate?.end || !tempDate?.start"
                  type="button"
                  class="btn flexible_btn"
                  (click)="imFlex()"
                >
                  {{ "I'm Flexible" | translate }}
                </button>
                <button
                  *ngIf="tempDate?.end && tempDate?.start"
                  type="button"
                  class="btn flexible_btn"
                  (click)="clearCalendar()"
                >
                  {{ "Clear" | translate }}
                </button>
                <button
                  type="button"
                  class="calBtn selectBtn"
                  (click)="updateDate()"
                >
                  {{ "Select" | translate }}
                </button>
              </div>
            </div>

            <div class="Flexible_dropBox" [hidden]="!showFlexiablePlan">
              <app-flexible-dates
                #flexibleDates
                (searchViewListEmit)="closeClender()"
                (onFlexibleSelectEmit)="onFlexibleSelect($event)"
                (onClearEmit)="clearEventEmit()"
                [selectedDays]="selectedDays"
                [selectedMonths]="selectedMonths"
                (onCloseEmit)="closeFlex()"
              ></app-flexible-dates>
            </div>
          </div>
        </div>
        <div class="guest-input">
          <div
            class="input-group has_icon guest-input-control"
            #bottomSheetAnchorRef
            (click)="openGuestSheet()"
          >
            <input
              type="text"
              class="form-control search_input_field text-overflow"
              [ngClass]="isInHeader && 'height-46-px'"
              [placeholder]="'Guests' | translate"
              readonly
              [value]="
                filter.guestNumber ? filter.guestNumber + ' Guests' : 'Guests'
              "
              autocorrect="off"
              (focus)="handleFocusingOnElement(FormElementsEnum.Guests)"
              (keydown.enter)="searchViewList()"
            />
            <span class="icon_img">
              <img
                src="assets/images/icons/user-secondary.svg"
                alt="user-secondary"
              />
            </span>
          </div>
        </div>
        <div>
          <button
            [ngClass]="isInHeader && 'searchBtn-map-view-page'"
            type="submit"
            class="searchBtn"
          >
            {{ !isInHeader ? ("Search" | translate) : "" }}
            <img src="assets/icons/Search_white.svg" alt="search" />
          </button>
        </div>
      </form>
    </div>
  </div>
  <!-- content header end -->
</div>
<!-- </div> -->

<div *ngIf="!showCalender && !showFlexiablePlan" class="bottom_action">
  <button class="clear_button" (click)="clearFilter()">Clear</button>

  <button class="search_button" (click)="searchViewList()">
    {{ "Search" | translate }}
  </button>
</div>

<div #bottomSheetRef>
  <div
    *ngIf="showGuestBottomSheet"
    class="guest_bottom_sheet"
    #guestBottomSheet
  >
    <h2>How many guests?</h2>
    <div class="guest_bottom_sheet_item">
      <div>
        <p class="guest_type">Adults</p>
        <p class="guest_type_description">12+ years</p>
      </div>
      <div class="guest_item_action">
        <button
          type="button"
          title="btn"
          class="guest-control-btn"
          (click)="handleChangeGuests('adults', -1)"
        >
          <img src="assets/images/icons/minus-icon.svg" alt="" />
        </button>
        <span [class.smallText]="!guests.adults">{{ guests.adults }}</span>
        <button
          type="button"
          title="btn"
          class="guest-control-btn"
          (click)="handleChangeGuests('adults', 1)"
        >
          <img src="assets/images/icons/plus-icon.svg" alt="" />
        </button>
      </div>
    </div>
    <div class="guest_bottom_sheet_item">
      <div>
        <p class="guest_type">Children</p>
        <p class="guest_type_description">2 - 11 years</p>
      </div>
      <div class="guest_item_action">
        <button
          type="button"
          title="btn"
          class="guest-control-btn"
          (click)="handleChangeGuests('children', -1)"
        >
          <img src="assets/images/icons/minus-icon.svg" alt="" />
        </button>
        <span [class.smallText]="!guests.children">{{ guests.children }}</span>
        <button
          type="button"
          title="btn"
          class="guest-control-btn"
          (click)="handleChangeGuests('children', 1)"
        >
          <img src="assets/images/icons/plus-icon.svg" alt="" />
        </button>
      </div>
    </div>
    <div class="guest_bottom_sheet_item">
      <div>
        <p class="guest_type">Infants</p>
        <p class="guest_type_description">Under 2 years</p>
      </div>
      <div class="guest_item_action">
        <button
          type="button"
          title="btn"
          class="guest-control-btn"
          (click)="handleChangeGuests('infants', -1)"
        >
          <img src="assets/images/icons/minus-icon.svg" alt="" />
        </button>
        <span [class.smallText]="!guests.infants">{{ guests.infants }}</span>
        <button
          type="button"
          title="btn"
          class="guest-control-btn"
          (click)="handleChangeGuests('infants', 1)"
        >
          <img src="assets/images/icons/plus-icon.svg" alt="" />
        </button>
      </div>
    </div>
    <div class="guest_bottom_sheet_item">
      <div>
        <p class="guest_type">Pets</p>
        <p class="guest_type_description">
          Dogs, Cats, small non-dangerous animals
        </p>
      </div>
      <div class="guest_item_action">
        <button
          type="button"
          title="btn"
          class="guest-control-btn"
          (click)="handleChangeGuests('pets', -1)"
        >
          <img src="assets/images/icons/minus-icon.svg" alt="" />
        </button>
        <span [class.smallText]="!guests.pets">{{ guests.pets }}</span>
        <button
          type="button"
          title="btn"
          class="guest-control-btn"
          (click)="handleChangeGuests('pets', 1)"
        >
          <img src="assets/images/icons/plus-icon.svg" alt="" />
        </button>
      </div>
    </div>
  </div>
</div>
