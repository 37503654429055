import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from 'src/app/shared/services/language.service';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { UserService } from 'src/app/shared/services/user.service';
import { v4 as uuidv4 } from 'uuid';
import { ClevertapService } from 'src/app/shared/services/clevertap.service';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { query } from '@angular/animations';
import { AuthService } from 'src/app/shared/services/auth.service';
declare var $: any;

@Component({
  selector: 'app-onboarding-new-intro',
  templateUrl: './onboarding-new-intro.component.html',
  styleUrls: ['./onboarding-new-intro.component.scss'],
})
export class OnboardingNewIntroComponent implements OnInit {
  public activeIndex: number = 0;
  public selectedLanguage = 'en';
  private onboardingid = '';
  public config: SwiperOptions = {
    virtual: false,
    effect: 'slide',
    // slideEffect: {
    //   crossFade: true
    // },
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    // pagination : true ,
    pagination: {
      clickable: true,
      // dynamicBullets: true
    },
    speed: 1000,
    loop: true,
    spaceBetween: 0,
  };
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private languageService: LanguageService,
    private cd: ChangeDetectorRef,
    private clevertapService: ClevertapService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private userService: UserService,
    private authService: AuthService
  ) {
    localStorage.setItem(
      'redirect_to',
      this.route.snapshot.queryParams.redirect_to
    );
    localStorage.setItem('fragment', this.route.snapshot.queryParams.fragment);
    localStorage.setItem(
      'queryParams',
      JSON.stringify(this.route.snapshot.queryParams)
    );
    localStorage.setItem(
      'invite_code',
      this.route.snapshot.queryParams?.invite_code
    );
    localStorage.setItem('token', this.route.snapshot.queryParams.token);

    if (this.route.snapshot.queryParams.token) {
      let spaceId = this.route.snapshot.params.id;
      localStorage.setItem('token', this.route.snapshot.queryParams.token);
      setTimeout(() => {
        if (this.authService.validate(this.route.snapshot.queryParams.token)) {
          localStorage.setItem('token', this.route.snapshot.queryParams.token);
          this.router.navigate([this.route.snapshot.queryParams.redirect_to], {
            queryParams: {
              from: this.route.snapshot?.queryParams?.from,
              to: this.route.snapshot?.queryParams?.to,
              guest: this.route.snapshot?.queryParams?.guest,
            },
          });
        }
      }, 2000);
    }
  }

  ngOnInit(): void {
    if (localStorage.getItem('uuid') === 'null') {
      this.onboardingid = uuidv4();
      localStorage.setItem('uuid', this.onboardingid);
    }
    let loadLang = this.languageService.GetLanguage() + '';

    if (loadLang == 'null' || loadLang == '') {
      this.selectedLanguage = 'en';
    } else {
      this.selectedLanguage = loadLang;
    }

    this.languageService.languageUpdates.subscribe((lang) => {
      this.selectedLanguage = lang;
    });

    this.userService.postAnalytics(this.router.url, '');

    this.googleAnalyticsService.eventEmitter(
      'SU_Intro',
      'SignUp',
      'SU_Intro_App'
    );

    localStorage.setItem(
      'redirect_to',
      this.route.snapshot.queryParams.redirect_to
    );
    const queryParams = {
      openLoginModal: true,
      ...this.route.snapshot.queryParams,
    };
    this.router.navigate(['/'], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  slideChange(swiper) {
    if (typeof swiper?.realIndex != 'undefined')
      this.activeIndex = swiper?.realIndex;
    this.cd.detectChanges();
  }

  onSwiper(swiper) {
    this.activeIndex = swiper?.realIndex;
  }

  handleSignUp(route = '', cardnumber) {
    switch (route) {
      case 'listHome':
        localStorage.setItem('signUpRoute', 'listHome');
        if (cardnumber == 1) {
          this.googleAnalyticsService.eventEmitter(
            'SU_IntroToEmail',
            'SignUp',
            'SU_Intro_Card1'
          );
        } else {
          this.googleAnalyticsService.eventEmitter(
            'SU_IntroToEmail',
            'SignUp',
            'SU_Intro_Card2'
          );
        }
        break;
      case 'tryPremium':
        localStorage.setItem('signUpRoute', 'tryPremium');
        this.googleAnalyticsService.eventEmitter(
          'SU_IntroToEmail',
          'SignUp',
          'SU_Intro_Card3'
        );
        break;
      default:
        localStorage.setItem('signUpRoute', null);
        break;
    }

    if (this.route.snapshot.queryParams) {
      this.router.navigate(['auth', 'signup'], {
        queryParams: this.route.snapshot.queryParams,
        queryParamsHandling: 'merge',
      });
    } else {
      this.router.navigate(['auth', 'signup']);
    }
  }

  handleSignIn() {
    if (this.route.snapshot.queryParams) {
      this.router.navigate(['auth', 'login'], {
        queryParams: this.route.snapshot.queryParams,
        queryParamsHandling: 'merge',
      });
    } else {
      this.router.navigate(['auth', 'login']);
    }
  }
}
