import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";

@Injectable({
	providedIn: "root"
})
export class DeepLinkGuard implements CanActivate {
    // testingMode = true;

	constructor() { }
	canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.checkLink();
	}
    checkLink(){
        setTimeout(() => {
            document.location.href = 'https://onelink.to/holidayswapapp';
        }, 3000);
        return false;
    }
}
