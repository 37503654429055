import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import { environment } from "src/environments/environment";

import clevertap from 'clevertap-web-sdk';
import { LanguageService } from './language.service';
import { MessagingService } from 'src/app/core/services/messaging.service';
import { UserService } from './user.service';
import { HttpClient } from '@angular/common/http';
import { HttpService } from './http.service';
import { IfStmt } from '@angular/compiler';
import { debounceTime } from 'rxjs/operators';

declare global {
  interface Window {
    flutter_inappwebview: any
  }
}

@Injectable({
  providedIn: 'root'
})
export class ClevertapService {

  public deviceInfo = null;
  public baseUrl = environment.base;
  public userInfo: any;
  private clevertap_id: string;
  private device_clevertap_id: string;

  public isWebView: boolean = false;

  constructor(
    private languageService: LanguageService,
    private deviceService: DeviceDetectorService,
    private messagingService: MessagingService,
    private userService: UserService,
    private httpClient: HttpClient,
    private httpService: HttpService) {
    this.getDeviceInfo();
    this.userService.currentUserSubject.pipe(
      debounceTime(1000)
    ).subscribe((response:any) =>{
      if(!response?.email)return;
      this.createUserProfile(response, response.date_of_birth, response.is_notification_on ?true:false )
    })
  }

  public initializeClevertap() {

    clevertap.privacy.push({ optOut: false }); // Set the flag to true, if the user of the device opts out of sharing their data
    clevertap.privacy.push({ useIP: false });  // Set the flag to true, if the user agrees to share their IP data
    clevertap.init(environment.clever_tap_id, '', ''); // Replace with values applicable to you. Refer below

    clevertap.spa = true;
  }

  public profileEvent(eventName: string) {
    let userDataStorage = localStorage.getItem("userData");
    let userData = JSON.parse(userDataStorage);

    if (userData) {
      clevertap.event.push(eventName, {
        "signed_in": "TRUE",
        "user_id": userData.id,
        "user_name": userData.user_name,
        "birth_date": userData.date_of_birth,
        "email": userData.email,
        "phone_number": "",

        "app_build": 1,
        "app_package": this.baseUrl,
        "device_name": this.deviceInfo.deviceType,
        "device_os": this.deviceInfo.os,
        "device_platform": this.deviceInfo.os_version,
        "timestamp": new Date()
      });
    }
  }

  public anonymousProfileEvent(eventName: string) {

    clevertap.event.push(eventName, {
      "signed_in": "FALSE",
      "user_id": localStorage.getItem("your_temp_id"),

      "device_name": this.deviceInfo.deviceType,
      "device_os": this.deviceInfo.os,
      "device_platform": this.deviceInfo.os_version,
      "timestamp": new Date()
    });
  }

  public anonymousProfile() {
    if (!localStorage.getItem("your_temp_id") || localStorage.getItem("your_temp_id") == "" || localStorage.getItem("your_temp_id") == "null") {
      let randomIndentityNumber = (Math.floor(Math.random() * 150000) + 1);
      let randomUniq = Math.random().toString(36).slice(2);
      let uniqIdentifier = (randomIndentityNumber + randomUniq + Date.now());


      localStorage.setItem("your_temp_id", (uniqIdentifier + ""));

      clevertap.profile.push({
        "Site": {
          "Identity": uniqIdentifier,

          "MSG-push": true,
          "MSG-email": false,
          "MSG-sms": false,
          "MSG-whatsapp": false,
        }
      });

      setTimeout(() => {
        this.anonymousProfileEvent("AnonymousProfiles");
        // this.updateUserProfile();
      }, 2000);
    }
  }

  public taskbarEvent(menuName: string) {
    clevertap.event.push("Taskbar-clicked", {
      "task_name": menuName,
      "app_build": 1,
      "app_package": this.baseUrl,
      "device_name": this.deviceInfo.deviceType,
      "device_os": this.deviceInfo.os,
      "device_platform": this.deviceInfo.os_version,
      "timestamp": new Date(),
      "device_language": this.languageService.GetLanguage()
    });
  }

  public confirmNumberEventOTPVerificationClicked(phoneNumber) {
    this.userInfo = localStorage.getItem("userData");
    this.userInfo = JSON.parse(this.userInfo);


    clevertap.event.push("Confirmed number - OTP verification clicked", {
      "verified": "FALSE",
      "signed_in": "TRUE",
      "user_id": this.userInfo.id,
      "user_name": this.userInfo.user_name,
      "birth_date": this.userInfo.date_of_birth,
      "email": this.userInfo.email,
      "phone_number": phoneNumber,
      "app_build": 1,
      "app_package": this.baseUrl,
      "device_name": this.deviceInfo.deviceType,
      "device_os": this.deviceInfo.os,
      "device_platform": this.deviceInfo.os_version,
      "timestamp": new Date(),
      "device_language": this.languageService.GetLanguage()
    });
  }

  public confirmNumberEventSendAgainVerificationClicked(phoneNumber) {
    this.userInfo = localStorage.getItem("userData");
    this.userInfo = JSON.parse(this.userInfo);

    clevertap.event.push("Confirmed number - send again clicked", {
      "verified": "FALSE",
      "signed_in": "TRUE",
      "user_id": this.userInfo.id,
      "user_name": this.userInfo.user_name,
      "birth_date": this.userInfo.date_of_birth,
      "email": this.userInfo.email,
      "phone_number": phoneNumber,
      "app_build": 1,
      "app_package": this.baseUrl,
      "device_name": this.deviceInfo.deviceType,
      "device_os": this.deviceInfo.os,
      "device_platform": this.deviceInfo.os_version,
      "timestamp": new Date(),
      "device_language": this.languageService.GetLanguage()
    });
  }

  getDeviceInfo() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    // const isMobile = this.deviceService.isMobile();
    // const isTablet = this.deviceService.isTablet();
    // const isDesktopDevice = this.deviceService.isDesktop();

  }

  // public subscribeNotification() {
  //   clevertap.notifications.push({
  //     "apnsWebPushId": "<apple web push id>", //only for safari browser
  //     "apnsWebPushServiceUrl": "<safari package service url>", //only for safari browser
  //     "titleText": 'Would you like to receive discount?',
  //     "bodyText": 'Keep yourself updated with discount, and promotional packages',
  //     "okButtonText": 'Sure',
  //     "rejectButtonText": 'No thanks',
  //     "okButtonColor": '#f28046'
  //   });
  // }

  public createUserProfile(userData, dob, pushStatus = true) {
    let dob_obj = new Date(dob + 'T10:20:30Z');
    let clever_tap_identity = userData.clevertap_identity;
    // localStorage.setItem("your_temp_id", clever_tap_identity);
    this.userService.update({"clevertap_identity": clever_tap_identity});


    let data = {
      "Name": userData.name,
      "Email": userData.email,
      "Phone": "",
      //"Gender": "M",
      "DOB": dob_obj,
      "Identity": clever_tap_identity,
      "Photo": "",
      "MSG-email": true,                 // Disable email notifications
      "MSG-push": pushStatus,                  // Enable push notifications
      "MSG-sms": true,                   // Enable sms notifications
      "MSG-whatsapp": true,              // Enable WhatsApp notifications
    };
    if(this.isWebView) {
      const args = [{data: data}];
      if(window.flutter_inappwebview) {
        window.flutter_inappwebview.callHandler('clevertapWebviewLogin', ...args).then(() => {

        });
      } else {

      }
    } else {
      clevertap.onUserLogin.push({
        "Site": data
      });
    }

  }

  public update_user_push_status(status: boolean, userInfo: any){


    clevertap.profile.push({
      "Site": {
        "Identity": userInfo.clevertap_identity,
        "MSG-push": status
      }
     });
  }


  public userProfileLogin(userData, pushStatus) {

    if(userData?.clevertap_identity) {

      localStorage.setItem("your_temp_id", userData?.clevertap_identity);

      const data = {
        "Name": (userData.first_name + " " + userData.last_name),
        "Identity": userData.clevertap_identity,
        "Email": userData.email,
        "MSG-email": true,
        "MSG-push": pushStatus,
        "MSG-sms": true,
        "MSG-whatsapp": true,
      };

      if(this.isWebView) {

        const args = [{Site: data}];

        if(window.flutter_inappwebview) {
          window.flutter_inappwebview.callHandler('clevertapWebviewLogin', ...args).then((val) => {

            this.device_clevertap_id  = val;
            this.updateClevertapId(val);
          });
        } else {

        }
      } else {
        clevertap.onUserLogin.push({
          "Site": data
        });
      }
    }


    // this.userService.update({
    //   "clevertap_identity": userData.clevertap_identity,
    // }).subscribe((response: { data: any }) => {

    // });
  }

  /*
   * This function is called to initialize clevertap on flutter side when the user is already logged in the app
   */
  public updateClevertapLoginLoggedUser(userData) {

    const data = {
      "Name": (userData.data.first_name + " " + userData.data.last_name),
      "Identity": userData.data.clevertap_identity,
      "Email": userData.data.email,
      "MSG-email": true,
      "MSG-push": (userData.data.is_notification_on == 1) ? true : false,
      "MSG-sms": true,
      "MSG-whatsapp": true,
    };

    const args = [{Site: data}];
    if(window.flutter_inappwebview) {
      window.flutter_inappwebview.callHandler('clevertapWebviewLogin', ...args).then((val) => {
        this.device_clevertap_id  = val;
        this.updateClevertapId(val);
      });
    } else {

    }

  }

  public updateUserProfile() {

    this.userInfo = localStorage.getItem("userData");
    this.userInfo = JSON.parse(this.userInfo);
    this.messagingService.getMyToken();

    clevertap.profile.push({
      "Site": {
        "token": this.messagingService.getMyToken()
      }
    });
  }

  public deleteUserProfile(profileId) {
    let profileIdentity = {
      "identity": (profileId + "")
    };


    this.httpService.post(`/clevertap-delete-profile`, profileIdentity).subscribe((response: any) => {

    });
  }

  public sendNotification(notificationData) {


    if (notificationData.friendId != notificationData.userId){
      this.httpService.post(`/clevertap-notification`, {...notificationData}).subscribe((response: any) => {

      });
    }
  }


  public updateClevertapId(clevertap_id){
    // let myuserInfo = localStorage.getItem("userData");
    // let parsedUserInfo = JSON.parse(myuserInfo);
    let myuser_id = localStorage.getItem("id");





    this.httpService.put(`/update-user-clevertapid`, {"user_id": myuser_id, "clevertap_id": clevertap_id}).subscribe((response: any) => {

    });
  }


  public getNewClevertap_identity(){
    let randomIndentityNumber = (Math.floor(Math.random() * 150000) + 1);
    let randomUniq = Math.random().toString(36).slice(2);
    let user_identity = environment.envPrefix + (randomIndentityNumber + randomUniq + Date.now());
    localStorage.setItem("your_temp_id", user_identity);

    return user_identity;
  }

  public getClevertap_id(){
    if(this.isWebView) {
      return this.device_clevertap_id;
    }

    if(!this.clevertap_id) {
      this.setClevertap_id();
    }
    return this.clevertap_id;
  }

  public setClevertap_id(){
    this.clevertap_id = clevertap.getCleverTapID();
  }
  /**
   *
   * @param action Name of the event to which clevertap triggers
   * @param data Data which is passes to clever tap
   * @param allowMultiple If we want to send an event multiple times nevertheless how many times then pass true
   * @param actionSuffix If we want trigger and event once for a one specfic feature ex: one event for one booking then pass prefix here with id of booking
   */
  public pushCleverTapEvent(action: string, data: any, allowMultiple: boolean = true, actionSuffix: string = "") {


    if(this.userService.completed_action.indexOf(action) < 0 || allowMultiple) {
			this.callCleverTapEvent(action, data);

      if(!allowMultiple) {


        this.userService.completed_action.push(action + actionSuffix);
        let obj = {
          "action": action + actionSuffix,
        };
        this.userService.cleverTapAction(obj).subscribe(res => {})
      }
		}
  }

  callCleverTapEvent(action: string, data: string) {
    if(this.isWebView) {

      const args = [{action: action, data: data}];
      if(window.flutter_inappwebview) {
        window.flutter_inappwebview.callHandler('clevertapPushEvent', ...args).then((val) => {

        });
      }else {

      }
    } else {
      clevertap.event.push(action, data);
    }
  }
}


//  window.addEventListener("myCustomEvent", async (event) => {

//   return event.detail;
// }, false);
