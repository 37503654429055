import { EventEmitter } from '@angular/core';
import { ChangeDetectorRef, Component, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ClevertapService } from 'src/app/shared/services/clevertap.service';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { UserService } from 'src/app/shared/services/user.service';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

@Component({
  selector: 'app-empty-my-homes',
  templateUrl: './empty-my-homes.component.html',
  styleUrls: ['./empty-my-homes.component.scss']
})
export class EmptyMyHomesComponent implements OnInit {
  @Input() public mySpaces = [];
  public active_homes_count = 0;
  @Output() public addHomeClick = new EventEmitter();
  @ViewChild("swiper", { static: false }) swiper?: SwiperComponent;
  public selectedLanguage = "en";
  id = localStorage.getItem("id")

  isDesktopView: boolean = (window.screen.width > 1024);
	@HostListener('window:resize', ['$event'])
	handleResize(event: KeyboardEvent) {
		this.isDesktopView = (window.screen.width > 1024);
	}
  public activeIndex = 0;
  public user: any = [];
  public config: SwiperOptions = {
		direction: "horizontal",
		slidesPerView:1.4,
		spaceBetween: 20,
		keyboard: true,
		mousewheel: true,
		scrollbar: false,
		navigation: false,
		pagination: false,
		virtual: true,
		preloadImages: false,
    // autoHeight: true,

		breakpoints: {
			640: {
				slidesPerView: 3,
			},
			1024: {
				slidesPerView: 3,
			},
			1440: {
				slidesPerView: 4,
			},
			1600: {
				slidesPerView: 4.5,
			},
			1800: {
				slidesPerView: 5.5,
			}
		}
	};

  constructor(
    private cd: ChangeDetectorRef,
    private router : Router,
    private clevertapService : ClevertapService,
    private googleAnalyticsService : GoogleAnalyticsService,
    public languageService : LanguageService,
	private userService: UserService
  ) {
    // this.mySpaces.splice(1, 0, {id : 14444})


    let loadLang = this.languageService.GetLanguage() + '';
		if (loadLang == "null" || loadLang == "") this.selectedLanguage = "en";
		else this.selectedLanguage = loadLang;

		this.languageService.languageUpdates.subscribe(
			(lang) => {
				this.selectedLanguage = lang;
			});

			this.getSpaceCount();
  }

  getSpaceCount() {
    this.userService.getuserSpaceCount().subscribe((res : any) => {
      this.active_homes_count = res.spaces_count;
    })
  }


  ngOnInit(): void {
	this.getMe();
	this.getSpaceCount();
  }

  getMe() {
	this.userService.getMe().subscribe((res: any) => {
		this.user = res.data
	})
  }

  navigateToHome(id) {
    if(!id) return;
    let localdata = localStorage.getItem("navUrl");

		if(localdata != "SearchList"){
			localStorage.setItem("navUrl", "Trendings");
			this.router.navigateByUrl("/view-home/home-details-ibs/"+id);
		}else{
			this.router.navigateByUrl("/view-home/home-details-ibs/"+id);
		}

  }

  onSlideChange() {
    this.activeIndex = this.swiper?.swiperRef.realIndex;
    this.cd.detectChanges();
  }

  editSpace(space) {
    const navigationExtras = { queryParams: { id: space.id } };
    let commands;

    if(this.isDesktopView) {
      commands = ['/spaces/add-home/'+space.id]
    } else {

      commands = [
        {
          outlets: {
            modal: ["modal", "spaces", "add-home", space.id],
          },
        },
      ];

    }


    // localStorage.setItem('home-page-url', JSON.stringify(this.swap));
    this.router.navigate(commands);
  }

  updateSpaceCount(spaceCount) {

    this.active_homes_count = spaceCount;
  }

  public goToSpace() {


  // this.id != 530953 && this.id != 662444 && this.id != 665829
    if(this.active_homes_count >= 30 && this.userService.allowedMaxHomes == '0') {
      this.userService.maxAddHomeReached.next(true)
    }
    else {
      this.addHomeClick.emit();
    }
		this.userService.postAnalytics(this.router.url,"empty_my_home_add_home_clicked").subscribe((res)=>{});

		// Fire the AppsFlyer event
		(window as any).AF('pba', 'event', {eventType: 'EVENT', eventName: 'add_home_button_clicked', eventValue: {
			"screen": "empty_my_homes_section"
		}});

    this
    .googleAnalyticsService
    .eventEmitter("LH_Entry", "ListHome", "LH_Entry_ButtonClick");
	}

  goToTokenPurchase() {
    // if(!this.user?.email_verified) {
    // 	this.userService.verifyMail.next(true);
    // 	return;
    // }
  }

  public update() {
		this.swiper.swiperRef.updateSlides();
		this.swiper.swiperRef.updateSize();

		this.cd.detectChanges();

  }

}
