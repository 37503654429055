<div class="dropdown">
  <div class="dropdown-div" (click)="activateDropdown()">
    <img class="dropbtn" [src]="selecetdLanguageFile" />
    <span class="selectedLng_name" *ngIf="showLanguageName"
      >{{ selectedLanguageName }}
    </span>
    <img class="dropbtn_img" src="/assets/icons/down_arrow.png" />
  </div>

  <div
    class="dropdown-content"
    [ngClass]="{
      'dropdown-content-rightDir': selectedLanguage == 'ar',
      'language-shown': showLanguageName
    }"
    *ngIf="dropdownStatus"
  >
    <a (click)="changeLanguage('en', '/assets/flags/uk_flag.png')">
      <img src="/assets/flags/uk_flag.png" width="20" height="15" /> English</a
    >
    <a
      (click)="changeLanguage('it', '/assets/flags/itly_flag.jpg')"
      *ngIf="url.indexOf('/intro') < 0"
    >
      <img src="/assets/flags/itly_flag.jpg" width="20" height="15" />
      Italian</a
    >
    <a (click)="changeLanguage('es', '/assets/flags/spain_flag.jpg')">
      <img src="/assets/flags/spain_flag.jpg" width="20" height="15" />
      Spanish</a
    >
    <a (click)="changeLanguage('pt', '/assets/flags/portugal_flag.jpg')">
      <img src="/assets/flags/portugal_flag.jpg" width="20" height="15" />
      Portugese</a
    >
    <a
      (click)="changeLanguage('tr', '/assets/flags/turkey_flag.png')"
      *ngIf="url.indexOf('/intro') < 0"
    >
      <img src="/assets/flags/turkey_flag.png" width="20" height="15" />
      Turkish</a
    >
    <a
      (click)="changeLanguage('ar', '/assets/flags/saudia_flag.png')"
      *ngIf="url.indexOf('/intro') < 0"
    >
      <img src="/assets/flags/saudia_flag.png" width="20" height="15" />
      Arabic</a
    >
    <a
      (click)="changeLanguage('zh', '/assets/flags/china_flag.jpg')"
      *ngIf="url.indexOf('/intro') < 0"
    >
      <img src="/assets/flags/china_flag.jpg" width="20" height="15" />
      Chinese</a
    >
  </div>
</div>
