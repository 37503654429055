import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SpaceService } from './space.service';
import { SnackbarService } from './snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { Upload } from '@aws-sdk/lib-storage';
import { S3Client } from '@aws-sdk/client-s3';

const s3Client = new S3Client({
  credentials: {
    accessKeyId: environment.AWS_ACCESS_KEY,
    secretAccessKey: environment.AWS_SECRET_KEY,
  },
  region: environment.AWS_REGION,
});

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  public presigned = '';
  constructor(
    private http: HttpClient,
    private spaceService: SpaceService,
    private snackbarService: SnackbarService,
    public translate: TranslateService
  ) {}

  public async fileUpload(file, fileuploadurl, type) {
    const contentType = type;

    const s3Upload = new Upload({
      client: s3Client,
      params: {
        Bucket: environment.AWS_BUCKET, //'holidayswap-local',
        Key: 'spaces-images/' + fileuploadurl,
        Body: file,
        ContentType: contentType,
      },
    });

    const response = await s3Upload.done();
    response.Location = response.Location.replace(/holidayswap-local.*?amazonaws\.com/g, "assets.holidayswap.com");

    return response;
  }

  public async fileProfileUpload(file, fileuploadurl, type, keyParamUrl) {
    const contentType = file.type;
    const params = {
      Bucket: environment.AWS_BUCKET, //'holidayswap-local',
      Key: keyParamUrl + fileuploadurl,
      Body: file,
      ContentType: contentType,
    };

    const s3Upload = new Upload({
      client: s3Client,
      params: params
    });

    const response = await s3Upload.done();
    response.Location = response.Location.replace(/holidayswap-local.*?amazonaws\.com/g, "assets.holidayswap.com");
    return response;
  }

  public maxFileSizeValidator(
    bytes,
    decimals: number = 2,
    maxSize: number = 2
  ) {
    if (bytes === 0) return true;

    const dm = decimals <= 0 ? 0 : decimals;
    const bytesInMb = (bytes * 0.000001) / 1;

    if (parseFloat(bytesInMb.toFixed(dm)) > maxSize) {
      //   this._alert.error('Error', `Upload file max limit is ${maxSize} MB`);
      this.snackbarService.show({
        message: this.translate.instant(
          `Picture size limit is ${maxSize} MB. Please use a lower-resolution file.`
        ),
        type: 'success',
        config: {
          class: 'centered',
        },
      });
      return false;
    }

    return true;
  }
}
