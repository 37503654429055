<div class="tiplati_wrapper">
    <div class="back-header">
        <span (click)="pop()">
            <svg  width="20" height="15" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M14.666 6.22828C14.666 6.37931 14.6113 6.52523 14.512 6.63905C14.4128 6.75288 14.2756 6.8269 14.126 6.84745L14.041 6.85328L3.05435 6.85328L7.02352 10.8058C7.13622 10.9169 7.20259 11.0666 7.20922 11.2247C7.21585 11.3828 7.16225 11.5376 7.05924 11.6577C6.95624 11.7778 6.81149 11.8544 6.65421 11.872C6.49694 11.8896 6.33885 11.8469 6.21185 11.7524L6.14185 11.6916L1.10018 6.67161C0.988501 6.55994 0.923033 6.41026 0.91685 6.25244L0.916017 6.22828V6.20411C0.916492 6.19187 0.917327 6.17964 0.918517 6.16744L0.916017 6.22828C0.916309 6.09413 0.95953 5.9636 1.03935 5.85578L1.04435 5.84911C1.06128 5.82709 1.07965 5.80621 1.09935 5.78661L6.14102 0.764111C6.25231 0.650222 6.40288 0.583107 6.56198 0.576471C6.72108 0.569835 6.87671 0.624177 6.9971 0.728402C7.11749 0.832628 7.19356 0.978875 7.20977 1.13729C7.22599 1.2957 7.18113 1.45432 7.08435 1.58078L7.02352 1.64994L3.05602 5.60244L14.041 5.60244C14.2068 5.60244 14.3657 5.66829 14.483 5.7855C14.6002 5.90271 14.666 6.06168 14.666 6.22744V6.22828Z"
                    fill="#000000" />
            </svg>
        </span>
    </div>
    <div class="info-container">
        {{"Please note that you have 10 minutes to complete this step.  If you are unable to complete it, you will need to return to re-enter your banking details." | translate}}
    </div>
    <iframe [src]="safeURL" style="width: 100vw; min-height: calc(100vh - 155px); border: none;" ></iframe>
</div>
