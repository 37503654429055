<div
  *ngIf="!loading"
  class="home-detail-main home-detail-main__mobile app_container"
>
  <div class="home-top">
    <!-- property-intro-start -->
    <div class="home-detail-header">
      <div class="explore_left">
        <div (click)="pop()">
          <svg
            width="15"
            height="12"
            viewBox="0 0 15 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.666 6.22828C14.666 6.37931 14.6113 6.52523 14.512 6.63905C14.4128 6.75288 14.2756 6.8269 14.126 6.84745L14.041 6.85328L3.05435 6.85328L7.02352 10.8058C7.13622 10.9169 7.20259 11.0666 7.20922 11.2247C7.21585 11.3828 7.16225 11.5376 7.05924 11.6577C6.95624 11.7778 6.81149 11.8544 6.65421 11.872C6.49694 11.8896 6.33885 11.8469 6.21185 11.7524L6.14185 11.6916L1.10018 6.67161C0.988501 6.55994 0.923033 6.41026 0.91685 6.25244L0.916017 6.22828V6.20411C0.916492 6.19187 0.917327 6.17964 0.918517 6.16744L0.916017 6.22828C0.916309 6.09413 0.95953 5.9636 1.03935 5.85578L1.04435 5.84911C1.06128 5.82709 1.07965 5.80621 1.09935 5.78661L6.14102 0.764111C6.25231 0.650222 6.40288 0.583107 6.56198 0.576471C6.72108 0.569835 6.87671 0.624177 6.9971 0.728402C7.11749 0.832628 7.19356 0.978875 7.20977 1.13729C7.22599 1.2957 7.18113 1.45432 7.08435 1.58078L7.02352 1.64994L3.05602 5.60244L14.041 5.60244C14.2068 5.60244 14.3657 5.66829 14.483 5.7855C14.6002 5.90271 14.666 6.06168 14.666 6.22744V6.22828Z"
              fill="#FF6D2E"
            />
          </svg>
        </div>
      </div>

      <div class="property-info">
        <h2 class="search-home-detail-header">{{ space.title }}</h2>

        <div class="property-info-bottom">
          <div class="property-info-left">
            <div class="location_list">
              <span *ngIf="space?.address?.country || space?.address?.city">
                <svg
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.29404 2.2871L2.29405 2.28708C3.443 1.14308 5.00177 0.5 6.62756 0.5C8.25334 0.5 9.81211 1.14308 10.9611 2.28708C12.1099 3.431 12.755 4.98204 12.755 6.59886C12.755 8.21568 12.1099 9.76672 10.9611 10.9106L6.62756 15.2255L2.29405 10.9106L2.29404 10.9106C1.72512 10.3442 1.27394 9.6719 0.966163 8.9321C0.658384 8.19232 0.5 7.39948 0.5 6.59886C0.5 5.79824 0.658384 5.00541 0.966163 4.26562C1.27394 3.52583 1.72512 2.8535 2.29404 2.2871ZM6.62756 8.98415C7.26164 8.98415 7.87019 8.73337 8.31922 8.28628C8.76831 7.83912 9.02101 7.23218 9.02101 6.59886C9.02101 5.96554 8.76831 5.3586 8.31922 4.91144C7.87019 4.46435 7.26164 4.21357 6.62756 4.21357C5.99347 4.21357 5.38492 4.46435 4.93589 4.91144C4.4868 5.3586 4.2341 5.96554 4.2341 6.59886C4.2341 7.23218 4.4868 7.83912 4.93589 8.28628C5.38492 8.73337 5.99347 8.98415 6.62756 8.98415Z"
                    fill="#23262F"
                    stroke="#23262F"
                  />
                </svg>
                <!-- {{space?.address?.country ? space?.address?.country+',' : ' '}} {{space?.address?.city}} -->
              </span>
              <span class="ml_3 location_address">
                {{
                  space?.address?.country ? space?.address?.country + "," : " "
                }}
                {{ space?.address?.city }}</span
              >
            </div>

            <!--              <div class="rating_item" *ngIf="space?.reviews?.length == null && space?.reviews?.average == 0">-->
            <!--                <img src="/assets/images/star_ic.svg">-->
            <!--                <span class="rating_list">{{space?.moderator_review_rating}}</span>-->
            <!--              </div>-->

            <div class="price-wrapper" *ngIf="space.payment_type_id == 561">
              <span class="home-price"
                >${{ space.hosting_amount | number }}</span
              >
              <span class="home-price-per pl-5" *ngIf="!token">
                {{ "per night" | translate }}</span
              >
            </div>

            <div class="price-wrapper" *ngIf="space.payment_type_id != 561">
              <span class="home-price">{{ space.hosting_tokens }}</span>
              <span class="gray_text pl-5" *ngIf="!token">
                {{ "per night" | translate }}</span
              >
            </div>
          </div>

          <div class="share-btn-container">
            <div class="send_btn">
              <app-social-share
                [homeId]="spaceId"
                [imgUrl]="_albums[selectedCoverImage]?.src"
                [shareMessage]="shareMessage + '\n' + space.deeplink_url"
                [deeplinkUrl]="space.deeplink_url"
              ></app-social-share>
            </div>
            <a
              class="send_btn"
              [ngClass]="{ 'send_btn-override': selectedLanguage == 'ar' }"
              *ngIf="space?.is_favourited"
              (click)="onLike()"
            >
              <i class="fa fa-heart" aria-hidden="true"></i
            ></a>
            <a
              class="send_btn"
              [ngClass]="{ 'send_btn-override': selectedLanguage == 'ar' }"
              *ngIf="!space?.is_favourited"
              (click)="onLike()"
            >
              <i class="fa fa-heart-o" aria-hidden="true"></i
            ></a>
          </div>
        </div>
      </div>
    </div>

    <!-- property-intro-end -->

    <div class="app-grid-image-viewer">
      <div class="image-viewer-explore" (click)="pop()">
        <svg
          width="15"
          height="12"
          viewBox="0 0 15 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.666 6.22828C14.666 6.37931 14.6113 6.52523 14.512 6.63905C14.4128 6.75288 14.2756 6.8269 14.126 6.84745L14.041 6.85328L3.05435 6.85328L7.02352 10.8058C7.13622 10.9169 7.20259 11.0666 7.20922 11.2247C7.21585 11.3828 7.16225 11.5376 7.05924 11.6577C6.95624 11.7778 6.81149 11.8544 6.65421 11.872C6.49694 11.8896 6.33885 11.8469 6.21185 11.7524L6.14185 11.6916L1.10018 6.67161C0.988501 6.55994 0.923033 6.41026 0.91685 6.25244L0.916017 6.22828V6.20411C0.916492 6.19187 0.917327 6.17964 0.918517 6.16744L0.916017 6.22828C0.916309 6.09413 0.95953 5.9636 1.03935 5.85578L1.04435 5.84911C1.06128 5.82709 1.07965 5.80621 1.09935 5.78661L6.14102 0.764111C6.25231 0.650222 6.40288 0.583107 6.56198 0.576471C6.72108 0.569835 6.87671 0.624177 6.9971 0.728402C7.11749 0.832628 7.19356 0.978875 7.20977 1.13729C7.22599 1.2957 7.18113 1.45432 7.08435 1.58078L7.02352 1.64994L3.05602 5.60244L14.041 5.60244C14.2068 5.60244 14.3657 5.66829 14.483 5.7855C14.6002 5.90271 14.666 6.06168 14.666 6.22744V6.22828Z"
            fill="#FF6D2E"
          />
        </svg>
      </div>

      <app-grid-image-viewer [images]="space.images"></app-grid-image-viewer>

      <div class="image-viewer-share-btns">
        <div class="send_btn">
          <app-social-share
            [homeId]="spaceId"
            [imgUrl]="_albums[selectedCoverImage]?.src"
            [shareMessage]="shareMessage + '\n' + space.deeplink_url"
            [deeplinkUrl]="space.deeplink_url"
          ></app-social-share>
        </div>
        <a
          class="send_btn"
          [ngClass]="{ 'send_btn-override': selectedLanguage == 'ar' }"
          *ngIf="space?.is_favourited"
          (click)="onLike()"
        >
          <img
            src="assets/images/icons/heart-solid.svg"
            [ngClass]="{ facility_section_override: selectedLanguage == 'ar' }"
          />
        </a>
        <a
          class="send_btn"
          [ngClass]="{ 'send_btn-override': selectedLanguage == 'ar' }"
          *ngIf="!space?.is_favourited"
          (click)="onLike()"
        >
          <img
            src="assets/images/icons/heart-regular.svg"
            [ngClass]="{ facility_section_override: selectedLanguage == 'ar' }"
        /></a>
      </div>
    </div>
  </div>

  <app-penny-popup [spaceId]="space.id"></app-penny-popup>

  <!-- image section with header -->
  <div class="home-detail-content">
    <div
      class="bottom-sheet"
      [class.pwa]="isPWA"
      *ngIf="this.userData?.id === this.space?.user?.id"
    >
      <span class="clearButton">Need to make changes?</span>
      <button class="selectButton" (click)="goToEdit()">Edit listing</button>
    </div>

    <div class="home-detail-row">
      <div class="left-column">
        <!-- Hosted by section starts-->
        <div class="hosted_section">
          <p class="section_title">
            {{ "Hosted by" | translate }} {{ space?.user?.first_name }}
          </p>

          <div class="hosting-paper">
            <div class="host_avatar">
              <app-avatar
                height="48"
                width="48"
                [picture]="space?.user?.image_url"
                [name]="space?.user?.first_name"
              >
              </app-avatar>
              <div class="host_details">
                <p>
                  {{ "Joined in" | translate }}
                  {{ space?.user?.created_at | date : "MMM. YYYY" }}
                </p>
                <p class="host_reviews">
                  <!--                       <span> <img class="icons" src="/assets/images/star_ic.svg" alt=""> 0 {{"reviews" | translate}}</span>-->
                  <span *ngIf="space?.user?.is_verified">
                    <img
                      class="icons"
                      src="assets/icons/verified_ic.png"
                      alt=""
                    />{{ "Verified Identity" | translate }}</span
                  >
                </p>
              </div>
            </div>

            <div class="amenities_block_btn_wrapper" *ngIf="!contactHostbtn">
              <button
                type="button"
                class="hosting_btn"
                (click)="contactHost(space?.user?.id)"
              >
                {{ "Contact host" | translate }}
              </button>
            </div>
            <div *ngIf="this.userData?.id === this.space?.user?.id">
              <button type="button" class="edit_btn" (click)="goToEdit()">
                {{ "Edit your listing" | translate }}
              </button>
            </div>
          </div>
        </div>

        <!-- Hosted by section ends-->

        <div
          *ngIf="this.userData?.id !== this.space?.user?.id"
          class="side-form-paper side-form-paper-desktop"
        >
          <div class="total-price">
            <span>Total (USD):</span>
            <span>{{ getTotalPrice() | currency : "USD" }}</span>
          </div>
          <app-drop-panel
            #dropdown
            [className]="'app-booking-calendar-wrapper'"
            [closable]="false"
          >
            <app-booking-calendar
              #calendarDateComp
              [datesError]="datesError"
              [unavailable]="yourSwap.unavailable"
              (dateChange)="onDateSelected($event, true)"
              [max]="maxDate"
              [min]="minDate"
              mode="range"
              [alignMode]="isPWA || isMobile ? 'list' : 'slide'"
              [destroy]="false"
              [pricePerNight]="pricePerNight()"
              (close)="dropdownRef?._close()"
            >
            </app-booking-calendar>
          </app-drop-panel>

          <div class="overview_date">
            <div
              appRipple
              class="styled-input"
              (click)="setUnavailableDates()"
              [dropdownTriggerFor]="dropdown"
            >
              <img
                class="date__icon"
                src="assets/images/icons/primary-calendar-icon.svg"
                alt=""
              />
              {{ swap?.your_swap?.started_at | moment : "MMM DD, YYYY" }}
            </div>

            <div
              appRipple
              class="styled-input"
              (click)="setUnavailableDates()"
              [dropdownTriggerFor]="dropdown"
            >
              <img
                class="date__icon"
                src="assets/images/icons/primary-calendar-icon.svg"
                alt=""
              />
              {{ swap?.your_swap?.ended_at | moment : "MMM DD, YYYY" }}
            </div>
          </div>

          <div class="did-floating-label-content">
            <div
              #guestBottomSheetAnchorRef
              class="styled-input"
              (click)="openGuestBottomSheet()"
            >
              <img
                class="date__icon"
                src="assets/images/icons/primary-user-icon.svg"
                alt=""
              />
              {{ swap?.your_swap?.guests }} Guests
            </div>
          </div>

          <div class="total-booking-cost">
            <span>Total booking cost:</span>
            <span>{{ getTotalBookingCost() | currency : "USD" }}</span>
          </div>
          <div class="total-booking-cost booking-fee">
            <span
              >Booking fee ({{ getNights()
              }}{{ getNights() < 2 ? " night" : " nights" }})
              <br *ngIf="isPremiumMember" />
              <span *ngIf="isPremiumMember" class="premium-selected">{{
                "Waived with premium" | translate
              }}</span>
            </span>
            <span>{{ getNights() * bookingFee | currency : "USD" }}</span>
          </div>
          <div class="grand-total">
            <span>Grand total (USD)</span>
            <span>{{ getTotalPrice() | currency : "USD" }}</span>
          </div>

          <app-home-detail-sign-in
            *ngIf="!authenticated()"
          ></app-home-detail-sign-in>
          <button
            *ngIf="authenticated()"
            class="book-now-btn"
            (click)="handleBookHome()"
          >
            {{ "Book now" | translate }}
          </button>
        </div>

        <!-- overview section start -->
        <div class="overview-section">
          <div class="section_title">{{ "Overview" | translate }}</div>

          <div class="facility_section">
            <ul>
              <li>
                <img
                  src="assets/images/user_bl.svg"
                  [ngClass]="{
                    facility_section_override: selectedLanguage == 'ar'
                  }"
                />{{ space?.guests }}
              </li>
              <li>
                <img
                  src="assets/images/bad_bl.svg"
                  [ngClass]="{
                    facility_section_override: selectedLanguage == 'ar'
                  }"
                />{{ space?.bedrooms }}
              </li>
              <li>
                <img
                  src="assets/images/shower_bl.svg"
                  [ngClass]="{
                    facility_section_override: selectedLanguage == 'ar'
                  }"
                />{{ space?.bathrooms }}
              </li>
            </ul>
          </div>
          <div
            [innerHTML]="
              space?.description.slice(0, maxOverviewLength) +
              (space.description.length > maxOverviewLength ? '...' : '')
            "
            class="section_desciption"
          ></div>
          <span
            class="more-btn"
            (click)="
              maxOverviewLength =
                maxOverviewLength < space.description.length
                  ? space.description.length
                  : 300
            "
            >{{
              maxOverviewLength < space.description.length
                ? ("Read more" | translate)
                : ("Read less" | translate)
            }}</span
          >
        </div>
        <!-- overview section end -->
        <section
          class="amenities_section"
          id="amenitiesFormsection"
          *ngIf="totalSelectedAmenities > 0"
        >
          <div class="title">
            <div class="section_title">{{ "Amenities" | translate }}</div>
            <!-- <span class="edit_btn" (click)="openEditHomePopup(5)">{{"Edit" | translate}}</span> -->
          </div>
          <div class="features_check_outer pe-none allAmenities">
            <span
              class="amenity-chip"
              *ngFor="
                let amenities of categories.amenities.slice(
                  0,
                  maxAmenityLength
                );
                let i = index
              "
            >
              - {{ amenities.name | translate }}
            </span>
            <!--                <form id="amenitiesForm">-->
            <!--                  <h5 class="featuresTitle" *ngIf="categories?.amenities?.length > 0">{{"Features" | translate}}</h5>-->
            <!--                  <ng-container *ngFor="let amenities of categories.amenities; let i=index">-->
            <!--                    <div class="featureCheckBox">-->
            <!--                      <input type="checkbox" [id]="'aminti'+i" [value]="amenities.id"-->
            <!--                             [checked]="amenities.isActive" />-->
            <!--                      <label [for]="'aminti'+i">{{ amenities.name | translate}}</label>-->
            <!--                    </div>-->
            <!--                  </ng-container>-->

            <!--                  <h5 class="featuresTitle" *ngIf="categories?.rules?.length > 0">{{"Rules" | translate}}</h5>-->
            <!--                  <ng-container *ngFor="let rules of categories.rules; let i=index">-->
            <!--                    <div class="featureCheckBox">-->
            <!--                      <input type="checkbox" [id]="'rule'+i" [value]="rules.id" [checked]="rules.isActive" />-->
            <!--                      <label [for]="'rule'+i">{{ rules.name | translate}}</label>-->
            <!--                    </div>-->
            <!--                  </ng-container>-->

            <!--                  <h5 class="featuresTitle" *ngIf="categories?.accessibilities?.length > 0">{{"Accessibilities" | translate}}</h5>-->
            <!--                  <ng-container *ngFor="let accessibilities of categories.accessibilities; let i=index">-->
            <!--                    <div class="featureCheckBox">-->
            <!--                      <input type="checkbox" [id]="'access'+i" [value]="accessibilities.id"-->
            <!--                             [checked]="accessibilities.isActive" />-->
            <!--                      <label [for]="'access'+i">{{ accessibilities.name | translate}}</label>-->
            <!--                    </div>-->
            <!--                  </ng-container>-->

            <!--                  <h5 class="featuresTitle" *ngIf="categories?.safeties?.length > 0">{{"health" | translate}} & {{"safety" | translate}}</h5>-->
            <!--                  <ng-container *ngFor="let safeties of categories.safeties; let i=index">-->
            <!--                    <div class="featureCheckBox">-->
            <!--                      <input type="checkbox" [id]="'safeties'+i" [value]="safeties.id"-->
            <!--                             [checked]="safeties.isActive" />-->
            <!--                      <label [for]="'safeties'+i">{{ safeties.name | translate}}</label>-->
            <!--                    </div>-->
            <!--                  </ng-container>-->
            <!--                </form>-->
          </div>
          <div
            class="btn_wrapper"
            *ngIf="maxAmenityLength < categories.amenities.length"
          >
            <button class="hosting_btn" (click)="toggleAmenities()">
              {{
                this.showAllAmenities
                  ? ("See fewer options" | translate)
                  : ("Show more amenities" | translate)
              }}
            </button>
          </div>
        </section>

        <!-- map section start -->
        <div class="map-section" id="map-section">
          <div class="map_header">
            <div class="section_title">
              {{ "Listing Location" | translate }}
            </div>
            <a
              [href]="
                'https://www.google.com/maps/dir/' +
                space?.address?.latitude +
                ',' +
                space?.address?.longitude
              "
              target="_blank"
              >{{ "Get directions" | translate }}</a
            >
          </div>
          <div class="location_map">
            <app-google-map-view
              *ngIf="space?.address?.latitude && space?.address?.longitude"
              [height]="'300px'"
              [latitude]="space?.address?.latitude"
              [zoom]="15"
              #locationMap
              [longitude]="space?.address?.longitude"
            ></app-google-map-view>

            <!-- <app-mapbox-pin *ngIf="space.address" style="margin: 0px; width: 100vw; border-radius: 0px;"
                    [latitude]="space?.address?.latitude" [zoom]="5" [longitude]="space?.address?.longitude">
                </app-mapbox-pin> -->

            <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1251822.1217861257!2d4.158466276504734!3d52.20768204530646!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c609c3db87e4bb%3A0xb3a175ceffbd0a9f!2sNetherlands!5e0!3m2!1sen!2sin!4v1646731027996!5m2!1sen!2sin" width="100%" height="260" style="border:0;" allowfullscreen="" loading="lazy"></iframe> -->
          </div>

          <div class="map_address">
            <div>
              <div class="map_address_label">Address</div>
              <p class="map_address_description">
                {{
                  space?.address?.full_address ??
                    space?.address?.city + ", " + space?.address?.country
                }}
              </p>
            </div>
            <span class="map_address_copy_btn" (click)="copyAddress()">
              <img src="assets/images/icons/copy_icon.svg" alt="copy" />
            </span>
          </div>
        </div>

        <!--            <div class="share_section">-->
        <!--              <app-social-share-button [homeId]="spaceId" [imgUrl]="_albums[selectedCoverImage]?.src"-->
        <!--                                       [shareMessage]="shareMessage + '\n' + space.deeplink_url" [deeplinkUrl]="space.deeplink_url">-->
        <!--              </app-social-share-button>-->
        <!--            </div>-->

        <!-- review section start -->
        <section class="review_section">
          <div class="reviews_header d-flex justify-content-between mb-3">
            <div class="section_title">{{ "Reviews" | translate }}</div>
          </div>
          <div class="user_reviews">
            <!--
            <p *ngIf="reviewes.length < 1 || reviewes == null">
              {{ "No reviews to show." | translate }}
            </p>
            <app-home-reviews [reviewList]="reviewList"></app-home-reviews>
            //-->
            <p>
              {{ "No reviews to show." | translate }}
            </p>
          </div>
          <!--
          <div class="amenities_block_btn_wrapper" *ngIf="reviewes.length > 0">
            <div class="btn_wrapper">
              <button
                type="button"
                class="block_btn"
                [ngClass]="{ no_cursor: space?.reviews?.data < 1 }"
                (click)="viewReviews()"
              >
                {{ "See all reviews" | translate }}
              </button>
            </div>
          </div>
          //-->
        </section>

        <div class="space-option-box">
          <div id="accordion">
            <div class="card">
              <div class="card-header">
                <a
                  class="collapsed btn"
                  data-bs-toggle="collapse"
                  href="#collapseFive"
                >
                  <span class="heading-option-style">
                    {{ "Availability" | translate }} </span
                  ><i class="fa fa-angle-right toggle-icon-style"></i>
                </a>
              </div>

              <div
                id="collapseFive"
                class="collapse"
                data-bs-parent="#accordion"
              >
                <div class="card-body">
                  <div class="space-host">
                    <app-calendar
                      *ngIf="unavailable_old"
                      [unavailable]="unavailable_old"
                      [min]="minDate"
                      [clickDisable]="true"
                      [destroy]="true"
                    ></app-calendar>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <a
                  class="collapsed btn"
                  data-bs-toggle="collapse"
                  href="#collapseSix"
                >
                  <span class="heading-option-style">
                    {{ "Cancellation Policy" | translate }} </span
                  ><i class="fa fa-angle-right toggle-icon-style"></i>
                </a>
              </div>
              <div
                id="collapseSix"
                class="collapse"
                data-bs-parent="#accordion"
              >
                <div class="card-body card-override">
                  <ng-container *ngIf="isTANHome">
                    <h6>
                      {{
                        "Cancellations of Booking Contracts - TAN HOMES"
                          | translate
                      }}
                    </h6>
                    <div
                      [innerHTML]="
                        'Cancellations of Booking Contracts - TAN HOMES - Content'
                          | translate
                      "
                    ></div>
                  </ng-container>
                  <ng-container *ngIf="!isTANHome">
                    <h6>
                      {{
                        "Cancellations of Booking Contracts - NOT TAN HOMES"
                          | translate
                      }}
                    </h6>
                    <div
                      [innerHTML]="
                        'Cancellations of Booking Contracts - NOT TAN HOMES - Content'
                          | translate
                      "
                    ></div>
                  </ng-container>

                  <!-- <div>
                              <h6>{{"Insurance in Case of Cancellations" | translate}}</h6>
                              <p>
                                  {{"Insurance in Case of Cancellations Paragragh" | translate}}
                              </p>
                          </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="booking-share">
          <img src="assets/images/congrats-space.png" alt="congrats" />
          <h2>
            {{ "Share this home" | translate }} <br class="d-block d-lg-none" />
            {{ "with your friends" | translate }}
          </h2>

          <span class="booking-share-button">
            <app-social-share
              [homeId]="spaceId"
              [ImgUrl]="'/assets/images/icons/share_icon.png'"
              [shareMessage]="shareMessage + '\n' + space.deeplink_url"
              [deeplinkUrl]="space.deeplink_url"
            ></app-social-share>
          </span>
        </div>
      </div>
      <div class="right-column">
        <div
          class="side-form-paper"
          *ngIf="this.userData?.id !== this.space?.user?.id"
        >
          <div class="total-price">
            <span>Total (USD):</span>
            <span>{{ getTotalPrice() | currency : "USD" }}</span>
          </div>
          <app-drop-panel
            #dropdown2
            [className]="'app-booking-calendar-wrapper'"
            [closable]="false"
          >
            <app-booking-calendar
              #calendarDateComp
              [datesError]="datesError"
              [unavailable]="yourSwap.unavailable"
              (dateChange)="onDateSelected($event, true)"
              [max]="maxDate"
              [min]="minDate"
              mode="range"
              [alignMode]="isPWA || isMobile ? 'list' : 'slide'"
              [destroy]="false"
              [pricePerNight]="pricePerNight()"
              (close)="dropdownRef2._close()"
            >
            </app-booking-calendar>
          </app-drop-panel>

          <div class="overview_date">
            <div
              appRipple
              class="styled-input"
              (click)="setUnavailableDates()"
              [dropdownTriggerFor]="dropdown2"
            >
              <img
                class="date__icon"
                src="assets/images/icons/primary-calendar-icon.svg"
                alt=""
              />
              {{ swap?.your_swap?.started_at | moment : "MMM DD, YYYY" }}
            </div>

            <div
              appRipple
              class="styled-input"
              (click)="setUnavailableDates()"
              [dropdownTriggerFor]="dropdown2"
            >
              <img
                class="date__icon"
                src="assets/images/icons/primary-calendar-icon.svg"
                alt=""
              />
              {{ swap?.your_swap?.ended_at | moment : "MMM DD, YYYY" }}
            </div>
          </div>

          <div class="did-floating-label-content">
            <div
              appRipple
              class="styled-input"
              #guestPopupAnchorRef
              (click)="openGuestSheet()"
            >
              <img
                class="date__icon"
                src="assets/images/icons/primary-user-icon.svg"
                alt=""
              />
              {{ swap?.your_swap?.guests }} Guests
            </div>
            <div #guestPopupRef>
              <div *ngIf="showGuestPopup" class="guest_bottom_sheet">
                <h2>How many guests?</h2>
                <div class="guest_bottom_sheet_item">
                  <div>
                    <p class="guest_type">Adults</p>
                    <p class="guest_type_description">12+ years</p>
                  </div>
                  <div class="guest_item_action">
                    <button
                      type="button"
                      title="btn"
                      class="guest-control-btn"
                      (click)="handleChangeGuests('adults', -1)"
                    >
                      <img src="assets/images/icons/minus-icon.svg" alt="" />
                    </button>
                    <span [class.smallText]="!guests.adults">{{
                      guests.adults
                    }}</span>
                    <button
                      type="button"
                      title="btn"
                      class="guest-control-btn"
                      (click)="handleChangeGuests('adults', 1)"
                    >
                      <img src="assets/images/icons/plus-icon.svg" alt="" />
                    </button>
                  </div>
                </div>
                <div class="guest_bottom_sheet_item">
                  <div>
                    <p class="guest_type">Children</p>
                    <p class="guest_type_description">2 - 11 years</p>
                  </div>
                  <div class="guest_item_action">
                    <button
                      type="button"
                      title="btn"
                      class="guest-control-btn"
                      (click)="handleChangeGuests('children', -1)"
                    >
                      <img src="assets/images/icons/minus-icon.svg" alt="" />
                    </button>
                    <span [class.smallText]="!guests.children">{{
                      guests.children
                    }}</span>
                    <button
                      type="button"
                      title="btn"
                      class="guest-control-btn"
                      (click)="handleChangeGuests('children', 1)"
                    >
                      <img src="assets/images/icons/plus-icon.svg" alt="" />
                    </button>
                  </div>
                </div>
                <div class="guest_bottom_sheet_item">
                  <div>
                    <p class="guest_type">Infants</p>
                    <p class="guest_type_description">Under 2 years</p>
                  </div>
                  <div class="guest_item_action">
                    <button
                      type="button"
                      title="btn"
                      class="guest-control-btn"
                      (click)="handleChangeGuests('infants', -1)"
                    >
                      <img src="assets/images/icons/minus-icon.svg" alt="" />
                    </button>
                    <span [class.smallText]="!guests.infants">{{
                      guests.infants
                    }}</span>
                    <button
                      type="button"
                      title="btn"
                      class="guest-control-btn"
                      (click)="handleChangeGuests('infants', 1)"
                    >
                      <img src="assets/images/icons/plus-icon.svg" alt="" />
                    </button>
                  </div>
                </div>
                <div class="guest_bottom_sheet_item">
                  <div>
                    <p class="guest_type">Pets</p>
                    <p class="guest_type_description">
                      Dogs, Cats, small non-dangerous animals
                    </p>
                  </div>
                  <div class="guest_item_action">
                    <button
                      type="button"
                      title="btn"
                      class="guest-control-btn"
                      (click)="handleChangeGuests('pets', -1)"
                    >
                      <img src="assets/images/icons/minus-icon.svg" alt="" />
                    </button>
                    <span [class.smallText]="!guests.pets">{{
                      guests.pets
                    }}</span>
                    <button
                      type="button"
                      title="btn"
                      class="guest-control-btn"
                      (click)="handleChangeGuests('pets', 1)"
                    >
                      <img src="assets/images/icons/plus-icon.svg" alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="total-booking-cost">
            <span>Total booking cost:</span>
            <span>{{ getTotalBookingCost() | currency : "USD" }}</span>
          </div>
          <div class="total-booking-cost booking-fee">
            <span
              >Booking fee ({{ getNights()
              }}{{ getNights() < 2 ? " night" : " nights" }})
              <br *ngIf="isPremiumMember" />
              <span *ngIf="isPremiumMember" class="premium-selected">{{
                "Waived with premium" | translate
              }}</span>
            </span>
            <span>{{ getNights() * bookingFee | currency : "USD" }}</span>
          </div>
          <div class="grand-total">
            <span>Grand total (USD)</span>
            <span>{{ getTotalPrice() | currency : "USD" }}</span>
          </div>

          <app-home-detail-sign-in
            *ngIf="!authenticated()"
          ></app-home-detail-sign-in>
          <button
            *ngIf="authenticated()"
            class="book-now-btn"
            (click)="handleBookHome()"
          >
            {{ "Book now" | translate }}
          </button>
        </div>
        <div
          class="side-form-paper edit-section"
          *ngIf="this.userData?.id === this.space?.user?.id"
        >
          <div class="total-price">
            <span>Need to make changes?</span>
          </div>

          <button class="book-now-btn edit" (click)="goToEdit()">
            Edit listing
          </button>
        </div>
      </div>
    </div>

    <!--        <app-twitter-share [pennyCode]="pennyCode"></app-twitter-share>-->

    <div class="similar_homes_section">
      <div class="section_title">{{ "Similar homes" | translate }}</div>
      <div class="similar_homes_swiper">
        <app-homes-card-component
          #homesCardComponent
          (similarHomesClickEmit)="closeAmenities()"
          *ngIf="similar_space.length > 0"
          [featuredSpaces]="similar_space"
        ></app-homes-card-component>
        <p class="no-data" *ngIf="similar_space.length == 0">
          {{ "No data to display" | translate }}
        </p>
      </div>
    </div>
  </div>
</div>

<app-loading [loading]="loading"></app-loading>

<app-bottom-sheet
  *ngIf="!isDesktopView"
  [closeHeader]="true"
  (closed)="closeDateSelection()"
  #bottomSheet="bottomSheet"
  [defaultHeight]="'448px'"
  [defaultMaxHeight]="'80%'"
>
  <div bottomSheet-header>{{ "Select dates" | translate }}</div>
  <div bottomSheet-body *ngIf="bottomSheet._visible">
    <div class="checkin_date bottom_border mb-45">
      <div>
        <div>
          <app-drop-panel
            #dropdown
            [className]="'search-home-details-calendar'"
            [closable]="false"
          >
            <app-booking-calendar
              #calendarDateComp
              [datesError]="datesError"
              [unavailable]="yourSwap.unavailable"
              (dateChange)="onDateSelected($event, true)"
              [max]="maxDate"
              [min]="minDate"
              mode="range"
              [destroy]="false"
              (close)="dropdownRef?._close()"
            >
            </app-booking-calendar>
          </app-drop-panel>

          <div class="overview_date">
            <div [class.filled]="swap?.your_swap?.started_at">
              <label class="title">{{ "From" | translate }}</label>
              <div
                appRipple
                class="row"
                (click)="setUnavailableDates()"
                [dropdownTriggerFor]="dropdown"
              >
                <img
                  class="date__icon"
                  src="../../../../../assets/icons/calender_icon.svg"
                  alt=""
                />
                <div class="value">
                  {{ swap?.your_swap?.started_at | moment : "MMM DD, YYYY" }}
                </div>
              </div>
            </div>

            <div [class.filled]="swap?.your_swap?.ended_at">
              <label class="title">{{ "To" | translate }}</label>
              <div
                appRipple
                class="row"
                (click)="setUnavailableDates()"
                [dropdownTriggerFor]="dropdown"
                class="row"
              >
                <img
                  class="date__icon"
                  src="../../../../../assets/icons/calender_icon.svg"
                  alt=""
                />
                <div class="value">
                  {{ swap?.your_swap?.ended_at | moment : "MMM DD, YYYY" }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="did-floating-label-content">
      <select
        class="form-select"
        id="floatingSelectGrid"
        [(ngModel)]="swap.your_swap.guests"
        [ngClass]="{
          'floatingSelectGrid-direOverride': selectedLanguage == 'ar'
        }"
        name="pace_type"
        aria-label="Floating label select example"
        onclick="this.setAttribute('value', this.value);"
        onchange="this.setAttribute('value', this.value);"
        value="6"
      >
        <!-- <option value=""></option> -->
        <option *ngFor="let guest of guestList" [value]="guest.guestValue">
          {{ guest.guestName }}
        </option>
      </select>
      <label class="did-floating-label">{{ "Guests" | translate }}</label>
    </div>
  </div>
</app-bottom-sheet>

<div
  class="add-home-modal home-benefit-modal"
  *ngIf="selectDateModal && isDesktopView"
>
  <div class="close-section">
    <div class="skip-and-close">
      <span class="close-popup" (click)="closeDateSelection()">
        <img src="assets/images/cancel_ic.svg" />
      </span>
    </div>
  </div>
  <p class="modal-title">{{ "Select dates" | translate }}</p>
  <div class="dates_selection_wrapper">
    <div class="checkin_date bottom_border m-b-20">
      <div>
        <div>
          <app-drop-panel #dropdown [closable]="false">
            <app-booking-calendar
              #calendarDateComp
              [datesError]="datesError"
              [unavailable]="yourSwap.unavailable"
              (dateChange)="onDateSelected($event, true)"
              [max]="maxDate"
              [min]="minDate"
              mode="range"
              [destroy]="false"
              (close)="dropdownRef?._close()"
            >
            </app-booking-calendar>
          </app-drop-panel>

          <div class="overview_date">
            <div [class.filled]="swap?.your_swap?.started_at">
              <label class="title">{{ "From" | translate }}</label>
              <div
                appRipple
                class="row"
                (click)="setUnavailableDates()"
                [dropdownTriggerFor]="dropdown"
              >
                <img
                  class="date__icon"
                  src="../../../../../assets/icons/calender_icon.svg"
                  alt=""
                />
                <div class="value">
                  {{ swap?.your_swap?.started_at | moment : "MMM DD, YYYY" }}
                </div>
              </div>
            </div>

            <div [class.filled]="swap?.your_swap?.ended_at">
              <label class="title">{{ "To" | translate }}</label>
              <div
                appRipple
                class="row"
                (click)="setUnavailableDates()"
                [dropdownTriggerFor]="dropdown"
                class="row"
              >
                <img
                  class="date__icon"
                  src="../../../../../assets/icons/calender_icon.svg"
                  alt=""
                />
                <div class="value">
                  {{ swap?.your_swap?.ended_at | moment : "MMM DD, YYYY" }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="did-floating-label-content">
      <select
        class="form-select"
        id="floatingSelectGrid"
        [(ngModel)]="swap.your_swap.guests"
        [ngClass]="{
          'floatingSelectGrid-direOverride': selectedLanguage == 'ar'
        }"
        name="pace_type"
        aria-label="Floating label select example"
        onclick="this.setAttribute('value', this.value);"
        onchange="this.setAttribute('value', this.value);"
        value="6"
      >
        <!-- <option value=""></option> -->
        <option *ngFor="let guest of guestList" [value]="guest.guestValue">
          {{ guest.guestName }}
        </option>
      </select>
      <label class="did-floating-label">{{ "Guests" | translate }}</label>
    </div>
  </div>
</div>

<div *ngIf="selectDateModal && isDesktopView" class="bix__modal-backdrop"></div>

<div #guestBottomSheetRef>
  <div
    *ngIf="showGuestBottomSheet"
    class="guest_bottom_sheet mobile_guest_bottom_sheet"
  >
    <h2>How many guests?</h2>
    <div class="guest_bottom_sheet_item">
      <div>
        <p class="guest_type">Adults</p>
        <p class="guest_type_description">12+ years</p>
      </div>
      <div class="guest_item_action">
        <button
          type="button"
          title="btn"
          class="guest-control-btn"
          (click)="handleChangeGuests('adults', -1)"
        >
          <img src="assets/images/icons/minus-icon.svg" alt="" />
        </button>
        <span [class.smallText]="!guests.adults">{{ guests.adults }}</span>
        <button
          type="button"
          title="btn"
          class="guest-control-btn"
          (click)="handleChangeGuests('adults', 1)"
        >
          <img src="assets/images/icons/plus-icon.svg" alt="" />
        </button>
      </div>
    </div>
    <div class="guest_bottom_sheet_item">
      <div>
        <p class="guest_type">Children</p>
        <p class="guest_type_description">2 - 11 years</p>
      </div>
      <div class="guest_item_action">
        <button
          type="button"
          title="btn"
          class="guest-control-btn"
          (click)="handleChangeGuests('children', -1)"
        >
          <img src="assets/images/icons/minus-icon.svg" alt="" />
        </button>
        <span [class.smallText]="!guests.children">{{ guests.children }}</span>
        <button
          type="button"
          title="btn"
          class="guest-control-btn"
          (click)="handleChangeGuests('children', 1)"
        >
          <img src="assets/images/icons/plus-icon.svg" alt="" />
        </button>
      </div>
    </div>
    <div class="guest_bottom_sheet_item">
      <div>
        <p class="guest_type">Infants</p>
        <p class="guest_type_description">Under 2 years</p>
      </div>
      <div class="guest_item_action">
        <button
          type="button"
          title="btn"
          class="guest-control-btn"
          (click)="handleChangeGuests('infants', -1)"
        >
          <img src="assets/images/icons/minus-icon.svg" alt="" />
        </button>
        <span [class.smallText]="!guests.infants">{{ guests.infants }}</span>
        <button
          type="button"
          title="btn"
          class="guest-control-btn"
          (click)="handleChangeGuests('infants', 1)"
        >
          <img src="assets/images/icons/plus-icon.svg" alt="" />
        </button>
      </div>
    </div>
    <div class="guest_bottom_sheet_item">
      <div>
        <p class="guest_type">Pets</p>
        <p class="guest_type_description">
          Dogs, Cats, small non-dangerous animals
        </p>
      </div>
      <div class="guest_item_action">
        <button
          type="button"
          title="btn"
          class="guest-control-btn"
          (click)="handleChangeGuests('pets', -1)"
        >
          <img src="assets/images/icons/minus-icon.svg" alt="" />
        </button>
        <span [class.smallText]="!guests.pets">{{ guests.pets }}</span>
        <button
          type="button"
          title="btn"
          class="guest-control-btn"
          (click)="handleChangeGuests('pets', 1)"
        >
          <img src="assets/images/icons/plus-icon.svg" alt="" />
        </button>
      </div>
    </div>
  </div>
</div>
