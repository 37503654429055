import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { ClevertapService } from '../../services/clevertap.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { NotificationService } from '../../services/notification.service';
import { SnackbarService } from '../../services/snackbar.service';
import { SpaceService } from '../../services/space.service';
import { UserService } from '../../services/user.service';
import { SnackbarComponent } from '../snackbar/snackbar.component';

@Component({
  selector: 'app-homes-card-component',
  templateUrl: './homes-card-component.component.html',
  styleUrls: ['./homes-card-component.component.scss'],
})
export class HomesCardComponentComponent implements OnInit {
  @Input() public featuredSpaces: any = [];
  @Output() public similarHomesClickEmit = new EventEmitter();
  userData: any;
  wishlist = [];
  userEmail;
  isPremiumMember;

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  public config: SwiperOptions = {
    direction: 'horizontal',
    slidesPerView: 1.4,
    spaceBetween: 20,
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    navigation: false,
    pagination: false,
    virtual: false,
    preloadImages: false,
    autoHeight: false,
    observer: true,

    breakpoints: {
      640: {
        slidesPerView: 2.2,
      },
      1024: {
        slidesPerView: 4.2,
      },
    },
  };
  constructor(
    private clevertapService: ClevertapService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private spaceService: SpaceService,
    private snackbarService: SnackbarService,
    private notificationService: NotificationService,
    private userService: UserService,
    private translate: TranslateService,
    private cd: ChangeDetectorRef,
    private toastr: ToastrService,
    private router: Router
  ) {
    setTimeout(() => {
      let userFullData = localStorage.getItem('userDataTEST');
      this.userData = JSON.parse(userFullData);

      if (this.userData) {
        this.isPremiumMember =
          this.userData.subscription?.planName != 'Lite' &&
          this.userData.subscription?.is_trial == 1;
      } else {
        this.userService.currentUserSubject.subscribe((data) => {
          this.userData = data;
          this.isPremiumMember =
            this.userData.subscription?.planName != 'Lite' &&
            this.userData.subscription?.is_trial == 1;
        });
        // this.isPremiumMember = this.userData.subscription?.planName =='Lite' || this.userData.subscription?.is_trial == 0 ;
      }
    }, 8000);
  }

  ngOnInit(): void {}

  update() {
    this.swiper.swiperRef.updateSlides();
    this.swiper.swiperRef.updateSize();

    this.cd.detectChanges();
  }
  onSlideChange() {}

  public navigateToHome(id) {
    if (!id) return;

    localStorage.setItem('navUrl', 'PopularHomes');
    if (this.clevertapService.isWebView) {
      this.googleAnalyticsService.eventEmitter(
        'home_clicks_app',
        'Clicks',
        '/view-home/home-details-ibs/' + id
      );
    } else {
      this.googleAnalyticsService.eventEmitter(
        'home_clicks_web',
        'Clicks',
        '/view-home/home-details-ibs/' + id
      );
    }

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.onSameUrlNavigation = 'reload';

    this.router.navigateByUrl('/view-home/home-details-ibs/' + id);
    this.similarHomesClickEmit.emit();
    if (this.wishlist.length <= 0) {
      let searchData = {
        email: this.userEmail,
      };
      this.clevertapService.pushCleverTapEvent('wishlist', searchData);
    }
  }

  getPersentage(amount) {
    let discount = Math.round((parseInt(amount) * 20) / 100);
    let offerAmt = amount - discount;
    return offerAmt;
  }

  public onLike(space, index) {
    this.spaceService.likeSpace(space.id, space.is_favourited).subscribe(
      (response) => {
        //	space.is_favourited = !this.space.is_favourited;
        this.featuredSpaces[index].is_favourited = !space.is_favourited;

        if (this.featuredSpaces[index].is_favourited) {
          this.snackbarService.show({
            message: this.translate.instant(
              'This home has been saved to your favourites!'
            ),
            type: 'success',
            config: {
              class: 'centered',
            },
          });
        } else {
          this.snackbarService.show({
            message: this.translate.instant(
              'Removed home from your favourites'
            ),
            type: 'success',
            config: {
              class: 'centered',
            },
          });
        }

        let _tempuser = {
          name: this.notificationService.getSelectedFriend().first_name,
          email: this.notificationService.getSelectedFriend().email,
          friendId: this.notificationService.getSelectedFriend().id,
          userId: this.userService.getCurrentUser().id,
          title: 'Your home was liked!',
          notificationType: 4,
          user_identity:
            this.notificationService.getSelectedFriend().clevertap_identity,
          clevertap_id:
            this.notificationService.getSelectedFriend().clevertap_id,
          message:
            this.userService.getCurrentUser().first_name +
            " liked your home. See if it's a match!",
        };

        this.clevertapService.sendNotification(_tempuser);
      },
      (err) => {
        this.toastr.error(this.translate.instant(err), 'Error');
      }
    );
  }

  gotoPopularHomes() {
    this.router.navigate(['popular-stay']);
  }
}
