<div class="main_wrapper" [class.arabic_lang]="selectedLanguage=='ar'">
  <div [class]="'star_reviews ' + ((colorClass)?colorClass:'')" [class.clickable]="clickable" [class.inactive] = "inactive? true : false">
     <span *ngFor="let s of starList" (click)="selectedStar(s.value)">
<!-- (dblclick)="resetStar()" -->
      <svg [class.active]="control ? control.value >= s.value : star >= s.value" [ngClass]="'icon__size-'+iconSize" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.214 13.614">
        <defs>
          <style>.star_a{fill:none;}.star_b{fill:#e1e3e9;}</style>
        </defs>
        <path id="Path_4636" class="star_a star_b" data-name="Path 4636" d="M6.764.9A.956.956,0,0,1,8.582.9L9.605,4.044a.956.956,0,0,0,.908.66h3.31a.957.957,0,0,1,.562,1.73L11.708,8.378a.956.956,0,0,0-.348,1.069l1.023,3.147a.956.956,0,0,1-1.472,1.069L8.234,11.719a.956.956,0,0,0-1.123,0L4.434,13.663a.956.956,0,0,1-1.471-1.069L3.986,9.447a.956.956,0,0,0-.348-1.069L.962,6.435A.957.957,0,0,1,1.524,4.7H4.833a.956.956,0,0,0,.909-.66L6.765.9Z" transform="translate(-0.566 -0.236)"/>
      </svg>
       <div *ngIf="showLabel">
         <span class="star_label"> {{ s.label | translate }}</span>
       </div>
     </span>
  </div>


</div>


