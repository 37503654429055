import {
	Component,
	OnInit, Input,
	Output, EventEmitter, HostListener, ElementRef, ViewChild, SimpleChanges, TemplateRef, ChangeDetectionStrategy, ChangeDetectorRef
} from "@angular/core";
import { NgForOfContext } from '@angular/common';
export function debounce(delay: number = 300): MethodDecorator {
	return (_target: any, _propertyKey: string, descriptor: PropertyDescriptor) => {
		let timeout = null;

		const original = descriptor.value;

		descriptor.value = function (...args) {
			clearTimeout(timeout);
			timeout = setTimeout(() => original.apply(this, args), delay);
		};

		return descriptor;
	};
}
@Component({
	selector: "app-select",
	templateUrl: "./select.component.html",
	styleUrls: ["./select.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectComponent implements OnInit {
	@Input() public disabled: boolean = false;
	@Input() public required: boolean = false;
	@Input() public multiple: boolean = false;
	@Input() public search: boolean = false;

	@Input() public placeholder: string;
	@Input() public key: string;
	@Input() public value: string;

	@Input() public config: any = {};

	@Input() public items: any[] = [];
	@Input() public template: TemplateRef<any>;
	// @ContentChild(TemplateRef) public template: TemplateRef<any>;

	@Input() public selected: any;
	@Output() public selectedChange = new EventEmitter();

	@Output() public onSearching = new EventEmitter();

	public _open = false;
	public _search: string;
	@ViewChild("searchField", { static: true }) public searchField: ElementRef;

	public _selectedItems: any = [];
	public _mappedItems = [];
	public _itemsCache = [];

	constructor(private elementRef: ElementRef, private changeDetector: ChangeDetectorRef) { }
	public ngOnChanges(changes: SimpleChanges) {

		if (changes.selected && changes.selected.currentValue !== changes.selected.previousValue) {
			this.detectSelectedChange();
		}
	}

	public detectSelectedChange() {
		// this._selectedItems = this.multiple ? this.selected : [this.selected];

		if (this.multiple) {
			if (!this.selected) {
				this.selected = [];
			}

			/*this._selectedItems = this.items.filter((item) => (this.selected as string[])
				.includes(this.key && this.value ? item[this.key] : item)).map((item) => this.key ? item[this.key] : item);*/



		} else {
			const itemFound = this.items.find((item) => this.key
				&& this.value ? item[this.value] === this.selected : item === this.selected);

			this._selectedItems = [itemFound];
		}
		/*if (this.multiple) {
			// map items to plain array
			if (!this.selected) {
				this.selected = [];
			}
			this._selectedItems = this.items.filter((item) => (this.selected as string[])
				.includes(this.key && this.value ? item[this.value] : item)).map((item) => this.key ? item[this.key] : item);
		} else {
			const itemFound = this.items.find((item) => this.key
				&& this.value ? item[this.value] === this.selected : item === this.selected);

			this._selectedItems = itemFound && itemFound[this.key] ? itemFound[this.key] : itemFound;
		}

		if (this._selectedItems === undefined) {
			this._selectedItems = this.multiple ? [] : "";
		}*/

	}
	public ngOnInit() {

		//	document.documentElement.style.setProperty("--border-color", this.color);
	}

	public show() {
		this._open = true;

		if (this.search) {
			setTimeout(() => {
				document.getElementById("searchField").focus();
			}, 0);
		}

		this.changeDetector.detectChanges();
	}

	public close() {
		this._open = false;
		this.changeDetector.detectChanges();
	}

	public onClear() {

	}

	public onSearch(query: string) {
		if (this.config.lazyload) {


			if (query.length > 1) {
				this._mappedItems = this._itemsCache.filter((i) => i.toLowerCase().includes(query.toLowerCase()));
			} else {
				this._mappedItems = this._itemsCache;
			}
		}
		this.onSearching.emit(query);
	}

	public onSelect(item) {
		if (this.multiple) {

			if (!this._selectedItems) {
				this._selectedItems = [];
			}

			this._selectedItems.push(item);
		} else {
			this._selectedItems = [item];
			this.close();
		}

		this._emitChanges();
	}

	public onRemoveItem(index) {
		this._selectedItems.splice(index, 1);
		this.changeDetector.detectChanges();

		this._emitChanges();
	}

	@HostListener("document:mousedown", ["$event"])
	public onGlobalClick(event): void {
		if (!this.elementRef.nativeElement.contains(event.target)) {
			// clicked outside => close dropdown list
			this._open = false;
			this.changeDetector.detectChanges();
		}
	}

	private _emitChanges() {


		if (!this.key) {
			if (this.multiple) {
				const items = this.items.filter((item) => this._selectedItems.includes(item));
				return this.selectedChange.emit(items.length > 0 ? items : []);
			}

			this.onSearching.emit("");
			this._search = "";
			this.changeDetector.detectChanges();

			this.selectedChange.emit(this._selectedItems[0]);
			return;
		}

		if (this.key) {
			if (this.multiple) {


				return this.selectedChange.emit(this._selectedItems.length > 0 ? this._selectedItems.map((item) => item[this.value]) : []);
			}

			this.onSearching.emit("");
			this._search = "";
			this.changeDetector.detectChanges();
			return this.selectedChange.emit(this.value ? this._selectedItems[0][this.value] : this._selectedItems[0] || "");
		}

	}

}
