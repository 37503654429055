
<div class="share_btnBox">
    <button type="button" class="oneShare_btn" *ngIf="cleverTabService.isWebView && !congratsShare" (click)="socialShareModal()">
        <img class="coinImg" src="assets/images/two-coins.svg" alt="">
        <p>{{'Share this amazing news' | translate}}</p>
        <img src="assets/images/download_btn.svg" alt="">
    </button>

    <button type="button" class="oneShare_btn" [class.congratsShare]="congratsShare" *ngIf="cleverTabService.isWebView && congratsShare" (click)="socialShareModal()">
        <div class="white_wrapper">
            <img class="spaceImg" src="assets/images/congrats-space.png" alt="">
            <p>{{'Show off your home!' | translate}}</p>
            <span class="shareIcon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12" cy="12" r="11.4" stroke="#FFFFFF" stroke-width="1.2"/>
                    <path d="M7.33594 12.0488V16.6662H16.6693V12.0488" stroke="#FFFFFF" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11.4399 11.4987L11.4402 11.4986L11.4358 11.4418L11.4352 11.4341L11.4355 11.4341L11.4355 6.98687L8.64902 9.49057C8.64896 9.49063 8.6489 9.49069 8.64883 9.49075C8.54738 9.58275 8.41272 9.63536 8.2725 9.64062C8.1322 9.64589 7.99345 9.60349 7.8843 9.51976C7.77489 9.43582 7.70248 9.31563 7.68578 9.18199C7.66906 9.04814 7.71006 8.91507 7.79746 8.8099L7.80242 8.80393L7.80264 8.80414L7.8481 8.75733L7.85284 8.75246L7.85299 8.75263L11.6044 5.38156L11.6046 5.38142C11.7065 5.29026 11.841 5.23833 11.981 5.23342L11.9814 5.23341L11.9995 5.23285L12.0026 5.23275L12.0026 5.2328L12.0028 5.2328L12.0206 5.2328L12.0241 5.2328L12.0241 5.23286C12.035 5.23324 12.0459 5.23391 12.0567 5.23485L12.0567 5.23524C12.1568 5.24412 12.2534 5.2771 12.3362 5.3319L12.3367 5.33223L12.3416 5.33557L12.3426 5.33622C12.3626 5.34995 12.3816 5.3649 12.3994 5.38099C12.3995 5.381 12.3995 5.38102 12.3995 5.38103L16.1523 8.75163C16.2562 8.8427 16.3197 8.9681 16.326 9.10327C16.3324 9.23874 16.2804 9.36924 16.1845 9.4684C16.0889 9.56721 15.9568 9.62777 15.8162 9.64065C15.6756 9.65353 15.5336 9.61811 15.4191 9.53971L15.4137 9.53596L15.4138 9.53579L15.3621 9.49511L15.357 9.49108L15.3571 9.49093L12.5703 6.98792L12.5703 11.4341C12.5703 11.5753 12.5075 11.7081 12.4001 11.8042C12.2931 11.9 12.1495 11.9521 12.0026 11.9521C11.8685 11.952 11.7376 11.9087 11.6341 11.8279M11.4399 11.4987L11.6341 11.8279M11.4399 11.4987L11.441 11.5061M11.4399 11.4987L11.441 11.5061M11.6341 11.8279C11.5304 11.747 11.4605 11.6332 11.441 11.5061M11.6341 11.8279L11.441 11.5061" fill="#FFFFFF" stroke="#FF6D2E" stroke-width="0.2"/>
                    </svg>
            </span>
            <!-- <img class="shareIcon" src="assets/images/download_btn.svg" alt=""> -->
        </div>
    </button>
    <div class="web_buttons" *ngIf="!cleverTabService.isWebView">
        <button class="shareBtn faceBookBtn" type="button"  shareButton="facebook" [title]="'Holidayswap'" [url]="customUrl" (click)="shareCalled()">
            <i class="fa fa-facebook-square fb-icon" aria-hidden="true"></i>
        </button>
        <button class="shareBtn twitterBtn" type="button" shareButton="twitter" [title]="'Holidayswap'" [url]="customUrl" (click)="shareCalled()">
            <i class="fa fa-twitter twitter-icon" aria-hidden="true"></i>
        </button>
        <button class="shareBtn whatsappBtn" type="button" shareButton="whatsapp" [title]="'Holidayswap'" [url]="customUrl" (click)="shareCalled()">
            <i class="fa fa-whatsapp whatsapp-icon" aria-hidden="true"></i>
        </button>
    </div>
</div>