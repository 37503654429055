<div class="pay-group">

	<div class="pay-options">
		<!-- <div (click)="onMethod(0)" class="option">
			<div class="option-header">
				<div class="option-header-type">
					{{"Wallet" | translate}}
				</div>

				<div class="option-header-toggle">
					<i class="{{ payment.method === 0 ? 'feather-check-circle' : 'feather-circle'}}"></i>
				</div>
			</div>

			<div *ngIf="payment.method === 0" class="option-inner">
				<app-payment-request-button (onPaymentMethod)="onPaymentMethod($event)"
					(onPaymentSuccess)="onWalletPaymentSuccess($event)" (loading)="loading = $event" [item]="item"
					#wallet>
				</app-payment-request-button>
				{{ (wallet.available ? "" : "Wallet not available, please use a different payment method") | translate }}
			</div>
		</div> -->

		<button class="confirm-btn" *ngIf="paymentSelectionConfirm" (click)="onPay()">
			{{confirmPurchase_btnTxt | translate}}
		 </button>

		<div (click)="onMethod(1)" class="option" *ngIf="showCard">
			<div class="option-header">
				<div class="option-header-type">
					{{"Credit / Debit card" | translate}}
				</div>

				<div class="option-header-toggle">
					<i class="{{ payment.method === 1 ? 'feather-check-circle' : 'feather-circle'}}"></i>
				</div>

			</div>

			<div *ngIf="payment.method === 1" class="option-inner">
				<app-payment-cards [editable]="false" (onSelected)="onPaymentMethod($event)"></app-payment-cards>
			</div>
		</div>
	</div>

	<button class="confirm-btn" *ngIf="payment.card && paymentSelection" (click)="selectPaymentConfirm()">{{"Select" | translate}}</button>


	<!-- <div appRipple (click)="onPay()" *ngIf="payment.card" class="pay-action">
		Select
	</div> -->

	<app-loading [absolute]="true" [loading]="loading"></app-loading>
</div>