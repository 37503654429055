import { Component, OnInit, Input, ChangeDetectorRef, ViewChild, Output, EventEmitter, HostListener } from '@angular/core';

import { UserService } from "src/app/shared/services/user.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FeaturedExploreSpacesComponent } from "../../component/featured-explore-spaces/featured-explore-spaces.component";
import { ExploreSpacesService } from "src/app/shared/services/explore-spaces.service";
import { FeaturedRegionsComponent } from "../../component/featured-regions/featured-regions.component";
import { CookieService } from 'ngx-cookie-service';
import { SpaceService } from "src/app/shared/services/space.service";
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { TokenService } from 'src/app/shared/services/token.service';
import { BottomSheetComponent } from 'src/app/shared/components/bottom-sheet/bottom-sheet.component';
import { Location } from "@angular/common";
import { LanguageService } from 'src/app/shared/services/language.service';
import {ToastrService} from "ngx-toastr";
import { PromoService } from 'src/app/shared/services/promo.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import clevertap from 'clevertap-web-sdk'
import { ClevertapService } from 'src/app/shared/services/clevertap.service';
import { TranslateService } from '@ngx-translate/core';
import { MyHomesComponent } from '../../component/my-homes/my-homes.component';

declare var $: any;

@Component({
	selector: 'app-explore-home-spaces',
	templateUrl: './explore-home-spaces.component.html',
	styleUrls: ['./explore-home-spaces.component.scss']
})
export class ExploreHomeSpacesComponent implements OnInit {
	public user: any = {};
	public loading = true;
	openAddHomeForm = false;
	public address: any = {};
	public featuredLocations = [];
	public featuredSpaces = [];
	public spaces = [];
	public subscribePopStatus = false;
	public cardPayment = false;
	public selectedSubscriptionId: any = 2;
	public userDetails;
	public addHomePopup = false;
  public addRatingPopup = false;
  timeStamp:any;

  app_review;
	@ViewChild("featuredExploreSpacesEl") featuredExploreSpacesEl: FeaturedExploreSpacesComponent;
	@ViewChild("featuredRegionstSpacesEl") featuredRegionstSpacesEl?: FeaturedRegionsComponent;
	@ViewChild("myhomesEl") myhomesEl: MyHomesComponent;

	// =======================================================================================
	public selectedLanguage = "en";

	public subscriptions: any[] = [];
	public purchaseSubs = [];

	public selected: any = {};
	@Input() public active: number;
	@Output() public onSubscription = new EventEmitter();
	@ViewChild("bottomSheet") bottomSheet: BottomSheetComponent;

  ratingForm:FormGroup
	subscribeTimeout: any;
	addHomePopupTimeout: any;
    canSwap: boolean = false;

    public space: any = {
      reviews: {
        data: [],
      }
    };
    public reviewes = [];
    public tasks = [];
    public taskId;
    public swapId;
    public spaceId;
    public ratingTask: any;
    public ratingPopStatus = false;
	public subscription;
	public showWelcomepopup = false;
	public mySpaces = [];
	public arb = false;

	isDesktopView: boolean = (window.screen.width > 1024);
	@HostListener('window:resize', ['$event'])
	handleResize(event: KeyboardEvent) {
		this.isDesktopView = (window.screen.width > 1024);
	}

	constructor(
		public userService: UserService,
    private route: ActivatedRoute,
		private router: Router,
		private spaceService: SpaceService,
		private exploreService: ExploreSpacesService,
		private cd: ChangeDetectorRef,
		private cookieService: CookieService,
		private subscriptionService: SubscriptionService,
		private snackbarService: SnackbarService,
		private tokenService: TokenService,
		private location: Location,
		private languageService: LanguageService,
    private toastr: ToastrService,
		private promoService: PromoService,
		private clevertapService: ClevertapService,
    	private fb:FormBuilder,
		public translate: TranslateService
	) {
		this.getMyHomes();
		this.ratingForm = this.fb.group({
		rating: new FormControl(1),
		})


		let loadLang = this.languageService.GetLanguage() + '';
		if (loadLang == "null" || loadLang == "") this.selectedLanguage = "en";
		else this.selectedLanguage = loadLang;

		this.languageService.languageUpdates.subscribe(
		(lang) => {
			this.selectedLanguage = lang;

			if(this.selectedLanguage == 'ar') {
				this.arb = true;
			}
			else {
				this.arb = false;
			}
		});


	}

	testParticularEvent() {
		//can be removed later
		let data = {
			"title": "Custom triggered event"
		};
		this.clevertapService.pushCleverTapEvent('mobileSignUpUser', data, true);
	}

	ngOnInit(): void {
		this.subscribePopStatus = false;
		this.timeStamp=Math.floor(Math.random() * 1000) + 1

		// UserService.cacheBuster$.next();
		// this.userService.getMe().subscribe();
		this.getUserDetails();
    	this.getProgressTrack();
		this.promoService.tokenUpdates.subscribe((res) => {
			this.getUserDetails();
		})

		this.userService.currentUser.subscribe((user) => {
			this.loading = false;
			this.user = user;


		});

		SubscriptionService.cacheBuster$.next();
		this.subscriptionService.getAvailableSubscriptions().subscribe((subscriptions) => {
			this.subscriptions = subscriptions;

			//this.subscriptions.reverse();
			this.selected = this.subscriptions[1];

		}).add(() => {
			this.loading = false;
		});
		this.subscriptionService.getSubscriptionHistory().subscribe((response: { data: [] }) => {
			this.purchaseSubs = response.data;
		});

		this.getSpaces();
		this.getFeaturedSpaces();
		this.getFeaturedLocations();
    this.getHomepage();

	if (!localStorage.getItem("premiumPopup") || localStorage.getItem("premiumPopup") == "0") {
			this.subscribeTimeout = setTimeout(() => {

				if (this.user.subscription?.subscription_id == 3){
					this.subscribePopStatus = true;
				}
				else if (this.user.subscription?.cancelled_at != "" && this.user.subscription?.cancelled_at != null){
					this.subscribePopStatus = true;
				}
				localStorage.setItem("premiumPopup", "1");
			}, 10000);
	}

		if(localStorage.getItem("navUrl")){

			localStorage.removeItem("navUrl");
		}

		// setTimeout(() => {
		// 	if (!this.user.subscription)
		// 		this.subscribePopStatus = true;
		// 	else if (this.user.subscription.cancelled_at != "" && this.user.subscription.cancelled_at != null)
		// 		this.subscribePopStatus = true;
		// }, 10000);

		this.userService.userDetailsUpdateEvent.subscribe(() => {
			this.getUserDetails();
		});


		this.languageService.languageUpdates.subscribe(
			(lang)=>{
			  this.selectedLanguage = lang;
			}
		  )

		  let loadLang = this.languageService.GetLanguage() + '';

		  if (loadLang == "null" || loadLang == "") {
			  this.selectedLanguage = "en";
		  }
		  else {
			  this.selectedLanguage = loadLang;
		  }

    //   this.ratingForm.get('rating').valueChanges.subscribe(res => {


	// 		// this.router.navigate(["/settings/token-payment"], { queryParams: { "rating": this.tokenPrice, "tokens": this.tokenValue } });

    //   })
	}

	ngOnDestroy() {
		clearTimeout(this.subscribeTimeout);
		clearTimeout(this.addHomePopupTimeout);
	}

	showToggle: boolean = false;
	onShow() {
		let el = document.getElementsByClassName("bunch_drop");
		if (!this.showToggle) {
			this.showToggle = true;
			el[0].className = "bunch_drop show";
		}
		else {
			this.showToggle = false;
			el[0].className = "bunch_drop";
		}
	}

	public getFeaturedSpaces() {
		this.loading = true;

		this.exploreService.getFeaturedSpaces().subscribe((res: any) => {
			this.loading = false;

			this.featuredSpaces = res.data;

			this.featuredExploreSpacesEl.update();
			this.cd.detectChanges();

		}, (err) => {
			this.loading = false;


		}).add(() => {
		});
	}

	public getFeaturedLocations() {
		this.loading = true;

		this.exploreService.getFeaturedLocations().subscribe((res: any) => {
			this.loading = false;

			this.featuredLocations = res.data;

			this.featuredRegionstSpacesEl?.update();
			this.cd.detectChanges();

		}, (err) => {
			this.loading = false;


		}).add(() => {
		});
	}


	goToTokenPurchase() {
		// if(!this.user?.email_verified) {
		// 	this.userService.verifyMail.next(true);
		// 	return;
		// }
		clearTimeout(this.subscribeTimeout);
		this.subscribePopStatus = false;
		if(this.isDesktopView) {
			//this.router.navigateByUrl("/settings/tokens");
		} else {
			this.router.navigate([{ outlets: { modal: ["modal", "settings", 'tokens'] } }], { queryParams: { "previousPage": "/"} });

		}
	}

	searchResults() {

		this.cookieService.delete('search-address');

		this.router.navigate(["/map-view-list"]);

	}

	searchViewList() {

		this.router.navigate(["/search-homes"]);
	}

	public getSpaces() {
		this.loading = true;
		this.spaceService.getMySpaces().subscribe(
			(data) => {
				this.spaces = data;


				if(this.spaces.length == 0)
				{

					if(!localStorage.getItem("addHomeMessagePopup") ||  localStorage.getItem("addHomeMessagePopup") == "0")
					{
				this.addHomePopupTimeout = setTimeout(() => {
					this.addHomePopup = true;

					localStorage.setItem("addHomeMessagePopup", "1");

				}, 20000);
			}
			}

				this.loading = false;

			}
			, (err) => {

			}).add(() => {
				this.loading = false;
			});
	}

	public goToSpace() {
		this.showAddHomeForm();
		// this.addHomePopup = false;

		// if (this.spaces.length == 0 ) {

		// 	this.router.navigate(["/add-space-intro"]);
		// }
		// else {

		// 	this.router.navigate(["/spaces/add-home"],{ queryParams: { "previousPage": '/'} });
		// }
		this.userService.postAnalytics(this.router.url,"explore_home_spaces_add_home_clicked").subscribe((res)=>{});

		// Fire the AppsFlyer event
		(window as any).AF('pba', 'event', {eventType: 'EVENT', eventName: 'add_home_button_clicked', eventValue: {
			"screen": "explore_home_spaces"
		}});
	}
	showAddHomeForm(){
		this.addHomePopup = false;
		this.openAddHomeForm = true;
	}
	closeAddFormPopup() {
		this.openAddHomeForm = false;
	}

    public getMyHomes() {
        this.spaceService.getMySpaces().subscribe((homes: any) => {
			this.mySpaces = homes;

			if(this.mySpaces.length > 0) {
				this.mySpaces.splice(1, 0, {id : null})
			}
            this.canSwap = (homes.filter(home => home.purpose != 'host').length > 0);

			if(this.myhomesEl){
				this.myhomesEl.update(this.mySpaces);
				this.cd.detectChanges();
			}
        }),
		(error => {
			throw new Error(error)
		});
    }
	// =======================================================================================

	public onClearSubscription() {
		this.selected = {};
	}

	setSelectedPlan(subscription) {

		this.selected = subscription;
		this.selectedSubscriptionId = subscription.id;

	}

	openPaymentPopup() {

		if(this.selectedSubscriptionId != 3) {
			// this.isSubscripted = true;


			if (this.user.subscription?.subscription_id === this.selectedSubscriptionId) {
				this.toastr.success(this.translate.instant(`You are already on this plan`), 'Success')
				return;
			}
			this.bottomSheet.toggle();
		}
		else {
			this.toastr.error(this.translate.instant("Please select plan"), "Error")
		}
	}

	public onShowPayment(subscription) {
		this.selected = subscription;

		this.bottomSheet.toggle();
	}

	public onPaymentSuccess(e) {



		//if (this.purchaseSubs && this.purchaseSubs.length > 0) {
		this.addBonusToken();
		//}



		if (!this.user?.subscription?.id || this.user?.subscription?.subscription_id == '3') {
			//	if (!this.user?.has_subscription?.id) {
			this.onSubscribe({
				item_id: e.id,
				payment_method: e.method,
				bonus_token: false
			});
		} else {
			this.onUpdate(e.id, e.method);
		}
	}

	public onUpdate(id, payment_method) {

		this.subscriptionService.update(id, payment_method).subscribe(() => {
			this.snackbarService.show({
				message: this.translate.instant("Subscription updated!"),
			});
			this.onSubscription.emit();

			UserService.cacheBuster$.next();

			this.userService.getMe().subscribe((user) => {
				this.user = user.data;
			});


			this.bottomSheet.close();
			this.router.navigate(["/dashboard"]);
		}, (err) => {

			this.snackbarService.show({
				message: err,
				type: 'success',
				config: {
					class : 'centered'
				}
			});
			this.loading = false;
			this.bottomSheet.close();
		}).add(() => {
			this.loading = false;
		});
	}

	public onSubscribe(data: { item_id: number; payment_method: string; bonus_token :boolean}) {


		this.loading = true;
		this.subscriptionService.subscribe(data).subscribe(() => {
			this.snackbarService.show({
				message: this.translate.instant("Subscription started!"),
			});
			UserService.cacheBuster$.next();

			this.userService.getMe().subscribe((user) => {
				this.user = user.data;
			});
			this.bottomSheet.close();
			this.onSubscription.emit();
			this.router.navigate(["/dashboard"]);
		}, (err) => {

			this.snackbarService.show({
				message: err,
				type: 'success',
				config: {
					class : 'centered'
				}
			});
			this.loading = false;
			this.bottomSheet.close();
		}).add(() => {
			this.loading = false;
		});
	}

	addBonusToken() {
		// this.tokenService.bonusTokens("Bonus", 19, 23, 50, "Bonus on subscribing").subscribe(
		// 	(res) => {

		// 	}
		// )
	}

	public pop() {
		this.subscribePopStatus = false;
	}

	// ========================================================================================

	public showCardPayment() {
		this.cardPayment = !this.cardPayment;
	}

	public deactivatePaymentCard(e) {

		this.cardPayment = false;
	}

	public getUserDetails()
	{

		UserService.cacheBuster$.next();
		this.userService.getMe().subscribe((user : any) => {

			this.userDetails = user.data;
			this.subscription = user.data.subscription;
			// setTimeout(() => {
			// 	this.showWelcomepopup = user.data.welcome_popup == 1 ? false : true;
			// 	if(!this.isDesktopView && this.showWelcomepopup) {
			// 		this.router.navigate([{ outlets: { modal: ["modal", "welcome-popup" ] } }]);
			// 	}
			// }, 180000);



		});

	}

	public closeHomePopup() {
		this.addHomePopup = false;
	}

    gotoSwipePage(subscribeId: any,type: 'check'|'route' ='route', showToast: boolean = true):any {
		if(subscribeId == 3){
			let message = 'You are in Lite access, Please upgrade your subscription'
			this.toastr.error(this.translate.instant(message), "Error");
			return false;
		}

        if(!this.canSwap) {
			let requiredSwap="Swap Home Required";
			let swapFeature="Swap feature only available for users that have properties with swap function selected.";
            // if(showToast) this.snackbarService.show({message: 'Swap feature only available for users that have properties with swap function selected'});
            if(showToast)   this.toastr.info(this.translate.instant(swapFeature), this.translate.instant(requiredSwap));
            return false;
        };

        if(type =='route') this.router.navigateByUrl('/swipe');
        return true;
    }

    public getProgressTrack(){
		this.userService.getProgressTrackerDetails().subscribe((data:any) => {
			this.app_review = data.data.app_review_given;
		})
    }

   	public getHomepage() {
		this.spaceService.getHomePage().subscribe((home:any) => {
			this.tasks = home.data.tasks;
			let ratingTask = this.tasks.find(task => task.task_id == 9); // 9 is show rating task
			if(ratingTask) {
			// if(!localStorage.getItem("ratingPopup") || localStorage.getItem("ratingPopup") == "0"){
			//   this.addRatingPopup = true;
			// }
			// this.taskId = ratingTask.task_id;
			this.spaceId = ratingTask.space_id;
			this.swapId = ratingTask.route_action_id;
			this.ratingTask=ratingTask;
				if(localStorage.getItem("ratingPopup") && localStorage.getItem("ratingPopup")=='1'){
					this.addRatingPopup = false;
				}else{
					this.addRatingPopup = true;
					this.spaceService.ratingPopup.next(true);
				}
			}
		})
  	}

	public closeRatingPopup(colseTask=false){
    	// this.ratingPopStatus = false;
		if(colseTask){
			this.closeTask();
		}
		this.spaceService.ratingPopup.next(false);
		this.addRatingPopup = false;
		localStorage.setItem("ratingPopup","1");
	}

	public closeTask(){
		this.spaceService.colseReviewTask(this.swapId).subscribe((result:any) => {
		//    },(err) => {
		//   }).add(() => {
		// 	this.loading = false;
		});
	}

	get rating() {
		return this.ratingForm.get("rating")  as FormControl;
	}

	navigateTorating(){
		this.closeRatingPopup();
		this.router.navigate(
			["/ratings/add-ratings", this.ratingTask.space_id,this.ratingTask.route_action_id],
			{ queryParams: { "rating": this.rating.value} }
		);
	}

	closeWelcomePopUp(event) {
		this.showWelcomepopup = false;
	}

}
