import { SpaceService } from 'src/app/shared/services/space.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { CalendarComponent } from '../calendar/calendar.component';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-home-unavailablity-date',
  templateUrl: './home-unavailablity-date.component.html',
  styleUrls: ['./home-unavailablity-date.component.scss'],
})
export class HomeUnavailablityDateComponent implements OnInit {
	@Input() public space: number;

  @Input() public dates: any = [];
	@Output() public datesChange = new EventEmitter();

	public unavailable = [];
	public minDate = new Date();
	public selectedDate;
	public spaceId;
	public unavailable_dates : any = [];
	public toggleCalendar: boolean = false;

	public seletedRange: any = null;
  @ViewChild('unavailableCalendar') unavailableCalendar: CalendarComponent;

  isMobile = false;

  isPWA = false;


  constructor(private spaceService: SpaceService, private toastr: ToastrService, private route: ActivatedRoute, private deviceService: DeviceDetectorService) {
    this.isMobile = this.deviceService.isMobile();

    this.isPWA = window.matchMedia('(display-mode: standalone)').matches;

		this.route.params.subscribe((param) => {

			this.spaceId = param.id;
    });


		//  this.getSpaceDetails();

	 }

	ngOnInit(): void {
		setTimeout(() => {

      if (this.spaceId) {
				this.getSpaceDetails();
			}

			// let dates = [];

			// this.dates.map((date) => {

			// 	const start_date  = moment(date.started_at).format('YYYY-MM-DD');
			// 	const end_date  = moment(date.ended_at).format('YYYY-MM-DD');
			// 	dates.push(this.enumerateDaysBetweenDates(start_date, end_date));
			// })

			// this.unavailable = dates;


			// this.unavailableCalendar.updateUnavailable(this.unavailable);

    }, 1000);
	}

	public changeDateSelection(date) {

		this.selectedDate = date;
	}
	getSpaceDetails() {
    this.spaceService.getSpace(this.spaceId).subscribe((res: any) => {

			this.unavailable_dates = res.unavailable_dates.filter((dates) => {
        if (dates?.status == 'UNAVAILABLE') {
				  return dates;
				}
      });

			  const dates = [];
			  this.unavailable_dates.map((date) => {
					const start = moment.unix(date.started_at).format("YYYY-MM-DD");
					const end = moment.unix(date.ended_at).format("YYYY-MM-DD");
					dates.push(...this.enumerateDaysBetweenDates(start, end));
				});

					this.unavailable = dates;
					// this.unavailableCalendar?.updateUnavailable(this.unavailable)
			//   this.generateGalleryImages(this.space.images);


    });

	}
	public onDateAdded() {
    const date = this.selectedDate;
		if (date.start && date.end) {
			const dates = this.enumerateDaysBetweenDates(date.start, date.end);
			this.unavailable = [...this.unavailable, ...dates];



			const len = this.dates.push({
				started_at: date.start,
				ended_at: date.end,
        status: 'UNAVAILABLE',
			});




			// const dates = [];
			// this.dates.map((date) => {
			// 	const start = moment(date.started_at).format("YYYY-MM-DD");
			// 	const end = moment(date.ended_at).format("YYYY-MM-DD");
			// 	dates.push(this.enumerateDaysBetweenDates(start, end));
			// });
			// this.unavailable = dates;
      this.unavailableCalendar?.updateUnavailable(this.unavailable);

			// this.syncFusionCalendarComp.updateUnavailableDates(this.dates);

			// this.spaceService.addUnavailableDates(this.space, {
			// 	dates: [{
			// 		started_at: date.start,
			// 		ended_at: date.end
			// 	}]
			// }).subscribe(res => {

			// }, (err) => {
			// 	this.dates.splice(len - 1, 1);
			// });
			this.datesChange.emit(this.dates);
      this.selectedDate = '';
		}



	}


	public onRemove(i) {
    const date = this.dates.splice(i, 1);
		this.datesChange.emit(this.dates);

		const dates = [];

		this.dates.map((date) => {
			const start = moment(date.started_at).format("YYYY-MM-DD");
			const end = moment(date.ended_at).format("YYYY-MM-DD");
			dates.push(...this.enumerateDaysBetweenDates(start, end));
		});



	   this.unavailable = dates;

    if (date[0].id) {
			this.spaceService.deleteUnavailableDates(date[0].id).subscribe((res: any) => {

        this.toastr.success(res.message, 'Success');



			}, (err) => {
				// this.dates.splice(len - 1, 1);



			});


		}
		else {




		}

	}

	private _enumerateDaysBetweenDates(startDate, endDate) {
		const dates = [];

		const currDate = moment(startDate).startOf("day");
		const lastDate = moment(endDate).startOf("day");

		while (currDate.add(1, "days").diff(lastDate) < 0) {

			dates.push(currDate.clone().toDate());
		}

		return dates;
	}


	public enumerateDaysBetweenDates(startDate, endDate) {
		startDate = moment(startDate);
		endDate = moment(endDate);

		const now = startDate;
		const dates = [];

		while (now.isBefore(endDate) || now.isSame(endDate)) {
			dates.push(now.format("YYYY-MM-DD"));
			now.add(1, "days");
		}
		return dates;
	}

	toggleDateRangePicker() {
		this.toggleCalendar = true;

		// this.syncFusionCalendarComp?.toggleDateRangePicker(this.toggleCalendar)
	  }

	//   updateUnavailable(dates) {
	// 	this.dates = dates;
	// 	this.updateDateFormat();
	//   }

	//   updateDateFormat(){
	// 		this.dates.map((date) => {

	// 			const start_date  = moment(date.started_at).format('YYYY-MM-DD');
	// 			const end_date  = moment(date.ended_at).format('YYYY-MM-DD');
	// 			this.dates.push(this.enumerateDaysBetweenDates(start_date, end_date));
	// 		})

	// 		this.unavailable = this.dates;


	//   }


}
