import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      document.location.href = 'https://onelink.to/holidayswapapp';
  }, 250);
  }

}
