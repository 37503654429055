<div class="app_container">
    <!-- <div class="explore_header">
       <div class="explore_left">
           <div class="back_btn">
               <span (click)="pop()"><img src="/assets/images/back_arrow.svg"></span>
           </div>
       </div>
   </div> -->

    <div class="home-intro">
        <!-- <div class="home-intro-bg">
            <img src="../../../../../assets/images/lisitng_img.jpg">
        </div> -->
        <div class="home-intro-content">
            <span class="tag_line">{{"You are about to earn HS Points for adding your home!" | translate}} </span>
            <h1>{{"Become a host in a few steps" | translate}}</h1>
            <span class="gray_tag_line">{{"Join us, We’ll help you every step of the way." | translate}}</span>
            <div class="move-step-btn" [ngClass]="{'move-step-btn_override': selectedLanguage == 'ar'}">
                <button type="button" (click)="goToAddSpace()">
                    <i class="fa " [ngClass]="{'fa-angle-right': selectedLanguage != 'ar', 'fa-angle-left': selectedLanguage == 'ar'}" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    </div>
</div>
