<div class="share_icon" (click)="socialShareModal()" [class.explore_right]="float" [ngClass]="{'explore_right_override': selectedLanguage == 'ar'}">
    <img alt="share icon" [src]="ImgUrl">
</div>

<div id="socialshare-modal-1" *ngIf="shareModalStatus" class="social_modal">
    <div (click)="socialShareModal()">
        <i class="fa fa-times share-close-icon"></i>
    </div>

    <div class="social_media" [ngClass]="{'lng_arabic': selectedLanguage == 'ar'}">
        <ng-container>
            <i (click)="shareModal()" class="fa fa-facebook-square fb-icon" aria-hidden="true" shareButton="facebook" [title]="'Holidayswap'" [url]="customUrl"></i>
            <i (click)="shareModal()" class="fa fa-twitter twitter-icon" aria-hidden="true" shareButton="twitter" [title]="'Holidayswap'" [url]="customUrl"></i>
            <!-- <i (click)="shareModal()" class="fa fa-instagram instagram-icon" aria-hidden="true" shareButton="instagram" [title]="'Holidayswap'" [url]="customUrl"></i> -->
            <i (click)="shareModal()" class="fa fa-whatsapp whatsapp-icon" aria-hidden="true" shareButton="whatsapp" [title]="'Holidayswap'" [url]="customUrl"></i>
            <!-- <i (click)="shareModal()" class="fa fa-linkedin linkedIn-icon" aria-hidden="true" shareButton="linkedin" [title]="'Holidayswap'" [url]="customUrl"></i> -->
        </ng-container>
        <!-- <ng-container *ngIf="cleverTabService.isWebView">
            <i (click)="shareModal('facebook')" class="fa fa-facebook-square fb-icon" aria-hidden="true" [title]="'Holidayswap'" [url]="customUrl"></i>
            <i (click)="shareModal('twitter')" class="fa fa-twitter twitter-icon" aria-hidden="true" [title]="'Holidayswap'" [url]="customUrl"></i>
            <i (click)="shareModal('system')" class="fa fa-pinterest pinterest-icon" aria-hidden="true" [title]="'Holidayswap'" [url]="customUrl"></i>
            <i (click)="shareModal('whatsapp')" class="fa fa-whatsapp whatsapp-icon" aria-hidden="true" [title]="'Holidayswap'" [url]="customUrl"></i>
        </ng-container> -->


    </div>
</div>
