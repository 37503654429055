<div class="digit-group">
	<input #first class="input" autocomplete="new-password" appActivateNextInput maxlength="1" minlength="1" type="tel"
		[(ngModel)]="code.one" />
	<input class="input" autocomplete="new-password" appActivateNextInput maxlength="1" minlength="1" type="tel"
		[(ngModel)]="code.two" />
	<input class="input" autocomplete="new-password" appActivateNextInput maxlength="1" minlength="1" type="tel"
		[(ngModel)]="code.three" />
    <input class="input" autocomplete="new-password" appActivateNextInput maxlength="1" minlength="1" type="tel"
		[(ngModel)]="code.four" />
    <input class="input" autocomplete="new-password" appActivateNextInput maxlength="1" minlength="1" type="tel"
		[(ngModel)]="code.five" />
	<input class="input" autocomplete="new-password" appActivateNextInput maxlength="1" minlength="1" type="tel"
		[(ngModel)]="code.six" (ngModelChange)="onLastEntry()" />

	<!-- <div class="copy-option" (click)="pasteCode()">
		Paste
	</div> -->
	<!-- appPasteText -->
</div>
