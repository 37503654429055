<div
  *ngIf="visible"
  id="explore-filter-modal"
  class="explore-filter-modal-container"
>
  <div class="explore-filter-modal-content">
    <div class="modal-content-header">
      <h4>Filters</h4>
      <button class="close-button" (click)="close()">
        <img
          src="../../../../../assets/images/onboard/close.svg"
          alt="close_icon"
        />
      </button>
    </div>
    <div class="filter-section">
      <section class="section">
        <span class="filter-modal-section-title"
          >Please select one option:</span
        >
        <div class="option-container">
          <div class="option" [class.selected-box]="placeTypes === '565'">
            <input
              type="checkbox"
              id="entirePlace"
              [checked]="placeTypes === '565'"
              (change)="handleChangePlaceType('565')"
            />
            <label for="entirePlace">
              <span class="customCheckbox"></span>
              <div>
                <span>An entire place</span>
                <p>Guests have the whole place to themselves</p>
              </div>
            </label>
          </div>
          <div class="option" [class.selected-box]="placeTypes === '566'">
            <input
              type="checkbox"
              id="privateRoom"
              [checked]="placeTypes === '566'"
              (change)="handleChangePlaceType('566')"
            />
            <label for="privateRoom">
              <span class="customCheckbox"></span>
              <div>
                <span>A private room</span>
                <p>
                  Guests sleep in a private room but some areas maybe be shared
                  with others
                </p>
              </div>
            </label>
          </div>
          <div class="option" [class.selected-box]="placeTypes === '567'">
            <input
              type="checkbox"
              id="sharedRoom"
              [checked]="placeTypes === '567'"
              (change)="handleChangePlaceType('567')"
            />
            <label for="sharedRoom">
              <span class="customCheckbox"></span>
              <div>
                <span>A shared room</span>
                <p>
                  Guests sleep in a room or common area that may be shared with
                  you or others
                </p>
              </div>
            </label>
          </div>
        </div>
      </section>
      <hr class="divider divider-1" />
      <section class="section">
        <span class="filter-modal-section-title"
          >Enter a price range to search through</span
        >
        <div class="custom-slider">
          <ngx-slider
            [(value)]="priceBottomValue"
            [(highValue)]="priceTopValue"
            (userChange)="onSliderChange($event)"
            [options]="priceOption"
          ></ngx-slider>
        </div>
        <div class="price-range-container">
          <div class="price-container">
            <span class="price-label">Minimum price</span>
            <div>
              <span class="prize">
                $
                <input
                  type="number"
                  min="0"
                  max="1500"
                  [value]="priceBottomValue"
                  (change)="onChangeMinimumPrice($event)"
                />
              </span>
            </div>
          </div>
          <div class="separator">
            <span class="prize">to</span>
          </div>
          <div class="price-container">
            <span class="price-label">Maximum price</span>
            <div>
              <span class="prize">
                $
                <input
                  type="number"
                  min="0"
                  max="1500"
                  [value]="priceTopValue"
                  (change)="onChangeMaximumPrice($event)"
                />
              </span>
            </div>
          </div>
        </div>
      </section>
      <hr class="divider" />
      <section class="section">
        <span class="filter-modal-section-title">Layout and facilities</span>
        <div class="layout-wrapper">
          <span class="item-title">Bedrooms</span>
          <div>
            <div class="button-container">
              <button
                *ngFor="let item of ['Any', '1', '2', '3', '4', '5', '6']"
                key="value"
                [class.active]="activeBedrooms === item"
                (click)="activeBedrooms = item"
              >
                {{ item }}
              </button>
            </div>
          </div>
          <span class="item-title">Bathrooms</span>
          <div>
            <div class="button-container">
              <button
                *ngFor="let item of ['Any', '1', '2', '3', '4', '5', '6']"
                key="value"
                [class.active]="activeBathrooms === item"
                (click)="activeBathrooms = item"
              >
                {{ item }}
              </button>
            </div>
          </div>
        </div>
      </section>
      <section class="section">
        <span class="filter-modal-section-title">Best in class</span>
        <div class="content-container">
          <div class="item-container">
            <span class="class-title">Top tier hosts</span>
            <p class="class-paragraph">
              Stay with hosts that go above and beyond!
            </p>
          </div>
          <div class="custom-toggle-switch">
            <input
              type="checkbox"
              class="toggleSwitchCheckbox"
              id="toggleSwitch0"
              [checked]="activeButtons[0]"
              (change)="handleButtonClick($event, 0)"
            />
            <label class="toggleSwitchLabel" [for]="'toggleSwitch0'">
              <span class="toggleSwitchInner"></span>
              <span class="toggleSwitchSwitch"></span>
            </label>
          </div>
        </div>
      </section>
      <hr class="divider" />
      <section class="section">
        <span class="filter-modal-section-title">Furry family members</span>
        <div class="content-container">
          <div class="item-container">
            <span class="class-title">Homes allowing pets</span>
            <p class="class-paragraph">
              (Dogs, Cats, small non-dangerous animals):
              <span class="check">{{ activeButtons?.[2] ? 'Yes' : 'No' }}</span>
            </p>
          </div>
          <div class="custom-toggle-switch">
            <input
              type="checkbox"
              class="toggleSwitchCheckbox"
              id="toggleSwitch2"
              [checked]="activeButtons[2]"
              (change)="handleButtonClick($event, 2)"
            />
            <label class="toggleSwitchLabel" [for]="'toggleSwitch2'">
              <span class="toggleSwitchInner"></span>
              <span class="toggleSwitchSwitch"></span>
            </label>
          </div>
        </div>
      </section>
    </div>
    <hr class="divider" />
    <div class="section footer">
      <button class="clearButton" (click)="clearFilter()">Clear</button>
      <button class="selectButton" (click)="handleSubmit()">Select</button>
    </div>
  </div>
</div>
