<form id="payment-form" class="add_card_wrapper" [formGroup]="card">
  <div class="add_card_container">
    {{ error }}

    <div class="form-item">
      <label>{{ "Full Name" | translate }}*</label>

      <input
        required
        autocomplete="name"
        class="input"
        formControlName="name"
        type="text"
        name="full_name"
        placeholder="{{ 'Name on the card' | translate }}"
      />
      <ng-container *ngIf="card.get('name').touched">
        <span
          class="input-error"
          *ngIf="this.card.get('name').hasError('required')"
          >{{ "Full name is incomplete" | translate }}</span
        >
        <span
          class="input-error"
          *ngIf="this.card.get('name').hasError('invalid')"
          >{{ "Full name is incomplete" | translate }}</span
        >
      </ng-container>
    </div>

    <div class="bix-row">
      <div class="col-12 col-md-6 mt-4">
        <div class="form-item">
          <label> {{ "Card Number" | translate }}* </label>
          <div
            #cardNumberRef
            class="input stripe-input {{ focus ? 'focus' : '' }}"
          ></div>
          <div #cardNumberErrorRef class="input-error" role="alert"></div>
        </div>
      </div>
      <div class="col-6 col-md-3 mt-4">
        <div class="form-item">
          <label> {{ "Expiry" | translate }}* </label>
          <div
            #cardExpiryRef
            id="expiry-content"
            class="input stripe-input {{ focus ? 'focus' : '' }}"
          ></div>
          <div
            #cardExpiryErrorRef
            id="expiry-error"
            class="input-error"
            role="alert"
          ></div>
        </div>
      </div>
      <div class="col-6 col-md-3 mt-4">
        <div class="form-item">
          <label> {{ "CVC" | translate }}* </label>
          <div
            #cardCVCRef
            id="cvc-content"
            class="input stripe-input {{ focus ? 'focus' : '' }}"
          ></div>
          <div
            #cardCVCErrorRef
            id="cvc-error"
            class="input-error"
            role="alert"
          ></div>
        </div>
      </div>
    </div>

    <div class="bix-row">
      <div class="col-12 col-md-4">
        <div class="form-item">
          <label>{{ "Post code" | translate }}*</label>

          <input
            required
            class="input"
            formControlName="postalcode"
            type="text"
            name="postalcode"
            placeholder="{{ 'Enter post code' | translate }}"
            #receiverInput
          />
          <ng-container *ngIf="card.get('postalcode').touched">
            <span
              class="input-error"
              *ngIf="this.card.get('postalcode').hasError('required')"
              >{{ "Post code is incomplete" | translate }}</span
            >
            <span
              class="input-error"
              *ngIf="this.card.get('postalcode').hasError('minlength')"
              >{{ "Post code must be 2 to 10 characters" | translate }}</span
            >
            <span
              class="input-error"
              *ngIf="this.card.get('postalcode').hasError('maxlength')"
              >{{ "Post code must be 2 to 10 characters" | translate }}</span
            >
          </ng-container>
        </div>
      </div>
      <div class="col-12 col-md-8">
        <div class="form-item">
          <label>{{ "Country/Region" | translate }}*</label>
          <div class="country-picker-wrapper">
            <input
              id="autocompleteInput"
              ngx-google-places-autocomplete
              [options]="options"
              required
              class="input"
              (onAddressChange)="handleAddressChange($event)"
              formControlName="country"
              placeholder="{{ 'Select country/region' | translate }}"
            />
            <ng-container *ngIf="card.get('country').touched">
              <span
                class="input-error"
                *ngIf="this.card.get('country').hasError('required')"
                >{{ "Country/Region* is incomplete" | translate }}</span
              >
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div class="form-item m-t-20">
      <!--			<div class="fixed-footer">-->
      <!--				<div>-->
      <!--					<p>{{"Add Payment Method" | translate}}</p>-->
      <!--					<p class="highlight">{{"Credit or Debit card" | translate}}</p>-->
      <!--				</div>-->
      <!--				<div><button class="add-button" (click)="onSubmit()">{{"Add" | translate}}</button></div>-->
      <!--			</div>-->
      <!-- <app-button [block]="true" [loading]="loading" background="#275efe" textColor="white"> {{"Save" | translate}}
			</app-button> -->
    </div>
  </div>

  <button class="hs_btn hs_btn--primary m-b-20" (click)="onSubmit()">
    {{ "Confirm payment method" | translate }}
  </button>
</form>

<app-loading [loading]="loading"></app-loading>
