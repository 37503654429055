import { Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { SnackbarService } from "src/app/shared/services/snackbar.service";
import { SpaceService } from "src/app/shared/services/space.service";
import { environment } from "src/environments/environment";
import { SwiperOptions } from "swiper";
import { ICreateInstantPay } from "src/app/shared/interfaces/swap";
import { forkJoin } from "rxjs";
import { DropPanelComponent } from "src/app/shared/components/drop-panel/drop-panel.component";
import { SwapService } from "src/app/shared/services/swap.service";
import { NotificationService } from "src/app/shared/services/notification.service";
import { TokenService } from "src/app/shared/services/token.service";
import { UserService } from "src/app/shared/services/user.service";
import { Location } from "@angular/common";
import { ClevertapService } from 'src/app/shared/services/clevertap.service';
import { Lightbox } from 'ngx-lightbox';
import { LanguageService } from 'src/app/shared/services/language.service';

@Component({
	selector: 'app-home-space-details',
	templateUrl: './home-space-details.component.html',
	styleUrls: ['./home-space-details.component.scss']
})
export class HomeSpaceDetailsComponent implements OnInit {
	shareMessage="I just listed my Home on Holiday Swap so I can earn thousands and travel for free!  Check it out! ";
	public swap: ICreateInstantPay = {
		type: "host",
		your_swap: {
			guests: 1,
		},
		their_swap: {
			guests: 1,

		},
		token: 0
	};

	public spaceId = 0;
	public tab = 0;
	public openAddHomeForm = false;
	public config: SwiperOptions = {
		slidesPerView: 1,
		virtual: true,
		spaceBetween: 0,
		pagination: { clickable: true, type: "bullets" },
		navigation: false

	};
	public step = 0;

	public mySpaces = [];
	public userSpaces = [];
	public yourSwap = {
		unavailable: [],
	};
	public theirSwap = {
		unavailable: [],

	};
	public spaceFeatures = [];
	public minDate = moment().toDate()
	public maxDate = moment().add(11, "months").toDate();
	public extras = [];
	public payment = {
		has_property_insurance: false,
		has_cancellation_insurance: false,

	};
	public paymentsEnabled = environment.payments_enabled;
	// shareModalStatus = false;
	public selectedLanguage = "en";
	public sections = [{
		key: "amenities",
		name: "Amenities",
		icon: this.sanitizer.bypassSecurityTrustHtml(`	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
		stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
		class="ai ai-Wifi">
		<path d="M2 10c6-6.667 14-6.667 20 0" />
		<path d="M6 14c3.6-4 8.4-4 12 0" />
		<circle cx="12" cy="18" r="1" />
	</svg>`),
	}, {
		key: "accessibilities",
		name: "Accessibility",
		icon: this.sanitizer.bypassSecurityTrustHtml(`<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
		stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
		class="ai ai-Lifesaver">
		<g clip-path="url(#clip0)">
			<circle cx="12" cy="12" r="10" transform="rotate(45 12 12)" />
			<circle cx="12" cy="12" r="4" transform="rotate(45 12 12)" />
			<path d="M19.071 4.929l-4.243 4.243" />
			<path d="M9.172 14.828l-4.243 4.243" />
			<path d="M19.071 19.071l-4.243-4.243" />
			<path d="M9.172 9.172L4.929 4.929" />
		</g>
		<defs>
			<clipPath id="clip0">
				<rect width="24" height="24" />
			</clipPath>
		</defs>
	</svg>`)
	}, {
		key: "atmospheres",
		name: "Atmosphere",
		icon: this.sanitizer.bypassSecurityTrustHtml(`<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
		stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
		class="ai ai-Coffee">
		<path d="M11 7c4.418 0 8 .895 8 2s-3.582 2-8 2-8-.895-8-2c0-.357.375-.693 1.033-.984" />
		<path
			d="M3 9v9.343c0 1.061.44 2.08 1.409 2.513C5.624 21.399 7.711 22 11 22c3.29 0 5.377-.601 6.591-1.144.968-.433 1.409-1.452 1.409-2.513V9" />
		<path d="M19 10v0a3 3 0 0 1 3 3v0a3 3 0 0 1-3 3v0" />
		<path d="M7 3v4" />
		<path d="M11 2v2" />
		<path d="M15 4v3" />
	</svg>`),
	}, {
		key: "landscapes",
		name: "Landscape",
		icon: this.sanitizer.bypassSecurityTrustHtml(`	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
		stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
		class="ai ai-Image">
		<path d="M2 6a4 4 0 0 1 4-4h12a4 4 0 0 1 4 4v12a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V6z" />
		<circle cx="8.5" cy="8.5" r="2.5" />
		<path
			d="M14.526 12.621L6 22h12.133A3.867 3.867 0 0 0 22 18.133V18c0-.466-.175-.645-.49-.99l-4.03-4.395a2 2 0 0 0-2.954.006z" />
	</svg>`),
	}, {
		key: "safeties",
		name: "Safety",
		icon: this.sanitizer.bypassSecurityTrustHtml(`<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="ai ai-FaceWink"><circle cx="12" cy="12" r="10"/><path d="M7 9h2"/><path d="M16 9.05v-.1"/><path d="M16 15c-.5 1-1.79 2-4 2s-3.5-1-4-2"/></svg>`),
	}, {
		key: "rules",
		name: "Rules",
		icon: this.sanitizer.bypassSecurityTrustHtml(`<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="ai ai-Shield"><path d="M13.147 21.197l1.67-1.168a13.393 13.393 0 0 0 5.447-13.624.837.837 0 0 0-.453-.586L12 2 4.19 5.819a.837.837 0 0 0-.454.586 13.393 13.393 0 0 0 5.448 13.624l1.67 1.168a2 2 0 0 0 2.293 0z"/></svg>`),
	}];

	public expand = {
		amenities: false,
		accessibility: false,
		atmosphere: false,
		landscape: false,
		saftey: false,
		rules: false,
	};
	public space: any = {
		reviews: {
			data: [],
		}
	}; // todo generate interface

  userID = localStorage.getItem('id');
  isEdit=false;

	public unavailable = [];
	public loading = true;
	private viewNotifyTimeout = null;
	public userDetails: any;
	public isReserveClicked = false;
	public totalBookingDays = 1;
	token = '';

	_albums = [];
	public reviewes = [];

	items = [
		{
			name: 'home',
			link: '/'
		},
		{
			label: 'home details'
		}
	]
	public desktopSwiperConfig: SwiperOptions = {
		slidesPerView: 8,
		virtual: true,
		spaceBetween: 15,
		pagination: false,
		navigation: false,
		breakpoints: {
			1024: {
				slidesPerView: 5,
				spaceBetween: 15,
			},
			1920: {
				slidesPerView: 8,
				spaceBetween: 15
			}
		}
	};
	selectedCoverImage: number = 0;
	isDesktopView: boolean = (window.screen.width > 1024);
	hasHistory: boolean = false;
	@HostListener('window:resize', ['$event'])
	handleResize(event: KeyboardEvent) {
		this.isDesktopView = (window.screen.width > 1024);
	}


	constructor(private sanitizer: DomSanitizer,
		private route: ActivatedRoute,
		private spaceService: SpaceService,
		private snackbarService: SnackbarService,
		private swapService: SwapService,
		private router: Router,
		private notificationService: NotificationService,
		public userService: UserService, private location: Location,
		private tokenService: TokenService, private clevertapService: ClevertapService,
		private _lightbox: Lightbox,
		private languageService: LanguageService) {
			this.hasHistory = this.router.navigated;
		}

	ngOnInit(): void {


		this.route.params.subscribe((params) => {

			if (params.id) {
				this.spaceId = params.id;

				this._getSpace(params.id);
				//this.getExtras(params.id);
			}
		});
    // this.getUserSpace();
		UserService.cacheBuster$.next();
		this.userService.getMe().subscribe((data)=>{


    });

		let loadLang = this.languageService.GetLanguage() + '';
		if (loadLang == "null" || loadLang == "") this.selectedLanguage = "en";
		else this.selectedLanguage = loadLang;

		this.languageService.languageUpdates.subscribe(
			(lang) => {
				this.selectedLanguage = lang;
			});
	}


	private _getSpace(id: number) {
		this.loading = true;
		this.spaceService.getSpace(id).subscribe((space) => {
			this.loading = false;
			this.space = space;
			this.spaceId = this.space.id;
      // this.userID = this.space.user.id

			localStorage.setItem("space_id", this.space.id);
			this.spaceFeatures = this.space.amenities;
			this.notificationService.setFriend(this.space.user);


      if(this.space.user.id == this.userID) {
        this.isEdit = true;
      }
			const dates = [];
			this.space.unavailable_dates.map((date) => {
				const start = moment.unix(date.started_at).format("YYYY-MM-DD");
				const end = moment.unix(date.ended_at).format("YYYY-MM-DD");
				dates.push(...this.enumerateDaysBetweenDates(start, end));
			});

			this.unavailable = dates;



			//	if (this.space.purpose.name != 'both') {
			this.swap.type = this.space.purpose.name;
			this.step = this.step + 1;
			//	this.loadSpaces(this.space.user.id);
			//   this.onProgress();
			//	}

			this.reviewes = this.space.reviews.data;
			this.generateGalleryImages(this.space.images);

			this.viewNotifyTimeout = setTimeout(() => {
				let spaceData = {
					"space_id": localStorage.getItem("space_id"),
					"user_id": localStorage.getItem("id"),
				};





				this.spaceService.viewedSpace(spaceData).subscribe((res) => {
					if (res && res == true) {
						const messageNotify = {
							"title": "Somebody viewed your space",
							"text": "Your space has been viewed by someone",
						};
						this.notificationService.sendFCMNotification(messageNotify);
					}
				});
			}, 12000);
		}, (err) => {
			// handle error
		}).add(() => {
			this.loading = false;
		});
	}

  private getUserSpace() {
    this.loading = true;

		// this.spaceService.getMySpaces().subscribe((space) => {
    //   space.forEach(element => {


    //         if(element.id==this.userID) {

    //           this.isEdit = true;


    //         }
    //    });
    // })
  }

	public enumerateDaysBetweenDates(startDate, endDate) {
		startDate = moment(startDate);
		endDate = moment(endDate);

		const now = startDate;
		const dates = [];

		while (now.isBefore(endDate) || now.isSame(endDate)) {
			dates.push(now.format("YYYY-MM-DD"));
			now.add(1, "days");
		}
		return dates;
	}

	public pop() {
		if(this.hasHistory) {
			this.location.back();
		} else {
			this.router.navigateByUrl('/');
		}
	}

	public goToEditSpace() {
		this.openAddHomeForm = true;
		// const navigationExtras = { queryParams: { id: this.space.id } };
		// const commands = [
		// 	{
		// 		outlets: {
		// 			modal: ["modal", "spaces", "add-home"],
		// 		},
		// 	},
		// ];
		// this.router.navigate(commands, navigationExtras);

		// const navigationExtras = { queryParams: { id: this.space.id } };
		// let commands;

		// if (this.isDesktopView) {
		// 	commands = ['/spaces/add-home/'+this.space.id]
		// } else {
		// 	commands = [
		// 		{ outlets: { modal: ["modal", "spaces", "add-home", this.space.id], }, },
		// 	];
		// }

		// this.router.navigate(commands);

	}

	  closeAddFormPopup() {
		this.openAddHomeForm = false;
		this._getSpace(this.spaceId);
	  }





	// public socialShareModal(){
	// 	this.shareModalStatus = !this.shareModalStatus;
	// }
	generateGalleryImages(images) {
		this._albums = [];

		for (let img of images) {
			const album = {
				src: img.image_url,
				caption: '',
				thumb: img.image_url
			};

			this._albums.push(album);
		}
	}

	openLightbox(index?: number): void {
		this._lightbox.open(this._albums, index || this.selectedCoverImage);
	}
	onImgError(event) {
		event.target.src = 'http://via.placeholder.com/320x320.png'
		//Do other stuff with the event.target
	}
}
