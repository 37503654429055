import { Component, Input, OnInit } from '@angular/core';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  @Input('title') title: any;
  @Input('breadcrumb') breadcrumb: any[] = [];
  public selectedLanguage = "en";

  constructor(private languageService: LanguageService) { }

  ngOnInit(): void {
    let loadLang = this.languageService.GetLanguage() + '';
    if (loadLang == "null" || loadLang == "") this.selectedLanguage = "en";
    else this.selectedLanguage = loadLang;

    this.languageService.languageUpdates.subscribe(
      (lang) => {
        this.selectedLanguage = lang;
      });
  }

}
