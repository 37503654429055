import { Component, HostListener, OnInit, Output, EventEmitter, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import * as confetti from 'canvas-confetti';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  @ViewChild('canvas', {static : true}) public canvasElement: ElementRef;
  isDesktopView: boolean = window.screen.width > 1024;
  @Output() public closeEmit = new EventEmitter();


  @HostListener('window:resize', ['$event'])
  handleResize(event: KeyboardEvent) {
    this.isDesktopView = window.screen.width > 1024;
  }

  constructor(
    private renderer2: Renderer2,
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.surprise();
  }

  public surprise(): void {

    const canvas = this.renderer2.createElement('canvas');

    this.renderer2.appendChild(this.canvasElement.nativeElement, canvas);

    const myConfetti = confetti.create(canvas, {
      resize: true // will fit all screen sizes

    });

    myConfetti({
      // angle: randomInRange(55, 125),
      spread: 130,
      particleCount: 100,
      origin: { y: 0.6 },
      shapes: ['circle', 'square'],
      colors: ['#FF6D2E', '#F8B54A', '#F44274', '#6DD0E7', '#F71873', '#6DD0E7'],
      gravity : 3
      // any other options from the global
      // confetti function
    });
  }



  public back() {

    this.router.navigateByUrl('/');
    this.closeWelcome();
  }

  closePopup() {
    this.closeEmit.emit("true");
    this.closeWelcome();
  }

  closeWelcome() {
    this.userService.updateWelcomeStatus().subscribe((res: any) => {

      this.router.navigateByUrl('/');
    }, (err) => {

      this.router.navigateByUrl('/');
    })
  }


}
