
<div class="popular_widget_main">
    <div class="trending_header">
        <span>
            {{"My Homes" | translate}}
        </span>
        <button class="btn-right" (click)="goToSpace()">{{"Add home" | translate}}</button>
    </div>

    <div class="swiper_area">
        <swiper #swiper [config]="config" (slideChange)="onSlideChange()">


            <ng-container *ngIf="mySpaces.length == 0">
                <ng-template swiperSlide>
                    <div class="popular_widget_item">
                        <div class="card tokens_card pointer" (click)="goToSpace()">
                            <img class="half_ring" src="/assets/images/half_ring.png" alt="">
                            <p class="text-orange text-18 font-bold top_paragraph lh-16">
                                {{"Add a home, apartment or even just your bedroom and earn HS Points!" | translate}}
                            </p>
                            <p class="font-bold mb-60">{{"Become a host in a few simple steps" | translate}}</p>
                            <p class="text-orange para3">{{"Add Home" | translate}}</p>
                            <img class="home_image" [class.arabic] = "selectedLanguage == 'ar'" src="/assets/images/explore_card_home.png" alt="">
                            <img class="token_12" [class.arabic] = "selectedLanguage == 'ar'" src="/assets/images/token_12.png" alt="">
                            <img class="token_15" [class.arabic] = "selectedLanguage == 'ar'" src="/assets/images/token_15.png" alt="">
                            <img class="token_22" [class.arabic] = "selectedLanguage == 'ar'" src="/assets/images/token_22.png" alt="">
                        </div>
                    </div>
                </ng-template>
                <ng-template swiperSlide>
                    <div class="popular_widget_item">
                        <div class="card tokens_card pointer" (click)="goToTokenPurchase()">
                            <img class="half_ring" src="/assets/images/half_ring.png" alt="">
                            <p class="top_paragraph">
                                {{"HS Points are the in app currency of Holiday Swap allowing you to travel the world for much less." | translate}}
                            </p>
                            <p class="text-orange font-bold mb-60 para2">{{"1 HS Point = $1" | translate}}</p>
                            <p class="text-orange para3">{{"My HS Points" | translate}}</p>
                            <img class="girl_image" [class.arabic] = "selectedLanguage == 'ar'" src="/assets/images/girl_img_2x.png" alt="">
                            <img class="token_image" [class.arabic] = "selectedLanguage == 'ar'" src="/assets/images/explore_card_token.png" alt="">
                            <img class="dot_arrow" [class.arabic] = "selectedLanguage == 'ar'" src="/assets/images/dot-arrow.png" alt="">
                        </div>
                    </div>
                </ng-template>
            </ng-container>
        </swiper>
    </div>


    <div class="button_footer">
        <button class="btn-block" (click)="goToSpace()">{{"Add home" | translate}}</button>
    </div>

</div>
