<div class="sprite">
	<svg>
		<defs>

			<symbol id="visa-icon">
				<g>
					<path class="st0"
						d="M126,82H2c-1.1,0-2-0.9-2-2V2c0-1.1,0.9-2,2-2h124c1.1,0,2,0.9,2,2v78C128,81.1,127.1,82,126,82z" />
					<g>
						<polygon class="st1" points="47.3,56.6 52.6,25.5 61.1,25.5 55.8,56.6 		" />
						<path class="st1" d="M86.5,26.2c-1.7-0.6-4.3-1.3-7.6-1.3c-8.4,0-14.3,4.2-14.3,10.3c0,4.5,4.2,7,7.4,8.5c3.3,1.5,4.4,2.5,4.4,3.9
			  c0,2.1-2.6,3-5.1,3c-3.4,0-5.2-0.5-8-1.6l-1.1-0.5l-1.2,7c2,0.9,5.6,1.6,9.4,1.7c8.9,0,14.7-4.2,14.8-10.6c0-3.5-2.2-6.2-7.1-8.5
			  c-3-1.4-4.8-2.4-4.8-3.9c0-1.3,1.5-2.7,4.9-2.7c2.8,0,4.8,0.6,6.3,1.2l0.8,0.4L86.5,26.2" />
						<path class="st1"
							d="M108.3,25.5h-6.6c-2,0-3.5,0.6-4.4,2.6L84.7,56.6h8.9c0,0,1.5-3.8,1.8-4.7c1,0,9.6,0,10.9,0
			  c0.3,1.1,1,4.7,1,4.7h7.9L108.3,25.5 M97.8,45.6c0.7-1.8,3.4-8.7,3.4-8.7c-0.1,0.1,0.7-1.8,1.1-3l0.6,2.7c0,0,1.6,7.4,2,9H97.8z" />
						<path class="st1"
							d="M40.2,25.5l-8.3,21.2L31,42.4c-1.5-5-6.4-10.4-11.7-13.1l7.6,27.2l9,0l13.4-31.1H40.2" />
						<path class="st2"
							d="M24.2,25.5H10.5l-0.1,0.6C21,28.7,28.1,34.9,31,42.4l-3-14.3C27.5,26.1,26,25.6,24.2,25.5" />
					</g>
				</g>
			</symbol>

			<symbol id="mc-icon">
				<g>
					<path class="st0"
						d="M126,82H2c-1.1,0-2-0.9-2-2V2c0-1.1,0.9-2,2-2h124c1.1,0,2,0.9,2,2v78C128,81.1,127.1,82,126,82z" />
					<g>
						<path class="st1" d="M54.4,41c0-8,3.8-15.1,9.6-19.7C59.8,18,54.4,16,48.6,16c-13.8,0-25,11.2-25,25s11.2,25,25,25
			  c5.8,0,11.2-2,15.4-5.3C58.2,56.1,54.4,49,54.4,41z" />
						<path class="st2" d="M79.4,16c-5.8,0-11.2,2-15.4,5.3c5.8,4.6,9.6,11.7,9.6,19.7S69.8,56.1,64,60.7C68.2,64,73.6,66,79.4,66
			  c13.8,0,25-11.2,25-25S93.2,16,79.4,16z" />
						<path class="st3"
							d="M73.6,41c0-8-3.8-15.1-9.6-19.7C58.2,25.9,54.4,33,54.4,41s3.8,15.1,9.6,19.7C69.8,56.1,73.6,49,73.6,41z" />
					</g>
				</g>
			</symbol>

			<symbol id="amex-icon">
				<g>
					<path class="st0"
						d="M126,82H2c-1.1,0-2-0.9-2-2V2c0-1.1,0.9-2,2-2h124c1.1,0,2,0.9,2,2v78C128,81.1,127.1,82,126,82z" />
					<g>
						<path class="st1"
							d="M21.7,27.4L9.4,54.6h14.7l1.8-4.3h4.2l1.8,4.3h16.2v-3.3l1.4,3.3h8.4l1.4-3.4v3.4h33.8l4.1-4.2l3.8,4.2
			  l17.3,0l-12.4-13.6l12.4-13.7h-17.1l-4,4.2l-3.7-4.2H57.1l-3.2,7l-3.2-7H36v3.2l-1.6-3.2C34.3,27.4,21.7,27.4,21.7,27.4z
			   M24.6,31.2h7.2L40,49.7V31.2h7.9l6.3,13.3L60,31.2h7.8v19.6H63l0-15.3l-7,15.3h-4.3l-7-15.3v15.3H35l-1.9-4.4h-10l-1.9,4.4H16
			  C16,50.8,24.6,31.2,24.6,31.2z M72.2,31.2h19.4l5.9,6.4l6.1-6.4h5.9l-9,9.8l9,9.7h-6.2l-5.9-6.5l-6.2,6.5H72.2L72.2,31.2
			  L72.2,31.2z M28.1,34.5l-3.3,7.8h6.6L28.1,34.5z M76.9,35.3v3.6h10.6v4H76.9v3.9h11.9l5.5-5.7L89,35.3L76.9,35.3L76.9,35.3z" />
					</g>
				</g>
			</symbol>

			<symbol id="disc-icon">
				<g>
					<path class="st0"
						d="M113.5,29.1h-1.1v5.6h1.1c2.3,0,3.5-1,3.5-2.9C117,30.1,115.8,29.1,113.5,29.1z" />
					<path class="st0" d="M11.7,29.4h-1v12.2h1c2.3,0,3.8-0.4,4.9-1.5c1.2-1.1,2-2.9,2-4.7c0-1.8-0.7-3.5-2-4.6
		  C15.4,29.8,14,29.4,11.7,29.4z" />
					<path class="st0" d="M126,0H2C0.9,0,0,0.9,0,2v78c0,1.1,0.9,2,2,2h27.9C93.4,71.1,128,46.6,128,46.6V2C128,0.9,127.1,0,126,0z
		   M19,42.6c-1.8,1.5-3.8,2.1-6.6,2.1H7.1V26.2h5.3c5.8,0,9.9,3.8,9.9,9.3C22.3,38.2,21.1,40.8,19,42.6z M27.6,44.7H24V26.2h3.6V44.7
		  z M35.3,45.2c-2.7,0-4.7-1.1-6.4-3.5l2.3-2.2c0.8,1.6,2.2,2.5,3.9,2.5c1.6,0,2.8-1.1,2.8-2.6c0-0.8-0.4-1.4-1.1-1.9
		  c-0.4-0.2-1.1-0.6-2.5-1.1c-3.4-1.2-4.5-2.5-4.5-5.1c0-3,2.5-5.3,5.8-5.3c2,0,3.9,0.7,5.4,2l-1.9,2.5c-0.9-1.1-1.8-1.5-2.9-1.5
		  c-1.6,0-2.7,0.9-2.7,2c0,1,0.6,1.5,2.8,2.3c4.1,1.5,5.3,2.8,5.3,5.8C41.7,42.7,39.1,45.2,35.3,45.2z M57.1,31.2
		  c-1.5-1.5-2.8-2.1-4.5-2.1c-3.4,0-6,2.8-6,6.3c0,3.7,2.6,6.4,6.2,6.4c1.6,0,2.9-0.6,4.4-2v4.2c-1.6,0.8-3,1.1-4.5,1.1
		  c-5.5,0-9.8-4.2-9.8-9.6c0-5.4,4.4-9.7,9.8-9.7c1.6,0,2.9,0.3,4.4,1.1V31.2z M68.1,45.4c-5.4,0-9.8-4.4-9.8-9.8s4.4-9.8,9.8-9.8
		  c5.4,0,9.8,4.4,9.8,9.8S73.5,45.4,68.1,45.4z M86.6,45.2h-1.9l-7.8-19h3.9l4.9,12.4l5-12.4h3.9L86.6,45.2z M106.4,29.4h-6.6v4.1
		  h6.4v3.1h-6.4v5h6.6v3.1H96.2V26.2h10.2V29.4z M117.9,44.7l-5-7.4h-0.5v7.4h-3.6V26.2h5.3c4.2,0,6.5,2,6.5,5.5
		  c0,2.8-1.5,4.7-4.2,5.2l5.8,7.8H117.9z" />
					<circle class="st0" cx="68.1" cy="35.5" r="9.8" />
					<circle class="st1" cx="68.1" cy="35.5" r="9.8" />
					<path class="st0" d="M126,82c1.1,0,2-0.9,2-2V46.6c0,0-34.6,24.4-98.1,35.4H126z" />
					<path class="st1" d="M126,82c1.1,0,2-0.9,2-2V46.6c0,0-34.6,24.4-98.1,35.4H126z" />
					<path class="st2"
						d="M12.4,26.2H7.1v18.5h5.3c2.8,0,4.8-0.7,6.6-2.1c2.1-1.7,3.4-4.4,3.4-7.1C22.3,30,18.2,26.2,12.4,26.2z
		   M16.6,40.1c-1.1,1-2.6,1.5-4.9,1.5h-1V29.4h1c2.3,0,3.7,0.4,4.9,1.5c1.2,1.1,2,2.8,2,4.6C18.6,37.2,17.8,39,16.6,40.1z" />
					<rect x="24" y="26.2" class="st2" width="3.6" height="18.5" />
					<path class="st2" d="M36.4,33.3c-2.2-0.8-2.8-1.3-2.8-2.3c0-1.2,1.1-2,2.7-2c1.1,0,2,0.4,2.9,1.5l1.9-2.5c-1.5-1.4-3.4-2-5.4-2
		  c-3.3,0-5.8,2.3-5.8,5.3c0,2.5,1.2,3.8,4.5,5.1c1.4,0.5,2.1,0.8,2.5,1.1c0.7,0.5,1.1,1.1,1.1,1.9c0,1.5-1.2,2.6-2.8,2.6
		  c-1.7,0-3.1-0.9-3.9-2.5l-2.3,2.2c1.7,2.4,3.7,3.5,6.4,3.5c3.7,0,6.4-2.5,6.4-6.1C41.7,36.2,40.5,34.8,36.4,33.3z" />
					<path class="st2" d="M42.8,35.5c0,5.4,4.3,9.6,9.8,9.6c1.6,0,2.9-0.3,4.5-1.1v-4.2c-1.4,1.4-2.7,2-4.4,2c-3.6,0-6.2-2.6-6.2-6.4
		  c0-3.5,2.7-6.3,6-6.3c1.7,0,3,0.6,4.5,2.1V27c-1.6-0.8-2.9-1.1-4.4-1.1C47.2,25.8,42.8,30.1,42.8,35.5z" />
					<polygon class="st2"
						points="85.7,38.6 80.8,26.2 76.8,26.2 84.7,45.2 86.6,45.2 94.6,26.2 90.7,26.2 	" />
					<polygon class="st2" points="96.2,44.7 106.4,44.7 106.4,41.6 99.8,41.6 99.8,36.6 106.2,36.6 106.2,33.5 99.8,33.5 99.8,29.4 
		  106.4,29.4 106.4,26.2 96.2,26.2 	" />
					<path class="st2"
						d="M120.7,31.7c0-3.5-2.4-5.5-6.5-5.5h-5.3v18.5h3.6v-7.4h0.5l5,7.4h4.4l-5.8-7.8
		  C119.2,36.4,120.7,34.5,120.7,31.7z M113.5,34.7h-1.1v-5.6h1.1c2.2,0,3.5,0.9,3.5,2.7C117,33.7,115.8,34.7,113.5,34.7z" />
				</g>
			</symbol>
			<symbol id="diners-icon">
				<g transform="translate(0.000000,16.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M98 138 c45 -22 41 -99 -5 -117 l-28 -11 30 5 c34 7 65 38 65 67 0
24 -44 68 -67 67 -15 0 -14 -2 5 -11z"/>
<path d="M35 98 c-4 -10 -4 -26 0 -35 8 -17 8 -17 12 0 3 9 3 25 0 35 -4 16
-4 16 -12 0z"/>
<path d="M91 80 c0 -33 1 -34 11 -12 8 17 8 26 0 34 -9 9 -12 4 -11 -22z"/>
</g>
			</symbol>

		</defs>
	</svg>
</div>


<span *ngIf="brand === 'visa'" class="cc-sm">
	<svg viewBox="0 0 128 82" class="icon shape-codepen">
		<use xlink:href="#visa-icon"></use>
	</svg>
</span>


<span *ngIf="brand === 'mastercard'" class="cc-sm">
	<svg viewBox="0 0 128 82" class="icon shape-codepen">
		<use xlink:href="#mc-icon"></use>
	</svg>
</span>

<span *ngIf="brand === 'amex'" class="cc-sm">
	<svg viewBox="0 0 128 82" class="icon shape-codepen">
		<use xlink:href="#amex-icon"></use>
	</svg>
</span>

<span *ngIf="brand === 'discover'" class="cc-sm">
	<svg viewBox="0 0 128 82" class="icon shape-codepen">
		<use xlink:href="#disc-icon"></use>
	</svg>
</span>

<span *ngIf="brand === 'diners'" class="cc-sm">
	<svg viewBox="0 0 128 82"  class="icon shape-codepen">
		<use xlink:href="#diners-icon" ></use>
	</svg>
</span>