<div class="loading {{ absolute ? 'absolute' : '' }}" *ngIf="loading">
	<div class="spinner">
		<div class="logo">
			<svg width="1024px" height="1024px" viewBox="0 0 113 99" version="1.1" xmlns="http://www.w3.org/2000/svg"
				xmlns:xlink="http://www.w3.org/1999/xlink">
				<title>Group 5@1x</title>
				<g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
					<g id="Group-5">
						<polygon id="Fill-1" fill="#F29000"
							points="56.4671512 98.4529581 112.578221 26.2249349 71.8009884 0.453051163"></polygon>
						<polygon id="Fill-2" fill="#FED900"
							points="40.9330674 0.000262790698 0.000788372093 26.1137744 56.453486 98.4626814 56.453486 98.4574256 56.4587419 98.4521698 41.0145326 0.0607046512">
						</polygon>
						<polygon id="Fill-3" fill="#F29000"
							points="56.4576907 98.4529581 56.4734581 98.4398186 56.5365279 12.604493 56.4471791 12.6754465 40.9320163 0.00105116279">
						</polygon>
						<polygon id="Fill-4" fill="#E84C1E"
							points="56.4482302 12.6749209 56.4666256 98.4471767 71.8004628 0.455153488"></polygon>
					</g>
				</g>
			</svg>
		</div>
		<!--div class="bounce1"></div>
		<div class="bounce2"></div>
		<div class="bounce3"></div-->
	</div>
</div>