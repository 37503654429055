import {
  Component,
  OnInit,
  EventEmitter,
  NgZone,
  Output,
  Input,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ISocialLoginRequest } from 'src/app/shared/interfaces/auth';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';
import firebase from 'firebase/compat';
import FacebookAuthProvider = firebase.auth.FacebookAuthProvider;
import { ClevertapService } from 'src/app/shared/services/clevertap.service';
import { UserService } from 'src/app/shared/services/user.service';
import { LanguageService } from 'src/app/shared/services/language.service';

declare const gapi;
declare const FB;

@Component({
  selector: 'app-social-sign-in',
  templateUrl: './social-sign-in.component.html',
  styleUrls: ['./social-sign-in.component.scss'],
})
export class SocialSignInComponent implements OnInit {
  @Output() onCallback = new EventEmitter();
  @Input() buttons: boolean = false;
  @Input() hiddenGoggle: boolean = false;
  @Input() hiddenApple: boolean = false;
  public loading = false;
  public ios = false;
  private auth2;
  public userDevice: any;
  public redirect_to: string;
  logged = false;
  public selectedLanguage = 'en';

  constructor(
    private authService: AuthService,
    private ngZone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    private clevertapService: ClevertapService,
    private userService: UserService,
    private languageService: LanguageService,
  ) {
    if (localStorage.getItem('redirect_to')) {
      this.redirect_to = localStorage.getItem('redirect_to');
    }
  }

  ngOnInit(): void {
    this.loading = true;
    this.route.queryParams.subscribe((params) => {
      if (params.code) {
        this._onLogin({
          provider: 'facebook',
          provider_access_token: params.code,
        });
      }
    });

    let loadLang = this.languageService.GetLanguage() + '';
    if (loadLang == 'null' || loadLang == '') this.selectedLanguage = 'en';
    else this.selectedLanguage = loadLang;

    this.languageService.languageUpdates.subscribe((lang) => {
      this.selectedLanguage = lang;
    });

    this.getOS();
  }

  public onFacebook() {
    const redirect_uri = `${environment.base}/auth/login`;
    if (
      window.navigator.userAgent.match(/iPad/i) ||
      window.navigator.userAgent.match(/iPhone/i)
    )
      window.open(
        `https://www.facebook.com/dialog/oauth?client_id=${
          environment.social.facebook
        }&redirect_uri=${encodeURIComponent(
          redirect_uri,
        )}&state=test&scope=email,public_profile`,
        '',
        null,
      );
    else
      window.location.href = `https://www.facebook.com/v12.0/dialog/oauth?client_id=${
        environment.social.facebook
      }&redirect_uri=${encodeURIComponent(
        redirect_uri,
      )}&state=test&scope=email,public_profile`;
  }

  public onGoogle() {
    // this.logged = true
    this.loading = true;
    this.auth2.signIn();
  }

  public async onApple() {
    try {
      this.loading = true;
      const response = await (window as any).AppleID.auth.signIn();

      this._onLogin({
        provider: 'apple',
        provider_access_token: response.authorization.id_token,
        first_name: response?.user?.firstName,
        last_name: response?.user?.lastName,
        email: response?.user?.email,
      });
    } catch (err) {
    } finally {
      this.loading = false;
    }
  }

  public iOS() {
    /*return [
			"iPad Simulator",
			"iPhone Simulator",
			"iPod Simulator",
			"iPad",
			"iPhone",
			"iPod"
		].includes(navigator.platform)
			// iPad on iOS 13 detection
			|| (navigator.userAgent.includes("Mac") && "ontouchend" in document);*/
  }

  loadExternalScripts() {
    let node = document.createElement('script');
    node.src =
      'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
    node.type = 'text/javascript';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
    return node;
  }

  ngAfterViewInit() {
    this.loadExternalScripts().onload = () => {
      // this._handleGoogleLogin(document.getElementById("google-login"));
      (window as any).AppleID.auth.init({
        clientId: 'com.holidayswap.website',
        scope: 'name email',
        redirectURI: `${environment.base}/auth/login`,
        state: 'origin:web',
        usePopup: true,
      });
    };

    gapi.load('auth2', () => {
      const instance = gapi.auth2.getAuthInstance();

      if (instance) {
        this.auth2 = instance;
      } else {
        this.auth2 = gapi.auth2.init({
          client_id: environment.social.google,
          cookie_policy: 'none',
          ux_mode: window.navigator.userAgent == 'random' ? 'redirect' : 'popup',
        });
      }

      this.auth2.isSignedIn.listen((status) => {
        if (!status) {
          return;
        }
        this.ngZone.run(() => {
          this._onLogin({
            provider: 'google',
            provider_access_token: gapi.auth2
              .getAuthInstance()
              .currentUser.get()
              .getAuthResponse().id_token,
          });
        });
      });
    });
  }

  private _onLogin(data: ISocialLoginRequest) {
    this.loading = true;
    this.logged = true;
    this.authService.socialLogin(data).subscribe(
      (response: any) => {
        if (response.existingUser == true) {
          if (this.auth2) {
            this.auth2.signOut();
          }
          // this.getUserData()
          localStorage.setItem('sociallyLoggedin', '1');
          if (this.redirect_to != null && this.redirect_to != 'undefined') {
            this.router.navigate([this.redirect_to], {
              queryParams: this.route.snapshot.queryParams,
              queryParamsHandling: 'merge',
            });
          } else {
            this.router.navigate(['/'], {
              queryParams: this.route.snapshot.queryParams,
              queryParamsHandling: 'merge',
            });
          }
        } else {
          if (this.auth2) {
            this.auth2.signOut();
          }
          localStorage.setItem('sociallyLoggedin', '1');
          localStorage.setItem('verifyLocalMin', JSON.stringify(2));
          localStorage.setItem('verifyLocalSec', JSON.stringify(0));
          // Email Verify redirect
          localStorage.setItem('email_verified', 'true');

          this.router.navigate(['/auth/social-loginsignUp'], {
            queryParams: this.route.snapshot.queryParams,
            queryParamsHandling: 'merge',
          });
        }
        // this.router.navigate(["/"]);
        // this.router.navigate([!response.onboarding ? "/setup" : "/dashboard"]);
        // this.router.navigate([response.has_onboarded ? "/dashboard" : "/onboarding"]);
      },
      (err) => {
        this.logged = false;
        this.loading = false;
        if (this.auth2) {
          this.auth2.signOut();
        }
      },
    );
  }

  getOS() {
    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }

    this.userDevice = os;
  }

  // getUserData(responseLoginData) {
  // 	this.userService.getProfile(responseLoginData.user_id).subscribe((response: any) => {
  // 		if (Object.keys(response.data).length > 0) {
  // 			let userData = response.data;

  // 			this.clevertapService.setClevertap_id();
  // 			this.clevertapService.userProfileLogin(userData, true);
  // 			this.clevertapService.updateClevertapId(this.clevertapService.getClevertap_id());
  // 		}
  // 	}, (err) => {

  // 	}).add((() => {
  // 		this.loading = false;
  // 	}));
  // }
}
