import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root"
})
export class MapboxService {
	public markers = new BehaviorSubject<{ type?: string, markers?: any[] }>({ markers: [] });
	constructor(private http: HttpClient) { }

	public search(path) {
		const uri = `${environment.mapbox.endpoint}/${encodeURI(path)}`;
		return this.http.get(`${uri}.json?types=country,region,address,district,locality,neighborhood,place&access_token=${environment.mapbox.key}&cachebuster=1562139794033&autocomplete=true`).pipe(
			catchError(this.handleError), map((res) => res),
		);
	}

	public searchAddress(path) {
		const uri = `${environment.mapbox.endpoint}/${encodeURI(path)}`;
		return this.http.get(`${uri}.json?access_token=${environment.mapbox.key}&cachebuster=1562139794033&autocomplete=true`).pipe(
			catchError(this.handleError), map((res) => res),
		);
	}

	public searchByCountry(path) {
		const uri = `${environment.mapbox.endpoint}/${encodeURI(path)}`;
		return this.http.get(`${uri}.json?types=country&access_token=${environment.mapbox.key}&cachebuster=1562139794033&autocomplete=true`).pipe(
			catchError(this.handleError), map((res) => res),
		);
	}


	public parseResult(feature) {
		const address: any = {};
		for (const result of feature.context) {
			if (result.id.includes("postcode")) {
				address.postcode = result.text;
			}
			if (result.id.includes("locality")) {
				address.line_2 = result.text;
			}
			if (result.id.includes("place")) {
				address.city = result.text;
			}
			if (result.id.includes("district")) {
				address.county = result.text;
			}
			if (result.id.includes("country")) {
				address.country = result.text;
			}

			if (result.id.includes("postcode")) {
				address.postcode = result.text;
			}
		}
		// First line of address
		if (feature.text || feature.address) {
			address.street = (feature.address ? feature.address + " " : "") + (feature.text ? feature.text : "");
		}

		if (feature.id.includes("place")) {
			address.city = feature.text;
		}
        if (feature.id.includes("region")) {
            address.city = feature.text;
        }
		// Full Address
		if (feature.place_name) {
			address.full_address = feature.place_name;
		}
		// Coordinates
		if (feature.center) {
			address.longitude = feature.center[0];
			address.latitude = feature.center[1];
		}

		return address;

	}

	private handleError(error: HttpErrorResponse) {
		if (error.error instanceof ErrorEvent) {
		} else {
			return throwError(
				error.error.message);
		}
		return throwError(
			"Something bad happened; please try again later.");
	}

}
