import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from "ngx-cookie-service";

declare var $: any;

const DEFAULT_COOKIE_TYPES = [
  'necessary',
  'performance',
  'functional',
  'targeting'
]

@Component({
  selector: 'app-cookie-handler',
  templateUrl: './cookie-handler.component.html',
  styleUrls: ['./cookie-handler.component.scss']
})
export class CookieHandlerComponent implements OnInit {
  public _visible = false;
  public active_accordion = "";
  public show_setting_modal = false;
  public ipAddress = "";
  public cookieTypes: string[] = DEFAULT_COOKIE_TYPES;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
  ) { }

  ngOnInit(): void {
    this.http.get("https://api.ipify.org?format=json").subscribe((res: any) => {
      if (res?.data?.ip) {
        this.ipAddress = res.data.ip;
      }
    });

    const cookie = this.cookieService.get("holiday_cookie");
    if (!cookie) this._visible = true;
  }

  public open() {
    this._visible = true;
    $('.app-navigation').addClass("hide");
  }

  public close() {
    this._visible = false;
    $('.app-navigation').removeClass("hide");
  }

  public handleChangeOption(event: any) {
    const { name, checked: value } = event.target;
    if (value) {
      this.cookieTypes = [...this.cookieTypes, name];
    } else {
      this.cookieTypes = [...this.cookieTypes.filter(item => item !== name)];
    }
  }

  public handleReject() {
    this.cookieService.set("holiday_cookie", JSON.stringify({}), 100);
    this._visible = false;
    this.show_setting_modal = false;
  }

  public onChangAccordion(key: string) {
    this.active_accordion = this.active_accordion === key ? null : key;
  }

  public toggleCookieSetting() {
    this.show_setting_modal = !this.show_setting_modal;
  }

  public handleSetCookie(acceptAll?: boolean) {
    const payload = {
      'cookie_types': acceptAll ? DEFAULT_COOKIE_TYPES : this.cookieTypes,
      'consent_date': new Date().toDateString(),
      'ip_address': this.ipAddress
    }
    this.cookieService.set("holiday_cookie", JSON.stringify(payload), 10);
    this._visible = false;
    this.show_setting_modal = false;
  }
}
