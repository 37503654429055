import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpService } from './http.service';
import { BehaviorSubject } from 'rxjs';

interface ExploreFilterOptions {
  minPrice?: number;
  maxPrice?: number;
  bedrooms?: number | string;
  bathrooms?: number | string;
  placeTypes?: string;
}

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  form: FormGroup;
  purposeName = 'Both';
  purposeIds = 522;

  exploreFilters$ = new BehaviorSubject<ExploreFilterOptions | null>(null);

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      city: [''],
      hosting_tokens: [500],
      category_ids: [''],
      purpose_ids: [''],
      guests: [''],
      started_at: [''],
      ended_at: [''],
      longitude: [''],
      latitude: [''],
      country: [''],
      sort_by: ['distance'],
    });
  }

  updateExploreFilters(options: ExploreFilterOptions | null) {
    this.exploreFilters$.next(options);
  }
}
