import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthLayoutComponent } from 'src/app/layouts/auth-layout/auth-layout.component';
import { IntroComponent } from './pages/intro/intro.component';
//import { LoginComponent } from "./pages/login/login.component";
import { ResetComponent } from './pages/reset/reset.component';
//import { SignupComponent } from "./pages/signup/signup.component";
import { OnboardingTourIntroComponent } from './pages/onboarding-tour-intro/onboarding-tour-intro.component';
import { RegisterComponent } from './pages/register/register.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { TermsPrivacyServiceComponent } from './terms-privacy-service/terms-privacy-service.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { OnboardingIntroComponent } from './pages/onboarding-intro/onboarding-intro.component';
import { OnboardingNewIntroComponent } from './pages/onboarding-new-intro/onboarding-new-intro.component';
import { VerifyAccountComponent } from './pages/verify-account/verify-account.component';
import { AdventureSelectionComponent } from './pages/adventure-selection/adventure-selection.component';
import { TrendingRegionResolver } from './trending-region-resolver.resolver';
import { CongratsListPremiumComponent } from './pages/congrats-list-premium/congrats-list-premium.component';
import { UpgradeSubscriptionComponent } from './pages/upgrade-subscription/upgrade-subscription.component';
import { AvailableSubscriptionResolver } from './available-subscription-resolver.resolver';
import { SocialloginSignupComponent } from './pages/sociallogin-signup/sociallogin-signup.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'intro',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'intro',
        component: OnboardingNewIntroComponent,
      },
      {
        path: 'intro-new',
        component: OnboardingIntroComponent,
      },
      {
        path: 'login',
        component: SignInComponent,
      },
      {
        path: 'signup',
        component: RegisterComponent,
      },
      {
        path: 'reset',
        component: ResetComponent,
      },
      {
        path: 'verify-account',
        component: VerifyAccountComponent,
      },
      {
        path: 'nearly-there',
        component: AdventureSelectionComponent,
        resolve: { trendingList: TrendingRegionResolver },
      },

      {
        path: 'social-loginsignUp',
        component: SocialloginSignupComponent,
      },
      {
        path: 'congrats-signup',
        component: CongratsListPremiumComponent,
      },
      {
        path: 'upgrade-subscription',
        component: UpgradeSubscriptionComponent,
        resolve: {
          subscriptionList: AvailableSubscriptionResolver,
        },
      },
      // {
      // 	path: "explore-spaces",
      // 	component: ExploreSpacesComponent,
      // },
      // {
      // 	path: "explore-search-result",
      // 	component: ExploreSearchResultComponent,
      // },
      // {
      // 	path: "property-details/:id",
      // 	component: PropertyDetailsComponent,
      // },
      // {
      // 	path: "map-view-spaces",
      // 	component: ResultMapViewSpacesComponent,
      // },
      // {
      // 	path: "register",
      // 	component: RegisterComponent,
      // },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
      },
      {
        path: 'terms-service-policy',
        component: TermsPrivacyServiceComponent,
      },
      // {
      // 	path: "sign-in",
      // 	component: SignInComponent,
      // }
      // {
      // 	path: "explore-home-spaces",
      // 	component: ExploreHomeSpacesComponent,
      // }
    ],
  },
  // {
  // 	path: "onboarding-tour-intro",
  // 	component: OnboardingTourIntroComponent,
  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
