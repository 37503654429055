import {EventEmitter, NgZone, Output} from '@angular/core';
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnInit,
  ViewChild,
  KeyValueDiffer,
  KeyValueDiffers
} from '@angular/core';
import {Router} from '@angular/router';
import {ClevertapService} from 'src/app/shared/services/clevertap.service';
import {GoogleAnalyticsService} from 'src/app/shared/services/google-analytics.service';
import {LanguageService} from 'src/app/shared/services/language.service';
import {SwiperOptions} from 'swiper';
import {SwiperComponent} from 'swiper/angular';
import {UserService} from 'src/app/shared/services/user.service';
import {SpaceService} from 'src/app/shared/services/space.service';
import {SnackbarService} from 'src/app/shared/services/snackbar.service';

declare var $: any;

@Component({
  selector: 'app-my-homes-explore',
  templateUrl: './my-homes.component.html',
  styleUrls: ['./my-homes.component.scss']
})
export class MyHomesComponent implements OnInit {
  @Input() public mySpaces = [];

  public active_homes_count: number = 0;
  @Output() public addHomeClick = new EventEmitter();
  @Output() public editHomeClick = new EventEmitter();
  @Output() public deleteSpaceEmit = new EventEmitter();
  @ViewChild("swiper", {static: false}) swiper?: SwiperComponent;
  public selectedLanguage = "en";
  public activeIndex = 0;
  public loading: boolean = false;
  public spaceId;
  public deviceWidth;
  private user: any = [];
  public modalBackdrop = false;
  isDesktopView: boolean = (window.screen.width > 1024);
  block_btn: boolean = true;
  public email_verified = false;
  id = localStorage.getItem("id")

  @HostListener('window:resize', ['$event'])
  handleResize(event: KeyboardEvent) {
    this.isDesktopView = (window.screen.width > 1024);
  }

  // private dataDiffer: KeyValueDiffer<number, any>;

  public config: SwiperOptions = {
    direction: "horizontal",
    slidesPerView: 1.4,
    spaceBetween: 20,
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    navigation: false,
    pagination: false,
    virtual: false,
    preloadImages: false,
    observer: true,
    // autoHeight: true,

    breakpoints: {
      640: {
        slidesPerView: 3,
      },
      1024: {
        slidesPerView: 3.5,
      },
      1440: {
        slidesPerView: 4,
      },
      1600: {
        slidesPerView: 4.5,
      },
      1800: {
        slidesPerView: 5.5,
      }
    }
  };

  constructor(
    private cd: ChangeDetectorRef,
    private router: Router,
    private clevertapService: ClevertapService,
    private googleAnalyticsService: GoogleAnalyticsService,
    public languageService: LanguageService,
    private userService: UserService,
    private spaceService: SpaceService,
    private snackbarService: SnackbarService,
    private ngZone: NgZone,
    // private differs: KeyValueDiffers,
  ) {


    // this.mySpaces.splice(1, 0, {id : 14444})


    let loadLang = this.languageService.GetLanguage() + '';
    if (loadLang == "null" || loadLang == "") this.selectedLanguage = "en";
    else this.selectedLanguage = loadLang;

    this.languageService.languageUpdates.subscribe(
      (lang) => {
        this.selectedLanguage = lang;
      });

    this.getSpaceCount();

  }

  getSpaceCount() {
    this.userService.getuserSpaceCount().subscribe((res: any) => {
      this.active_homes_count = res.spaces_count;
      this.block_btn = false
    })
  }

  ngOnInit(): void {
    this.calculateWidth()
    this.getMe();
    this.getSpaceCount();

  }

  getMe() {
    this.userService.getMe().subscribe((res) => {
      this.block_btn = false;
      this.user = res.data;
      this.email_verified = res.data.email_verified;
    })
  }

  ngOnChanges(): void {

    this.getSpaceCount();
  }

  ngDoCheck(): void {
    // const changes = this.dataDiffer.diff(this.active_homes_count);
  }

  public calculateWidth() {
    let offsetWidth = document.getElementById("add-home-space-main-head").offsetWidth;

    this.deviceWidth = offsetWidth;
    // this.openHomeDeletePopup();
  }

  navigateToHome(id) {
    if (!id) return;
    let localdata = localStorage.getItem("navUrl");

    if (localdata != "SearchList") {
      localStorage.setItem("navUrl", "Trendings");
      this.router.navigateByUrl("/spaces/edit-home/" + id);
    } else {
      this.router.navigateByUrl("/spaces/edit-home/" + id);
    }

  }

  onSlideChange() {
    // this.activeIndex = this.swiper?.swiperRef.realIndex;
    // this.cd.detectChanges();
  }

  // updateSpaceCount(spaceCount) {
  //   this.block_btn = false;

  //   this.active_homes_count = spaceCount;
  // }

  editSpace(space) {


    let spaceData = {...space}

    this.router.navigate([`/spaces/edit-home/${space.id}`])
    // this.editHomeClick.emit(spaceData);
    // // const navigationExtras = { queryParams: { id: space.id } };
    // let commands;

    // if(this.isDesktopView) {
    //   commands = ['/spaces/add-home/'+space.id]
    // } else {

    //   commands = [
    //     {
    //       outlets: {
    //         modal: ["modal", "spaces", "add-home", space.id],
    //       },
    //     },
    //   ];

    // }


    // // localStorage.setItem('home-page-url', JSON.stringify(this.swap));
    // this.router.navigate(commands);
  }

  public goToSpace() {


    // this.id != 530953 && this.id != 662444 && this.id != 665829
    if (this.active_homes_count >= 30 && this.userService.allowedMaxHomes == '0') {
      this.userService.maxAddHomeReached.next(true)
    } else {
      this.addHomeClick.emit();
    }
    // if (this.mySpaces.length == 0 ) {
    // 	this.router.navigate(["/add-space-intro"]);
    // }
    // else {
    // 	this.router.navigate(["/spaces/add-home"],{ queryParams: { "previousPage": '/'} });
    // }
    this.userService.postAnalytics(this.router.url, "my_homes_section_add_home_clicked").subscribe((res) => {
    });

    // Fire the AppsFlyer event
    (window as any).AF('pba', 'event', {
      eventType: 'EVENT', eventName: 'add_home_button_clicked', eventValue: {
        "screen": "my_homes_section"
      }
    });

    this
      .googleAnalyticsService
      .eventEmitter("LH_LoadImages", "ListHome", "LH_App_Images");


  }

  goToTokenPurchase() {
    // if (!this.email_verified) {
    //   this.userService.verifyMail.next(true);
    //   return;
    // }
  }

  public update(data) {


    // this.mySpaces = data;

    this.ngZone.run(() => {
      this.cd.detectChanges();
      this.swiper.swiperRef.updateSlides();
      this.swiper.swiperRef.updateSize();
      this.swiper.swiperRef.update();
    });


  }

  deleteSpace(space) {
    $('.app-content').addClass('noscroll');
    // document.body.classList.add('no-scroll');
    this.modalBackdrop = true;
    this.spaceId = space?.id
    $('#spacedeleteModal').modal('show');

  }

  closeBackDrop() {
    this.modalBackdrop = false;
    $('.app-content').removeClass('noscroll');
  }

  onDelete() {
    // document.body.classList.remove('no-scroll');
    this.modalBackdrop = false;
    // this.deleteSpaceEmit.emit(this.spaceId);
    $('#spacedeleteModal').modal('hide');
    this.loading = true;
    this.deleteSpaceEmit.emit(this.spaceId);
    this.loading = false;
    $('.app-content').removeClass('noscroll');
    // this.snackbarService.show({
    //   message: 'Home deleted',
    //   type: 'error',
    // });


    // this.spaceService.delete(this.spaceId).subscribe((data) => {
    //     this.getUpdate();
    //     this.loading = false;
    //   }, (err) => {

    //   })
  }

  // getUpdate(){
  //   this.ngZone.run(()=> {
  //     this.getMyHomes();
  //     this.cd.detectChanges();
  //   })
  // }

  //   public getMyHomes() {
  //     this.spaceService.getMySpaces().subscribe((homes: any) => {

  //       this.mySpaces = homes;

  //       if(this.mySpaces.length > 0) {
  //         this.mySpaces.splice(1, 0, {id : null})
  //       }
  // 	  this.update(this.mySpaces);

  //     }),
  //     (error => {
  //       throw new Error(error)
  //     });
  //   }
}
