
<div class="popular_widget_main">
    <div class="widget_header">
        <h2>{{"Popular homes" | translate}}</h2>
        <!-- <span class="view_link"><a>View all</a></span> -->
    </div>

    <div class="swiper_area">

        <swiper #swiper [config]="config" (slideChange)="onSlideChange()">
            <ng-container *ngFor="let space of featuredSpaces; index as i">
                <ng-template swiperSlide>
                    <ng-container>
                      <div class="popular_widget_item">
                          <div class="card">
                              <div class="popular_image">
                              <img class="card-img-top lazyload"  [lazyLoad]="space.ipad_image" (click)="navigateToHome(space.id)">
                              <div class="popular_action">
                                  <ul>
                                      <!-- <li><a><img src="/assets/images/send_ic.svg" ></a></li> -->
                                      <li *ngIf="space?.is_favourited"><a (click)="onLike(space,i)"><i class="fa fa-heart" aria-hidden="true"></i></a></li>
                                      <li *ngIf="!space?.is_favourited"><a (click)="onLike(space,i)"><i class="fa fa-heart-o" aria-hidden="true"></i></a></li>
                                  </ul>
                              </div>
                              </div>
                              <div class="card-body" (click)="navigateToHome(space.id)">
                                  <div class="popular_title">
                                      <h5 class="card-title" *ngIf="space?.title">{{ (space?.title?.length>40)?space?.title?.substring(0,40) :space?.title  }}
                                          {{ (space?.title?.length>40)?"...":""}} </h5>
                                          <h5 class="card-title" *ngIf="!space?.title">  </h5>
<!--                                      <span class="rating_item" *ngIf="space?.rating?.average > 0"><img src="/assets/images/star_ic.svg" >-->
<!--                                        <span class="rating_list">-->
<!--                                          {{space?.rating?.average }}-->
<!--                                        </span>-->
<!--                                      </span>-->
                                  </div>
                                  <div class="popular_des">
                                      <span>{{ space?.bedrooms }} {{ space?.bedrooms > 1? "bedrooms" : "bedroom" |translate }} . {{ space?.guests }}  {{space?.guests >1? "guests" : "guest" | translate}}</span>

                                      <span *ngIf="space?.rating?.total_reviews > 0">{{space?.rating?.total_reviews }} {{space?.rating?.total_reviews == 1 ? "review" : "reviews" | translate}}</span>
                                  </div>
                                  <hr>
                                  <div class="popular_footer">
                                      <span *ngIf="space?.country || space?.city"><img src="/assets/images/mark_gray_ic.svg" >&nbsp;{{space?.country ? space?.country+',' : ' '}} {{space?.city}}</span>
                                      <span class="explore_price" [class.striked]="isPremiumMember" *ngIf="space?.hosting_tokens > 0 && space?.purpose != 'swap'"><img src="/assets/images/Token_Plane.png">
                                        <span>{{ space?.hosting_tokens }}</span>
                                        <!-- <s *ngIf="isPremiumMember">{{ space?.hosting_tokens }}</s> -->
                                    </span>
                                  </div>
                              </div>
                              <div class="offer_footer" *ngIf="space?.hosting_tokens > 0 && space?.purpose != 'swap'">
                                <div class="text_part">
                                    <span>{{ "Subscribed Users" | translate }} &nbsp;&nbsp;&nbsp;</span><p><span class="text_red text_big">20%</span> <span class="text_red">{{ "Discount" | translate }}</span></p>
                                </div>
                                <div class="ammount_part">
                                    <span class="explore_price"><img src="/assets/images/Token_Plane.png">{{ getPersentage(space?.hosting_tokens) }}</span>
                                </div>
                              </div>
                            </div>
                      </div>
                    </ng-container>
                </ng-template>
              </ng-container>
          </swiper>
</div>


</div>
