import { AuthService } from 'src/app/shared/services/auth.service';
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { MessagingService } from 'src/app/core/services/messaging.service';
import { UserService } from 'src/app/shared/services/user.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { NotificationService } from 'src/app/shared/services/notification.service';

import { ClevertapService } from 'src/app/shared/services/clevertap.service';
import { TokenService } from 'src/app/shared/services/token.service';
import { SwapService } from 'src/app/shared/services/swap.service';
import { SpaceService } from 'src/app/shared/services/space.service';
import { WindowScrollingService } from 'src/app/shared/services/window-scrolling.service';
import { PusherService } from 'src/app/shared/services/pusher.service';
import { Channel } from 'pusher-js';
import { ChatService } from 'src/app/shared/services/chat.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { DailyRewardPopupComponent } from '../../shared/components/daily-reward-popup/daily-reward-popup.component';
import { hasUserID } from "src/app/shared/utils/authentication";

declare var $: any;

const excludedRoutes = [
  '/',
  '/map-view-list',
  '/view-home/home-details-ibs',
  '/confirm-and-pay/home-details-ibs',
  '/view-home/confirm-booking/',
  '/view-home/checkout-booking/',
  '/messages/',
  '/settings/tokens',
  '/settings/subscriptions',
];

@Component({
  selector: 'app-public-layout',
  templateUrl: './public-layout.component.html',
  styleUrls: ['./public-layout.component.scss'],
})
export class PublicLayoutComponent implements OnInit {
  isDesktopView: boolean = window.innerWidth > 1024;
  @HostListener('window:resize', ['$event'])
  handleResize(event: KeyboardEvent) {
    this.isDesktopView = window.innerWidth > 1024;
  }

  public tab = 0;
  public showDropProfile = false;
  public width = window.innerWidth;
  public subscription: Subscription;
  selectedMenu: string;
  bdageStatus: boolean;
  inappNotifyBadge: boolean;
  ipAddress: any;
  public reviewPopup = false;
  public channel: Channel;
  public notificationChannel: Channel;
  public clevertapChannel: Channel;
  public selectedLanguage = 'en';
  public notifyBadge = false;
  public toggleZindex = false;
  public tripNotify = false;
  public isPhoneVerified = 0;
  public dashboardActive = false;
  public touringStep = 0;
  subscribeForm: FormGroup;
  public filter: any = {
    purpose_ids: 522,
    // purpose_ids:null,
  };
  loading: boolean = false;
  queryParams = null;
  form: FormGroup;
  hideFooter: boolean = false;
  currentStreak = 1;
  authenticatedHeader: boolean = false;
  hideDesktopNavigationContents: boolean = false;
  @ViewChild('mainWindowScroll', { static: true }) mainWindowScroll: ElementRef;
  @ViewChild('dailyRewardPopup') dailyRewardPopup: DailyRewardPopupComponent;

  constructor(
    public windowScrollingService: WindowScrollingService,
    public router: Router,
    private messagingService: MessagingService,
    private tokenService: TokenService,
    public userService: UserService,
    private httpService: HttpService,
    private notificationService: NotificationService,
    private clevertapService: ClevertapService,
    private swapService: SwapService,
    private spaceService: SpaceService,
    private pusherService: PusherService,
    private chatService: ChatService,
    private authService: AuthService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private cookieService: CookieService
  ) {
    if (
      localStorage.getItem('queryParams') != 'undefined' &&
      typeof localStorage.getItem('queryParams') != undefined
    )
      this.queryParams = JSON.parse(localStorage.getItem('queryParams'));

    this.form = this.fb.group({
      searchinput: [''],
    });

    this.listenToRouteChange();

    this.router.events.subscribe((event) => {
      $('.app-content').removeClass('hide_scroll');
      if (event instanceof NavigationEnd && this.reviewPopup) {
        this.closeReview_popup();
      }
      if (event instanceof NavigationEnd) {
        this.mainWindowScroll.nativeElement.scrollTop = 0;
      }
    });

    this.bdageStatus = false;
    this.inappNotifyBadge = false;
  }

  ngOnInit(): void {
    this.subscribeForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });

    this.queryParams = JSON.parse(localStorage.getItem('queryParams'));
    this.messagingService?.askPermission();
    this.spaceService?.ratingPopup.subscribe((data) => {
      this.toggleZindex = data;
    });
    this.authService.validate();

    this.authService.$loggedIn.subscribe((state) => {
      const token = localStorage.getItem('token');
      this.authenticatedHeader = token ? true : false;
      if (this.authenticatedHeader) {
        const touringStarted = localStorage.getItem('touringStarted');
        if (!touringStarted) {
          this.startTouring();
          localStorage.setItem('touringStarted', 'started');
        }
      }
      if (this.authenticatedHeader) {
        this.userService.getCoins();
        setTimeout(() => {
          this.userService.getDailyStreaks().subscribe((res: any) => {
            if (res.data?.length) {
              this.currentStreak = res.data[res.data.length - 1]?.day ?? 1;
            }
          });
        }, 3000);
      }
    });
    this.chatService.scrollPosition.subscribe((res) => {
      this.mainWindowScroll.nativeElement.scrollTop = 0;
    });

    let userid = localStorage.getItem('id');

    this.channel = this.pusherService.pusher.subscribe(`my-channel${userid}`);
    this.channel.bind('my-event', (res) => {
      if (!this.selectedMenu)
        this.selectedMenu = localStorage.getItem('selectedMenu');
      if (this.selectedMenu != 'inbox') {
        this.bdageStatus = true;
      } else {
        this.bdageStatus = false;
      }
    });

    this.notificationChannel = this.pusherService.pusher.subscribe(
      `notification-channel${userid}`
    );
    this.notificationChannel.bind('inside-notification-event', (res) => {
      this.toastr.success(res.message, res.title);
      this.inappNotifyBadge = true;
    });

    this.form.get('searchinput').valueChanges.subscribe((value) => {
      this.router.navigate(['/search-list']);
    });

    this.getNotifications();
    this.getTripNotifications();

    setInterval(() => {
      this.getTripNotifications();
    }, 60000);

    this.notificationService.updateNotifyBadge.subscribe((badge_status) => {
      this.notifyBadge = badge_status;
      this.inappNotifyBadge = badge_status;
    });

    this.notificationService.updateTripNotifyBadge.subscribe((status) => {
      this.tripNotify = status;
    });
  }

  @HostListener('document:click', ['$event'])
  handleClick(event: Event) {
    const clickedElement = event.target as HTMLElement;
    if (
      !clickedElement.closest('.profile-button') &&
      !clickedElement.closest('.touring') &&
      !clickedElement.closest('.header-section')
    ) {
      this.closeHeader();
      this.touringStep = 0;
    }
  }

  @hasUserID()
  getTripNotifications() {
    this.notificationService.getTripNotifications().subscribe((res: any) => {
      if (res.length > 0) {
        this.tripNotify = true;
      }
    });
  }

  openAuthRewardPopup() {
    if (this.authenticatedHeader) {
      this.router.navigate(['/dashboard']);
    } else {
      this.dailyRewardPopup.openAuthModal();
    }
  }

  @hasUserID()
  getNotifications() {
    this.notificationService.getNotificationStatus().subscribe((res) => {
      if (res > 0) this.notifyBadge = true;
      else this.notifyBadge = false;
    });
  }

  submitSubscribe() {
    const isValid = this.subscribeForm.get('email').valid;
    if (!isValid) {
      this.toastr.error('Please enter valid email');
    } else {
      this.toastr.success('Subscribed successfully');
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.messagingService.getMyToken()) {
        this.httpService.post(`/users/me/fcm-token`, {
          token: this.messagingService.getMyToken(),
        });
      }
    }, 600);

    let userData = null;
    if (localStorage.getItem('userData')) {
      let userDataStorage = localStorage.getItem('userData');
      userData = JSON.parse(userDataStorage);

      if (userData.user_review == '1') {
        this.showReview_popup();
      }
    }
    // 0- normal login, 1- social login, 2- don't execute this condition again

    if (localStorage.getItem('sociallyLoggedin') == '1') {
      localStorage.setItem('sociallyLoggedin', '2');
      this.clevertapService.setClevertap_id();
      this.clevertapService.userProfileLogin(userData, true);
      this.clevertapService.updateClevertapId(
        this.clevertapService.getClevertap_id()
      );
    }
    if (this.authenticatedHeader) {
      const touringStarted = localStorage.getItem('touringStarted');
      if (!touringStarted) {
        this.startTouring();
        localStorage.setItem('touringStarted', 'started');
      }
    }
  }

  public goToPrize() {
    this.router.navigate(['/prizes']);
    this.closeHeader();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  saveMenuSelection(menuName) {
    this.loading = true;
    this.closeHeader();
    localStorage.setItem('deactivAccStatus', '0');

    this.swapService.saveBookingDates(null);
    if (this.selectedMenu != menuName) {
      this.notificationService.removeFriend();
    }

    this.selectedMenu = menuName;
    localStorage.setItem('selectedMenu', this.selectedMenu);

    if (this.selectedMenu == 'inbox') this.bdageStatus = false;

    this.clevertapService.taskbarEvent(menuName);

    if (this.selectedMenu == 'dashboard') {
      this.router.navigate(['/dashboard'], {
        queryParams: this.route.snapshot.queryParams,
        queryParamsHandling: 'merge',
      });
    }

    if (menuName) {
      if (menuName === 'explore') {
        this.router.navigate(['/'], {
          queryParams: this.route.snapshot.queryParams,
          queryParamsHandling: 'merge',
        });
      } else {
        this.router.navigate([menuName], {
          queryParams: this.route.snapshot.queryParams,
          queryParamsHandling: 'merge',
        });
      }
    }

    this.loading = false;
  }

  // ----------------------------------------------------------------------------------------
  showReview_popup() {
    this.reviewPopup = true;
  }

  closeReview_popup() {
    this.reviewPopup = false;
    this.userService.setUserReview('2').subscribe((res) => {});
    this.tokenService
      .bonusTokens('Bonus', 16, 23, 20, 'Bonus on app review')
      .subscribe((res) => {});
    this.userService.totalToken.next(true);
  }

  public showHeader() {
    if (!this.authenticatedHeader) {
      this.dailyRewardPopup.openAuthModal();
      return;
    }
    const headerModal = document.getElementById('header-modal');
    if (headerModal.style.display === 'block') {
      $('#header-modal').css('display', 'none');
    } else {
      $('#header-modal').css('display', 'block');
    }
  }

  public closeHeader() {
    $('#header-modal').css('display', 'none');
  }

  public startTouring() {
    if (!this.authenticatedHeader) {
      this.dailyRewardPopup.openAuthModal();
      return;
    }
    this.touringStep = 1;
    $('#header-modal').css('display', 'block');
  }

  public touringNext() {
    this.touringStep++;
    if (this.touringStep > 3) {
      this.touringStep = 0;
      this.closeHeader();
    }
  }

  listenToRouteChange() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let routerURL = this.router.url;
        if (this.router.url == '/settings/tokens') {
          this.hideDesktopNavigationContents = true;
        } else {
          this.hideDesktopNavigationContents = false;
        }

        if (routerURL.split('?')[0] == '/dashboard-profile') {
          this.dashboardActive = true;
        } else {
          this.dashboardActive = false;
        }

        if (!excludedRoutes.some((route) => this.router.url.includes(route))) {
          this.clearSearchResult();
          this.filter.purpose_ids = 522;
          localStorage.setItem('searchFilters', JSON.stringify(this.filter));
          this.cookieService.set('searchFilter', JSON.stringify(this.filter));
        }
      }
    });
  }

  profileDropdown() {
    this.showDropProfile = !this.showDropProfile;
  }

  logoutUser() {
    this.closeHeader();
    this.notificationService.removeToken();
    let userLang = localStorage.getItem('lang');
    this.userService.stopTimer();
    setTimeout(() => {
      this.authService.clear();
      localStorage.clear();
      localStorage.setItem('lang', userLang);
      this.authenticatedHeader = false;
    }, 600);
  }

  isLinkActive(url) {
    const queryParamsIndex = this.router.url.indexOf('?');
    const baseUrl =
      queryParamsIndex === -1
        ? this.router.url
        : this.router.url.slice(0, queryParamsIndex);
    return baseUrl === url;
  }

  clearSearchResult() {
    localStorage.setItem('searchlocation', '');
    localStorage.setItem('flexibleSelect', 'false');
    this.cookieService.delete('search-address', '/');
    this.cookieService.delete('search-address', '/');
    this.cookieService.delete('search-address');
  }
}
