<div class="combobox">
    <div class="combobox-input" [ngClass]="{'error': showError}" (click)="toggleListDisplay()" >{{(selectedItem ? selectedItem : placeholder) | translate}}</div>
      
      <!-- <span *ngIf="showError" class="error-text">Please select a country</span>  -->
      <span class="down-arrow">
          <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.35072 0.749781C0.575754 0.524816 0.880923 0.398437 1.19912 0.398437C1.51732 0.398437 1.82249 0.524816 2.04752 0.749781L5.99912 4.70138L9.95072 0.749781C10.177 0.531192 10.4802 0.410238 10.7948 0.412972C11.1094 0.415706 11.4104 0.541909 11.6329 0.764399C11.8554 0.986889 11.9816 1.28786 11.9843 1.6025C11.9871 1.91714 11.8661 2.22026 11.6475 2.44658L6.84752 7.24658C6.62249 7.47154 6.31732 7.59792 5.99912 7.59792C5.68092 7.59792 5.37575 7.47154 5.15072 7.24658L0.35072 2.44658C0.125755 2.22155 -0.000623769 1.91638 -0.000623755 1.59818C-0.000623742 1.27998 0.125755 0.974814 0.35072 0.749781Z" fill="#3E3E3E"/>
          </svg>
      </span>
    <div class="combobox-options" *ngIf="!listHidden">
      <input *ngIf="search" type="text" 
          [(ngModel)]="inputItem" 
          (ngModelChange)="getFilteredList()" 
          class="combobox-input-search" 
          (keyup)="onKeyPress($event)" 
          [placeholder]="'Search' | translate" 
          [ngClass]="{'error': showError}"
          autocorrect="off"
          (click)="clearSelected()"
          autocomplete="off" 
          >
          <div class="list-overflow">
            <list-item *ngFor="let item of filteredList;let i = index" 
                (click)="selectItem(i)" 
                [ngClass]="{'selected': item.selected || item.country === selectedItem || item.name === placeholder, 'highlighted': i===selectedIndex}">
                {{item.name | translate}}
            </list-item>
          </div>
    </div>
  </div>