<div class="auth-layout">
	<div class="auth-layout-window">
		<!-- <app-header [back]="canGoBack" [transparent]="true" [actionTemplate]="actionTemplate">
			<ng-template #actionTemplate>
			</ng-template>

		</app-header> -->

		<router-outlet></router-outlet>

	</div>


</div>