import { Component, OnInit , ChangeDetectorRef, EventEmitter, Input, ViewChild , Output } from '@angular/core';
import { SwiperOptions } from "swiper";
import { SwiperComponent } from "swiper/angular";
import { SpaceService } from "src/app/shared/services/space.service";
import { NotificationService } from 'src/app/shared/services/notification.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ClevertapService } from 'src/app/shared/services/clevertap.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { TranslateService } from '@ngx-translate/core';

declare var $: any;

@Component({
  selector: 'app-featured-explore-spaces',
  templateUrl: './featured-explore-spaces.component.html',
  styleUrls: ['./featured-explore-spaces.component.scss']
})
export class FeaturedExploreSpacesComponent implements OnInit {

  @Input() public featuredSpaces = [];
  isPremiumMember:boolean = false;
  userData:any;
  public config: SwiperOptions = {
		direction: "horizontal",
		slidesPerView:1.4,
		spaceBetween: 20,
		keyboard: true,
		mousewheel: true,
		scrollbar: false,
		navigation: false,
		pagination: false,
		virtual: true,
		preloadImages: false,
		observer: true,

		// math = Math;
		breakpoints: {
			640: {
				slidesPerView: 3,
			},
			1024: {
				slidesPerView: 3,
			},
			1440: {
				slidesPerView: 4,
			},
			1600: {
				slidesPerView: 4.5,
			},
			1800: {
				slidesPerView: 5.5,
			}
		}
	};

	private _prevIndex;
  userEmail;
  wishlist = [];
  @ViewChild("swiper", { static: false }) swiper?: SwiperComponent;
  //@Output() public onLikedSpaceCard = new EventEmitter();
  constructor(
	  private cd: ChangeDetectorRef,
	  private spaceService: SpaceService,
	  private notificationService: NotificationService,
	  private userService: UserService,
	  private clevertapService: ClevertapService,
	  private toastr : ToastrService,
	  private googleAnalyticsService: GoogleAnalyticsService,
	  public translate: TranslateService,
    private route:Router) {
		let userFullData = localStorage.getItem("userDataTEST");
		this.userData = JSON.parse(userFullData);
		if(this.userData){
			this.isPremiumMember = this.userData.subscription?.planName!='Lite' && this.userData.subscription?.is_trial == 1;
		}else{
			this.userService.currentUserSubject.subscribe(data=>{
				this.userData = data;
				this.isPremiumMember = this.userData.subscription?.planName!='Lite' && this.userData.subscription?.is_trial == 1;
			})
			// this.isPremiumMember = this.userData.subscription?.planName =='Lite' || this.userData.subscription?.is_trial == 0 ;
		}
	 }

  ngOnInit(): void {
    let userDataStorage = localStorage.getItem("userData");
    let userData = JSON.parse(userDataStorage);



    this.userEmail = userData?.email;
    this.userService.currentUser.subscribe((user) => {
      this.wishlist = user.favourited;
    })

  }

  getPersentage(amount){
	let discount = Math.round(parseInt(amount)*20/100);
	let offerAmt = amount - discount;
	return offerAmt;
  }

  public onSlideChange() {
		const space = this.featuredSpaces[this.swiper.swiperRef.activeIndex];
	//	this.onSlideChanged.emit(space);

		this._prevIndex = this.swiper.swiperRef.activeIndex;
		if (this.featuredSpaces.length === this.swiper.swiperRef.activeIndex + 1) {

		//	this.onEnd.emit();

			$(".popular-regions-main .swiper-slide ").addClass("dynamic-swipe-style");
		}

		else {

			$(".popular-regions-main .swiper-slide ").removeClass("dynamic-swipe-style");
		//	this.isLastSpace = false;
		}

	}

  public update() {
		this.swiper.swiperRef.updateSlides();
		this.swiper.swiperRef.updateSize();

		this.cd.detectChanges();

  }

  public onLike(space,index) {

	this.spaceService.likeSpace(space.id, space.is_favourited).subscribe((response) => {
	//	space.is_favourited = !this.space.is_favourited;
		this.featuredSpaces[index].is_favourited = !space.is_favourited;


		// let message = {
		// 	"title": "Someone liked your space",
		// 	"text": 'Someone added your space in his favourite list'
		// };
		//if (space.is_favourited) this.notificationService.sendFCMNotification(message);


		let _tempuser = {
			"name": (this.notificationService.getSelectedFriend().first_name),
			"email": this.notificationService.getSelectedFriend().email,
			"friendId": this.notificationService.getSelectedFriend().id,
			"userId": this.userService.getCurrentUser().id,
			"title": "Your home was liked!",
			"notificationType": 4,
			"user_identity": this.notificationService.getSelectedFriend().clevertap_identity,
			"clevertap_id": this.notificationService.getSelectedFriend().clevertap_id,
			"message": (this.userService.getCurrentUser().first_name) + " liked your home. See if it's a match!"
		};

		this.clevertapService.sendNotification(_tempuser);

	}, (err) => {
		this.toastr.error(this.translate.instant(err), "Error");

	});
}


  public nextSpaceArrow()
	{
		this.swiper.swiperRef.slideNext(100);
	}

	public previousSpaceArrow()
	{
		this.swiper.swiperRef.slidePrev(100);
	}

  public navigateToHome(id) {
    if(!id) return
	localStorage.setItem("navUrl", "PopularHomes");
	if(this.clevertapService.isWebView){
		this
		.googleAnalyticsService
		.eventEmitter("home_clicks_app", "Clicks", "/view-home/home-details-ibs/"+id);
	}else{
		this
		 .googleAnalyticsService
		 .eventEmitter("home_clicks_web", "Clicks", "/view-home/home-details-ibs/"+id);
	}

    this.route.navigateByUrl("/view-home/home-details-ibs/"+id);
    if(this.wishlist.length <= 0) {
      let searchData = {
        email :this.userEmail
      }
      this.clevertapService.pushCleverTapEvent('wishlist',searchData);
    }
  }

}
