import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-daily-streak',
  templateUrl: './daily-streak.component.html',
  styleUrls: ['./daily-streak.component.scss'],
})
export class DailyStreakComponent implements OnInit {
  @Input() public currentStreak = 1;
  @Output() public goToPrize = new EventEmitter();

  constructor(private router: Router) {}

  ngOnInit(): void {}

  public navigatePrize() {
    this.router.navigate(['prizes']);
    this.goToPrize.emit();
  }
}
