import {
  Component,
  OnInit,
  HostListener,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  NgZone,
  ViewChild,
} from '@angular/core';
import { KeyValueDiffer, KeyValueDiffers } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import * as moment from 'moment';
import { ThemeService } from 'src/app/core/services/theme.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { environment } from 'src/environments/environment';
import { ClevertapService } from '../../services/clevertap.service';
import { LanguageService } from '../../services/language.service';
import { LocationService } from '../../services/location.service';
import { MetadataService } from '../../services/metadata.service';
import { SnackbarService } from '../../services/snackbar.service';
import { SpaceService } from '../../services/space.service';
import { TokenService } from '../../services/token.service';
import { UploadService } from '../../services/upload.service';
import { UserService } from '../../services/user.service';
import { v4 as uuidv4 } from 'uuid';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { GoogleMapViewComponent } from '../google-map-view/google-map-view.component';
import { ComboBoxComponent } from '../combo-box/combo-box.component';
import { CustomAddressMapComponent } from '../custom-address-map/custom-address-map.component';
import { merge } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { countrylist } from '../../services/countrylist.service';
declare var $: any;

export function debounce(delay: number = 300): MethodDecorator {
  return (
    _target: any,
    _propertyKey: string,
    descriptor: PropertyDescriptor
  ) => {
    let timeout = null;

    const original = descriptor.value;

    descriptor.value = function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => original.apply(this, args), delay);
    };

    return descriptor;
  };
}

@Component({
  selector: 'app-edit-home-modal',
  templateUrl: './edit-home-modal.component.html',
  styleUrls: ['./edit-home-modal.component.scss'],
})
export class EditHomeModalComponent implements OnInit {
  @Output() public exitModal = new EventEmitter();
  @Output() public reOpenModal = new EventEmitter();
  @Output() public amenitiesList = new EventEmitter();
  @Input() public editData: any;
  @Input() public formSignUp: boolean = false;
  @Input() public activeStep = 1;
  @Input() public darkTheme = false;
  @Input() public inputstarted = false;
  @Input() public disableOne = true;
  @ViewChild('rtype') rtypeRef: ComboBoxComponent;
  @ViewChild('countriesRef') countriesRef: ComboBoxComponent;

  @ViewChild('pinSpotLocation') pinSpotLocation: CustomAddressMapComponent;
  outsideClick = false;
  serverErrorList = [];
  serverErrors: any = [];
  public showAllAmenities: boolean = false;
  hideAmenitiesButton = false;
  totalSelectedAmenities = 0;
  markedAmenitiesLength = 0;
  markedAccessibilitiesLength = 0;
  marketRulesLength = 0;
  marketSafetiesLength = 0;

  titleFromChatGPT = '';
  chatgptDescriptionReceived: boolean = false;
  descriptionFromChatGPT = '';
  place_type = 565;

  homeTypes: any = [
    {
      name: 'An entire place',
      desc: 'Guests have the whole place to themselves',
      checked: false,
      status: true,
      id: 565,
    },
    {
      name: 'A private room',
      desc: 'Guests sleep in a private room but some areas maybe shared with you or others',
      checked: false,
      status: true,
      id: 566,
    },
    {
      name: 'A shared room',
      desc: 'Guests sleep in a room or common area that may be shared with you or others',
      checked: false,
      status: true,
      id: 567,
    },
  ];

  public selectedCountry: string = '';
  public combainedAddress = '';
  // public disableOne = true;
  public modalBackdrop = false;
  // public inputStarted = false;
  public updatedChanges = true;
  text = '';
  progressWidth = '0';
  public verification: any = {};
  public btn_block = false;
  loading = false;
  description_loading = false;
  selectedAddress = false;
  public completePercentage = 0;
  stepNumber = 1;
  exitClickedOnce = false;
  addressForm: FormGroup;
  homeDetailForm: FormGroup;
  aminitiesForm: FormGroup;
  amenitiesListForm: FormGroup;
  showImg = false;
  isPhoneVerified = false;
  userEmail;
  userData: any;
  isSpaceComplete;
  hideOtpInput = false;
  cropEnabled = false;
  slideBg;
  amountSliderBg;
  sentTime = 0;
  removeImg = false;
  renderderOnce = false;
  patchedOnce = false;
  public validTokenVal = false;
  isEditSwap = true;
  isEditToken = false;
  isEditSwapbtn = true;
  isEditTokenbtn = false;
  // otpConfirmed=false;
  fileName: any;
  resendHide = true;
  onlyToken = false;
  showCropper = false;
  // localEditData:any;
  croppedImage: any = '';
  imageChangedEvent: any = '';
  placeholderImg = 'assets/images/file_up.png';
  public location: any = {};
  settings = {
    fill: '#FFA300',
    background: '#fffaef',
  };

  cardSliderShow = false;
  tokenSliderShow = false;
  original_fileName: any;
  oldFiatBooking: any;
  originalFile: any = '';
  priceLoading: boolean = true;
  fiatActivated: boolean = false;

  isEditMode = false;

  public space: any = {
    id: 0,
    title: '',
    description: '',
    completed_percentage: 0,
    guests: 0,
    images: Array(6)
      .fill(0)
      .map((x) => {
        return {
          id: '',
          tag: '',
          image_path: '',
        };
      }),
    originalImages: Array(6)
      .fill(0)
      .map((x) => {
        return {
          id: '',
          tag: '',
          image_path: '',
        };
      }),
    bedrooms: 0,
    bathrooms: 0,
    amenities: [],
    accessibilities: [],
    atmospheres: [],
    unavailable_dates: [],
    address: {},
    landscapes: [],
    rules: [],
    safeties: [],
    purpose_id: 521,
    hosting_tokens: 49,
    is_instant: 0,
    dates: [],
    isAlreadyAdded: false,
    unavailable_dates_edit: [],
    flat_no: '',
    street: '',
    city: '',
    country: '',
    continent: '',
    postcode: 0,
    latitude: 0,
    longitude: 0,
    state: '',
    postcode_1: '',
    full_address: '',
    street_1: '',
    street_2: '',
    countryIso: '',
    is_hsg_assistant: false,
  };
  resultHide = true;
  public locations = [];
  public outsideViewImage: any = { tag_id: 555, image_url: '' };
  public livingRoomImages: any = { tag_id: 544, image_url: '' };
  public bedRoomImages: any = { tag_id: 541, image_url: '' };
  public kitchenImages: any = { tag_id: 552, image_url: '' };
  public bathRoomImages: any = { tag_id: 542, image_url: '' };
  public guestRoomImages: any = { tag_id: 551, image_url: '' };

  public original_outsideViewImage: any = { tag_id: 555, image_url: '' };
  public original_livingRoomImages: any = { tag_id: 544, image_url: '' };
  public original_bedRoomImages: any = { tag_id: 541, image_url: '' };
  public original_kitchenImages: any = { tag_id: 552, image_url: '' };
  public original_bathRoomImages: any = { tag_id: 542, image_url: '' };
  public original_guestRoomImages: any = { tag_id: 551, image_url: '' };

  excludeCountries: string[] = [
    'AFGHANISTAN',
    'BELARUS',
    'BURUNDI',
    'CENTRAL AFRICAN REPUBLIC',
    'CHAD',
    'CONGO',
    'CRIMEA',
    'CUBA',
    'DEMOCRATIC REPUBLIC OF CONGO',
    'DONETSK PEOPLES REPUBLIC',
    'ERITREA',
    'IRAQ',
    'IRAN',
    'LIBYA',
    'LUGANSK PEOPLES REPUBLIC',
    'MYANMAR',
    'NORTH KOREA',
    'SOMALIA',
    'SOUTH SUDAN',
    'SUDAN',
    'SYRIA',
    'RUSSIA',
    'VENEWZUELA',
    'YEMEN',
    'UKRAINE',
    'VENEZUELA',
  ];

  // public unavailable_dates : any = [];
  clickedImg = {
    id: '',
    tagId: NaN,
    index: NaN,
    imgUrl: '',
  };
  markers = [];
  public selectedLanguage = 'en';
  public categories: any = {};
  private dataDiffer: KeyValueDiffer<string, any>;
  // homeImages=[];

  public remaining: any;
  // private readonly cooldown: number = 60;

  private interval: any;

  isDesktopView: boolean = window.screen.width > 1024;

  public payment_type = {
    cash: false,
    purpose: {
      host: false,
      swap: false,
      all: false,
    },
  };
  public purpose_type: any = [];
  toggle = true;
  status = 'Enable';

  @ViewChild('localtionMap') localtionMap: GoogleMapViewComponent;

  @HostListener('window:resize', ['$event'])
  handleResize(event: KeyboardEvent) {
    this.isDesktopView = window.screen.width > 1024;
  }
  spaceId;
  public countries: any = countrylist;

  markerUpdated: boolean = false;
  markedCategories: any = {};

  constructor(
    public uploadService: UploadService,
    private spaceService: SpaceService,
    private snackbarService: SnackbarService,
    private themeService: ThemeService,
    private locationService: LocationService,
    private languageService: LanguageService,
    private metadataService: MetadataService,
    private tokenService: TokenService,
    public userService: UserService,
    private clevertapService: ClevertapService,
    public fb: FormBuilder,
    private differs: KeyValueDiffers,
    public router: Router,
    private zone: NgZone,
    private cd: ChangeDetectorRef,
    private backlocation: Location,
    public translate: TranslateService,
    private route: ActivatedRoute
  ) {
    this.progressWidth = (this.activeStep / 7) * 100 + '%';
    this.addressForm = this.fb.group({
      city: ['', Validators.pattern(/^[a-zA-Z0-9\s,.-]+$/)],
      country: ['', [Validators.required]],
      state: ['', Validators.pattern(/^[a-zA-Z0-9\s,.-]+$/)],
      full_address: [''],
      latitude: [''],
      longitude: [''],
      postcode: [''],
      postcode_1: ['', [Validators.required]],
      street: [''],
      street_1: [
        '',
        [Validators.required, Validators.pattern(/^[a-zA-Z0-9\s,.-]+$/)],
      ],
      street_2: ['', Validators.pattern(/^[a-zA-Z0-9\s,.-]+$/)],
      title: [
        '',
        [
          Validators.required,
          RxwebValidators.minLength({ value: 10 }),
          RxwebValidators.maxLength({ value: 1000 }),
        ],
      ],
      bathrooms: [
        '',
        [
          Validators.required,
          RxwebValidators.maxNumber({ value: 20 }),
          RxwebValidators.minNumber({ value: 1 }),
          RxwebValidators.numeric({ allowDecimal: false, isFormat: true }),
        ],
      ],
      bedrooms: [
        '',
        [
          Validators.required,
          RxwebValidators.maxNumber({ value: 20 }),
          RxwebValidators.minNumber({ value: 1 }),
          RxwebValidators.numeric({ allowDecimal: false, isFormat: true }),
        ],
      ],
      description: [
        '',
        [
          Validators.required,
          RxwebValidators.minLength({ value: 50 }),
          RxwebValidators.maxLength({ value: 1000 }),
        ],
      ],
      guests: [
        '',
        [
          Validators.required,
          RxwebValidators.maxNumber({ value: 20 }),
          RxwebValidators.minNumber({ value: 1 }),
          RxwebValidators.numeric({ allowDecimal: false, isFormat: true }),
        ],
      ],
    });

    this.homeDetailForm = this.fb.group({
      bathrooms: [
        '',
        [
          Validators.required,
          RxwebValidators.maxNumber({ value: 20 }),
          RxwebValidators.minNumber({ value: 1 }),
        ],
      ],
      bedrooms: [
        '',
        [
          Validators.required,
          RxwebValidators.maxNumber({ value: 20 }),
          RxwebValidators.minNumber({ value: 1 }),
        ],
      ],
      description: [
        '',
        [
          Validators.required,
          RxwebValidators.minLength({ value: 50 }),
          RxwebValidators.maxLength({ value: 1000 }),
        ],
      ],
      guests: [
        '',
        [
          Validators.required,
          RxwebValidators.maxNumber({ value: 20 }),
          RxwebValidators.minNumber({ value: 1 }),
        ],
      ],
      title: [
        '',
        [Validators.required, RxwebValidators.minLength({ value: 10 })],
      ],
    });

    this.aminitiesForm = this.fb.group({
      accessibilities: new FormArray([]),
      amenities: new FormArray([]),
      rules: new FormArray([]),
      safeties: new FormArray([]),
    });

    this.amenitiesListForm = this.fb.group({
      accessibilities: new FormArray([]),
      amenities: new FormArray([]),
      rules: new FormArray([]),
      safeties: new FormArray([]),
    });

    this.setAmenities();

    let loadLang = this.languageService.GetLanguage() + '';
    if (loadLang == 'null' || loadLang == '') {
      this.selectedLanguage = 'en';
    } else {
      this.selectedLanguage = loadLang;
    }
  }

  setAmenities() {
    this.metadataService
      .getSpaceCategories()
      .subscribe((response: { data: any }) => {
        this.categories = response.data;
        this.markedCategories = response.data;

        let amnetiesOverride = this.categories.amenities.map((v) => ({
          ...v,
          isActive: false,
        }));
        this.categories.amenities = amnetiesOverride;
        this.markedCategories.amenities = amnetiesOverride;

        let rulesOverride = this.categories.rules.map((r) => ({
          ...r,
          isActive: false,
        }));
        this.categories.rules = rulesOverride;
        this.markedCategories.rules = rulesOverride;

        let accessibiltyOverride = this.categories.accessibilities.map((r) => ({
          ...r,
          isActive: false,
        }));
        this.categories.accessibilities = accessibiltyOverride;
        this.markedCategories.accessibilities = accessibiltyOverride;

        let safetiesOverride = this.categories.safeties.map((s) => ({
          ...s,
          isActive: false,
        }));
        this.categories.safeties = safetiesOverride;
        this.markedCategories.safeties = safetiesOverride;
      });
  }

  ngOnInit(): void {
    $('.app-navigation').addClass('hide');
    $('.app-content').addClass('addHomeModal');

    this.place_type = this.editData.place_type;

    if (this.editData) {
      this.dataDiffer = this.differs.find(this.editData).create();
    }
    UserService.cacheBuster$.next();
    this.userService.getMe().subscribe((user) => {
      this.userEmail = user.data?.email;
    });
    if (this.editData) {
      this.cd.detectChanges();
      setTimeout(() => {
        this.patchEditdata(this.editData);
      }, 1000);
    }
    this.oldFiatBooking = {
      paymentTypeId: this.space.payment_type_id,
      purposeId: this.space.purpose_id,
    };

    setTimeout(() => {
      if (
        (this.space.payment_type_id === 561 && this.space.purpose_id === 522) ||
        (this.space.payment_type_id === 561 && this.space.purpose_id === 520)
      ) {
        this.fiatActivated = true;
      }
    }, 1000);

    this.userService.isPhoneVerified().subscribe((res: any) => {
      this.isPhoneVerified = res;
    });

    this.userData = JSON.parse(localStorage.getItem('userDataTEST'));
    this.userService.getProgressTrackerDetails().subscribe((response: any) => {
      this.isSpaceComplete = response?.data;
    });

    this.route.params.subscribe((params) => {
      if (params.id) {
        this.spaceId = params.id;
      }
    });

    merge(
      this.addressForm.get('street_1').valueChanges,
      this.addressForm.get('street_2').valueChanges,
      this.addressForm.get('city').valueChanges,
      this.addressForm.get('state').valueChanges,
      this.addressForm.get('postcode_1').valueChanges,
      this.addressForm.get('country').valueChanges
    )
      .pipe(debounceTime(500))
      .subscribe((res) => {
        this.updateMarker();
      });

    merge(
      this.addressForm.get('bedrooms').valueChanges,
      this.addressForm.get('bathrooms').valueChanges,
      this.addressForm.get('guests').valueChanges
    )
      .pipe(debounceTime(500))
      .subscribe((res) => {
        if (
          this.addressForm.dirty &&
          this.addressForm.controls.bedrooms.valid &&
          this.addressForm.controls.bathrooms.valid &&
          this.addressForm.controls.guests.valid &&
          this.addressForm.controls.title.valid &&
          this.addressForm.controls.description &&
          this.space.is_hsg_assistant &&
          this.addressForm.get('bedrooms').value > 0 &&
          this.addressForm.get('bathrooms').value > 0 &&
          this.addressForm.get('guests').value > 0
        ) {
          this.space.bedrooms = this.addressForm.get('bedrooms').value;
          this.space.bathrooms = this.addressForm.get('bathrooms').value;
          this.space.guests = this.addressForm.get('guests').value;
          this.description_loading = true;

          this.spaceService
            .updateType(this.space?.id, this.space)
            .subscribe(
              (response: { data?: any }) => {
                // resolve()
              },
              (error) => {}
            )
            .add(() => {
              this.setHsgtoggleType();
            });
        }
      });
  }

  myAsyncFunction() {
    return new Promise((resolve, reject) => {
      // Perform some asynchronous operation
      this.spaceService.updateType(this.space?.id, this.space).subscribe(
        (response: { data?: any }) => {
          resolve(response);
          // resolve()
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  ngAfterViewInit() {
    this.loadPersentage();
    // setTimeout(() => {
    //   this.sliderTokenUpdate()
    //   this.amountSliderUpdate();
    // }, 5000);
  }
  ngDoCheck(): void {
    if (this.editData) {
      const changes = this.dataDiffer.diff(this.editData);
      if (changes && !this.patchedOnce) {
        this.patchEditdata(this.editData);
        this.patchedOnce = true;
      }
    }
  }

  onFeatureCheckboxChange(event: any, formName) {
    const selectedValues = this.aminitiesForm.controls[formName] as FormArray;
    if (event.target.checked) {
      selectedValues.push(new FormControl(parseInt(event.target.value)));
    } else {
      const index = selectedValues.controls.findIndex(
        (x) => x.value === parseInt(event.target.value)
      );
      selectedValues.removeAt(index);
    }
  }

  get dates() {
    return this.homeDetailForm.get('dates') as FormArray;
  }

  loadPersentage() {
    let persent = (this.space.hosting_tokens / 500) * 100 + '%';
    let amountPersent = (this.space.hosting_amount / 500) * 100 + '%';
    this.slideBg =
      'linear-gradient(90deg, rgb(255, 163, 0)' +
      persent +
      ', rgb(255, 250, 239)' +
      persent +
      ')';
    this.amountSliderBg =
      'linear-gradient(90deg, rgb(28, 201, 26)' +
      amountPersent +
      ', rgb(187,239,186)' +
      amountPersent +
      ')';
  }

  addDates() {
    return this.fb.group({
      id: [''],
      ended_at: [''],
      started_at: [''],
    });
  }

  public onSelect(location) {
    if (
      location.type == 'place' ||
      location.type == 'district' ||
      location.type == 'region' ||
      location.type == 'country'
    ) {
      this.snackbarService.show({
        message: this.translate.instant(
          'Please enter your home street address.'
        ),
        type: 'success',
        config: {
          class: 'centered',
        },
      });
      this.location.full_address = '';
      this.locations = [];
      this.resultHide = true;
    } else {
      this.selectedAddress = true;
      this.locations = [];
      this.location = location;
      this.resultHide = true;
      if (this.markers.length > 0) this.clear();
      this.onAddMarker(location);
      this.flyTo(location);
      this.patchAddress(location);
    }
  }

  // @debounce(300)
  // public autoCompleteAddress(query: string) {
  //   this.resultHide = false;

  //   this.patchAddress(query);

  @debounce(300)
  async autoCompleteAddress(query) {
    this.resultHide = false;

    let _self = this;
    $.each(query?.address_components, function () {
      if (this.types[0] == 'country') {
        let name = this.long_name;
        const excluded = _self.excludeCountries.some((country) =>
          name?.toUpperCase().includes(country.toUpperCase())
        );

        if (excluded) {
          _self.snackbarService.show({
            message: _self.translate.instant(
              'Sorry!  Your country / region is currently not supported by Holiday Swap.'
            ),
            type: 'success',
            config: {
              class: 'centered',
            },
          });
          _self.location = [];
          _self.location.full_address = '';
          _self.locations = [];
          query = '';
          return;
        }
        // address.country = this.long_name;
      }

      // let address : any = {};

      // if(this.types[0] == "country"){
      //   address.country = this.long_name;
      // }
    });

    // const excluded = this.excludeCountries.some(country =>
    //   query?.formatted_address?.toUpperCase().includes(country.toUpperCase())
    // );

    // if (excluded) {
    //   this.snackbarService.show({
    //     message: this.translate.instant("Sorry!  Your country / region is currently not supported by Holiday Swap."),
    //     type: 'success',
    //     config: {
    //       class : 'centered'
    //     }
    //   });
    //   this.location = [];
    //   return;
    // }

    setTimeout(() => {
      if (query?.formatted_address?.length >= 1) {
        let formatted_address = query?.formatted_address;
        this.location.full_address = query?.formatted_address;
        this.resultHide = false;
        // this.addressCicked = true;
        let searchAddressComponents = query?.address_components;
        let searchGeometry = query?.geometry;

        this._patchAddress(
          searchAddressComponents,
          searchGeometry,
          formatted_address
        );
        this.patchAddress(this.location);
        this.onAddMarker(this.location);
        // 	this.resultHide = false;
      } else {
        this.resultHide = true;
        this.locations = [];
        this.location = [];
        this.patchAddress(this.locations);
      }
    }, 3000);
  }

  _patchAddress(searchAddressComponents, geometry, formatted_address) {
    let address: any = {};
    let street_number = '';
    let street_route = '';
    let stateSet: boolean = false;
    $.each(searchAddressComponents, function () {
      if (this.types[0] == 'postal_code') {
        address.postcode = this.long_name;
      }

      if (this.types[0] == 'administrative_area_level_1') {
        address.state = this.long_name;
        // stateSet = true;
      }

      if (this.types[0] == 'administrative_area_level_2') {
        address.state = this.long_name;
        // stateSet = true;
      }

      if (this.types[0] == 'country') {
        address.country = this.long_name;
      }

      if (this.types[0] == 'colloquial_area' && this.types[1] == 'locality') {
        address.city = this.long_name;
      }

      if (this.types[0] == 'sublocality_level_1') {
        // address.city = this.long_name;
        address.line_2 = this.long_name;
      }

      if (this.types[0] == 'postal_town') {
        address.city = this.long_name;
      }

      if (this.types[0] == 'locality') {
        // address.line_2 = this.long_name;
        address.city = this.long_name;
      }

      if (this.types[0] == 'street_number') {
        street_number = this.long_name;
      }
      if (this.types[0] == 'route') {
        street_route = this.long_name;
      }
    });

    address.full_address = formatted_address;
    address.longitude = geometry?.location.lng();
    address.latitude = geometry?.location.lat();

    if (street_number != '' && street_route != '') {
      address.street = street_number + ' ' + street_route;
    } else if (street_number == '' && street_route != '') {
      address.street = street_route;
    }

    if (address?.length > 0) {
      this.locations = address;
      this.location = address;
    }
    this.location = address;

    // return address;
  }

  patchAddress(address) {
    this.addressForm.patchValue({
      city: address.city ? address.city : address.county ? address.county : '',
      country: address.country ? address.country : '',
      state: address.state ? address.state : '',
      full_address: address.full_address ? address.full_address : '',
      latitude: address.latitude ? address.latitude : '',
      longitude: address.longitude ? address.longitude : '',
      postcode: address.postcode ? address.postcode : '',
      postcode_1: address.postcode ? address.postcode : '',
      street: address.street ? address.street : '',
      street_1: address.street ? address.street : '',
      street_2: address.line_2 ? address.line_2 : '',
    });
  }

  private _parseResult(feature) {
    const address: any = {};
    if (feature.place_type[0] == 'place') {
      for (const result of feature.context) {
        if (result.id.includes('postcode')) {
          address.postcode = result.text;
        }
        if (result.id.includes('locality')) {
          address.line_2 = result.text;
        }
        if (result.id.includes('place')) {
          address.city = result.text;
        }
        if (result.id.includes('district')) {
          address.county = result.text;
        }
        if (result.id.includes('region')) {
          address.state = result.text;
        }
        if (result.id.includes('country')) {
          address.country = result.text;
        }
      }
    }
    // First line of address
    if (feature.text || feature.address) {
      address.street =
        (feature.address ? feature.address + ' ' : '') +
        (feature.text ? feature.text : '');
    }

    if (feature.place_type[0] == 'locality') {
      for (const result of feature.context) {
        if (result.id.includes('postcode')) {
          address.postcode = result.text;
        }
        if (result.id.includes('place')) {
          address.city = result.text;
        }
        if (result.id.includes('locality')) {
          address.line_2 = result.text;
        }
        if (result.id.includes('district')) {
          address.county = result.text;
        }
        if (result.id.includes('region')) {
          address.state = result.text;
        }
        if (result.id.includes('country')) {
          address.country = result.text;
        }
      }
    }
    if (feature.place_type[0] == 'neighborhood') {
      for (const result of feature.context) {
        if (result.id.includes('postcode')) {
          address.postcode = result.text;
        }
        if (result.id.includes('place')) {
          address.city = result.text;
        }
        if (result.id.includes('locality')) {
          address.line_2 = result.text;
        }
        if (result.id.includes('district')) {
          address.county = result.text;
        }
        if (result.id.includes('region')) {
          address.state = result.text;
        }
        if (result.id.includes('country')) {
          address.country = result.text;
        }
      }
    }
    if (feature.place_type[0] == 'address') {
      for (const result of feature.context) {
        if (result.id.includes('postcode')) {
          address.postcode = result.text;
        }
        if (result.id.includes('place')) {
          address.city = result.text;
        }
        if (result.id.includes('locality')) {
          address.city = result.text;
        }
        if (result.id.includes('district')) {
          address.county = result.text;
        }
        if (result.id.includes('region')) {
          address.state = result.text;
        }
        if (result.id.includes('country')) {
          address.country = result.text;
        }
      }
    }
    if (feature.place_type[0] == 'region') {
      for (const result of feature.context) {
        if (result.id.includes('postcode')) {
          address.postcode = result.text;
        }
        if (result.id.includes('place')) {
          address.city = result.text;
        }
        if (result.id.includes('district')) {
          address.county = result.text;
        }
        if (result.id.includes('region')) {
          address.state = result.text;
        }
        if (result.id.includes('country')) {
          address.country = result.text;
        }
      }
    }

    if (feature.id.includes('place')) {
      address.city = feature.text;
    }
    if (feature.id.includes('region')) {
      address.state = feature.text;
    }
    if (feature.id.includes('address')) {
      let houseNumber = feature.address;
      let position = feature.place_name.search(houseNumber);
      if (position == 0) {
        address.street =
          (feature.address ? feature.address + ' ' : '') + feature.text;
      } else {
        address.street =
          feature.text + (feature.address ? ' ' + feature.address : '');
      }
    }
    if (feature.id.includes('country')) {
      address.country = feature.text;
    }
    address.type = feature.place_type[0];
    // Full Address
    if (feature.place_name) {
      address.full_address = feature.place_name;
    }
    // Coordinates
    if (feature.center) {
      address.longitude = feature.center[0];
      address.latitude = feature.center[1];
    }

    return address;
  }

  renderMap() {
    this.renderderOnce = true;
  }

  public clear() {
    if (this.markers.length > 0) {
      this.markers.map((marker) => {
        marker.remove();
      });
      this.markers = [];
    }
  }

  public flyTo(space: { longitude: number; latitude: number }) {}

  public onAddMarker(space: { latitude: number; longitude: number }) {
    if (space.latitude && space.longitude) {
      // const el = document.createElement("div");
      const img = document.createElement('img');
      img.src = 'assets/images/map_marker.png';
    }
  }

  amountSliderUpdate(input) {
    const sliders = document.querySelectorAll('.range-amount-slider');
    this.settings = {
      fill: '#1cc91a',
      background: '#bbefbas',
    };
    // Array.prototype.forEach.call(sliders, (slider) => {
    this.applyFill(input, this.space.hosting_amount, 'amount');
    // });
  }

  sliderTokenUpdate(input, token_value, type) {
    setTimeout(() => {
      this.applyFill(input, input.value, type);
    }, 100);
  }

  public setSpaceInstantType() {
    if (this.space.is_instant == 1) {
      this.space.is_instant = 0;
    } else {
      if (this.space.is_instant == 0) {
        this.space.is_instant = 1;
      }
    }
  }

  public setHsgtoggleType() {
    this.description_loading = true;
    this.spaceService.getHsg_assistant(this.spaceId).subscribe((res: any) => {
      this.description_loading = false;

      this.addressForm.patchValue({
        title: res?.data?.title,
        description: res?.data?.description,
      });
    });
  }

  openImgPopup(index, tagId, imgUrl) {
    this.clickedImg.index = index;
    this.clickedImg.tagId = tagId;
    this.clickedImg.imgUrl = imgUrl;
    this.showImg = true;
  }

  picImageFile(event) {
    let file = event.target.files[0];

    if (this.uploadService.maxFileSizeValidator(file.size, 2, 20)) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let image_uuid = uuidv4();
        let fileExt = file.name.split('.').pop();
        this.fileName = image_uuid + '.' + fileExt;
        this.original_fileName = 'original_' + image_uuid + '.' + fileExt;
        this.imageChangedEvent = event;
        this.cropEnabled = true;
        this.originalFile = file;
        // form.get('documentOrigFileName').setValue(file.name);
        // form.get('base64').setValue(reader.result);
        // form.get('documentOrigFileName').patchValue({
        //   documentOrigFileName: file.name,
        //   base64: reader.result
        // });
      };
      reader.onerror = (error) => {};
    } else {
      event.target.value = '';
      this.originalFile = '';
    }
  }

  closeCroper() {
    this.imageChangedEvent = '';
    this.cropEnabled = false;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  loadImageFailed() {
    this.closeCroper();
    this.snackbarService.show({
      message: this.translate.instant('Wrong file format selected.'),
      type: 'success',
      config: {
        class: 'centered',
      },
    });
  }

  imageLoaded() {
    this.showCropper = true;
  }

  public uploadSpaceImage() {
    this.loading = true;
    const file = new Buffer(
      this.croppedImage.replace(/^data:image\/\w+;base64,/, ''),
      'base64'
    );
    let index = this.clickedImg.index;
    let tagId = this.clickedImg.tagId;
    let fileType = this.croppedImage.split(';')[0].split('/')[1];

    this.uploadService
      .fileUpload(file, this.fileName, fileType)
      .then((result) => {
        let imagePath = result.Location.replace(
          'holidayswap-local.s3.amazonaws.com',
          'assets.holidayswap.com'
        );
        if (tagId == 555) {
          this.outsideViewImage.image_url = imagePath;
        }
        if (tagId == 544) {
          this.livingRoomImages.image_url = imagePath;
        }
        if (tagId == 541) {
          this.bedRoomImages.image_url = imagePath;
        }
        if (tagId == 552) {
          this.kitchenImages.image_url = imagePath;
        }
        if (tagId == 542) {
          this.bathRoomImages.image_url = imagePath;
        }
        if (tagId == 551) {
          this.guestRoomImages.image_url = imagePath;
        }

        this.clickedImg.imgUrl = imagePath;
        this.space.images[index] = {
          id: '',
          image_path: imagePath,
          tag: tagId,
        };

        // this.homeImages[index] = [...this.clickedImg];
        this.uploadOriginalImage();

        this.cropEnabled = false;
        this.showImg = false;
        this.loading = false;
      });
  }

  uploadOriginalImage() {
    if (this.originalFile != '') {
      this.loading = true;
      const file = this.originalFile;
      let index = this.clickedImg.index;
      let tagId = this.clickedImg.tagId;
      let fileType = file.name.split('.').pop();

      this.uploadService
        .fileUpload(file, this.original_fileName, fileType)
        .then((result) => {
          let imagePath = result.Location.replace(
            'holidayswap-local.s3.amazonaws.com',
            'assets.holidayswap.com'
          );
          if (tagId == 555) {
            this.original_outsideViewImage.image_url = imagePath;
          }
          if (tagId == 544) {
            this.original_livingRoomImages.image_url = imagePath;
          }
          if (tagId == 541) {
            this.original_bedRoomImages.image_url = imagePath;
          }
          if (tagId == 552) {
            this.original_kitchenImages.image_url = imagePath;
          }
          if (tagId == 542) {
            this.original_bathRoomImages.image_url = imagePath;
          }
          if (tagId == 551) {
            this.original_guestRoomImages.image_url = imagePath;
          }

          this.clickedImg.imgUrl = imagePath;
          this.space.originalImages[index] = {
            id: '',
            image_path: imagePath,
            tag: tagId,
          };
        });
    }
  }

  public onRemove() {
    let index = this.clickedImg.index;
    if (this.space.images.length > 0 && this.space.images[index].id) {
      let itemId = this.space.images[index].id;
      this.spaceService.deleteImage(itemId).subscribe(
        (response) => {
          // this.homeImages.splice(index,1,null);
          this.space.images[index] = {
            id: '',
            image_path: '',
            tag: '',
          };
        },
        (err) => {}
      );
    }
    if (index == 0) {
      this.outsideViewImage = { tag_id: '', image_url: '' };
    }

    if (index == 1) {
      this.livingRoomImages = { tag_id: '', image_url: '' };
    }

    if (index == 2) {
      this.bedRoomImages = { tag_id: '', image_url: '' };
    }

    if (index == 3) {
      this.kitchenImages = { tag_id: '', image_url: '' };
    }

    if (index == 4) {
      this.bathRoomImages = { tag_id: '', image_url: '' };
    }

    if (index == 5) {
      this.guestRoomImages = { tag_id: '', image_url: '' };
    }
    this.removeOriginalImage();
    // this.clickedImg.imgUrl = "";
    // this.removeImg = false;
  }

  removeOriginalImage() {
    let index = this.clickedImg.index;
    if (
      this.space.originalImages.length > 0 &&
      this.space.originalImages[index].id
    ) {
      let itemId = this.space.originalImages[index].id;
      this.spaceService.deleteOriginalImage(itemId).subscribe(
        (response) => {
          // this.homeImages.splice(index,1,null);
          this.space.originalImages[index] = {
            id: '',
            image_path: '',
            tag: '',
          };
        },
        (err) => {}
      );
    }
    if (index == 0) {
      this.original_outsideViewImage = { tag_id: '', image_url: '' };
    }

    if (index == 1) {
      this.original_livingRoomImages = { tag_id: '', image_url: '' };
    }

    if (index == 2) {
      this.original_bedRoomImages = { tag_id: '', image_url: '' };
    }

    if (index == 3) {
      this.original_kitchenImages = { tag_id: '', image_url: '' };
    }

    if (index == 4) {
      this.original_bathRoomImages = { tag_id: '', image_url: '' };
    }

    if (index == 5) {
      this.original_guestRoomImages = { tag_id: '', image_url: '' };
    }
    this.clickedImg.imgUrl = '';
    this.removeImg = false;
  }

  gotoExplore() {
    this.exitClick();
    this.router.navigate(['/']);
  }

  exitClick() {
    if (this.outsideViewImage.image_url == '' || this.activeStep > 12) {
      this.exitModal.emit();
    } else if (this.activeStep == 4) {
      // this.activeStep++;
      this.saveHome(true);
      this.exitModal.emit();
      this.combainedAddress = this.getCombainedAddress();
    } else if (this.activeStep == 11) {
      this.onUpdate();
      this.saveHome(true);
      this.exitModal.emit();
    } else {
      if (!this.exitClickedOnce) {
        this.exitClickedOnce = true;
        this.saveHome(false);
      }
      this.exitModal.emit();
    }
  }

  backToPrev() {
    if (this.activeStep == 11) {
      this.addressForm
        .get('title')
        .setValue(this.addressForm.get('title').value.trim());
      this.addressForm
        .get('description')
        .setValue(this.addressForm.get('description').value.trim());
      if (
        this.addressForm.controls.bedrooms.valid &&
        this.addressForm.controls.bathrooms.valid &&
        this.addressForm.controls.guests.valid &&
        this.addressForm.controls.title.valid &&
        this.addressForm.controls.description.valid
      ) {
        this.onUpdate();
        this.saveHome(true);
      }
      // this.onUpdate();
      this.exitModal.emit();
    } else if (this.activeStep == 6) {
      if (this.isEditMode == true) {
        this.saveCategories();
        this.activeStep = 11;
      } else {
        this.onUpdate();
        this.saveHome(true);
        this.exitModal.emit();
      }
    }
  }

  saveClick() {
    this.serverErrors = [];
    if (this.outsideViewImage.image_url == '' || this.activeStep > 12) {
      this.exitModal.emit();
    } else if (this.activeStep == 4) {
      // this.activeStep++;
      this.saveHome(true);
      // this.exitModal.emit();
      this.combainedAddress = this.getCombainedAddress();
    } else if (this.activeStep == 11) {
      this.addressForm
        .get('title')
        .setValue(this.addressForm.get('title').value.trim());
      this.addressForm
        .get('description')
        .setValue(this.addressForm.get('description').value.trim());

      // if(this.addressForm.controls.bedrooms.valid && this.addressForm.controls.bathrooms.valid && this.addressForm.controls.guests.valid && this.addressForm.controls.title.valid && this.addressForm.controls.description.valid){
      //   this.onUpdate();

      // }else{
      //   return;
      // }
      this.onUpdate();
      // this.saveHome(true);
      // this.exitModal.emit();
    } else if (this.activeStep == 6) {
      this.amenitiesList.emit();

      if (this.isEditMode == true) {
        // this.onUpdate();
        this.saveCategories();

        // this.saveHome(false);
        this.activeStep = 11;

        // this.patchEditdata(this.editData);
      } else {
        this.onUpdate();
        this.saveHome(true);
        this.exitModal.emit();
      }
    } else {
      if (!this.exitClickedOnce) {
        this.exitClickedOnce = true;
        this.saveHome(true);
        // this.inputStarted = false;
      }
    }
  }

  saveActivestep(event, type, selectedSpace) {
    event.preventDefault();
    if (this.activeStep == 2) {
      if (type == 'next') {
        this.isEditToken = true;
        this.isEditSwap = false;
        this.isEditSwapbtn = false;
        this.isEditTokenbtn = true;
      } else {
        if (this.fiatActivated) {
          if (
            this.space.payment_type_id == 562 &&
            this.space.purpose_id == 521
          ) {
            $('#fiatBooking').modal('show');
            this.modalBackdrop = true;
          } else if (
            (this.space.payment_type_id == 562 &&
              this.space.purpose_id == 522) ||
            (this.space.payment_type_id == 562 && this.space.purpose_id == 520)
          ) {
            $('#fiatBookingtoken').modal('show');
            this.modalBackdrop = true;
          } else {
            this.saveHome(false);
            this.exitModal.emit();
          }
        } else {
          this.saveHome(false);
          this.exitModal.emit();
        }
      }
    }
  }

  pop(event, type) {
    if (type == 'step2') {
      this.isEditSwap = true;
      this.isEditToken = false;
      this.isEditSwapbtn = true;
      this.isEditTokenbtn = false;
    } else if (type == 'step1') {
      this.exitModal.emit();
    } else {
      this.backlocation.back();
    }
  }

  validateForm() {
    if (this.activeStep == 1) {
      if (this.outsideViewImage.image_url != '') {
        this.activeStep++;
      } else {
        this.snackbarService.show({
          message: this.translate.instant('Cover image is mandatory'),
          type: 'success',
          config: {
            class: 'centered',
          },
        });
      }
    } else if (this.activeStep == 2) {
      this.activeStep++;
      if (!this.renderderOnce) {
        setTimeout(() => {
          // this.renderMap();
        }, 200);
      }
    } else if (this.activeStep == 3) {
      if (
        (this.addressForm.get('full_address').value != '' &&
          this.selectedAddress) ||
        (this.addressForm.get('full_address').value != '' && this.space.id)
      ) {
        this.activeStep++;
      } else {
        this.snackbarService.show({
          type: 'success',
          message: this.translate.instant('Please select address.'),
          config: {
            class: 'centered',
          },
        });
      }
    } else if (this.activeStep == 4) {
      this.addressForm.markAllAsTouched();
      if (this.addressForm.valid) {
        this.activeStep++;
      }
    } else if (this.activeStep == 5) {
      this.activeStep++;
      this.saveHome(true);
    } else if (this.activeStep == 6) {
      this.activeStep++;
    } else if (this.activeStep == 7) {
      this.homeDetailForm.markAllAsTouched();
      if (this.homeDetailForm.valid) {
        this.activeStep++;
      }
    } else if (this.activeStep == 8) {
      this.space.isAlreadyAdded = true;
      if (!this.isPhoneVerified) {
        if (
          this.verification.phone &&
          this.verification.phone.length > 4 &&
          this.verification.phone.length < 15
        ) {
          this.onConfirm();
        } else {
          this.snackbarService.show({
            message: this.translate.instant('Please enter valid phone number.'),
            type: 'success',
            config: {
              class: 'centered',
            },
          });
        }
      } else {
        this.saveHome();
      }
    } else if (this.activeStep == 9) {
      // this.disableOne = !this.disableOne;
      this.activeStep++;
    } else if (this.activeStep == 10) {
      // this.disableOne = !this.disableOne;
      this.activeStep++;
    } else if (this.activeStep == 11) {
      // this.disableOne = !this.disableOne;
      this.saveHome();
      this.activeStep++;
    } else if (this.activeStep == 12) {
      this.activeStep++;
    }
  }

  patchOtp(event) {
    this.verification.code = event;
  }

  onDatesSelected(dates) {
    this.space.dates = dates;
  }

  public onRequest() {
    // this.loading = true;

    var hasLeadingZero = this.hasLeadingZero(this.space?.phone_no);

    if (hasLeadingZero) {
      this.snackbarService.show({
        message:
          'Hey! We see you have a zero at the start of your mobile - is this correct?  If you have any troubles, try removing the zero!',
        type: 'success',
        config: {
          class: 'centered',
        },
      });
    } else {
      if (this.sentTime < 1) {
        this._startCountdown();
      }
      if (this.sentTime < 2) {
        this.btn_block = true;
        this.hideOtpInput = true;
        this.userService
          .requestPhoneVerification({
            telephone_no: this.verification.phone,
            country_code: this.space?.country_code,
            phone_no: this.space?.phone_no,
          })
          .subscribe(
            (res: any) => {
              this.sentTime++;
            },
            (err) => {
              this.snackbarService.show({
                type: 'success',
                message: err.toString(),
                config: {
                  class: 'centered',
                },
              });
              // this.error = err;
              // this.loading = true;
            }
          );
      }
    }
  }

  public onConfirm() {
    this.userService
      .confirmPhoneVerification({
        code: this.verification.code,
      })
      .subscribe(
        (response) => {
          this.verifiedOTP();
          this.snackbarService.show({
            type: 'success',
            message: this.translate.instant(
              'Phone number successfully verified!'
            ),
            config: {
              class: 'centered',
            },
          });
          this.userService.getMe();
          this.saveHome();
          // this.otpConfirmed = true;

          // this.tokenService.bonusTokens("Bonus", 21, 26, 10, "Bonus on account verification").subscribe(
          // 	(res) => {
          // 	});

          // this.goback()
        },
        (err) => {
          // this.error = err;
          this.snackbarService.show({
            type: 'success',
            message: this.translate.instant('Please enter valid OTP.'),
            config: {
              class: 'centered',
            },
          });
        }
      );
  }

  confirmNumberEventSendAgainVerificationClicked() {
    this.clevertapService.confirmNumberEventSendAgainVerificationClicked(
      this.verification.phone
    );
  }

  verifiedOTP() {
    this.clevertapService.confirmNumberEventOTPVerificationClicked(
      this.verification.phone
    );
  }

  private _startCountdown() {
    this.resendHide = false;
    // let currentTime = new Date();
    // let cDateMillisecs = moment(currentTime).valueOf();
    let countDownTime = moment().add(1, 'minutes');
    let tDateMillisecs = moment(countDownTime).valueOf();
    // this.remaining = this.cooldown;
    this.interval = setInterval(() => {
      let currentTime = new Date();
      let cDateMillisecs = moment(currentTime).valueOf();
      let difference = tDateMillisecs - cDateMillisecs;
      if (difference <= 0) {
        this.resendHide = true;
        this.btn_block = false;
        clearInterval(this.interval);
        return;
      } else {
        let seconds: any = Math.floor(difference / 1000);
        let minutes: any = Math.floor(seconds / 60);
        // this.days = Math.floor(this.hours / 24);

        // this.hours %= 24;
        minutes %= 60;
        seconds %= 60;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;

        this.remaining = minutes + ':' + seconds;
        // // document.getElementById('days').innerText = this.days;
        // document.getElementById('hours').innerText = this.hours;
        // document.getElementById('mins').innerText = this.minutes;
        // document.getElementById('seconds').innerText = this.seconds;
      }
    }, 1000);
  }
  patchEditdata(data) {
    this.priceLoading = false;

    this.selectedCountry = data?.address?.country;

    this.addressForm.get('country').setValue(data?.address?.country);
    if (data.amenities) {
      let selectedAmenities = this.aminitiesForm.controls[
        'amenities'
      ] as FormArray;
      selectedAmenities.controls = [];
      data.amenities?.map((res1) => {
        this.categories?.amenities?.filter((res) => {
          if (res1.id == res.id) {
            res.isActive = true;
            let selected = this.aminitiesForm.controls[
              'amenities'
            ] as FormArray;
            selected.push(new FormControl(res.id));
          }
        });

        this.markedCategories.amenities?.filter((res) => {
          if (res1.id == res.id) {
            res.isActive = true;
          }
        });
      });
    }

    if (data.accessibilities) {
      let selectedAccessibilities = this.aminitiesForm.controls[
        'accessibilities'
      ] as FormArray;
      selectedAccessibilities.controls = [];
      data.accessibilities?.map((res1) => {
        this.categories?.accessibilities?.filter((res) => {
          if (res1.id == res.id) {
            res.isActive = true;
            let selected = this.aminitiesForm.controls[
              'accessibilities'
            ] as FormArray;
            selected.push(new FormControl(res.id));
          }
        });
      });
    }

    if (data.rules) {
      let selectedRules = this.aminitiesForm.controls['rules'] as FormArray;
      selectedRules.controls = [];
      data.rules?.map((res1) => {
        this.categories?.rules?.filter((res) => {
          if (res1.id == res.id) {
            res.isActive = true;
            let selected = this.aminitiesForm.controls['rules'] as FormArray;
            selected.push(new FormControl(res.id));
          }
        });
      });
    }

    if (data.safeties) {
      let selectedSafeties = this.aminitiesForm.controls[
        'safeties'
      ] as FormArray;
      selectedSafeties.controls = [];
      data.safeties?.map((res1) => {
        this.categories?.safeties?.filter((res) => {
          if (res1.id == res.id) {
            res.isActive = true;
            let selected = this.aminitiesForm.controls['safeties'] as FormArray;
            selected.push(new FormControl(res.id));
          }
        });
      });
    }

    // this.markedCategories.amenities = this.markedCategories?.amenities?.filter((res) => res.isActive == true);
    // this.markedCategories.accessibilities = this.markedCategories?.accessibilities?.filter((res) => res.isActive == true);
    // this.markedCategories.rules = this.markedCategories?.rules?.filter((res) => res.isActive == true);
    // this.markedCategories.safeties = this.markedCategories?.safeties?.filter((res) => res.isActive == true);
    this.markedAmenitiesLength = this.markedCategories?.amenities?.filter(
      (res) => res.isActive == true
    ).length;
    this.markedAccessibilitiesLength =
      this.markedCategories?.accessibilities?.filter(
        (res) => res.isActive == true
      ).length;
    this.marketRulesLength = this.markedCategories?.rules?.filter(
      (res) => res.isActive == true
    ).length;
    this.marketSafetiesLength = this.markedCategories?.safeties?.filter(
      (res) => res.isActive == true
    ).length;

    this.totalSelectedAmenities =
      this.markedAmenitiesLength +
      this.markedAccessibilitiesLength +
      this.marketRulesLength +
      this.marketSafetiesLength;

    if (data.address === null) {
      data.address = {
        latitude: '',
        longitude: '',
      };
    }

    if (data.images && data.images.length > 0) {
      this.space.images = data.images.map((image, index) => {
        if (index == 0) {
          this.outsideViewImage.image_url = image.image_url;
        }
        if (image.tag?.id == 555) {
          this.outsideViewImage.image_url = image.image_url;
        }
        if (image.tag?.id == 544) {
          this.livingRoomImages.image_url = image.image_url;
        }
        if (image.tag?.id == 541) {
          this.bedRoomImages.image_url = image.image_url;
        }
        if (image.tag?.id == 552) {
          this.kitchenImages.image_url = image.image_url;
        }
        if (image.tag?.id == 542) {
          this.bathRoomImages.image_url = image.image_url;
        }
        if (image.tag?.id == 551) {
          this.guestRoomImages.image_url = image.image_url;
        }
        return {
          id: image.id,
          image_path: image.image_url,
          tag: image.tag.id,
          uploaded: true,
        };
      });

      for (let i = 0; i < this.space.images.length; i++) {
        if (this.space.images[i].image_url != '') {
          this.completePercentage += 5;
        }
      }

      // this.homeImages = this.space.images;
    }
    this.space = data;

    // this.patchAmenities();

    if (
      data.address?.manual_latlng == true ||
      data.address?.manual_latlng == 1
    ) {
      this.markerUpdated = true;
    } else {
      this.markerUpdated = false;
    }

    if (data.unavailable_dates) {
      this.space['dates'] = data.unavailable_dates
        ? data.unavailable_dates.map((date) => {
            return {
              id: date.id,
              started_at: moment.unix(date.started_at).format('YYYY-MM-DD'),
              ended_at: moment.unix(date.ended_at).format('YYYY-MM-DD'),
              status: date?.status,
            };
          })
        : [];
      this.space['dates'] = this.space?.dates?.filter((dates) => {
        if (dates?.status == 'UNAVAILABLE') {
          return dates;
        }
      });
    } else {
      this.space['dates'] = [];
    }

    this.addressForm.patchValue({
      city: data.address?.city
        ? data.address?.city
        : data.address?.county
        ? data.address?.county
        : '',
      country: data.address?.country ? data.address.country : '',
      state: data.address?.state ? data.address?.state : '',
      full_address: data.address?.full_address
        ? data.address?.full_address
        : '',
      latitude: data.address?.latitude ? data.address?.latitude : '',
      longitude: data.address?.longitude ? data.address?.longitude : '',
      postcode: data.address?.postcode ? data.address?.postcode : '',
      postcode_1: data.address?.postcode ? data.address?.postcode : '',
      street: data.address?.street ? data.address?.street : '',
      street_1: data.address?.street_1 ? data.address?.street_1 : '',
      street_2: data.address?.street_2 ? data.address?.street_2 : '',
    });

    if (data.address?.full_address) {
      this.location['full_address'] = data.address?.full_address;
    }

    this.addressForm.patchValue({
      bathrooms: data.bathrooms,
      bedrooms: data.bedrooms,
      description: data.description,
      guests: data.guests,
      title: data.title,
    });
    this.selectedCountry = data.address.country;

    this.homeDetailForm.patchValue({
      bathrooms: data.bathrooms,
      bedrooms: data.bedrooms,
      description: data.description,
      guests: data.guests,
      title: data.title,
    });

    this.setPaymentOptions();

    if (data.hosting_tokens == null) {
      this.space.hosting_tokens = 49;

      setTimeout(() => {
        this.sliderTokenUpdate(
          document.getElementById('token-slider-input'),
          data.hosting_tokens,
          'token'
        );
      }, 500);
    }
    if (data.hosting_tokens != null) {
      setTimeout(() => {
        this.sliderTokenUpdate(
          document.getElementById('token-slider-input'),
          data.hosting_tokens,
          'token'
        );
      }, 500);
    }

    if (this.space.address != null && !this.space.address.full_address) {
      this.space.address.full_address =
        this.space.address.city + ',' + this.space.address.country;
    }

    if (data.title != null && data.title != '') {
      if (data.address != null && data.address != undefined) {
        this.space.address.full_address =
          data.address.city + ' , ' + data.address.country;
      }
    }
  }

  public applyFill(slider, hostingvalue, type) {
    this.zone.run(() => {
      let fillColor = this.settings.fill;
      let backgroundColor = this.settings.background;
      let val = this.space.hosting_tokens;
      if (type == 'amount') {
        fillColor = '#1cc91a';
        backgroundColor = '#bbefba';
        val = this.space.hosting_amount;
      }

      let percentage = (100 * (val - slider.min)) / (slider.max - slider.min);
      if (!val || val == '' || val == undefined) {
        percentage = 0;
        slider.value = 0;
      }
      const bg = `linear-gradient(90deg, ${fillColor} ${percentage}%, ${backgroundColor} ${
        percentage + 0.1
      }%)`;

      if (slider) {
        slider.style.background = bg;
      }
      this.cd.detectChanges();
    });
  }

  applyFillInitial(slider, token) {
    // Let's turn our value into a percentage to figure out how far it is in between the min and max of our input
    const percentage = (100 * (token - slider.min)) / (slider.max - slider.min);
    // now we'll create a linear gradient that separates at the above point
    // Our background color will change here
    const bg = `linear-gradient(90deg, ${this.settings.fill} ${percentage}%, ${
      this.settings.background
    } ${percentage + 0.1}%)`;
    slider.style.background = bg;
  }

  saveHome(onExit = false) {
    if (this.activeStep == 4) {
      // ----
      let country_address = this.addressForm.get('country').value;

      const excluded = this.excludeCountries.some((country) =>
        country_address?.toUpperCase().includes(country.toUpperCase())
      );

      if (excluded) {
        this.snackbarService.show({
          message: this.translate.instant(
            'Sorry!  Your country / region is currently not supported by Holiday Swap.'
          ),
          type: 'success',
          config: {
            class: 'centered',
          },
        });
        this.addressForm.get('country').setValue('');
        // return;
      }
      // -----

      this.addressForm.markAllAsTouched();
      if (this.addressForm.invalid) {
        this.exitClickedOnce = false;
        return;
      }
      if (
        typeof this.addressForm.get('title').value != null &&
        this.addressForm.get('title').value.trim() == ''
      ) {
        this.addressForm.controls.title.setErrors({ required: true });

        return;
      }

      if (
        this.addressForm.get('street_1').value != null &&
        this.addressForm.get('street_1').value.trim() == ''
      ) {
        this.addressForm.controls.street_1.setErrors({ required: true });

        return;
      }

      if (
        this.addressForm.get('postcode_1').value != null &&
        this.addressForm.get('postcode_1').value.trim() == ''
      ) {
        this.addressForm.controls.postcode_1.setErrors({ required: true });

        return;
      }

      if (
        this.addressForm.get('city').value != null &&
        this.addressForm.get('city').value.trim() == ''
      ) {
        this.addressForm.controls.city.setErrors({ required: true });

        return;
      }

      if (
        this.addressForm.get('state').value != null &&
        this.addressForm.get('state').value.trim() == ''
      ) {
        this.addressForm.controls.state.setErrors({ required: true });

        return;
      }

      if (
        this.addressForm.get('country').value != null &&
        this.addressForm.get('country').value.trim() == ''
      ) {
        this.addressForm.controls.country.setErrors({ required: true });

        return;
      }

      if (
        this.addressForm.get('description').value != null &&
        this.addressForm.get('description').value.trim() == ''
      ) {
        this.addressForm.controls.description.setErrors({ required: true });

        return;
      }
      return this.editHomesave();
    }

    let addresVal = this.addressForm.value;
    let detailVal = this.homeDetailForm.value;
    // let amienitiesVal = this.aminitiesForm.value;

    this.space.address = addresVal;
    this.space.bathrooms = addresVal.bathrooms;
    this.space.bedrooms = addresVal.bedrooms;
    this.space.description = addresVal.description;
    this.space.guests = addresVal.guests;
    this.space.title = addresVal.title;

    this.space.country = addresVal.country;

    (this.space.flat_no = addresVal.street_2),
      (this.space.street = addresVal.street),
      (this.space.city = addresVal.city),
      (this.space.country = addresVal.country);
    let countryCode = this.countries.filter((country) => {
      if (country.name.toLowerCase() == this.space.country.toLowerCase()) {
        return country.code;
      }
    });
    this.space.countryIso = countryCode[0]?.code ? countryCode[0]?.code : '';
    (this.space.postcode = addresVal.postcode),
      (this.space.latitude = addresVal.latitude | 0),
      (this.space.longitude = addresVal.longitude | 0),
      (this.space.state = addresVal.state),
      (this.space.postcode = addresVal.postcode_1),
      (this.space.postcode_1 = addresVal.postcode_1),
      (this.space.full_address = addresVal.full_address),
      (this.space.street_1 = addresVal.street_1),
      (this.space.street_2 = addresVal.street_2),
      (this.space.title = addresVal.title),
      (this.space.address.manual_latlng = this.markerUpdated),
      (this.space.accessibilities =
        this.aminitiesForm.get('accessibilities').value);
    this.space.amenities = this.aminitiesForm.get('amenities').value;
    this.space.safeties = this.aminitiesForm.get('safeties').value;
    this.space.rules = this.aminitiesForm.get('rules').value;
    this.space.hosting_tokens = parseInt(
      this.space.hosting_tokens ? this.space.hosting_tokens : 49
    );
    this.space.hosting_amount = parseInt(
      this.space.hosting_amount ? this.space.hosting_amount : 49
    );

    this.space.images = this.space.images
      .filter((image) => image.image_path && image.image_path.length > 0)
      .map((image) => {
        this.completePercentage += 10;
        return {
          id: image.id,
          image_path: image.image_path,
          tag: image.tag,
        };
        // return image;
      });
    if (this.activeStep == 1 && this.space.images.length == 0) {
      return;
    }
    // this.space.images = this.homeImages;
    if (this.space.title) {
      this.completePercentage += 10;
    }

    if (this.space.description) {
      this.completePercentage += 10;
    }
    if (this.space?.address?.city) {
      this.completePercentage += 10;
    }

    if (
      this.space.guests > 0 &&
      this.space.bathrooms > 0 &&
      this.space.bedrooms > 0
    ) {
      this.completePercentage += 10;
    }

    localStorage.removeItem('spaceDetails');
    // this.addBonusToken();

    let homeData = {
      email: this.userEmail,
      phone_number: '',
      app_build: '',
      app_package: '',
      device_name: '',
      device_os: '',
      device_platform: '',
      timestamp: '',
    };
    this.clevertapService.pushCleverTapEvent('home_added', homeData);
    let moreHome = {
      email: this.userEmail,
      phone_number: '',
      app_build: '',
      app_package: '',
      device_name: '',
      device_os: '',
      device_platform: '',
      timestamp: '',
    };
    this.clevertapService.pushCleverTapEvent('add_more_homes', moreHome);
    if (this.completePercentage < 100) {
      let incomplete = {
        email: this.userEmail,
      };
      this.clevertapService.pushCleverTapEvent(
        'listing_incomplete',
        incomplete
      );
    }

    if (this.space.images.length == 6) {
      let homePhoto = {
        email: this.userEmail,
        phone_number: '',
        app_build: '',
        app_package: '',
        device_name: '',
        device_os: '',
        device_platform: '',
        timestamp: '',
      };
      this.clevertapService.pushCleverTapEvent('home_photos', homePhoto);
    }

    if (this.space.id) {
      this.spaceService.updateSpaceCompletionRate(
        this.space.id,
        this.completePercentage
      );
      this.priceLoading = false;
      this._updateSpace(this.space, onExit);
    } else {
      this._createSpace(this.space, onExit);
    }
    // this.editHomesave();
  }

  public _createSpace(space, onExit = false) {
    this.spaceService.create(space).subscribe(
      (response: { data?: any }) => {
        // this.toastr.success('Saved successfully.', 'Success');
        if (onExit) {
          this.snackbarService.show({
            message: this.translate.instant('Saved successfully.'),
            type: 'success',
            config: {
              class: 'centered',
            },
          });
          this.clearAll();
          this.exitModal.emit();
        } else {
          this.activeStep++;
        }
      },
      (err) => {
        // this.toastr.error(err.toString(), 'Request failed');
        this.snackbarService.show({
          message: this.translate.instant('Request failed.'),
          type: 'success',
          config: {
            class: 'centered',
          },
        });
        this.exitClickedOnce = false;
        if (onExit) {
          this.exitModal.emit();
        }
      }
    );
  }

  addBonusToken() {
    this.tokenService
      .bonusTokens('Bonus', 18, 20, 10, 'Bonus on creating first space')
      .subscribe((res) => {});
  }

  updateImgBtn() {
    if (!this.clickedImg.imgUrl) {
      this.snackbarService.show({
        message: this.translate.instant('Please select image to update.'),
        type: 'success',
        config: {
          class: 'centered',
        },
      });
    } else {
      this.showImg = false;
    }
  }

  public onUpdate() {
    this.space.accessibilities =
      this.aminitiesForm.get('accessibilities').value;
    this.space.amenities = this.aminitiesForm.get('amenities').value;
    this.space.safeties = this.aminitiesForm.get('safeties').value;
    this.space.rules = this.aminitiesForm.get('rules').value;

    this.addressForm.markAllAsTouched();
    if (
      this.addressForm.controls.bedrooms.valid &&
      this.addressForm.controls.bathrooms.valid &&
      this.addressForm.controls.guests.valid &&
      this.addressForm.controls.title.valid &&
      this.addressForm.controls.description.valid
    ) {
      this.spaceService
        .updateType(this.space?.id, this.space)
        .subscribe((response: { data?: any }) => {
          if (response) {
            this.snackbarService.show({
              message: this.translate.instant('Saved successfully.'),
              type: 'success',
              config: {
                class: 'centered',
              },
            });
            this.saveHome(true);
            this.exitModal.emit();
          }
        });
    } else {
      // this.snackbarService.show({
      //   message: this.translate.instant("Please enter the details"),
      //   type: 'success',
      //   config: {
      //     class: 'centered'
      //   }
      // });
      return;
    }
  }

  public saveCategories() {
    this.space.accessibilities =
      this.aminitiesForm.get('accessibilities').value;
    this.space.amenities = this.aminitiesForm.get('amenities').value;
    this.space.safeties = this.aminitiesForm.get('safeties').value;
    this.space.rules = this.aminitiesForm.get('rules').value;
    this.priceLoading = true;
    this.spaceService
      .saveUpdatedCategories(this.space?.id, this.space)
      .subscribe((response: { data?: any }) => {
        this.editData = response.data;
      })
      .add(() => {
        // this.reOpenModal.emit(this.activeStep);
        // this.patchEditdata(this.editData);
        this.setAmenities();
        setTimeout(() => {
          this.patchEditdata(this.editData);
          this.priceLoading = false;
        }, 2000);
      });
  }

  public _updateSpace(space, onExit = false) {
    this.spaceService
      .update(this.space?.id, space)
      .subscribe((response: { data?: any }) => {
        if (response) {
          this.snackbarService.show({
            message: this.translate.instant('Saved successfully.'),
            type: 'success',
            config: {
              class: 'centered',
            },
          });
        }

        if (onExit) {
          this.clearAll();
          this.exitModal.emit();
        } else {
          // this.activeStep++;
          this.exitModal.emit();
        }
      });
    (err) => {
      // this.toastr.error(err.toString(), 'Request failed');
      // this.snackbarService.show({
      //   message: err.toString(),
      //   type: 'success',
      //   config: {
      //     class: 'centered'
      //   }
      // });

      this.exitClickedOnce = false;

      if (onExit) {
        this.exitModal.emit();
      }
    };
  }

  clearAll() {
    this.space = {};
    this.addressForm.reset();
    this.homeDetailForm.reset();
  }
  ngOnDestroy(): void {
    this.userData = undefined;
    $('.app-navigation').removeClass('hide');
    $('.app-content').removeClass('addHomeModal');
  }

  gotoSubscription() {
    if (!this.userData?.email_verified) {
      this.userService.verifyMail.next(true);
    } else {
      if (this.isDesktopView) this.router.navigate(['/settings/subscriptions']);
      else this.router.navigate(['/settings/subscriptions']);
      // this.router.navigate(['/', { outlets: { modal: ['modal', 'settings', 'subscriptions'] } }]);
    }
  }

  changeHostingToken(type) {
    switch (type) {
      case 'swap':
        if (this.space.purpose_id == 520) {
          this.space.purpose_id = 522;
          this.space.payment_type_id = 561;

          // $('#fiatBooking').modal('show');

          // this.isEditSwapbtn = true;
          // this.isEditTokenbtn = false;
        } else if (this.space.purpose_id == 522) {
          this.space.purpose_id = 520;
          this.space.payment_type_id = 562;

          // this.isEditSwap = true;
          // this.isEditToken = false;
        } else {
          this.space.purpose_id = 521;
          this.space.payment_type_id = 562;
          this.isEditSwap = true;
          this.isEditToken = false;

          // this.isEditSwapbtn = false;
          // this.isEditTokenbtn = true;
        }

        // $('#fiatBooking').modal('show');

        if (this.space.purpose_id == 521) {
          this.isEditTokenbtn = true;
          this.isEditSwapbtn = false;
        } else {
          this.isEditTokenbtn = false;
          this.isEditSwapbtn = true;
        }
        break;
      case 'list':
        if (this.space.purpose_id == 521) {
          this.space.purpose_id = 522;
          this.space.payment_type_id = 561;

          // this.isEditSwapbtn = true;
          // this.isEditTokenbtn = false;
        } else if (this.space.purpose_id == 522) {
          this.space.purpose_id = 521;
          this.space.payment_type_id = 562;

          // this.isEditSwapbtn = true;
          // this.isEditTokenbtn = false;
        } else {
          this.space.purpose_id = 520;
          this.space.payment_type_id = 561;

          // this.isEditSwapbtn = true;
          // this.isEditTokenbtn = false;
        }

        if (this.space.purpose_id == 521) {
          this.isEditTokenbtn = true;
          this.isEditSwapbtn = false;
        } else {
          this.isEditTokenbtn = false;
          this.isEditSwapbtn = true;
        }
        break;
    }
    this.setPaymentOptions();
  }

  setPaymentOptions() {
    if (this.space.purpose_id == 522) {
      this.purpose_type = [];
      this.purpose_type = [
        { id: 520, type: 'host' },
        { id: 521, type: 'swap' },
      ];
      this.payment_type.purpose.all = true;
    }

    if (this.space.purpose_id == 521) {
      this.purpose_type = [];
      this.purpose_type = [{ id: 521, type: 'swap' }];
      this.isEditSwapbtn = false;
      this.isEditTokenbtn = true;
    }

    if (this.space.purpose_id == 520) {
      this.purpose_type = [];
      this.purpose_type = [{ id: 520, type: 'host' }];
      this.isEditSwapbtn = true;
      this.isEditTokenbtn = false;
    }

    this.purpose_type.map((purpose) => {
      if (purpose.type) {
        this.payment_type.purpose[purpose.type] = true;
      }
    });

    if (
      this.payment_type.purpose.swap == true &&
      this.payment_type.purpose.host == true &&
      this.space.payment_type_id == 561
    ) {
      this.payment_type.purpose.all = true;
    }

    if (this.space.payment_type_id == 561) {
      this.cardSliderShow = true;
      this.tokenSliderShow = false;
      // this.space.hosting_amount = 49;
      // this.space.hosting_tokens = 0;
      this.payment_type.purpose.host = true;
      this.payment_type.purpose.swap = false;
    } else {
      this.cardSliderShow = false;
      this.tokenSliderShow = true;
      // this.space.hosting_amount = 0;
      // this.space.hosting_tokens = 49;
    }

    setTimeout(() => {
      this.resetSlider(this.space?.hosting_tokens, 'token');
      this.resetSlider(this.space?.hosting_amount, 'amount');
    }, 300);
  }

  changeTokenValue() {
    if (this.space.payment_type_id == 561) {
      this.isEditSwapbtn = false;
      this.isEditTokenbtn = true;
    } else {
      this.isEditSwapbtn = false;
      this.isEditTokenbtn = true;
    }
  }

  resetSlider(tokenValue, type) {
    if (type == 'token') {
      this.applyFill(
        document.getElementById('token-slider-input'),
        tokenValue,
        'token'
      );
    }

    if (type == 'amount') {
      this.applyFill(
        document.getElementById('amount-slider-input'),
        tokenValue,
        'amount'
      );
    }
  }

  resetAmountSlider(tokenValue) {
    this.applyFill(
      document.getElementById('token-slider-input'),
      tokenValue,
      'token'
    );
    this.applyFill(
      document.getElementById('amount-slider-input'),
      tokenValue,
      'amount'
    );
  }

  isInt(n) {
    return Number(n) === n && n % 1 === 0;
  }

  setAmountManualVal(event: Event) {
    let val = event.target as HTMLInputElement;

    let _tempVal = +val.value;

    if (_tempVal <= 0 || _tempVal > 500) {
      // this.toastr.error('Please select tokens between 1 - 500', 'Error');
      this.space.hosting_amount = 0;
      this.resetAmountSlider(this.space?.hosting_amount);
      this.validTokenVal = false;
      return;
    }

    if (!this.isInt(_tempVal)) {
      // this.toastr.error('Please select tokens between 1 - 500', 'Error');
      this.space.hosting_amount = 0;
      this.resetAmountSlider(this.space?.hosting_amount);
      this.validTokenVal = false;
      return;
    }

    this.validTokenVal = true;
    //Math.trunc(+val.value)
    //setTimeout(() => {
    this.space.hosting_amount = +parseFloat(val.value);

    this.calCulateTokenPrice();
    this.resetAmountSlider(this.space?.hosting_amount);
    //}, 500);
  }

  setManualVal(event: Event) {
    let val = event.target as HTMLInputElement;

    let _tempVal = +val.value;

    if (_tempVal <= 0 || _tempVal > 500) {
      // this.toastr.error('Please select tokens between 1 - 500', 'Error');
      this.space.hosting_tokens = 0;
      this.resetSlider(this.space?.hosting_tokens, 'token');
      this.validTokenVal = false;
      return;
    }

    if (!this.isInt(_tempVal)) {
      // this.toastr.error('Please select tokens between 1 - 500', 'Error');
      this.space.hosting_tokens = 0;
      this.resetSlider(this.space?.hosting_tokens, 'token');
      this.validTokenVal = false;
      return;
    }

    this.validTokenVal = true;
    //Math.trunc(+val.value)
    //setTimeout(() => {
    this.space.hosting_tokens = +parseFloat(val.value);

    this.calCulateTokenPrice();
    this.resetSlider(this.space?.hosting_tokens, 'token');
    //}, 500);
  }

  public calCulateTokenPrice() {
    this.validTokenVal = true;
    // if (this.isDiscount) {
    // 	// this.tokenPrice = this.tokenValue;// - tokenCal;
    // 	// this.tokenPrice = (this.tokenPrice - ((this.tokenPrice * this.discountPrice) / 100));
    // }
    // else this.tokenPrice = this.tokenValue;
  }

  handleInputValidation(obj, key, max) {
    let val = parseFloat(obj[key]);

    if (this.space.payment_type_id == 562 || this.space.purpose_id == 521) {
      if (val > max) {
        obj[key] = 49;
        this.snackbarService.show({
          message: this.translate.instant(
            `Please select tokens between 1 - ${max}`
          ),
          type: 'info',
          config: {
            class: 'centered',
          },
        });
      } else if (val <= 0) {
        obj[key] = 49;
        this.snackbarService.show({
          message: this.translate.instant(
            `Please select tokens between 1 - ${max}`
          ),
          type: 'info',
          config: {
            class: 'centered',
          },
        });
      }
    } else {
      if (val > max) {
        obj[key] = 49;
        this.snackbarService.show({
          message: this.translate.instant(
            `Please select dollars between 1 - ${max}`
          ),
          type: 'info',
          config: {
            class: 'centered',
          },
        });
      } else if (val <= 0) {
        obj[key] = 49;
        this.snackbarService.show({
          message: this.translate.instant(
            `Please select dollars between 1 - ${max}`
          ),
          type: 'info',
          config: {
            class: 'centered',
          },
        });
      }
    }

    val = parseInt(obj[key].toString());
    obj[key] = val;

    try {
      this.sliderTokenUpdate(
        document.getElementById('amount-slider-input'),
        'NA',
        'amount'
      );
      this.sliderTokenUpdate(
        document.getElementById('token-slider-input'),
        'NA',
        'token'
      );
    } catch (e) {}
  }

  checkRegEx(event) {
    var keyCode = 'which' in event ? event.which : event.keyCode;

    let isNotWanted =
      keyCode == 69 || keyCode == 189 || keyCode == 110 || keyCode == 190;
    return !isNotWanted;
  }

  closeBackDrop() {
    this.modalBackdrop = false;
    this.space.payment_type_id = this.oldFiatBooking.paymentTypeId;
    this.space.purpose_id = this.oldFiatBooking.purposeId;
  }

  proceedBooking() {
    this.saveHome(false);
    this.exitModal.emit();
  }

  onPhonenumberChange(event) {
    // form.controls['telephoneNumber'].setValue(event?.phone);
    // form.controls['telephoneCode'].setValue(event?.code);
    // form.controls['countryIso'].setValue(event?.country_code);
    // // form.controls['telephoneNumber'].setValue(event);
    this.space.country_code = event?.code;
    this.space.phone_no = event?.phone;
  }

  outsideClickTrigger(event) {
    if (
      !event.target?.className?.includes('combobox-input-search') &&
      !event.target.className.includes('combobox-input')
    ) {
      this.outsideClick = true;
      this.cd.detectChanges();
      this.rtypeRef?.update();

      this.countriesRef?.update();
      // this.currencyCode?.update();
    }
  }

  setServerError() {
    this.serverErrors = [];
    if (this.serverErrorList.length > 0) {
      this.serverErrorList.map((errors, index) => {
        this.serverErrors.push({
          path: errors?.path,
          message: errors?.message,
        });
      });
    }
  }

  onSelectCountry(country) {
    this.selectedCountry = country.name;
    this.addressForm.get('country').setValue(country.name);

    if (this.addressForm.get('country').value == '') {
      // this.selectedCountry = country;
      this.selectedCountry = country.name;
    }
  }

  editHomesave(onExit = false) {
    let saveAddress = {
      id: this.spaceId,
      show_specific_location: false,
      city: this.addressForm.get('city').value,
      continent: this.addressForm.get('state').value,
      country: this.addressForm.get('country').value,
      flat_no: this.space.flat_no,
      isocode: '',
      latitude: this.addressForm.get('latitude').value,
      longitude: this.addressForm.get('longitude').value,
      postcode: this.addressForm.get('postcode_1').value,
      street: this.addressForm.get('street').value,
      street_1: this.addressForm.get('street_1').value,
      street_2: this.addressForm.get('street_2').value,
      full_address: this.addressForm.get('full_address').value,
      title: this.addressForm.get('title').value,
      manual_latlng: this.markerUpdated,
    };

    this.spaceService.postSaveaddress(saveAddress).subscribe(
      (res: any) => {
        this._updateSpace(this.space, onExit);
        if (res) {
          this.snackbarService.show({
            message: this.translate.instant('Saved successfully.'),
            type: 'success',
            config: {
              class: 'centered',
            },
          });
        }

        if (onExit) this.exitModal.emit();
      },
      (err) => {
        this.snackbarService.show({
          message: err,
          type: 'success',
          config: {
            class: 'centered',
          },
        });
        // })
      }
    );
  }

  UpdatedPinAdd(formattedNewLocation: any) {
    const extractedInformation = formattedNewLocation.components.reduce(
      (result, component) => {
        const types = component.types;
        const longName = component.long_name;

        if (types.includes('country')) {
          result.country = longName;
        } else if (types.includes('administrative_area_level_1')) {
          result.state = longName;
        } else if (types.includes('administrative_area_level_2')) {
          result.street1 = longName;
        } else if (types.includes('administrative_area_level_3')) {
          result.street2 = longName;
        } else if (types.includes('postal_code')) {
          result.postalCode = longName;
        } else if (types.includes('locality')) {
          result.city = longName;
        }

        return result;
      },
      {
        country: '',
        street1: '',
        street2: '',
        postalCode: '',
        city: '',
        state: '',
      }
    );
    this.addressForm.get('street_1').setValue(extractedInformation.street1);
    this.addressForm.get('street_2').setValue(extractedInformation.street2);
    this.addressForm.get('country').setValue(extractedInformation.country);
    this.addressForm.get('city').setValue(extractedInformation.city);
    this.addressForm.get('postcode_1').setValue(extractedInformation.postCode);
    this.addressForm.get('state').setValue(extractedInformation.state);

    this.combainedAddress = formattedNewLocation.formatted_address;
    this.location.latitude = formattedNewLocation.lat;
    this.addressForm.get('latitude').setValue(formattedNewLocation.lat);
    this.location.longitude = formattedNewLocation.lng;
    this.addressForm.get('longitude').setValue(formattedNewLocation.lng);

    this.markerUpdated = true;
  }

  getCombainedAddress(): string {
    const street1 = this.addressForm.get('street_1').value;
    const street2 = this.addressForm.get('street_2').value;
    const city = this.addressForm.get('city').value;
    const state = this.addressForm.get('state').value;
    const postcode = this.addressForm.get('postcode_1').value;
    const country = this.addressForm.get('country').value;
    let address = '';

    if (street1) {
      address += street1;
    }

    if (street2) {
      address += (address ? ', ' : '') + street2;
    }

    if (city) {
      address += (address ? ', ' : '') + city;
    }

    if (state) {
      address += (address ? ', ' : '') + state;
    }

    if (postcode) {
      address += (address ? ' ' : '') + postcode;
    }

    if (country) {
      address += (address ? ', ' : '') + country;
    }

    address = address.trim();

    return address;
  }

  updateMarker() {
    const geocoder = new google.maps.Geocoder();
    this.combainedAddress = this.getCombainedAddress();
    geocoder.geocode({ address: this.combainedAddress }, (results, status) => {
      if (status === 'OK') {
        const location = results[0].geometry.location;

        if (!this.markerUpdated) {
          this.space.address.longitude = location.lng();
          this.space.address.latitude = location.lat();
          this.space.address.formatted_address = results[0].formatted_address;
          this.location.full_address = results[0].formatted_address;
          this.addressForm
            .get('longitude')
            .setValue(this.space.address.longitude);
          this.addressForm
            .get('latitude')
            .setValue(this.space.address.latitude);
        } else {
          this.space.address.longitude = this.space.address.longitude;
          this.space.address.latitude = this.space.address.latitude;
          this.space.address.formatted_address = results[0].formatted_address;
          this.location.full_address = results[0].formatted_address;
          this.addressForm
            .get('longitude')
            .setValue(this.space.address.longitude);
          this.addressForm
            .get('latitude')
            .setValue(this.space.address.latitude);
        }

        if (this.localtionMap) {
          this.localtionMap?.flyTo(location.lat(), location.lng());
        }
      }
    });
  }

  back() {
    this.activeStep--;
  }

  nextMap() {
    let formVal = this.addressForm.value;
    let city = formVal.city;
    let country = formVal.country;
    let postcode_1 = formVal.postcode_1;
    let state = formVal.state;
    let street = formVal.street;
    let street_1 = formVal.street_1;
    let street_2 = formVal.street_2;

    if (
      city != '' &&
      country != '' &&
      state != '' &&
      postcode_1 != '' &&
      street_1 != ''
    ) {
      this.activeStep++;
    }
  }

  hasLeadingZero(phoneNumber) {
    // Remove any non-digit characters from the phone number
    phoneNumber = phoneNumber.replace(/\D/g, '');

    // Check if the phone number starts with a zero
    return phoneNumber.charAt(0) === '0';
  }

  toggleAmenities() {
    if ($('#amenitiesListForm').outerHeight() > 200) {
      if (this.showAllAmenities) {
        this.showAllAmenities = false;
        $('.allAmenities').css('height', '200px');
        $('.amenities_block_btn_wrapper .shadow_box').css('display', 'block');
      } else {
        this.priceLoading = false;
        this.showAllAmenities = true;
        const height1 = $('#amenitiesListForm').outerHeight();
        $('.allAmenities').css('height', height1);
      }
    }
  }

  patchAmenities() {
    if (this.space?.amenities) {
      this.space?.amenities?.map((res1) => {
        this.categories?.amenities?.filter((res) => {
          if (res1.id == res.id) {
            res.isActive = true;
            // let selected = (this.aminitiesForm.controls['amenities'] as FormArray);
            // selected.push(new FormControl(res.id))
          }
        });
      });
    }

    if (this.space?.accessibilities) {
      this.space?.accessibilities?.map((res1) => {
        this.categories?.accessibilities?.filter((res) => {
          if (res1.id == res.id) {
            res.isActive = true;
            // let selected = (this.aminitiesForm.controls['accessibilities'] as FormArray);
            // selected.push(new FormControl(res.id))
          }
        });
      });
    }

    if (this.space?.rules) {
      this.space?.rules?.map((res1) => {
        this.categories?.rules?.filter((res) => {
          if (res1.id == res.id) {
            res.isActive = true;
            // let selected = (this.aminitiesForm.controls['rules'] as FormArray);
            // selected.push(new FormControl(res.id))
          }
        });
      });
    }

    if (this.space?.safeties) {
      this.space?.safeties?.map((res1) => {
        this.categories?.safeties?.filter((res) => {
          if (res1.id == res.id) {
            res.isActive = true;
            // let selected = (this.aminitiesForm.controls['safeties'] as FormArray);
            // selected.push(new FormControl(res.id))
          }
        });
      });
    }

    this.categories.amenities = this.categories?.amenities?.filter(
      (res) => res.isActive == true
    );
    this.categories.accessibilities = this.categories?.accessibilities?.filter(
      (res) => res.isActive == true
    );
    this.categories.rules = this.categories?.rules?.filter(
      (res) => res.isActive == true
    );
    this.categories.safeties = this.categories?.safeties?.filter(
      (res) => res.isActive == true
    );
    this.totalSelectedAmenities =
      this.categories?.amenities?.length +
      this.categories?.accessibilities?.length +
      this.categories?.rules?.length +
      this.categories?.safeties?.length;
    setTimeout(() => {
      if ($('#amenitiesListForm').outerHeight() > 200) {
        this.hideAmenitiesButton = false;
      } else {
        this.hideAmenitiesButton = true;
        $('.features_check_outer').css('height', 'auto');
      }
    }, 300);
  }

  openAmenitiesModal() {
    this.isEditMode = true;
    this.activeStep = 6;

    $('.add_form_body').scrollTop(0);
  }

  placeTypeChange(event) {
    this.space.place_type = this.place_type;
    if (
      this.titleFromChatGPT != '' &&
      this.titleFromChatGPT.toLowerCase() ==
        this.homeDetailForm.get('title')?.value.toLowerCase()
    ) {
      this.chatgptDescriptionReceived = false;
    } else if (this.homeDetailForm.get('title')?.value == '') {
      this.chatgptDescriptionReceived = false;
    } else {
      this.chatgptDescriptionReceived = true;
    }

    if (
      this.descriptionFromChatGPT != '' &&
      this.descriptionFromChatGPT.toLowerCase() ==
        this.homeDetailForm.get('description')?.value.toLowerCase()
    ) {
      this.chatgptDescriptionReceived = false;
    } else if (this.homeDetailForm.get('description')?.value == '') {
      this.chatgptDescriptionReceived = false;
    } else {
      this.chatgptDescriptionReceived = true;
    }

    this.spaceService
      .updateType(this.spaceId, this.space)
      .subscribe((res) => {});

    if (
      this.space.is_hsg_assistant == true ||
      this.space.is_hsg_assistant == 1
    ) {
      this.description_loading = true;
      setTimeout(() => {
        this.setHsgtoggleType();
      }, 5000);
    }
  }

  checkHSGAssistance(event) {
    this.spaceService
      .updateType(this.spaceId, this.space)
      .subscribe((res) => {});

    if (
      this.space.is_hsg_assistant == true ||
      this.space.is_hsg_assistant == 1
    ) {
      this.space.bedrooms = this.addressForm.get('bedrooms').value;
      this.space.bathrooms = this.addressForm.get('bathrooms').value;
      this.space.guests = this.addressForm.get('guests').value;

      this.spaceService
        .saveUpdatedCategories(this.space?.id, this.space)
        .subscribe((response: { data?: any }) => {})
        .add(() => {
          this.setHsgtoggleType();
        });

      this.spaceService
        .updateType(this.spaceId, this.space)
        .subscribe((res) => {});
    }
  }

  onWheel(event) {
    event.preventDefault();
  }
}
