import { Injectable, Output } from "@angular/core";
import * as EventEmitter from "events";
import * as moment from "moment";
import { Subject } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class LanguageService {

    private selectedLang: string = "en";
    languageDirection: string = "ltr";

	  @Output() public languageDirChange = new Subject<string>();
    @Output() public languageUpdates = new Subject<string>();

    constructor() {
    }

    /**
     * The lang code stored in localStorage is mismatched with Moment library's locale name
     */
    public static parseLangForMoment(lang: string) {
      if (lang == "en") {
        return 'en-gb';
      } else if (lang == 'zh') {
        return 'zh-cn';
      } else {
        return lang;
      }
    }

    public SetLanguage(lang) {
        this.selectedLang = lang;
        localStorage.setItem("lang", lang);

        // TODO: at this moment, we'll only support English
        // if (lang == "en") {
        //   moment.locale('en-gb');
        // } else if (lang == 'zh') {
        //   moment.locale('zh-cn');
        // } else {
        //   moment.locale(lang);
        // }

        this.languageUpdates.next(lang);
    }

    public GetLanguage() {
        this.selectedLang = localStorage.getItem("lang");
        return this.selectedLang;
    }

    public setDirection(language){
        if (language == "ar") this.languageDirection = "rtl";
        else this.languageDirection = "ltr";

        this.languageDirChange.next(this.languageDirection);
    }


//   public updateLanguage(language: string): void {
//   }
}
