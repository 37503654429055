<div class="home-detail-main app_container">
  <!-- image section with header -->
  <!-- <div class="explore_header">
        <div class="explore_left">
            <i class="fa fa-arrow-left previous-arrow" (click)="pop()" aria-hidden="true"></i>
        </div>
    </div>
    <app-social-share></app-social-share> -->

  <div class="home-prop-img">
    <swiper [config]="config">
      <ng-container *ngFor="let image of space.images; index as i">
        <ng-template swiperSlide>
          <div class="space-slider-image">
            <img class="lazyload" [lazyLoad]="image.image_url" alt="" />
          </div>
        </ng-template>
      </ng-container>
    </swiper>
  </div>
  <!-- image section with header -->
  <div class="home-detail-content">
    <!-- property-intro-start -->
    <div class="property-intro">
      <div class="left-section">
        <h2>{{ space.title }}</h2>
        <span
          class="gray_text"
          *ngIf="space?.address?.country || space?.address?.city"
        >
          <img src="/assets/images/mark_ic.svg" />
          {{
            space?.address?.country
              ? space?.address?.country + " , "
              : "
                    "
          }}
          {{ space?.address?.city }}
        </span>
      </div>
      <div class="right-section">
        <div class="right_inner">
          <h2>
            <img src="/assets/images/Token_Plane.png" *ngIf="!token" />{{
              space.hosting_tokens
            }}
          </h2>
          <span class="gray_text" *ngIf="!token">{{
            "per night" | translate
          }}</span>
        </div>
      </div>
    </div>
    <!-- property-intro-end -->
    <!-- overview section start -->
    <div class="overview-section">
      <div class="section_label">{{ "Overview" | translate }}</div>
      <div class="facility_section">
        <ul>
          <li><img src="/assets/images/user_bl.svg" />{{ space?.guests }}</li>
          <li><img src="/assets/images/bad_bl.svg" />{{ space?.bedrooms }}</li>
          <li>
            <img src="/assets/images/shower_bl.svg" />{{ space?.bathrooms }}
          </li>
          <!-- <li><img src="/assets/images/home_bl.svg">Studio</li> -->
        </ul>
      </div>
      <div [innerHTML]="space?.description" class="section_description"></div>
    </div>
    <!-- overview section end -->
    <!-- map section start -->
    <div class="map-section">
      <div class="map_header">
        <div class="section_label">{{ "Map Location" | translate }}</div>
        <!-- <a>{{"Direction" | translate}}</a> -->
      </div>
      <div class="location_map"></div>
    </div>
    <!-- map section start -->
    <!-- feature section start -->
    <div class="feature_section">
      <div class="property-intro">
        <div class="left-section">
          <div class="section_label">{{ "Features" | translate }}</div>
          <div class="facility_section">
            <ul>
              <li *ngFor="let feature of spaceFeatures">
                {{ feature?.name | translate }}
              </li>
            </ul>
          </div>
        </div>
        <div class="right-section">
          <div class="right_inner">
            <div class="section_label">{{ "Instant booking" | translate }}</div>
            <div class="toggle-switch">
              <span class="toggle_label" *ngIf="space?.is_instant == '1'">{{
                "On" | translate
              }}</span>
              <span class="toggle_label" *ngIf="space?.is_instant == '0'">{{
                "Off" | translate
              }}</span>
              <label class="switch">
                <input
                  type="checkbox"
                  disabled
                  [checked]="space?.is_instant == '1'"
                  (click)="statusReadOnly()"
                />
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="space-option-box">
      <div id="accordion">
        <div class="card">
          <div class="card-header">
            <a
              class="collapsed btn"
              data-bs-toggle="collapse"
              href="#collapseFive"
            >
              <span class="heading-option-style">
                {{ "Hosted by" | translate }}
                {{ space?.user?.first_name }} </span
              ><i class="fa fa-angle-right toggle-icon-style"></i>
            </a>
          </div>

          <div id="collapseFive" class="collapse" data-bs-parent="#accordion">
            <div class="card-body">
              <div class="space-host">
                <div class="picture">
                  <!--img [src]="space?.user?.image_url" /-->
                  <app-avatar
                    height="100"
                    width="100"
                    [picture]="space?.user?.image_url"
                    [name]="space?.user?.first_name"
                    [verified]="space?.user?.is_verified"
                  >
                  </app-avatar>
                </div>

                <div class="name">
                  {{ space?.user?.first_name }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <a
              class="collapsed btn"
              data-bs-toggle="collapse"
              href="#collapseThree"
            >
              <span class="heading-option-style">
                {{ "Home details" | translate }} </span
              ><i class="fa fa-angle-right toggle-icon-style"></i>
            </a>
          </div>
          <div id="collapseThree" class="collapse" data-bs-parent="#accordion">
            <div class="card-body">
              <div class="space-features">
                <ng-container *ngFor="let section of sections">
                  <div
                    (click)="expand[section.key] = !expand[section.key]"
                    appRipple
                    class="space-features-header {{
                      expand[section.key] ? 'active' : ''
                    }}"
                  >
                    <div class="feature icon" [innerHTML]="section.icon"></div>

                    <div class="name">
                      {{ section.name | translate }}
                    </div>

                    <div class="arrow">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="ai ai-ChevronRight"
                      >
                        <path d="M8 4l8 8-8 8" />
                      </svg>
                    </div>
                  </div>

                  <div class="space-features-items" *ngIf="expand[section.key]">
                    <div class="item" *ngFor="let item of space[section.key]">
                      {{ item.name | translate }}
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-loading [loading]="loading"></app-loading>
