<form class="sign-in-form" [formGroup]="form" (ngSubmit)="onSubmit()">
  <h2 class="form-title">{{ 'Sign in or Sign up to book' | translate }}</h2>

  <div class="form-field">
    <input
      type="email"
      formControlName="username"
      placeholder="{{'Enter your email' | translate}}"
      required
    >
    <p
      class="input-error"
      *ngIf="form.controls?.['username']?.touched && form.controls?.['username']?.errors?.['required']"
    >
      {{ 'Email is required' | translate }}
    </p>
    <p
      class="input-error"
      *ngIf="form.controls?.['username']?.touched && form.controls?.['username']?.errors?.['email']"
    >
      {{ 'Invalid email address' | translate }}
    </p>
  </div>

  <div class="form-field position-relative">
    <input [type]="showPassword ? 'text' : 'password'" formControlName="password"
           placeholder="{{'Enter your password' | translate}}" required>
    <span class="show-password" (click)="toggleShowPassword()">
      <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="!showPassword"></i>
      <i class="fa fa-eye" aria-hidden="true" *ngIf="showPassword"></i>
    </span>
    <p
      class="input-error"
      *ngIf="form.controls?.['password']?.touched && form.controls?.['password']?.errors?.['required']"
    >
      {{ 'Password is required' | translate }}
    </p>
  </div>

  <button [disabled]="isSubmitting" type="submit" class="primary-button">
    <i *ngIf="isSubmitting" class="fa fa-spinner fa-spin"></i>
    {{ 'Continue' | translate }}
  </button>

  <p class="divider-text">{{ 'or continue with' | translate }}</p>

  <app-social-sign-in [hiddenApple]="true"></app-social-sign-in>
</form>
