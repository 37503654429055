import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { ISnackbar } from "../interfaces/snackbar";


@Injectable({
	providedIn: "root"
})
export class SnackbarService {
	private snackbarSubject = new Subject<any>();
	public snackbarState = this.snackbarSubject.asObservable();

	constructor() { }

	show(params: ISnackbar) {
		this.snackbarSubject.next({ duration: 5000, ...params });
	}
}