import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-explore-cards',
  templateUrl: './explore-cards.component.html',
  styleUrls: ['./explore-cards.component.scss'],
})
export class ExploreCardsComponent implements OnInit {
  @Input() homeList: any = [];
  @Input() hideLoadMore: boolean = true;
  @Input() loading: boolean = true;
  @Output() loadMore = new EventEmitter();
  public reviewImage = 'assets/images/list-your-home-bg.jpg';
  public config: SwiperOptions = {
    direction: 'horizontal',
    slidesPerView: 'auto',
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    navigation: false,
    pagination: false,
    virtual: false,
    preloadImages: false,
    observer: true,
    spaceBetween: 0,
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
    },
  };

  reviews = [
    {
      id: 1,
      image_url: 'assets/images/testimonals/ava1.png',
      name: 'Inthiyazarifa Arifa',
      content:
        'Great service, very responsive and they have great deals!  I recommend Holiday Swap to everyone.',
    },
    {
      id: 2,
      image_url: 'assets/images/testimonals/ava2.png',
      name: 'Emmanuel Gabathuse',
      content:
        'Excellent service and they communicate really well. They understand customer needs and give top service!',
    },
    {
      id: 3,
      image_url: 'assets/images/testimonals/ava3.png',
      name: 'Mikki Tenazas',
      content:
        'Great customer service I loved that their prices were a lot more competitive than other platforms. I will definitely book with them again.',
    },
  ];

  shownContentIds: number[] = [];

  isContentShown(id: number): boolean {
    return this.shownContentIds.includes(id);
  }

  toggleContent(id: number): void {
    const elem = document.getElementById(`testimonalsCard${id}`);

    if (this.isContentShown(id)) {
      this.shownContentIds = this.shownContentIds.filter(
        (reviewId) => reviewId !== id
      );
      elem.style.webkitLineClamp = '3';
    } else {
      this.shownContentIds.push(id);
      elem.style.webkitLineClamp = 'unset';
    }

    this.cdr.detectChanges();
  }

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}
}
