<div
  class="add_home_form_wrapper"
  [class.onSuccess]="activeStep == 10"
  [class.dark-theme]="darkTheme"
>
  <!-- <div class="progress_bar_wrapper" *ngIf="activeStep<=7">
        <p>Step {{stepNumber}} of 5</p>
        <div class="progress_bar">
            <span [ngStyle]="{'width': progressWidth}"></span>
        </div>
    </div> -->

  <div class="add_form_body">
    <!-- image upload step -->
    <div class="form_detail_inner image_upload_wrapper" *ngIf="activeStep == 1">
      <h4 class="step_title">{{ "Edit your home images" | translate }}</h4>
      <p class="step_detail font-20">
        <strong>{{ "Earn 70 HS Points" | translate }}</strong>
      </p>
      <p class="step_detail">
        {{ "for uploading 6 images of your home." | translate }}
      </p>

      <div class="image_form">
        <div
          class="image_box home_view"
          (click)="openImgPopup(0, 555, outsideViewImage.image_url)"
        >
          <img
            [src]="
              outsideViewImage.image_url
                ? outsideViewImage.image_url
                : placeholderImg
            "
            alt="place holder image"
          />
          <span>{{ "Cover Image" | translate }}</span>
          <img
            src="../../../../assets/images/Edit_Icon.png"
            alt=""
            class="edit_icon"
          />
        </div>
        <div
          class="image_box living_room"
          (click)="openImgPopup(1, 544, livingRoomImages.image_url)"
        >
          <img
            [src]="
              livingRoomImages.image_url
                ? livingRoomImages.image_url
                : placeholderImg
            "
            alt="place holder image"
          />
          <img
            src="../../../../assets/images/Edit_Icon.png"
            alt=""
            class="edit_icon"
          />
        </div>
        <div
          class="image_box beadroom"
          (click)="openImgPopup(2, 541, bedRoomImages.image_url)"
        >
          <img
            [src]="
              bedRoomImages.image_url ? bedRoomImages.image_url : placeholderImg
            "
            alt="place holder image"
          />
          <img
            src="../../../../assets/images/Edit_Icon.png"
            alt=""
            class="edit_icon"
          />
        </div>
        <div
          class="image_box kitchen"
          (click)="openImgPopup(3, 552, kitchenImages.image_url)"
        >
          <img
            [src]="
              kitchenImages.image_url ? kitchenImages.image_url : placeholderImg
            "
            alt="place holder image"
          />
          <img
            src="../../../../assets/images/Edit_Icon.png"
            alt=""
            class="edit_icon"
          />
        </div>
        <div
          class="image_box bathroom"
          (click)="openImgPopup(4, 542, bathRoomImages.image_url)"
        >
          <img
            [src]="
              bathRoomImages.image_url
                ? bathRoomImages.image_url
                : placeholderImg
            "
            alt="place holder image"
          />
          <img
            src="../../../../assets/images/Edit_Icon.png"
            alt=""
            class="edit_icon"
          />
        </div>
        <div
          class="image_box guest_room"
          (click)="openImgPopup(5, 551, guestRoomImages.image_url)"
        >
          <img
            [src]="
              guestRoomImages.image_url
                ? guestRoomImages.image_url
                : placeholderImg
            "
            alt="place holder image"
          />
          <img
            src="../../../../assets/images/Edit_Icon.png"
            alt=""
            class="edit_icon"
          />
        </div>
      </div>
      <ul class="image_token_desc">
        <li>
          <p>
            {{ "Outside view of home" | translate }}
            <span class="highlight">{{ "+ 10 HS Points" | translate }}</span>
          </p>
        </li>
        <li>
          <p>
            {{ "Livingroom" | translate }}
            <span class="highlight">{{ "+ 10 HS Points" | translate }}</span>
          </p>
        </li>
        <li>
          <p>
            {{ "Bedroom" | translate }}
            <span class="highlight">{{ "+ 10 HS Points" | translate }}</span>
          </p>
        </li>
        <li>
          <p>
            {{ "Kitchen" | translate }}
            <span class="highlight">{{ "+ 10 HS Points" | translate }}</span>
          </p>
        </li>
        <li>
          <p>
            {{ "Bathroom" | translate }}
            <span class="highlight">{{ "+ 10 HS Points" | translate }}</span>
          </p>
        </li>
        <li>
          <p>
            {{ "Guest room" | translate }}
            <span class="highlight">{{ "+ 10 HS Points" | translate }}</span>
          </p>
        </li>
      </ul>
    </div>

    <!-- travel oprions -->
    <div
      class="form_detail_inner travel_option_wrapper"
      *ngIf="activeStep == 2"
    >
      <div class="edit_home_wrapper" *ngIf="isEditSwap">
        <div class="edit_wrapper">
          <button type="button" class="backBtn" (click)="pop($event, 'step1')">
            <svg
              width="17"
              height="15"
              viewBox="0 0 17 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17 7.27403C17 7.45527 16.9344 7.63037 16.8152 7.76696C16.6961 7.90355 16.5316 7.99238 16.352 8.01703L16.25 8.02403H3.066L7.829 12.767C7.96425 12.9003 8.04389 13.08 8.05184 13.2697C8.0598 13.4595 7.99548 13.6452 7.87187 13.7893C7.74826 13.9335 7.57456 14.0254 7.38583 14.0465C7.1971 14.0676 7.0074 14.0163 6.855 13.903L6.771 13.83L0.721001 7.80603C0.586981 7.67202 0.508419 7.49241 0.500999 7.30303L0.5 7.27403V7.24503C0.50057 7.23034 0.501572 7.21566 0.503 7.20103L0.5 7.27403C0.500351 7.11305 0.552216 6.95641 0.648001 6.82703L0.653999 6.81903C0.674316 6.7926 0.696365 6.76754 0.719999 6.74403L6.77 0.71703C6.90355 0.580364 7.08423 0.499827 7.27515 0.491863C7.46607 0.4839 7.65283 0.54911 7.7973 0.67418C7.94177 0.799251 8.03305 0.974748 8.05251 1.16484C8.07197 1.35493 8.01813 1.54528 7.902 1.69703L7.829 1.78003L3.068 6.52303L16.25 6.52303C16.4489 6.52303 16.6397 6.60205 16.7803 6.7427C16.921 6.88335 17 7.07412 17 7.27303V7.27403Z"
                fill="#212121"
              />
            </svg>
          </button>
          <h4 class="edit_header">
            {{ "Edit how you use your listing" | translate }}
          </h4>
        </div>
        <h4 class="step_title paytype_title">
          Step 1
          <span class="highlight_orange"
            >of <span class="highlight_text">2</span></span
          >
        </h4>
        <p class="edit_text">
          {{ "I would like to use my listing by..." | translate }}
        </p>

        <p class="select_option_text">
          {{ "Please select up to two options here:" | translate }}
        </p>
        <div class="option_radioList_outer">
          <div class="radio_box_outer">
            <input
              type="checkbox"
              id="swapHome"
              hidden
              value="521"
              (change)="changeHostingToken('swap')"
            />
            <label
              for="swapHome"
              class="radioInner"
              [class.selected]="
                space.purpose_id == 521 || space.purpose_id == 522
              "
            >
              <div class="radio_imgBox">
                <img
                  class="uncheck"
                  src="assets/images/un-checked.svg"
                  alt=""
                />
                <img class="check" src="assets/images/checked.svg" alt="" />
              </div>
              <div class="title_box">
                <h4>{{ "Swapping homes with others" | translate }}</h4>
                <p>
                  {{ "Avoid accommodation costs when travelling!" | translate }}
                </p>
              </div>
            </label>
          </div>
          <div class="radio_box_outer">
            <input
              type="checkbox"
              id="listHome"
              hidden
              value="520"
              (change)="changeHostingToken('list')"
            />
            <label
              for="listHome"
              class="radioInner"
              [class.selected]="
                space.purpose_id == 520 || space.purpose_id == 522
              "
            >
              <div class="radio_imgBox">
                <img
                  class="uncheck"
                  src="assets/images/un-checked.svg"
                  alt=""
                />
                <img class="check" src="assets/images/checked.svg" alt="" />
              </div>
              <div class="title_box">
                <h4>{{ "Listing my home for bookings" | translate }}</h4>
                <p>
                  {{ "Earn money for your bookings." | translate }}
                </p>
              </div>
            </label>
          </div>
        </div>
      </div>

      <div class="edit_home_wrapper" *ngIf="isEditToken">
        <div class="edit_wrapper">
          <button type="button" class="backBtn" (click)="pop($event, 'step2')">
            <svg
              width="17"
              height="15"
              viewBox="0 0 17 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17 7.27403C17 7.45527 16.9344 7.63037 16.8152 7.76696C16.6961 7.90355 16.5316 7.99238 16.352 8.01703L16.25 8.02403H3.066L7.829 12.767C7.96425 12.9003 8.04389 13.08 8.05184 13.2697C8.0598 13.4595 7.99548 13.6452 7.87187 13.7893C7.74826 13.9335 7.57456 14.0254 7.38583 14.0465C7.1971 14.0676 7.0074 14.0163 6.855 13.903L6.771 13.83L0.721001 7.80603C0.586981 7.67202 0.508419 7.49241 0.500999 7.30303L0.5 7.27403V7.24503C0.50057 7.23034 0.501572 7.21566 0.503 7.20103L0.5 7.27403C0.500351 7.11305 0.552216 6.95641 0.648001 6.82703L0.653999 6.81903C0.674316 6.7926 0.696365 6.76754 0.719999 6.74403L6.77 0.71703C6.90355 0.580364 7.08423 0.499827 7.27515 0.491863C7.46607 0.4839 7.65283 0.54911 7.7973 0.67418C7.94177 0.799251 8.03305 0.974748 8.05251 1.16484C8.07197 1.35493 8.01813 1.54528 7.902 1.69703L7.829 1.78003L3.068 6.52303L16.25 6.52303C16.4489 6.52303 16.6397 6.60205 16.7803 6.7427C16.921 6.88335 17 7.07412 17 7.27303V7.27403Z"
                fill="#212121"
              />
            </svg>
          </button>
          <h4 class="edit_header">
            {{ "Edit how you use your listing" | translate }}
          </h4>
        </div>
        <h4 class="step_title paytype_title">
          Step 2
          <span class="highlight_orange"
            >of <span class="highlight_text">2</span></span
          >
        </h4>
        <p class="edit_text">
          {{ "I would like to be paid in..." | translate }}
        </p>

        <p class="select_option_text">
          {{ "Please select one option:" | translate }}
        </p>

        <div class="option_radioList_outer">
          <div class="radio_box_outer">
            <input
              type="radio"
              id="hostHome"
              hidden
              value="562"
              [(ngModel)]="space.payment_type_id"
              (ngModelChange)="changeTokenValue()"
            />
            <label
              for="hostHome"
              class="radioInner"
              [class.selected]="space.payment_type_id == 562"
            >
              <div class="radio_imgBox">
                <img
                  class="uncheck"
                  src="assets/images/un-checked.svg"
                  alt=""
                />
                <img class="check" src="assets/images/checked.svg" alt="" />
              </div>
              <div class="title_box">
                <h4>{{ "Receive HS Points for hosting" | translate }}</h4>
                <p>
                  {{
                    "And use the HS Points you earn from Hosting to book other homes for free!"
                      | translate
                  }}
                </p>
              </div>
            </label>
          </div>

          <div class="radio_box_outer">
            <input
              type="radio"
              id="cardHome"
              hidden
              value="561"
              [(ngModel)]="space.payment_type_id"
              (ngModelChange)="changeTokenValue()"
            />
            <label
              for="cardHome"
              class="radioInner"
              [class.selected]="space.payment_type_id == 561"
            >
              <div class="radio_imgBox">
                <img
                  class="uncheck"
                  src="assets/images/un-checked.svg"
                  alt=""
                />
                <img class="check" src="assets/images/checked.svg" alt="" />
              </div>
              <div class="title_box">
                <h4>{{ "Receive Cash for hosting" | translate }}</h4>
                <p>
                  {{
                    "Accept card payments for your bookings via transfer wise!"
                      | translate
                  }}
                </p>
              </div>
            </label>
          </div>
        </div>
      </div>

      <!-- <h4 class="step_title paytype_title">{{"STEP" | translate}} <span class="highlight_orange">{{"1 of 3" |
                    translate}}</span> {{": How would you like to use your home to travel?" | translate}}</h4>
            <h4 class="step_sub_title">{{"Please select up to two options here: " | translate}}</h4>


            <div [class.disabled_section]="space.purpose_id==521">

                <h4 class="step_title paytype_title  mt-40">{{"STEP" | translate}} <span class="highlight_orange">{{"2
                        of 3" | translate}}</span> {{": How would you like to be paid for your bookings?" | translate}}
                </h4>
                <h4 class="step_sub_title">{{"Please select one option:" | translate}}</h4>
                <div class="option_radioList_outer">


                    <div class="radio_box_outer">
                        <input type="radio" id="hostHome" hidden value="562" [(ngModel)]="space.payment_type_id">
                        <label for="hostHome" class="radioInner" [class.selected]="space.payment_type_id == 562">
                            <div class="radio_imgBox">
                                <img class="uncheck" src="assets/images/un-checked.svg" alt="">
                                <img class="check" src="assets/images/checked.svg" alt="">
                            </div>
                            <div class="title_box">
                                <h4>{{"Receive HS Points for hosting" | translate}}</h4>
                                <p>{{"And use the HS Points you earn from Hosting to book other homes for free!" |
                                    translate}}
                                </p>
                            </div>
                        </label>
                    </div>

                    <div class="radio_box_outer">
                        <input type="radio" id="cardHome" hidden value="561" [(ngModel)]="space.payment_type_id">
                        <label for="cardHome" class="radioInner" [class.selected]="space.payment_type_id == 561">
                            <div class="radio_imgBox">
                                <img class="uncheck" src="assets/images/un-checked.svg" alt="">
                                <img class="check" src="assets/images/checked.svg" alt="">

                            </div>
                            <div class="title_box">
                                <h4>{{"Payment with debit/credit card" | translate}}</h4>
                                <p>{{"Receive card payments for your bookings via Stripe Connect!" | translate}}</p>
                            </div>
                        </label>
                    </div>
                </div>

                <div class="option_radioList_outer">
                    <div class="availability_header mb-30">
                        <div>
                            <h4 class="step_title paytype_title mt-40">{{"STEP" | translate}} <span
                                    class="highlight_orange">{{"3 of 3" | translate}}</span> {{": How much would you
                                like to charge per night?" | translate}}</h4>
                            <h4 class="step_sub_title">{{"Please enter the rate or use the slider:" | translate}}</h4>

                        </div>
                        <div class="slider_area"
                            [class.disabled_section]="space.payment_type_id == 561 || space.purpose_id==521">

                            <div class="range__container input-field-div"
                                [ngClass]="{'input-token_ar-override': selectedLanguage == 'ar'}">
                                <input type="number" class="token-manual-input-field" placeholder="000"
                                    [(ngModel)]="space.hosting_tokens"
                                    (input)="sliderTokenUpdate(tokenSliderInput, space.hosting_tokens, 'token')"
                                    (change)="handleInputValidation(space, 'hosting_tokens')"
                                    (keydown)="checkRegEx($event)">
                                <span class="token-price-border">{{space.hosting_tokens == 1 ? "HS Point" :"HS Points" |
                                    translate}}</span>
                            </div>
                            <div class="range-token-slider">
                                <input class="range-slider__range" [style.background]="slideBg" type="range" min="1"
                                    max="500" #tokenSliderInput [(ngModel)]="space.hosting_tokens"
                                    (input)="sliderTokenUpdate($event.target, space.hosting_tokens, 'token')"
                                    id="token-slider-input">
                                <span class="range-slider__value">{{space.hosting_tokens}}</span>
                            </div>
                        </div>

                        <div class="slider_area"
                            [class.disabled_section]="space.payment_type_id == 562 || space.purpose_id==521">
                            <div class="range__container input-field-div"
                                [ngClass]="{'input-token_ar-override': selectedLanguage == 'ar'}">
                                <input type="number" class="token-manual-input-field" placeholder="000" min="1"
                                    max="500" [(ngModel)]="space.hosting_amount"
                                    (input)="sliderTokenUpdate(amountSliderInput, space.hosting_amount, 'amount')"
                                    (change)="handleInputValidation(space, 'hosting_amount')"
                                    (keydown)="checkRegEx($event)">
                                <span class="token-price-border">{{ space.hosting_amount == 1 ? "Dollar" :"Dollars" |
                                    translate}} {{"(USD)" | translate}}</span>
                            </div>
                            <div class="range-token-slider range-amount">
                                <input class="range-slider__range" [style.background]="amountSliderBg" type="range"
                                    min="1" max="500" #amountSliderInput [(ngModel)]="space.hosting_amount"
                                    (input)="sliderTokenUpdate($event.target, space.hosting_amount, 'amount')"
                                    id="amount-slider-input">
                                <span class="range-slider__value">{{space.hosting_amount}}</span>
                            </div>
                        </div>
                        <div>
                            <p class="sub">
                                {{"At Holiday Swap, we believe in full transparency"|translate}}
                                <b>{{"(no hidden fees!)"|translate}}</b>
                                {{"please remember to factor in cleaning and other costs into your total."| translate}}

                            </p>
                        </div>
                    </div>
                </div>
            </div> -->
    </div>

    <!-- select address in map -->
    <div
      class="form_detail_inner map_select_wrapper"
      [hidden]="activeStep != 3"
    >
      <h4 class="step_title">
        {{ "Where is your home located?" | translate }}
      </h4>
      <div class="searchInput_box">
        <!-- <input type="text" name="searh" title="search box" placeholder="Enter your home address"> -->
        <input
          #searchSpaceInput
          type="text"
          autocomplete="false"
          name="searh"
          class="form-control input_field"
          (focus)="location.full_address ? '' : (resultHide = false)"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="location.full_address"
          (ngModelChange)="autoCompleteAddress($event)"
          placeholder="{{ 'Enter your home address' | translate }}"
        />
        <div
          class="results"
          [class.visible]="locations.length > 0"
          [hidden]="resultHide"
        >
          <ul class="mb-0">
            <li *ngFor="let location of locations" (click)="onSelect(location)">
              {{ location.full_address }}
            </li>
          </ul>
        </div>
      </div>
      <div id="map_box" class="mapOuter"></div>
    </div>

    <div
      class="form_detail_inner address_confirm_wrapper"
      *ngIf="activeStep == 4"
    >
      <h4 class="step_title">{{ "Edit your listing address" | translate }}</h4>
      <div class="addres_form_box">
        <form [formGroup]="addressForm" autocomplete="off">
          <!-- <div class="from-group mb-30"
                        [class.dirty]="addressForm.get('title').invalid && (addressForm.get('title').dirty || addressForm.get('title').touched)">
                        <label>{{"Home Title*" | translate}}</label>
                        <input type="text" autocomplete="false" class="form-control" formControlName="title" >
                        <ng-container
                            *ngIf="addressForm.get('title').invalid && (addressForm.get('title').dirty || addressForm.get('title').touched)">
                            <span class="error_txt" *ngIf="addressForm.get('title').hasError('required')">{{'Title
                                required'|translate}}</span>
                            <span class="error_txt" *ngIf="addressForm.get('title').hasError('minLength')">{{'Title
                                should be at least 10 characters.'|translate}}</span>
                            <span class="error_txt" *ngIf="addressForm.get('title').hasError('maxLength')">{{'Title
                                should be at most 100 characters.'|translate}}</span>
                        </ng-container>
                    </div> -->
          <div
            class="from-group mb-20"
            [class.dirty]="
              addressForm.get('street_1').invalid &&
              (addressForm.get('street_1').dirty ||
                addressForm.get('street_1').touched)
            "
          >
            <label>{{ "Street*" | translate }}</label>
            <input
              type="text"
              autocomplete="false"
              formControlName="street_1"
              class="form-control"
            />
            <ng-container
              *ngIf="
                addressForm.get('street_1').invalid &&
                (addressForm.get('street_1').dirty ||
                  addressForm.get('street_1').touched)
              "
            >
              <span
                class="error_txt"
                *ngIf="addressForm.get('street_1').hasError('required')"
                >{{
                  "Street
                                required" | translate
                }}</span
              >
              <span
                class="error_txt"
                *ngIf="
                  addressForm.get('street_1').dirty &&
                  !addressForm.get('street_1').hasError('required')
                "
                >{{ "Invalid Address" | translate }}</span
              >
            </ng-container>
          </div>
          <div class="from-group mb-20">
            <input
              type="text"
              autocomplete="false"
              formControlName="street_2"
              class="form-control"
            />
            <ng-container
              *ngIf="
                addressForm.get('street_2').invalid &&
                (addressForm.get('street_2').dirty ||
                  addressForm.get('street_2').touched)
              "
            >
              <span
                class="error_txt"
                *ngIf="addressForm.get('street_2').dirty"
                >{{ "Invalid Address" | translate }}</span
              >
            </ng-container>
          </div>

          <div class="col">
            <!-- [class.pe-none]="space?.address?.latitude != 0 && space?.address?.longitude != 0" -->
            <div
              class="from-group mb-20"
              [class.dirty]="
                addressForm.get('city').invalid &&
                (addressForm.get('city').dirty ||
                  addressForm.get('city').touched)
              "
            >
              <label>{{ "City*" | translate }}</label>
              <input
                type="text"
                autocomplete="false"
                formControlName="city"
                [readonly]="location.city"
                required="location.city"
                class="form-control"
              />
              <ng-container
                *ngIf="
                  addressForm.get('city').invalid &&
                  (addressForm.get('city').dirty ||
                    addressForm.get('city').touched)
                "
              >
                <span
                  class="error_txt"
                  *ngIf="addressForm.get('city').hasError('required')"
                  >{{
                    "City
                                    required" | translate
                  }}</span
                >
                <span
                  class="error_txt"
                  *ngIf="
                    addressForm.get('city').dirty &&
                    !addressForm.get('city').hasError('required')
                  "
                  >{{ "Invalid City" | translate }}</span
                >
              </ng-container>
            </div>
          </div>

          <div class="row mb-20">
            <div class="col">
              <!-- [class.pe-none]="space?.address?.latitude != 0 && space?.address?.longitude != 0" -->
              <div
                class="from-group"
                [class.dirty]="
                  addressForm.get('state').invalid &&
                  (addressForm.get('state').dirty ||
                    addressForm.get('state').touched)
                "
              >
                <label>{{ "State/Prov*" | translate }}</label>
                <input
                  type="text"
                  autocomplete="false"
                  formControlName="state"
                  [readonly]="location.state"
                  required="location.state"
                  class="form-control"
                />
                <ng-container
                  *ngIf="
                    addressForm.get('state').invalid &&
                    (addressForm.get('state').dirty ||
                      addressForm.get('state').touched)
                  "
                >
                  <span
                    class="error_txt"
                    *ngIf="addressForm.get('state').hasError('required')"
                    >{{
                      "State
                                        required" | translate
                    }}</span
                  >
                  <span
                    class="error_txt"
                    *ngIf="
                      addressForm.get('state').dirty &&
                      !addressForm.get('state').hasError('required')
                    "
                    >{{ "Invalid State" | translate }}</span
                  >
                </ng-container>
              </div>
            </div>

            <div class="col-auto small_input">
              <div
                class="from-group"
                [class.dirty]="
                  addressForm.get('postcode_1').invalid &&
                  (addressForm.get('postcode_1').dirty ||
                    addressForm.get('postcode_1').touched)
                "
              >
                <label>{{ "Post Code*" | translate }}</label>
                <input
                  type="text"
                  autocomplete="false"
                  formControlName="postcode_1"
                  class="form-control"
                />
                <ng-container
                  *ngIf="
                    addressForm.get('postcode_1').invalid &&
                    (addressForm.get('postcode_1').dirty ||
                      addressForm.get('postcode_1').touched)
                  "
                >
                  <span
                    class="error_txt"
                    *ngIf="addressForm.get('postcode_1').hasError('required')"
                    >{{
                      "Post Code
                                        required" | translate
                    }}</span
                  >
                  <span
                    class="error_txt"
                    *ngIf="addressForm.get('postcode_1').hasError('numeric')"
                    >{{
                      "Only number
                                        allowed" | translate
                    }}</span
                  >
                </ng-container>
              </div>
            </div>
          </div>
          <!-- <div class="row mb-20">
                        <div class="col">
                            <div class="from-group"
                                [class.pe-none]="space?.address?.latitude != 0 && space?.address?.longitude != 0"
                                [class.dirty]="addressForm.get('country').invalid && (addressForm.get('country').dirty || addressForm.get('country').touched)">
                                <label>{{"Country/Region*" | translate}}</label>
                                <input type="text" autocomplete="false" formControlName="country"
                                    [readonly]="location.country" required="location.country" class="form-control">
                                <ng-container
                                    *ngIf="addressForm.get('country').invalid && (addressForm.get('country').dirty || addressForm.get('country').touched)">
                                    <span class="error_txt"
                                        *ngIf="addressForm.get('country').hasError('required')">{{'Country
                                        required'|translate}}</span>
                                </ng-container>
                            </div>
                        </div>
                    </div> -->

          <!-- New dropdown design  -->
          <div class="row mb-20">
            <div class="col">
              <div
                class="form-field from-group"
                [class.dirty]="
                  addressForm.controls['country'].hasError('required') &&
                  addressForm.controls['country'].touched &&
                  addressForm.controls['country'].invalid
                "
              >
                <label>{{ "Country/Region*" | translate }}</label>
                <app-google-country-dropdown
                  [list]="countries"
                  #countriesRef
                  [search]="true"
                  [outsideClick]="outsideClick"
                  [placeholder]="
                    selectedCountry || 'Select country' | translate
                  "
                  [selected]="selectedCountry"
                  (onSelect)="onSelectCountry($event)"
                >
                </app-google-country-dropdown>

                <p
                  class="error_txt margin-text"
                  *ngIf="
                    addressForm.controls['country'].hasError('required') &&
                    addressForm.controls['country'].touched &&
                    addressForm.controls['country'].invalid
                  "
                >
                  {{ "Please select any country" | translate }}
                </p>

                <ng-container *ngFor="let item of serverErrors; let i = index">
                  <p
                    class="error_txt"
                    *ngIf="
                      item?.path == 'country' || item?.path == 'countryCode'
                    "
                  >
                    {{ item?.message }}
                  </p>
                </ng-container>
              </div>
            </div>
          </div>
          <!-- <div class="row mb-20" *ngIf="selectedCountry === ''">
                        <div class="col">
                            <div class="form-field">
                                <label>{{"Country/Region*" | translate}}*</label>
                                <app-combo-box [list]="countries" #countriesRef [search]="true"
                                    (onSelect)="onSelectCountry($event)" [outsideClick]="outsideClick"
                                    [placeholder]="'Select country' | translate" [selected]="selectedCountry">
                                </app-combo-box>
                                <p class="red-alert"
                                    *ngIf="addressForm.controls['country'].hasError('required') && addressForm.controls['country'].touched">
                                    {{"Please select any country" | translate}}
                                </p>

                                <ng-container *ngFor="let item of serverErrors; let i = index">
                                    <p class="red-alert" *ngIf="item?.path == 'country' || item?.path == 'countryCode'">
                                        {{item?.message}}</p>
                                </ng-container>
                            </div>
                        </div>
                    </div> -->

          <div class="map-section mb-20" id="map-section">
            <div class="locationmap_header">
              <h2>Show your specific location</h2>
              <p>
                Make it clear to guests where your place is located. We’ll only
                share your address after they’ve made a reservation.
              </p>
              <span class="map_header_location">
                Click on the map to update the address
              </span>
            </div>
            <div class="location_map" (click)="nextMap()">
              <app-google-map-view
                *ngIf="space?.address?.latitude && space?.address?.longitude"
                #localtionMap
                [height]="'300px'"
                [latitude]="space?.address?.latitude"
                [zoom]="15"
                [longitude]="space?.address?.longitude"
              ></app-google-map-view>
            </div>
          </div>

          <!-- <div class="from-group mb-30">
                        <label>{{"Rooms, Beds & Guests*" | translate}}</label>
                        <div class="row">
                            <div class="col">
                                <input type="number" min="1" max="20" formControlName="bedrooms"
                                    class="form-control roomInput"
                                    [class.inputError]="addressForm.get('bedrooms').invalid && (addressForm.get('bedrooms').dirty || addressForm.get('bedrooms').touched)">
                            </div>
                            <div class="col">
                                <input type="number" min="1" max="20" formControlName="bathrooms"
                                    class="form-control bathInput"
                                    [class.inputError]="addressForm.get('bathrooms').invalid && (addressForm.get('bathrooms').dirty || addressForm.get('bathrooms').touched)">
                            </div>
                            <div class="col">
                                <input type="number" min="1" max="20" formControlName="guests"
                                    class="form-control guestInput"
                                    [class.inputError]="addressForm.get('guests').invalid && (addressForm.get('guests').dirty || addressForm.get('guests').touched)">
                            </div>
                        </div>
                        <ng-container
                            *ngIf="(addressForm.get('bedrooms').invalid && (addressForm.get('bedrooms').dirty || addressForm.get('bedrooms').touched))||(addressForm.get('bathrooms').invalid && (addressForm.get('bathrooms').dirty || addressForm.get('bathrooms').touched))||(addressForm.get('guests').invalid && (addressForm.get('guests').dirty || addressForm.get('guests').touched))">
                            <span class="error_txt"
                                *ngIf="(addressForm.get('bedrooms').hasError('required')) || (addressForm.get('bathrooms').hasError('required')) || (addressForm.get('guests').hasError('required')) || (addressForm.get('bedrooms').hasError('maxNumber')) || (addressForm.get('bathrooms').hasError('maxNumber')) || (addressForm.get('guests').hasError('maxNumber')) || (addressForm.get('bedrooms').hasError('minNumber')) || (addressForm.get('bathrooms').hasError('minNumber')) || (addressForm.get('guests').hasError('minNumber'))">{{'Please
                                add details'|translate}}</span>
                        </ng-container> -->

          <!-- <span class="error_txt" *ngIf="(addressForm.get('bedrooms').hasError('maxNumber')) || (addressForm.get('bathrooms').hasError('maxNumber')) || (addressForm.get('guests').hasError('maxNumber'))">{{'Max number allowed is 20.'|translate}}</span> -->
          <!-- </div> -->

          <!-- <div class="from-group mb-30"
                        [class.dirty]="addressForm.get('description').invalid && (addressForm.get('description').dirty || addressForm.get('description').touched)">
                        <label>{{"Overview*" | translate}}</label>
                        <textarea description="" autocomplete="false" formControlName="description" rows="10"
                            class="form-control text-area"
                            placeholder="{{'Tell us a little about your home' | translate}}"></textarea>
                        <ng-container
                            *ngIf="addressForm.get('description').invalid && (addressForm.get('description').dirty || addressForm.get('description').touched)">
                            <span class="error_txt"
                                *ngIf="addressForm.get('description').hasError('required')">{{'Description
                                required'|translate}}</span>
                            <span class="error_txt"
                                *ngIf="addressForm.get('description').hasError('minLength')">{{'Description should be at
                                least 50 characters.'|translate}}</span>
                            <span class="error_txt"
                                *ngIf="addressForm.get('description').hasError('maxLength')">{{'Description should be at
                                most 1000 characters.'|translate}}</span>
                        </ng-container>
                    </div> -->

          <!-- <div class="availability_header mb-30">
                        <label>{{"When will your home be unavailable?" | translate}}</label>
                        <p class="sub">{{"Add dates when you are not available" | translate}}</p>
                        <app-home-unavailablity-date [space]="space.id" [(dates)]="space.dates"
                            (datesChange)="onDatesSelected($event)"></app-home-unavailablity-date>
                    </div>
                    <div class="availability_header" *ngIf="space.purpose_id != 521 && space.payment_type_id != 561">
                        <div class="toggle_area">
                            <label>{{"Allow instant booking" | translate}}</label>
                            <div class="toggle-switch">
                                <label class="switch">
                                    <input type="checkbox" (click)="setSpaceInstantType()"
                                        [checked]="space?.is_instant == '1'">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </div>
                        <p>{{"Allow your home to be booked instantly with automatic confirmation. Guests love the
                            convenience and on average hosts earn more HS Points from instant bookings." | translate}}</p>
                    </div> -->
        </form>
      </div>
    </div>

    <!-- added for edit home title - edit your listing details -->
    <div
      class="form_detail_inner address_confirm_wrapper edit-home-listing"
      [hidden]="activeStep != 11"
    >
      <div class="edit_wrapper">
        <button type="button" class="backBtn" (click)="backToPrev()">
          <svg
            width="17"
            height="15"
            viewBox="0 0 17 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17 7.27403C17 7.45527 16.9344 7.63037 16.8152 7.76696C16.6961 7.90355 16.5316 7.99238 16.352 8.01703L16.25 8.02403H3.066L7.829 12.767C7.96425 12.9003 8.04389 13.08 8.05184 13.2697C8.0598 13.4595 7.99548 13.6452 7.87187 13.7893C7.74826 13.9335 7.57456 14.0254 7.38583 14.0465C7.1971 14.0676 7.0074 14.0163 6.855 13.903L6.771 13.83L0.721001 7.80603C0.586981 7.67202 0.508419 7.49241 0.500999 7.30303L0.5 7.27403V7.24503C0.50057 7.23034 0.501572 7.21566 0.503 7.20103L0.5 7.27403C0.500351 7.11305 0.552216 6.95641 0.648001 6.82703L0.653999 6.81903C0.674316 6.7926 0.696365 6.76754 0.719999 6.74403L6.77 0.71703C6.90355 0.580364 7.08423 0.499827 7.27515 0.491863C7.46607 0.4839 7.65283 0.54911 7.7973 0.67418C7.94177 0.799251 8.03305 0.974748 8.05251 1.16484C8.07197 1.35493 8.01813 1.54528 7.902 1.69703L7.829 1.78003L3.068 6.52303L16.25 6.52303C16.4489 6.52303 16.6397 6.60205 16.7803 6.7427C16.921 6.88335 17 7.07412 17 7.27303V7.27403Z"
              fill="#212121"
            />
          </svg>
        </button>
        <h4 class="step_title arrow_button_title">
          {{ "Edit your listing details" | translate }}
        </h4>
      </div>
      <div class="addres_form_box">
        <form [formGroup]="addressForm" autocomplete="off">
          <div class="hsg_togglewrapper">
            <div>
              <p>Use HSG assistant for this listing?</p>
              <p class="desciption_toggle">
                Optimizing listing description:
                <span>
                  {{ this.space.is_hsg_assistant == 1 ? "Yes" : "No" }}
                </span>
              </p>
            </div>
            <div>
              <div class="toggle_area">
                <div class="toggle-switch Optimizing_togle">
                  <label class="switch">
                    <input
                      type="checkbox"
                      name="is_hsg_assistant"
                      [checked]="space.is_hsg_assistant"
                      [ngModelOptions]="{ standalone: true }"
                      [value]="space.is_hsg_assistant"
                      [(ngModel)]="space.is_hsg_assistant"
                      (change)="checkHSGAssistance($event)"
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div
            class="from-group mb-30"
            [class.dirty]="
              addressForm.get('title').invalid &&
              (addressForm.get('title').dirty ||
                addressForm.get('title').touched)
            "
          >
            <label>{{ "Home Title*" | translate }}</label>
            <input
              type="text"
              autocomplete="false"
              class="form-control"
              formControlName="title"
            />
            <ng-container
              *ngIf="
                addressForm.get('title').invalid &&
                (addressForm.get('title').dirty ||
                  addressForm.get('title').touched)
              "
            >
              <span
                class="error_txt"
                *ngIf="addressForm.get('title').hasError('required')"
                >{{
                  "Title
                                required" | translate
                }}</span
              >
              <span
                class="error_txt"
                *ngIf="addressForm.get('title').hasError('minLength')"
                >{{
                  "Title
                                should be at least 10 characters." | translate
                }}</span
              >
              <span
                class="error_txt"
                *ngIf="addressForm.get('title').hasError('maxLength')"
                >{{
                  "Title
                                should be at most 100 characters." | translate
                }}</span
              >
            </ng-container>
          </div>

          <div class="from-group mb-30">
            <label>{{ "Beds, Bathrooms & Guests*" | translate }}</label>
            <div class="row">
              <div class="col">
                <input
                  type="number"
                  min="1"
                  max="20"
                  formControlName="bedrooms"
                  class="form-control roomInput"
                  [readonly]="description_loading"
                  (wheel)="onWheel($event)"
                  [class.inputError]="
                    addressForm.get('bedrooms').invalid &&
                    (addressForm.get('bedrooms').dirty ||
                      addressForm.get('bedrooms').touched)
                  "
                />
              </div>
              <div class="col">
                <input
                  type="number"
                  min="1"
                  max="20"
                  formControlName="bathrooms"
                  class="form-control bathInput"
                  [readonly]="description_loading"
                  (wheel)="onWheel($event)"
                  [class.inputError]="
                    addressForm.get('bathrooms').invalid &&
                    (addressForm.get('bathrooms').dirty ||
                      addressForm.get('bathrooms').touched)
                  "
                />
              </div>
              <div class="col">
                <input
                  type="number"
                  min="1"
                  max="20"
                  formControlName="guests"
                  class="form-control guestInput"
                  [readonly]="description_loading"
                  (wheel)="onWheel($event)"
                  [class.inputError]="
                    addressForm.get('guests').invalid &&
                    (addressForm.get('guests').dirty ||
                      addressForm.get('guests').touched)
                  "
                />
              </div>
            </div>
            <ng-container
              *ngIf="
                (addressForm.get('bedrooms').invalid &&
                  (addressForm.get('bedrooms').dirty ||
                    addressForm.get('bedrooms').touched)) ||
                (addressForm.get('bathrooms').invalid &&
                  (addressForm.get('bathrooms').dirty ||
                    addressForm.get('bathrooms').touched)) ||
                (addressForm.get('guests').invalid &&
                  (addressForm.get('guests').dirty ||
                    addressForm.get('guests').touched))
              "
            >
              <!-- <span class="error_txt"
                                *ngIf="(addressForm.get('bedrooms').hasError('required')) || (addressForm.get('bathrooms').hasError('required')) || (addressForm.get('guests').hasError('required')) || (addressForm.get('bedrooms').hasError('maxNumber')) || (addressForm.get('bathrooms').hasError('maxNumber')) || (addressForm.get('guests').hasError('maxNumber')) || (addressForm.get('bedrooms').hasError('minNumber')) || (addressForm.get('bathrooms').hasError('minNumber')) || (addressForm.get('guests').hasError('minNumber'))">{{'Please
                                add details'|translate}}</span> -->

              <span
                class="error_txt"
                *ngIf="
                  addressForm.get('bedrooms').hasError('required') ||
                  addressForm.get('bathrooms').hasError('required') ||
                  addressForm.get('guests').hasError('required') ||
                  addressForm.get('bedrooms').hasError('maxNumber') ||
                  addressForm.get('bathrooms').hasError('maxNumber') ||
                  addressForm.get('guests').hasError('maxNumber') ||
                  addressForm.get('bedrooms').hasError('minNumber') ||
                  addressForm.get('bathrooms').hasError('minNumber') ||
                  addressForm.get('guests').hasError('minNumber') ||
                  addressForm.get('guests').hasError('numeric') ||
                  addressForm.get('bathrooms').hasError('numeric') ||
                  addressForm.get('bedrooms').hasError('numeric')
                "
                >{{
                  "Please add
                                details" | translate
                }}
              </span>
            </ng-container>

            <!-- <span class="error_txt" *ngIf="(addressForm.get('bedrooms').hasError('maxNumber')) || (addressForm.get('bathrooms').hasError('maxNumber')) || (addressForm.get('guests').hasError('maxNumber'))">{{'Max number allowed is 20.'|translate}}</span> -->
          </div>

          <div
            class="from-group mb-30"
            [class.dirty]="
              addressForm.get('description').invalid &&
              (addressForm.get('description').dirty ||
                addressForm.get('description').touched)
            "
          >
            <label>{{ "Overview*" | translate }}</label>
            <textarea
              description=""
              autocomplete="false"
              formControlName="description"
              rows="10"
              class="form-control text-area"
              placeholder="{{ 'Tell us a little about your home' | translate }}"
            ></textarea>
            <ng-container
              *ngIf="
                addressForm.get('description').invalid &&
                (addressForm.get('description').dirty ||
                  addressForm.get('description').touched)
              "
            >
              <span
                class="error_txt"
                *ngIf="addressForm.get('description').hasError('required')"
                >{{
                  "Description
                                required" | translate
                }}</span
              >
              <span
                class="error_txt"
                *ngIf="addressForm.get('description').hasError('minLength')"
                >{{
                  "Description should be at
                                least 50 characters." | translate
                }}</span
              >
              <span
                class="error_txt"
                *ngIf="addressForm.get('description').hasError('maxLength')"
                >{{
                  "Description should be at
                                most 1000 characters." | translate
                }}</span
              >
            </ng-container>
          </div>
        </form>
      </div>

      <!-- type of place -->
      <div class="form_details_inner home_type_wrapper">
        <p class="type_title">{{ "Type of place" | translate }}</p>
        <div>
          <label class="type_label" for="title">{{
            "Please select one option:" | translate
          }}</label>
          <div class="type_list">
            <ng-container *ngFor="let type of homeTypes">
              <div class="form-control-radio">
                <input
                  type="radio"
                  name="hometype"
                  [id]="'types' + type.id"
                  [value]="type.id"
                  [(ngModel)]="place_type"
                  (ngModelChange)="placeTypeChange($event)"
                  [checked]="place_type == type.id"
                />
                <label [for]="'types' + type.id">
                  <span class="name">{{ type.name }}</span>
                  <span class="desc">{{ type.desc }}</span>
                </label>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <!-- amenities -->
      <section class="amenities_section">
        <div class="title step-sub--title">
          <p>{{ "Amenities" | translate }}</p>
          <span class="edit_btn" (click)="openAmenitiesModal()">{{
            "Edit" | translate
          }}</span>
        </div>
        <div
          class="features_check_outer features_check_outer-ameneties pe-none allAmenities"
          [class.no-amenities]="totalSelectedAmenities <= 0"
          [appLoad]="priceLoading"
        >
          <form id="amenitiesListForm">
            <h5 class="featuresTitle" *ngIf="markedAmenitiesLength > 0">
              {{ "Features" | translate }}
            </h5>
            <ng-container
              *ngFor="
                let amenities of markedCategories.amenities;
                let i = index
              "
            >
              <div class="featureCheckBox" *ngIf="amenities.isActive">
                <input
                  type="checkbox"
                  [id]="'aminti' + i"
                  [value]="amenities.id"
                  [checked]="amenities.isActive"
                />
                <label [for]="'aminti' + i">{{
                  amenities.name | translate
                }}</label>
              </div>
            </ng-container>

            <h5 class="featuresTitle" *ngIf="marketRulesLength > 0">
              {{ "House Rules" | translate }}
            </h5>
            <ng-container
              *ngFor="let rules of markedCategories.rules; let i = index"
            >
              <div class="featureCheckBox" *ngIf="rules.isActive">
                <input
                  type="checkbox"
                  [id]="'rule' + i"
                  [value]="rules.id"
                  [checked]="rules.isActive"
                />
                <label [for]="'rule' + i">{{ rules.name | translate }}</label>
              </div>
            </ng-container>

            <h5 class="featuresTitle" *ngIf="markedAccessibilitiesLength > 0">
              {{ "Accessibility" | translate }}
            </h5>
            <ng-container
              *ngFor="
                let accessibilities of markedCategories.accessibilities;
                let i = index
              "
            >
              <div class="featureCheckBox" *ngIf="accessibilities.isActive">
                <input
                  type="checkbox"
                  [id]="'access' + i"
                  [value]="accessibilities.id"
                  [checked]="accessibilities.isActive"
                />
                <label [for]="'access' + i">{{
                  accessibilities.name | translate
                }}</label>
              </div>
            </ng-container>

            <h5 class="featuresTitle" *ngIf="marketSafetiesLength > 0">
              {{ "health" | translate }} & {{ "safety" | translate }}
            </h5>
            <ng-container
              *ngFor="let safeties of markedCategories.safeties; let i = index"
            >
              <div class="featureCheckBox" *ngIf="safeties.isActive">
                <input
                  type="checkbox"
                  [id]="'safeties' + i"
                  [value]="safeties.id"
                  [checked]="safeties.isActive"
                />
                <label [for]="'safeties' + i">{{
                  safeties.name | translate
                }}</label>
              </div>
            </ng-container>

            <ng-container *ngIf="totalSelectedAmenities <= 0">
              <p>{{ "There are no amenities to show." | translate }}</p>
            </ng-container>
          </form>
        </div>
        <div
          class="amenities_block_btn_wrapper"
          [class.show-all]="showAllAmenities"
          *ngIf="!hideAmenitiesButton && totalSelectedAmenities > 2"
        >
          <div class="shadow_box"></div>
          <button class="block_btn" (click)="toggleAmenities()">
            {{
              this.showAllAmenities
                ? ("See fewer
                        options" | translate)
                : ("See all amenities" | translate)
            }}
          </button>
        </div>
      </section>

      <div *ngIf="description_loading" class="transparent_loader">
        <div class="load-wrapper">
          <div class="lds-ellipsis center">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <p>
            {{
              "We're crafting a captivating title and description just for you. Stay tuned!"
            }}
          </p>
        </div>
      </div>
    </div>

    <div class="form_detail_inner map_select_wrapper" *ngIf="activeStep == 5">
      <span class="back_arrow" (click)="back()">
        <img src="/assets/images/back_arrow.svg" alt="" />
      </span>
      <h4 class="step_title">{{ "Edit your listing address" | translate }}</h4>
      <h4 class="map_header">
        {{ "Is the pin in the right spot?" | translate }}
      </h4>
      <p class="step-sub--title">
        Your address is only shared with guests only after they have made a
        reservation.
      </p>
      <div class="searchInput_box searchInput_box_PinMap">
        <input
          [ngModelOptions]="{ standalone: true }"
          disabled="disabled"
          [(ngModel)]="combainedAddress"
        />
      </div>

      <div class="pin-spot--map position-relative">
        <!-- <app-custom-address-map (passUpdatedLocation)="UpdatedPinAdd($event)" [address]="combainedAddress"
                    #pinSpotLocation></app-custom-address-map> -->
        <app-custom-address-map
          (passUpdatedLocation)="UpdatedPinAdd($event)"
          [markerUpdated]="markerUpdated"
          [longitude]="space.address.longitude"
          [latitude]="space.address.latitude"
          [address]="combainedAddress"
          #pinSpotLocation
        ></app-custom-address-map>
      </div>
    </div>

    <!-- home details -->
    <div
      class="form_detail_inner address_confirm_wrapper edit-home-listing"
      [hidden]="activeStep != 6"
    >
      <div class="edit_wrapper">
        <button type="button" class="backBtn" (click)="backToPrev()">
          <svg
            width="17"
            height="15"
            viewBox="0 0 17 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17 7.27403C17 7.45527 16.9344 7.63037 16.8152 7.76696C16.6961 7.90355 16.5316 7.99238 16.352 8.01703L16.25 8.02403H3.066L7.829 12.767C7.96425 12.9003 8.04389 13.08 8.05184 13.2697C8.0598 13.4595 7.99548 13.6452 7.87187 13.7893C7.74826 13.9335 7.57456 14.0254 7.38583 14.0465C7.1971 14.0676 7.0074 14.0163 6.855 13.903L6.771 13.83L0.721001 7.80603C0.586981 7.67202 0.508419 7.49241 0.500999 7.30303L0.5 7.27403V7.24503C0.50057 7.23034 0.501572 7.21566 0.503 7.20103L0.5 7.27403C0.500351 7.11305 0.552216 6.95641 0.648001 6.82703L0.653999 6.81903C0.674316 6.7926 0.696365 6.76754 0.719999 6.74403L6.77 0.71703C6.90355 0.580364 7.08423 0.499827 7.27515 0.491863C7.46607 0.4839 7.65283 0.54911 7.7973 0.67418C7.94177 0.799251 8.03305 0.974748 8.05251 1.16484C8.07197 1.35493 8.01813 1.54528 7.902 1.69703L7.829 1.78003L3.068 6.52303L16.25 6.52303C16.4489 6.52303 16.6397 6.60205 16.7803 6.7427C16.921 6.88335 17 7.07412 17 7.27303V7.27403Z"
              fill="#212121"
            />
          </svg>
        </button>
        <h4 class="step_title arrow_button_title">
          {{ "Edit listing amenities" | translate }}
        </h4>
      </div>
      <div class="features_check_outer">
        <form [formGroup]="aminitiesForm">
          <h5 class="featuresTitle">{{ "Features" | translate }}</h5>
          <ng-container
            *ngFor="let amenities of categories.amenities; let i = index"
          >
            <div class="featureCheckBox">
              <input
                class="amenities-editList"
                type="checkbox"
                [id]="'aminti' + i"
                formArrayName="amenities"
                [value]="amenities.id"
                [checked]="amenities.isActive"
                (change)="onFeatureCheckboxChange($event, 'amenities')"
              />
              <label [for]="'aminti' + i">{{
                amenities.name | translate
              }}</label>
            </div>
          </ng-container>

          <h5 class="featuresTitle">{{ "House Rules" | translate }}</h5>
          <ng-container *ngFor="let rules of categories.rules; let i = index">
            <div class="featureCheckBox">
              <input
                class="amenities-editList"
                type="checkbox"
                [id]="'rule' + i"
                formArrayName="rules"
                [value]="rules.id"
                [checked]="rules.isActive"
                (change)="onFeatureCheckboxChange($event, 'rules')"
              />
              <label [for]="'rule' + i">{{ rules.name | translate }}</label>
            </div>
          </ng-container>

          <h5 class="featuresTitle">{{ "Accessibility" | translate }}</h5>
          <ng-container
            *ngFor="
              let accessibilities of categories.accessibilities;
              let i = index
            "
          >
            <div class="featureCheckBox">
              <input
                class="amenities-editList"
                type="checkbox"
                [id]="'access' + i"
                formArrayName="accessibilities"
                [value]="accessibilities.id"
                [checked]="accessibilities.isActive"
                (change)="onFeatureCheckboxChange($event, 'accessibilities')"
              />
              <label [for]="'access' + i">{{
                accessibilities.name | translate
              }}</label>
            </div>
          </ng-container>

          <h5 class="featuresTitle">
            {{ "health" | translate }} & {{ "safety" | translate }}
          </h5>
          <ng-container
            *ngFor="let safeties of categories.safeties; let i = index"
          >
            <div class="featureCheckBox">
              <input
                class="amenities-editList"
                type="checkbox"
                [id]="'safeties' + i"
                formArrayName="safeties"
                [value]="safeties.id"
                [checked]="safeties.isActive"
                (change)="onFeatureCheckboxChange($event, 'safeties')"
              />
              <label [for]="'safeties' + i">{{
                safeties.name | translate
              }}</label>
            </div>
          </ng-container>
        </form>
      </div>
    </div>

    <div
      class="form_detail_inner address_confirm_wrapper"
      [hidden]="activeStep != 7"
    >
      <h4 class="step_title">
        {{
          "Almost done! Please tell us a bit about your home in your own words."
            | translate
        }}
      </h4>
      <form [formGroup]="homeDetailForm" autocomplete="off">
        <div class="addres_form_box">
          <div
            class="from-group mb-30"
            [class.dirty]="
              homeDetailForm.get('title').invalid &&
              (homeDetailForm.get('title').dirty ||
                homeDetailForm.get('title').touched)
            "
          >
            <label>{{ "Home Title*" | translate }}</label>
            <input
              type="text"
              autocomplete="false"
              class="form-control"
              formControlName="title"
            />
            <ng-container
              *ngIf="
                homeDetailForm.get('title').invalid &&
                (homeDetailForm.get('title').dirty ||
                  homeDetailForm.get('title').touched)
              "
            >
              <span
                class="error_txt"
                *ngIf="homeDetailForm.get('title').hasError('required')"
                >{{
                  "Title
                                required" | translate
                }}</span
              >
              <span
                class="error_txt"
                *ngIf="homeDetailForm.get('title').hasError('minLength')"
                >{{
                  "Title
                                should be at least 10 characters." | translate
                }}</span
              >
            </ng-container>
          </div>
          <div
            class="from-group mb-30"
            [class.dirty]="
              homeDetailForm.get('description').invalid &&
              (homeDetailForm.get('description').dirty ||
                homeDetailForm.get('description').touched)
            "
          >
            <label>{{ "Overview*" | translate }}</label>
            <textarea
              description=""
              autocomplete="false"
              formControlName="description"
              rows="10"
              class="form-control text-area"
              placeholder="{{ 'Tell us a little about your home' | translate }}"
            ></textarea>
            <ng-container
              *ngIf="
                homeDetailForm.get('description').invalid &&
                (homeDetailForm.get('description').dirty ||
                  homeDetailForm.get('description').touched)
              "
            >
              <span
                class="error_txt"
                *ngIf="homeDetailForm.get('description').hasError('required')"
                >{{
                  "Description
                                required" | translate
                }}</span
              >
              <span
                class="error_txt"
                *ngIf="homeDetailForm.get('description').hasError('minLength')"
                >{{
                  "Description should be
                                at least 50 characters." | translate
                }}</span
              >
            </ng-container>
          </div>
          <div class="from-group mb-30">
            <label>{{ "Rooms, Beds & Guests*" | translate }}</label>
            <div class="row">
              <div class="col">
                <input
                  type="number"
                  min="1"
                  max="20"
                  formControlName="bedrooms"
                  class="form-control roomInput"
                  [class.inputError]="
                    homeDetailForm.get('bedrooms').invalid &&
                    (homeDetailForm.get('bedrooms').dirty ||
                      homeDetailForm.get('bedrooms').touched)
                  "
                />
              </div>
              <div class="col">
                <input
                  type="number"
                  min="1"
                  max="20"
                  formControlName="bathrooms"
                  class="form-control bathInput"
                  [class.inputError]="
                    homeDetailForm.get('bathrooms').invalid &&
                    (homeDetailForm.get('bathrooms').dirty ||
                      homeDetailForm.get('bathrooms').touched)
                  "
                />
              </div>
              <div class="col">
                <input
                  type="number"
                  min="1"
                  max="20"
                  formControlName="guests"
                  class="form-control guestInput"
                  [class.inputError]="
                    homeDetailForm.get('guests').invalid &&
                    (homeDetailForm.get('guests').dirty ||
                      homeDetailForm.get('guests').touched)
                  "
                />
              </div>
            </div>
            <ng-container
              *ngIf="
                (homeDetailForm.get('bedrooms').invalid &&
                  (homeDetailForm.get('bedrooms').dirty ||
                    homeDetailForm.get('bedrooms').touched)) ||
                (homeDetailForm.get('bathrooms').invalid &&
                  (homeDetailForm.get('bathrooms').dirty ||
                    homeDetailForm.get('bathrooms').touched)) ||
                (homeDetailForm.get('guests').invalid &&
                  (homeDetailForm.get('guests').dirty ||
                    homeDetailForm.get('guests').touched))
              "
            >
              <span
                class="error_txt"
                *ngIf="
                  homeDetailForm.get('bedrooms').hasError('required') ||
                  homeDetailForm.get('bathrooms').hasError('required') ||
                  homeDetailForm.get('guests').hasError('required') ||
                  homeDetailForm.get('bedrooms').hasError('maxNumber') ||
                  homeDetailForm.get('bathrooms').hasError('maxNumber') ||
                  homeDetailForm.get('guests').hasError('maxNumber') ||
                  homeDetailForm.get('bedrooms').hasError('minNumber') ||
                  homeDetailForm.get('bathrooms').hasError('minNumber') ||
                  homeDetailForm.get('guests').hasError('minNumber')
                "
                >{{
                  "Please
                                add details" | translate
                }}</span
              >
              <!-- <span class="error_txt" *ngIf="(homeDetailForm.get('bedrooms').hasError('maxNumber')) || (homeDetailForm.get('bathrooms').hasError('maxNumber')) || (homeDetailForm.get('guests').hasError('maxNumber'))">{{'Max number allowed is 20.'|translate}}</span> -->
            </ng-container>
          </div>
        </div>
      </form>

      <div class="availability_header mb-30">
        <label>{{ "When will your home be unavailable?" | translate }}</label>
        <p class="sub">
          {{ "Add dates when you are not available" | translate }}
        </p>
        <app-home-unavailablity-date
          [space]="space.id"
          [(dates)]="space.dates"
          (datesChange)="onDatesSelected($event)"
        ></app-home-unavailablity-date>
      </div>
      <div class="availability_header" *ngIf="space.purpose_id != 521">
        <div class="toggle_area">
          <label>{{ "Allow instant booking" | translate }}</label>
          <div class="toggle-switch">
            <label class="switch">
              <input
                type="checkbox"
                (click)="setSpaceInstantType()"
                [checked]="space?.is_instant == '1'"
              />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        <p>
          {{
            "Allow your home to be booked instantly with automatic confirmation. Guests love the convenience
                    and on average hosts earn more HS Points from instant bookings."
              | translate
          }}
        </p>
      </div>
    </div>
    <!-- phone validation part -->
    <div
      class="form_detail_inner phone_verificatin_wrapper"
      *ngIf="activeStep == 8"
    >
      <h4 class="step_title" *ngIf="!isPhoneVerified">
        {{
          "Please confirm your mobile number. Your number will only
                be used for booking purposes within the App." | translate
        }}
      </h4>
      <h4 class="step_title" *ngIf="isPhoneVerified">
        {{ "You are verified." | translate }}
      </h4>
      <div class="phone_verify_outer">
        <ng-container *ngIf="!isPhoneVerified">
          <div class="from-group">
            <label>{{ "Please enter your mobile number*" | translate }}</label>
            <app-phone-number-input
              #input
              [(onChange)]="verification.phone"
              (onChangeInput)="onPhonenumberChange($event)"
            ></app-phone-number-input>
          </div>
          <button
            class="phone-verification-btn popup_btn"
            (click)="otpInputs.clearOTP(); onRequest()"
            [disabled]="!input.valid"
            [class.pe-none]="btn_block || sentTime > 1"
          >
            {{
              (sentTime < 1
                ? "Send confirmation code"
                : "Resend confirmation code"
              ) | translate
            }}
          </button>

          <div class="resend_box" *ngIf="!resendHide">
            <div class="resendTxt">
              {{ "Resend code in" | translate }} {{ remaining }}
            </div>
            <!-- <button  class="resendBtn txtBtn"> {{"Resend" | translate}} </button> -->
          </div>
          <div [hidden]="!hideOtpInput">
            <p class="otp_labeltext">
              {{ "Please enter the validation number we’ve sent*" | translate }}
            </p>
            <app-phone-otp-input
              #otpInputs
              (otpChange)="patchOtp($event)"
            ></app-phone-otp-input>
          </div>
        </ng-container>
        <ng-container *ngIf="isPhoneVerified">
          <h5>Ph: {{ userData.contact_no }}</h5>
        </ng-container>
      </div>
    </div>
    <div class="form_detail_inner succes_wrapper" *ngIf="activeStep == 10">
      <h2 class="successTitle" *ngIf="!space.id">
        {{ "Thank you for listing your home!" | translate }}
      </h2>
      <h2 class="successTitle" *ngIf="space.id">
        {{ "Thank you for updating!" | translate }}
      </h2>
      <h4 class="step_title" *ngIf="!space.id">
        {{
          "Now check out all the awesome things you’ll get, too!" | translate
        }}
      </h4>
      <!-- <h4 class="step_title" *ngIf="space.id">Now take the opportunity to share your amazing place!</h4> -->

      <ul class="points_list list-unstyled" *ngIf="userData.spaces_count == 0">
        <li>
          <strong>{{ "10 HS Points" | translate }}</strong>
          {{ "for listing your home." | translate }}
        </li>
        <li>
          <strong>{{ space.images.length * 10 }} of 60 HS Points</strong> for
          uploading {{ space.images.length }} of 6 pictures.
        </li>
      </ul>
    </div>

    <div
      class="form_detail_inner address_confirm_wrapper"
      *ngIf="activeStep == 12"
    >
      <h4 class="step_title">{{ "Edit your listing details" | translate }}</h4>
      <div class="addres_form_box">
        <form [formGroup]="addressForm" autocomplete="off">
          <div class="availability_header mb-30">
            <label>{{
              "When will your home be unavailable?" | translate
            }}</label>
            <p class="sub">
              {{ "Add dates when you are not available" | translate }}
            </p>
            <app-home-unavailablity-date
              [space]="space.id"
              [(dates)]="space.dates"
              (datesChange)="onDatesSelected($event)"
            ></app-home-unavailablity-date>
          </div>
          <div
            class="availability_header"
            *ngIf="space.purpose_id != 521 && space.payment_type_id != 561"
          >
            <div class="toggle_area">
              <label>{{ "Allow instant booking" | translate }}</label>
              <div class="toggle-switch">
                <label class="switch">
                  <input
                    type="checkbox"
                    (click)="setSpaceInstantType()"
                    [checked]="space?.is_instant == '1'"
                  />
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <p>
              {{
                "Allow your home to be booked instantly with automatic confirmation. Guests love the
                            convenience and on average hosts earn more HS Points from instant bookings."
                  | translate
              }}
            </p>
          </div>
        </form>
      </div>
    </div>

    <div
      class="form_detail_inner edit_get_paid_wrapper"
      [hidden]="activeStep != 9"
    >
      <h4 class="step_title">{{ "Edit how much you get paid" | translate }}</h4>

      <p class="edit_model_text">
        {{ "The nightly price for my listing is..." | translate }}
      </p>
      <span class="edit_header">
        {{ "Please enter the rate or use the slider:" | translate }}
      </span>

      <div
        class="slider_area"
        [class.disabled_section]="
          space.payment_type_id == 561 || space.purpose_id == 521
        "
      >
        <!-- <h2>{{space.hosting_tokens}} {{"HS Points per night" | translate}} (${{space.hosting_tokens}}/N)</h2> -->
        <div
          class="range__container input-field-div"
          [ngClass]="{ 'input-token_ar-override': selectedLanguage == 'ar' }"
        >
          <input
            type="number"
            class="token-manual-input-field"
            placeholder="000"
            [(ngModel)]="space.hosting_tokens"
            (input)="
              sliderTokenUpdate(tokenSliderInput, space.hosting_tokens, 'token')
            "
            (change)="handleInputValidation(space, 'hosting_tokens', 500)"
            (keydown)="checkRegEx($event)"
          />
          <span class="token-price-border">{{
            space.hosting_tokens == 1 ? "HS Point" : ("HS Points" | translate)
          }}</span>
        </div>
        <div class="range-token-slider">
          <input
            class="range-slider__range"
            [style.background]="slideBg"
            type="range"
            min="1"
            max="500"
            #tokenSliderInput
            [(ngModel)]="space.hosting_tokens"
            (input)="
              sliderTokenUpdate($event.target, space.hosting_tokens, 'token')
            "
            id="token-slider-input"
          />
          <span class="range-slider__value">{{ space.hosting_tokens }}</span>
        </div>
      </div>

      <div
        class="slider_area"
        [class.disabled_section]="
          space.payment_type_id == 562 || space.purpose_id == 521
        "
      >
        <div
          class="range__container input-field-div"
          [ngClass]="{ 'input-token_ar-override': selectedLanguage == 'ar' }"
        >
          <input
            type="number"
            class="token-manual-input-field"
            placeholder="000"
            min="1"
            max="5000"
            [(ngModel)]="space.hosting_amount"
            (input)="
              sliderTokenUpdate(
                amountSliderInput,
                space.hosting_amount,
                'amount'
              )
            "
            (change)="handleInputValidation(space, 'hosting_amount', 5000)"
            (keydown)="checkRegEx($event)"
          />
          <span class="token-price-border"
            >{{
              space.hosting_amount == 1 ? "Dollar" : ("Dollars" | translate)
            }}
            {{ "(USD)" | translate }}</span
          >
        </div>
        <div class="range-token-slider range-amount">
          <input
            class="range-slider__range"
            [style.background]="amountSliderBg"
            type="range"
            min="1"
            max="5000"
            #amountSliderInput
            [(ngModel)]="space.hosting_amount"
            (input)="
              sliderTokenUpdate($event.target, space.hosting_amount, 'amount')
            "
            id="amount-slider-input"
          />
          <span class="range-slider__value">{{ space.hosting_amount }}</span>
        </div>
      </div>
      <p class="edit_text_info">
        {{ "At Holiday Swap, we believe in full transparency" | translate }}
        <b>{{ "(no hidden fees!)" | translate }}</b>
        {{
          "please remember to factor in cleaning and other costs into
                your total." | translate
        }}
      </p>
    </div>
  </div>
  <!-- <div class="succes_bottomBox text-center" *ngIf="activeStep>7">
        <button type="button" class="resendTxt txtBtn" *ngIf="formSignUp" (click)="gotoExplore()"> {{"Explore Holiday Swap" | translate}} </button>
        <button type="button" class="resendTxt txtBtn" *ngIf="!formSignUp" (click)="gotoExplore()"> {{"View Home Listing" | translate}} </button>
    </div> -->
  <div class="add_form_footer">
    <!-- <button type="button" class="btn exitBtn" (click)="moveStep('prev')">{{activeStep==1?'Exit':'Back'}}</button> -->
    <button
      type="button"
      class="btn updateBtn"
      id="updateBtn"
      (click)="saveClick()"
      *ngIf="activeStep != 2"
    >
      <span *ngIf="activeStep <= 8 && activeStep != 5 && activeStep != 6">{{
        "Update preferences" | translate
      }}</span>
      <span *ngIf="activeStep == 5" class="editupdatePreferenceBtn">{{
        "Update address" | translate
      }}</span>
      <span *ngIf="activeStep == 6">{{ "Update amenities" | translate }}</span>
      <span *ngIf="activeStep == 9">{{ "Save preferences" | translate }}</span>
      <span *ngIf="activeStep == 11">{{
        "Update listing details" | translate
      }}</span>
      <span *ngIf="activeStep == 12">{{
        "Update preferences" | translate
      }}</span>
      <!-- <span *ngIf="activeStep==1">Earning options</span>
            <span *ngIf="activeStep==2">Location</span>
            <span *ngIf="activeStep==3">Confirm Address</span>
            <span *ngIf="activeStep==4 || activeStep==5">Home Details</span>
            <span *ngIf="activeStep==6">Verify & Finish</span>
            <span *ngIf="activeStep==7">Finish up!</span> -->
      <!-- <img src="assets/icons/next_arrow.svg" alt="next arrow"> -->
    </button>
    <div *ngIf="activeStep == 2" class="w-100">
      <button
        type="button"
        class="btn updateBtn"
        *ngIf="isEditSwapbtn"
        (click)="saveActivestep($event, 'next', space)"
      >
        {{ "Next" | translate }}
      </button>
      <button
        type="button"
        class="btn updateBtn"
        *ngIf="isEditTokenbtn"
        (click)="saveActivestep($event, 'updatePreference', space)"
      >
        {{ "Save preferences" | translate }}
      </button>
    </div>

    <!-- <button type="button" class="btn updateBtn"  *ngIf="activeStep=8" >
            <span>{{"Save preferences" | translate}}</span>
        </button> -->
  </div>
</div>

<div class="imageUploadPopup" *ngIf="showImg">
  <div class="popupHeader">
    <button type="button" class="backBtn" (click)="showImg = false">
      <img alt="img" src="/assets/images/back_arrow.svg" />
    </button>
    <div class="action_btns">
      <button type="button" class="actionBtn" (click)="updateImg.click()">
        {{ (clickedImg.imgUrl ? "Switch" : "Upload") | translate }}
        <input
          type="file"
          name=""
          accept=".png, .jpg, .jpeg"
          #updateImg
          hidden
          (change)="picImageFile($event)"
        />
      </button>
      <button
        type="button"
        class="actionBtn"
        *ngIf="clickedImg.imgUrl && clickedImg.tagId != 555"
        (click)="removeImg = true"
      >
        {{ "Remove" | translate }}
      </button>
    </div>
  </div>
  <div class="imageDisplay">
    <img
      [src]="clickedImg.imgUrl ? clickedImg.imgUrl : placeholderImg"
      (click)="updateImg.click()"
      alt=""
    />
  </div>
  <div class="imgDisplay_footer">
    <button class="updateImgBtn popup_btn" (click)="updateImgBtn()">
      {{ "Update Image" | translate }}
    </button>
  </div>
</div>

<div class="remove_alretBackdrop" *ngIf="removeImg"></div>
<div class="removeAlretPopup" *ngIf="removeImg">
  <button class="closeBtn" (click)="removeImg = false">
    <img alt="close icon" src="/assets/images/cancel_ic.svg" />
  </button>
  <img src="/assets/icons/remove_icon.svg" alt="" class="imgIcon" />
  <h5>{{ "Remove Image?" | translate }}</h5>
  <p>
    {{
      "Are you sure you want to remove Image? you can't undo this action"
        | translate
    }}
  </p>
  <div class="text-center">
    <button class="btn removeBtn" (click)="onRemove()">
      {{ "Remove Image" | translate }}
    </button>
    <br />
    <button class="txtBtn btn cancelBtn" (click)="removeImg = false">
      {{ "Cancel" | translate }}
    </button>
  </div>
</div>

<div class="image_croper" *ngIf="cropEnabled">
  <div class="popupHeader">
    <button type="button" class="backBtn" (click)="closeCroper()">
      <img alt="img" src="/assets/images/back_arrow.svg" />
    </button>
  </div>
  <p class="cropper_header">
    {{ "You can adjust the size of your image here!" | translate }}
  </p>
  <div class="cropDisplay">
    <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="true"
      [containWithinAspectRatio]="false"
      [aspectRatio]="4 / 3"
      [onlyScaleDown]="true"
      [roundCropper]="false"
      [resizeToWidth]="550"
      [style.display]="showCropper ? null : 'none'"
      format="png"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()"
      (loadImageFailed)="loadImageFailed()"
    >
    </image-cropper>
  </div>
  <div class="imgDisplay_footer">
    <button class="cancel_btn btn" (click)="closeCroper()">
      {{ "Cancel" | translate }}
    </button>
    <button class="update_btn btn" (click)="uploadSpaceImage()">
      {{ "Update" | translate }}
    </button>
  </div>
</div>

<!-- loadign -->
<div class="loading absolute" *ngIf="loading">
  <div class="spinner">
    <div class="logo">
      <svg
        width="1024px"
        height="1024px"
        viewBox="0 0 113 99"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <title>Group 5@1x</title>
        <g
          id="Symbols"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g id="Group-5">
            <polygon
              id="Fill-1"
              fill="#F29000"
              points="56.4671512 98.4529581 112.578221 26.2249349 71.8009884 0.453051163"
            ></polygon>
            <polygon
              id="Fill-2"
              fill="#FED900"
              points="40.9330674 0.000262790698 0.000788372093 26.1137744 56.453486 98.4626814 56.453486 98.4574256 56.4587419 98.4521698 41.0145326 0.0607046512"
            ></polygon>
            <polygon
              id="Fill-3"
              fill="#F29000"
              points="56.4576907 98.4529581 56.4734581 98.4398186 56.5365279 12.604493 56.4471791 12.6754465 40.9320163 0.00105116279"
            ></polygon>
            <polygon
              id="Fill-4"
              fill="#E84C1E"
              points="56.4482302 12.6749209 56.4666256 98.4471767 71.8004628 0.455153488"
            ></polygon>
          </g>
        </g>
      </svg>
    </div>
    <!--div class="bounce1"></div>
		<div class="bounce2"></div>
		<div class="bounce3"></div-->
  </div>
</div>

<!-- Reject booking confirm dialog Kiran KV 18-11-2022 -->
<!-- div class="modal accept-booking-dialog" aria-hidden="true" id="rejectBookingDialog" data-bs-backdrop="static"> -->
<div
  class="modal accept-booking-dialog"
  aria-hidden="true"
  data-bs-backdrop="static"
  id="fiatBooking"
>
  <!-- <button type="button" class="btn-close close-popup-style" (click)="closeBackDrop()" data-bs-dismiss="modal">
    </button> -->
  <div class="modal-dialog modal-lg">
    <div class="modal-content swap-request-content-box">
      <div class="modal-body swap-request-body-style">
        <div class="swap-request-box">
          <h4>
            {{
              "Are you sure that you want to change your home from BOOKINGS to SWAP ONLY? This means that
                        you cannot receive cash bookings for your home."
                | translate
            }}
          </h4>
          <div class="btn_wrapper">
            <button
              type="button"
              data-bs-dismiss="modal"
              (click)="closeBackDrop()"
              class="btn btn-light cancel-btn-style btn-common-style underlined_text"
            >
              {{ "Cancel" | translate }}
            </button>
            <button
              type="button"
              class="btn btn-light accept-btn-style btn-common-style"
              (click)="proceedBooking()"
            >
              {{ "Proceed" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal accept-booking-dialog"
  aria-hidden="true"
  data-bs-backdrop="static"
  id="fiatBookingtoken"
>
  <!-- <button type="button" class="btn-close close-popup-style" (click)="closeBackDrop()" data-bs-dismiss="modal">
    </button> -->
  <div class="modal-dialog modal-lg">
    <div class="modal-content swap-request-content-box">
      <div class="modal-body swap-request-body-style">
        <div class="swap-request-box">
          <h4>
            {{
              "Are you sure you want to change your payments from CASH to HS POINTS ONLY? This means that you
                        cannot receive cash bookings for your home." | translate
            }}
          </h4>
          <div class="btn_wrapper">
            <button
              type="button"
              data-bs-dismiss="modal"
              (click)="closeBackDrop()"
              class="btn btn-light cancel-btn-style btn-common-style underlined_text"
            >
              {{ "Cancel" | translate }}
            </button>
            <button
              type="button"
              class="btn btn-light accept-btn-style btn-common-style"
              (click)="proceedBooking()"
            >
              {{ "Proceed" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
