import { HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of, throwError } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { ExploreSpacesService } from 'src/app/shared/services/explore-spaces.service';
import { PromoService } from 'src/app/shared/services/promo.service';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { UserService } from 'src/app/shared/services/user.service';

@Injectable()
export class TrendingRegionResolver implements Resolve<any> {
  constructor(public exploreService : ExploreSpacesService, private router: Router, public subscriptionService : SubscriptionService) { 
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<HttpEvent<any>> {
   
    return this.exploreService.getFeaturedLocations().pipe(
        map((response: any) => {
            return response.data;
        }), 
    );
  }

}
