<div class="bottom-sheet-overlay" *ngIf="_visible">
  <div
    class="bottom-sheet"
    *ngIf="_visible"
    [@slideUpToggle]
    #bottomSheetContainer
    [ngStyle]="{ height: defaultHeight, 'max-height': defaultMaxHeight }"
  >
    <div
      class="bottom-sheet-container"
      [ngStyle]="{
        height: '100%',
        'max-height': maxHeight,
        'min-width': minWidth || '300px'
      }"
    >
      <div
        *ngIf="closeHeader"
        class="bottom-sheet-container-header"
        (pan)="onPan($event)"
      >
        <div class="bottom-sheet-container-header__title">
          <ng-content select="[bottomSheet-header]"></ng-content>
        </div>

        <span class="close" (click)="close()"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="ai ai-Cross"
          >
            <path d="M20 20L4 4m16 0L4 20" /></svg
        ></span>
      </div>
      <div
        class="bottom-sheet-container-header__subtitle"
        *ngIf="hasBottomSheetTitle()"
      >
        <ng-content select="[bottomSheet-title]"></ng-content>
      </div>

      <div class="bottom-sheet-container-body">
        <ng-content select="[bottomSheet-body]"></ng-content>
      </div>
      <div class="bottom-sheet-container-footer">
        <ng-content select="[bottomSheet-footer]"></ng-content>
      </div>
    </div>
  </div>
</div>
