<div class="popupBackground" *ngIf="showMaxHomePopup"></div>
<div class="verify-modal" *ngIf="showMaxHomePopup">
    <div class="close-section">
        <div class="skip-and-close"> <span class="close-popup" (click)="closeAddHomePopUp()">
			<img alt="" src="/assets/images/cancel_ic.svg"></span> </div>
    </div>
    <div class="verify-title">
        <h2 >{{"Congrats!" | translate}}</h2>
    </div>
	<div class="verify-content">
		
		<p class="message-text-style">{{"You have reached the maximum number of allowed listings. Please contact us should you require more!" | translate}}</p>
	</div>
	
	<ng-container >
		<div class="verify-btn-main">
			<div class="verify-btn-head">
				<button type="button" class="verify-btn-popup" (click)="contactUs()"> {{"Contact Us" | translate}} </button>
			</div>
		</div>
	</ng-container>
</div>