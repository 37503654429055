import { SnackbarService } from './../../../../shared/services/snackbar.service';
import { MetadataService } from './../../../../shared/services/metadata.service';
import { LanguageService } from './../../../../shared/services/language.service';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  HostListener,
} from '@angular/core';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { Router } from '@angular/router';
import { SpaceService } from 'src/app/shared/services/space.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ClevertapService } from 'src/app/shared/services/clevertap.service';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { FilterService } from 'src/app/shared/services/filter.service';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { ExploreFilterModalComponent } from '../explore-filter-modal/explore-filter-modal.component';
import { HttpService } from '../../../../shared/services/http.service';

declare var $: any;

@Component({
  selector: 'app-map-view-home-cards-list',
  templateUrl: './map-view-home-cards-list.component.html',
  styleUrls: ['./map-view-home-cards-list.component.scss'],
})
export class MapViewHomeCardsListComponent implements OnInit {
  // public indexArray= [4,16];

  isDesktopView: boolean = window.screen.width > 1024;
  advanceBtn: boolean = false;
  isPremiumMember: boolean = false;
  public loading = false;
  userData: any;
  public filter: any = {
    purpose_ids: 522,
    // purpose_ids:null,
  };
  // public pagination = {
  //   total: 0,
  //   page: 1,
  //   // skip: 0,
  //   // limit: 5,
  // };
  public location: any = {};
  public selectedLanguage = 'en';
  searchedAddress: any;
  public categories: any = {
    space_purposes: [],
  };

  user: any = [];
  block_btn: boolean = true;
  openAddHomeForm: boolean = false;
  public email_verified = false;
  public addHomePopup = false;
  popoupBackdrop = false;

  @HostListener('window:resize', ['$event'])
  handleResize(event: KeyboardEvent) {
    this.isDesktopView = window.screen.width > 1024;
  }

  public arb = false;
  @Input() public btnLoad = true;
  @Input() public spaceList = [];
  @Input() public total = 0;
  @Input() public map = null;
  @Output() public filterChange = new EventEmitter();
  @Output() public clearFilter = new EventEmitter();
  @Output() public loadMoreTrigger = new EventEmitter<number>();
  @Input() public pagination: any;

  @ViewChild('filterModal') filterModal: ExploreFilterModalComponent;

  // public config: SwiperOptions = {
  // 	direction: "horizontal",
  // 	slidesPerView: 1.4,
  // 	spaceBetween: 15,
  // 	keyboard: true,
  // 	mousewheel: true,
  // 	scrollbar: false,
  // 	navigation: false,
  // 	pagination: false,
  // 	virtual: true,
  // 	preloadImages: false,

  // 	breakpoints: {
  // 		640: {
  // 			slidesPerView: 3,
  // 		}
  // 	}
  // };
  private _prevIndex;

  // @ViewChild("swiper", { static: false }) swiper?: SwiperComponent;

  constructor(
    private cd: ChangeDetectorRef,
    private router: Router,
    private spaceService: SpaceService,
    private notificationService: NotificationService,
    private userService: UserService,
    private httpService: HttpService,
    private clevertapService: ClevertapService,
    private toastr: ToastrService,
    private filterService: FilterService,
    private googleAnalyticsService: GoogleAnalyticsService,
    public translate: TranslateService,
    private metadataService: MetadataService,
    public languageService: LanguageService,
    private snackbarService: SnackbarService,
    private cookieService: CookieService
  ) {
    if (this.cookieService.get('search-address')) {
      this.advanceBtn = true;
    } else {
      this.advanceBtn = false;
    }
    const userFullData = localStorage.getItem('userDataTEST');
    this.userData = JSON.parse(userFullData);
    if (this.userData) {
      this.isPremiumMember = this.userData.subscription?.planName != 'Lite';
    } else {
      this.userService.currentUserSubject.subscribe((data) => {
        this.userData = data;
        this.isPremiumMember = this.userData.subscription?.planName != 'Lite';
      });
    }
  }

  ngOnInit(): void {
    if (localStorage.getItem('searchFilters') != null) {
      this.filter = JSON.parse(localStorage.getItem('searchFilters'));
    }

    // if(this.filter.purpose_ids == 522){
    //   alert(1)
    //   this.filter.purpose_ids == 522;
    // }else{

    //   alert(2)

    this.filter.purpose_ids = this.filterService?.purposeIds;

    // this.filter.purpose_ids = this.filterService.purposeIds;
    // }
    this.getMe();
    const loadLang = this.languageService.GetLanguage() + '';
    if (loadLang == 'null' || loadLang == '') {
      this.selectedLanguage = 'en';
    } else {
      this.selectedLanguage = loadLang;
    }
    this.languageService.languageUpdates.subscribe((lang) => {
      this.selectedLanguage = lang;

      if (this.selectedLanguage == 'ar') {
        this.arb = true;
      } else {
        this.arb = false;
      }
    });

    if (this.httpService.ACCESS_TOKEN) {
      this.metadataService
        .getSpaceNewCategories()
        .subscribe((response: { data: any }) => {
          this.categories = response.data;

          // this.categories.space_purposes.unshift({ id: -1, name: "All" });
          // this.categories.space_purposes.push({ id: -2, name: "IBS" });
        });
    }
  }

  filterSpaceList(spaces) {
    return spaces.filter(
      (item) => item.type !== 'BUY_TOKEN' && item.type === 'CARD'
    );
  }

  onPageChange(page: number) {
    this.btnLoad = true;
    this.loadMoreTrigger.emit(page);
    document.getElementById('mainOuter').scrollTop = 100;
  }

  onPageBoundsCorrection(page: number) {
    this.btnLoad = true;
    this.loadMoreTrigger.emit(page);
  }

  getMe() {
    if (this.httpService.ACCESS_TOKEN) {
      this.userService.getMe().subscribe((res) => {
        this.block_btn = false;
        this.user = res.data;
        this.email_verified = res.data.email_verified;
      });
    }
  }

  openFilterModal() {
    this.filterModal.open();
  }

  getPersentage(amount) {
    const discount = Math.round((parseInt(amount) * 20) / 100);
    const offerAmt = amount - discount;
    return offerAmt;
  }

  public flooringNumber(num: number): number {
    return Math.floor(num);
  }

  public onPurposeTypeChange(purpose_ids, name) {
    this.filter.purpose_ids = purpose_ids;
    this.filterService.purposeIds = purpose_ids;
    this.filterService.purposeName = name;
    // this.onFilterChange();
    this.filterChange.emit(purpose_ids);
    // this.clearFilter.emit(purpose_ids);

    $('.card_list').scrollTop(0);

    this.cookieService.set('searchFilter', JSON.stringify(this.filter));
    localStorage.setItem('searchFilters', JSON.stringify(this.filter));
  }

  public handleSearchByAdditionalFilter(payload) {
    this.filterChange.emit(payload);
  }

  public handleClearSearch() {
    this.filterChange.emit(null);
  }

  // public onFilterChange() {

  // 	if (this.filter.purpose_ids != -1) {
  // 		this.filter = { ...this.filter, ...this.location };
  // 		this.searchedAddress = this.filter;
  // 	}
  // 	// this.spaceList = [];
  // 	// this.pagination.page = 1;
  // 	// this.getSpaces();
  // }
  // public getSpaces() {
  // 	this.loading = true;
  // 	/*if (this.mapComponent) {
  // 		this.mapComponent.clear();
  // 	}*/
  // 	const query = { ...this.searchedAddress, ...this.pagination };

  // 	this.spaceService.getSpaces(this._getUriWithParam(query)).subscribe((res: any) => {
  // 		this.loading = false;

  // 		this.spaceList = res.data;

  // 	}, (err) => {
  // 		this.loading = false;

  // 	}).add(() => {
  // 	});
  // }
  // public onSlideChange() {

  // 	const space = this.spaceList[this.swiper.swiperRef.activeIndex];
  // 	this.onSlideChanged.emit(space);

  // 	this._prevIndex = this.swiper.swiperRef.activeIndex;
  // 	if (this.spaceList.length === this.swiper.swiperRef.activeIndex + 1) {

  // 		//	this.onEnd.emit();

  // 		//$(".popular-regions-main .swiper-slide ").addClass("dynamic-swipe-style");
  // 	}

  // 	else {

  // 		//$(".popular-regions-main .swiper-slide ").removeClass("dynamic-swipe-style");
  // 		//	this.isLastSpace = false;
  // 	}

  // }

  // public update() {

  // 	this.swiper.swiperRef.updateSlides();
  // 	this.swiper.swiperRef.updateSize();

  // 	this.cd.detectChanges();
  // }

  // public nextSpaceArrow() {
  // 	this.swiper.swiperRef.slideNext(100);
  // }

  // public previousSpaceArrow() {
  // 	this.swiper.swiperRef.slidePrev(100);
  // }

  // goToViewList() {

  clear(purpose_ids) {
    this.filterService.purposeIds = purpose_ids;
    // this.filter.purpose_ids = purpose_id;
    this.cookieService.set('searchFilter', JSON.stringify(this.filter));
    localStorage.setItem('searchFilters', JSON.stringify(this.filter));
  }

  ngOnDestroy(): void {
    // localStorage.removeItem('navUrl');
  }

  public navigateToHome(id) {
    if (!id) return;

    const localdata = localStorage.getItem('navUrl');

    if (this.map) {
      localStorage.setItem(
        'currentMapCoordinates',
        JSON.stringify(this.map.getCenter())
      );

      localStorage.setItem('currentMapZoomLevel', this.map.getZoom());
    }

    if (this.clevertapService.isWebView) {
      this.googleAnalyticsService.eventEmitter(
        'home_clicks_app',
        'Clicks',
        '/view-home/home-details-ibs/' + id
      );
    } else {
      this.googleAnalyticsService.eventEmitter(
        'home_clicks_web',
        'Clicks',
        '/view-home/home-details-ibs/' + id
      );
    }

    if (localdata != 'SearchList') {
      localStorage.setItem('navUrl', 'Trendings');
    }
    if (this.isDesktopView) {
      window.open('/view-home/home-details-ibs/' + id, '_blank');
    } else {
      this.router.navigate(['/view-home/home-details-ibs/' + id]);
    }
    window.localStorage.setItem('swapCancelRedirectUri', this.router.url);
  }

  public onLike(event: Event, space, index) {
    event.stopPropagation();
    this.notificationService.setFriend(space.user);

    this.spaceService.likeSpace(space.id, space.is_favourited).subscribe(
      (response) => {
        // space.is_favourited = !this.space.is_favourited;
        this.spaceList[index].is_favourited = !space.is_favourited;
        if (this.spaceList[index].is_favourited) {
          this.snackbarService.show({
            message: this.translate.instant(
              'This home has been saved to your favourites!'
            ),
            type: 'success',
            config: {
              class: 'centered',
            },
          });
        } else {
          this.snackbarService.show({
            message: this.translate.instant(
              'Removed home from your favourites'
            ),
            type: 'success',
            config: {
              class: 'centered',
            },
          });
        }
        if (this.spaceList[index].is_favourited) {
          const _tempuser = {
            name: this.notificationService.getSelectedFriend().first_name,
            email: this.notificationService.getSelectedFriend().email,
            friendId: this.notificationService.getSelectedFriend().id,
            userId: this.userService.getCurrentUser().id,
            title: 'Your home was liked!',
            notificationType: 4,
            user_identity:
              this.notificationService.getSelectedFriend().clevertap_identity,
            clevertap_id:
              this.notificationService.getSelectedFriend().clevertap_id,
            message:
              this.userService.getCurrentUser().first_name +
              " liked your home. See if it's a match!",
          };

          this.clevertapService.sendNotification(_tempuser);
        }
      },
      (err) => {
        this.toastr.error(this.translate.instant(err), 'Error');
      }
    );
  }

  goToTokenPurchase() {
    // if (!this.email_verified) {
    //   this.userService.verifyMail.next(true);
    //   return;
    // }
  }

  handlePremium() {
    this.block_btn = false;

    // if (!this.user?.email_verified) {
    //   this.userService.verifyMail.next(true);
    //   return;
    // }
    if (this.isDesktopView) {
      this.router.navigate(['/settings/subscriptions']);
    } else {
      //   this.premiumAccountBottomSheet.toggle();
      this.router.navigate(['/settings/subscriptions']);
      // this.router.navigate([
      //   '/',
      //   { outlets: { modal: ['modal', 'settings', 'subscriptions'] } },
      // ]);
    }
  }

  listHomes() {
    this.addHomePopup = true;
    this.openAddHomeForm = true;
    this.backdropToggle(true);
    if (!this.isDesktopView) {
      $('.filter_outer').addClass('hide');
    }
    // this.backdropToggle(true);
  }

  closeAddFormPopup() {
    this.openAddHomeForm = false;
    this.backdropToggle(false);
    if (!this.isDesktopView) {
      $('.filter_outer').removeClass('hide');
    }
  }

  backdropToggle(status: boolean) {
    this.popoupBackdrop = status;
  }

  updateBtnLoad(btnLoad, pagination) {
    this.btnLoad = btnLoad;
    this.pagination = pagination;
  }
}
