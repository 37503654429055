import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";

@Component({
  selector: 'app-phone-otp-input',
  templateUrl: './phone-otp-input.component.html',
  styleUrls: ['./phone-otp-input.component.scss']
})
export class PhoneOtpInputComponent implements OnInit {

  activePasteSubmition: boolean = false;
	public code = {
		one: "",
		two: "",
		three: "",
		four: "",
		five: "",
		six: "",
		};
	@Input() public otp: number | string;
	@Output() public otpChange = new EventEmitter();
	@ViewChild("first") firstInput: ElementRef;

	constructor(private el: ElementRef) { }

	ngOnInit(): void {
		// document.addEventListener("paste", function (e) {
		// 	let data = e.clipboardData.getData('Text');
		// 	let splittedNumbers = data.split("");


		// 	[].forEach.call(document.querySelectorAll("input[type=tel]"), (node, index) => {
		// 		// And set input value to the relative character

		// 		node.value = splittedNumbers[index];

		// 		if (index == 3){
		// 			this.otpChange.emit(code);
		// 		}
		// 	});
		// });

		document.addEventListener("paste", this.onPasteCode.bind(this));
		// document.addEventListener("paste", (evt) => this.onPasteCode(evt));
	}

	clearOTP(){
		this.code = {
			one: "",
			two: "",
			three: "",
			four: "",
			five: "",
			six: "",
			};
	}

	onPasteCode(e){
		let data = e.clipboardData.getData('Text');
			let splittedNumbers = data.split("");

			[].forEach.call(document.querySelectorAll("input[type=tel]"), (node, index) => {
				// And set input value to the relative character

				node.value = splittedNumbers[index];

				if (index == 5){

					this.otpChange.emit(data);
				}
			});
	}

	onLastEntry() {

		const keys = Object.keys(this.code);
		const code = keys.map((key, index) => {
			return this.code[key];
		}).join("");
		if (code.length === 6) {
			this.otpChange.emit(code);
			return;
		}
	}

	ngAfterViewInit() {
		(this.firstInput.nativeElement as HTMLInputElement).focus();
	}

	pasteCode(): void {

		// document.addEventListener("paste", function (e) {
		// 	// if the target is a text input
		// 	if (e.target.type === "text") {
		// 		var data = e.clipboardData.getData('Text');
		// 		// split clipboard text into single characters
		// 		data = data.split('');
		// 		// find all other text inputs
		// 		[].forEach.call(document.querySelectorAll("input[type=text]"), (node, index) => {
		// 			// And set input value to the relative character
		// 			node.value = data[index];
		// 		});
		// 	}
		// });
	}

}
