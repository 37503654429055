export const hasUserID = (): MethodDecorator => {
  return (_target: any, _propertyKey: string, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value;

    descriptor.value = function (...args: any[]) {
      const userID = localStorage.getItem("id");

      if (userID !== null) {
        return originalMethod.apply(this, args);
      } else {
        return null;
      }
    };

    return descriptor;
  };
};
