<div class="explore-navigation" [class.static]="hiddenTitle">
  <h2 *ngIf="!hiddenTitle">Select your style</h2>

  <div class="explore-navigation-container">
    <div *ngIf="categories.length" #scrollDiv class="explore-navigation-items">
      <div
        *ngFor="let item of categories"
        class="category-item"
        [class.active]="activeCategory === item.id"
        (click)="clickCategoryItem(item.id)"
      >
        <div class="explore-navigation-item">
          <img [src]="item.src" alt="category" />
        </div>
        <span>{{ item.text }}</span>
      </div>
    </div>

    <div *ngIf="!categories.length" class="explore-navigation-items">
      <div
        *ngFor="let item of skeletonCategory()"
        class="category-item"
        [class.active]="activeCategory === item.id"
      >
        <div class="explore-navigation-item">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <style>
              .spinner_ajPY {
                transform-origin: center;
                animation: spinner_AtaB 0.75s infinite linear;
              }
              @keyframes spinner_AtaB {
                100% {
                  transform: rotate(360deg);
                }
              }
            </style>
            <path
              d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
              opacity=".25"
            />
            <path
              d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"
              class="spinner_ajPY"
            />
          </svg>
        </div>
        <span></span>
      </div>
    </div>

    <div
      class="arrow-next"
      [class.static]="hiddenTitle"
      *ngIf="categories.length"
    >
      <img
        src="/assets/icons/Next.svg"
        alt="filter"
        (click)="controlScrolling()"
      />
    </div>

    <div
      class="explore-filter"
      *ngIf="isDesktopView || router.url !== '/map-view-list'"
      (click)="openFilterModal()"
    >
      Filters
      <img src="/assets/icons/Filter.svg" alt="filter" />
    </div>
  </div>
</div>

<app-explore-filter-modal
  #filterModal
  (onFilter)="filter.emit($event)"
  (onClear)="closeFilterModal()"
>
</app-explore-filter-modal>
