import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: 'app-explore-hero',
  templateUrl: './explore-hero.component.html',
  styleUrls: ['./explore-hero.component.scss']
})
export class ExploreHeroComponent implements OnInit {
  @Input() region: any = {
    label: undefined,
    params: undefined
  };

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  goToMobileSearch() {
    this.router.navigate([`/search-homes`]);
  }

  imgError(event: any) {
    event.target.src = 'assets/images/banner/Home-Hero-Banner-default.png';
  }
}
