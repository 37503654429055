import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-card-brand-svg',
	templateUrl: './card-brand-svg.component.html',
	styleUrls: ['./card-brand-svg.component.scss']
})
export class CardBrandSvgComponent implements OnInit {
	@Input() public brand: string;
	constructor() { }

	ngOnInit(): void {

	}

}
