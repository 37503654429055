import { NotificationService } from 'src/app/shared/services/notification.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { ToastrService } from 'ngx-toastr';
import { ILoginRequest } from 'src/app/shared/interfaces/auth';
import { environment } from 'src/environments/environment';
import clevertap from 'clevertap-web-sdk';
import { CookieService } from 'ngx-cookie-service';
import { ClevertapService } from 'src/app/shared/services/clevertap.service';
import { UserService } from 'src/app/shared/services/user.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { v4 as uuidv4 } from 'uuid';
import { PusherService } from 'src/app/shared/services/pusher.service';
import { Channel } from 'pusher-js';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { TranslateService } from '@ngx-translate/core';
//import { runInThisContext } from 'vm';
import sha256 from 'crypto-js/sha256';
import hex from 'crypto-js/enc-hex';

declare var $: any;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  token: string | undefined;
  public user: {
    email?: string;
    password?: string;
    first_name?: string;
    last_name?: string;
    invite_code?: string;
    g_recaptcha_response?: string;
    date_of_birth?: string;
    clevertap_identity?: string;
  } = {
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    invite_code: '',
    g_recaptcha_response: '',
    date_of_birth: '',
    clevertap_identity: '',
  };

  public userLogin: ILoginRequest = {
    username: '',
    password: '',
  };
  private signupuid = '';
  public userName = '';
  public signup_res: any;
  public terms = false;
  public termsOne = true;
  public queryParams = null;
  private randomDefaultDates = [
    '1995-11-01',
    '1996-05-11',
    '1995-10-04',
    '1994-04-23',
    '1993-05-15',
    '1992-03-22',
    '1991-01-15',
    '1990-02-14',

    '1989-01-02',
    '1988-04-04',
    '1987-02-11',
    '1986-03-08',
    '1985-06-12',
    '1984-04-16',
    '1983-08-16',
    '1982-09-19',
    '1981-12-23',
    '1980-10-28',
  ];

  submitted: boolean = false;

  public error: string;
  public lastnameBlank = false;
  public firstnameBlank = false;
  public recaptchBlank = false;
  public passwordBlank = false;
  public emailBlank = false;
  public completed: boolean = false;
  public loading = false;
  public baseUrl = environment.base;
  public envPrefix = environment.envPrefix;
  public selectedLanguage = 'en';
  public redirect_to: string;
  // ------ desktop view layout ------
  // @author : sanjay bixbytes @date : 09-05-2022
  activeStepper: number = 0;
  deviceInfo: DeviceInfo;
  recaptchaValid: boolean = true;

  public notificationChannel: Channel;
  public hidePass = true;
  public continueClicked: boolean = false;
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private cookieService: CookieService,
    private clevertapService: ClevertapService,
    private userService: UserService,
    private deviceService: DeviceDetectorService,
    private notificationService: NotificationService,
    private languageService: LanguageService,
    private snackbarService: SnackbarService,
    private pusherService: PusherService,
    private googleAnalyticsService: GoogleAnalyticsService,
    public translate: TranslateService
  ) {
    if (
      localStorage.getItem('queryParams') != 'undefined' &&
      typeof localStorage.getItem('queryParams') != undefined
    )
      this.queryParams = JSON.parse(localStorage.getItem('queryParams'));

    this.token = undefined;
    this.redirect_to = localStorage.getItem('redirect_to');
    if (localStorage.getItem('lang') == null) {
      this.languageService.SetLanguage('en');
    }

    if (localStorage.getItem('invite_code')) {
      this.user.invite_code = localStorage.getItem('invite_code');
    }
  }

  ngOnInit(): void {
    if (localStorage.getItem('uuid') === 'null') {
      this.signupuid = uuidv4();
      localStorage.setItem('uuid', this.signupuid);
    }
    this.route.queryParams.subscribe((params) => {
      if (params.invite_code) {
        this.user.invite_code = params.invite_code;
      }
      if (!params.invite_code) {
      }
      if (params.email) {
        this.user.email = params.email;
      }
    });

    if (this.cookieService.get('userEmail')) {
      this.user.email = this.cookieService.get('userEmail');
    }

    let loadLang = this.languageService.GetLanguage() + '';
    if (loadLang == 'null' || loadLang == '') this.selectedLanguage = 'en';
    else this.selectedLanguage = loadLang;

    this.languageService.languageUpdates.subscribe((lang) => {
      this.selectedLanguage = lang;
    });
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    if (localStorage.getItem('lang') == null) {
      this.languageService.SetLanguage('en');
    }

    // Fire the AppsFlyer event
    (window as any).AF('pba', 'event', {
      eventType: 'EVENT',
      eventName: 'register_screen',
      eventValue: {
        description: 'Register screen viewed',
      },
    });

    // if(this.clevertapService.isWebView){
    // this
    // .googleAnalyticsService
    // .eventEmitter("SU_Register", "SignUp", "SU_App_Register");

    // }
    const queryParams = {
      openLoginModal: true,
      ...this.route.snapshot.queryParams,
    };
    this.router.navigate(['/'], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  public onSignup() {
    this.submitted = true;
    this.error = '';

    // Fire the AppsFlyer event
    (window as any).AF('pba', 'event', {
      eventType: 'EVENT',
      eventName: 'register_user_details',
      eventValue: {
        description: 'Name and password entered',
      },
    });

    const myfirstname = this.user.first_name.trim();
    const firstnameSplit = myfirstname.split(' ');

    for (let i = 0; i < firstnameSplit.length; i++) {
      firstnameSplit[i] =
        firstnameSplit[i][0]?.toUpperCase() + firstnameSplit[i]?.substr(1);
    }

    firstnameSplit.join(' ');

    const csv = firstnameSplit;

    const concatfirstword = firstnameSplit
      .map((word) => {
        if (word != 'undefined') var firstname = word.concat();

        return firstname;
        // return word
      })
      .join(' ');

    if (typeof concatfirstword != undefined || concatfirstword != 'undefined')
      this.user.first_name = concatfirstword;

    const lastnameWord = this.user.last_name.trim();
    const lastnameSplit = lastnameWord.split(' ');

    for (let i = 0; i < lastnameSplit.length; i++) {
      lastnameSplit[i] =
        lastnameSplit[i][0]?.toUpperCase() + lastnameSplit[i]?.substr(1);
    }

    lastnameSplit.join(' ');

    const lastname_sep = lastnameSplit;

    const concatlastword = lastnameSplit
      .map((word) => {
        if (word != 'undefined') var lastname = word.concat();

        return lastname;
        // return word
      })
      .join(' ');

    if (typeof concatlastword != undefined || concatlastword != 'undefined')
      this.user.last_name = concatlastword;

    if (
      this.envPrefix == 'prod' ||
      this.envPrefix == 'dev' ||
      this.envPrefix == 'staging'
    ) {
      if (this.token) {
        this.recaptchaValid = true;
        this.user.g_recaptcha_response = this.token;
      } else {
        this.recaptchaValid = false;
        return;
      }
    } else {
      this.recaptchaValid = true;
      this.user.g_recaptcha_response = 'true';
    }
    if (this.user?.password == '' || !this.passwordBlank) {
      // this.snackbarService.show({
      // 	message: "Please enter password",
      // 	type: 'success',
      // 	config: {
      // 		class : 'centered'
      // 	}
      // });
      this.passwordBlank = false;
      return;
    }

    if (this.user?.first_name.trim() == '') {
      this.firstnameBlank = true;
      return;
    }

    if (this.user?.last_name.trim() == '') {
      this.lastnameBlank = true;
      return;
    }

    if (
      this.user.email &&
      this.user.password &&
      this.user.g_recaptcha_response
    ) {
      this.loading = true;

      let getRandomIndex = Math.floor(Math.random() * 15) + 1;
      this.user.date_of_birth = null;

      this.user.clevertap_identity =
        this.clevertapService.getNewClevertap_identity();

      this.activeStepper = 0;

      this.authService
        .signup(this.user)
        .subscribe(
          (response: any) => {
            this.loading = false;

            this.signUpEvent(response);
            // this.toastr.success('Thanks for signing up, please log in to access and update your profile.', 'Success');
            this.userLogin.username = this.user.email;
            this.userLogin.password = this.user.password;

            // this.clevertapService.userProfileLogin(this.user);
            localStorage.setItem('redirect_to', '');
            this.redirect_to = '/auth/verify-account';

            this.signup_res = {
              id: response.id,
              username: this.user.email,
              password: this.user.password,
            };

            this.googleAnalyticsService.eventEmitter(
              'SU_SubmitNamePW',
              'SignUp',
              'SU_NamePWEntry'
            );
            this.googleAnalyticsService.eventEmitter(
              'SU_ZBCheckPass',
              'SignUp',
              'SU_PassedCheck'
            );

            // Fire the AppsFlyer event
            (window as any).AF('pba', 'event', {
              eventType: 'EVENT',
              eventName: 'register_success',
              eventValue: {
                description: 'Account created',
              },
            });
            // generate hashed email for AppsFlyer
            var hash = sha256(this.user.email);
            let hashedEmail = hash.toString(hex);
            // Fire the AppsFlyer event
            (window as any).AF('pba', 'setCustomerUserId', hashedEmail);

            // this.activeStepper = 2;
            this.loading = true;
            // this.notifyme();
            localStorage.setItem('signUpRes', JSON.stringify(this.signup_res));
            localStorage.setItem('verifyLocalMin', JSON.stringify(20));
            localStorage.setItem('verifyLocalSec', JSON.stringify(0));
            this.router.navigate(['/auth/verify-account'], {
              queryParams: this.route.snapshot.queryParams,
              queryParamsHandling: 'merge',
            });
            localStorage.setItem('redirectFrom', 'signUp');
            // localStorage.setItem("sociallyLoggedin", "0");

            //this.onLogin(response);
          },
          (err) => {
            this.error = err;

            // if(this.error.includes("Your email address appears to be invalid") && this.error.includes("Please check you entered it correctly"))
            // {

            this.googleAnalyticsService.eventEmitter(
              'SU_ZBCheckFail',
              'SignUp',
              'SU_FailedCheck'
            );
            // }

            this.token = undefined;
            // this.snackbarService.show({
            // 	message :err,
            // 	type: 'success',
            // 	config: {
            // 		class : 'centered'
            // 	}
            // })
            this.emailBlank = err;

            this.activeStepper = 0;
            this.loading = false;
          }
        )
        .add(() => {
          // this.loading = false;
        });
    }
    this.userService
      .postAnalytics(this.router.url, 'agree_and_continue_button')
      .subscribe((res) => {});
  }

  public notifyme() {
    // Turn on notifications

    this.authService
      .login({
        ...this.signup_res,
        grant_type: 'password',
        scope: '*',
      })
      .subscribe(
        (response: any) => {
          this.cookieService.set('onboarding-popup', '1');
          localStorage.setItem('sociallyLoggedin', '0');
          this.notificationService
            .upadateNotificationStatus(1, this.signup_res.id)
            .subscribe((res) => {});
          this.clevertapService.userProfileLogin(response.roleUser, true);
          this.clevertapService.setClevertap_id();
          this.clevertapService.updateClevertapId(
            this.clevertapService.getClevertap_id()
          );
          // this.router.navigate(["/"]);
          if (this.redirect_to != null && this.redirect_to != 'undefined') {
            // this.loading = false;
            localStorage.setItem('verifyLocalMin', JSON.stringify(20));
            localStorage.setItem('verifyLocalSec', JSON.stringify(0));
            this.router.navigate([this.redirect_to], {
              queryParams: this.route.snapshot.queryParams,
              queryParamsHandling: 'merge',
            });
            // comment added
          } else {
            // this.loading = false;
            // this.router.navigate(["/"]);
            localStorage.setItem('verifyLocalMin', JSON.stringify(20));
            localStorage.setItem('verifyLocalSec', JSON.stringify(0));
            this.router.navigate(['/auth/verify-account'], {
              queryParams: this.route.snapshot.queryParams,
              queryParamsHandling: 'merge',
            });
          }
        },
        (err) => {}
      );
  }

  public onLogin(userData) {
    this.authService
      .login({
        ...this.userLogin,
        grant_type: 'password',
        scope: '*',
      })
      .subscribe(
        (response: any) => {
          this.cookieService.set('onboarding-popup', '1');
          if (this.redirect_to != null) {
            this.router.navigate([this.redirect_to], {
              queryParams: this.route.snapshot.queryParams,
              queryParamsHandling: 'merge',
            });
          } else {
            this.router.navigate(['/'], {
              queryParams: this.route.snapshot.queryParams,
              queryParamsHandling: 'merge',
            });
          }
          // this.router.navigate(["/"]);
        },
        (err) => {
          this.loading = false;
          // this.snackbarService.show({
          // 	message :err,
          // 	type: 'success',
          // 	config: {
          // 		class : 'centered'
          // 	}
          // })
          this.emailBlank = err;
        }
      );

    // this.loading = true;

    // this.authService.login({
    // 	...this.userLogin,
    // 	grant_type: "password",
    // 	scope: "*",
    // }).subscribe((response: any) => {
  }

  signUpEvent(userData) {
    let dob_obj = new Date(this.user.date_of_birth + 'T10:20:30Z');
    this.clevertapService.createUserProfile(userData, dob_obj);
    //mobileSignUpUser
    this.clevertapService.pushCleverTapEvent(
      'mobileSignUpUser',
      {
        'First name': this.user.first_name,
        'Last name': this.user.last_name,
        Email: this.user.email,
        Date: new Date(),
        device_name: this.deviceInfo.deviceType,
        device_os: this.deviceInfo.os,
        device_platform: this.deviceInfo.os_version,
      },
      true
    );
  }

  public openSignUpPopup() {
    if (this.user.email.length < 7) {
      // this.snackbarService.show({
      // 	message: "Please enter email",
      // 	type: "success",
      // 	config: {
      // 		class : 'centered'
      // 	}
      // });

      this.continueClicked = true;

      this.emailBlank = false;
    } else {
      this.error = null;

      this.checkEmail();
      // if (window.screen.width < 1024) {
      // 	// $('.subscription_plan').modal('show');
      // 	this.activeStepper = 1;
      // } else {
      // 	this.activeStepper = 1;
      // }
    }

    // this
    // .googleAnalyticsService
    // .eventEmitter("SU_SubmitEmail", "SignUp", "SU_SubmitEmail");

    // else{
    // 	this
    // 	.googleAnalyticsService
    // 	.eventEmitter("congrats_signup_web", "Clicks", "/congrats-signup");
    // }
  }

  checkEmail() {
    this.authService
      .checkMailVerification(this.user.email)
      .subscribe((resp: any) => {
        if (!resp.isRegistered) {
          this.activeStepper = 1;
          this.googleAnalyticsService.eventEmitter(
            'SU_SubmitEmail',
            'SignUp',
            'SU_EmailEntry'
          );

          // Fire the AppsFlyer event
          (window as any).AF('pba', 'event', {
            eventType: 'EVENT',
            eventName: 'register_email',
            eventValue: {
              description: 'Email entered',
            },
          });
        } else if (resp.isRegistered && !resp.isVerified) {
          localStorage.setItem(
            'signUpRes',
            JSON.stringify({ username: this.user.email })
          );
          localStorage.setItem('verifyLocalMin', JSON.stringify(20));
          localStorage.setItem('verifyLocalSec', JSON.stringify(0));
          this.router.navigate(['/auth/verify-account'], {
            queryParams: this.route.snapshot.queryParams,
            queryParamsHandling: 'merge',
          });
          localStorage.setItem('redirectFrom', 'signUp');
        } else {
          this.snackbarService.show({
            message: this.translate.instant(
              'This email is already exist. Please SignIn'
            ),
            type: 'success',
            config: {
              class: 'centered',
            },
          });

          if (this.route.snapshot.queryParams) {
            this.router.navigate(['/auth/login'], {
              queryParams: this.route.snapshot.queryParams,
              queryParamsHandling: 'merge',
            });
          } else {
            this.router.navigate(['/auth/login']);
          }
        }
      });
    // this.userService.resendEmail(this.signup_res?.username).subscribe((res: any) => {

    // })
  }

  public closeSignupModal() {
    this.activeStepper = 0;
  }

  resolved(event) {
    if (this.token) {
      this.recaptchaValid = true;
    }
  }

  public open_notification_popup() {
    $('#notification_popup').modal('show');
  }

  toggleRefferenceInput() {
    $('.referal_input_container').toggleClass('active');
    $('.referrel_link').toggleClass('expanded');
  }

  // getDeviceInfo() {
  //   this.deviceInfo = this.deviceService.getDeviceInfo();
  //     const isMobile = this.deviceService.isMobile();
  //     const isTablet = this.deviceService.isTablet();
  //     const isDesktopDevice = this.deviceService.isDesktop();

  // }

  firstNameValidation() {
    this.firstnameBlank = this.user.first_name.length > 1 ? true : false;
  }

  lastNameValidation() {
    this.lastnameBlank = this.user.last_name.length > 1 ? true : false;
  }

  passwordValidation() {
    this.passwordBlank = this.user.password.length > 6 ? true : false;
  }

  emailValidation() {
    this.emailBlank = true;
  }

  toggleHidePassword() {
    this.hidePass = !this.hidePass;
  }

  handleLogin() {
    if (this.route.snapshot.queryParams) {
      this.router.navigate(['/auth/login'], {
        queryParams: this.route.snapshot.queryParams,
        queryParamsHandling: 'merge',
      });
    } else {
      this.router.navigate(['/auth/login']);
    }
  }

  termsErrorToggle() {
    if (this.termsOne) {
    }
  }
}
