
<div class="background_wrapper">
    <div class="logo_header">
        <div class="intro_brand">
            <img src="/assets/new-logo/app-logo-dark.svg" alt="">  
            <!-- <img src="/assets/images/onboard/logo.svg"> -->
            <!-- <span class="brand_title">Holiday Swap</span> -->
        </div>
        <div class="pointer" (click)="closeVerification()">
            <img src="../../../../../assets/images/onboard/close.svg" alt="">
        </div>
       
    </div>
    <div class="app_container">
        <div class="content_area">
            <div class="page-title">
                <h1 *ngIf="this.isVerified == false">{{"Please check your inbox" | translate}}</h1>
                <h1 *ngIf="this.isVerified == true">{{"Well done!" | translate}}
                    <br>{{"You’re verified!" | translate}}
                </h1>
            </div>
            <div class="page-desc">
                <p *ngIf="this.isVerified == false">
                    {{"We’ve sent you an email to" | translate}} <b>{{"verify" | translate}}</b> {{"your address and to confirm your account. Please" | translate}} <b>{{"click on the link" | translate}}</b> {{"in that email." | translate}} 
                </p>
                <p *ngIf="this.isVerified == false">
                    {{"If it’s landed in your Junk Box, please put Holiday Swap on your" | translate}} <b>{{"Safe Senders List" | translate}}.</b> 
                </p>
                <p *ngIf="enableResend && this.isVerified == false" >
                    {{"Please click" | translate}} <a (click)="resendEmail()" class="highlight">{{"here" | translate}}</a> {{"to resend, if required" | translate}}.
                </p>
            </div>

            <div class="timer_wrapper">
                <div class="discount_outer" *ngIf="!hideCounter">
                    <div class="discount-time-box">
                      <!-- <p class="discount-text">20% discount ends in:</p> -->
                      <div class="timer-wrap">
            
                        <div class="timeDiv" *ngIf="this.difference > 0">
                            <h3 id="minsVerify">{{this.minutes}}</h3>
                            <span *ngIf="this.minutes">{{"MINS" | translate}}</span>
                        </div>
                        <div *ngIf="this.minutes" class="separater"> : </div>
                        <div class="timeDiv" *ngIf="this.difference > 0">
                            <h3 id="secondsVerify">{{this.seconds}}</h3>
                            <span *ngIf="this.seconds">{{"SECS" | translate}}</span>
                          </div>
                      </div>
                    </div>
                  </div>
            </div>
        </div>
        <div class="login_link">
            <button type="button" class="btn_large btn_transparant" (click)="onLogin()" [disabled]="this.isVerified == false">{{"Continue" | translate}}</button>
        </div>
    </div>
</div>
   
   <app-loading [absolute]="true" [loading]="loading"></app-loading>