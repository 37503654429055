import { StarRatingSliderComponent } from './../../../../shared/components/star-rating-slider/star-rating-slider.component';
import { ElementRef } from '@angular/core';
import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  HostListener,
} from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ExploreSpacesService } from 'src/app/shared/services/explore-spaces.service';
import { ClevertapService } from 'src/app/shared/services/clevertap.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ActivatedRoute } from '@angular/router';
import { MetadataService } from 'src/app/shared/services/metadata.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { Router } from '@angular/router';

import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { FormGroup } from '@angular/forms';
import { FlexibleDatesComponent } from 'src/app/shared/components/flexible-dates/flexible-dates.component';

import { ICreateInstantPay } from 'src/app/shared/interfaces/swap';
import { SwapService } from 'src/app/shared/services/swap.service';
import { NewMapResultViewComponent } from '../../component/new-map-result-view/new-map-result-view.component';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { MapViewHomeCardsListComponent } from '../../component/map-view-home-cards-list/map-view-home-cards-list.component';
import { ExploreFilterModalComponent } from '../../component/explore-filter-modal/explore-filter-modal.component';
import { HttpService } from '../../../../shared/services/http.service';
import { SpaceService } from '../../../../shared/services/space.service';

declare var $: any;

export function debounce(delay: number = 300): MethodDecorator {
  return (
    _target: any,
    _propertyKey: string,
    descriptor: PropertyDescriptor
  ) => {
    let timeout = null;

    const original = descriptor.value;

    descriptor.value = function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => original.apply(this, args), delay);
    };

    return descriptor;
  };
}

@Component({
  selector: 'app-map-view-list-card',
  templateUrl: './map-view-list-card.component.html',
  styleUrls: ['./map-view-list-card.component.scss'],
})
export class MapViewListCardComponent implements OnInit {
  isDesktopView: boolean = window.screen.width > 1024;
  addressCicked: boolean = false;
  @ViewChild('mainOuter', { static: false }) mainOuter: ElementRef;
  @ViewChild('starRateSlider') starRateSlider: StarRatingSliderComponent;
  @ViewChild('flexibleDates') flexibleDates: FlexibleDatesComponent;
  @HostListener('window:resize', ['$event'])
  handleResize(event: KeyboardEvent) {
    this.isDesktopView = window.screen.width > 1024;
  }
  public minDate = moment().toDate();
  public maxDate = moment().add(11, 'months').toDate();

  searchedAddress: any;
  searchDate: any = '';
  guestNumber: number = 0;
  full_address: any = '';
  longitude: any = '';
  latitude: any = '';
  purposeId = '';
  // searchedLocation:any;
  differntMonth = false;
  sho;
  public spaceList: any = [];
  public spaceListMap = [];
  showFilterMob = false;
  public loading = false;
  showCalender = false;
  showFlexiablePlan = false;
  resultHide = true;
  tempDate: any;
  flexibleSelected = JSON.parse(localStorage.getItem('flexibleSelect'));
  btnLoad: boolean = false;
  // tempDate:any ={};

  public filter: any = {
    purpose_ids: 522,
    ended_at: '',
    started_at: '',
    homeRating: 3,
    totalBookingDays: '',
    guestNumber: this.guestNumber,
    days: [],
    months: [],
  };
  public additionalFilter: any = null;

  public selectedLanguage = 'en';

  public pagination = {
    total: 0,
    page: 1,
    last_page: 1,
    // skip: 0,
    // limit: 5,
    pageSize: 24,
  };

  public categories: any = {
    space_purposes: [],
  };
  public selectedCategory = null;
  checkboxGroup: FormGroup;
  radiobtnGroup: FormGroup;
  public arb = false;
  setFlexible: boolean = false;
  @ViewChild('resultMapViewSpaces')
  resultMapViewSpaces: NewMapResultViewComponent;
  @ViewChild('mapViewHomeCards')
  mapViewHomeCards: MapViewHomeCardsListComponent;

  public locations = [];
  public location: any = {};
  public wishlist: any = [];
  public historyLocations = [];
  public selectedDays;
  public selectedMonths = [];
  userEmail;
  city;

  public swap: ICreateInstantPay = {
    type: 'host',
    your_swap: {
      guests: 1,
    },
    their_swap: {
      guests: 1,
    },
    token: 0,
  };
  public totalBookingDays = 1;
  public hasHistory = false;
  @ViewChild('filterModal') filterModal: ExploreFilterModalComponent;

  inputoptions: Options = new Options({
    // types: ['geocode'],
    fields: [
      'formatted_address',
      'geometry',
      'place_id',
      'address_components',
      'type',
    ],
  });

  constructor(
    private cookieService: CookieService,
    private exploreService: ExploreSpacesService,
    private cd: ChangeDetectorRef,
    private clevertapService: ClevertapService,
    private userservices: UserService,
    private metadataService: MetadataService,
    public languageService: LanguageService,
    private filterService: FilterService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private swapService: SwapService,
    private spaceService: SpaceService,
    public translate: TranslateService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private httpService: HttpService
  ) {
    this.hasHistory = this.router.navigated;
    if (this.httpService.ACCESS_TOKEN) {
      this.getSearchHistory();
    }
    this.route.queryParams.subscribe((params) => {
      if (params?.full_address) {
        this.location.full_address = params.full_address;
        this.location.longitude = params.longitude;
        this.location.latitude = params.latitude;
        this.cookieService.set('search-address', JSON.stringify(params));
        localStorage.setItem('searchlocation', JSON.stringify(params));
        this.addressCicked = true;
      }
    });
  }

  public pop() {
    if (this.hasHistory) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnInit(): void {
    this.filter.purpose_ids = this.filterService.purposeIds;

    $('#search-address').focus();
    // this.route.queryParams.subscribe((region) => {

    // });
    if (
      localStorage.getItem('searchlocation') != '' &&
      localStorage.getItem('searchlocation') != null &&
      localStorage.getItem('searchlocation') != 'undefined'
    ) {
      this.location = JSON.parse(localStorage.getItem('searchlocation'));
    }

    if (!this.location.full_address) {
      this.location.full_address = this.location.city_name;
      this.location.longitude = this.location.longitude;
      this.location.latitude = this.location.latitude;
    }
    if (localStorage.getItem('searchFilters')) {
      this.filter = JSON.parse(localStorage.getItem('searchFilters'));
    }
    if (this.location.full_address) this.addressCicked = true;
    if (this.filter?.ended_at) {
      this.searchDate =
        moment(this.filter.started_at).format('ll') +
        ' - ' +
        moment(this.filter.ended_at).format('ll');
    }
    if (!!this.filter) {
      this.guestNumber = this.filter?.guestNumber;
      // this.full_address = this.filter?.full_address;
      // this.longitude = this.filter?.longitude;
      // this.latitude = this.filter?.latitude;
    }

    if (this.cookieService.get('search-address')) {
      this.searchedAddress = JSON.parse(
        this.cookieService.get('search-address')
      );
      if (this.cookieService.get('searchFilter')) {
        this.filter = JSON.parse(this.cookieService.get('searchFilter'));
      }
      if (
        localStorage.getItem('guest') != '' &&
        localStorage.getItem('guest') != null &&
        localStorage.getItem('guest') != 'undefined'
      ) {
        this.guestNumber = JSON.parse(localStorage.getItem('guest'));
        this.filter.guestNumber = this.guestNumber;
        this.guestNumber = this.filter.guestNumber;
        this.getSearchSpaces();
      }
      if (this.filter?.ended_at) {
        this.searchDate =
          moment(this.filter.started_at).format('ll') +
          ' - ' +
          moment(this.filter.ended_at).format('ll');
      }
      if (this.filter?.days) {
        this.selectedDays = this.filter?.days;
      }
      if (this.filter?.months) {
        this.selectedMonths = this.filter?.months;
      }

      if (this.selectedDays) {
        this.patchMonths();
      }
      this.guestNumber = this.filter?.guestNumber;

      this.getSearchSpaces();
    } else if (this.location.full_address) {
      this.searchedAddress = this.location;
      this.searchedAddress = {
        ...this.searchedAddress,
        full_address: '',
      };
      this.getSearchSpaces();
    } else {
      this.getFeaturedSpaces();
    }

    let userDataStorage = localStorage.getItem('userData');
    let userData = JSON.parse(userDataStorage);
    this.userEmail = userData.email;

    if (this.httpService.ACCESS_TOKEN) {
      this.userservices.currentUser.subscribe((user) => {
        if (Object.keys(user).length > 0) {
          this.wishlist = user?.favourited;
        }
      });

      this.metadataService
        .getSpaceNewCategories()
        .subscribe((response: { data: any }) => {
          this.categories = response.data;
        });
    }

    let loadLang = this.languageService.GetLanguage() + '';
    if (loadLang == 'null' || loadLang == '') {
      this.selectedLanguage = 'en';
    } else {
      this.selectedLanguage = loadLang;
    }
    this.languageService.languageUpdates.subscribe((lang) => {
      this.selectedLanguage = lang;

      if (this.selectedLanguage == 'ar') {
        this.arb = true;
      } else {
        this.arb = false;
      }
    });
  }

  onEnterKey() {
    this.searchHome();
  }

  loadHomeByCategory(category) {
    this.selectedCategory = category;
    document
      .getElementById('main-app')
      .scrollTo({ top: 0, behavior: 'smooth' });
    let query;

    if (this.filter?.guestNumber > 0) {
      query = {
        ...this.searchedAddress,
        homeRating: this.filter.homeRating,
        started_at: this.filter?.started_at,
        ended_at: this.filter?.ended_at,
        guests: this.filter.guestNumber,
        days: this.filter?.days,
        months: [...new Set(this.filter?.months)],
      };
    } else {
      query = {
        ...this.searchedAddress,
        full_address: this.filter.full_address,
        longitude: this.filter.longitude,
        latitude: this.filter.latitude,
        homeRating: this.filter.homeRating,
        started_at: this.filter?.started_at,
        ended_at: this.filter?.ended_at,
        days: this.filter?.days,
        months: [...new Set(this.filter?.months)],
      };
    }

    if (!this.guestNumber) {
      delete query.guests;
    }

    if (this.additionalFilter) {
      Object.assign(query, this.additionalFilter);
    }

    this.spaceService
      .getHomeByStyleList(this._getUriWithParam(query), category)
      .subscribe((res: any) => {
        this.loading = false;
        this.spaceList = res.data;
        this.pagination.total = res.meta?.total;
        this.pagination.pageSize = res.meta?.pageSize || 24;
        this.pagination.page = res.meta?.current_page;
        this.pagination.last_page = res.meta?.last_page;

        this.spaceList = this.spaceList.map((space) => ({
          ...space,
          type: 'CARD',
        }));
      });

    this.spaceService
      .getHomeByStyleMap(this._getUriWithParam(query), category)
      .subscribe((res: any) => {
        this.loading = false;
        this.spaceListMap = res.data;
        let zoom_level = 12;
        if (this.location?.full_address == this.location?.country) {
          zoom_level = 4;
        }
        this.cd.detectChanges();
        this.resultMapViewSpaces.reRenderMap(this.spaceListMap, zoom_level);
      });
  }

  handleOpenFilterModal() {
    this.filterModal.open();
  }

  patchMonths() {
    let selected_days = '';
    let selected_months = '';
    if (
      this.selectedDays.length <= 0 ||
      typeof this.selectedDays.length == undefined
    ) {
      this.selectedDays = 2;
    }
    if (this.selectedDays == 7) {
      selected_days = '1 week';
    } else if (this.selectedDays == 14) {
      selected_days = '2 weeks';
    } else {
      selected_days = this.selectedDays + ' days';
    }

    if (this.selectedMonths.length > 0) {
      this.selectedMonths.map((month) => {
        const [mon, year] = month.split('-');
        let new_month = moment(new Date(+year, +mon - 1)).format('MMM-YY');
        if (selected_months != '') {
          selected_months = selected_months + ', ' + new_month;
        } else {
          selected_months = selected_months + ' ' + new_month;
        }
      });
    }
    let flexible_txt = '';
    if (
      (this.selectedDays.length > 0 ||
        typeof this.selectedDays.length != undefined) &&
      this.selectedMonths.length > 0
    ) {
      flexible_txt = selected_days + ',' + selected_months;
    } else if (this.selectedMonths.length <= 0) {
      flexible_txt = selected_days;
    } else {
      flexible_txt = selected_months;
    }

    if (localStorage.getItem('flexibleSelect') == 'true') {
      this.searchDate = flexible_txt;
      this.setFlexible = true;
    }
  }

  starValChange(event) {
    this.filter.homeRating = event;
  }

  public getSearchHistory() {
    this.exploreService.getSearchHistory().subscribe((res: any) => {
      this.historyLocations = res.data;
    });
  }

  public getFeaturedSpaces() {
    this.loading = true;

    this.exploreService
      .getFeaturedSpaces()
      .subscribe(
        (res: any) => {
          this.loading = false;

          this.spaceList = [];

          this.spaceList = res.data;
          if (res.data.length > 0) {
          } else {
            this.resultMapViewSpaces.clear();
          }

          this.cd.detectChanges();

          if (this.spaceList?.length > 0) {
            this.onSlideChange(this.spaceList[0]);
          }
        },
        (err) => {
          this.loading = false;
        }
      )
      .add(() => {});
  }

  public updateFilter(additionalFilter) {
    if (additionalFilter) {
      this.additionalFilter = additionalFilter;
      this.cookieService.set('searchFilter', JSON.stringify(this.filter));
      localStorage.setItem('searchFilters', JSON.stringify(this.filter));
      this.getSearchSpaces();
    } else {
      this.additionalFilter = null;
    }
  }

  showFilterMobFun() {
    // this.showFilterMob = true;
    $('.app-navigation').addClass('hide');
    if (!this.isDesktopView) {
      this.router.navigate(['/search-homes']);
    }
    // $('.app-navigation').addClass('hide');
  }
  closeFilterMob(id) {
    this.showFilterMob = false;
    this.filter.purpose_ids = 522;
    this.filter.ended_at = '';
    this.filter.started_at = '';
    this.filter.homeRating = 3;
    this.filter.days = [];
    this.filter.months = [];
    this.searchDate = '';
    this.guestNumber = 2;
    // this.filter.push()
    // this.location.full_address = '';
    this.cookieService.set('searchFilter', JSON.stringify(this.filter));
    localStorage.setItem('searchFilters', JSON.stringify(this.filter));
    this.flexibleSelected = false;
    this.filter.guestNumber = this.guestNumber;
    $('.app-navigation').removeClass('hide');
    this.starRateSlider.resetSratVal();
    this.mapViewHomeCards.clear(this.filter.purpose_ids);
    this.cd.detectChanges();
    this.selectedDays = [];
    this.selectedMonths = [];

    setTimeout(() => {
      this.flexibleDates.update(this.selectedDays, this.selectedMonths);
    }, 1000);
    // setTimeout(() => {
    // 	this.clearEventEmit();
    // }, 400)
    localStorage.setItem('flexibleSelect', 'false');
    this.searchHome(false);
    this.swapService.swap = {};
  }

  public getSearchSpaces() {
    // this.resultMapViewSpaces.flyToLocation(this.location);

    this.loading = true;
    let query;

    // Collect data used for search so we can send it in the AppsFlyer event
    let AFeventData = { ...this.location, ...this.filter };

    if (this.filter?.guestNumber > 0) {
      query = {
        ...this.searchedAddress,
        homeRating: this.filter.homeRating,
        started_at: this.filter?.started_at,
        ended_at: this.filter?.ended_at,
        guests: this.filter.guestNumber,
        days: this.filter?.days,
        months: this.filter?.months,
      };
    } else {
      query = {
        ...this.searchedAddress,
        homeRating: this.filter.homeRating,
        started_at: this.filter?.started_at,
        ended_at: this.filter?.ended_at,
        days: this.filter?.days,
        months: this.filter?.months,
      };
    }

    if (!this.guestNumber) {
      delete query.guests;
    }

    if (this.additionalFilter) {
      Object.assign(query, this.additionalFilter);

      // If filtering, add filter data we can send it in the AppsFlyer event
      AFeventData = { ...AFeventData, ...this.additionalFilter };
    }

    // Fire the AppsFlyer event
    (window as any).AF('pba', 'event', {
      eventType: 'EVENT',
      eventName: 'what_they_searched',
      eventValue: AFeventData,
    });

    document.getElementById('mainOuter').scrollTop = 0;
    this.exploreService
      .getSearchSpaces(this._getUriWithParam(query), true)
      .subscribe(
        (res: any) => {
          this.loading = false;
          this.spaceList = res.data;
          this.pagination.total = res.meta?.total;
          this.pagination.pageSize = res.meta?.pageSize || 24;
          this.pagination.page = res.meta?.current_page;
          this.pagination.last_page = res.meta?.last_page;

          this.spaceList = this.spaceList.map((space) => ({
            ...space,
            type: 'CARD',
          }));
          // .filter((item, index) => index < 8);

          const generateCTAList = () => {
            const ctaList = [];

            const CTA_TYPES = {
              BUY_TOKEN: 'BUY_TOKEN',
              GO_PREMIUM: 'GO_PREMIUM',
              LIST_HOME: 'LIST_HOME',
            };

            const isNotPremiumUser = JSON.parse(
              localStorage.getItem('userDataTEST')
            )?.subscription?.name.includes('Lite Access');

            // const atleastOneHomeNotListed = true; //TODO:

            const atleastOneHomeNotListed =
              JSON.parse(localStorage.getItem('userDataTEST'))
                ?.active_spaces_count == 0 ||
              JSON.parse(localStorage.getItem('userDataTEST'))
                ?.active_spaces_count < 1;

            ctaList.push(CTA_TYPES.BUY_TOKEN);

            if (isNotPremiumUser) ctaList.push(CTA_TYPES.GO_PREMIUM);

            if (atleastOneHomeNotListed) ctaList.push(CTA_TYPES.LIST_HOME);

            return ctaList;
          };
        },
        (err) => {
          this.loading = false;
        }
      )
      .add(() => {});

    this.exploreService
      .getSpaceFortheMap(this._getUriWithParam(query), true)
      .subscribe(
        (res: any) => {
          this.loading = false;
          this.spaceListMap = [];

          this.spaceListMap = res.data;
          let zoom_level = 12;
          if (this.location?.full_address == this.location?.country) {
            zoom_level = 4;
          } else {
            zoom_level = 12;
          }
          this.resultMapViewSpaces.flyToLocation(this.location, 12);
          this.cd.detectChanges();
          this.resultMapViewSpaces.reRenderMap(this.spaceListMap, zoom_level);

          const lastMapLocation = localStorage.getItem('currentMapCoordinates');

          if (lastMapLocation !== null) {
            const coordinates = JSON.parse(lastMapLocation);

            const lastMapZoomLevel = parseInt(
              localStorage.getItem('currentMapZoomLevel')
            );

            this.resultMapViewSpaces.setMapCenterWithSpecifiedZoom(
              {
                longitude: coordinates.lng,
                latitude: coordinates.lat,
              },
              lastMapZoomLevel
            );
          } else if (this.spaceListMap.length > 0) {
            this.onSlideChange(this.spaceListMap[0]);
          } else {
            this.resultMapViewSpaces.flyToLocation(this.location, 12);
          }
          localStorage.removeItem('currentMapCoordinates');
          localStorage.removeItem('currentMapZoomLevel');
        },
        (err) => {
          this.loading = false;
        }
      )
      .add(() => {});
  }

  public onSlideChange(space) {
    if (space && space.address) {
    }
  }

  private _getUriWithParam = (params: Record<string, any>): string => {
    const Url = new URL(window.location.href);
    const urlParams: URLSearchParams = new URLSearchParams(Url.search);
    for (const key in params) {
      if (params[key] !== undefined) {
        urlParams.set(key, params[key]);
      }
    }
    Url.search = urlParams.toString().replace(window.location.href, '');
    const query = '?' + Url.toString().split('?')[1];
    return decodeURIComponent(query);
  };

  public onSelect(location, save = false) {
    // this.locations = [];
    this.location = location;
    this.searchedAddress = this.location;
    this.resultHide = true;
    this.addressCicked = true;
    if (this.location.full_address) {
      this.cookieService.delete('search-address');
      this.cookieService.set('search-address', JSON.stringify(this.location));
      localStorage.setItem('searchlocation', JSON.stringify(this.location));
      this.cookieService.set('searchFilter', JSON.stringify(this.filter));
      localStorage.setItem('searchFilters', JSON.stringify(this.filter));
    } else {
    }
  }
  public saveHistory() {
    if (this.location) {
      let loc =
        this.location?.city || this.location?.full_address.split(',')[0];
      this.googleAnalyticsService.eventEmitter(
        'SR_ExplorePage',
        'Search',
        'SR_' + loc
      );
      let obj = { ...this.location, homeRating: this.filter.homeRating };
      if (this.httpService.ACCESS_TOKEN) {
        this.exploreService.saveSearchHistory(obj).subscribe((res) => {});
      }
    }
  }

  @debounce(300)
  public autoCompleteAddress(query) {
    let formatted_address = query?.formatted_address;
    this.location = JSON.parse(localStorage.getItem('searchlocation'));
    this.cookieService.set('searchFilter', JSON.stringify(this.filter));
    localStorage.setItem('searchFilters', JSON.stringify(this.filter));

    this.location.full_address = query?.formatted_address;
    this.location.longitude = query?.longitude;
    this.location.latitude = query?.latitude;

    this.resultHide = false;
    this.addressCicked = true;
    let searchAddressComponents = query?.address_components;
    let searchGeometry = query?.geometry;

    this._patchAddress(
      searchAddressComponents,
      searchGeometry,
      formatted_address
    );
  }

  _patchAddress(searchAddressComponents, geometry, formatted_address) {
    let address: any = {};
    let street_number = '';
    let street_route = '';
    $.each(searchAddressComponents, function () {
      if (this.types[0] == 'postal_code') {
        address.postcode = this.long_name;
      }

      if (this.types[0] == 'administrative_area_level_1') {
        address.state = this.long_name;
        // stateSet = true;
      }

      if (this.types[0] == 'administrative_area_level_2') {
        address.state = this.long_name;
        // stateSet = true;
      }

      if (this.types[0] == 'country') {
        address.country = this.long_name;
      }

      if (this.types[0] == 'colloquial_area' && this.types[1] == 'locality') {
        address.city = this.long_name;
      }

      if (this.types[0] == 'sublocality_level_1') {
        address.city = this.long_name;
      }

      if (this.types[0] == 'postal_town') {
        address.city = this.long_name;
      }

      if (this.types[0] == 'locality') {
        // address.line_2 = this.long_name;
        address.city = this.long_name;
      }

      if (this.types[0] == 'street_number') {
        street_number = this.long_name;
      }
      if (this.types[0] == 'route') {
        street_route = this.long_name;
      }
    });

    address.full_address = formatted_address;
    address.longitude = geometry?.location.lng();
    address.latitude = geometry?.location.lat();

    if (street_number != '' && street_route != '') {
      address.street = street_number + ' ' + street_route;
    } else if (street_number == '' && street_route != '') {
      address.street = street_route;
    }

    if (address?.length > 0) {
      this.locations = address;
      this.location = address;
    }
    this.location = address;
  }

  updateDate() {
    this.filter.ended_at = this.tempDate.end;
    this.filter.started_at = this.tempDate.start;
    this.filter.totalBookingDays = this.tempDate.days;
    if (this.tempDate.end == this.tempDate.start) {
      this.filter.ended_at = moment(this.tempDate.end).add(1, 'days');
    }
    if (this.tempDate.end) {
      if (
        moment(this.filter.started_at).format('M') !=
        moment(this.filter.ended_at).format('M')
      ) {
        this.differntMonth = true;
      } else {
        this.differntMonth = false;
      }
      this.searchDate =
        moment(this.filter.started_at).format('ll') +
        ' - ' +
        moment(this.filter.ended_at).format('ll');
    }
    this.showCalender = false;
  }

  public onDateSelected(date) {
    if (this.isDesktopView) {
      this.filter.ended_at = date.end;
      this.filter.started_at = date.start;
      this.filter.totalBookingDays = date.days;
      if (date.end == date.start) {
        this.filter.ended_at = moment(date.end).add(1, 'days');
      }
      if (date.end) {
        if (
          moment(this.filter.started_at).format('M') !=
          moment(this.filter.ended_at).format('M')
        ) {
          this.differntMonth = true;
        } else {
          this.differntMonth = false;
        }
        this.searchDate =
          moment(this.filter.started_at).format('ll') +
          ' - ' +
          moment(this.filter.ended_at).format('ll');
      }
    } else {
      this.tempDate = date;
    }
    this.filter.days = [];
    this.filter.months = [];
    this.cookieService.set('searchFilter', JSON.stringify(this.filter));
    localStorage.setItem('searchFilters', JSON.stringify(this.filter));
    this.clearEventEmit();
    this.patchDatesToSwap(date, true);
  }

  patchDatesToSwap(date, me: boolean) {
    let tempDate = {
      ended_at: date.end,
      started_at: date.start,
      totalBookingDays: date.days,
    };
    this.swapService.saveBookingDates(tempDate);
    if (me) {
      this.swap.your_swap.ended_at = date.end;
      this.swap.your_swap.started_at = date.start;
      this.totalBookingDays = date.days;
    } else {
      this.swap.their_swap.ended_at = date.end;
      this.swap.their_swap.started_at = date.start;
    }

    this.swapService.swap = this.swap;
  }

  isIOSDevice() {
    return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  }
  imFlex() {
    // this.selectedMonths = [];
    // this.selectedDays = '';
    if (!this.setFlexible) {
      this.selectedDays = 2;
      this.searchDate = '';
    }
    this.showCalender = false;
    this.showFlexiablePlan = true;
    localStorage.setItem('flexibleSelect', 'true');
    this.filter.started_at = '';
    this.filter.ended_at = '';
    this.flexibleDates.update(this.selectedDays, this.selectedMonths);
  }
  updateGuests(type) {
    if (type == 'add' && this.guestNumber < 80) {
      this.guestNumber++;
    }
    if (type == 'less' && this.guestNumber > 0) {
      this.guestNumber--;
    }
    this.filter.guestNumber = this.guestNumber;
  }

  searchHome(validate = true) {
    this.saveHistory();
    this.locations = [];
    this.spaceList = [];
    this.resultMapViewSpaces.clear();
    this.filterService.updateExploreFilters(null);
    this.showCalender = false;
    this.showFilterMob = false;
    this.showFlexiablePlan = false;
    this.searchedAddress = this.location;

    $('.app-navigation').removeClass('hide');
    if (validate) {
      if (this.location.full_address && this.addressCicked) {
        this.getSearchSpaces();
      } else {
        this.toastr.error(
          this.translate.instant('Please select valid address'),
          this.translate.instant('Error')
        );
      }
    } else {
      this.getSearchSpaces();
    }
    this.userservices
      .postAnalytics(this.router.url, 'map_view_list_search_button')
      .subscribe((res) => {});
    this.cookieService.set('search-address', JSON.stringify(this.location));
    localStorage.setItem('searchlocation', JSON.stringify(this.location));
    this.cookieService.set('searchFilter', JSON.stringify(this.filter));
    localStorage.setItem('searchFilters', JSON.stringify(this.filter));

    //remove set co-ordinates when searching for newer place
    localStorage.removeItem('currentMapCoordinates');
    localStorage.removeItem('currentMapZoomLevel');
  }

  ngOnDestroy(): void {
    if (this.wishlist && this.wishlist.length <= 0) {
      let searchData = {
        email: this.userEmail,
      };
      this.clevertapService.pushCleverTapEvent('wishlist', searchData);
    }
    // localStorage.removeItem("searchlocation");
    // localStorage.removeItem("searchDate");
    localStorage.removeItem('guest');
  }

  onFlexibleSelect(event: any) {
    let selected_days = '';
    let selected_months = '';
    this.filter.days = event.days;
    this.filter.months = event.months;
    if (event.days == 7) {
      selected_days = '1 week';
    } else if (event.days == 14) {
      selected_days = '2 weeks';
    } else {
      selected_days = event.days + ' days';
    }

    if (event.months.length > 0) {
      event.months.map((month) => {
        const [mon, year] = month.split('-');
        let new_month = moment(new Date(+year, +mon - 1)).format('MMM-YY');
        if (selected_months != '') {
          selected_months = selected_months + ', ' + new_month;
        } else {
          selected_months = selected_months + ' ' + new_month;
        }
      });
    }
    let flexible_txt = '';
    if (event.days) {
      flexible_txt = selected_days + ',' + selected_months;
    } else {
      flexible_txt = selected_months;
    }
    this.searchDate = flexible_txt;
    this.setFlexible = true;
  }

  closeFlex() {
    this.showFlexiablePlan = false;
    this.showCalender = true;
  }

  clearEventEmit() {
    if (localStorage.getItem('searchFilters')) {
      this.filter = JSON.parse(localStorage.getItem('searchFilters'));
    }
    if (this.filter?.days) {
      this.selectedDays = this.filter?.days;
    }
    if (this.filter?.months) {
      this.selectedMonths = this.filter?.months;
    }
    this.flexibleDates.update(this.selectedDays, this.selectedMonths);
    this.setFlexible = false;
    this.patchMonths();
    // this.searchDate = '';
  }

  loadMoreData(page?: number): void {
    if (page) {
      this.pagination.page = page ? page : this.pagination.page + 1;
    }
    this.getSpaceData();
  }

  getSpaceData() {
    // this.loading = true;
    let query;
    if (this.filter?.guestNumber > 0) {
      query = {
        ...this.pagination,
        ...this.searchedAddress,
        homeRating: this.filter.homeRating,
        started_at: this.filter?.started_at,
        ended_at: this.filter?.ended_at,
        guests: this.filter.guestNumber,
        days: this.filter?.days,
        months: [...new Set(this.filter?.months)],
      };
    } else {
      query = {
        ...this.pagination,
        ...this.searchedAddress,
        homeRating: this.filter.homeRating,
        started_at: this.filter?.started_at,
        ended_at: this.filter?.ended_at,
        days: this.filter?.days,
        months: [...new Set(this.filter?.months)],
      };
    }

    const optionsInFilterModal = this.filterService.exploreFilters$.getValue();
    if (optionsInFilterModal) {
      query.hosting_amounts = `${optionsInFilterModal.minPrice},${optionsInFilterModal.maxPrice}`;
      query.place_types = optionsInFilterModal.placeTypes || undefined;
      query.bedrooms =
        optionsInFilterModal.bedrooms === 'Any'
          ? undefined
          : optionsInFilterModal.bedrooms;
      query.bathrooms =
        optionsInFilterModal.bathrooms === 'Any'
          ? undefined
          : optionsInFilterModal.bathrooms;
    }

    if (this.selectedCategory) {
      this.spaceService
        .getHomeByStyleList(this._getUriWithParam(query), this.selectedCategory)
        .subscribe((res: any) => {
          this.loading = false;
          this.btnLoad = false;
          this.mapViewHomeCards.updateBtnLoad(false, this.pagination);

          this.spaceList = res.data;
          this.pagination.total = res.meta?.total;
          this.pagination.pageSize = res.meta?.pageSize || 24;
          this.pagination.page = res.meta?.current_page;
          this.pagination.last_page = res.meta?.last_page;

          this.spaceList = this.spaceList.map((space) => ({
            ...space,
            type: 'CARD',
          }));
        });
    } else {
      this.exploreService
        .getSearchSpaces(this._getUriWithParam(query), true)
        .subscribe(
          (res: any) => {
            this.btnLoad = false;

            this.spaceList = [...res.data];
            this.pagination.total = res.meta?.total;
            this.pagination.page = res.meta?.current_page;
            this.pagination.last_page = res.meta?.last_page;
            this.mapViewHomeCards.updateBtnLoad(false, this.pagination);

            this.spaceList = this.spaceList.map((space) => ({
              ...space,
              type: 'CARD',
            }));
          },
          (err) => {
            this.loading = false;
          }
        )
        .add(() => {});
    }
  }
}
