<div class="card" (click)="goToHomeDetail()">
  <div class="popular_image">
    <img
      class="card-img-top lazyload pointer"
      [lazyLoad]="space.webp_image || space.images?.[0]?.webp_image"
    />
  </div>
  <div class="card-body">
    <div class="pointer">
      <div class="popular_title">
        <h5 class="card-title" *ngIf="space?.title">
          {{
            space?.title?.length > 40
              ? space?.title?.substring(0, 40)
              : space?.title
          }}
          {{ space?.title?.length > 40 ? "..." : "" }}
        </h5>
        <h5 class="card-title" *ngIf="!space?.title"></h5>
        <span class="card-location">
          {{ space?.address?.country ? space?.address?.country + "," : " " }}
          {{ space?.address?.city }}
        </span>
      </div>
      <div class="popular_des">
        <span class="card-desc"
          >{{ space?.bedrooms }}
          {{ (space?.bedrooms > 1 ? "bedrooms" : "bedroom") | translate }} .
          {{ space?.guests }}
          {{ (space?.guests > 1 ? "guests" : "guest") | translate }}</span
        >

        <!-- <span *ngIf="space?.rating?.total_reviews > 0">{{space?.rating?.total_reviews }} {{space?.rating?.total_reviews == 1 ? "review" : "reviews" | translate}}</span> -->
      </div>
    </div>

    <div class="popular_footer">
      <div class="popular_action">
        <ul>
          <li *ngIf="space?.is_favourited">
            <a (click)="onLike($event, space)"
              ><i class="fa fa-heart" aria-hidden="true"></i
            ></a>
          </li>
          <li *ngIf="!space?.is_favourited">
            <a (click)="onLike($event, space)"
              ><i class="fa fa-heart-o" aria-hidden="true"></i
            ></a>
          </li>
        </ul>
      </div>
      <span
        class="explore_price"
        *ngIf="
          space?.hosting_amount >= 0 &&
          space?.purpose != 'swap' &&
          space.payment_type_id == 561
        "
      >
        <span>${{ flooringNumber(space?.hosting_amount) | number }}</span>
        <span class="gray_text pl-5"> {{ "per night" | translate }}</span>
      </span>
      <span
        class="explore_price"
        *ngIf="
          space?.hosting_tokens > 0 &&
          space?.purpose != 'swap' &&
          space.payment_type_id != 561
        "
      >
        <span>{{ space?.hosting_tokens | number }}</span>
        <!-- <s *ngIf="isPremiumMember">{{ space?.hosting_tokens }}</s> -->
        <span class="gray_text pl-5"> {{ "per night" | translate }}</span>
      </span>
      <span class="swap_show" *ngIf="space?.purpose == 'swap'">
        <img src="/assets/images/new_swap_icon.svg" alt="" />
        <span>{{ "Swap" | translate }}</span>
      </span>
    </div>
  </div>
  <!--  <div class="offer_footer" *ngIf="space?.hosting_tokens > 0 && space?.purpose != 'swap' && space.payment_type_id != 561">-->
  <!--    <div class="text_part">-->
  <!--      <span>{{ "Subscribed Users" | translate }} &nbsp;&nbsp;&nbsp;</span><p><span class="text_red text_big">20%</span> <span class="text_red">{{ "Discount" | translate }}</span></p>-->
  <!--    </div>-->
  <!--    <div class="ammount_part">-->
  <!--      <span class="explore_price"><img src="/assets/images/Token_Plane.png">{{ getPersentage(space?.hosting_tokens) }}</span>-->
  <!--      <span class="black_text pl-5" > {{"per night" | translate}}</span>-->
  <!--    </div>-->
  <!--  </div>-->
</div>
