import { Component, Input, OnInit } from '@angular/core';
import { Location } from "@angular/common";
import { Router } from '@angular/router';

@Component({
  selector: 'app-country-notification-header',
  templateUrl: './country-notification-header.component.html',
  styleUrls: ['./country-notification-header.component.scss']
})
export class CountryNotificationHeaderComponent implements OnInit {

  @Input() backLink: string = "";
  @Input() returnUrl: string = "";
  @Input() fragment: string = "";

  hasHistory: boolean = false;
  constructor( private location: Location, private router: Router) {
    this.hasHistory = this.router.navigated;
   }

  ngOnInit(): void {


  }


  public pop() {
    if(this.returnUrl != "") {
      if(this.fragment != "") {
        this.router.navigate([this.returnUrl], {fragment: this.fragment});
        return;
      } else {
        this.router.navigate([this.returnUrl]);
        return;
      }
    }

    if (this.backLink)this.router.navigate([this.backLink]);
		else {
      if(this.hasHistory) {
        this.location.back();
      } else {
        this.router.navigateByUrl('/');
      }
    }
	}

}
