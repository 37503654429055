import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { Lightbox } from 'ngx-lightbox';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CalendarComponent } from 'src/app/shared/components/calendar/calendar.component';
import { ICreateInstantPay } from 'src/app/shared/interfaces/swap';
import { ClevertapService } from 'src/app/shared/services/clevertap.service';
import { ExploreSpacesService } from 'src/app/shared/services/explore-spaces.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { SpaceService } from 'src/app/shared/services/space.service';
import { SwapService } from 'src/app/shared/services/swap.service';
import { TokenService } from 'src/app/shared/services/token.service';
import { UserService } from 'src/app/shared/services/user.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { environment } from 'src/environments/environment';
import { SwiperOptions } from 'swiper';
import { Location } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { MetadataService } from 'src/app/shared/services/metadata.service';
import { HomesCardComponentComponent } from 'src/app/shared/components/homes-card-component/homes-card-component.component';
import { BottomSheetComponent } from 'src/app/shared/components/bottom-sheet/bottom-sheet.component';
import { PopupService } from 'src/app/shared/services/popup.service';
import { DropPanelComponent } from '../../../../shared/components/drop-panel/drop-panel.component';
import { GoogleMapViewComponent } from 'src/app/shared/components/google-map-view/google-map-view.component';

declare var $: any;

@Component({
  selector: 'app-search-home-details',
  templateUrl: './search-home-details.component.html',
  styleUrls: ['./search-home-details.component.scss'],
})
export class SearchHomeDetailsComponent implements OnInit {
  public swap: ICreateInstantPay = {
    type: 'host',
    your_swap: {
      guests: 1,
    },
    their_swap: {
      guests: 1,
    },
    token: 0,
  };
  public localNavData = '';
  public spaceId = 0;
  public tab = 0;
  public config: SwiperOptions = {
    slidesPerView: 1,
    virtual: true,
    spaceBetween: 0,
    pagination: false,
    autoplay: false,
    observer: true,
    observeParents: true,
    parallax: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };
  public step = 0;
  public displyCount;
  public mySpaces = [];
  public userSpaces = [];
  hideMinus = false;
  public yourSwap = {
    unavailable: [],
  };
  public theirSwap = {
    unavailable: [],
  };
  public spaceFeatures = [];
  public minDate = moment().toDate();
  public maxDate = moment().add(11, 'months').toDate();
  public extras = [];
  public payment = {
    has_property_insurance: false,
    has_cancellation_insurance: false,
  };
  @ViewChild('locationMap') locationMap: GoogleMapViewComponent;

  public paymentsEnabled = environment.payments_enabled;
  instantpaymentStatus = false;

  public bookings_msg = ['week', 'month', 'year'];
  public total_booking_msg = '';
  public randCount;
  shareMessage =
    'I just found this amazing home on Holiday Swap!  Check it out! ';
  public sections = [
    {
      key: 'amenities',
      name: 'Amenities',
      icon: this.sanitizer
        .bypassSecurityTrustHtml(`	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
		stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
		class="ai ai-Wifi">
		<path d="M2 10c6-6.667 14-6.667 20 0" />
		<path d="M6 14c3.6-4 8.4-4 12 0" />
		<circle cx="12" cy="18" r="1" />
	</svg>`),
    },
    {
      key: 'accessibilities',
      name: 'Accessibility',
      icon: this.sanitizer
        .bypassSecurityTrustHtml(`<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
		stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
		class="ai ai-Lifesaver">
		<g clip-path="url(#clip0)">
			<circle cx="12" cy="12" r="10" transform="rotate(45 12 12)" />
			<circle cx="12" cy="12" r="4" transform="rotate(45 12 12)" />
			<path d="M19.071 4.929l-4.243 4.243" />
			<path d="M9.172 14.828l-4.243 4.243" />
			<path d="M19.071 19.071l-4.243-4.243" />
			<path d="M9.172 9.172L4.929 4.929" />
		</g>
		<defs>
			<clipPath id="clip0">
				<rect width="24" height="24" />
			</clipPath>
		</defs>
	</svg>`),
    },
    {
      key: 'atmospheres',
      name: 'Atmosphere',
      icon: this.sanitizer
        .bypassSecurityTrustHtml(`<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
		stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
		class="ai ai-Coffee">
		<path d="M11 7c4.418 0 8 .895 8 2s-3.582 2-8 2-8-.895-8-2c0-.357.375-.693 1.033-.984" />
		<path
			d="M3 9v9.343c0 1.061.44 2.08 1.409 2.513C5.624 21.399 7.711 22 11 22c3.29 0 5.377-.601 6.591-1.144.968-.433 1.409-1.452 1.409-2.513V9" />
		<path d="M19 10v0a3 3 0 0 1 3 3v0a3 3 0 0 1-3 3v0" />
		<path d="M7 3v4" />
		<path d="M11 2v2" />
		<path d="M15 4v3" />
	</svg>`),
    },
    {
      key: 'landscapes',
      name: 'Landscape',
      icon: this.sanitizer
        .bypassSecurityTrustHtml(`	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
		stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
		class="ai ai-Image">
		<path d="M2 6a4 4 0 0 1 4-4h12a4 4 0 0 1 4 4v12a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V6z" />
		<circle cx="8.5" cy="8.5" r="2.5" />
		<path
			d="M14.526 12.621L6 22h12.133A3.867 3.867 0 0 0 22 18.133V18c0-.466-.175-.645-.49-.99l-4.03-4.395a2 2 0 0 0-2.954.006z" />
	</svg>`),
    },
    {
      key: 'safeties',
      name: 'Safety',
      icon: this.sanitizer.bypassSecurityTrustHtml(
        `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="ai ai-FaceWink"><circle cx="12" cy="12" r="10"/><path d="M7 9h2"/><path d="M16 9.05v-.1"/><path d="M16 15c-.5 1-1.79 2-4 2s-3.5-1-4-2"/></svg>`
      ),
    },
    {
      key: 'rules',
      name: 'Rules',
      icon: this.sanitizer.bypassSecurityTrustHtml(
        `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="ai ai-Shield"><path d="M13.147 21.197l1.67-1.168a13.393 13.393 0 0 0 5.447-13.624.837.837 0 0 0-.453-.586L12 2 4.19 5.819a.837.837 0 0 0-.454.586 13.393 13.393 0 0 0 5.448 13.624l1.67 1.168a2 2 0 0 0 2.293 0z"/></svg>`
      ),
    },
  ];

  public expand = {
    amenities: false,
    accessibility: false,
    atmosphere: false,
    landscape: false,
    saftey: false,
    rules: false,
  };
  public space: any = {
    reviews: {
      data: [],
    },
  };

  public similar_space = [];
  public unavailable = [];
  public unavailable_old = [];
  public loading = false;
  private viewNotifyTimeout = null;
  public userDetails: any;
  public isReserveClicked = false;
  public totalBookingDays = 1;
  public bookingFee = 10;
  token = '';
  public selectedLanguage = 'en';
  public showGuestPopup = false;
  public showGuestBottomSheet = false;

  filter: any = {};

  isEdit = false;
  user = 'Jason Statom';
  datePickerConfig = {
    format: 'DD-MM-YYYY',
  };
  items = [
    {
      name: 'home',
      link: '/',
    },
    {
      label: 'home details',
    },
  ];
  _albums = [];
  public reviewes = [];
  public guestList = [];
  public reviewList = [];
  aminitiesForm: FormGroup;
  contactHostbtn: boolean = false;
  public spaceExtraInfo;
  public desktopSwiperConfig: SwiperOptions = {
    slidesPerView: 8,
    virtual: true,
    spaceBetween: 15,
    pagination: false,
    navigation: false,
    breakpoints: {
      1024: {
        slidesPerView: 5,
        spaceBetween: 15,
      },
      1920: {
        slidesPerView: 8,
        spaceBetween: 15,
      },
    },
  };
  @ViewChild('homesCardComponent')
  homesCardComponent: HomesCardComponentComponent;
  @ViewChild('calendarDateComp') calendarDateComp: CalendarComponent;
  @ViewChild('bottomSheet') bottomSheet: BottomSheetComponent;
  @ViewChild('guestPopupRef') guestPopupRef: ElementRef<HTMLDivElement>;
  @ViewChild('guestBottomSheet') guestBottomSheet: ElementRef;
  @ViewChild('guestPopupAnchorRef')
  guestPopupAnchorRef: ElementRef<HTMLDivElement>;
  @ViewChild('guestBottomSheetAnchorRef')
  guestBottomSheetAnchorRef: ElementRef<HTMLDivElement>;
  @ViewChild('guestBottomSheetRef')
  guestBottomSheetRef: ElementRef<HTMLDivElement>;
  public submit = false;

  isDesktopView: boolean = window.screen.width > 1024;
  public isMobile: boolean = false;

  @ViewChild('dropdown') dropdownRef: DropPanelComponent;
  @ViewChild('dropdown2') dropdownRef2: DropPanelComponent;

  @HostListener('window:resize', ['$event'])
  handleResize(event: KeyboardEvent) {
    this.isDesktopView = window.screen.width > 1024;
  }

  routeEvents: Subscription;
  public isBooked = false;
  public ispopular = false;
  public isRegion = false;
  userEmail;
  public wishlist = [];
  public featureSpace = [];
  featured_home;
  public cityname: any;
  public spaceCity;
  public categories: any = [];
  isPremiumMember: boolean = false;
  userData: any;
  more_description: boolean = true;
  showAllAmenities: boolean = false;
  more_bio: boolean = false;
  today = moment();
  start_date = moment().add(1, 'days').format('YYYY-MM-DD');
  end_date = moment().add(2, 'days').format('YYYY-MM-DD');
  selectDateModal: boolean = false;
  hasHistory: boolean = false;
  redirect_from = '';
  totalSelectedAmenities = 0;
  hideAmenitiesButton = false;
  returnUrl = '';
  datesError = '';
  public seletedRange: any = null;
  checkIOS: boolean = false;
  isPWA: boolean = false;
  public isSafariBrowser = false;
  public isFirefoxBrowser = false;
  public filterList: any = {};
  public pennyCode = '14JE32';
  guestNumber: number = 2;
  guests = {
    adults: 0,
    children: 0,
    infants: 0,
    pets: 0,
  };

  maxAmenityLength: number = 15;
  maxOverviewLength: number = window.screen.width > 1024 ? 300 : 150;

  perNight = 10;

  constructor(
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private spaceService: SpaceService,
    private snackbarService: SnackbarService,
    private swapService: SwapService,
    private router: Router,
    private notificationService: NotificationService,
    public userService: UserService,
    private location: Location,
    private tokenService: TokenService,
    private clevertapService: ClevertapService,
    private languageService: LanguageService,
    private _lightbox: Lightbox,
    private cd: ChangeDetectorRef,
    private toastr: ToastrService,
    private exploreService: ExploreSpacesService,
    public translate: TranslateService,
    private cookieService: CookieService,
    private metadataService: MetadataService,
    private deviceService: DeviceDetectorService,
    private httpService: HttpService,
    private popupService: PopupService,
    private titleService: Title
  ) {
    this.hasHistory = this.router.navigated;

    this.checkIOS = this.isIOSDevice();
    this.isSafariBrowser = this.IsSafari();
    this.isMobile = window.screen.width < 769;
    this.isPWA = window.matchMedia('(display-mode: standalone)').matches;
    this.isFirefoxBrowser = this.IsFirefox();

    if (
      Object.keys(this.swapService.swap).length !== 0 &&
      this.swapService.bookingDates !== null
    ) {
      this.swap = this.swapService.swap;
      this.totalBookingDays =
        this.swapService.bookingDates.totalBookingDays || 1;
    }
    const userFullData = localStorage.getItem('userDataTEST');
    this.userData = JSON.parse(userFullData);
    if (this.userData) {
      this.isPremiumMember =
        this.userData.has_subscription.subscription_item_id !== 3;
      if (this.isPremiumMember) {
        this.bookingFee = 0;
      }
    } else {
      this.userService.currentUserSubject.subscribe((data) => {
        this.userData = data;
        this.isPremiumMember =
          this.userData.has_subscription.subscription_item_id !== 3;
        if (this.isPremiumMember) {
          this.bookingFee = 0;
        }
      });
    }
    this.getSpaceCategories();

    this.route.queryParams.subscribe((params) => {
      if (params.redirect_from == 'trips') {
        this.redirect_from = params.redirect_from;
        if (this.swap.type == 'host') {
          this.contactHostbtn = true;
        }
      } else {
        this.redirect_from = '';
      }

      if (params.returnURL == 'dashboard-profile') {
        this.returnUrl = params.returnURL;
      } else if (params.returnURL == 'map-view-list') {
        this.returnUrl = params.returnURL;
      }
    });

    if (
      localStorage.getItem('searchFilters') != 'undefined' &&
      typeof localStorage.getItem('searchFilters') != undefined
    ) {
      this.filter = JSON.parse(localStorage.getItem('searchFilters'));
    }

    if (this.filter?.started_at) {
      if (this.filter?.ended_at) {
        const startedAt = moment(this.filter.started_at);
        const endedAt = moment(this.filter.ended_at);
        if (startedAt.isSame(endedAt)) {
          endedAt.add(1, 'days');
          this.filter.ended_at = endedAt.format('YYYY-MM-DD');
          localStorage.setItem('searchFilters', JSON.stringify(this.filter));
        } else if (startedAt.isAfter(endedAt)) {
          const temp = this.filter.started_at;
          this.filter.started_at = this.filter.ended_at;
          this.filter.ended_at = temp;

          localStorage.setItem('searchFilters', JSON.stringify(this.filter));
        }
      }
    }

    if (this.filter?.started_at) {
      this.swap.your_swap.started_at = this.filter?.started_at;
    }

    if (this.filter?.ended_at) {
      this.swap.your_swap.ended_at = this.filter?.ended_at;
    }

    if (this.filter?.guests) {
      this.swap.your_swap.guests = this.filter?.guests;
    }
    this.guests.adults = this.swap.your_swap.guests;
  }

  get isTANHome(): boolean {
    return !!this.space?.tan_home_id;
  }

  authenticated(): boolean {
    return !!this.httpService.ACCESS_TOKEN;
  }

  calcTotalGuests(guests: Record<string, number>) {
    const total = Object.values(guests).reduce((sum, currentValue) => {
      return (sum += currentValue);
    }, 0);
    this.guestNumber = total;
    this.filter = {
      ...this.filter,
      guestNumber: total,
    };
    this.swap.your_swap.guests = total;
  }

  handleChangeGuests(field: string, changes: number) {
    if ((changes > 0 && this.guests[field] < 20) || changes < 0) {
      this.guests = {
        ...this.guests,
        [field]: Math.max(this.guests[field] + changes, 0),
      };
      this.calcTotalGuests(this.guests);
    }
  }

  @HostListener('document:click', ['$event.target'])
  onClick(htmlElement: HTMLElement) {
    if (
      !this.guestPopupAnchorRef?.nativeElement.contains(htmlElement) &&
      !this.guestPopupRef?.nativeElement.contains(htmlElement)
    ) {
      this.showGuestPopup = false;
    }

    if (this.isMobile) {
      if (
        !this.guestBottomSheetAnchorRef?.nativeElement.contains(htmlElement) &&
        !this.guestBottomSheetRef?.nativeElement.contains(htmlElement)
      ) {
        this.showGuestBottomSheet = false;
        $('.app-navigation').removeClass('hide');
      }
    }
  }

  openGuestSheet() {
    this.showGuestPopup = true;
  }

  openGuestBottomSheet() {
    $('.app-navigation').addClass('hide');
    this.showGuestBottomSheet = true;
  }

  isIOSDevice() {
    return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  }

  IsSafari() {
    const is_safari = /^(?!.*chrome).*safari/i.test(navigator.userAgent);
    return is_safari;
  }

  IsFirefox() {
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    return isFirefox;
  }

  ngOnInit(): void {
    this.localNavData = localStorage.getItem('navUrl')
      ? localStorage.getItem('navUrl')
      : '';

    this.route.params.subscribe((params) => {
      if (params.id) {
        // Fire the AppsFlyer event
        (window as any).AF('pba', 'event', {
          eventType: 'EVENT',
          eventName: 'home_view',
          eventValue: {
            space_id: params.id,
            url: window.location.href,
          },
        });

        if (this.httpService.ACCESS_TOKEN) {
          this.spaceService.viewSpace(params.id).subscribe((res) => {});
        }
        this._getSpace(params.id);
      }
    });

    if (this.cookieService.get('search-address')) {
      this.cityname = JSON.parse(this.cookieService.get('search-address'));
    }

    this.userService.currentUser.subscribe((user) => {
      this.wishlist = user?.favourited;
    });

    localStorage.setItem(
      'instantpaymentStatus',
      +this.instantpaymentStatus + ''
    );

    const loadLang = this.languageService.GetLanguage() + '';

    if (loadLang == 'null' || loadLang == '') {
      this.selectedLanguage = 'en';
    } else {
      this.selectedLanguage = loadLang;
    }

    this.languageService.languageUpdates.subscribe((lang) => {
      this.selectedLanguage = lang;
    });

    UserService.cacheBuster$.next();
    if (this.httpService.ACCESS_TOKEN) {
      this.userService.getMe().subscribe((user) => {
        this.userEmail = user.data.email;

        if (typeof this.swap?.your_swap?.started_at == 'undefined') {
          this.swap.your_swap.started_at = this.start_date;
          this.swap.their_swap.started_at = this.start_date;
        }
        if (typeof this.swap?.your_swap?.ended_at == 'undefined') {
          this.swap.your_swap.ended_at = this.end_date;
          this.swap.their_swap.ended_at = this.end_date;
        }

        this.seletedRange = {
          start: this.swap.your_swap.started_at,
          end: this.swap.your_swap.ended_at,
        };
      });
    } else {
      if (typeof this.swap?.your_swap?.started_at == 'undefined') {
        this.swap.your_swap.started_at = this.start_date;
        this.swap.their_swap.started_at = this.start_date;
      }
      if (typeof this.swap?.your_swap?.ended_at == 'undefined') {
        this.swap.your_swap.ended_at = this.end_date;
        this.swap.their_swap.ended_at = this.end_date;
      }
    }

    this.routeEvents = this.router.events.subscribe((event) => {
      if (
        event instanceof NavigationStart &&
        this.isDesktopView &&
        !event.url.includes('/view-home/checkout-booking/') &&
        !event.url.includes('view-home/home-details-ibs')
      ) {
        this.swapService.bookingDates = {};
        // this.swapService.swap = {}
      }
    });

    this.exploreService.getFeaturedSpaces().subscribe((res: any) => {
      this.featureSpace = res.data.filter((space) => {
        return !(space.purpose === 'swap' || space.payment_type_id !== 561);
      });

      this.featureSpace.forEach((feature) => {
        this.featured_home = feature;
      });
    });

    if (localStorage.getItem('searchFilters') != null) {
      this.filterList = JSON.parse(localStorage.getItem('searchFilters'));
    }
  }

  ngAfterViewInit(): void {
    if (this.isSafariBrowser) {
      $('.app-content').css('padding-bottom', '0px');
    }
    if (this.isFirefoxBrowser) {
      $('.app-content').css('padding-bottom', '0px');
    }
  }

  ngOnChanges() {}

  getTotalPrice() {
    const nights = this.getNights();
    if (this.space?.purpose?.name === 'swap') {
      return this.bookingFee * nights;
    } else {
      if (this.space.payment_type_id === 561) {
        return this.space.hosting_amount * nights + this.bookingFee * nights;
      } else {
        return this.space.hosting_tokens * nights;
      }
    }

    return 0;
  }

  getTotalBookingCost() {
    const nights = this.getNights();
    if (this.space.payment_type_id === 561) {
      return this.space.hosting_amount * nights;
    } else {
      return this.space.hosting_tokens * nights;
    }

    return this.bookingFee;
  }

  getNights(): number {
    if (this.swap?.your_swap?.started_at && this.swap?.your_swap?.ended_at) {
      const diff = moment(this.swap?.your_swap?.ended_at).diff(
        this.swap?.your_swap?.started_at,
        'day'
      );
      return Math.abs(diff);
    }
    return 0;
  }

  copyAddress() {
    const address =
      this.space?.address?.full_address ??
      this.space?.address?.city + this.space?.address?.country;
    navigator.clipboard.writeText(address);

    this.snackbarService.show({
      message: this.translate.instant('Address is copied'),
      type: 'success',
      config: {
        class: 'centered',
      },
    });
  }

  getSpaceCategories() {
    if (this.httpService.ACCESS_TOKEN) {
      this.metadataService
        .getSpaceCategories()
        .subscribe((response: { data: any }) => {
          this.categories = response.data;

          const amnetiesOverride = this.categories.amenities.map((v) => ({
            ...v,
            isActive: false,
          }));
          this.categories.amenities = amnetiesOverride;

          const rulesOverride = this.categories.rules.map((r) => ({
            ...r,
            isActive: false,
          }));
          this.categories.rules = rulesOverride;

          const accessibiltyOverride = this.categories.accessibilities.map(
            (r) => ({ ...r, isActive: false })
          );
          this.categories.accessibilities = accessibiltyOverride;

          const safetiesOverride = this.categories.safeties.map((s) => ({
            ...s,
            isActive: false,
          }));
          this.categories.safeties = safetiesOverride;
        });
    }
  }

  getDiscountAmount(amount, totalDay) {
    const totalAmt = amount * totalDay;
    let discount;
    if (this.isPremiumMember) {
      discount = Math.round((totalAmt * 20) / 100);
    } else {
      discount = 0;
    }
    const offerAmt = totalAmt - discount;
    return offerAmt;
  }

  getDiscount(amount, totalDay) {
    const totalAmt = amount * totalDay;
    let discount;
    if (this.isPremiumMember) {
      discount = Math.round((totalAmt * 20) / 100);
      if (discount <= 0) {
        this.hideMinus = true;
      }
    } else {
      discount = 0;
    }
    return discount;
  }

  private _getSpace(id: number) {
    this.loading = true;
    this.spaceService
      .getOpenSpace(id)
      .subscribe(
        (space) => {
          this.loading = false;
          this.space = space;
          this.titleService.setTitle(`${space.title} - HolidaySwap`);
          const metaImage = space.images?.[0]?.webp_image ?? '';
          this.spaceCity = this.space.address?.city;

          this.similar_space = space?.similar_spaces;

          this.cd.detectChanges();

          this.spaceId = this.space.id;
          this.spaceService.getSpace(this.spaceId).subscribe((res) => {
            this.spaceExtraInfo = { ...res };
            this.updateMarker();
          });
          this.reviewes = this.space.reviews.data;
          this.reviewList = this.space.reviews.data.slice(0, 2);

          const booking_rand = Math.floor(Math.random() * 2);

          this.randCount = booking_rand;
          if (booking_rand == 0) {
            const text = space.bookingWeekCount > 1 ? 'times' : 'time';
            this.total_booking_msg = `Booked ${space.bookingWeekCount} ${text} this ${this.bookings_msg[booking_rand]}`;
            this.displyCount = space.bookingWeekCount;
          } else if (booking_rand == 1) {
            const text = space.bookingMonthCount > 1 ? 'times' : 'time';
            this.total_booking_msg = `Booked ${space.bookingMonthCount} ${text} this ${this.bookings_msg[booking_rand]}`;
            this.displyCount = space.bookingMonthCount;
          } else if (booking_rand == 2) {
            const text = space.bookingsYearCount > 1 ? 'times' : 'time';
            this.total_booking_msg = `Booked ${space.bookingsYearCount} ${text} this ${this.bookings_msg[booking_rand]}`;
            this.displyCount = space.bookingsYearCount;
          } else {
            const text = space.bookingsCount > 1 ? 'times' : 'time';
            this.total_booking_msg = `Booked ${space.bookingsCount} ${text}`;
            this.displyCount = space.bookingsCount;
          }

          localStorage.setItem('space_id', this.space.id);
          this.spaceFeatures = this.space.amenities;
          this.notificationService.setFriend(this.space.user);
          const dates = [];
          const old_dates = [];
          this.space.unavailable_dates.map((date) => {
            const start = moment.unix(date.started_at).format('YYYY-MM-DD');
            const end = moment.unix(date.ended_at).format('YYYY-MM-DD');
            dates.push({
              dates: this.enumerateDaysBetweenDates(start, end),
              status: date.status,
            });
            old_dates.push(...this.enumerateDaysBetweenDates(start, end));
          });

          this.unavailable = dates;
          this.unavailable_old = old_dates;

          // if (this.space.purpose.name != 'both') {
          this.swap.type = this.space?.purpose?.name;
          this.step = this.step + 1;
          // 	this.loadSpaces(this.space.user.id);
          //   this.onProgress();
          // 	}
          this.generateGalleryImages(this.space.images);
          if (this.httpService.ACCESS_TOKEN) {
            this.getUnavailableDates(this.space);
          }
          this.patchAmenities();

          for (let i = 1; i <= this.space.guests; i++) {
            this.guestList.push({
              guestValue: i,
              guestName: i + ' ' + (i > 1 ? 'Guests' : 'Guest'),
            });
          }
          if (
            this.filterList?.guestNumber &&
            this.filterList?.guestNumber != '' &&
            this.filterList?.guestNumber != undefined
          ) {
            this.swap.your_swap.guests = this.filterList?.guestNumber;
          } else {
            this.swap.your_swap.guests = this.space?.guests;
          }
          if (
            localStorage.getItem('savedSearch') != '' &&
            localStorage.getItem('savedSearch') == '1' &&
            localStorage.getItem('savedSearch') != 'undefined'
          ) {
            this.swap.your_swap.guests = 2;
            localStorage.removeItem('savedSearch');
          }
          this.guests.adults = this.swap.your_swap.guests;

          this.viewNotifyTimeout = setTimeout(() => {
            if (
              space.id != localStorage.getItem('viewedProfileId') ||
              localStorage.getItem('viewedProfileId') == null
            ) {
              const _tempuser = {
                name: this.notificationService.getSelectedFriend().first_name,
                email: this.notificationService.getSelectedFriend().email,
                friendId: this.notificationService.getSelectedFriend().id,
                userId: this.userService.getCurrentUser().id,
                notificationType: 3,
                title: 'Your home was viewed',
                space_id: space.id,
                user_identity:
                  this.notificationService.getSelectedFriend()
                    .clevertap_identity,
                clevertap_id:
                  this.notificationService.getSelectedFriend().clevertap_id,
                message:
                  this.userService.getCurrentUser().first_name +
                  ' viewed your home',
              };

              localStorage.setItem('viewedProfileId', space.id);
              this.clevertapService.sendNotification(_tempuser);
            }
          }, 1000);
        },
        (err) => {
          this.loading = false;
          // handle error
        }
      )
      .add(() => {
        this.loading = false;
      });
  }

  public getUnavailableDates(space) {
    this.swap.your_swap.space_id = space.id;
    this.yourSwap = space;
    this.loading = true;

    this.spaceService
      .getUnavailableDates(space.id)
      .subscribe(
        (response) => {
          const dates = [];
          const dates_old = [];
          this.loading = false;
          response.map((date) => {
            const start = moment
              .unix(date.started_at)
              .utc()
              .format('YYYY-MM-DD');
            const end = moment.unix(date.ended_at).utc().format('YYYY-MM-DD');
            dates.push({
              dates: this.enumerateDaysBetweenDates(start, end),
              status: date.status,
            });
            dates_old.push(...this.enumerateDaysBetweenDates(start, end));
          });
          this.unavailable_old = dates_old;
          this.yourSwap.unavailable = dates;
          // this.syncFusionCalendarComp?.updateUnavailableDates(dates)
          this.cd.detectChanges();
        },
        (err) => {
          this.loading = false;
        }
      )
      .add(() => {
        this.loading = false;
      });
  }

  public enumerateDaysBetweenDates(startDate, endDate) {
    startDate = moment(startDate);
    endDate = moment(endDate);

    const now = startDate;
    const dates = [];

    while (now.isBefore(endDate) || now.isSame(endDate)) {
      dates.push(now.format('YYYY-MM-DD'));
      now.add(1, 'days');
    }
    return dates;
  }

  public pop() {
    // this.location.back();

    if (this.returnUrl) {
      if (this.returnUrl == 'dashboard-profile') {
        this.router.navigateByUrl(this.returnUrl);
      } else if (this.returnUrl == 'map-view-list') {
        this.router.navigateByUrl('/map-view-list');
      }
    } else {
      if (this.hasHistory) {
        this.location.back();
      } else {
        this.router.navigateByUrl('/');
      }
    }
  }

  goToConfirmBooking() {
    this.router.navigate(['/view-home/checkout-booking/' + this.space.id]);
  }

  public setSpaceBookingType() {
    this.instantpaymentStatus = !this.instantpaymentStatus;
    localStorage.setItem(
      'instantpaymentStatus',
      +this.instantpaymentStatus + ''
    );
  }

  statusReadOnly() {
    this.instantpaymentStatus = false;

    localStorage.setItem(
      'instantpaymentStatus',
      +this.instantpaymentStatus + ''
    );
    return false;
  }

  generateGalleryImages(images) {
    this._albums = [];
    for (const img of images) {
      let useImg = img.image_url;
      if (window.innerWidth < 1200) {
        useImg = img.desktop_image;
      } else if (window.innerWidth < 768) {
        useImg = img.ipad_image;
      } else if (window.innerWidth < 575) {
        useImg = img.mobile_image;
      }
      const album = {
        src: useImg,
        caption: '',
        thumb: img.mobile_image,
      };

      this._albums.push(album);
    }
  }

  selectedCoverImage: number = 0;

  openLightbox(index?: number): void {
    this._lightbox.open(this._albums, index || this.selectedCoverImage);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  public setUnavailableDates() {
    this.calendarDateComp.buildCalendar();
  }

  public onDateSelected(date, me: boolean) {
    if (!date.end || !date.start) return;
    const tempDate = {
      ended_at: date.end,
      started_at: date.start,
      totalBookingDays: date.days,
    };

    if (date.start != undefined && date.end != undefined) {
      const queryParams = {
        startDate: date.start,
        endDate: date.end,
        spaceId: this.space?.id,
      };
      this.spaceService.checkStateAvailable(queryParams).subscribe(
        (res: any) => {
          if (res.status == false) {
            this.datesError = res.message;
            setTimeout(() => {
              this.datesError = '';
            }, 5000);
            this.toastr.error(res.message, 'Error');

            this.swap.your_swap.started_at =
              this.route.snapshot.queryParams.from;
            this.swap.your_swap.ended_at = this.route.snapshot.queryParams.to;
            this.totalBookingDays = moment(
              this.route.snapshot.queryParams.to
            ).diff(moment(this.route.snapshot.queryParams.from), 'days', false);
            return;
          }
        },
        (err) => {
          this.datesError = err;
          setTimeout(() => {
            this.datesError = '';
          }, 5000);
          this.toastr.error(err, 'Error');

          this.swap.your_swap.started_at = this.route.snapshot.queryParams.from;
          this.swap.your_swap.ended_at = this.route.snapshot.queryParams.to;
          this.totalBookingDays = moment(
            this.route.snapshot.queryParams.to
          ).diff(moment(this.route.snapshot.queryParams.from), 'days', false);
          return;
        }
      );
    }
    this.swapService.saveBookingDates(tempDate);
    if (me) {
      this.swap.your_swap.ended_at = date.end;
      this.swap.your_swap.started_at = date.start;
      this.totalBookingDays = date.days;
    } else {
      this.swap.their_swap.ended_at = date.end;
      this.swap.their_swap.started_at = date.start;
    }

    this.swapService.swap = this.swap;
  }

  bookHome() {
    this.isBooked = true;
    this.ispopular = true;
    this.isRegion = true;
    this.submit = true;
    this.swapService.swap = this.swap;
    if (!this.swap.your_swap.started_at) {
      this.toastr.error('Please select booking dates', 'Error');
      return;
    } else if (!this.swap.your_swap.ended_at) {
      this.toastr.error('Please select booking dates', 'Error');
      return;
    } else if (!this.swap.your_swap.guests) {
      this.toastr.error('Please select number of guests', 'Error');
      return;
    }

    this.router.navigate(['/view-home/checkout-booking/' + this.space.id], {
      queryParams: {
        from: this.swap.your_swap.started_at,
        to: this.swap.your_swap.ended_at,
        guest: this.swap.your_swap.guests,
      },
    });
  }

  public onLike() {
    this.spaceService
      .likeSpace(this.space.id, this.space.is_favourited)
      .subscribe(
        (response) => {
          this.space.is_favourited = !this.space.is_favourited;
          if (this.space.is_favourited) {
            this.snackbarService.show({
              message: this.translate.instant(
                'This home has been saved to your favourites!'
              ),
              type: 'success',
              config: {
                class: 'centered',
              },
            });
          } else {
            this.snackbarService.show({
              message: this.translate.instant(
                'Removed home from your favourites'
              ),
              type: 'success',
              config: {
                class: 'centered',
              },
            });
          }

          const _tempuser = {
            name: this.notificationService.getSelectedFriend().first_name,
            email: this.notificationService.getSelectedFriend().email,
            friendId: this.notificationService.getSelectedFriend().id,
            userId: this.userService.getCurrentUser().id,
            title: 'Your home was liked!',
            notificationType: 4,
            user_identity:
              this.notificationService.getSelectedFriend().clevertap_identity,
            clevertap_id:
              this.notificationService.getSelectedFriend().clevertap_id,
            message:
              this.userService.getCurrentUser().first_name +
              " liked your home. See if it's a match!",
          };

          this.clevertapService.sendNotification(_tempuser);
        },
        (err) => {
          // this.toastr.error(err, "Error")
          this.toastr.error(this.translate.instant(err), 'Error');
        }
      );
  }

  viewReviews() {
    if (this.space?.reviews?.data?.length > 0) {
      this.router.navigate(['/ratings/view-rating/' + this.space.id]);
    }
  }

  toggleAmenities() {
    if (this.maxAmenityLength < this.categories.amenities.length) {
      this.maxAmenityLength = this.categories.amenities.length;
    } else {
      this.maxAmenityLength = 15;
    }
  }

  closeAmenities() {
    this.showAllAmenities = false;
    $('.allAmenities').css('height', '300px');
    $('.allAmenities').css('transition', '2s');
    $('.amenities_block_btn_wrapper .shadow_box').css('display', 'block');
  }

  closeDateSelection() {
    this.selectDateModal = false;
  }

  handleBookHome() {
    if (this.userData?.id !== this.space?.user?.id) {
      this.isBooked = true;
      this.ispopular = true;
      this.isRegion = true;
      this.submit = true;
      this.swapService.swap = this.swap;
      this.router.navigate(['/view-home/checkout-booking/' + this.space.id], {
        queryParams: {
          from: this.swap.your_swap.started_at,
          to: this.swap.your_swap.ended_at,
          guest: this.swap.your_swap.guests,
          adults: this.guests.adults,
          children: this.guests.children,
          pets: this.guests.pets,
          infants: this.guests.infants,
        },
      });
    } else {
      this.toastr.error('You cannot book your owned home', 'Error');
    }
  }

  toggleCalendar() {
    if (!this.isDesktopView) {
      this.bottomSheet.toggle();
    } else {
      this.selectDateModal = true;
    }
  }

  ngOnDestroy(): void {
    $('.app-navigation').removeClass('hide');
    this.titleService.setTitle('Holiday Swap');
    if (this.routeEvents) {
      this.routeEvents.unsubscribe();
    }
    if (this.isBooked == false && this.localNavData == 'SearchList') {
      const homeData = {
        city: this.spaceCity,
        email: this.userEmail,
        birth_date: '',
        user_name: '',
        user_id: '',
        signed_in: '',
      };

      this.clevertapService.pushCleverTapEvent('home_not_booked', homeData);
      localStorage.removeItem('navUrl');
    }

    if (this.wishlist && this.wishlist.length <= 0) {
      const searchData = {
        email: this.userEmail,
      };
      this.clevertapService.pushCleverTapEvent('wishlist', searchData);
    }

    if (
      this.localNavData == 'PopularHomes' &&
      this.featured_home &&
      this.featured_home.featured_position !== null &&
      this.featured_home.featured_position !== undefined &&
      this.featured_home.featured_position !== '' &&
      Number(this.featured_home.is_featured) === 1 &&
      !this.ispopular
    ) {
      const searchData = {
        email: this.userEmail,
      };

      this.clevertapService.pushCleverTapEvent(
        'popular_home_not_booked',
        searchData
      );
      localStorage.removeItem('navUrl');
    }

    if (
      this.localNavData == 'Trendings' &&
      this.cityname &&
      Object.keys(this.cityname).length > 0 &&
      this.cityname?.city_name &&
      this.cityname?.city_name !== undefined &&
      this.cityname?.city_name !== null &&
      this.cityname?.city_name !== '' &&
      !this.isRegion
    ) {
      const searchData = {
        email: this.userEmail,
      };
      this.clevertapService.pushCleverTapEvent(
        'trending_home_not_booked',
        searchData
      );

      localStorage.removeItem('navUrl');
    }

    if (this.isSafariBrowser) {
      $('.app-content').css('padding-bottom', '65px');
    }

    if (this.isFirefoxBrowser) {
      $('.app-content').css('padding-bottom', '65px');
    }
  }

  contactHost(spaceId) {
    const token = localStorage.getItem('token');
    if (token && token !== 'undefined') {
      this.router.navigate(['/messages/' + spaceId]);
    } else {
      this.popupService.openAuthModal();
    }
  }

  askToSwap() {
    this.router.navigate([this.space.deeplink_url]);
  }

  pricePerNight() {
    if (this.space.payment_type_id === 561) {
      return this.space.hosting_amount;
    } else {
      return this.space.hosting_tokens;
    }
  }

  patchAmenities() {
    if (this.space?.amenities) {
      this.space?.amenities?.map((res1) => {
        this.categories?.amenities?.filter((res) => {
          if (res1.id == res.id) {
            res.isActive = true;
            // let selected = (this.aminitiesForm.controls['amenities'] as FormArray);
            // selected.push(new FormControl(res.id))
          }
        });
      });
    }

    if (this.space?.accessibilities) {
      this.space?.accessibilities?.map((res1) => {
        this.categories?.accessibilities?.filter((res) => {
          if (res1.id == res.id) {
            res.isActive = true;
            // let selected = (this.aminitiesForm.controls['accessibilities'] as FormArray);
            // selected.push(new FormControl(res.id))
          }
        });
      });
    }

    if (this.space?.rules) {
      this.space?.rules?.map((res1) => {
        this.categories?.rules?.filter((res) => {
          if (res1.id == res.id) {
            res.isActive = true;
            // let selected = (this.aminitiesForm.controls['rules'] as FormArray);
            // selected.push(new FormControl(res.id))
          }
        });
      });
    }

    if (this.space?.safeties) {
      this.space?.safeties?.map((res1) => {
        this.categories?.safeties?.filter((res) => {
          if (res1.id == res.id) {
            res.isActive = true;
            // let selected = (this.aminitiesForm.controls['safeties'] as FormArray);
            // selected.push(new FormControl(res.id))
          }
        });
      });
    }

    this.categories.amenities = this.categories.amenities?.filter(
      (res) => res.isActive == true
    );
    this.categories.accessibilities = this.categories.accessibilities?.filter(
      (res) => res.isActive == true
    );
    this.categories.rules = this.categories.rules?.filter(
      (res) => res.isActive == true
    );
    this.categories.safeties = this.categories.safeties?.filter(
      (res) => res.isActive == true
    );

    this.totalSelectedAmenities =
      this.categories?.amenities?.length +
      this.categories?.accessibilities?.length +
      this.categories?.rules?.length +
      this.categories?.safeties?.length;

    setTimeout(() => {
      if ($('#amenitiesForm').outerHeight() > 300) {
        this.hideAmenitiesButton = false;
      } else {
        this.hideAmenitiesButton = true;
      }
    }, 300);
  }

  public goToEdit() {
    this.router.navigate(['/spaces/edit-home/' + this.space.id]);
  }

  getCombinedAddress(): string {
    const street1 = this.spaceExtraInfo.address.street_1;
    const street2 = this.spaceExtraInfo.address.street_2;
    const city = this.spaceExtraInfo.address.city;
    const state = this.spaceExtraInfo.address.state;
    const postcode = this.spaceExtraInfo.address.postcode;
    const country = this.spaceExtraInfo.address.country;

    let address = '';

    if (street1) {
      address += street1;
    }

    if (street2) {
      address += (address ? ', ' : '') + street2;
    }

    if (city) {
      address += (address ? ', ' : '') + city;
    }

    if (state) {
      address += (address ? ', ' : '') + state;
    }

    if (postcode) {
      address += (address ? ' ' : '') + postcode;
    }

    if (country) {
      address += (address ? ', ' : '') + country;
    }

    address = address.trim();

    return address;
  }

  updateMarker() {
    const geocoder = new google.maps.Geocoder();
    const combinedAddress = this.getCombinedAddress();
    geocoder.geocode({ address: combinedAddress }, (results, status) => {
      if (status === 'OK') {
        const location = results[0].geometry.location;
        this.space.address.longitude = location.lng();
        this.space.address.latitude = location.lat();
        if (this.locationMap) {
          this.locationMap?.flyTo(location.lat(), location.lng());
        }
      }
    });
  }
}
