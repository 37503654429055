import { NgModule, Optional, SkipSelf } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ThemeService } from "./services/theme.service";
import { UserService } from "../shared/services/user.service";
import { MessagingService } from "./services/messaging.service";



@NgModule({
	declarations: [],
	providers: [ThemeService, UserService, MessagingService],
	imports: [
		CommonModule
	]
})
export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		throwIfAlreadyLoaded(parentModule, "CoreModule");
	}
}
function throwIfAlreadyLoaded(parentModule: any, moduleName: string) {
	if (parentModule) {
		throw new Error(`${moduleName} has already been loaded. Import Core modules in the AppModule only.`);
	}
}
