<div class="explore-section-main">
    <div class="app_container">
        <!-- explore image header start -->
        <div class="explore_header_main">
            <div class="explore_header">
                <!-- header left brand start -->
                <div class="explore_left">
                    <div class="explore_brand" [routerLink]="['/']">
                        <span><img alt="" src="/assets/app-logo.svg"></span>
                        <span class="brand_title" (click)="testParticularEvent()">Holiday Swap</span>
                    </div>
                </div>
                <div class="tropy_icon_outer">
                    <a routerLink="/global-leaderboard" [routerLinkActive]="['active']" class="trophy_icon"> <span
                            class="nav_item_icon trophy_icon active">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M6.75 4.06452C6.75 3.33853 7.33853 2.75 8.06452 2.75H15.9355C16.6615 2.75 17.25 3.33853 17.25 4.06452V12C17.25 14.8995 14.8995 17.25 12 17.25C9.10051 17.25 6.75 14.8995 6.75 12V4.06452Z"
                                    stroke="white" stroke-width="1.5" />
                                <path d="M6.92 5H3.90323C3.40439 5 3 5.40439 3 5.90323V8C3 10.2091 4.79086 12 7 12V12"
                                    stroke="white" stroke-width="1.5" />
                                <path
                                    d="M17.08 5H20.0968C20.5956 5 21 5.40439 21 5.90323V8C21 10.2091 19.2091 12 17 12V12"
                                    stroke="white" stroke-width="1.5" />
                                <path d="M8 21.9004H15.8" stroke="white" stroke-width="1.5" stroke-linecap="round" />
                                <path d="M12 18L12 21.9" stroke="white" stroke-width="1.5" stroke-linecap="round" />
                            </svg>
                        </span> </a>
                    <app-language-selector></app-language-selector>
                </div>

                <!-- header left brand start -->
                <!-- header right navigation start -->
                <!-- <div class="explore-right">
                <ul>
                    <li class="notification"><a><img src="/assets/images/notification_ic.svg"><span class="noti_badge"></span></a></li>
                    <li class="language_dd">
                        <div class="dropdown">
                            <a class="btn btn-secondary dropdown-toggle" (click)="onShow()">
                            <img src="/assets/flags/uk_flag.png">
                            </a>
                            <div class="dropdown-menu bunch_drop" aria-labelledby="dropdownMenuLink">
                            <a class="dropdown-item" >Action</a>
                            <a class="dropdown-item" >Another action</a>
                            <a class="dropdown-item" >Something else here</a>
                            </div>
                        </div>
                    </li>
                </ul>
            </div> -->
                <!-- header right navigation end -->
            </div>
            <!-- header message notification start -->
            <div class="header_msg_notification" [ngClass]="{'header_msg_notif_dirOverride': selectedLanguage == 'ar'}">
                <div class="mg_notification_item"
                    [ngClass]="{'mg_notification_item_dirOverride': selectedLanguage == 'ar'}">
                    <div class="mg_notification_img">
                        <!-- <app-avatar height="80" width="80" [picture]="user?.image_url"
                        [name]="user?.first_name" [verified]="user?.is_verified">
                         </app-avatar> -->
                        <img alt="" class="lazyload onboard-img" [lazyLoad]="user?.image_url + '?' + timeStamp" />
                    </div>
                    <div class="mg_notification_details">
                        <span class="mg_title">{{"Hi" | translate}} {{ user?.first_name }}! <img
                                src="assets/icons/smily__emoji.svg" alt=""> </span>
                        <span *ngIf="+userDetails?.tokens>1 else elseblock" class="mg_token">{{"You have" | translate }}
                            {{ userDetails?.tokens }} {{ userDetails?.tokens == 1 ? "HS Point" : "HS Points" | translate}} </span>
                        <ng-template #elseblock>
                            <span class="mg_token">{{"You have" | translate }} {{ userDetails?.tokens }} {{userDetails?.tokens == 1?  "HS Point"  : "HS Points"|
                                translate}}</span>
                        </ng-template>
                        <a (click)="goToTokenPurchase()">{{"My HS Points"|translate}}</a>
                    </div>
                </div>
                <!-- <div class="header_add_home">
                    <div class="add_action_inner">
                        <div class="home_btn" (click)="goToSpace()">
                            <button type="button">{{"Add home" | translate}}</button>
                        </div>
                        <span class="token_home" [ngClass]="{'token_home-direOverride': selectedLanguage == 'ar'}">
                            <p>{{"earn HS Points" | translate }}</p><img src="/assets/images/Token_Plane.png">
                        </span>
                    </div>
                </div> -->
            </div>
            <!-- header message notification end -->

            <!-- <div class="search_area">
            <div class="search_form">
                <input type="text" class="form-input" placeholder="Where are you going?">
                <button type="button" class="search_btn"><img src="/assets/images/loc_wh_ic.svg"></button>
            </div>
            <div class="map_view_btn btn_large">
                <button type="button">Map View&nbsp;&nbsp; <img src="/assets/images/loc_wh_ic.svg"></button>
            </div>
        </div> -->

            <app-search-address-box (onSearchSpaces)="searchResults()" (onSearchListViewSpaces)="searchViewList()"
                [(location)]="address"></app-search-address-box>
            <div style="display: flex;align-items: center;justify-content: center;" class="home_btn mt-1">


                <!-- <button  (click)="gotoSwipePage(subscription?.subscription_id)">{{"Swap it!" | translate}}</button> -->

                <!-- *ngIf="this.subscription?.subscription_id !=3" -->
            </div>


            <!-- <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#subscription_modal">
           modals
          </button>  -->
        </div>
        <!-- explore image header end -->
        <!-- poplar widget start -->


        <!-- <div class="popular_widget_main">
        <div class="widget_header">
            <h2>Popular homes</h2>
            <span class="view_link"><a>View all</a></span>
        </div>

        <div class="popular_widget_item">
            <div class="card">
                <div class="popular_image">
                <img class="card-img-top" src="../../../../../assets/images/3.jpg" alt="Card image cap">
                <div class="popular_action">
                    <ul>
                        <li><a><img src="../../../../../assets/images/send_ic.svg" ></a></li>
                        <li><a><img src="../../../../../assets/images/fav_ic.png" ></a></li>
                    </ul>
                </div>
                </div>
                <div class="card-body">
                    <div class="popular_title">
                        <h5 class="card-title">Little cotton house</h5>
                        <span class="rating_item"><img src="../../../../../assets/images/star_ic.svg" >&nbsp;&nbsp;4.2</span>
                    </div>
                    <div class="popular_des">
                        <span>3 bedrooms . 6 guests</span>
                        <span>22 {{"reviews" | translate}}</span>
                    </div>
                    <hr>
                    <div class="popular_footer">
                        <span><img src="../../../../../assets/images/mark_gray_ic.svg" >&nbsp;United States, Joshua Tree</span>
                        <span class="explore_price">$1,280</span>
                    </div>
                </div>
              </div>
        </div>



    </div> -->

        <app-featured-explore-spaces #featuredExploreSpacesEl [featuredSpaces]="featuredSpaces" *ngIf="arb">
        </app-featured-explore-spaces>

        <app-featured-explore-spaces #featuredExploreSpacesEl [featuredSpaces]="featuredSpaces" *ngIf="!arb">
        </app-featured-explore-spaces>




        <!-- poplar widget end -->
        <!-- <div class="trending_widget_main">
        <div class="widget_header">
            <h2>Trending regions</h2>
            <span class="view_link"><a>View all</a></span>
        </div>
        <div class="trending_widget_item">
            <div class="trending_image">
                <img src="../../../../../assets/images/6.jpg">
            </div>
            <div class="trending_cont">
                <span class="trending_title">Little house, Norway</span>
            </div>
        </div>
    </div> -->




        <app-my-homes-explore #myhomesEl *ngIf="mySpaces.length > 0 && arb" [mySpaces]="mySpaces"></app-my-homes-explore>
        <app-my-homes-explore #myhomesEl *ngIf="mySpaces.length > 0 && !arb" [mySpaces]="mySpaces"></app-my-homes-explore>

        <app-empty-my-homes *ngIf="mySpaces.length == 0 && arb" [mySpaces]="mySpaces"></app-empty-my-homes>
        <app-empty-my-homes *ngIf="mySpaces.length == 0 && !arb" [mySpaces]="mySpaces"></app-empty-my-homes>



        <app-featured-regions #featuredRegionstSpacesEl [featuredLocations]="featuredLocations" *ngIf="arb">
        </app-featured-regions>

        <app-featured-regions #featuredRegionstSpacesEl [featuredLocations]="featuredLocations" *ngIf="!arb">
        </app-featured-regions>


    </div>
</div>

<app-loading [loading]="loading"></app-loading>



<!-- subscription modal start -->
<!-- Modal -->
<div class="modal subscription_plan" *ngIf="subscribePopStatus" id="subscription_modal" tabindex="-1"
    aria-labelledby="exampleModalLabel" aria-hidden="true">

    <div (click)="pop()" class="close_icon">

        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
            class="ai ai-ArrowLeft">
            <path d="M11 5l-7 7 7 7" />
            <path d="M4 12h16" />
        </svg>

        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M0.727211 0.727253C1.06476 0.389806 1.52252 0.200238 1.99981 0.200238C2.47711 0.200238 2.93486 0.389806 3.27241 0.727253L10.9998 8.45465L18.7272 0.727253C18.8933 0.555335 19.0919 0.418207 19.3115 0.323871C19.5311 0.229535 19.7673 0.179879 20.0063 0.177802C20.2453 0.175725 20.4823 0.221268 20.7035 0.311774C20.9247 0.402279 21.1257 0.535935 21.2947 0.704942C21.4637 0.873949 21.5974 1.07492 21.6879 1.29614C21.7784 1.51735 21.8239 1.75437 21.8219 1.99338C21.8198 2.23238 21.7701 2.46857 21.6758 2.68818C21.5815 2.90779 21.4443 3.10641 21.2724 3.27245L13.545 10.9999L21.2724 18.7273C21.6003 19.0667 21.7817 19.5214 21.7776 19.9934C21.7735 20.4653 21.5842 20.9168 21.2505 21.2505C20.9168 21.5843 20.4653 21.7736 19.9933 21.7777C19.5214 21.7818 19.0667 21.6003 18.7272 21.2725L10.9998 13.5451L3.27241 21.2725C2.93293 21.6003 2.47824 21.7818 2.00629 21.7777C1.53433 21.7736 1.08287 21.5843 0.749138 21.2505C0.415403 20.9168 0.226098 20.4653 0.221997 19.9934C0.217896 19.5214 0.399327 19.0667 0.727211 18.7273L8.45461 10.9999L0.727211 3.27245C0.389763 2.9349 0.200195 2.47715 0.200195 1.99985C0.200195 1.52256 0.389763 1.0648 0.727211 0.727253Z"
                fill="#ABB1C0" />
        </svg>

    </div>

    <div class="subscribe">
        <h1>{{"Unlock" | translate}}
            {{"Unlimited Access" | translate}}</h1>
        <p>{{ "Get more out of Holiday Swap with Premium" | translate }}</p>

        <div *ngIf="subscriptions.length > 0" class="features">
            <ul>
                <!-- <li *ngFor="let feature of subscriptions[0].features">{{feature | translate}}</li>
                <li>{{ "Risk free, cancel any time" | translate }}</li> -->
                <li
                    [ngClass]="{'features_override': selectedLanguage == 'ar', 'features-before_override': selectedLanguage == 'ar'}">
                    {{"Everything on Holiday Swap for one low price" | translate }}</li>
                <li
                    [ngClass]="{'features_override': selectedLanguage == 'ar', 'features-before_override': selectedLanguage == 'ar'}">
                    {{"Unlimited swaps on all your devices" | translate }}</li>
                <li
                    [ngClass]="{'features_override': selectedLanguage == 'ar', 'features-before_override': selectedLanguage == 'ar'}">
                    {{"No commitments, cancel anytime" | translate }}</li>
                <li
                    [ngClass]="{'features_override': selectedLanguage == 'ar', 'features-before_override': selectedLanguage == 'ar'}">
                    {{"Don’t pay to stay when traveling" | translate }}</li>
                <li
                    [ngClass]="{'features_override': selectedLanguage == 'ar', 'features-before_override': selectedLanguage == 'ar'}">
                    {{"20% discount on HS Point bookings" | translate }}</li>
                <li
                    [ngClass]="{'features_override': selectedLanguage == 'ar', 'features-before_override': selectedLanguage == 'ar'}">
                    {{"Risk free." | translate }}</li>

            </ul>
        </div>


        <div class="section">
            <!-- {{ "Select plan" | translate }} -->
            <ng-container *ngFor="let subscription of subscriptions; let i = index">

                <div *ngIf="i==1" class="free_plan" (click)="onShowPayment(subscription)">
                    <div class="free_plan_main">
                        <p> {{"start your 14 days free trial" | translate}} <br>
                            <span class="em_text">{{"After That" | translate}}, {{"$10/month"| translate}}</span>
                        </p>
                    </div>
                </div>

                <!-- <div *ngIf="i==1 &&  user?.has_subscription == null" class="free_plan" (click)="onShowPayment(subscription)">
                    <h3>{{"START YOUR 2 WEEKS FREE TRIAL" | translate }} <br><span class="em_text">After that, $10/month</span></h3>
                </div> -->
            </ng-container>
        </div>
        <div class="options">

            <ng-container *ngFor="let subscription of subscriptions; let i = index">
                <div appRipple class="option" *ngIf="subscription.id != 3">


                    <div class="details" (click)="setSelectedPlan(subscription)">


                        <input type="radio" *ngIf="subscription.id == 1" [id]="subscription.id" name="subscription"
                            value="{{subscription.name}}" [checked]="selectedSubscriptionId == 1">

                        <input type="radio" *ngIf="subscription.id == 2" [id]="subscription.id" name="subscription"
                            value="{{subscription.name}}" [checked]="selectedSubscriptionId == 2">  
                        <input type="radio" *ngIf="subscription.id == 3" [id]="subscription.id" name="subscription"
                            value="{{subscription.name}}" [checked]="selectedSubscriptionId == 3">  
                        <label for="{{subscription.id}}">
                            <div>
                                <span class="name"> {{subscription.name | translate}} &nbsp;
                                </span><br>
                                <span class="plan-des">
                                    {{ (subscription?.price / 100)| currency}} / {{subscription?.interval | translate}}
                                </span>
                            </div>
                        </label>


                    </div>

                </div>
            </ng-container>

            <div class="plan_access">
                <button type="button" appRipple (click)="openPaymentPopup()">{{"Unlock unlimited access" |
                    translate}}</button>
                <!-- <button type="button" *ngIf="this.selectedSubscriptionId == 3" appRipple (click)="openPaymentPopup()">{{"Get lite access" | translate}}</button> -->
            </div>

            <!-- <div appRipple (click)="openPaymentPopup()" *ngIf="user?.has_subscription == null" class="action-button highlight"> {{"START YOUR 2 WEEKS FREE TRIAL" | translate}}
            </div>
            <div appRipple (click)="openPaymentPopup()" *ngIf="user?.has_subscription?.id" class="action-button highlight"> START PREMIUM
            </div> -->

        </div>

        <!--div *ngIf="selected" appRipple class="action">
            Subscribe
        </div-->
        <app-loading [loading]="loading"></app-loading>

    </div>


    <!-- <app-bottom-sheet (closed)="onClearSubscription()" #bottomSheet="bottomSheet" [defaultHeight]="'100%'" [maxHeight]="'900px'" [minHeight]="'900px'">

        <div bottomSheet-header>{{"Subscribe" | translate}}</div>
        <div bottomSheet-body *ngIf="bottomSheet._visible">

            <div class="pricing-container text-center">
                <h1 style="margin: 0px;" class="text-center">{{ selected?.name }} </h1>
                <p style="margin: 0px;" class="text-meta">{{"for | translate"}} {{ (selected?.price) / 100 | currency }} / {{ selected?.interval}}</p>
                <p class="text-meta">{{"Choose an existing or add a new payment method to complete the purchase" | translate}}.</p>
            </div>

            <div>
                <app-pay (onSuccess)="onPaymentSuccess($event)"
                    [item]="{id: selected?.id, name: 'Subscription', type: 'subscription', price: selected?.price / 100, quantity: selected?.interval}">
                </app-pay>

            </div>

            <app-loading [loading]="loading"></app-loading>
        </div>

    </app-bottom-sheet> -->


    <app-bottom-sheet *ngIf="!isDesktopView" (closed)="onClearSubscription()" #bottomSheet="bottomSheet"
        [defaultHeight]="'100%'">

        <!-- <div bottomSheet-header>{{"Subscribe" | translate}}</div> -->
        <div bottomSheet-body *ngIf="bottomSheet._visible">

            <div class="token-payment_main-div">
                <div class="token-payment-header">
                    <div class="logo-section" [routerLink]="['/']">
                        <img class="holiday-logo" src="/assets/logos/holidayswap-logo.png">
                        <p class="holidayswap-txt">Holiday Swap</p>
                    </div>
                    <!-- <img class="close-icon" src="/assets/icons/close_icon.png"> -->
                </div>

                <div class="buy-options">
                    <p class="buy-tokens-txt">{{"Subscription" | translate}}</p>

                    <div class="options-section">
                        <button class="credit-payment-btn" (click)="showCardPayment()">
                            <img src="/assets/icons/credit-card-icon.png">
                            <span class="payment-type-txt">{{"Credit Card" | translate}}</span>
                        </button>
                    </div>
                </div>

                <p class="save-money-txt">
                    {{"You are about to save money on your travels!" | translate}}
                </p>

                <div *ngIf="cardPayment">
                    <app-pay (onSuccess)="onPaymentSuccess($event)"
                        [item]="{id: selected?.id, name: 'Subscription', type: 'subscription', price: selected?.price / 100, quantity: selected?.interval}">
                    </app-pay>
                </div>
                <app-loading [loading]="loading"></app-loading>
            </div>
        </div>
    </app-bottom-sheet>


</div>
<div class="bix__modal-backdrop" *ngIf="subscribePopStatus"> </div>
<!-- subscription modal end -->

<app-bottom-sheet *ngIf="isDesktopView" (closed)="onClearSubscription()" #bottomSheet="bottomSheet"
    [defaultHeight]="'100%'">

    <!-- <div bottomSheet-header>{{"Subscribe" | translate}}</div> -->
    <div bottomSheet-body *ngIf="bottomSheet._visible">

        <div class="token-payment_main-div">
            <div class="token-payment-header">
                <div class="logo-section" [routerLink]="['/']">
                    <img alt="" class="holiday-logo" src="/assets/logos/holidayswap-logo.png">
                    <p class="holidayswap-txt">Holiday Swap</p>
                </div>
                <!-- <img class="close-icon" src="/assets/icons/close_icon.png"> -->
            </div>

            <div class="buy-options">
                <p class="buy-tokens-txt">{{"Subscription" | translate}}</p>

                <div class="options-section">
                    <button class="credit-payment-btn" (click)="showCardPayment()">
                        <img alt="" src="/assets/icons/credit-card-icon.png">
                        <span class="payment-type-txt">{{"Credit Card" | translate}}</span>
                    </button>
                </div>
            </div>

            <p class="save-money-txt">
                {{"You are about to save money on your travels!" | translate}}
            </p>

            <div *ngIf="cardPayment">
                <app-pay (onSuccess)="onPaymentSuccess($event)"
                    [item]="{id: selected?.id, name: 'Subscription', type: 'subscription', price: selected?.price / 100, quantity: selected?.interval}">
                </app-pay>
            </div>
            <app-loading [loading]="loading"></app-loading>
        </div>
    </div>
</app-bottom-sheet>


<div id="add-home-popup" class="add-home-modal" *ngIf="addHomePopup">
    <div class="close-section">
        <div class="skip-and-close"> <span class="close-popup" (click)="closeHomePopup()"><img alt="" src="/assets/images/cancel_ic.svg"></span> </div>
    </div>

    <div class="token-img-box"><img alt="" class="token-img-style" src="/assets/images/Token_Plane.png"></div>
    <div class="add-home-title">
        <h2> {{"Add your home" | translate}}!</h2>
    </div>

    <div class="add-home-content">
        <p class="message-text-style"> Add your home with high quality photos and </p>
        <p class="message-text-style"> we will instantly reward you with 70 HS Points </p>
        <p class="message-text-style"> worth $70 </p>
    </div>

    <div class="waiting-for-text"> What are you waiting for ?</div>

    <div class="add-home-btn-main">
        <div class="add-home-btn-head" (click)="goToSpace()">
            <button type="button" class="add-home-btn-popup"> {{"Add home" | translate}}
            </button>
        </div>
    </div>
</div>

<div id="add-rating-popup" class="add-home-modal add_rating_modal" *ngIf="addRatingPopup">
    <div class="close-section">
        <div class="skip-and-close"> <span class="close-popup" (click)="closeRatingPopup(true)"><img alt="" src="/assets/images/cancel_ic.svg"></span> </div>
    </div>

    <h2 class="add-home-title">Rate Your Experience </h2>


    <div class="add-home-content" [formGroup]="ratingForm">
        <p class="message-text-style">
            If you enjoyed this home, please rate it! <br> Thank you for your support.
        </p>

        <!-- <p class="message-text-style"> If you enjoy using this home,<br/>
         would you mind talking a movement to rate it.<br/> it wont take more then a minute.<br/> thank you for your support </p> -->
        <app-star-reviews colorClass="orange" [clickable]="true" [control]="rating" [size]="'xl'" [inactive]="false">
        </app-star-reviews>
    </div>

    <div class="add-home-btn-main">
        <button type="button" class="add-home-btn-popup mb_12" (click)="navigateTorating()">
            Review it now
        </button>
        <button type="button" (click)="closeRatingPopup(true)" class="outline_btn add-home-btn-popup">
            Not now
        </button>
    </div>
</div>

<div class="bix__modal-backdrop" *ngIf="addHomePopup || addRatingPopup || openAddHomeForm"> </div>

<div class="bix__modal-backdrop" *ngIf="isDesktopView && showWelcomepopup"> </div>

<!-- <div class="modal welcome-modal" *ngIf="showWelcomepopup"> -->
    <!-- <app-welcome-popup *ngIf="isDesktopView"></app-welcome-popup> -->
    <!-- <app-welcome *ngIf="isDesktopView" (closeEmit)="closeWelcomePopUp($event)"></app-welcome> -->
<!-- </div> -->


<!-- add home form popup -->

<div class="add-home-form-modal" *ngIf="openAddHomeForm">
    <div class="close-section">
        <div class="skip-and-close">
            <span class="close-popup" (click)="addSpaceModal.exitClick()">
                <img alt="close icon" src="/assets/images/cancel_ic.svg"></span> </div>
        </div>

    <div class="add_home_sheet h-100">
        <app-add-home-modal #addSpaceModal (exitModal)="closeAddFormPopup()"></app-add-home-modal>
    </div>

</div>
