<div class="breadcrumbs_wrapper">
    <!-- breadcrumbs -->
    <breadcrumbs [breadcrumb]="items"></breadcrumbs>
  
</div>

<div class="explore-section-main">
    <div class="app_container">
        <!-- explore image header start -->
        <div class="explore_header_main">
            <div class="explore_header">
                <!-- header left brand start -->
                <div class="explore_left">
                    <div class="explore_brand" [routerLink]="['/']">
                        <span><img src="/assets/images/logo_NEW.svg"></span>
                    </div>
                </div>
            </div>
           
            <div class="location_header">{{"Trending regions" | translate}}</div>
            <div class="location_wrapper bix-row">
                <ng-container *ngFor="let space of featuredLocations; let i = index">
                    <div class="col-6 col-sm-4 col-md-3 col-lg-3 ">
                        <div class="trending_widget_item" [class.arabic]="selectedLanguage == 'ar'" (click)="searchResults(space?.name,space?.longitude,space?.latitude)">
                            <div class="trending_image">
                                <!-- <img src="../../../../../assets/images/6.jpg"> -->
                                <img class="lazyload" [lazyLoad]="space.image_url" defaultImage="/assets/images/default-cover.jpg" />
                                <!-- <img  [src]="space.image_url" (error)="onImgError($event)" /> -->
                            </div>
                            <div class="trending_cont">
                                <span class="trending_title">{{space?.name}}</span>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>

        </div>
    </div>
</div>

<app-loading [loading]="loading"></app-loading>