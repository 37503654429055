import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-home-google-maps',
  templateUrl: './add-home-google-maps.component.html',
  styleUrls: ['./add-home-google-maps.component.scss'],
})
export class AddHomeGoogleMapsComponent implements OnInit {
  options: google.maps.MapOptions = {
    zoom: 4,
    // center: {lat: 41.3359375, lng: 51.0722341},
    center: { lat: 51.07612361380574, lng: 10.208893086823068 },
    gestureHandling: 'greedy',
    minZoom: +2,
    maxZoom: +22,
    zoomControl: false,
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    draggable: true,
    restriction: {
      latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
    },
    // styles :[
    //   {
    //     elementType: "geometry",
    //     stylers: [{ color: "#f5f5f5" }],
    //   },
    //   {
    //     elementType: "labels.icon",
    //     stylers: [{ visibility: "off" }],
    //   },
    //   {
    //     elementType: "labels.text.fill",
    //     stylers: [{ color: "#616161" }],
    //   },
    //   {
    //     elementType: "labels.text.stroke",
    //     stylers: [{ color: "#f5f5f5" }],
    //   },
    //   {
    //     featureType: "administrative.land_parcel",
    //     elementType: "labels.text.fill",
    //     stylers: [{ color: "#bdbdbd" }],
    //   },
    //   {
    //     featureType: "poi",
    //     elementType: "geometry",
    //     stylers: [{ color: "#eeeeee" }],
    //   },
    //   {
    //     featureType: "poi",
    //     elementType: "labels.text.fill",
    //     stylers: [{ color: "#757575" }],
    //   },
    //   {
    //     featureType: "poi.park",
    //     elementType: "geometry",
    //     stylers: [{ color: "#e5e5e5" }],
    //   },
    //   {
    //     featureType: "poi.park",
    //     elementType: "labels.text.fill",
    //     stylers: [{ color: "#9e9e9e" }],
    //   },
    //   {
    //     featureType: "road",
    //     elementType: "geometry",
    //     stylers: [{ color: "#ffffff" }],
    //   },
    //   {
    //     featureType: "road.arterial",
    //     elementType: "labels.text.fill",
    //     stylers: [{ color: "#757575" }],
    //   },
    //   {
    //     featureType: "road.highway",
    //     elementType: "geometry",
    //     stylers: [{ color: "#dadada" }],
    //   },
    //   {
    //     featureType: "road.highway",
    //     elementType: "labels.text.fill",
    //     stylers: [{ color: "#616161" }],
    //   },
    //   {
    //     featureType: "road.local",
    //     elementType: "labels.text.fill",
    //     stylers: [{ color: "#9e9e9e" }],
    //   },
    //   {
    //     featureType: "transit.line",
    //     elementType: "geometry",
    //     stylers: [{ color: "#e5e5e5" }],
    //   },
    //   {
    //     featureType: "transit.station",
    //     elementType: "geometry",
    //     stylers: [{ color: "#eeeeee" }],
    //   },
    //   {
    //     featureType: "water",
    //     elementType: "geometry",
    //     stylers: [{ color: "#c9c9c9" }],
    //   },
    //   {
    //     featureType: "water",
    //     elementType: "labels.text.fill",
    //     stylers: [{ color: "#9e9e9e" }],
    //   },
    // ],
  };
  markerOptions: google.maps.MarkerOptions = { draggable: false };
  markerPositions: google.maps.LatLngLiteral[] = [];

  user: any = [];
  upcomingSwap: any = [];
  // markers : any = [];
  formattedaddress = ' ';
  address_options = {
    types: [],
    componentRestrictions: {
      country: ['AU'],
    },
  };
  latlng_;
  imageSrc;
  map;
  // currentLocation = new google.maps.LatLng(41.3359375, 51.0722341);
  currentLocation = new google.maps.LatLng(
    51.07612361380574,
    10.208893086823068
  );
  labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  labelIndex = 0;
  previousMarker;
  markerImg = 'assets/images/marker.png';
  constructor() {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.currentLocation = new google.maps.LatLng(
          position.coords.latitude,
          position.coords.longitude
        );
        this.map.panTo(this.currentLocation, 'currentLocation');
        this.addMarker(
          { lat: position.coords.latitude, lng: position.coords.longitude },
          this.map
        );
      },
      (error) => {
        // this.currentLocation = new google.maps.LatLng(41.3359375, 51.0722341);
        this.currentLocation = new google.maps.LatLng(
          51.07612361380574,
          10.208893086823068
        );
        this.map.panTo(this.currentLocation);
        this.addMarker(
          { lat: 51.0761236138057, lng: 10.208893086823068 },
          this.map
        );
      }
    );
  }

  ngOnInit(): void {
    this.map = new google.maps.Map(
      document.getElementById('add-home-gmap'),
      this.options
    );
    // this.getSpaces()
    setTimeout(() => {
      this.map.panTo(this.currentLocation);
    }, 5000);

    // google.maps.event.addListener(this.map, "click", (event) => {

    //   this.addMarker(event.latLng, this.map);
    // });

    // this.getLocationFromAddress(this.map);
  }

  getLocationFromAddress(map) {
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: 'Lautoka , Fiji' }, function (results, status) {
    });
  }

  addMarker(location: google.maps.LatLngLiteral, map: google.maps.Map) {
    // Add the marker at the clicked location, and add the next-available label
    // from the array of alphabetical characters.
    if (this.previousMarker && this.previousMarker.setMap) {
      this.previousMarker.setMap(null);
    }
    this.previousMarker = new google.maps.Marker({
      position: location,
      animation: google.maps.Animation.DROP,
      // label: this.labels[this.labelIndex++ % this.labels.length],
      map: map,
      icon: this.markerImg,
    });

  }

  async flyToLocation(address: { longitude: number; latitude: number }) {
    this.currentLocation = new google.maps.LatLng(
      address?.latitude,
      address?.longitude
    );
    await this.map.panTo(this.currentLocation);
    let latLngOfLocation = new google.maps.LatLngAltitude({
      lat: address?.latitude,
      lng: address.longitude,
    });
    await this.addMarker(latLngOfLocation, this.map);
  }

  async resetLocation() {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.currentLocation = new google.maps.LatLng(
          position.coords.latitude,
          position.coords.longitude
        );
        this.map.panTo(this.currentLocation, 'currentLocation');
        this.addMarker(
          { lat: position.coords.latitude, lng: position.coords.longitude },
          this.map
        );
      },
      (error) => {
        this.currentLocation = new google.maps.LatLng(
          51.07612361380574,
          10.208893086823068
        );
        this.map.panTo(this.currentLocation);
        this.addMarker(
          { lat: 51.0761236138057, lng: 10.208893086823068 },
          this.map
        );
      }
    );
  }
}
