<app-daily-reward-popup></app-daily-reward-popup>
<div
  class="app"
  [class.navigation__content-hide]="hideDesktopNavigationContents"
>
  <nav
    id="navigation-bar"
    *ngIf="router.url !== '/search-homes'"
    class="app__desktop-navigation"
  >
    <div
      class="app__desktop-navigation-container"
      [ngClass]="{
        'desktop-navigation-container-override': selectedLanguage == 'ar'
      }"
      [formGroup]="form"
    >
      <div class="first_section">
        <div class="app__desktop-logo" [routerLink]="['/']">
          <img src="/assets/new-logo/app-logo.svg" alt="" />
        </div>

        <app-search-homes
          *ngIf="router.url === '/map-view-list'"
          [isInHeader]="true"
        ></app-search-homes>
      </div>

      <div
        class="app_nav_items_container"
        *ngIf="!hideDesktopNavigationContents"
      >
        <ul class="nav_items" *ngIf="isDesktopView">
          <li>
            <div class="profile-button profileActive" (click)="showHeader()">
              <img
                *ngIf="!userService.loadingToken"
                src="/assets/images/icons/dashboard-token.svg"
              />
              <p *ngIf="!userService.loadingToken">
                {{ userService.token }}
              </p>
              <svg
                width="12"
                height="14"
                viewBox="0 0 12 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6 5.7C6.68199 5.7 7.33604 5.42607 7.81827 4.93848C8.30051 4.45088 8.57143 3.78956 8.57143 3.1C8.57143 2.41044 8.30051 1.74912 7.81827 1.26152C7.33604 0.773928 6.68199 0.5 6 0.5C5.31801 0.5 4.66396 0.773928 4.18173 1.26152C3.69949 1.74912 3.42857 2.41044 3.42857 3.1C3.42857 3.78956 3.69949 4.45088 4.18173 4.93848C4.66396 5.42607 5.31801 5.7 6 5.7ZM0 13.5C-1.17411e-08 12.7033 0.155195 11.9144 0.456723 11.1784C0.758251 10.4423 1.20021 9.77356 1.75736 9.21022C2.31451 8.64688 2.97595 8.20001 3.7039 7.89513C4.43185 7.59025 5.21207 7.43333 6 7.43333C6.78793 7.43333 7.56815 7.59025 8.2961 7.89513C9.02405 8.20001 9.68549 8.64688 10.2426 9.21022C10.7998 9.77356 11.2417 10.4423 11.5433 11.1784C11.8448 11.9144 12 12.7033 12 13.5H0Z"
                  fill="#23262F"
                />
              </svg>
            </div>
          </li>
        </ul>
        <ul class="nav_items" *ngIf="!isDesktopView">
          <li (click)="showHeader()" class="header-section">
            <div class="header-token">
              <img
                *ngIf="!userService.loadingToken"
                src="/assets/images/icons/dashboard-token.svg"
              />
              <p *ngIf="!userService.loadingToken">
                {{ userService.token }}
              </p>
              <svg
                width="12"
                height="14"
                viewBox="0 0 12 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6 5.7C6.68199 5.7 7.33604 5.42607 7.81827 4.93848C8.30051 4.45088 8.57143 3.78956 8.57143 3.1C8.57143 2.41044 8.30051 1.74912 7.81827 1.26152C7.33604 0.773928 6.68199 0.5 6 0.5C5.31801 0.5 4.66396 0.773928 4.18173 1.26152C3.69949 1.74912 3.42857 2.41044 3.42857 3.1C3.42857 3.78956 3.69949 4.45088 4.18173 4.93848C4.66396 5.42607 5.31801 5.7 6 5.7ZM0 13.5C-1.17411e-08 12.7033 0.155195 11.9144 0.456723 11.1784C0.758251 10.4423 1.20021 9.77356 1.75736 9.21022C2.31451 8.64688 2.97595 8.20001 3.7039 7.89513C4.43185 7.59025 5.21207 7.43333 6 7.43333C6.78793 7.43333 7.56815 7.59025 8.2961 7.89513C9.02405 8.20001 9.68549 8.64688 10.2426 9.21022C10.7998 9.77356 11.2417 10.4423 11.5433 11.1784C11.8448 11.9144 12 12.7033 12 13.5H0Z"
                  fill="#23262F"
                />
              </svg>
            </div>
          </li>
        </ul>
        <button
          *ngIf="router.url !== '/map-view-list'"
          (click)="saveMenuSelection('my-homes')"
          class="list-home-button"
        >
          List your home
        </button>
      </div>
    </div>
  </nav>

  <div class="app-navigation" *ngIf="router.url !== '/swipe'">
    <div
      appRipple
      (click)="saveMenuSelection('')"
      [routerLink]="['/']"
      [routerLinkActive]="['active']"
      [routerLinkActiveOptions]="{ exact: true }"
      class="app-navigation-item"
    >
      <div class="icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
        >
          <circle cx="14.5" cy="8.5" r="6.5" fill="white" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.1998 5.5999C9.71459 5.5999 8.29021 6.1899 7.24001 7.2401C6.1898 8.29031 5.5998 9.71469 5.5998 11.1999C5.5998 12.6851 6.1898 14.1095 7.24001 15.1597C8.29021 16.2099 9.71459 16.7999 11.1998 16.7999C12.685 16.7999 14.1094 16.2099 15.1596 15.1597C16.2098 14.1095 16.7998 12.6851 16.7998 11.1999C16.7998 9.71469 16.2098 8.29031 15.1596 7.2401C14.1094 6.1899 12.685 5.5999 11.1998 5.5999ZM2.7998 11.1999C2.79964 9.87789 3.1115 8.5745 3.71004 7.39574C4.30859 6.21698 5.1769 5.19614 6.24436 4.41624C7.31182 3.63634 8.54829 3.1194 9.85321 2.90747C11.1581 2.69554 12.4946 2.7946 13.7541 3.19659C15.0135 3.59859 16.1602 4.29216 17.101 5.22092C18.0419 6.14967 18.7502 7.28737 19.1684 8.54149C19.5866 9.79561 19.7029 11.1307 19.5079 12.4383C19.3128 13.7458 18.8119 14.9889 18.0458 16.0663L24.7896 22.8101C25.0446 23.0741 25.1857 23.4278 25.1825 23.7949C25.1794 24.1619 25.0321 24.5131 24.7726 24.7727C24.513 25.0322 24.1618 25.1795 23.7948 25.1826C23.4277 25.1858 23.074 25.0447 22.81 24.7897L16.0676 18.0473C14.8109 18.9409 13.3325 19.4713 11.7944 19.5805C10.2562 19.6896 8.71771 19.3733 7.34742 18.6661C5.97714 17.9589 4.82797 16.8881 4.02582 15.5712C3.22367 14.2542 2.79951 12.7419 2.7998 11.1999Z"
            fill="#ABB1C0"
          />
        </svg>
      </div>
      <div class="text">
        {{ "Explore" | translate }}
      </div>
    </div>
    <div
      appRipple
      (click)="saveMenuSelection('swaps')"
      [routerLink]="['/swaps', { outlets: { modal: null } }]"
      [routerLinkActive]="['active']"
      class="app-navigation-item"
    >
      <div class="icon icon_position-override">
        <span class="notify_badge" *ngIf="tripNotify"></span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.06993 5.66982C8.90788 3.83187 11.4007 2.79932 13.9999 2.79932C16.5992 2.79932 19.092 3.83187 20.9299 5.66982C22.7679 7.50777 23.8004 10.0006 23.8004 12.5998C23.8004 15.1991 22.7679 17.6919 20.9299 19.5298L13.9999 26.4598L7.06993 19.5298C6.15981 18.6198 5.43785 17.5394 4.94529 16.3503C4.45274 15.1613 4.19922 13.8869 4.19922 12.5998C4.19922 11.3128 4.45274 10.0383 4.94529 8.84929C5.43785 7.66023 6.15981 6.57984 7.06993 5.66982ZM13.9999 15.3998C14.7425 15.3998 15.4547 15.1048 15.9798 14.5797C16.5049 14.0546 16.7999 13.3424 16.7999 12.5998C16.7999 11.8572 16.5049 11.145 15.9798 10.6199C15.4547 10.0948 14.7425 9.79982 13.9999 9.79982C13.2573 9.79982 12.5451 10.0948 12.02 10.6199C11.4949 11.145 11.1999 11.8572 11.1999 12.5998C11.1999 13.3424 11.4949 14.0546 12.02 14.5797C12.5451 15.1048 13.2573 15.3998 13.9999 15.3998Z"
            fill="#ABB1C0"
          />
        </svg>
      </div>
      <div class="text">
        {{ "Trips" | translate }}
      </div>
    </div>
    <div
      appRipple
      (click)="saveMenuSelection('dashboard-profile')"
      [routerLink]="['/dashboard-profile', { outlets: { modal: null } }]"
      [queryParams]="{ previousPage: this.router.url }"
      [routerLinkActive]="['active']"
      class="app-navigation-item small_icon"
      [class.active-dash]="dashboardActive"
    >
      <div class="icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19.551"
          height="19.551"
          viewBox="0 0 19.551 19.551"
          fill="none"
        >
          <path
            id="Icon_material-dashboard"
            data-name="Icon material-dashboard"
            d="M4.5,15.362h8.689V4.5H4.5Zm0,8.689h8.689V17.534H4.5Zm10.862,0h8.689V13.189H15.362Zm0-19.551v6.517h8.689V4.5Z"
            transform="translate(-4.5 -4.5)"
            fill="#ABB1C0"
          />
        </svg>
      </div>
      <div class="text">
        {{ "Dashboard" | translate }}
      </div>
    </div>

    <div
      appRipple
      (click)="saveMenuSelection('inbox')"
      [routerLink]="['/inbox', { outlets: { modal: null } }]"
      [routerLinkActive]="['active']"
      class="app-navigation-item"
    >
      <div class="icon icon_position-override">
        <span
          class="red_dot"
          *ngIf="bdageStatus || notifyBadge || inappNotifyBadge"
        ></span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M25.1998 14.0002C25.1998 19.4126 20.185 23.8002 13.9998 23.8002C12.0119 23.8085 10.0512 23.3379 8.2836 22.4282L2.7998 23.8002L4.673 19.428C3.49 17.874 2.7998 16.0078 2.7998 14.0002C2.7998 8.5878 7.8146 4.2002 13.9998 4.2002C20.185 4.2002 25.1998 8.5878 25.1998 14.0002ZM9.7998 12.6002H6.9998V15.4002H9.7998V12.6002ZM20.9998 12.6002H18.1998V15.4002H20.9998V12.6002ZM12.5998 12.6002H15.3998V15.4002H12.5998V12.6002Z"
            fill="#ABB1C0"
          />
        </svg>
      </div>
      <div class="text">
        {{ "Inbox" | translate }}
      </div>
    </div>
    <div
      appRipple
      (click)="saveMenuSelection('my-homes')"
      [routerLink]="['/my-homes']"
      [routerLinkActive]="['active']"
      class="app-navigation-item"
    >
      <div class="icon">
        <svg
          width="29"
          height="28"
          viewBox="0 0 29 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.41406 25.6667H10.9974H17.9974H25.5807V11.6667L14.4974 3.5L3.41406 11.6667V25.6667Z"
            fill="#ABB1C0"
          />
          <path
            d="M11 25.6665V15.1665H18V25.6665"
            stroke="white"
            stroke-width="1.2"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="text">
        {{ "Homes" | translate }}
      </div>
    </div>
  </div>

  <div
    class="app-content"
    id="main-app"
    #mainWindowScroll
    [class.ratingModal]="toggleZindex"
    [class.noscroll]="windowScrollingService.isScrollDisabled$ | async"
  >
    <router-outlet></router-outlet>
    <footer
      class="footer"
      *ngIf="isDesktopView || router.url === '/view-home/checkout/success'"
    >
      <div class="footerMenu">
        <div class="footerContainer">
          <div class="footerMenuList">
            <div class="rowWrapper" style="margin-top: 24px">
              <div class="firstRow">
                <a href="/about-us">
                  <a class="footerMenuItem" target="_blank">About Us</a>
                </a>
                <a href="/how-it-works">
                  <a class="footerMenuItem" target="_blank">How It Works</a>
                </a>
              </div>
              <div class="secondRow">
                <a href="/legal/terms-of-service"
                  ><a class="footerMenuItem"> Terms of service </a></a
                >

                <a href="/legal/privacy-policy">
                  <a target="_blank" rel="noreferrer" class="footerMenuItem"
                    >Privacy Policy</a
                  >
                </a>
              </div>
              <div class="thirdRow">
                <a href="https://blog.holidayswap.com/">
                  <a target="_blank" rel="noreferrer" class="footerMenuItem"
                    >Blog</a
                  >
                </a>
                <a href="/faqs">
                  <a target="_blank" rel="noreferrer" class="footerMenuItem"
                    >FAQs</a
                  >
                </a>
              </div>
              <div class="forthRow">
                <a href="/legal/cookie-policy">
                  <a class="footerMenuItem" target="_blank">Cookie Policy</a>
                </a>
                <a href="/legal/prize-draw"
                  ><a class="footerMenuItem">
                    Prize Draw Terms and Conditions
                  </a></a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footerInfo">
        <div class="footerContainer" style="align-items: center">
          <p class="footerCopyRightText w-100 mb-5 mb-lg-0">
            Copyright 2024 Holiday Swap | All rights reserved | English (United
            Kingdom)
          </p>
          <ul class="d-flex w-100 justify-content-start justify-content-lg-end">
            <li>
              <a
                href="https://www.facebook.com/holidayswap"
                target="_blank"
                rel="noreferrer"
              >
                <img src="assets/images/Facebook.svg" />
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/holidayswapapp?s=21&t=_9WAkdb8OHtfDpyrUdEaXA"
                target="_blank"
                rel="noreferrer"
              >
                <img src="assets/images/Twitter.svg" />
              </a>
            </li>
            <li>
              <a
                href="https://instagram.com/holidayswap?igshid=YmMyMTA2M2Y="
                target="_blank"
                rel="noreferrer"
                ><img src="assets/images/Instagram.svg"
              /></a>
            </li>
            <li>
              <a
                href="https://youtube.com/c/HolidaySwap"
                target="_blank"
                rel="noreferrer"
                ><img src="assets/images/YouTube.svg"
              /></a>
            </li>
            <li>
              <a
                href="https://www.tiktok.com/@holidayswapapp?_t=8Wu3NO9cr6Q&_r=1"
                target="_blank"
                rel="noreferrer"
                ><img src="assets/images/TikTok.svg"
              /></a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  </div>
</div>

<div id="app-review-popup" class="app-review-modal" *ngIf="reviewPopup">
  <div class="close-section">
    <div class="skip-and-close">
      <span class="close-popup" (click)="closeReview_popup()"
        ><img src="../../../assets/images/cancel_ic.svg"
      /></span>
    </div>
  </div>
  <div class="congrates_img">
    <img src="../../../assets/images/congrats_img.svg" />
  </div>
  <div class="congrats-header-message">
    <h2>Thank you!</h2>
  </div>
  <div class="congrats-content">
    You have been granted 20 HS Points for leaving a review. Keep exploring and
    earn more HS Points. Have a nice day
  </div>
  <!-- <div class="congrats-header-message"> Keep using the app and you will get bonus HS Points, making you a top member of our app
	</div> -->

  <p class="now-one-step"></p>

  <div class="received-token-box">
    <a class="click-here-redirect" (click)="closeReview_popup()"> OK </a>
  </div>
</div>
<div *ngIf="reviewPopup" class="bix__modal-backdrop"></div>

<router-outlet name="modal"></router-outlet>

<app-loading [loading]="loading"></app-loading>
<div class="ModalContainer" id="header-modal">
  <div class="wrapper">
    <div class="headerWrapper">
      <div
        (click)="closeHeader()"
        class="header-profile"
        [class.searchProfile]="router.url === '/map-view-list'"
      >
        <img
          *ngIf="!userService.loadingToken"
          src="/assets/images/icons/dashboard-token.svg"
        />
        <p *ngIf="!userService.loadingToken">{{ userService.token }}</p>
        <svg
          width="12"
          height="14"
          viewBox="0 0 12 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6 5.7C6.68199 5.7 7.33604 5.42607 7.81827 4.93848C8.30051 4.45088 8.57143 3.78956 8.57143 3.1C8.57143 2.41044 8.30051 1.74912 7.81827 1.26152C7.33604 0.773928 6.68199 0.5 6 0.5C5.31801 0.5 4.66396 0.773928 4.18173 1.26152C3.69949 1.74912 3.42857 2.41044 3.42857 3.1C3.42857 3.78956 3.69949 4.45088 4.18173 4.93848C4.66396 5.42607 5.31801 5.7 6 5.7ZM0 13.5C-1.17411e-08 12.7033 0.155195 11.9144 0.456723 11.1784C0.758251 10.4423 1.20021 9.77356 1.75736 9.21022C2.31451 8.64688 2.97595 8.20001 3.7039 7.89513C4.43185 7.59025 5.21207 7.43333 6 7.43333C6.78793 7.43333 7.56815 7.59025 8.2961 7.89513C9.02405 8.20001 9.68549 8.64688 10.2426 9.21022C10.7998 9.77356 11.2417 10.4423 11.5433 11.1784C11.8448 11.9144 12 12.7033 12 13.5H0Z"
            fill="#23262F"
          />
        </svg>
      </div>
    </div>
    <div
      class="header-dropdown"
      [class.searchProfile2]="router.url === '/map-view-list'"
    >
      <div class="header-dropdown-content">
        <p (click)="saveMenuSelection('/')">Explore</p>
        <p (click)="saveMenuSelection('dashboard-profile')">Dashboard</p>
        <p (click)="saveMenuSelection('swaps')">Trips</p>
        <p (click)="saveMenuSelection('inbox')">Inbox</p>
        <p (click)="saveMenuSelection('my-homes')">Homes</p>
        <p (click)="saveMenuSelection('dashboard')">Profile</p>
        <p (click)="logoutUser()">Logout</p>
      </div>
      <app-daily-streak
        [currentStreak]="currentStreak"
        (goToPrize)="closeHeader()"
      ></app-daily-streak>
    </div>
  </div>
</div>
