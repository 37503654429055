import { Injectable, Output } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Cacheable } from "ts-cacheable";
import { IDashboardResponse } from "../interfaces/dashboard";
import { HttpService } from "./http.service";

@Injectable({
	providedIn: "root"
})
export class PromoService {
   public tokenUpdates = new BehaviorSubject<number>(0);

	constructor(private httpService: HttpService) {
        // this.tokenUpdates.subscribe((res) => {

        // })
     }

	checkTokenAvailability(data: any) {
        return this.httpService.post(`/check-token-available`, data);
    }

    claimPromo(data: any) {
        return this.httpService.post('/token-gift', data);
    }

    setTokenUpdates(data) {

        this.tokenUpdates.next(data)
    }


}
