import {
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewContainerRef,
} from "@angular/core";
import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { TemplatePortal } from "@angular/cdk/portal";
import { merge, Observable, Subscription } from "rxjs";
import {disableBodyScroll, clearAllBodyScrollLocks} from "body-scroll-lock";

import { DropdownPanel } from "../interfaces/dropdown-panel";


@Directive({
  selector: "[dropdownTriggerFor]",
  host: {
	"(click)": "toggleDropdown()",
  },
})
export class DropdownTriggerForDirective implements OnDestroy {
  private isDropdownOpen = false;
  private overlayRef: OverlayRef;
  private dropdownClosingActionsSub = Subscription.EMPTY;

  @Input("dropdownTriggerFor") public dropdownPanel: DropdownPanel;

  @Output("open") public open = new EventEmitter();

  @Output("close") public close = new EventEmitter();

  constructor(
	private overlay: Overlay,
	private viewContainerRef: ViewContainerRef,
  ) {

  }

  toggleDropdown(): void {
	this.isDropdownOpen ? this.destroyDropdown() : this.openDropdown();
  }

  openDropdown(): void {
	this.open.emit();

	this.isDropdownOpen = true;
	this.overlayRef = this.overlay.create({
		hasBackdrop: true,
		backdropClass: "cdk-overlay-backdrop",
		scrollStrategy: this.overlay.scrollStrategies.block(),
		positionStrategy: this.overlay.position().global()
		.centerHorizontally().bottom(),
		/*	positionStrategy: this.overlay
          .position()
          .flexibleConnectedTo(this.elementRef)
          .withPositions([
            {
              originX: "end",
              originY: "bottom",
              overlayX: "center",
              overlayY: "bottom",
              offsetY: 8
            }
          ])*/
	});


	const templatePortal = new TemplatePortal(
		this.dropdownPanel.templateRef,
		this.viewContainerRef,
	);

	this.overlayRef.attach(templatePortal);

  setTimeout(() => {
	const target = this.dropdownPanel.scrollTarget?.nativeElement;
	if (target){
		disableBodyScroll(target);
	}
  }, 100);

	this.dropdownClosingActionsSub = this.dropdownClosingActions().subscribe(
		() => this.destroyDropdown(),
	);
  }


  private dropdownClosingActions(): Observable<MouseEvent | void> {
	const backdropClick$ = this.overlayRef.backdropClick();
	const detachment$ = this.overlayRef.detachments();
	const dropdownClosed = this.dropdownPanel.closed;

	return merge(backdropClick$, detachment$, dropdownClosed);
  }

  private destroyDropdown(): void {
	if (!this.overlayRef || !this.isDropdownOpen) {
		return;
	}

	clearAllBodyScrollLocks();

	this.dropdownClosingActionsSub.unsubscribe();
	this.isDropdownOpen = false;
	this.overlayRef.detach();
	this.open.emit();

  }

  ngOnDestroy(): void {
	if (this.overlayRef) {
		this.overlayRef.dispose();
	}
  }
}
