<div class="background_wrapper">
  <div class="logo_header">
    <div class="intro_brand">
      <img src="/assets/new-logo/app-logo-dark.svg" alt="" />
      <!-- <img src="/assets/images/onboard/logo.svg"> -->
      <!-- <span class="brand_title">Holiday Swap</span> -->
    </div>

    <div>
      <app-language-selector></app-language-selector>
    </div>
  </div>
  <div class="app_container">
    <div class="content_area">
      <div class="page-title">
        <h1>
          {{ "Sign in and start" | translate }}<br />
          {{ "exploring" | translate }}
        </h1>
      </div>

      <!-- <app-panel *ngIf="error?.length > 0" class="m-t-20 m-b-20" background="#bd4239" textColor="white">
            {{error}}
             </app-panel> -->

      <div class="plans_access">
        <div class="form-field">
          <input
            type="email"
            [(ngModel)]="user.username"
            (ngModelChange)="handleEmail()"
            placeholder="{{ 'Enter your email' | translate }}"
            required
          />
          <p class="red-alert" *ngIf="submitted && !emailBlank">
            {{ "Please enter email" | translate }}
          </p>
          <p class="red-alert" *ngIf="error?.length > 0">
            {{ error }}
          </p>
        </div>

        <div class="form-field password-form">
          <input
            type="{{ hidePass ? 'password' : 'text' }}"
            [(ngModel)]="user.password"
            (ngModelChange)="handlePassword()"
            placeholder="{{ 'Enter your password' | translate }}"
            required
          />
          <span class="show-password" (click)="toggleHidePassword()">
            <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="hidePass"></i>
            <i class="fa fa-eye" aria-hidden="true" *ngIf="!hidePass"></i>
          </span>
          <p class="red-alert" *ngIf="submitted && !emailpassword">
            {{ "Please enter password" | translate }}
          </p>
        </div>

        <button
          type="button"
          class="btn_large btn_transparant"
          (click)="onLogin()"
        >
          {{ "Continue" | translate }}
        </button>
      </div>

      <div class="forgot-password">
        <a class="forgot-link" routerLink="/auth/reset">
          {{ "Forgot my password" | translate }}
        </a>
      </div>

      <div class="form-divider">
        <p>{{ "or continue with" | translate }}</p>
      </div>

      <!-- <div class="plans-area">
               <div class="payment_btn gg_btn">
                   <button type="button"><i class="fa fa-google" aria-hidden="true"></i>&nbsp;Continue with Google</button>
               </div>
               <div class="payment_btn fb_btn">
                  <button type="button"><i class="fa fa-facebook-square" aria-hidden="true"></i>&nbsp;Continue with Facebook</button>
              </div>
              <div class="payment_btn ap_btn">
                  <button type="button"><i class="fa fa-apple" aria-hidden="true"></i>&nbsp;Continue with Apple</button>
              </div>
              <div class="login_link">
              </div>
           </div> -->

      <app-social-sign-in> </app-social-sign-in>

      <!-- <div class="login_link">
            </div> -->

      <div class="login_link">
        <p>{{ "Don't have an account?" | translate }}</p>
        <button class="btn_large btn_transparant" (click)="handleSignUp()">
          &nbsp;{{ "Sign up" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<app-loading [absolute]="true" [loading]="loading"></app-loading>
