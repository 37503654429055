import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//import { LoginComponent } from "./pages/login/login.component";
import { ResetComponent } from './pages/reset/reset.component';
//import { SignupComponent } from "./pages/signup/signup.component";
import { AuthRoutingModule } from './auth-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { SocialLoginComponent } from './components/social-login/social-login.component';
import { IntroComponent } from './pages/intro/intro.component';
import { OnboardingTourIntroComponent } from './pages/onboarding-tour-intro/onboarding-tour-intro.component';
import { SpacesModule } from 'src/app/modals/spaces/spaces.module';
import { RegisterComponent } from './pages/register/register.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { SocialSignInComponent } from './components/social-sign-in/social-sign-in.component';
import { TermsPrivacyServiceComponent } from './terms-privacy-service/terms-privacy-service.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { SwiperModule } from 'swiper/angular';
//import { ExploreHomeSpacesComponent } from './pages/explore-home-spaces/explore-home-spaces.component';
import Swiper, { Autoplay } from 'swiper';
import { OnboardingIntroComponent } from './pages/onboarding-intro/onboarding-intro.component';
import { OnboardingNewIntroComponent } from './pages/onboarding-new-intro/onboarding-new-intro.component';
import { VerifyAccountComponent } from './pages/verify-account/verify-account.component';
import { AdventureSelectionComponent } from './pages/adventure-selection/adventure-selection.component';
import { TrendingRegionComponent } from '../explore-spaces-section/pages/trending-region/trending-region.component';
import { TrendingRegionResolver } from './trending-region-resolver.resolver';
import { CongratsListPremiumComponent } from './pages/congrats-list-premium/congrats-list-premium.component';
import { UpgradeSubscriptionComponent } from './pages/upgrade-subscription/upgrade-subscription.component';
import { AvailableSubscriptionResolver } from './available-subscription-resolver.resolver';
import { SocialloginSignupComponent } from './pages/sociallogin-signup/sociallogin-signup.component';
Swiper.use([Autoplay]);
@NgModule({
  declarations: [
    //	LoginComponent,
    ResetComponent,
    //	SignupComponent,
    SocialLoginComponent,
    IntroComponent,
    OnboardingTourIntroComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    SocialSignInComponent,
    TermsPrivacyServiceComponent,
    SignInComponent,
    OnboardingIntroComponent,
    OnboardingNewIntroComponent,
    VerifyAccountComponent,
    AdventureSelectionComponent,
    CongratsListPremiumComponent,
    UpgradeSubscriptionComponent,
    SocialloginSignupComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    AuthRoutingModule,
    SpacesModule,
    SwiperModule,
  ],
  providers: [TrendingRegionResolver, AvailableSubscriptionResolver],

  exports: [SocialLoginComponent, SocialSignInComponent],
})
export class AuthModule {}
