
<div class="breadcrumbs_container">
  <nav>
    <ul class="custom_breadcrumbs" [ngClass]="{'custom_breadcrumbs-direOverride': selectedLanguage == 'ar'}">
       <ng-container *ngFor="let item of breadcrumb; let last=last">
        <li class="breadcrumbs_item" >
          <a [routerLink]="item.link" *ngIf="!last">
            {{(item.name) |translate}}
          </a>
          <span *ngIf="last">{{(item.label) | translate}}</span>
        </li>   
       </ng-container>
      </ul>
  </nav>

</div>
