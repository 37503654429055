import {
  Component,
  HostListener,
  Input,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-custom-address-map',
  templateUrl: './custom-address-map.component.html',
  styleUrls: ['./custom-address-map.component.scss'],
})
export class CustomAddressMapComponent implements OnInit {
  @Output() public passUpdatedLocation = new EventEmitter();
  @Input() address: string = '';
  @Input() markerUpdated: boolean = false;
  @Input() longitude = '';
  @Input() latitude = '';

  formattedAddress = '';

  options: google.maps.MapOptions = {
    zoom: 18,
    center: { lat: 51.07612361380574, lng: 10.208893086823068 },
    gestureHandling: 'greedy',
    minZoom: 2,
    maxZoom: 18,
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    draggable: false,
    clickableIcons: false,
    restriction: {
      latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
    },
  };
  markerOptions: google.maps.MarkerOptions = {
    draggable: false,
    icon: 'assets/images/marker.png',
    animation: google.maps.Animation.DROP,
  };
  markerPositions: google.maps.LatLngLiteral[] = [];
  map;
  marker;
  geocoder;

  radius = 50;

  isDesktopView: boolean = window.screen.width > 1024;
  isTabletView: boolean = window.screen.width > 769;
  @HostListener('window:resize', ['$event'])
  handleResize(event: KeyboardEvent) {
    this.isDesktopView = window.screen.width > 1024;
    this.isTabletView = window.screen.width > 769;
  }

  constructor() {}

  async ngOnInit(): Promise<void> {
    const mapToolTip = document.querySelector('.map-tool--tip');
    // mapToolTip.classList.remove('show');

    setTimeout(() => {
      mapToolTip.classList.add('show');
    }, 20000);

    this.map = new google.maps.Map(
      document.getElementById('gmapUser'),
      this.options
    );
    this.addMakerWithCityGeocode();
  }

  addMakerWithCityGeocode() {
    this.geocoder = new google.maps.Geocoder();
    var _self = this;
    if (this.address != '')
      this.geocoder.geocode(
        { address: this.address },
        function (results, status) {
          if (status == 'OK') {
            var position = results[0].geometry.location;
            var originalMarkerPosition = results[0].geometry.location;
            if (_self.latitude && _self.longitude) {
              position = { lat: _self.latitude, lng: _self.longitude };
              originalMarkerPosition = {
                lat: _self.latitude,
                lng: _self.longitude,
              };
            } else {
              position = results[0].geometry.location;
              originalMarkerPosition = results[0].geometry.location;
            }
            if (position) {
              var marker = new google.maps.Marker({
                map: _self.map,
                position: position,
                draggable: true,
                icon: 'assets/images/marker.png',
                animation: google.maps.Animation.DROP,
              });
            }

            _self.map.setCenter(position);
            _self.map.panTo(position);

            var geocoder = new google.maps.Geocoder();

            google.maps.event.addListener(marker, 'dragend', function () {
              geocoder.geocode(
                { location: marker.getPosition() },
                function (results, status) {
                  var newMarkerPosition = results[0].geometry.location;

                  var distance =
                    google.maps.geometry.spherical.computeDistanceBetween(
                      originalMarkerPosition,
                      newMarkerPosition
                    );

                  var markerIsOrNotInBounds = _self.map
                    .getBounds()
                    .contains(marker.getPosition());

                  if (!markerIsOrNotInBounds) {
                    marker.setPosition(originalMarkerPosition);
                  }
                  if (status == google.maps.GeocoderStatus.OK) {
                    if (results.length > 0) {
                      const elementWithMostComponents = results.reduce(
                        (prev, current) => {
                          return current.address_components.length >
                            prev.address_components.length
                            ? current
                            : prev;
                        },
                        results[0]
                      );
                      console.log(results);
                      _self.passUpdatedLocation.emit({
                        lat: elementWithMostComponents.geometry.location.lat(),
                        lng: elementWithMostComponents.geometry.location.lng(),
                        formatted_address:
                          elementWithMostComponents.formatted_address,
                        components:
                          elementWithMostComponents.address_components,
                      });
                    } else {
                    }
                  } else {
                  }
                }
              );
            });
          } else {
            alert(
              'Geocode was not successful for the following reason: ' + status
            );
          }
        }
      );
  }

  updateAddress(address) {
    this.address = address;
    this.addMakerWithCityGeocode();
  }
}
