import { Injectable, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { Cacheable } from 'ts-cacheable';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  @Output() public scrollPosition = new Subject<string>();

  constructor(private httpService: HttpService) {}

  public getInbox(queryParams) {
    return this.httpService.get(
      `/users/me/chats?page=${queryParams.page}&search=${queryParams.search}`
    );
  }

  public getInboxOnOffset(offset) {
    return this.httpService.get(`/users/me/chats?offset=${offset}`);
  }

  public getMessages(id: number, pagination?: number) {
    return this.httpService.get(`/users/me/chats/${id}`);
  }

  public sendMessage(id: number, body: { text: string; pagination?: number }) {
    return this.httpService.post(`/users/me/chats/${id}`, body);
  }

  public getUnreadChatStatus(id: string | number) {
    return this.httpService.get(`/users/me/unread-chat-status/${id}`);
  }

  public getUnreadStatus() {
    let myId = localStorage.getItem('id');
    return this.httpService.get(`/message-status/${myId}`);
  }

  public updateScrollPosition() {
    this.scrollPosition.next();
  }
}
