<div class="app_container">
  <div id="accordion" class="terms_condition">
    <div class="card">
      <div class="card-header">
        <a class="collapsed btn" data-bs-toggle="collapse" href="#collapseOne">
          <span class="heading-option-style">Privacy Policy</span
          ><i class="fa fa-angle-right toggle-icon-style"></i>
        </a>
      </div>
      <div id="collapseOne" class="collapse" data-bs-parent="#accordion">
        <div class="card-body">
          <h1>Terms & Conditions</h1>

          <div class="section">
            <p>Last Updated: 13 April 2023</p>
            <!-- <p><b>
						Please read these Terms &amp; Conditions carefully as they contain important information
						about your legal rights, remedies and obligations.
						By accessing or using the Holiday Swap App, you agree to comply with and be bound by these Terms
						&amp; Conditions.
					</b></p> -->
            <!-- <p><b>Please read these Terms &amp; Conditions carefully as they contain
						important information about your legal rights, remedies and obligations. By
						accessing or using the Holiday Swap App, you agree to comply with and be bound
						by these Terms &amp; Conditions.</b></p> -->

            <!-- <p>Please read the Terms &amp; Conditions for using the App and Website (collectively, the
					“<b>Services</b>”) for
					Holiday Swap, operated by Holiday Swap Group Limited, a company incorporated in England and Wales
					with
					offices at 43 Berkeley Square, London, United
					Kingdom, W1J 5FJ, registration number 10847362 (hereinafter “<b>Holiday Swap</b>”, the
					“<b>Company</b>”, “<b>we</b>”, “<b>our</b>”, “<b>us</b>”).
					By accessing and/or using Holiday Swap’s Services, you agree to comply with and be bound by these
					Terms and Conditions (“<b>Terms</b>”).</p> -->
            <p>
              Please read the Terms &amp; Conditions (“Terms”) for using the App
              and Website (collectively, the “Services”) for Holiday Swap,
              operated by Holiday Swap Group Limited, a company incorporated in
              England and Wales with offices at
              <a
                href="https://www.google.com/maps/place/43+Berkeley+Square,+London+W1J+5FJ,+UK/@51.5093931,-0.1466591,17z/data=!3m1!4b1!4m5!3m4!1s0x48760529545c8c5b:0x6672a0ddf14c3366!8m2!3d51.5093931!4d-0.1466591"
                target="_blank"
                >43 Berkeley Square, London, United Kingdom, W1J 5FJ</a
              >, registration number 10847362 (hereinafter “Holiday Swap”, the
              “Company”, “we”, “our”, “us”). By accessing and/or using Holiday
              Swap’s Services, you agree to comply with and be bound by these
              Terms.
            </p>
          </div>

          <div class="section">
            <p><b>1. Definitions.</b></p>
            <p>Capitalized terms have the following meanings in these Terms:</p>

            <ul>
              <li>
                <p>
                  <b>App</b>: The Holiday Swap application distributed by us
                  including any related services provided by us, including the
                  web-based App.
                </p>
              </li>
              <li>
                <p>
                  <b>App Store</b> or <b>Google Play</b>: The application store
                  from which you download the App.
                </p>
              </li>
              <li>
                <p>
                  <b>App Store Rules</b>: Any applicable rules, policies, or
                  terms of the relevant App Store.
                </p>
              </li>
              <li>
                <p>
                  <b>Booking, Instant Booking</b> or <b>Booking Request</b>: Any
                  reservation made by users to stay at a Host’s accommodation.
                </p>
              </li>
              <li>
                <p>
                  <b>Booking Fee</b>: Any fee which is applied to a cash-booking
                  on a nightly basis.
                </p>
              </li>
              <li>
                <p>
                  <b>Chargeback</b>: Any payment that is for any reason
                  cancelled, refunded, reversed, charged back, withdrawn,
                  disputed or otherwise called into question together with any
                  fees, penalties or other charges that are or may be payable in
                  connection therewith.
                </p>
              </li>
              <!-- <li>
						<p><b>"Consumer"</b>: An individual acting for purposes which are wholly or mainly outside that
							person’s trade,
							business, craft or profession.</p>
					</li> -->
              <li>
                <p>
                  <b>Content</b>: All information of whatever kind published,
                  stored or sent on or in connection with our App, including all
                  information provided by Users on their Profiles.
                </p>
              </li>
              <li>
                <p>
                  <b>Dispute Period</b>: Within seven (7) days of the swap end
                  date as recorded with us unless otherwise stated on our App at
                  the time of the Swap Contract.
                </p>
              </li>
              <li>
                <p>
                  <b>Guest</b>: User in their capacity of staying at other
                  properties (as well as other members of a Guest’s party).
                </p>
              </li>
              <li>
                <p>
                  <b>Host</b> or <b>Owner</b>: User in the capacity of making
                  their properties available for other Users to stay in.
                </p>
              </li>
              <li>
                <p><b>Hosting</b>: When a User Hosts a Guest.</p>
              </li>
              <li>
                <p>
                  <b>Profile</b>: User profiles on the App, both personal
                  profiles and property listings.
                </p>
              </li>
              <li>
                <p>
                  <b>Property</b> or <b>Accommodation</b>: The property listed
                  on Users accounts that are offered for swapping and/or Hosting
                  on the App.
                </p>
              </li>
              <li>
                <p>
                  <b>Services: </b>Any services the Company provides on the App
                  or Website.
                </p>
              </li>
              <li>
                <p>
                  <b>Subscription Fees: </b>Any fee required to be paid when
                  using the App to complete a Swap or Hosting.
                </p>
              </li>
              <li>
                <p>
                  <b>Subscription:</b> The Company’s subscription plans
                  providing benefits to paying and subscribing Users.
                </p>
              </li>
              <li>
                <p>
                  <b>Swap:</b> A home exchange and/or Hosting of a Guest on the
                  agreed upon dates of a Swap Contract.
                </p>
              </li>
              <li>
                <p>
                  <b>Swap Contract</b>: Contract between Users to swap or be
                  Hosted in their respective properties.
                </p>
              </li>
              <li>
                <p>
                  <b>User</b>: Any person who uses our App and including all
                  Hosts and Guests in a Swap Contract.
                </p>
              </li>
              <li>
                <p>
                  <b>Website</b>: The Holiday Swap website, currently,
                  <a href="http://www.holidayswap.com/">www.holidayswap.com.</a>
                </p>
              </li>
              <!-- <li>
						<p>Applicability of the terms and conditions.
						</p>
					</li> -->
            </ul>

            <!-- <p>Thank you for using Holiday Swap! Please read the following Terms carefully and save these terms for
					future
					use. These Terms constitute a legally binding end user license agreement between you and Holiday
					Swap (the
					"<b>Agreement</b>"). This Agreement governs your access to and use of Holiday Swap’s Services,
					including but not limited to, any subdomains thereof, any other websites through which Holiday Swap
					makes its services available, our mobile, tablet and other smart device applications,
					and all associated services. In addition, when downloading our App in the Appstore or Google Play
					Store,
					you agree to be bound by the relevant Appstore or Google Play Store Rules.</p>
				<p>By subscribing to these Terms you agree to the use of email communications. </p>

				<p>Your contracting entity will be determined by your country of residence,
					which is the jurisdiction associated with your Holiday Swap account as determined by either your
					express selection or by Holiday Swap’s assessment of your residence using various data attributes
					associated with your Holiday Swap account.
					Our collection of such data can be found in our
					<a href="https://www.holidayswap.com/privacy-policy/">Privacy Policy</a>.
				</p>

				<p>These Terms apply to all Users and their travel companions.
					As a Guest, you promise that all other people staying with you at the Host’s property will comply
					with these Terms,
					including the Swap Contract, and you will be responsible to us and the Host if they do not.</p>

				<p>The headings in this agreement are inserted for convenience only and shall not affect its
					construction.</p>

				<p>A reference to a particular law is a reference to it as it is in force for the time being taking
					account of any amendment,
					extension, or re-enactment and includes any subordinate legislation for the time being in force made
					under it.</p>

				<p>Unless the context otherwise requires, a reference to one gender shall include a reference to the
					other genders.</p>

				<p>Unless the context otherwise requires, words in the singular include the plural and in the plural
					include the singular.</p> -->
          </div>

          <div class="section">
            <p><b>2. Applicability of the Terms.</b></p>

            <p>
              Please read the following Terms carefully and save these terms for
              future use. These Terms constitute a legally binding end user
              license agreement between you and Holiday Swap (the “Agreement”).
              This Agreement governs your access to and use of Holiday Swap’s
              Services, including but not limited to, any subdomains thereof,
              any other websites through which Holiday Swap makes its services
              available, our mobile, tablet and other smart device applications,
              and all associated services. In addition, when downloading our App
              in the App Store or Google Play, you agree to be bound by the
              relevant App Store or Google Play rules. By subscribing to these
              Terms, you agree to the use of email communications. Your
              contracting entity will be determined by your country of
              residence, which is the jurisdiction associated with your Holiday
              Swap account as determined by either your express selection or by
              Holiday Swap’s assessment of your residence using various data
              attributes associated with your Holiday Swap account. Our
              collection of such data can be found in our
              <a href="https://www.holidayswap.com/privacy-policy/"
                >Privacy Policy</a
              >. These Terms apply to all Users and their travel companions. As
              a Guest, you promise that all other people staying with you at the
              Host’s property will comply with these Terms, including the Swap
              Contract, and you will be responsible to us and the Host if they
              do not.
            </p>
          </div>

          <div class="section">
            <p><b>3. Changes to the Terms.</b></p>

            <p>
              Holiday Swap reserves the right to modify these Terms at any time
              in accordance with this provision. If we make changes to these
              Terms, we will post the revised Terms on Holiday Swap’s Services
              and update the “Last Updated” date at the top of these Terms.
              Updated Terms will be added to the Website. If you disagree with
              the revised Terms, you may terminate this Agreement with immediate
              effect. We will inform you about your right to terminate the
              Agreement in the notification email or letter. If you do not
              terminate your Agreement before the date the revised Terms become
              effective, your continued access to or use of the Services will
              constitute acceptance of the revised Terms. Any such change will
              not affect Swap Contracts concluded before such effective date.
            </p>
          </div>

          <div class="section">
            <p><b>4. General Use of Our App.</b></p>

            <p>
              We grant Users a limited personal right to use our App on any
              applicable device (mobiles, tablets, and other smart devices)
              owned or controlled by you in accordance with the App Store rules
              subject to these Terms. Our App may only be used in connection
              with genuine swaps of stays in Users’ properties and must not be
              used in connection with a business. You are not eligible for, and
              must not use or register on, our App if any of the following
              apply:
            </p>

            <!-- <p>Our App may only be used in connection with genuine swaps of stays in Users’ properties and must not
					be used in connection with a business.</p> -->

            <!-- <p>You are not eligible for, and must not use or register on, our App if any of the following apply:</p> -->

            <ul>
              <li>You are below eighteen (18) years of age; or</li>
              <li>
                You have been convicted of any offense, or subject to any court
                order, relating to assault, violence, sexual misconduct,
                harassment or involving dishonesty.
              </li>
            </ul>

            <p>
              You must comply with any instructions or guidelines within the
              App. You must ensure that any contact information or other
              information which you supply to us is accurate and not misleading
              and you will update it so that it remains so. We do not supply
              support except to the extent specifically stated on our App or in
              these Terms. You acknowledge that the App Store has no obligation
              to supply any maintenance and support services in relation to the
              App. The App is compatible with applicable devices and associated
              operating systems (OS’s) which have been released as at the date
              we launched the App (or as at the most recent app update). We do
              not guarantee that the App is, or will be, compatible with any
              other devices or OS’s. We may issue App updates through the App
              Store; if so, you may not be able to use our App properly or at
              all until you have downloaded the update, which may be subject to
              the agreement of new Terms. It is your responsibility to
              frequently monitor for App updates and to install them as soon as
              they become available.
            </p>

            <!-- <p>We do not supply support except to the extent specifically stated on our App or in these terms and
					conditions.</p> -->

            <!-- <p>You acknowledge that the Appstore has no obligation to supply any maintenance and support services in
					relation to the App.
				</p> -->

            <!-- <p>The App is compatible with applicable devices and associated operating systems (OS’s) which have been
					released as at the date we launched the App
					(or as at the most recent app update). We do not guarantee that the App is, or will be,
					compatible with any other devices or OS’s. We may issue App updates through the Appstore; if so, you
					may not be able to use our App properly or at all until you have downloaded the update,
					which may be subject to the agreement of new terms and conditions. It is your responsibility to
					frequently monitor for App updates and to install them as soon as they become available.</p> -->
          </div>

          <div class="section">
            <p><b>5. Additional User Obligations.</b></p>

            <p>
              You must not communicate with Users outside the App until a Swap
              Contract has been formed (see below). You must regularly check for
              messages from other Users and respond promptly to any such
              messages. You represent and warrant to us that under no
              circumstances will you allow more persons to stay in the Property
              than the number of beds shown in your listing. You acknowledge
              that the criteria for awarding or removing “Top Swapper” status
              are set out in the App. We can change these criteria at any time.
              If you have any complaint about another User, you must notify us
              promptly by email to
              <a href="mailto:support@holidayswap.com"
                >support@holidayswap.com</a
              >. We may in our discretion try to resolve the dispute, but we
              have no legal obligation to do so.
            </p>

            <!-- <p>You represent and warrant to us that under no circumstances will you allow more persons to stay in
					the
					Property than the number of beds shown in your listing.</p>

				<p>You acknowledge that the criteria for awarding or removing
					“Top Swapper” status are set out in the App. We can change these criteria at any time.</p>

				<p>If you have any complaint about another User, you must notify us promptly by email to <a
						href="mailto:support@holidayswap.com">support@holidayswap.com</a>. We may in our discretion try
					to resolve the dispute, but we are not obligated to do so.</p> -->
          </div>

          <div class="section">
            <p><b>6. Acceptable Use Policy.</b></p>

            <p>
              You are solely responsible for compliance with all laws, rules,
              regulations, and tax obligations that may apply to your use of the
              App. You agree that you will not, in connection with the App, do
              the following, as these behaviours are strictly forbidden and may
              result in your Profile closure:
            </p>

            <ul>
              <li>
                Breach any applicable law, regulation, or code of conduct,
                and/or assist or enable another to breach or circumvent any
                applicable laws or regulations, agreements with third-parties,
                third-party rights or our Terms and other policies on our
                Website and App.
              </li>
              <li>
                Publish or send any Content (including links or references), or
                otherwise behave in a manner, which:
                <ul>
                  <li>
                    is defamatory, threatening, harassing, invasive of privacy,
                    offensive, vulgar, racist, hateful, discriminatory, obscene,
                    pornographic, sexually suggestive, promoting of self-harm,
                    misleading, abusive or deceptive;
                  </li>
                  <li>
                    infringes any intellectual property or other rights of
                    others (this includes posting images and tips on the in-app
                    map, My Maps);
                  </li>
                  <li>involves phishing or scamming or similar conduct; or</li>
                  <li>
                    content which we otherwise reasonably consider to be
                    inappropriate.
                  </li>
                </ul>
              </li>

              <li>
                Publish or send any Content which involves revealing any
                personal data or information of another person (i.e. information
                enabling someone to be identified or contacted) unless that
                person is eighteen (18) years or over and you have obtained that
                person’s expressed written consent.
              </li>

              <li>
                Impersonate any person or entity for the purpose of misleading
                others.
              </li>

              <li>Sell access to the App.</li>

              <li>
                Use the App to provide a similar service to third parties or
                otherwise with a view to competing with us.
              </li>

              <li>
                Sell advertising, sponsorship, or promotions on or in connection
                with Content except where explicitly authorised by us.
              </li>

              <li>
                Use the App for junk mail, spam, pyramid or similar or
                fraudulent schemes, or unsolicited commercial messages.
              </li>

              <li>
                Do anything which may have the effect of disrupting the App.
              </li>

              <li>
                Do anything which may negatively affect other Users’ enjoyment
                of the App.
              </li>

              <li>
                Use our App for other purposes not expressly permitted by these
                Terms or in a manner that falsely implies Company endorsement,
                partnership otherwise misleads others as to your affiliation
                with the Company.
              </li>

              <li>
                Copy, store or otherwise access or use any information,
                including personally identifiable information about any other
                User, contained on our Website or App in any way that is
                inconsistent with the Companies Privacy Policy or these Terms or
                that otherwise violates the privacy rights of Users or
                third-parties.
              </li>

              <li>
                Offer, as a Host, any Accommodation that you do not yourself own
                or have permission to make available as a resident or other
                property through our App.
              </li>

              <li>
                Contact another User for any purpose other than asking a
                question related to your own Swap or booking, properties, or the
                User’s use of the App, including, but not limited to recruiting
                or otherwise soliciting Users to join third-party services,
                applications, or websites.
              </li>

              <li>
                Use the App to request, make or accept a booking independent of
                the App, to circumvent any Subscription Fees or for any other
                reason.
              </li>

              <li>
                Request, accept or make any payment for a Swap outside of the
                Company’s App or payment provider and services. If you do so,
                you acknowledge and agree that you: (1) would be in breach of
                these Terms; (2) accept all risks and responsibility for such
                payments; and (3) hold the Company harmless from any liability
                for such payment.
              </li>

              <li>
                Discriminate against or harass anyone on the basis of race,
                national origin, religion, gender, gender identity, physical or
                mental disability, medical condition, marital status, age,
                sexual orientation, or otherwise engage in any violent, harmful,
                abusive or disruptive behavior.
              </li>

              <li>
                Use, display, mirror or frame the Company’s content, name,
                trademark, logo or other proprietary information, or the layout
                and design of any page or form contained on a page in the App,
                without the Company’s express written consent.
              </li>

              <li>
                Dilute, tarnish, or otherwise harm the Company’s brand or
                business in any way.
              </li>

              <li>
                Use any robots, spider, crawler, scraper or other automated
                means or processes to access, collect data or other content from
                or otherwise interact with the App.
              </li>

              <li>
                Avoid, bypass, remove, deactivate, impair, descramble, or
                otherwise attempt to circumvent any technological measure
                implemented by the Company or any third party on behalf of the
                Company.
              </li>

              <li>
                Attempt to decipher, decompile, disassemble, or reverse engineer
                any of the software used to provide the Company’s App, Website
                and any other platforms owned and/or run by the Company.
              </li>

              <li>
                Take any action that damages or adversely affects, or could
                damage or adversely affect the performance or proper functioning
                of the App.
              </li>

              <li>
                Export, re-export, import, or transfer the App except as
                authorized by United States law, the laws of England and Wales,
                and the export control laws of your jurisdiction, and any other
                applicable laws.
              </li>

              <li>
                Violate or infringe anyone else’s rights or otherwise cause harm
                to anyone.
              </li>

              <li>Attempt, encourage, or assist any of the above.</li>
            </ul>
          </div>

          <div class="section">
            <p><b>7. Platform Service.</b></p>

            <p>
              Our App constitutes a neutral platform whereby Users can arrange
              to Swap in other Users’ properties. While we facilitate such
              arrangements, the Swap Contract is between the Users.
              Notwithstanding anything to the contrary in our App, we are not a
              party to the Swap Contract itself. We are not involved in the
              substance of the relationship between the Users. You acknowledge
              that any legal recourse arising from breach of the Swap Contract
              (e.g., inaccurate descriptions or conditions of a property, or
              damage to a property) or otherwise in relation to the Swap
              Contract, is against the other party to the Swap Contract and not
              against Holiday Swap.
            </p>
          </div>

          <div class="section">
            <p><b>8. Disclaimer.</b></p>

            <p>
              You acknowledge that we do not vet all Users or their property
              listings on the App. Whilst we do perform “know your customer”
              practises with active Guests and make continuous efforts to
              moderate property listings, you rely on such information and/or
              deal with other Users at your own risk. We cannot guarantee that
              they will comply with these Terms. It is your responsibility to
              carry out careful and detailed investigations before dealing with
              other Users. Please speak to Users on our in-app chat to learn
              more about the User and their Property. We accept no legal
              responsibility for the accuracy of, or otherwise in relation to,
              other Users’ property listings or Content or behaviour or in
              connection with any dealings between Users. You acknowledge that
              in using the App, you may be exposed to offensive or other
              inappropriate Content or behaviour. If so, please contact us at
              <a href="mailto:support@holidayswap.com"
                >support@holidayswap.com</a
              >
              to report the issue. If appropriate, you should stop using the App
              and seek relevant external help, for example from law enforcement
              authorities. If you have any complaint about Content or behavior
              which you think is defamatory or otherwise infringes your rights,
              please email us at
              <a href="mailto:support@holidayswap.com"
                >support@holidayswap.com</a
              >.
            </p>
          </div>

          <div class="section">
            <p><b>9. Reviews and Ratings.</b></p>

            <p>
              You acknowledge that we may permit Users to post reviews about you
              and/or your Property, and that these will be publicly available
              for viewing and will remain so. We are not responsible for
              monitoring or editing reviews. Reviews and star ratings
              (“<b>Ratings</b>”) reflect the opinions of individual Users and do
              not reflect the opinion of the Company. Reviews are not verified
              by us for accuracy and may be incorrect or misleading. Reviews and
              ratings made by Users must be accurate and may not contain any
              offensive or defamatory language. If you write a review or rating,
              you promise that it is your independent, honest, genuine opinion.
              Members are prohibited from manipulating reviews in any manner,
              such as instructing a third party to write a positive or negative
              review about yourself or another User. Reviews are part of a
              User’s public profile.
            </p>
          </div>

          <div class="section">
            <p><b>10. Your Profile, Content and Property Listing.</b></p>

            <p>
              You are responsible for your Profile and Content. You represent
              and warrant to us that you have (and will retain) all rights and
              permissions (including, but not limited to, intellectual property
              rights) needed to enable use of your Content as contemplated by
              the App and these Terms. We reserve the right, without notice or
              refund, to reject, suspend, alter, remove or delete Profiles and
              Content or to disclose to the relevant authorities any Content or
              behaviour if it is the subject of complaint or where we have
              reason to believe that it breaches our Terms, or that such steps
              are necessary to protect us or others, or that a criminal act has
              been committed, or if we are required to do so by law or
              appropriate authority. If so, you must not attempt to re-publish
              or re-send the relevant Content. We do not accept responsibility
              if your Profile or Content is misused by other Users as this is
              outside our reasonable control. It is your responsibility to make
              your own backup of any Content stored within the App to protect
              you in case of loss or damage to such material. We are not
              responsible for such loss or damage. We reserve the right to place
              advertisements adjacent to or within your Content. We retain all
              revenue from such advertisements. Once this Agreement is
              terminated or if your account has been inactive for a period of
              six (6) months, we will reserve the right without notice to
              irretrievably delete your Content. Content requested to be deleted
              by Users will be deleted without undue delay.
            </p>
          </div>

          <div class="section">
            <p><b>11. Contractual Relationship.</b></p>

            <p>
              As we have previously laid out in these Terms, a contractual
              relationship is formed between you and the Company once you
              download our App and register as a User. These Terms constitute a
              legally binding agreement between you and the Company.
            </p>
          </div>

          <div class="section">
            <p><b>12. Swap Contracts, Warrant and Representations.</b></p>

            <p>
              A Swap Contract is formed between User and another User when a
              swap is confirmed via our App including dates and number of
              Guests. You promise, represent, and warrant that you will not
              participate in any home exchange unless a Swap Contract is formed
              in this way on the App. You further represent and warrant to us
              that you will comply with all your obligations under the Swap
              Contract. If you have made a genuine mistake in the Swap Contract,
              you must contact us at
              <a href="mailto:support@holidayswap.com"
                >support@holidayswap.com</a
              >
              and the other User immediately. In our discretion, we will help
              you try to resolve the issue with the other User, but it will be
              up to the other User to decide whether to accept any changes as
              you have entered a legal contract with that User. Please speak to
              the other User in the in-app chat to discuss any problems and
              possible resolutions. There will be no refund of our Subscription
              Fees unless in our sole discretion, we decide otherwise. The Swap
              Contract includes the Terms set out in this section. You warrant
              and represent to us the following:
            </p>

            <ul>
              <li>
                To deal with other Users in a polite, courteous and co-operative
                manner.
              </li>
              <li>
                To comply with all visa requirements, tax requirements, all
                applicable laws, regulations, and ordinances (including zoning
                laws, permits and licenses).
              </li>
              <li>
                To not breach any agreements entered into with any
                third-parties.
              </li>
              <li>
                To take out adequate insurance coverage both in your capacity as
                a Host and Guest. This includes, but is not limited to,
                insurance covering property and home, liability, travel, and
                health.
              </li>
              <li>
                Not to offer, request or accept payment from another User on the
                same dates when a Swap Contract has already been formed.
              </li>
              <li>
                To notify the other User and our support (<a
                  href="mailto:support@holidayswap.com"
                  >support@holidayswap.com</a
                >) promptly, in writing, if you have any complaint arising from
                the stay.
              </li>
            </ul>
          </div>

          <div class="section">
            <p>
              <b>13. Cancellations of Swap Contracts – Issue of HS Points.</b>
            </p>

            <p>
              FOR THE AVOIDANCE OF ANY DOUBT, ALL USERS MAY BE REFUNDED IN THE
              FORM OF IN-APP HS POINTS ONLY. REFUNDS WILL NOT BE MADE VIA
              PAYPAL, OR ANY OTHER PAYMENT METHOD. As of June 2021, all refunds
              in the App will only be provided in the form of in-app HS Points,
              which may be used for a future home exchange within the Holiday
              Swap App. Only in extenuating circumstances, as outlined herein,
              shall a User be refunded in the form they paid, or if it is within
              the “cooling-off” period or otherwise, pursuant to applicable laws
              and regulations. It is in Holiday Swap’s sole discretion to
              provide refunds to Users in alternative forms from in-app HS
              Points, as in-app HS Points are the standard for all refunds.
              Hosts and Guests are responsible for any modification to a swap
              that they make using the Holiday Swap App and agree to pay any
              additional Host or Guest fees with such modifications, when
              applicable. If a modification cannot be agreed upon, the Users may
              cancel the Swap Contract, however, if a Swap Contract is cancelled
              within seven (7) calendar days before the first date of the Swap
              Contract, the User cancelling the Swap Contract (the “Cancelling
              User”) forfeits any Subscription Fees paid and a refund of the
              fees in the form of in-app HS Points, unless extenuating
              circumstances apply and are approved by the Company, in our sole
              discretion. The User being cancelled on (the “Impacted User”)
              within seven (7) calendar days before the first date of the Swap
              Contract, will be refunded, in the form of in-app HS Points only.
              The in-app HS Points can then be used for another Swap Contract.
              Please note, Users will not be refunded their Subscription Fees.
              Users are strongly encouraged to take out their own insurance.
            </p>

            <!-- <p>If the Impacted User subscribes to our <b>Guarantee</b> for Property Damage, the Impacted User may apply this Guarantee coverage
					to a future Swap Contract, within twelve (12) months from the cancellation, however, it may not be refunded. </p> -->

            <!-- <p>Users are encouraged to subscribe to our <b>Guarantee</b> to protect their trip. Please refer to our
					<b>Guarantee Terms</b>. Users who subscribe for Property Damage will be permitted to use the Property Damage
					for the same number days of coverage on another Swap, if their Swap is cancelled, and meets our cancellation policy.</p> -->
          </div>

          <div class="section">
            <p><b>14. Company Insurance.</b></p>
            <p>
              At this time the Company includes Property Damage insurance via
              the nightly Booking Fee. The Company accepts no liability for
              claims made. We strongly advise for all Users to ensure they have
              the appropriate insurance.
            </p>
          </div>

          <div class="section">
            <p><b>15. Extenuating Circumstances.</b></p>

            <p>
              We may give you a refund for the Subscription Fee for the nightly
              rate only if the Cancelling User cancels due to extenuating and/or
              unforeseen circumstances that are not within their control. This
              includes:
            </p>

            <ul>
              <li>Death of a Host, Guest, or an immediate family member.</li>
              <li>
                Airport or road closures making it impossible for you to travel
                to your destination.
              </li>
              <li>Airport or road closures due to natural disasters.</li>
              <li>
                If the Host or Guest has been individually impacted by a natural
                disaster or if there has been severe damage to their home (this
                does not include planned renovations).
              </li>
              <li>
                Serious illness of the Host, Guest or any member of the
                travelling party.
              </li>
              <li>
                Unforeseen or unexpected changes in a country’s safety and
                security, such as government security advisories due to
                political or civil unrest and/or violence, increased military
                presence, high terrorist alerts, or severe damage to the area’s
                infrastructure and stability.
              </li>
              <li>
                Civil obligations such as jury duty, court appearances, military
                deployment, and individual or government mandated travel
                restrictions, such as sudden changes to visa or passport
                requirements (this does not include lost or expired travel
                documents).
              </li>
            </ul>

            <p>
              If a User seeks to claim one of these extenuating circumstances,
              they must provide reasonable and valid documentation of proof
              requested by the Company. This may include government-issued
              certificates, copies of official notices, photograph
              documentation, invoices of damage, and documentation of illness or
              disease or illness that is affecting the region or an entire group
              of people (however, this does not include diseases or illnesses
              that are commonly and known to be found in that region, such as
              malaria in Sub-Saharan Africa). Please note, COVID-19 is a
              foreseeable event. From April 15, 2020, any bookings that are
              booked and subsequently cancelled owing to any reason linked to
              COVID-19, will not be considered an extenuating circumstance.
              Trips cancelled for any reason linked to COVID-19 will not be able
              to recover any Subscription Fees. Each case for extenuating
              circumstances will be reviewed by the Company. Each claim for
              extenuating circumstances must be submitted to
              <a href="mailto:support@holidayswap.com"
                >support@holidayswap.com</a
              >
              within fourteen (14) days of the extenuating circumstance. Claims
              for extenuating circumstances must be made in good faith and take
              into account the legitimate interests and safety of both parties.
            </p>
          </div>

          <div class="section">
            <p><b>16. Third Party Insurance.</b></p>
            <p>
              The Company includes Property Damage insurance within the nightly
              Booking Fee through it’s partner SuperHog. The Company accepts no
              liability for claims made. We strongly advise for all Users to
              ensure they have the appropriate insurance, including homeowners
              or renters.
            </p>
          </div>

          <div class="section">
            <p><b>17. In Your Capacity as Owner/Host.</b></p>

            <p>You represent and warrant to us that:</p>

            <ul>
              <li>
                Your Property listing, including photos, provides a reasonably
                accurate description of the property and that you have not left
                out any important features or other information about the
                property or area which Guests would reasonably expect to be told
                about.
              </li>
              <li>Your Property listing is in safe and habitable condition.</li>
              <li>
                You have the full legal right to make the relevant Property
                available for stays by other Users, whether you are present in
                the Property or not.
                <ul>
                  <li>
                    Please note, some landlord-tenant agreements do not permit
                    guests on the property, and it is your sole responsibility
                    to ensure you are complying with all rules, third-party
                    agreements, and regulations.
                  </li>
                </ul>
              </li>
              <li>
                You have obtained all necessary consents from third-parties to
                receive guests, including but not limited to mortgage lenders,
                insurance providers, and landlords.
              </li>
              <li>
                If you share the property with others who will remain during the
                stay, you promise to have obtained consent from other occupants
                to receive Guests and that you have disclosed to the Guest in
                advance of entering the Swap Contract that the property will not
                be vacant during the Guest’s stay. You are responsible for the
                behavior of those other occupants during the stay.
              </li>
              <li>
                To make the keys reasonably available to Guests and in
                accordance with any methods agreed via the App.
              </li>
              <li>
                You will ensure that the property is made available to Guests in
                a reasonably clean and tidy condition, with clean running water,
                hot water, and heating.
              </li>
              <li>
                To make yourself reasonably contactable by Guests during the
                period of any stay in your Property, and to respond promptly and
                appropriately to any requests by Guests.
              </li>
              <li>
                Take reasonable care to secure your valuables and obtain
                insurance for your property.
              </li>
              <li>
                To inspect your property immediately after the end of any stay
                and to report any suspected crime to the police.
              </li>
            </ul>
          </div>

          <div class="section">
            <p><b>18. Subscriptions.</b></p>

            <p>
              Holiday Swap may offer a Subscription with various offers and
              periods from time to time. All Subscription payments made are paid
              in advance on the App via our secure payment providers. Stripe
              and/or PayPal Subscription benefits are only available to
              Subscription paying Users (“Subscribing Users”). If you choose to
              subscribe to a Subscription, your period will begin on the date
              your payment is made in-full. A Subscription automatically renews
              at the beginning of each billing period of your Subscription. Your
              card used to sign up for the Subscription will be automatically
              charged on the renewal date unless it is cancelled before the
              renewal date. Users are permitted to trial a Subscription for
              fourteen (14) calendar days for free (Trial Period”). At the end
              of the Trial Period, payment for the chosen Subscription will be
              automatically taken, using the payment method provided by you when
              subscribing to your Subscription. You may cancel your free trial
              at any time within the Trial Period, or on our Website. If you do
              not cancel, your Subscription will automatically renew for the
              full period of your Subscription. During the Trial Period,
              insurance is not included. The Company accepts no liability for
              claims made. We strongly advise for all Users to ensure they have
              the appropriate insurance. Holiday Swap is permitted at its sole
              discretion to run promotional offers and discounts (“Offers”) to
              acquire a Subscription. These Offers may only be used in
              accordance with the terms provided when applicable; they may not
              be transferred or refunded. The Company reserves the right to
              increase fees for a Subscription at any time and will notify you
              of this by electronic mail (using the email provided on your
              Profile) and/or in-app notifications. The current benefits
              include, but are not limited to, unlimited swaps for the period of
              the Subscription for the User and for one (1) guest and instant
              messaging Users on the App prior to “matching” - limited to ten
              (10) messages per day. These benefits are subject to change at any
              time, in the Company’s sole discretion. The Company may
              immediately suspend or cancel any type of Subscription without
              notice or refund, if you:
            </p>

            <ul>
              <li>Breach these Terms.</li>
              <li>
                Fail to fully disclose the number of Guests you bring per Swap.
              </li>
              <li>
                Cancel an agreed and confirmed Swap Contract within seven (7)
                days from the agreed start date unless under extenuating
                circumstances.
              </li>
              <li>
                Are made part of a bankruptcy proceedings or if you do not pay a
                court judgment on time.
              </li>
              <li>
                Other reasonable circumstances that arise justifying suspension
                or cancellation, at the Company’s sole discretion.
              </li>
            </ul>

            <!-- <p><b>Upgrading</b></p>

				<p>Upgrading Subscriptions may occur when you subscribe to a longer Subscription Period, or when you subscribe
					to our Premium Plus Subscription Plan. The User's Subscription Plan will be immediately upgraded. Users will
					receive a refund of the prorated
					amount of their original Subscription. </p>

				<p><b>Downgrading</b></p>

				<p>Downgrading Subscriptions may occur when you subscribe to a shorter Subscription Period or lower Subscription
					level. The User's original Subscription Plan will continue until the next renewal date, and upon the renewal
					date will automatically be renewed
					at the shorter Subscription Period or lower Subscription level. </p>

				<p><b>Cancellation of Subscriptions </b></p>

				<p>If you cancel your Subscription, your current Subscription Plan will continue for the remainder of the
					Subscription Period and until the renewal date. </p>

				<p>If you subscribe to our Subscriptions via the Apple (iOS) App, you will be required to cancel your
					Subscription through the App Store, as per Apple"s in-app purchase policy. </p>

				<p>If you subscribe to our Subscriptions via the Google Play Store, you will be required to cancel the
					Subscription through the Google Play Store, pursuant to their terms and conditions. </p>

				<p>Cancellations of Subscriptions made on our Website, paid via our secure payment provider, will need to be
					cancelled directly on the Website. </p>

				<p>Please note, we do not provide refunds for Subscriptions, unless it is within your rights of the "Cooling Off
					Period" (see below). However, if you have used our Services before you exercise your right to cancel, then
					you must pay for the value of
					the Service used up to the date of cancellation. </p> -->
          </div>

          <div class="section">
            <p><b>19. Cancellation or Downgrading of a Subscription.</b></p>

            <p>
              If you cancel or downgrade your Subscription, your current
              Subscription will continue for the remainder of the period of the
              current Subscription and will then downgrade to the downgraded
              Subscription. Subscription cancellations made on our Website, paid
              via our secure payment provider, will need to be cancelled
              directly on the Website. Cancellations of a Subscription made on
              our App, paid via our secure payment provider will need to be
              cancelled directly on the App. Please note, we do not provide
              refunds for a Subscription unless it is within your rights of the
              “Cooling Off Period” (see above). However, if you have used our
              Services before you exercise your right to cancel, then you must
              pay for the value of the Service used up to the date of
              cancellation.
            </p>
          </div>

          <div class="section">
            <p><b>20. In Your Capacity as Guest.</b></p>

            <p>You represent and warrant to us that:</p>

            <ul>
              <li>
                You and your party will comply with all reasonable rules or
                regulations that apply to the property which are brought to your
                attention (e.g., relating to smoking or pets).
              </li>
              <li>
                To check the Host’s homeowner’s, renter’s or other relevant
                insurance coverage in advance and ensure your stay does not
                breach any of the policies that cover the Host and the Host’s
                cohabitants.
              </li>
              <li>
                That the number of persons who will stay in the property during
                the stay will not be more than the number of Guests agreed upon
                in the Swap Contract.
              </li>
              <li>
                You and your party will treat the property with reasonable care
                and consideration and will not engage in any illegal,
                disruptive, or unreasonable behaviour including vis-à-vis
                neighbours or other occupants of the property (if it is shared).
              </li>
              <li>
                You will not act in a way that would be a private nuisance to
                neighbours, including but not limited to hosting parties or
                acting disorderly.
              </li>
              <li>
                To take reasonable care to secure your valuables and obtain
                relevant insurance.
              </li>
              <li>
                To inform Hosts promptly if any of your party causes any damage
                to the property and to reimburse Hosts for such damage.
                <ul>
                  <li>
                    The Host is entitled to be reimbursed the value of the
                    damage, loss or theft, at the Guest’s responsibility.
                  </li>
                  <li>
                    The Company in its sole discretion may determine if the
                    Guest is responsible to pay the Host and the amount to be
                    paid.
                  </li>
                </ul>
              </li>
              <li>
                You will leave the Property in a reasonably clean and tidy
                condition, without damage and waste.
              </li>
              <li>
                To leave the keys in the property at the end of the stay unless
                otherwise agreed with the Owner.
              </li>
            </ul>
          </div>

          <div class="section">
            <p><b>21. Acts and Omissions.</b></p>

            <p>
              Unless expressly allowed by the Company, you may not list more
              than one accommodation per one Property listing. You represent and
              warrant to us that any Property you post and the booking of, or a
              Guest’s stay at, a Property will (1) not breach any agreements you
              have entered into with any third-parties, such as homeowners
              association, condominium, or other agreements, and (2) comply with
              all applicable laws (such as zoning laws), tax requirements, and
              other rules and regulations (including having all required
              permits, licenses and registrations). As a Host, you are
              responsible for your own acts and omissions and are also
              responsible for the acts and omissions of any individuals who
              reside at or are otherwise present at the Property at your request
              or invitation, excluding the Guest and any individuals the Guest
              invites to the accommodation.
            </p>
          </div>

          <div class="section">
            <p><b>22. Payments and Discount Codes.</b></p>

            <p>
              Holiday Swap uses secure payment service providers to ensure your
              payments are secure. At the time of entering a Swap Contract,
              Hosts and Guests can have unlimited Swaps once they have paid
              their Subscription Fees. The prices shown on our App include VAT
              or other applicable sales tax. The amount of the Subscription Fee
              is as stated on the App and is subject to change at the Company’s
              discretion at any time. We may offer discount codes from time to
              time. Such codes may only be applied to purchases made through the
              account in respect of which the discount code was offered and
              registered and are not transferrable or redeemable for cash.
              Unless otherwise stated, codes: (1) are only available for future
              new orders placed online; (2) cannot be used retrospectively; (3)
              can only be redeemed once per User; and (4) expire after twelve
              (12) months.. You cannot use more than one discount code per
              transaction unless we state otherwise; if we do so, the order in
              which the codes are to be applied is in our sole discretion. We
              reserve the right to reject any discount code if we consider that
              it is being used in breach of this Terms. Discount codes are
              subject to any additional specific Terms which are specified at
              the point of issue. We reserve the right to discontinue or
              otherwise modify any discount codes at any time without prior
              notice.
            </p>
          </div>

          <div class="section">
            <p><b>23. In-App Purchases and HS Points.</b></p>

            <p>
              We may offer HS Points from time to time when Users perform
              certain functions (e.g. when completing a Profile), or Users may
              purchase HS Points as an In-App Purchase. Each HS Point is
              currently worth One US Dollar ($1.00 USD) (“HS Points”). Such HS
              Points may only be applied to purchases made through the account
              and are not transferable or redeemable for cash. Unless otherwise
              stated, HS Points: (1) cannot be exchanged for real money, real
              goods or real services from us or anyone else; (2) are not
              transferrable to anyone else; and (3) all sales by us to you of HS
              Points are final, and you agree that we will not refund any
              transaction once it has been made. If you live in the United
              Kingdom or the European Union, you have certain rights to withdraw
              from distance purchases. HS Points may only be used in lieu of
              payment for a Swap Contract. If you are gifted HS Points by us or
              purchase HS Points, you may use the value of these HS Points
              towards a subsequent Booking. Please note, HS Points cannot be
              applied to the Subscription Fee. We may revise the pricing and/or
              value of HS Points offered through the Services at any time, but
              if you have active HS Points, such changes will only take effect
              in the future. We may limit the total amount of HS Points that may
              be purchased at any one time, and/or limit the total amount of HS
              Points that may be held in your account in the aggregate. You are
              only allowed to obtain HS Points from us, and not in any other
              way. We reserve the right to suspend, inactivate, and delete
              accounts in accordance with these Terms. If we suspend,
              inactivate, or delete your account, you may lose HS Points and we
              will not compensate you for this loss or make any refund to you.
              The charge for HS Points is inclusive of all sales taxes, VAT and
              other charges. Depending on which bank you use, additional charges
              may be issued by your bank; we have no control over this and
              accept no liability in relation to the same. If you are unsure
              whether you will be subject to such additional charges, then you
              should confirm with your bank before making a purchase on our App.
              We accept payment via our payment processing partners, Stripe and
              or PayPal, or through In-App purchases. Our payment processing
              partners may have their own terms and you should ensure you agree
              with these prior to making any payment. If your transaction with
              our payment processing partners is not successful, then your
              purchase will not be fulfilled. Upon the completion of a
              successful payment transaction, your purchase will be fulfilled to
              you as soon as possible; we will endeavour to fulfil your order at
              the point of purchase. If you live in the United Kingdom or
              European Union, we will provide you with a VAT invoice where we
              are required to do so by law or when requested by you. You agree
              that these invoices may be electronic format.
            </p>
          </div>

          <div class="section">
            <p><b>24. Disputes Between Users.</b></p>

            <p>
              You represent and warrant to us to take reasonable steps to
              resolve any dispute with other Users who you have entered into a
              Swap or Booking with. We may adjudicate on any disputes between
              the parties of a Swap Contract or Booking (whether relating to
              cancellations, inaccurate descriptions or condition of a property,
              damage to a property or otherwise) in our absolute, sole
              discretion: (1) where within the Dispute Period one of the Users
              has raised a dispute (as explained below) and we think that the
              dispute is not a minor one and in our discretion, requires our
              intervention; and (2) otherwise in our discretion. If a Host
              claims and provides evidence that a Guest has damaged an
              accommodation or any personal or other property at any
              accommodation, the Host can seek payment from the Guest for the
              amount of damage, loss or theft, which the Guest (and any of the
              Guest’s party) is responsible for. The Company also is not liable
              for any disputes, and dispute subject matter, arising between
              Users and/or a Swap Contract. We highly advise all Users to
              acquire independent insurance coverage for damage, loss, theft, or
              cancellation. Users agree to cooperate with and assist the Company
              in good faith, and to provide the Company with such information
              and take such actions as may be reasonably requested by the
              Company in connection with any claim or complaint made by Users
              relating to the Property or any personal or other property located
              at the Property. If we inform the parties that we are adjudicating
              on a dispute, both Users must provide prompt and reasonable
              cooperation including provision of any information we request.
              Subject to such cooperation, we will make a decision within
              forty-five (45) days of receiving all of the information we
              require but do not guarantee to meet this timescale. Our decision
              will be in our absolute discretion and will be final but without
              affecting any legal rights of the aggrieved User against the other
              User. In the case of cancellations, we will apply the applicable
              cancellation policy. If you are a Guest or Host, you understand
              and agree that the Company may make a claim under your homeowner’s
              or renters insurance policy related to any damage or loss that you
              may have caused, or been responsible for, to any personal or other
              property (including an accommodation) of the Host (including
              without limitation amounts paid by the Company). You agree to
              cooperate and assist the Company in good faith and to provide the
              Company with such information as may be reasonably requested by
              the Company to make a claim under your homeowner’s or renter’s
              insurance, including but not limited to, executing documents, and
              taking such further steps as the Company may reasonably request to
              assist the Company in accomplishing the foregoing. If the User has
              another insurance policy (which is not homeowner’s or renter’s
              insurance) then these rules still apply.
            </p>
          </div>

          <div class="section">
            <p><b>25. Right to Cancel (Cooling-Off Period).</b></p>

            <p>
              If you are located within the United Kingdom or European Economic
              Area, you have the right to cancel subject to the provisions set
              out below. However, you lose the right to cancel digital downloads
              where the supply began before the end of cancellation period with
              your express consent, and you acknowledged that your right to
              cancel would be lost in such case. You have the right to cancel
              within fourteen (14) days from the date you initially signed up as
              a Guest or Host without giving any reason. To exercise the right
              to cancel, you must inform us via email to
              <a href="mailto:support@holidayswap.com"
                >support@holidayswap.com</a
              >
              and confirm your decision to cancel by a clear statement. You may
              use the following model cancellation form at the end of this
              Agreement. To meet the cancellation deadline, you must send your
              communication concerning your exercise of the right to cancel
              before the cancellation period has expired. If you cancel within
              the allotted time, we will reimburse you payments received from
              you; this does not include App purchases and Subscription Fees
              (including subscriptions). We will make the reimbursement without
              undue delay, and not later than thirty (30) days after the day on
              which we are informed about your decision to cancel. We will make
              the reimbursement via Stripe, PayPal, or other electronic banking
              means in our sole discretion; in any event, you will not incur any
              fees because of the reimbursement. You are entitled to cancel use
              of our App at any time and for any reason, by giving notice of
              cancellation by emailing us at
              <a href="mailto:support@holidayswap.com"
                >support@holidayswap.com</a
              >. You may use the following model cancellation form at the end of
              this Agreement. If you cancel:
            </p>

            <ul>
              <li>
                Your Agreement still continues insofar as necessary to
                facilitate any pending Swap Contracts you entered into before
                termination (which are unaffected)
              </li>
              <li>Stop the above, your right to use our App is terminated.</li>
              <li>
                You are not entitled to any refund (though this does not affect
                a cooling off rights, explained above).
              </li>
              <li>
                Any rights and liabilities which arose before termination are
                unaffected.
              </li>
              <li>
                All clauses which are stated or intended to continue after
                cancellation will continue to apply.
              </li>
              <li>
                You must not attempt to re-download our App if we have given you
                notice of termination.
              </li>
            </ul>
          </div>

          <div class="section">
            <p>
              <b
                >26. Cancellation of Booking Requests and Instant Bookings -
                Issue of HS Points.</b
              >
            </p>
            <p>
              Hosts and Guests are responsible for any modification to a Booking
              or Instant Booking that they make and agree to pay any additional
              Host or Guest fees if applicable. If a modification cannot be
              agreed upon, the Guest may cancel the booking, subject to the
              following conditions:
            </p>

            <ul>
              <li>
                <b>Instant Booking.</b> When a Guest makes an instant booking,
                HS Points will be blocked temporarily and only on the day the of
                arrival, HS Points will be transferred to the host. If the Guest
                cancels within seven (7) calendar days of the trip, the Guest
                will forfeit all the HS Points spent on this Instant Booking.
                Extenuating circumstances may apply which may be approved by the
                Company, in our sole discretion. Users must provide adequate
                evidence. If the Host cancels within seven (7) calendar days of
                the trip, the Guest will refund all the HS Points spent on this
                Instant Booking. Occasionally a Host may need to cancel. The
                Company will do everything possible to assist in finding
                alternative accommodation, but this is not a guarantee.
              </li>

              <li>
                <b>Booking Request.</b> When a Guest makes a Booking Request, HS
                Points will be blocked temporarily. The Booking request is sent
                to the Host. The Host then has three (3) options:

                <ul>
                  <li>
                    If the Host agrees with the Guest and travel commences, HS
                    Points will be released on the first calendar day of the
                    trip.
                  </li>
                  <li>
                    If the Host disagrees, the HS Points will be returned on
                    that day.
                  </li>
                  <li>
                    If no decision is made within twenty four (24) hours the
                    Booking Request will be cancelled and HS Points will be
                    returned to the user.
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <div class="section">
            <p><b>27. Third Party Services / Advertising / Websites.</b></p>

            <p>
              We may use third party-provided services or display third-party
              advertising within our App and/or link to third-party websites
              which may be of interest to you. We do not recommend or endorse,
              nor are we legally responsible for those sites or services. You
              use them at your own risk. Our in-app ads are provided by the
              Facebook Ad Network. To learn more, please refer to Facebook’s
              terms.
            </p>
          </div>

          <div class="section">
            <p><b>28. Guidance by Us.</b></p>

            <p>
              Any guidance or similar information which we ourselves make
              available on our App is intended as very general guidance
              information, but we cannot guarantee that it is accurate or up to
              date and we do not accept legal responsibility for it. Before
              acting on such information, you must make your own appropriate and
              careful inquiries including as to its accuracy and suitability for
              your purposes. You rely on such information at your own risk.
            </p>
          </div>

          <div class="section">
            <p><b>29. Security.</b></p>

            <p>
              Your account on our App is for your personal use only and is
              non-transferable (this includes any assignments or delegations of
              your rights and responsibilities under this contract). You must
              not authorise or permit any other person to use your account. You
              must take reasonable care to protect and keep confidential your
              password and other account or identity information. You must
              notify us immediately of any apparent breach of security such as
              loss, theft, misuse or unauthorised disclosure or use of a
              password. You are responsible for third-parties who use your
              account or identity.
            </p>
          </div>

          <div class="section">
            <p><b>30. Functioning of Our App.</b></p>

            <p>
              We do not guarantee that the App will be uninterrupted or
              error-free and are not responsible for any losses arising from
              such interruptions or errors. We are entitled, without notice and
              without liability, to suspend the App for repair, maintenance,
              improvement, or other technical reason. We are entitled, without
              notice and without liability, to make changes to the App. We will
              try to ensure these do not have a material adverse effect on your
              use of the App.
            </p>
          </div>

          <!-- <div class="section">

				<p><b>The Following Applies Where Apple Inc. is the
						Appstore</b></p>

				<p>In the event of any failure of the App to
					conform to any applicable warranty, you may notify Apple, and Apple will refund
					the purchase price for the App (if applicable). To the maximum extent permitted
					by applicable law, Apple will have no other warranty obligation whatsoever with
					respect to the App, and any other claims, losses, liabilities, damages, costs
					or expenses attributable to any failure to conform to any warranty will be our
					sole responsibility subject to these terms and conditions.</p>

				<p>We, not Apple or Google Play, are responsible
					for addressing your claims or the claims of any third party relating to the App
					or the end-user's possession and/or use of that App, including, but not limited
					to:</p>

				<ul>
					<li>Product liability claims</li>
					<li>Any claim that the App fails to
						conform to any applicable legal or regulatory requirement</li>
					<li>Claims arising under consumer
						protection or similar legislation</li>
				</ul>

			</div> -->

          <div class="section">
            <p><b>31. Intellectual Property Rights.</b></p>

            <p>
              All trademarks, logos, graphics, images, photographs, animation,
              videos, text, and software used on and in connection with the App
              are the Company’s intellectual property or that of our partners or
              other Users. For the purposes of your personal use only, you may
              view such material on your screen and print a single copy. You may
              not otherwise use, sublicense, retrieve, display, modify, copy,
              print, sell, distribute, download, hire, reverse engineer (unless
              permitted by applicable law) or create extracts of, or derivative
              works from, such material without our specific prior written
              consent. The Company’s registered trademarks include our logo and
              branded name, Holiday Swap. You mustn’t collect, scrape, or
              harvest any Content on our App without our specific prior written
              consent. All content included in or made available through our
              App, platforms, and social media sites, such as text, graphics,
              logos, button icons, images, audio clips, digital downloads, data
              compilations, and software is the property of the Company, or its
              content suppliers and protected by the United Kingdom and
              international copyright laws. The compilation of all content
              included in or made available through our App is the exclusive
              property of the Company. If you publish any Content on our App,
              you grant us a worldwide, perpetual, non-exclusive, transferable
              (with right to sub-license), royalty-free license to use, copy,
              alter, display, and create extracts of, or derivative works from,
              that Content in any media formats, on our own App, on our other
              channels including mobile, email communications, social media, PR,
              competitions and press releases and also on third party media,
              including for the purpose of redistribution or promotion of our
              App. You waive your moral rights in relation to such Content to
              the extent legally permitted. You also grant each User a license
              to use your Content in accordance with these Terms. This includes
              all Profile information and images. Please ensure all images
              uploaded by you onto their Profile or the In-App map, MyMaps, are
              owned by you and do not infringe any intellectual property laws.
              Any intellectual property infringements will be the sole
              responsibility of the User, and the User agrees to not hold the
              Company liable. You must not reverse-engineer or decompile any of
              our software in any way (except to the extent allowed by
              applicable law). You must not create or use a modified or
              derivative version of our software or distribute or sublicense our
              software to third parties. You must take reasonable steps to
              ensure that our software is not disclosed to any third-party. In
              the event of any third-party claim that the App or your possession
              and use of the App infringes that third-party’s intellectual
              property rights, we, not the App Store, will be solely responsible
              for the investigation, defense, settlement, and discharge of any
              such intellectual property infringement claim.
            </p>
          </div>

          <div class="section">
            <p><b>32. Privacy and Personal Data.</b></p>

            <p>
              Pursuant to the European Union General Data Protection Regulation
              2016/679 and the EU law retained version of the General Data
              Protection Regulation 2016/679 (“UK GDPR”), as well as associated
              national laws, Holiday Swap is committed to protecting and
              respecting your privacy and rights related to our processing of
              personal information. By using our Services, you acknowledge and
              agree that we may process your personal data in accordance with
              the Terms of our
              <a href="https://www.holidayswap.com/privacy-policy/"
                >privacy policy</a
              >, which we reserve the right to modify at any time. If we make
              material changes, we will provide a notice on our Website and via
              e-mail. You may access the most current version of our privacy
              statement on this site. If you have any questions regarding your
              privacy rights, please email us at
              <a href="mailto:support@holidayswap.com"
                >support@holidayswap.com</a
              >.
            </p>
          </div>

          <div class="section">
            <p>
              <b
                >33. Legal Compliance and Eligibility for Using Our Services.</b
              >
            </p>

            <p>
              You must be at least eighteen (18) years old and able to enter
              into legally binding contracts to access and use Holiday Swap’s
              Services or register a Holiday Swap account. By accessing or using
              our services, you represent and warrant that you are eighteen (18)
              years or older and have the legal capacity and authority to enter
              into a contract. You represent and warrant that (1) you are not
              located in a country that is subject to a U.S. Government embargo,
              or that has been designated by the U.S. Government as a “terrorist
              supporting” country; and (2) you are not listed on any U.S.
              Government list of prohibited or restricted parties. You represent
              and warrant that you will comply with any applicable export
              control laws in your local jurisdiction. The access to or use of
              Services may be subject to separate policies or may require you to
              accept additional Terms. If there is a conflict between these
              terms and terms applicable to a specific area or feature, the
              latter terms will take precedence with respect to your access to
              and use of that area or feature, unless specified otherwise.
            </p>

            <!-- <p>If you access or download the App from the Apple
					App Store or Google Play Store (or another applicable app store), you agree to
					their Licensed Application End User License Agreements. Our MyMaps implement
					mapping services from Google Maps API, and your use with this feature is
					subject to Google Maps Additional Terms of Service.</p> -->
          </div>

          <div class="section">
            <p><b>34. Member Verification.</b></p>

            <p>
              We do not assume any responsibility for the confirmation of any
              User’s identity. Notwithstanding the above, for transparency and
              fraud prevention purposes, and as permitted by applicable laws, we
              may, but have no obligation to (i) ask Users to provide a form of
              government identification or other information or undertake
              additional checks designed to help verify the identities or
              backgrounds of Users; (ii) screen Users against third-party
              databases or other sources and request reports from service
              providers; and (iii) where we have sufficient information to
              identify a User, obtain reports from public records of criminal
              convictions or sex offender registrations or an equivalent version
              of background of registered sex offender checks in your local
              jurisdiction. The Company, however, is not responsible for
              confirming User identities or property verification; if it does
              confirm User identities or property verification, it may do so in
              its absolute and sole discretion.
            </p>
          </div>

          <div class="section">
            <p><b>35. Events Outside Company’s Control.</b></p>

            <p>
              We are not liable for failure to perform or delay in performing
              any obligation under this Agreement if the failure or delay is
              caused by any circumstances beyond our reasonable control
              including third-party telecommunication failures.
            </p>
          </div>

          <div class="section">
            <p><b>36. Disclaimers.</b></p>

            <p>
              IF YOU CHOOSE TO USE OUR SERVICES, YOU DO SO VOLUNTARILY AND AT
              YOUR SOLE RISK. THE COMPANY’S SERVICES AND ALL INFORMATION,
              CONTENT, MATERIALS, PRODUCTS, AND OTHER SERVICES INCLUDED ON OR
              OTHERWISE MADE AVAILABLE TO YOU THROUGH THE COMPANY’S SERVICES,
              WEBSITES, AND APP ARE PROVIDED BY THE COMPANY ON AN “AS IS” AND
              “AS AVAILABLE” BASIS, WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS
              OR IMPLIED. YOU AGREE THAT YOU HAVE HAD WHATEVER OPPORTUNITY YOU
              DEEM NECESSARY TO INVESTIGATE THE COMPANY’S SERVICES, LAWS, RULES,
              OR REGULATIONS THAT MAY BE APPLICABLE TO YOUR LISTINGS AND/OR HOST
              SERVICES YOU ARE RECEIVING AND THAT YOU ARE NOT RELYING UPON ANY
              STATEMENT OF LAW OR FACT MADE BY THE COMPANY RELATING TO A
              LISTING. IF WE CHOOSE TO CONDUCT IDENTITY VERIFICATION OR
              BACKGROUND CHECKS ON ANY USER, TO THE EXTENT PERMITTED BY
              APPLICABLE LAW, WE DISCLAIM WARRANTIES OF ANY KIND, EITHER EXPRESS
              OR IMPLIED, THAT SUCH CHECKS WILL IDENTIFY PRIOR MISCONDUCT BY A
              USER OR GUARANTEE THAT A USER WILL NOT ENGAGE IN MISCONDUCT IN THE
              FUTURE. TO THE FULL EXTENT PERMISSIBLE BY LAW, HOLIDAY SWAP GROUP
              LIMITED DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING
              BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND
              FITNESS FOR A PARTICULAR PURPOSE. THE COMPANY DOES NOT WARRANT
              THAT OUR SERVICES, INFORMATION, CONTENT, MATERIALS, PRODUCTS, OR
              OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU
              THROUGH OUR PLATFORMS ARE FREE OF VIRUSES OR OTHER HARMFUL
              COMPONENTS. TO THE FULL EXTENT PERMISSIBLE BY LAW, THE COMPANY
              WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE
              USE OF ANY SERVICES, OR FROM ANY INFORMATION, CONTENT, MATERIALS,
              PRODUCTS, OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE
              AVAILABLE TO YOU THROUGH OUR PLATFORMS, INCLUDING BUT NOT LIMITED
              TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL
              DAMAGES, UNLESS OTHERWISE SPECIFIED IN WRITING. THE FOREGOING
              DISCLAIMERS APPLY TO THE MAXIMUM EXTENT PERMITTED BY LAW. YOU MAY
              HAVE OTHER STATUTORY RIGHTS. HOWEVER, THE DURATION OF STATUTORILY
              REQUIRED WARRANTIES, IF ANY, SHALL BE LIMITED TO THE MAXIMUM
              EXTENT PERMITTED BY LAW.
            </p>
          </div>

          <div class="section">
            <p><b>37. Liability.</b></p>

            <p>
              Unless your country of residence is in the United Kingdom or the
              European Union, you acknowledge and agree that, to the maximum
              extent permitted by law, the entire risk arising out of your
              access to and use of the Company’s App and/or Services, whether in
              person or online remains with you. Neither the Company nor any
              other party involved in creating, producing or delivering the
              Company’s App and/or Services will be liable for any incidental,
              special, exemplary or consequential damages, including lost
              profits, loss of data or loss of goodwill, service interruption,
              computer damage or system failure or the cost of substitute
              products or services, or for any damages for personal or bodily
              injury or emotional distress arising out of or in connection with
              (1) these Terms; (2) from the use of or inability to use the App
              and/or Services; (3) from any communications, interactions or
              meetings with other Users or other persons with whom you
              communicate, interact or meet with as a result of your use of the
              App; or (4) from your publishing or entering into a Swap Contract,
              whether based on warranty, contract, tort (including negligence),
              product liability or any other legal theory, and whether or not
              the Company has been informed of the possibility of such damage,
              even if a limited remedy set forth herein is found to have failed
              of its essential purpose. Nothing in this Agreement in any way
              limits or excludes our liability for negligence causing death or
              personal injury, or for fraud or fraudulent misrepresentation, or
              for anything which may not legally be excluded or limited in your
              jurisdiction of residence. In this section, any reference to us
              includes our employees and agents. The Company shall not be liable
              for any loss or damage caused by us or our employees or agents in
              circumstances where any of the following apply:
            </p>

            <ul>
              <li>
                There is no breach of a legal duty of care owed to you by us or
                by any of our employees or agents.
              </li>
              <li>
                Such loss or damage was not reasonably foreseeable (meaning it
                was not an obvious consequence of our breach or not contemplated
                by you and us at the time we entered into this contract).
              </li>
              <li>
                Such loss or damage is caused by you, for example by not
                complying with this Agreement, Terms and Policies.
              </li>
              <li>Such loss or damage relates to a business of yours.</li>
            </ul>

            <p>
              You will be liable for any reasonably foreseeable loss or damage
              we suffer arising from your breach of this Agreement or misuse of
              our App (subject of course to our obligation to mitigate any
              losses). If your country of residence is in the United Kingdom or
              European Union, we are liable under statutory provisions for
              intent and gross negligence by us, our legal representatives,
              directors, or other agents. The same applies to the assumption of
              guarantees or any other strict liability, or in case of a culpable
              injury to life, limb, or health. We are liable for any negligent
              breaches of essential contractual obligations by us, our legal
              representatives, directors, or other vicarious agents. Essential
              contractual obligations are such duties of the Company in whose
              proper fulfilment you regularly trust and must trust for the
              proper execution of the Agreement, but the amount shall be limited
              to the typically occurring foreseeable damage. Any additional
              liability of the Company is excluded.
            </p>

            <!-- <p>The following applies where Apple Inc. is the
					Appstore:</p>

				<ul>
					<li>In the event of any failure of the
						App to conform to any applicable warranty, you may notify Apple, and Apple will
						refund the purchase price for the App (if applicable). To the maximum extent
						permitted by applicable law, Apple will have no other warranty obligation
						whatsoever with respect to the App, and any other claims, losses, liabilities,
						damages, costs or expenses attributable to any failure to conform to any
						warranty will be our sole responsibility subject to these Terms</li>
					<li>We, not Apple, are responsible for
						addressing your claims or the claims of any third-party relating to the App or
						the end-user's possession and/or use of that App, including, but not limited
						to:
						<ul>
							<li>product liability claims</li>
							<li>any claim that the App fails to
								conform to any applicable legal or regulatory requirement</li>
							<li>claims arising under consumer
								protection or similar legislation</li>
						</ul>
					</li>
				</ul> -->
          </div>

          <div class="section">
            <p><b>38. Indemnification.</b></p>

            <p>
              You agree to release, defend, indemnify and hold Holiday Swap and
              its affiliates, subsidiaries, officers, directors, employees and
              agents, as harmless from and against any claims, liabilities,
              damages, losses, and expenses, including, without limitation,
              reasonable legal and accounting fees, arising out of or in any way
              connected with (1) your breach of these Terms or our other Company
              policies; (2) your improper use of our App; (3) your interaction
              with any User, stay at an accommodation, including but not limited
              to any injuries, losses, theft or damages (whether compensatory,
              direct, incidental, consequential or otherwise) of any kind
              arising in connection with or as a result of such interaction or
              stay; or (4) your breach of any laws, regulations or third party
              rights.
            </p>
          </div>

          <div class="section">
            <p><b>39. Jurisdiction.</b></p>

            <p>
              These Terms and any dispute or claim arising out of or in
              connection with it or its subject matter or formation (including
              non-contractual disputes or claims) shall be governed by and
              construed in accordance with the law of England and Wales. Any
              dispute will be subject to the non-exclusive jurisdiction of the
              courts of England and Wales. If you are a consumer, you will
              benefit from any mandatory provisions of the law in your country
              of residence. Nothing in the above clause takes away or reduces
              your rights as a consumer to rely on those provisions. If you are
              a consumer, any dispute, controversy, proceedings or claim between
              you and us relating to this Agreement, or the relationship between
              you and Us (whether contractual or otherwise) shall be subject to
              the jurisdiction of the courts of England, Wales, Scotland, or
              Northern Ireland, as determined by your residency.
            </p>
          </div>

          <div class="section">
            <p><b>40. Complaints and Feedback.</b></p>

            <p>
              If you have any complaints, please contact us at
              <a href="mailto:support@holidayswap.com"
                >support@holidayswap.com</a
              >. We welcome and encourage you to provide feedback, comments, and
              suggestions for improvements to our Services. You may submit this
              feedback by emailing us through the “Contact Us” page on our
              Website. Any feedback you submit to us will be considered
              non-confidential and non-proprietary to you. By submitting
              feedback to us. You grant us a non-exclusive, worldwide,
              royalty-free, irrevocable, sub-licensable, perpetual license to
              use and publish those ideas and materials for any purpose, without
              compensation to you.
            </p>
          </div>

          <div class="section">
            <p><b>41. General Provisions.</b></p>

            <p>
              We may send all notices under this Agreement by email to the most
              recent email address you have provided to us. All notices will be
              published on our App and Website. Headings in this Agreement are
              for information purposes only and are not binding. Except as they
              may be supplemented by additional terms, policies, guidelines or
              standards, these Terms constitute the entire Agreement between
              Holiday Swap and you pertaining to the subject matter hereof, and
              supersede any and all prior oral or written understandings or
              agreements between Holiday Swap and you in relation to the access
              to and use of our Services. No joint venture, partnership,
              employment, or agency relationship exists between you and Holiday
              Swap as a result of this Agreement or your use of our Services.
              These Terms do not and are not intended to confer any rights or
              remedies upon any person other than the parties. If any provision
              of these Terms is held to be invalid and unenforceable, such
              provision will be struck and will not affect the validity and
              enforceability of the remaining provisions. A person who is not a
              party to this Agreement shall have no rights to enforce this
              Agreement except insofar as expressly stated otherwise. Holiday
              Swap’s failure to enforce any right or provision in these Terms
              will not constitute a waiver of such right or provision unless
              acknowledged and agreed to by us in writing. Except as expressly
              set forth in these Terms, the exercise by either party of any of
              its remedies under these Terms will be without prejudice to its
              other remedies under these Terms or otherwise permitted under law.
              You may not assign, transfer or delegate this Agreement and your
              rights and obligations hereunder without Holiday Swap’s prior
              written consent. Holiday Swap may without restriction assign,
              transfer or delegate this Agreement and any rights and obligations
              hereunder, at its sole discretion, with thirty (30) days’ prior
              notice. Your right to terminate this Agreement at any time remains
              unaffected. This Agreement constitutes the entire understanding
              between the parties and supersedes all prior agreements between
              the parties with respect to the subject matter hereof and shall
              bind each party. No variation of this Agreement shall be valid
              unless it is in writing and signed by or on behalf of each of the
              parties. A person who is not a party to this Agreement shall not
              have any rights under the Contract (Rights of Third Parties) Act
              1999 to enforce any term of this Agreement. The rights of the
              parties to terminate, rescind or agree any variation, waiver or
              settlement under this Agreement are not subject to the consent of
              any person that is not a party to this Agreement. If you have any
              questions about these Terms, please email us at
              <a href="mailto:support@holidayswap.com"
                >support@holidayswap.com</a
              >.
            </p>
          </div>

          <div class="section">
            <p><b>42. Online Store.</b></p>

            <p>
              We reserve the right to refuse any order you place with us. We
              may, in our sole discretion, limit or cancel quantities purchased
              per person, per household, or per order. These restrictions may
              include orders placed by or under the same customer account, the
              same credit card, and/or orders that use the same billing and/or
              shipping address. In the event that we make a change to or cancel
              an order, we may attempt to notify you by contacting the e-mail
              and/or billing address/phone number provided at the time the order
              was made. We reserve the right to limit or prohibit orders that,
              in our sole judgment, appear to be placed by dealers, resellers or
              distributors. Furthermore, it is in our sole discretion to
              discontinue any product at any time, without cause or reason. You
              agree to provide current, complete, and accurate purchase and
              account information for all purchases made at our store. You agree
              to promptly update your account and other information, including
              your email address and credit card numbers and expiration dates,
              so that we can complete your transactions and contact you as
              needed. If you have any questions pertaining to online orders,
              please contact
              <a href="mailto:support@holidayswap.com"
                >support@holidayswap.com</a
              >
              contact.
            </p>

            <!-- <p><b>Risk of Loss</b></p>

				<p>All purchases of physical items from the Company are made pursuant to a shipment contract. For
					shipment contracts, the seller must satisfy the following
					for perfect delivery: (1) get the goods to a common carrier; (2) make arrangements for delivery; and
					(3) notify the buyer.
					The risk of loss and title for the physical items purchased from the Company pass to you upon our
					delivery to the carrier.</p>

				<p><b>Returns and Refunds</b></p>

				<p>The Company does not take title to returned items until the items have been physically returned to
					us.
					At our discretion, a refund may be issued without requiring a return.
					In this situation, the Company does not take title to the refunded item. </p>

				<p>To be eligible for a return, refund or exchange, your item must be unused and in the same condition
					that you received it in. Please maintain our original packaging and send back the item in this
					packaging.
					Returns, refunds and exchanges must be made within <b>fourteen (14) calendar days</b> from receiving
					the purchase.
					Once your return is received and inspected for quality, we will notify you by email whether you are
					eligible for a refund.
					If approved, then your refund will be processed and a credit will automatically be applied to your
					original payment method within seven (7) business days. Please note that you are responsible for
					paying your own shipping costs for returning your items. Shipping costs are non-refundable.</p>

				<p><b>Product Descriptions</b></p>

				<p>The Company attempts to be as accurate as possible when providing product descriptions.
					However, the Company does not warrant that product descriptions or other content is accurate,
					complete, reliable, current or error-free.
					If a product offered by us is not as described, your sole remedy is to return it in unused condition
					pursuant to our Returns Policy (above).</p> -->
          </div>

          <div class="section">
            <p><b>43. Fees and Waiver of Fees.</b></p>

            <p>
              Guests subscribed to an annual plan of $100 are entitled to have
              all Booking Fees waived for the tenure of that subscription. All
              other Guests will be subject to a $10 (USD) per night booking fee
              on top of any other charge. Hosts who have subscribed to the
              annual plan of $100 will pay a 6% fee on the total cost of all
              completed bookings. Hosts are entitled to monies 72 hours after
              the first day the booking commences. All other hosts, not
              subscribed the the annual plan of $100 will pay a 9% fee on the
              total of all completed bookings. This includes casual hosts who
              are on the $10 per month plan. We reserve the right to change the
              booking fee and commission structure at our discretion and will
              endeavour to inform all customers prior to any change.
            </p>
          </div>

          <div class="section">
            <p><b>43.1 Cancellation of cash bookings </b></p>

            <p>More than 7 days in advance of the booking a start date:</p>
            <p>
              If a guest cancels more than 7 days before the booking start date,
              all credit will be refunded within 72 hours of cancelling, minus
              the first 3 nights of the Booking Fee.
            </p>
            <p>
              If a host cancels more than 7 days before the booking start date,
              all credit will be refunded to the guest within 72 hours of
              cancelling and the company will do everything reasonably possible
              to find an alternative accommodation. This is not guaranteed.
              There will be no penalty to the host for cancelling.
            </p>
            <p>Less than 7 days in advance of the booking a start date:</p>
            <p>
              If a guest cancels less than 7 days before the booking start date,
              all credit, minus the Booking Fees will be funded to the host
              within 72 hours of cancelling.
            </p>

            <p>
              If a host cancels less than 7 days before the booking start date,
              all credit will be refunded to the guest within 72 hours of
              cancelling and the host will pay the Booking Fee penalty, 6% of
              total for premium hosts or 9% for casual hosts. This penalty will
              be deducted immediately.
            </p>

            <p>
              On occasion Hosts will have special rules, terms and conditions
              that apply to bookings of their properties. These will be
              presented during the booking process and must be agreed to by you,
              the Guest, prior to completion of the booking process.
            </p>
          </div>

          <div class="section">
            <p><b>44. Government Identification.</b></p>

            <p>
              Various government laws require us to obtain, verify, and record
              information identifying each person who opens an account,
              including applicable “Know Your Customer” requirements. You
              acknowledge such information disclosure requirements and agree to
              comply with all such information disclosure requests from time to
              time. In accordance with this, we require a valid Governmental ID
              before any Host payouts. We use the third party company, SuperHog,
              to undertake our know your customer practises.
            </p>
          </div>

          <div class="section">
            <p><b>45. Headings and References.</b></p>

            <p>
              The headings in this Agreement are inserted for convenience only
              and shall not affect its construction. A reference to a particular
              law is a reference to it as it is in force for the time being
              taking account of any amendment, extension, or re-enactment and
              includes any subordinate legislation for the time being in force
              made under it. Unless the context otherwise requires, a reference
              to one gender shall include a reference to the other gender.
              Unless the context otherwise requires, words in the singular
              include the plural and, in the plural, include the singular.
            </p>
          </div>

          <div class="section">
            <p><b>46. Incorporation Information.</b></p>

            <p>
              Holiday Swap Group Limited is incorporated in England and Wales.
              The registered number is 10847362. The Company’s registered office
              is 43 Berkeley Square, London, United Kingdom, W1J 5FJ. For more
              information, visit our Website.
            </p>
          </div>

          <div class="section">
            <p><b>47. Taxes and licences.</b></p>

            <p>
              Occupant taxes, operating licenses and related liabilities are the
              sole responsibility of the host or homeowner.
            </p>
          </div>

          <div class="section">
            <p><b>48. New York City and home listings.</b></p>

            <p>
              New York City prohibits renting out a home or apartment for less
              than 30 days if the owner is not present during the stay. However,
              Local Law 18 requires hosts already adhering to existing
              guidelines and planning on renting out a space to register with a
              city database and obtain a registration number. Holiday Swap has
              the right to then verify that number before listing a home and
              processing payments for that listing. Failing to comply with the
              new measure means a host could end up owing the city between
              $1,000 and $5,000 in penalty fees.
            </p>
          </div>

          <div class="section">
            <p><b>49. Vouchers.</b></p>

            <p>
              Occasionally Holiday Swap may offer promotional vouchers including
              discounts for the cost of Bookings. Vouchers are single use, one
              voucher per booking and have no residual value beyond the first
              (and only) booking made.
            </p>
          </div>

          <div class="section">
            <p><b>50. Boosting homes in Search.</b></p>

            <p>
              Hosts may Boost their home to appear top of search in their
              respective city or region for periods of time defined in one week
              increments. Ability to Boost is a limited free benefit for Gold
              and Platinum tiers and may also be purchased with HS Points.
              Boosting is on a first come first served basis. When two users
              purchase a Boost in the same city / region, the last one to do so
              will appear top for the time period claimed.
            </p>
          </div>

          <div class="section">
            <p><b>51. Red List Countries.</b></p>
            <p>
              At this time we are unable to service users from the following
              list of countries and regions and/or any countries that are added
              to international sanctions list from time to time:
            </p>
            <p>
              AFGHANISTAN, BELARUS, BURUNDI, CENTRAL AFRICAN REPUBLIC, CHAD,
              CONGO, CRIMEA (REGION OF UKRAINE), CUBA, DEMOCRATIC REPUBLIC OF
              CONGO, DONETSK PEOPLES REPUBLIC, ERITREA, IRAQ, IRAN, LIBYA,
              LUGANSK PEOPLES REPUBLIC, MYANMAR, NORTH KOREA, SOMALIA, SOUTH
              SUDAN, SUDAN, SYRIA, RUSSIA, VENEZUELA, YEMEN
            </p>
          </div>

          <!--
			<div class="section">

				<p><b>Company information</b></p>

				<p>Company name: Holiday Swap Group Limited<br>
					Country of incorporation: England and Wales.<br>
					Registered number: 10847362<br>
					Registered office and trading address: 43 Berkeley Square, London, United
					Kingdom, W1J 5FJ</p>

				<p>Other contact information: See our
					website. <a href="http://www.holidayswap.com/">www.holidayswap.com</a></p>

			</div> -->

          <div class="section">
            <p class="text-center"><b>Appendix 1</b></p>

            <p>
              Cancellations can be made via email at
              <a href="mailto:support@holidayswap.com"
                >support@holidayswap.com</a
              >
              or in writing by mail at 43 Berkeley Square, London, United
              Kingdom, W1J 5FJ. Below is a model cancellation form.
            </p>

            <p>Dear Holiday Swap Group Limited:</p>

            <p>
              I/We hereby give notice that I/we cancel my/our Agreement with
              Holiday Swap. Please delete any and all personal information that
              has been stored pursuant to the Agreement with you.
            </p>

            <p>Thank you.</p>

            <p>
              Name of user/s:<br />
              Address of user/s:<br />
              Date: <br />
              User/s signature (if in writing):
            </p>
          </div>

          <div class="section">
            <p><b>Competition Terms and Conditions</b></p>

            <p>
              1. By entering the prize draw you are agreeing to these prize draw
              terms and conditions.
            </p>
            <p>2. The prize draw is being run by Holiday Swap Group Ltd</p>
          </div>

          <div class="section">
            <p><b>Eligibility to enter</b></p>

            <p>
              3. The competition is open to entrants over 18 years of age.
              (Holiday Swap staff members and family members are excluded from
              the draw).
            </p>
            <p>
              4. In entering the prize draw, you confirm that you are eligible
              to do so and eligible to claim any prize you may win.
            </p>
            <p>5. A maximum of one entry per individual is permitted.</p>
            <p>
              6. All cash and swap bookings made between 1 January 2023 to 30
              May 2023 will be entered into the prize draw.
            </p>
          </div>
          <div class="section">
            <p><b>How to enter</b></p>

            <p>
              7. The competition is open to those currently on our mailing list,
              all subscribers and/or entrants who meet the minimum requirements
              as set out on our social media posts. Entries after the final time
              and date will not be included in the draw.
            </p>
            <p>
              8. To enter any competition, simply follow the instructions on
              Instagram or our mailing list.
            </p>
            <p>
              8.1. Entrants must make a successful booking on the Holiday Swap
              app. A successful booking includes cash bookings and swap
              bookings. HS Point bookings, where users redeem HS Points to make
              a booking do not apply.
            </p>
            <p>
              8.2. Each night of the booking will count as one (1) entry into
              the competition up to a maximum of seven (7) nights.
            </p>
            <p>
              9. HolidaySwap will not accept responsibility if contact details
              provided are incomplete or inaccurate.
            </p>
          </div>

          <div class="section">
            <p><b>The prize</b></p>

            <p>10. The winners will be drawn at random.</p>
            <p>
              11. The prize is non-exchangeable, non-transferable and no cash
              alternatives will be offered. The air-miles awarded to the winner
              may only be redeemed through Aeroplan and their partner airlines
              and no other ancillary services that we may offer. There is no
              cash-out option with this prize.
            </p>
            <p>
              12. We reserve the right to substitute prizes with another prize
              of equal or higher value if circumstances beyond our control make
              it necessary to do so.
            </p>
            <p>
              13. The decision of HolidaySwap regarding any aspect of the prize
              draw is final and binding and no correspondence will be entered
              into about it.
            </p>
            <p>
              14. One winner per week will be announced over a period of five
              (5) weeks. Each winner will be awarded 100,000 Aeroplan air-miles.
              One (1) grand prize winner will be chosen on the sixth week and
              will be awarded 500,000 Aeroplan air-miles.
            </p>
            <p>
              15. The total number of air-miles to be given away will be one (1)
              million air-miles, split between 6 separate winners.
            </p>
          </div>

          <div class="section">
            <p><b>Winner announcement</b></p>

            <p>
              14. The winner will be notified via the email provided on your
              profile on the Holiday Swap app.
            </p>
            <p>
              15. HolidaySwap will attempt to contact the winner by email and
              social media (if possible) up to two times.
            </p>
            <p>
              16. If the winner does not respond to the emails notifying them of
              their win within 14 days of the second email, they will lose their
              right to the prize, and HolidaySwap reserves the right to choose
              and notify a new winner.
            </p>
          </div>

          <div class="section">
            <p><b>Receipt of the prize</b></p>

            <p>
              17. Please allow 14 days for delivery of the prize or alternative
              collection or delivery arrangements may be made through mutual
              agreement.
            </p>
          </div>

          <div class="section">
            <p><b>Data protection and publicity</b></p>
            <p>
              18. You consent to any personal information you provide in
              entering the competition being used by HolidaySwap for the
              purposes of administering the prize draw, and for those purposes
              as defined within our privacy notice.
            </p>
            <p>
              19. All personal information shall be used in accordance with
              HolidaySwap Privacy Notice. Limitation of Liability
            </p>
            <p>
              20. HolidaySwap does not accept any liability for any damage,
              loss, injury or disappointment suffered by any entrants as a
              result of either participating in the competition or being
              selected for a prize, save that HolidaySwap does not exclude its
              liability for death or personal injury as a result of its own
              negligence.
            </p>
            <p>
              21. HolidaySwap reserves the right to cancel the prize draw or
              amend these terms and conditions at any time, without prior
              notice.
            </p>
            <p>
              22. The prize draw and these terms and conditions will be governed
              by English law and any disputes will be subject to the exclusive
              jurisdiction of the courts of England and Wales.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <a class="collapsed btn">
          <span class="heading-option-style">Faq's</span
          ><i class="fa fa-angle-down" aria-hidden="true"></i>
        </a>
      </div>
    </div>
    <div id="accordion" class="faq_section">
      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360006762058-What-is-HomeExchange-"
            target="_blank"
            class="header_title"
            ><u>What is Holiday Swap?</u></a
          ><i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            Holiday Swap provides users with a platform to securely swap or host
            their homes in 185 countries from just $10 per month, per person (or
            through a subscription plan). Our innovative chat feature makes
            Holiday Swap the first truly global social media app for like-minded
            travellers and explorers. Explore with our in-app map, MyMaps, a
            user-curated guide to the world, allowing users to discover new
            destinations based on activity or discover top activities at their
            upcoming destinations
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360006762058-What-is-HomeExchange-"
            target="_blank"
            class="header_title"
            ><u>What are the advantages of Holiday Swap?</u></a
          ><i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <ul>
            <li>Swap vacation homes from $10 per month.</li>
            <li>Connect to millions of people worldwide.</li>
            <li>There are properties in 185+ countries.</li>
            <li>Make friends for life.</li>
            <li>Excellent customer service</li>
          </ul>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360007700358-Tips-to-find-an-exchange"
            target="_blank"
            class="header_title"
            ><u>What are the tips to find an exchange?</u></a
          ><i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <ul>
            <li>
              <p>
                Members who are looking for a swap prefer to take their time in
                finding the right home and swap partner for them. Finding an
                exchange requires time and personal investment. Once you send a
                request, members will often first take a glance at your home to
                determine whether they will be engaged in a mutual exchange.
                Also, check out your profile! This is why it is critical that
                you have your account and listing completed completely. Finally,
                unlike instant booking platforms, they are not required to say
                "yes" to every request they receive.
              </p>
            </li>
            <li>
              <p>
                We always advise our members to send as many personalised
                messages as possible. Start a conversation with the people you
                are traveling with, and mention what you like about the place.
              </p>
            </li>
            <li>
              <p>
                We recommend that you contact all of the members whose homes
                meet your needs in your desired location. The number of requests
                required to find an exchange varies depending on the destination
                (for example, New York and London are our most top attractions)
                and the time frame.
              </p>
            </li>
            <li>
              <p>
                It is also critical to make effective usage of the search ﬁlter.
                You can enter your specific dates and the number of bedrooms
                required using the filters. You can also limit your search to
                only reciprocal or non-reciprocal exchanges, as well as the
                amenities that are essential to you. This will aid in filtering
                the search results to display the homes that best meet your
                requirements, increasing your chances of locating the ideal
                exchange for you.
              </p>
            </li>
            <li>
              <p>
                Remember to consult the map! To see a larger area, you can zoom
                in or out of the map. For instance, if there aren't many options
                in your preferred destination, you may use the map to look
                around.
              </p>
            </li>
            <li>
              <p>
                Bear in mind that new opportunities can emerge at any time:)
              </p>
            </li>
            <li>
              <p>
                Check our article to find more tips for your next trip
                <a
                  href="https://blog.holidayswap.com/home-swap-tips-you-should-try-house-exchange-to-change/"
                  target="_blank"
                  ><u
                    >Home Swap Tips You Should Try House Exchange to Change!</u
                  ></a
                >
              </p>
            </li>
          </ul>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/4417099102993-How-do-I-use-it-"
            target="_blank"
            class="header_title"
            ><u>How do I use it?</u></a
          ><i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            Download the app to experience a new way to travel. With the click
            of a button, you can meet swappers and form long-lasting friendships
            with people from all over the world. We provide a variety of options
            for people of various backgrounds to swap homes. Swap your house
            with that of another swapper. Host your space or room.
          </p>
          <p></p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a class="header_title t-none">How to house swap for vacation?</a
          ><i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            It is easy to swap homes for your next vacation with Holiday Swap.
            You only need to sign up for
            <a href="https://app.holidayswap.com/" target="_blank"
              ><u>Holiday Swap</u></a
            >, upload your house photos, and start to search for the location
            where you will travel. Don’t worry! After you sign in, we will show
            you the steps you will take.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a class="header_title t-none">Swap holiday meaning?</a
          ><i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <ul>
            <li>
              <p>
                A house swap, also known as a home swap, is a temporary exchange
                of residences. It allows two people or families to experience a
                shift of scenery with all of the amenities of home and none of
                the costs and fees associated with a hotel.
              </p>
            </li>
            <li>
              <p>
                House swaps are typically used for holiday stays, but these
                short-term arrangements may be a viable option for many people
                who need places to stay away from home.
              </p>
            </li>
          </ul>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a class="header_title t-none">How does Holiday Swap work?</a
          ><i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <ul>
            <li>
              <p>Make your listing (it's free!) and complete your profile.</p>
            </li>
            <li>
              <p>
                Look for homes in interesting locations and send swap requests.
              </p>
            </li>
            <li><p>Join now to complete your swap!</p></li>
          </ul>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a class="header_title t-none">Is Holiday Swap legit?</a
          ><i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <ul>
            <li>
              <p>
                Holiday Swap is a safe social community of like-minded
                travellers. All your personal information on our platform is
                protected by us, and all your payments go through our secure
                payment provider, Stripe. Keeping your account safe is our top
                priority. We encourage users to use our chat to get to know each
                other and make lasting friendships around the world. Our chat
                instantly translates languages so that you can easily speak to
                anyone worldwide. Get to know who you are swapping with so that
                you feel more comfortable. Add them on social media!
              </p>
            </li>
            <li>
              <p>
                Before your trip, users can subscribe to our Guarantee for
                Property Damage and Trip Cancellation to add extra protection.
                Take a look at our Guarantee terms to discover more.
              </p>
            </li>
            <li>
              <p>
                Holiday Swap is an inclusive marketplace committed to
                accessibility, tolerance, respect and ensuring all our users
                have equal protections and opportunities. We welcome users into
                our Holiday Swap community, regardless of background.
              </p>
            </li>
          </ul>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a class="header_title t-none"
            >Can I swap my house if I stay in a rental house?</a
          ><i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            Yes, you can swap even if your house is rental. It is up to you as
            long as your host agrees to this.
          </p>
          <p></p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a class="header_title t-none"
            >I don’t own a house, can I still use Holiday Swap?</a
          ><i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            Yes, you can still use Holiday Swap. Share your space and start to
            swap!
          </p>
          <p></p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a class="header_title t-none">Is it free to swap?</a
          ><i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            You can use free version of Holiday Swap which is Lite Access. But
            if you want to access premium features you can travel the world only
            for $10/ month without paying extra to hotels and Airbnb.
          </p>
          <p></p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a class="header_title t-none"
            >What happens if someone breaks/destroys/steals anything from my
            house during their stay?</a
          ><i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            Please subscribe to our Guarantee for Property Damage. This will
            cover any approved losses stated in our Guarantee Terms.
          </p>
          <p></p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a class="header_title t-none"
            >How do I get the keys to the house I am going to stay in?</a
          ><i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>Message your host for key exchange details.</p>
          <p></p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a class="header_title t-none"
            >Is HS worldwide? Does it work in my country?</a
          ><i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            We are a home exchange platform available in +185 countries, with
            over 2 Million users. We are almost everywhere. It works in all
            countries around the world. If you have any further questions please
            feel free to contact
            <a href="mailto:marketing@holidayswap.com" target="_blank"
              ><u>marketing@holidayswap.com</u></a
            >
          </p>
          <p></p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a class="header_title t-none">Can I cash out my HS Points?</a
          ><i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>No, you cannot.</p>
        </div>
      </div>

      <h4>Bookings :</h4>
      <div class="card">
        <div class="card-header">
          <a class="header_title t-none">GUEST:</a
          ><i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <ul>
            <li>
              <p>
                Guest subscribes to either premium plan ($100 annual) and all
                booking fees get waived - this is unlimited for the tenure of
                subscription. Guest will then only pay the nightly rate as set
                by the host.
              </p>
            </li>
            <li>
              <p>
                Casual Guest chooses not to subscribe but continues to make a
                booking, $10USD per night booking will apply on top of his
                overall booking charge. So when he arrives at checkout for a 2
                night stay at $50 per night, his bill would appear as $100 + $20
                (2x nights booking fee) = $120.
              </p>
            </li>
          </ul>

          <p></p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a class="header_title t-none">HOST:</a
          ><i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <ul>
            <li>
              <p>
                Premium Host who has subscribed to $100 annual plan only, will
                pay a 6% commission on the total cost of all completed bookings.
                72 hours after arrival monies can be paid to host. If the
                guest’s booking for 2 nights costs $100 in total, host receives
                $94 after 3 days.
              </p>
            </li>
            <li>
              <p>
                Casual Host who does not want to pay for premium, will instead
                pay the 9% commission fee. So this host will receive $91, for
                the same 2 night booking at $100.
              </p>
            </li>
          </ul>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a class="header_title t-none"
            >What is the difference between HS and Airbnb?</a
          ><i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            There's no denying that peer-to-peer accommodation models like
            Airbnb, VRBO, and similar sites have popularised staying in a
            stranger's home. The main difference between those stays and a home
            swap is cost: the cost of the stay, the cost of cleaning, and, in
            many cases, a booking fee for each visit. Hotel and resort stays can
            be less expensive, but even those can have what the travel industry
            refers to as "drip pricing," which means that the posted rate for a
            stay is discounted and appealing, but the final bill is dotted with
            add-ons. Furthermore, as Airbnb properties become more corporately
            owned, the personal connection, as well as the connection to a
            lifestyle, is lost. And these hosts can impose stricter rules on
            entertaining additional guests, provide child-friendly options, and
            consider additional factors as Market Watch mentioned in
            <a
              href="https://www.marketwatch.com/story/try-a-home-swap-youll-skip-airbnb-and-hotel-costs-and-vacation-like-youre-a-local-11658331462"
              target="_bl
					"
              ><u
                >Try a home swap. You’ll skip Airbnb and hotel costs and
                vacation like you’re a local</u
              ></a
            >
            article.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a class="header_title t-none"
            >Do I need to make the swap happen at the same time with the person
            I am swapping my house with?</a
          ><i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            You can also book houses that you like. We have both book and swap
            options available. You can allow someone to book your space using HS
            Points, which you can redeem for your next trip.
          </p>
          <p></p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a class="header_title t-none"
            >Can I still swap my house if I live in the house?</a
          ><i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>You can swap the room you are using in the house.</p>
          <p></p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a class="header_title t-none"
            >Can I swap a 1 room studio for a 4 bedroom house?</a
          ><i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            Of course! Remember who you search for and swap with is completely
            up to you!
          </p>
          <p></p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a class="header_title t-none">Do I have to swap a whole property?</a
          ><i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            No. What makes us unique is your ability to be flexible about what
            you swap. Sure, you can swap an entire house or apartment, but you
            can just as easily swap a shared bedroom in a house (assuming
            acknowledgement of potential housemates and their permission). This
            allows much more flexibility and variation of what can be swapped,
            providing an experience tailored to your needs.
          </p>
          <p></p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360015490897-Ambassadors-who-are-they-and-can-I-become-one-"
            target="_blank"
            class="header_title"
            ><u>Ambassadors - who are they and can I become one?</u></a
          ><i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <ul>
            <li>
              <p>
                We would like to invite you to become a brand ambassador for our
                company!
              </p>
            </li>
            <li>
              <p>
                As a brand ambassador, you will help us spread the word about
                Holiday Swap with your audience through the following actions:
              </p>
            </li>
            <li>
              <p>
                Creating an account with Holiday Swap and listing your home.
              </p>
            </li>
            <li>
              <p>
                Sharing Instagram stories highlighting how your audience can
                save while traveling with Holiday Swap.
              </p>
            </li>
            <li>
              <p>
                Participating in arranged home exchanges with other influencers.
              </p>
            </li>
            <li>
              <p>
                Creating travel content for us that we can share with our
                audience.
              </p>
            </li>
          </ul>
          <p></p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a class="header_title t-none">What you’ll get in return:</a
          ><i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <ul>
            <li>
              This is a paid partnership, payment will be established based on
              your profile and account insights.
            </li>
            <p>
              If you’re interested, I’d love to chat more with you to go over
              what our brand ambassador program is all about so please
              <a href="https://form.jotform.com/222724285117050"
                ><u>click here</u></a
              >
              and fill out this form.
            </p>
          </ul>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a class="header_title t-none"
            >Is home swapping safe and how am I protected?</a
          ><i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <ul>
            <li>
              <p>
                Holiday Swap is a safe social community of like-minded
                travellers. All your personal information on our platform is
                protected by us, and all your payments go through our secure
                payment provider, Stripe. Keeping your account safe is our top
                priority.
              </p>
            </li>
            <li>
              <p>
                We encourage users to use our chat to get to know each other and
                make lasting friendships around the world. Our chat instantly
                translates languages so that you can easily speak to anyone
                worldwide. Get to know who you are swapping with so that you
                feel more comfortable. Add them on social media!
              </p>
            </li>
            <li>
              <p>
                Before your trip, users can subscribe to our Guarantee for
                Property Damage and Trip Cancellation to add extra protection.
                Take a look at our Guarantee terms to discover more.
              </p>
            </li>
            <li>
              <p>
                Holiday Swap is an inclusive marketplace committed to
                accessibility, tolerance, respect and ensuring all our users
                have equal protections and opportunities. We welcome users into
                our Holiday Swap community, regardless of background.
              </p>
            </li>
          </ul>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a class="header_title t-none">Do you store my credit card details?</a
          ><i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            No. We do not store credit card details nor do we share financial
            details with any third-parties. Our secure payment provider is
            Stripe.
          </p>
        </div>
      </div>

      <h4>Do I have to put pictures of my property on Holiday Swap?</h4>
      <div class="card">
        <div class="card-header">
          <a class="header_title t-none"
            >What if the property is not as described?</a
          ><i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            If the property is not as described when you arrive, make sure to
            take pictures of the problems and send our Support Team a detailed
            description of your experience
            <a href="mailto:support@holidayswap.com" target="_blank"
              ><u
                >support@holidayswap.com Please make sure to send all evidence
                within 7 days of the swap ending. We encourage all our users to
                subscribe to our Guarantee and take out the appropriate and
                relevant insurance coverage on their own.</u
              ></a
            >
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a class="header_title t-none">
            What if there is damage when I return?</a
          ><i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            Please subscribe to our Guarantee for Property Damage. This will
            cover any approved losses stated in our Guarantee Terms.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a class="header_title t-none">
            How do I gain access to the property?</a
          ><i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            Before you finalise your swap on the App, you will be asked to
            choose your method of key collection, whether that is leaving your
            key in a safe place, providing a security code, sending your key to
            the other user before the swap or using KeyCafe. We recommend using
            our preferred shipping choice DHL for shipping.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a class="header_title t-none"> What if I can’t enter the property?</a
          ><i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            Speak to your Host before you leave to ensure you have all the
            relevant information to enter the property on your arrival. Be sure
            to exchange numbers too in case there are any problems! We advise
            users to provide a local emergency contact in case a Guest is locked
            out or cannot enter the property.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a class="header_title t-none">
            Someone has chatted with me and ask for my address, and swap without
            going through Holiday Swap?</a
          ><i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            No. It is against our Terms and Conditions to plan a swap outside of
            the App. Not only will this breach your agreement with us, but it
            may be less secure for you.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a class="header_title t-none">
            How long it will take to hear back from customer service?</a
          ><i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            We strive to reply to you within 5 business days. In the case of
            urgent issues, you should first contact the other party. Please
            contact us at
            <a href="mailto:support@holidayswap.com" target="_blank"
              ><u>support@holidayswap.com</u></a
            >.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a class="header_title t-none">How to query a negative rating?</a
          ><i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            Sometimes, for reasons out of your control, you may receive ratings
            that you disagree with. Whilst we completely support an open and
            free ratings system, we want this to also be fair and genuine. If
            you think a rating is malicious, goes against our guidelines, or
            otherwise fabricated, then please get in touch with our Support Team
            and we will take a look into any concerns, and take action where
            necessary.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a class="header_title t-none"
            >What should I do in the event of the emergency or the problem
            related to property?</a
          ><i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            In case of an emergency at the property, please contact local
            emergency services and authorities right away. You should always
            ensure you have emergency contact details, and the relevant
            insurance for both yourself and the property. Any external emergency
            situation will not be Holiday Swap’s responsibility and you should
            proceed through appropriate channels dependent on any emergency you
            may have. Make sure to subscribe to our Guarantee for Property
            Damage and Trip Cancellation to help protect your trip!
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360008573837-What-do-I-do-if-the-website-is-not-displayed-correctly-"
            target="_blank"
            class="header_title"
            ><u>What do I do if the website is not displayed correctly?</u></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            Immediately contact with our support team. You can send email to
            <a href="mailto:info@holidayswap.com" target="_blank"
              ><u>support @holidayswap.com</u></a
            >
            or
            <a href="https://www.instagram.com/holidayswap/" target="_blank"
              ><u>dm</u></a
            >
            us on Instagram.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360000631217-I-have-found-an-error-on-the-website-what-should-I-do-"
            target="_blank"
            class="header_title"
            ><u>I have found an error on the website, what should I do?</u></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            Immediately contact with our support team. You can send email to
            <a href="mailto:info@holidayswap.com" target="_blank"
              ><u>support @holidayswap.com</u></a
            >
            or
            <a href="https://www.instagram.com/holidayswap/" target="_blank"
              ><u>dm</u></a
            >
            us on Instagram.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360000631237-What-are-GuestPoints-"
            target="_blank"
            class="header_title"
            ><u>What are HS Points?</u></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            HS Points are the most effective way to fully unlock all of the
            advantages of Holiday Swap. Why spend money to stay once your HS
            Points will be enough? Simply completing your profile earns you 350
            HS Points, which is equivalent to $350, that you can happily spend
            on your upcoming vacation! Would you like your property to come up
            first in a search? HS Points will help you get to the top! Can't
            agree on a traditional two-way swap? Choose to use a HS Point swap
            in which you pay the host in HS Points.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a class="header_title t-none">Do HS Points expire?</a
          ><i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            HS Points are valid for 24 months (2 full years) after issuance.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360000621938-Where-can-I-check-how-many-GuestPoints-I-have-"
            target="_blank"
            class="header_title"
            ><u>Where can I check how many HS Point I have?</u></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            Click on your profile, and under the edit profile button, you will
            see “My HS Points”.
          </p>
          <img
            src="/assets/images/Faq-Where-Can_I-Check-How-Many-Tokens-I-Have.jpg"
            alt="faq-tokens"
          />
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360000619457-What-happens-to-my-GuestPoints-when-my-host-guest-cancels-"
            target="_blank"
            class="header_title"
            ><u>What happens to my HS Points when my host/guest cancels?</u></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <ul>
            <li>
              <p>
                If you are a guest, your HS Points will be automatically
                refunded to your account once your host cancels the exchange.
                Our team will always contact you following a cancellation by
                your host, but if you require immediate assistance with the
                cancellation of your swap, please feel free to contact us.
              </p>
            </li>
            <li>
              <p>
                If you cancel as the host, HS Points for that swap will be
                returned to your guest automatically. The support team will
                always inform you to find out why you had to cancel.
              </p>
            </li>
          </ul>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360000619457-What-happens-to-my-GuestPoints-when-my-host-guest-cancels-"
            target="_blank"
            class="header_title"
            ><u>What is the value of my home in HS Points ?</u></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>$1 = 1 HS Point in our app.</p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a class="header_title t-none"
            >What are the benefits of owning HS Points?</a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <ul>
            <li>
              <p>
                Simply, HS Points enable our users to travel the world for much
                less.That is why influencers and celebrities are currently
                flooding in to buy token bundles on our app.
              </p>
            </li>
            <li>
              <p>
                Currently, users can buy HS Points on Holiday Swap at a 20%
                discount.
              </p>
            </li>
            <li>
              <p>
                For instance, normally 100 HS Points would equal $100 however,
                our users can currently buy 100 HS Points for $80 on our app.
              </p>
            </li>
            <li>
              <p>
                We are aiming to allow a cash-out of HS Points in the near
                future for a much higher value.
              </p>
            </li>
          </ul>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360000631317-How-do-I-earn-GuestPoints-"
            target="_blank"
            class="header_title"
            ><u>How do I earn HS Points?</u></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            HS Points are the in-app currency of Holiday Swap and 1 token is
            equal to $1. Earn HS Points on our app by completing various actions
            on your profile’s progress tracker or by hosting other travelers.
            The HS Points earned can be used to book any home around the world
            on HolidaySwap.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360000621998-What-can-I-do-if-I-do-not-have-enough-GuestPoints-to-finalize-an-exchange-"
            target="_blank"
            class="header_title"
            ><u
              >What can I do if I do not have enough HS Points to finalize an
              exchange?</u
            ></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            If your HS Points are not enough to finalise an exchange, you can
            either purchase new HS Points or check your progress chart, take the
            necessary steps, and earn HS Points for free.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360000622018-Can-I-purchase-GuestPoints-"
            target="_blank"
            class="header_title"
            ><u>Can I purchase HS Points?</u></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            If you do not want to host or swap homes you can directly buy HS
            Points on our app and book homes on HolidaySwap.
          </p>
          <img
            src="/assets/images/Faq-Can-I-Purchase-Tokens.jpg"
            alt="Faq-Can-I-Purchase-Tokens"
          />
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360019417737-How-to-reset-my-password-"
            target="_blank"
            class="header_title"
            ><u>How to reset my password?</u></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>You can reset your password in settings on the app.</p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360013803358-Can-my-partner-have-a-separate-HomeExchange-account-"
            target="_blank"
            class="header_title"
            ><u>Can my partner have a separate Holiday Swap account?</u></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>Yes, you can. Also you may have multiple homes per account.</p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360000602038-How-do-I-sign-up-on-HomeExchange-"
            target="_blank"
            class="header_title"
            ><u>How do I sign up on Holiday Swap?</u></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            Download our free app from
            <a href="https://app.holidayswap.com/" target="_blank"
              ><u>the app stores</u></a
            >
            and register your account in seconds!
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360000611697-Can-I-add-another-email-address-for-my-HomeExchange-account-"
            target="_blank"
            class="header_title"
            ><u
              >Can I add another email address for my Holiday Swap account?</u
            ></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>No.</p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360015491597-What-if-I-moved-from-the-home-that-is-listed-on-HomeExchange-"
            target="_blank"
            class="header_title"
            ><u
              >What if I moved from the home that is listed on Holiday Swap?</u
            ></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            You should keep your account and profile updated every time you move
            homes.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360007384077-What-do-I-do-if-I-have-issues-adding-my-address-"
            target="_blank"
            class="header_title"
            ><u>What do I do if I have issues adding my address?</u></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            We have a dedicated customer support team. Please contact
            <a href="mailto:support@holidayswap.com" target="_blank"
              ><u>support@holidayswap.com</u></a
            >
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360000613078-How-do-I-edit-my-listing-and-complete-it-to-100-"
            target="_blank"
            class="header_title"
            ><u>How do I edit my listing and complete it to 100%?</u></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            Go to Profile, select your home and click Edit to make changes to
            your home listing and complete it.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360000730577-How-are-the-search-results-ordered-and-how-can-I-appear-higher-"
            target="_blank"
            class="header_title"
            ><u
              >How are the search results ordered and how can I appear
              higher?</u
            ></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            The most active users get preference to appear higher in search
            results and least active users tend to appear further back in
            search. Coming soon we will be introduce our Boost add-on which
            allows you to appear top of search results for a small fee.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360000623757-How-do-I-find-an-exchange-"
            target="_blank"
            class="header_title"
            ><u>How do I find a swaps?</u></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <ul>
            <li>
              <p>
                After you've uploaded your home, you can begin looking for a
                swap in your favourite destinations.
              </p>
            </li>
            <li>
              <p>
                Now, it is time to find a house for your next trip. First, click
                on “Explore” and type your desired destination to search box.
              </p>
            </li>
          </ul>

          <img
            src="/assets/images/Faq-How-Do-I-Find-A-Swaps.jpg"
            alt="Faq-How-Do-I-Find-A-Swaps"
          />
          <p>
            Enter your destination, dates for your trip, the number of guests
            that will be accommodated, and lastly, select a rating for your
            possible host. Start to search!
          </p>
          <div>
            <img
              src="/assets/images/Faq-How-Do-I-Find-A-Swaps-1.jpg"
              alt="Faq-How-Do-I-Find-A-Swaps-1"
            />
            <img
              src="/assets/images/Faq-How-Do-I-Find-A-Swaps-2.jpg"
              alt="Faq-How-Do-I-Find-A-Swaps-2"
            />
          </div>

          <p>
            You can view the full listing description by clicking on any of the
            results. The space description will be opened in a new tab/page,
            eliminating the need to return to the search and relaunch it, as the
            search page will stay open in a separate tab/page.
          </p>

          <img
            src="/assets/images/Faq-How-Do-I-Find-A-Swaps-3.jpg"
            alt="Faq-How-Do-I-Find-A-Swaps-3"
          />

          <p>Now, you are ready for your next vacation!</p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360015818937-How-can-I-discuss-the-details-of-my-exchange-with-my-partner-"
            target="_blank"
            class="header_title"
            ><u
              >How can I discuss the details of my exchange with my partner?</u
            ></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            It is critical to discuss the details of the swap with your swap
            partner. Before finalising the swap, you must ask all of your
            questions and provide all relevant information about your home or
            stay to ensure that you are both on the exact same page.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360009466098-Can-hosts-ask-for-extra-fees-"
            target="_blank"
            class="header_title"
            ><u>Can hosts ask for extra fees?</u></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            No, they cannot ask for extra fees. If you face with this issue,
            please contact with our support team:
            <a href="mailto:info@holidayswap.com"
              ><u>support@holidayswap.com</u></a
            >
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360008777778-How-can-I-be-a-great-guest-"
            target="_blank"
            class="header_title"
            ><u>How can I be a great guest?</u></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <ul>
            <li>
              <p>
                Congratulations! You have completed a transaction as a guest.
                Here are our best tips for preparing for your swap and ensuring
                that both you and your swap partner have always had the best
                experience possible:)
              </p>
            </li>
            <li>Communication is essential!</li>
            <p>
              Before your swap, do not be hesitant to ask your host each and
              every questions you may have and to reach an agreement on certain
              details: When is the most convenient time to arrive? When and
              where will you pick up the keys, and from where will you return
              them? Where can you get cleaning supplies? Do you have any special
              guidelines for a particular household appliance? What should you
              do with your old sheets or towels once you leave the house?
            </p>
            <li>Respect your host's residence.</li>
            <p>
              Remember the most important thing in Holiday Swap: treat your
              hosts' home as you would want them to treat yours, and follow
              their instructions:)
            </p>
            <li>
              Leave your host's house in the same condition you found it (if not
              better!).
            </li>
            <p>
              Before leaving, give the house a thorough cleaning, air out the
              room, and leave the sheets and towels in the laundry basket, as
              agreed upon with your hosts. Take out the trash and double-check
              that there aren't any more dishes or glasses to be washed.
            </p>
            <li>
              Also, if you notice a problem or accidentally break something,
              please notify your hosts immediately!
            </li>
          </ul>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/4414688183313-How-much-does-the-membership-cost-"
            target="_blank"
            class="header_title"
            ><u>How much does the membership cost?</u></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            We only charge users from $10 per month, per person to swap or be a
            guest in another user’s home! We also offer various subscription
            plans that allow you to have unlimited free swaps with a guest for
            the term of your subscription!
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360000619397-What-is-included-in-the-HomeExchange-Membership-"
            target="_blank"
            class="header_title"
            ><u>What is included in the Holiday Swap Membership?</u></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <ul>
            <li><p>Everything on Holiday Swap for one low price</p></li>
            <li><p>Unlimited swaps on all your devices</p></li>
            <li><p>No commitments, cancel anytime</p></li>
            <li><p>Don’t pay to stay when travelling</p></li>
            <li><p>20% discount on token bookings</p></li>
            <li><p>Risk free</p></li>
          </ul>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360000610118-What-are-the-advantages-of-the-membership-"
            target="_blank"
            class="header_title"
            ><u>What are the advantages of the membership?</u></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <ul>
            <li><p>Everything on Holiday Swap for one low price</p></li>
            <li><p>Unlimited swaps on all your devices</p></li>
            <li><p>No commitments, cancel anytime</p></li>
            <li><p>Don’t pay to stay when travelling</p></li>
            <li><p>20% discount on token bookings</p></li>
            <li><p>Property damage protection</p></li>
          </ul>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360002868058-Do-I-need-to-take-the-membership-to-host-"
            target="_blank"
            class="header_title"
            ><u>Do I need to take the membership to host?</u></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            You can use the Lite version of the membership to host but still, we
            need to take your information to protect all of our users' safety
            and security.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360009313798-How-do-I-use-a-promo-code-"
            target="_blank"
            class="header_title"
            ><u>How do I use a referral code?</u></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            You can click invite friends button at the en of your profile page.
            You can share your link. If your friends register to Holiday Swap,
            both of you will earn HS Points and surprise gifts.
          </p>
          <img
            src="/assets/images/Faq-How-Do-I-Use-A-Referral-Code.jpg"
            alt="Faq-How-Do-I-Use-A-Referral-Code"
          />
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360000631337-How-can-I-change-my-payment-method-"
            target="_blank"
            class="header_title"
            ><u>How can I change my payment method?</u></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            To update your payment details, click on three lines in the top left
            corner.
          </p>
          <img
            src="/assets/images/Faq-How-Can-I-Change-My-Payment-Method.jpg"
            alt="Faq-How-Can-I-Change-My-Payment-Method"
          />
          <p>Click on “Payment Cards”.</p>
          <img
            src="/assets/images/Faq-How-Can-I-Change-My-Payment-Method-1.jpg"
            alt="Faq-How-Can-I-Change-My-Payment-Method-1"
          />
          <p>Click on add new card.</p>
          <p>Click on “Payment Cards”.</p>
          <img
            src="/assets/images/Faq-How-Can-I-Change-My-Payment-Method-2.jpg"
            alt="Faq-How-Can-I-Change-My-Payment-Method-2"
          />
          <p>Lastly, enter your credit card information.</p>
          <p>Click on add new card.</p>
          <p>Click on “Payment Cards”.</p>
          <img
            src="/assets/images/Faq-How-Can-I-Change-My-Payment-Method-3.jpg"
            alt="Faq-How-Can-I-Change-My-Payment-Method-3"
          />
          <p>You can see your saved cards in the top right corner.</p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360011893118-Can-I-finalize-an-exchange-for-a-friend-extended-family-member-using-my-account-"
            target="_blank"
            class="header_title"
            ><u
              >Can I finalize an exchange for a friend/extended family member
              using my account?</u
            ></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            It’s advised to book and swap only on your behalf through your own
            account. Bookings/arrangements made for other people may not be
            covered by Terms and Guarantee.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360000623857-How-and-why-should-I-report-a-member-"
            target="_blank"
            class="header_title"
            ><u>How and why should I report a member?</u></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            If they are asking for your bank account information, payment
            outside the Holiday Swap app, detailed personal information.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360003228118-How-can-I-be-sure-this-email-is-from-HomeExchange-"
            target="_blank"
            class="header_title"
            ><u>How can I be sure this email is from Holiday Swap?</u></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            If you are not sure if the e-mail you received came from Holiday
            Swap, send the email to
            <a href="mailto:support@holidayswap.com"
              ><u>support@holidayswap.com</u></a
            >
            forward it to our e-mail address.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360003250197-What-do-I-do-if-a-member-asks-me-to-pay-outside-of-the-HomeExchange-platform-"
            target="_blank"
            class="header_title"
            ><u
              >What do I do if a member asks me to pay outside of the Holiday
              Swap platform?</u
            ></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            You should not accept payments outside the Holiday Swap app for your
            safety.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360000621798-What-do-I-do-with-my-valuables-during-an-exchange-"
            target="_blank"
            class="header_title"
            ><u>What do I do with my valuables during an swap?</u></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <ul>
            <li>
              <p>Locking up your valuables is a great way to keep them safe.</p>
            </li>
            <li>
              <p>
                If you have basement, an office, or a store room that can be
                locked, it is best to keep your belongings there for your peace
                of mind.
              </p>
            </li>
            <li>
              <p>
                You may choose to keep small valuables on your person, such as
                jewelry.
              </p>
            </li>
            <li>
              <p>
                The biggest risk with bigger valuable items that are difficult
                to transport is that your guests will break them. You can tell
                your guests ahead of time not to touch or use them, or you can
                put a "Do not touch" note or wrap them with a blanket.
              </p>
            </li>
          </ul>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360000611557-What-is-the-verification-process-"
            target="_blank"
            class="header_title"
            ><u>What is the verification process?</u></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            You will be asked to verify your email and phone number for your own
            account security and so that we may contact you regarding your
            bookings.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360015895478-What-are-considered-violations-"
            target="_blank"
            class="header_title"
            ><u>What are considered violations in Holiday Swap?</u></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            Please refer to the Acceptable Use policy located in our Terms and
            Conditions policy on the app. This can be found in the Help section.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a class="header_title t-none">
            Is my data and personal information safe?</a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            Yes. We never store credit card information, and your address and
            personal information is secure. Please review our Privacy Policy for
            more information.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a class="header_title t-none"> Will my chats be private?</a>
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            With the exception of our Support Team, your chats will be private
            between yourself and the party you are chatting to or proposing to
            swap with. We will not sell or offer the information in private
            chats to third parties. Our Support Team will have sole access to
            user data and chat history in the scenario of a dispute between
            users or the breaching of Holiday Swap’s Terms and Conditions.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360000635157-What-do-I-do-in-case-of-damage-"
            target="_blank"
            class="header_title"
            ><u>What do I do in case of damage?</u></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            You represent and warrant to us to take reasonable care to secure
            your valuables and obtain relevant insurance. The Company is not
            responsible for any damage, loss or theft during your trip.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360000619497-How-does-the-deposit-process-work-"
            target="_blank"
            class="header_title"
            ><u>How does the deposit process work?</u></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            See our Terms and Conditions to learn more. Users are strongly
            encouraged to subscribe to our Guarantee for Trip Cancellation.
            Please refer to our Guarantee Terms.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360000632777-How-is-the-deposit-calculated-Can-I-modify-it-"
            target="_blank"
            class="header_title"
            ><u>How is the deposit calculated? Can I modify it?</u></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            See our Terms and Conditions to learn more. Users are strongly
            encouraged to subscribe to our Guarantee for Trip Cancellation.
            Please refer to our Guarantee Terms.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360000623618-Is-the-deposit-directly-debited-from-my-account-"
            target="_blank"
            class="header_title"
            ><u>Is the deposit directly debited from my account?</u></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            See our Terms and Conditions to learn more. Users are strongly
            encouraged to subscribe to our Guarantee for Trip Cancellation.
            Please refer to our Guarantee Terms.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360000635217-How-can-I-recover-my-deposit-"
            target="_blank"
            class="header_title"
            ><u>How can I recover my deposit?</u></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            See our Terms and Conditions to learn more. Users are strongly
            encouraged to subscribe to our Guarantee for Trip Cancellation.
            Please refer to our Guarantee Terms.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360000626478-What-happens-in-the-case-of-damages-or-disagreements-"
            target="_blank"
            class="header_title"
            ><u>What happens in the case of damages or disagreements?</u></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            See our Terms and Conditions to learn more. Users are strongly
            encouraged to subscribe to our Guarantee for Trip Cancellation.
            Please refer to our Guarantee Terms.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360000626378-Guest-the-home-does-not-match-the-information-from-the-listing-"
            target="_blank"
            class="header_title"
            ><u
              >Guest: the home does not match the information from the
              listing?</u
            ></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            Submit a claim for reimbursement under the Guarantee (a “Claim”),
            Subscriber must submit a Claim to
            <a href="mailto:support@holidayswap.com" target="_blank"
              ><u>support@holidayswap.com</u></a
            >
            and provide evidence of their claim.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360000635177-What-to-do-in-case-of-theft-"
            target="_blank"
            class="header_title"
            ><u>What to do in case of theft?</u></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            Report any incidences of theft to
            <a href="mailto:support@holidayswap.com" target="_blank"
              ><u>support@holidayswap.com</u></a
            >
            and with any photographic evidence of the items within 7 days of the
            trip.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360008842717-How-can-I-cancel-an-exchange-as-a-guest-host-"
            target="_blank"
            class="header_title"
            ><u>How can I cancel a swap as a guest / host?</u></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            A user may cancel a swap, but if they do so within 7 days, they
            forfeit any service fees paid. We reserve the right to make the
            Property unavailable or blocked for the dates of the canceled
            booking if the User cancels without a valid reason. In addition to
            any of the extenuating circumstances listed below, a genuine and
            legitimate concern about the Impacted User's behaviour will be
            considered a valid reason. For more information, please see our
            Terms and Conditions. Users are strongly encouraged to subscribe to
            our Trip Cancellation Guarantee. Please see our Guarantee Terms for
            more information.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360000619437-What-happens-when-I-am-a-guest-and-I-have-to-cancel-an-exchange-"
            target="_blank"
            class="header_title"
            ><u
              >What happens when I am a guest and I have to cancel a swap?</u
            ></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            A user may cancel a swap, but if they do so within 7 days, they
            forfeit any service fees paid. We reserve the right to make the
            Property unavailable or blocked for the dates of the canceled
            booking if the User cancels without a valid reason. In addition to
            any of the extenuating circumstances listed below, a genuine and
            legitimate concern about the Impacted User's behaviour will be
            considered a valid reason. For more information, please see our
            Terms and Conditions. Users are strongly encouraged to subscribe to
            our Trip Cancellation Guarantee. Please see our Guarantee Terms for
            more information.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360000619437-What-happens-when-I-am-a-guest-and-I-have-to-cancel-an-exchange-"
            target="_blank"
            class="header_title"
            ><u>What happens if my host cancels?</u></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            You can contact
            <a href="mailto:support@holidayswap.com" target="_blank"
              ><u>support@holidayswap.com</u></a
            >
            to make a claim.
          </p>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <a
            href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360000619477-In-case-of-cancellation-can-I-get-a-refund-for-any-payments-I-made-to-HomeExchange-"
            target="_blank"
            class="header_title"
            ><u
              >In case of cancellation, can I get a refund for any payments I
              made to Holiday Swap?</u
            ></a
          >
          <i class="fa fa-angle-right toggle-icon-style"></i>
        </div>
        <div class="card-body accordion_body" style="display: none">
          <p>
            You need to provide true reasons for cancellations and proof of
            reasons. After the subscriber has properly and fully submitted a
            Claim, including all documentation and information, we will
            determine, in our sole discretion, if the Subscriber has suffered an
            Approved Loss. If we determine that Subscriber has suffered an
            Approved Loss, we will accept the Claim, make reasonable efforts to
            notify Subscriber via the email associated with Subscriber’s account
            or otherwise through the App of our acceptance of the Claim within
            forty-eight (48) hours of such acceptance.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
