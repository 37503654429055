<div class="introWrapper">
  <div class="logoBox">
    <div class="intro_brand" routerLink="/">
      <img src="/assets/new-logo/app-logo-dark.svg" alt="">
     <!-- <img src="/assets/images/onboard/logo.svg"> -->
      <!-- <span class="brand_title">Holiday Swap</span> -->
    </div>
  </div>
  <div class="intro_container">
    <div class="betweenBox">
      <div class="slideWrapper">

        <swiper #swiper [config]="config">
          <ng-template swiperSlide>
            <div class="swiper_outerwarpper">
              <div class="swiper_imageBox">
                <img src="/assets/onBoardingSwiper/IMage.png" alt="">
              </div>
              <div class="swiper_textOuter">
                <h2 class="swiper_header">
                  Make new memories while <span>spending less</span>
                </h2>
                <p class="slider_text">
                  Millions of travelers, like you, looking to explore the world, make new memories and enjoy their
                  lives...the best part? They’re willing to swap homes in order to <b>save on booking costs.</b>
                </p>
              </div>


            </div>
          </ng-template>


          <ng-template swiperSlide>
            <div class="swiper_outerwarpper">
              <div class="swiper_imageBox">
                <img src="../../../../../assets/onBoardingSwiper/IMage (2).svg" alt="">
              </div>
              <div class="swiper_textOuter">
                <h2 class="swiper_header">
                  Worry less, <span>live more</span>
                </h2>
                <p class="slider_text">
                  The mantra “Mi casa es tu casa” means that there is a mutual respect and trust, we strive for that as
                  we work to give you the best experience with any stay you book, so you <b>can enjoy more of your trip.</b>

                </p>
              </div>


            </div>
          </ng-template>

          <ng-template swiperSlide>
            <div class="swiper_outerwarpper">
              <div class="swiper_imageBox">
                <img src="../../../../../assets/onBoardingSwiper/IMage (3).svg" alt="">
              </div>
              <div class="swiper_textOuter">
                <h2 class="swiper_header">
                  Enjoy <span>quick results</span> & reliable transactions
                </h2>
                <p class="slider_text">
                  Stay around the world and book homes for less using HS Points, our in-app currency!
                  Every HS Point equals $1 and the best part is that you can buy them for
                  <b>20% off for a limited time only!</b>
                </p>
              </div>


            </div>
          </ng-template>


        </swiper>

      </div>
    </div>


    <div class="intro_oval">

    </div>
    <div class="nav_button" routerLink="/auth/signup">
      <img src="/assets/onBoardingSwiper/Vector.svg" alt="">
    </div>

  </div>
</div>
