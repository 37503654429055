import { Injectable } from "@angular/core";
import { Cacheable, LocalStorageStrategy } from "ts-cacheable";
import { HttpService } from "./http.service";

@Injectable({
	providedIn: "root"
})
export class MetadataService {

	constructor(private httpService: HttpService) { }

	public getUserCategories() {
		return this.httpService.get(`/category-types/user/categories`);
	}



	public getSpaceCategories() {
		return this.httpService.get(`/category-types/space/categories`);
	}



	public getFilterCategories() {
		return this.httpService.get(`/category-types/filter/categories`);
	}


	public getSpaceNewCategories() {
		return this.httpService.get(`/category/filter`);
}

}
