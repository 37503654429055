import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "src/app/shared/shared.module";

import { SpaceDetailsComponent } from "./pages/space-details/space-details.component";
import { SpaceReviewsComponent } from "./components/space-reviews/space-reviews.component";

import { SpacesRoutingModule } from "./spaces-routing.module";
import { SpaceUnavailabilityComponent } from "./components/space-unavailability/space-unavailability.component";
import { AddHomeFormComponent } from './pages/add-home-form/add-home-form.component';
import { HomeSpaceImagesComponent } from './components/home-space-images/home-space-images.component';
import { HomeSpaceDetailsComponent } from './pages/home-space-details/home-space-details.component';
import { SpaceHomeAddressComponent } from './components/space-home-address/space-home-address.component';
import { HomeSpacesComponent } from './pages/home-spaces/home-spaces.component';
import { AddHomeAuthGuard } from "./pages/add-home.guard";
import { NewEditHomeComponentComponent } from './pages/new-edit-home-component/new-edit-home-component.component';

@NgModule({
	declarations: [
		SpaceDetailsComponent,
		SpaceReviewsComponent,
		SpaceUnavailabilityComponent,
		AddHomeFormComponent,
		HomeSpaceImagesComponent,
		HomeSpaceDetailsComponent,
		SpaceHomeAddressComponent,
    HomeSpacesComponent,
    NewEditHomeComponentComponent
	],
	imports: [
		CommonModule,
		SharedModule,
		SpacesRoutingModule
	],
	exports: [SpaceReviewsComponent, HomeSpaceImagesComponent],
	providers:[AddHomeAuthGuard]
})
export class SpacesModule { }
