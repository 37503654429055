import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExploreHomeSpacesComponent } from './pages/explore-home-spaces/explore-home-spaces.component';
import { SearchFilterListHomesComponent } from './pages/search-filter-list-homes/search-filter-list-homes.component';
import { AddSpaceLandingComponent } from './pages/add-space-landing/add-space-landing.component';
import { ExploreScreenComponent } from './pages/explore-screen/explore-screen.component';
import { PopularStayComponent } from './pages/popular-stay/popular-stay.component';
import { TrendingRegionComponent } from './pages/trending-region/trending-region.component';
import { MapViewListCardComponent } from './pages/map-view-list-card/map-view-list-card.component';
import { SearchFormMobileComponent } from './component/search-form-mobile/search-form-mobile.component';
import { SearchHomeDetailsComponent } from './pages/search-home-details/search-home-details.component';
import { BrandPage } from 'src/app/shared/components/brand-page/brand-page.component';
import { HowItWorksComponent } from 'src/app/features/explore-spaces-section/pages/how-it-works/how-it-works-component.component';
import { AboutUsComponent } from 'src/app/features/explore-spaces-section/pages/about-us/about-us.component';
import { FaqComponent } from './pages/faq/faq.component';

const routes: Routes = [
  { path: '', component: ExploreScreenComponent },
  { path: 'search-homes', component: SearchFormMobileComponent },
  { path: 'homes-filter-list', component: SearchFilterListHomesComponent },
  { path: 'add-space-intro', component: AddSpaceLandingComponent },
  { path: 'new-screen', component: ExploreHomeSpacesComponent },
  { path: 'popular-stay', component: PopularStayComponent },
  { path: 'trending-region', component: TrendingRegionComponent },
  { path: 'map-view-list', component: MapViewListCardComponent },
  {
    path: 'how-it-works',
    component: HowItWorksComponent,
  },
  {
    path: 'brand-page',
    component: BrandPage,
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
  },
  {
    path: 'faqs',
    component: FaqComponent,
  },
  {
    path: 'view-home/home-details-ibs/:id',
    component: SearchHomeDetailsComponent,
  },
  {
    path: 'view-home/search-home-details/:id',
    component: SearchHomeDetailsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ExploreSpaceRoutingModule {}
