<div class="help-container">
  <h1>FAQ</h1>
  <p class="subtitle">Updated : September, 2023</p>

  <div class="section">
    <a class="title" href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360006762058-What-is-HomeExchange-" target="_blank">
      <p><b>What is Holiday Swap?</b></p>
    </a>

    <p class="sub-section">
      Holiday Swap offers a secure platform for global home swapping and hosting, available at just $10 per month per person or through a subscription plan. Our innovative chat feature makes Holiday Swap the world's first truly global social media app for like-minded travellers and explorers. Discover new destinations and top activities with our in-app map, curated by users.
    </p>
  </div>

  <div class="section">
    <a class="title" href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360000611497-What-are-the-advantages-of-HomeExchange-" target="_balnk">
      <p><b>What are the advantages of Holiday Swap?</b></p>
    </a>

    <ul class="sub-section">
      <li>
        <p>Swap vacation homes from just $10 per month.</p>
      </li>
      <li>
        <p>Connect with millions of users worldwide.</p>
      </li>
      <li>
        <p>Book rental properties globally.</p>
      </li>
      <li>
        <p>Competitive pricing with no hidden fees.</p>
      </li>
      <li>
        <p>Excellent customer service.</p>
      </li>
      <li>
        <p>Earn and redeem HS Points for discounts.</p>
      </li>
    </ul>
  </div>

  <div class="section">
    <a class="title" href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/360007700358-Tips-to-find-an-exchange" target="_blank">
      <p><b>What are the tips to find an exchange?</b></p>
    </a>
    <p class="sub-section">Finding an exchange requires time and personal investment. Personalise your messages to potential swap partners and reach out to members whose homes match your needs. Use search filters effectively, explore the map, and keep an eye on new opportunities.</p>
  </div>

  <div class="section">
    <a class="title" href="https://homeexchangehelp.zendesk.com/hc/en-us/articles/4417099102993-How-do-I-use-it-" target="_blank">
      <p><b>How do I use it?</b></p>
    </a>
    <p class="sub-section">Download the app to meet swappers or bookers from around the world. Swap homes with other users or host your space.</p>
  </div>

  <div class="section">
    <p class="title"><b>How to house swap for vacation?</b></p>
    <p class="sub-section">Signing up for Holiday Swap is the first step. Upload photos of your home, search for your desired travel location, and follow the provided steps to make your swap happen.</p>
  </div>

  <div class="section">
    <p class="title"><b>What is a house swap?</b></p>
    <p class="sub-section">A house swap, also known as a home swap, is a temporary exchange of residences, allowing individuals or families to enjoy a change of scenery with all the comforts of home, minus hotel costs.</p>
  </div>

  <div class="section">
    <p class="title"><b>How does Holiday Swap work?</b></p>
    <ul class="sub-section">
      <li>
        <p>Create a listing (it's free) and complete your profile to earn 70 HS Points.</p>
      </li>
      <li>
        <p>Search for homes in exciting locations and send swap requests.</p>
      </li>
      <li>
        <p>Join now to complete your swap.</p>
      </li>
    </ul>
  </div>

  <div class="section">
    <p class="title"><b>Is Holiday Swap legit?</b></p>
    <p class="sub-section">Holiday Swap is a secure community, safeguarding your personal information and payments through our trusted partner, Stripe. We encourage users to use our chat for communication and offer language translation. Before your trip, you can subscribe to Property Damage protection.</p>
  </div>

  <div class="section">
    <p class="title"><b>
      Can I swap my house if I stay in a rental house?
    </b></p>
    <p class="sub-section">Yes, you can swap your rental house if your host agrees to it.</p>
  </div>

  <div class="section">
    <p class="title"><b>
      I don’t own a house, can I still use Holiday Swap?
    </b></p>
    <p class="sub-section">Absolutely, you can still use Holiday Swap. Share your available space and start swapping.</p>
  </div>

  <div class="section">
    <p class="title"><b>
      Is it free to swap?
    </b></p>
    <p class="sub-section">A Lite Access version is free, but for premium features, you can access the world for just $10/month.</p>
  </div>

  <div class="section">
    <p class="title"><b>
      What happens if something is damaged or stolen from my house during a swap?
    </b></p>
    <p class="sub-section">Subscribe to our Guarantee for Property Damage to cover approved losses. Also, consider having your own home insurance in place.</p>
  </div>

  <div class="section">
    <p class="title"><b>
      How do I get the keys to the house I am staying in?
    </b></p>
    <p class="sub-section">Message your host for key exchange details.</p>
  </div>

  <div class="section">
    <p class="title"><b>Is Holiday Swap worldwide?</b></p>
    <p class="sub-section">Yes, we operate in over 150 countries</p>
  </div>

  <div class="section">
    <p class="title"><b>Can I cash out my Holiday Swap Points?</b></p>
    <p class="sub-section">Currently, cashing out HS Points is not possible.</p>
  </div>

  <div class="section">
    <p class="title"><b>Bookings:</b></p>
    <p class="sub-section">Guests can subscribe to premium plans ($100 annually or $10 monthly) to waive booking fees.
      Casual Guests who don't subscribe pay a $10 USD per night booking fee.
      Premium Hosts pay a 6% commission, while Casual Hosts pay 9% on completed bookings.</p>
  </div>

  <div class="section">
    <p class="title"><b>What is the difference between HS and Airbnb?</b></p>
    <p class="sub-section">While both offer unique accommodation experiences, HS provides cost-effective global swaps with no hidden fees, while Airbnb may involve additional costs and lacks the personal touch of home exchanges.</p>
  </div>

  <div class="section">
    <p class="title"><b>Do I need to make the swap happen at the same time with the person I am swapping my house with?</b></p>
    <p class="sub-section">No, you can choose to swap or book at different times. Holiday Swap offers both options.</p>
  </div>

  <div class="section">
    <p class="title"><b>Can I still swap my house if I live in the house?</b></p>
    <p class="sub-section">Yes, you can even swap a room within your home.</p>
  </div>

  <div class="section">
    <p class="title"><b>Can I swap a 1 room studio for a 4 bedroom house?</b></p>
    <p class="sub-section">Certainly! Who you swap with is entirely up to you.</p>
  </div>

  <div class="section">
    <p class="title"><b>Do I have to swap a whole property?</b></p>
    <p class="sub-section">No, Holiday Swap allows flexibility. You can swap an entire property or just a shared bedroom, with permission from housemates.</p>
  </div>

  <div class="section">
    <p class="title"><b>Do I have to swap a whole property?</b></p>
    <p class="sub-section">No, Holiday Swap allows flexibility. You can swap an entire property or just a shared bedroom, with permission from housemates.</p>
  </div>

  <div class="section">
    <p class="title"><b>Ambassadors - who are they and can I become one?</b></p>
    <p class="sub-section">We invite you to become a brand ambassador and help spread the word about Holiday Swap. Share your experiences, create content, and receive payment based on your profile and insights.</p>
  </div>

  <div class="section">
    <p class="title"><b>Is home swapping safe and how am I protected?</b></p>
    <p class="sub-section">Holiday Swap prioritises safety, protecting your data and payments through Stripe. Use the chat to get to know your swap partners, add them on social media, and consider Property Damage protection.</p>
  </div>

  <div class="section">
    <p class="title"><b>Do you store my credit card details?</b></p>
    <p class="sub-section">No, we do not store credit card details or share financial information with third parties. Stripe is our secure payment provider.</p>
  </div>

  <div class="section">
    <p class="title"><b>Do I have to put pictures of my property on Holiday Swap?</b></p>
    <p class="sub-section">Yes, multiple images showcasing your space are required for your property to appear in search results. Accuracy is crucial.</p>
  </div>

  <div class="section">
    <p class="title"><b>What if the property is not as described?</b></p>
    <p class="sub-section">If the property doesn't match its description, document the issues with photos and contact support within 7 days of the swap ending.</p>
  </div>

  <div class="section">
    <p class="title"><b>What if there is damage when I return?</b></p>
    <p class="sub-section">Consider Property Damage protection and ensure you have home insurance in place.</p>
  </div>

  <div class="section">
    <p class="title"><b>How do I gain access to the property?</b></p>
    <p class="sub-section">Choose your key collection method before finalising the swap, whether it's leaving a key in a safe place, providing a lockbox code, sending the key to the other user, or using KeyCafe.</p>
  </div>

  <div class="section">
    <p class="title"><b>What if I can’t enter the property?</b></p>
    <p class="sub-section">Communicate with your host to ensure you have all necessary information for a smooth check-in.</p>
  </div>

  <div class="section">
    <p class="title"><b>What if someone wants to swap outside of Holiday Swap?</b></p>
    <p class="sub-section">Swapping outside the app is against our Terms and Conditions and is not secure. Stick to using the platform for swaps.</p>
  </div>

  <div class="section">
    <p class="title"><b>How long will it take to hear back from customer service?</b></p>
    <p class="sub-section">We aim to respond within 2 business days. In urgent situations, contact the other party first.</p>
  </div>

  <div class="section">
    <p class="title"><b>How to query a negative rating?</b></p>
    <p class="sub-section">If you believe a rating is unfair or fabricated, contact our Support Team with your concerns.</p>
  </div>

  <div class="section">
    <p class="title"><b>What should I do in the event of an emergency or a problem related to property?</b></p>
    <p class="sub-section">In case of an emergency, contact local authorities immediately. Ensure you have emergency contact details and appropriate insurance for your trip and property.</p>
  </div>

  <div class="section">
    <p class="title"><b>What do I do if the website is not displayed correctly?</b></p>
    <p class="sub-section">Contact our support team via email at <a class="text-link" href="support@holidayswap.com">support@holidayswap.com</a> or through Instagram DM.</p>
  </div>

  <div class="section">
    <p class="title"><b>I have found an error on the website, what should I do?</b></p>
    <p class="sub-section">Report any errors to our support team through email or Instagram DM.</p>
  </div>

  <div class="section">
    <p class="title"><b>What are HS Points?</b></p>
    <p class="sub-section">HS Points are in-app rewards that enhance your Holiday Swap experience. You can earn and redeem them for various benefits.</p>
  </div>

  <div class="section">
    <p class="title"><b>Do HS Points expire?</b></p>
    <p class="sub-section">Points are valid for 24 months (2 years) after issuance.</p>
  </div>

  <div class="section">
    <p class="title"><b>Where can I check my HS Points?</b></p>
    <p class="sub-section">View your HS Points in your profile settings.</p>
  </div>

  <div class="section">
    <p class="title"><b>What are the benefits of owning HS Points?</b></p>
    <p class="sub-section">Points allow you to travel for less. Currently, users can buy them at a 20% discount, making travel more affordable.</p>
  </div>

  <div class="section">
    <p class="title"><b>How do I earn HS Points?</b></p>
    <p class="sub-section">Complete actions on your profile progress tracker or host other travellers to earn HS Points. These can be used for prize draws, boosting your listing, or discounts.</p>
  </div>
</div>
