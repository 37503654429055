import { Injectable } from "@angular/core";
import { interval, Subscription } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class UpdateService {
	public updateSubscription: Subscription;

	constructor() {
		this.checkForUpdate();
	}

	public checkForUpdate(): void {


		// this.updateSubscription = this.updates.available.subscribe(event => this.promptUser());

		// if (this.updates.isEnabled) {
		// 	// Required to enable updates on Windows and ios.
		// 	this.updates.activateUpdate();

		// 	interval(60 * 60 * 100).subscribe(() => {
		// 		this.updates.checkForUpdate().then(() => {

		// 		});
		// 	});

		// }
	}

	promptUser(): void {
		// this.updates.activateUpdate().then(() => {
		// 	window.location.reload();
		// });
	}

}
