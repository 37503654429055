import { Component, OnInit, ViewChild, Output, EventEmitter} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BottomSheetComponent } from 'src/app/shared/components/bottom-sheet/bottom-sheet.component';
import { ClevertapService } from 'src/app/shared/services/clevertap.service';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { Observable, Subscription } from "rxjs";
import { IUser } from 'src/app/shared/interfaces/user';
import { UserService } from 'src/app/shared/services/user.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { TokenService } from 'src/app/shared/services/token.service';
import { PaymentOptionsComponent } from 'src/app/shared/components/payment-options/payment-options.component';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-upgrade-subscription',
  templateUrl: './upgrade-subscription.component.html',
  styleUrls: ['./upgrade-subscription.component.scss']
})
export class UpgradeSubscriptionComponent implements OnInit {
  public loading = false;
  public listHome = false;
  public tryPremium = true;
  public subscriptionList : any = [];
  // public hideCounter: boolean = false;
  // myInterval;
	// cDateMillisecs: any;
	// tDateMillisecs: any
	// currentDate: any;
	// targetDate: any;
	// oldDate:any;
	// difference: any;
	// seconds: any;
	// minutes: any;
	// hours: any;
	// days: any;
  form: FormGroup;
  // localMin = 10;
  // localSec = 0;
  public selected: any = {};
  public cardPayment = false;
  isSubscripted = false;
  public subscriptions: any[] = [];
  currentUserObserv: Subscription;
  public user: IUser = {};
  userEmail = '';
  withIn10Min = false;
  today = new Date();
  timerMinute = 10;
  state = 0;
  today_after14 = moment().add({days : 14})
	@Output() public onSubscription = new EventEmitter();

	@ViewChild("bottomSheet") bottomSheet: BottomSheetComponent;
  @ViewChild("bottomSheetAdd") bottomSheetAdd: BottomSheetComponent;
	@ViewChild('paymentOptions') paymentOptions: PaymentOptionsComponent;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private clevertapService: ClevertapService,
    private subscriptionService: SubscriptionService,
    public userService: UserService,
    private snackbarService: SnackbarService,
    private tokenService: TokenService,
    public translate: TranslateService
  ) {
    this.form = this.fb.group({
			type : [2],
		})
    this.route.data.subscribe((res:any) => {

      // this.subscriptionList = res.subscriptionList;
      this.subscriptionList = res.subscriptionList.filter(data =>  data.planName != 'Lite')

      this.selected = this.subscriptionList.filter(res => res.id == this.form.get("type").value)[0];

      // this.featuredLocations.map((location) => {
      //  Object.assign(location, {checked : false})
      // })
    })


    this.userService.getUpdatedMe();


    this.form.get('type').valueChanges.subscribe((val) => {

      this.selected = this.subscriptions.filter(res => res.id == this.form.get("type").value)[0];

    })
  }



  ngOnInit(): void {
    this.subscriptionService.getAvailableSubscriptions().subscribe((subscriptions) => {
      this.subscriptions = subscriptions;
      // this.subscriptions.reverse();
      // this.user = this.userService.getCurrentUser();
    }).add(() => {
      this.loading = false;
    })
  }

  // getMe() {
  //   this.userService.getMe().subscribe((res) => {
  //     this.user = res.data;
  //     if(this.user.subscription_offer) {
  //       this.hideCounter = false;

  //       this.counterStart();
  //     }
  //     else {
  //       this.hideCounter = true;

  //     }
  //   })
  // }

  onLogin() {

  }

  listHomes() {

  }

  closeSubscription() {
    // this.stopTimer();
    this.router.navigate(["/"], {queryParams : this.route.snapshot.queryParams, queryParamsHandling :'merge'});
  }

  // counterStart() {

	// }



  // stopTimer() {
	//   clearInterval(this.myInterval);
  // }


	public onClearSubscription() {
		this.selected = {};
	}

  public showCardPayment() {
		this.cardPayment = !this.cardPayment;
	}

	public deactivatePaymentCard(e){

		this.cardPayment = false;
	}

  openPaymentPopup() {
    if(this.userService.difference != 0) {
      this.withIn10Min = true;
    } else {
      this.withIn10Min = false;
    }

		if(this.form.get("type").value != 3) {
			this.isSubscripted = true;
			this.bottomSheet.toggle();
		}
		else {
			this.toastr.error(this.translate.instant("Please select plan"), "Error")
		}

	}

  public onPaymentSuccess(e) {



		//if (this.purchaseSubs && this.purchaseSubs.length > 0) {

		this.cardPayment = false;
		//}

		if (!this.user?.subscription?.id || this.user?.subscription?.subscription_id == '3') {
			//	if (!this.user?.has_subscription?.id) {
			this.onSubscribe({
				item_id: e.id,
				payment_method: e.method,
        bonus_token: this.withIn10Min
			});
		} else {
			this.onUpdate(e.id, e.method);
		}
	}

  public onSubscribe(data: { item_id: number; payment_method: string; bonus_token: boolean}) {
		this.loading = true;
		this.subscriptionService.subscribe(data).subscribe(() => {
			this.snackbarService.show({
				message: this.translate.instant("Subscription started!"),
        type: 'success',
				config: {
					class : 'centered'
				}
			});
			// this.addBonusToken();
			this.userService.getMe().subscribe((user) => {
				this.user = user.data;
			});
      this.loading = false;
			this.bottomSheet.close();
			this.onSubscription.emit();
      this.userService.stopTimer();
			this.router.navigate(["/"], {queryParams : this.route.snapshot.queryParams, queryParamsHandling :'merge'});
		}, (err) => {

      this.snackbarService.show({
				message: err,
				type: 'success',
				config: {
					class : 'centered'
				}
			});
			this.loading = false;
      this.bottomSheet.close();
		}).add(() => {
			this.loading = false;
		});
	}

  public onUpdate(id, payment_method) {
		this.subscriptionService.update(id, payment_method).subscribe(() => {
			this.snackbarService.show({
				message: this.translate.instant("Subscription updated!"),
			});
			this.onSubscription.emit();

        // if(this.premiumSubscribe==100) {

        //   this.OnsubscriptionUpdate();

        // }

			UserService.cacheBuster$.next();

			this.userService.getMe().subscribe((user) => {
				this.user = user.data;
        this.userEmail = user.data.email;


			});


			this.bottomSheet.close();
			this.router.navigate(["/"], {queryParams : this.route.snapshot.queryParams, queryParamsHandling :'merge'});
      this.onSubscriptionPurchased();
      if(this.form.get("type").value ==1){

        this.yearlySubscript();
      }
      if(this.form.get("type").value ==2) {
        this.monthlySubscripted();
      }


		}, (err) => {

      this.snackbarService.show({
				message: err,
				type: 'success',
				config: {
					class : 'centered'
				}
			});
			this.loading = false;
			this.bottomSheet.close();
		}).add(() => {
			this.loading = false;
		});
	}

  addBonusToken() {
		this.tokenService.bonusTokens("Bonus", 9, 10, 10, "Bonus on subscribing").subscribe(
			(res) => {

			}
		)
	}

  onSubscriptionPurchased() {
    let subscritpData = {
      timestamp:'',
      device_platform:'',
      device_os:'',
      device_name:'',
      app_package:'',
      app_build:'',
      phone_number:'',
      email:this.user.email,
      birth_date:'',
      user_name:'',
      user_id:'',
      signed_in:''
    }
    this.clevertapService.pushCleverTapEvent('premium_purchased', subscritpData);
  }
  yearlySubscript() {
    let yearSubscript = {
      timestamp:'',
      device_platform:'',
      device_os:'',
      device_name:'',
      app_package:'',
      app_build:'',
      phone_number:'',
      email:this.user.email,
      birth_date:'',
      user_name:'',
      user_id:'',
      signed_in:''
    }
    this.clevertapService.pushCleverTapEvent('yearly_subscription', yearSubscript)


  }

  monthlySubscripted(){


    let monthSubscript = {
      timestamp:'',
      device_platform:'',
      device_os:'',
      device_name:'',
      app_package:'',
      app_build:'',
      phone_number:'',
      email:this.user.email,
      birth_date:'',
      user_name:'',
      user_id:'',
      signed_in:''
    }
    this.clevertapService.pushCleverTapEvent('monthly_subscription', monthSubscript);

  }

  addNewCardToggle(event) {
		this.bottomSheetAdd.toggle()
		if(event == true) {
			this.state = 1;
		}
		else {
			this.state = 0;
		}
	}

	addCardSuccess() {
		this.bottomSheetAdd.toggle()
		this.state = 0;
		this.paymentOptions.updateCards();
	}



}
