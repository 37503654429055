import { Component, OnInit } from '@angular/core';


import { HmacSHA256, enc } from 'crypto-js';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from "src/environments/environment";
import { ActivatedRoute, Router } from '@angular/router';
import { WiseService } from '../../services/wise.service';
import { UserService } from '../../services/user.service';
import { Location } from "@angular/common";

declare var $: any;

@Component({
  selector: 'app-tipalti-payment-details',
  templateUrl: './tipalti-payment-details.component.html',
  styleUrls: ['./tipalti-payment-details.component.scss']
})
export class TipaltiPaymentDetailsComponent implements OnInit {

  public payerName = environment.tipalti.payer;
  public privateKey = environment.tipalti.privateKey;
  public iframeUrl = '';
  public baseURL = environment.base;
  public tipalti_baseURL = environment.tipalti.base
  bookingId = '';
  payee_id="";
  public email = "";
  public first_name="";
  public last_name="";
  hasHistory: boolean = false;
  safeURL;

  constructor(
    private sanitizer : DomSanitizer,
    private router : Router,
    private route : ActivatedRoute,
    private wiseService : WiseService,
    private userService: UserService,
    private location: Location,
  ) {
    this.hasHistory = this.router.navigated;
    this.userService.currentUserSubject.subscribe(data=>{
      // this.userData = data;

      this.email = data?.email;
      this.first_name = data?.first_name;
      this.last_name = data?.last_name;

    })

    this.route.queryParams.subscribe((query) => {
      if(query.bookingId)
      this.bookingId = query.bookingId;
      // this.payee_id = query.payee_id;
    })


  }

  ngOnInit(): void {
    this.checkTipaltiAccount()
  }

  checkTipaltiAccount() {
    this.wiseService.checkUserBankAccount().subscribe((res : any) => {
      this.payee_id = res.data?.payee_id;
    }).add(() => {
      this.iframeUrl = this.generateIframeUrl(this.payerName, this.privateKey);
      this.cleanURL();
    })
  }

  cleanURL() {
    if(this.iframeUrl) {
      this.safeURL =  this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeUrl);

    }
  }


  generateHash(queryString: string, privateKey: string): string {
    const hash = HmacSHA256(queryString, privateKey);
    const encodedHash = enc.Hex.stringify(hash);
    return encodedHash;
  }


  generateIframeUrl(payerName: string, privateKey: string): string {
    if(this.payee_id != "" || this.payee_id != undefined || this.payee_id != null)
    var queryString = `payer=${payerName}&idap=${this.payee_id}&email=${this.email}&first=${this.first_name}&last=${this.last_name}&ts=${Math.floor(Date.now() / 1000)}`;
    const hash = this.generateHash(queryString, privateKey);
    // const encodedQueryString = encodeURIComponent(queryString);

    var redirectUrl = '';
    if(this.bookingId) {
      redirectUrl = `${this.baseURL}/swaps/first-booking-accept/${this.bookingId}?account=success?payee_id=${this.payee_id}`;
    } else {
      redirectUrl = `${this.baseURL}/me/banking-details?payee_id=${this.payee_id}`;
    }
    return `${this.tipalti_baseURL}?${queryString}&hashkey=${hash}&Redirectto=${redirectUrl}`


  }

  public pop() {

		// if(this.hasHistory) {
		// 	this.location.back();
		// } else {
		// 	// this.router.navigateByUrl('/');
    //   this.router.navigate[`/me/profile-settings`];
		// }

    this.router.navigate([`/me/profile-settings`]);
	}




}

