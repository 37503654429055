import { DeepLinkGuard } from './shared/guards/deeplink.guard';
import { Injectable, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { SharedModule } from './shared/shared.module';
import { AuthGuard } from './shared/guards/auth.guard';
import {
  BrowserModule,
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HttpHandler,
  HttpInterceptor,
  HTTP_INTERCEPTORS,
  HttpRequest,
} from '@angular/common/http';
import { ModalLayoutComponent } from './layouts/modal-layout/modal-layout.component';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { CoreModule } from './core/core.module';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';
import { FACEBOOK_PIXEL_PROVIDER } from './facebook pixel/facebook-pixel.provider';
import { NgxSelectModule } from 'ngx-select-ex';
import { NgApexchartsModule } from 'ng-apexcharts';
import * as Hammer from 'hammerjs';
import { GoogleAnalyticsService } from './shared/services/google-analytics.service';
import { TestShareComponent } from './features/test-share/test-share.component';
import { DownloadComponent } from './redirections/download/download.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { PublicLayoutComponent } from './layouts/public-layout/public-layout.component';
import { ExploreSpaceModule } from './features/explore-spaces-section/explore-space.module';
import { DailyStreakComponent } from './shared/components/daily-streak/daily-streak.component';
// import HTTPRequest from 'pusher-js/types/src/core/http/http_request';

// import { RedirectGuard } from './shared/guards/redirect.guard';

export class HammerConfig extends HammerGestureConfig {
  override overrides = {
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

// @Injectable()
// export class SentryErrorHandler implements ErrorHandler {
//   constructor() {}
//   handleError(error) {
//     Sentry.captureException(error.originalError || error);

//   }
// }

@Injectable()
export class NoCacheHeadersInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authReq = req.clone({
      setHeaders: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
      },
    });
    return next.handle(authReq);
  }
}

@NgModule({
  declarations: [
    AppComponent,
    AuthLayoutComponent,
    MainLayoutComponent,
    ModalLayoutComponent,
    TestShareComponent,
    DownloadComponent,
    PublicLayoutComponent,
  ],
  imports: [
    CoreModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass:
        window.screen.width > 1024 ? 'toast-bottom-right' : 'toast-top-center',
      toastClass: 'ngx-toastr toast-style-design',
      preventDuplicates: true,
    }),
    NgxSelectModule,
    // NgApexchartsModule,
    GoogleMapsModule,
    GooglePlaceModule,
    ExploreSpaceModule,
  ],
  providers: [
    FACEBOOK_PIXEL_PROVIDER,
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    DeepLinkGuard,
    // RedirectGuard,
    CookieService,
    GoogleAnalyticsService,
    // {
    // 	provide: ErrorHandler,
    //     // useClass: SentryErrorHandler,
    // 	useValue: Sentry.createErrorHandler({
    // 	  showDialog: false,
    // 	}),
    // },
    // {
    // provide: Sentry.TraceService,
    // deps: [Router],
    // },
    // {
    // provide: APP_INITIALIZER,
    // useFactory: () => () => {},
    // deps: [Sentry.TraceService],
    // multi: true,
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoCacheHeadersInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  // exports: [CountryPickerModule]
})
export class AppModule {}
