
<div class="background_wrapper">
    <div class="logo_header">
        <div class="intro_brand">
            <img src="/assets/new-logo/app-logo-dark.svg" alt="">
            <!-- <img src="/assets/images/onboard/logo.svg"> -->
            <!-- <span class="brand_title">Holiday Swap</span> -->
        </div>
        <div class="pointer" (click)="closeCongrats()">
            <img src="../../../../../assets/images/onboard/close.svg" alt="">
        </div>
    </div>
    <div class="app_container">
        <div class="empty_div"></div>
        <div class="content_area">
            <div class="page-title">
                <p>{{"Congrats!" | translate}}</p>
            </div>
            <div class="page-desc">
                <p>
                   <b>{{"Your account has been created," | translate}}</b> {{"and as a welcome gift from us to you, you've" | translate}}
                   <b>{{"gained $10 in HS Points" | translate}}</b> {{"on us!" | translate}}
                </p>
            </div>
            <div class="congrats_card" *ngIf="listHome">
                <div>
                    <img src="../../../../../assets/images/onboard/card_coin.svg" alt="">
                    <p>{{"Rent or own, list your home and we’ll" | translate}} <span class="highlight">{{"gift you up to $100 more in HS Points" | translate}}</span>. {{"Earn, save, travel for" | translate}} <b>{{"a lot Less" | translate}}</b>!</p>
                </div>
                <button type="button" class="btn_large btn_transparant" (click)="listHomes()">{{"List your home" | translate}}</button>
            </div>

            <div class="congrats_card" *ngIf="tryPremium">
                <div>
                    <img src="../../../../../assets/images/onboard/card_home.png" alt="">
                    <p>{{"Sign up" | translate}} <b>{{"NOW" | translate}}</b> {{"for our free 14-Day Premium Trial and" | translate}} <span class="highlight">{{"we’ll gift you $200 in HS Points" | translate}}</span>.  {{"Swap homes, travel free!" | translate}}</p>
                </div>
                <button type="button" class="btn_large btn_transparant" (click)="tryPremiumSubscription()">{{"Try premium" | translate}}</button>
            </div>
        </div>
        <div class="login_link">
            <div class="button_wrapper">
                <div class="share_textBox">
                    <img src="/assets/images/shareText_img.png" alt="">
                    <p>{{"You can also share this amazing news with your friends and family to" | translate}}
                        <span>{{"gain $10 in extra HS Points" | translate}}</span>.</p>
                </div>
                <div class="share_btn_box">
                    <app-social-share-button
                    [shareMessage]="shareMessage"
                    [shareUrl]="'auth/intro'"
                    [deeplinkUrl]="webShareUrl"
                    [imgUrl]="webShareUrl"
                    ></app-social-share-button>
                </div>

                <button type="button" (click)="onExplore()" >{{"Explore Holiday Swap" | translate}}</button>
            </div>
        </div>
    </div>
</div>

   <app-loading [absolute]="true" [loading]="loading"></app-loading>


   <!-- add home form popup -->

<div class="add-home-form-modal" *ngIf="openAddHomeForm">
    <div class="close-section">
        <div class="skip-and-close">
            <span class="close-popup" (click)="addSpaceModal.exitClick()">
                <img alt="close icon" src="/assets/images/cancel_ic.svg"></span> </div>
        </div>

    <div class="add_home_sheet h-100">
        <app-add-home-modal #addSpaceModal (exitModal)="closeAddFormPopup()" [formSignUp]="true"></app-add-home-modal>
    </div>

</div>

<div *ngIf="videoBackdrop" class="bix__modal-backdrop"> </div>
