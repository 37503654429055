import { Directive, ElementRef, Input, OnChanges, Renderer2 } from "@angular/core";

@Directive({
	selector: "[appLoad]"
})
export class LoadDirective implements OnChanges {
	private hostEl: any;
	@Input() appLoad: boolean = false;
	@Input() ratio: string = "";

	constructor(private renderer: Renderer2, el: ElementRef) {
		this.hostEl = el.nativeElement;
	}

	ngOnChanges() {
		if(this.appLoad) {
			
			if(this.ratio != '') {
				let data = this.ratio.split(':');
				this.hostEl.style.minWidth = data[0];
				this.hostEl.style.minHeight = data[1];
			}
			this.hostEl.classList.add('view-loading');			
		} else {
			this.hostEl.classList.remove('view-loading');
		}
	}


}
