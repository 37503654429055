import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Cacheable } from 'ts-cacheable';
import { HttpService } from './http.service';
import { environment } from 'src/environments/environment';
import { ISpace } from '../interfaces/space';

@Injectable({
  providedIn: 'root',
})
export class SpaceService {
  public isTokenPopup: boolean = false;
  homeActionUpdateEvent = new EventEmitter<any>();

  public spaceListUpdate = new Subject<any>();
  public ratingPopup = new Subject<any>();
  public wishlistUpdated = new Subject<number>();

  public API_ENDPOINT = environment.api.base;

  public mySpaces$ = new Subject<ISpace[]>();

  constructor(
    private httpService: HttpService,
    private httpClient: HttpClient,
    handler: HttpBackend
  ) {
    this.httpClient = new HttpClient(handler);
  }

  // @Cacheable()
  public getSpaces(filter: string) {
    return this.httpService.get(`/spaces${filter}`);
  }

  // @Cacheable()
  public getOpenSpace(id: number) {
    return this.httpClient
      .get(`${this.API_ENDPOINT}/openspaces/${id}`)
      .pipe(map((res: { data: any }) => res.data));
  }

  // @Cacheable()
  public getSpace(id: number) {
    return this.httpService
      .get(`/spaces/${id}`)
      .pipe(map((res: { data: any }) => res.data));
  }

  // @Cacheable()
  public getMySpaces(filter = { page: 1 }) {
    return this.httpService.get(`/users/me/spaces?page=${filter.page}`).pipe(
      map((res: { data: any }) => {
        this.mySpaces$.next(res.data);
        return res.data;
      })
    );
  }

  public getMySpace(id: number) {
    return this.httpService
      .get(`/users/me/spaces/${id}`)
      .pipe(map((res: { data: any }) => res.data));
  }

  public getBookingDetails(id: number) {
    return this.httpService
      .get(`/getBookingDetail/${id}`)
      .pipe(map((res: { data: any }) => res));
  }

  // @Cacheable()
  public getSwapList() {
    return this.httpService
      .get(`/users/me/swap-list`)
      .pipe(map((res: { data: any }) => res.data));
  }

  public getOnlineUserList() {
    return this.httpService
      .get(`/users/me/contacted-users`)
      .pipe(map((res: { data: any }) => res.data));
  }

  public getuserFavouriteList() {
    return this.httpService
      .get(`/users/me/favourites`)
      .pipe(map((res: any) => res));
  }

  public getSwapConnected() {
    return this.httpService
      .get(`/users/me/swap-connections`)
      .pipe(map((res: any) => res));
  }

  @Cacheable()
  public getUserSpaces(id: number) {
    return this.httpService
      .get(`/users/${id}/spaces`)
      .pipe(map((res: { data: any }) => res.data));
  }

  public delete(id?: number) {
    return this.httpService
      .delete(id ? `/users/me/spaces/${id}` : `/users/me/spaces`)
      .pipe(map((res: { data: any }) => res.data));
  }

  @Cacheable()
  public getFeatured() {
    return this.httpService
      .get(`/featured/spaces`)
      .pipe(map((res: { data: any }) => res.data));
  }

  public likeSpace(id: number, liked: boolean) {
    return liked
      ? this.httpService.delete(`/users/me/favourites`, {
          space_id: id,
        })
      : this.httpService.post(`/users/me/favourites`, {
          space_id: id,
        });
  }

  public create(data: any) {
    return this.httpService.post(`/users/me/spaces`, data);
  }

  public update(id: number, data: any) {
    return this.httpService.put(`/users/me/spaces/${id}`, data);
  }

  public updateType(id: number, data: any) {
    return this.httpService.put(`/user/home/update-details/${id}`, data);
  }

  public saveUpdatedCategories(id: number, data: any) {
    return this.httpService.put(`/user/home/save-categories/${id}`, data);
  }

  public share(id: number) {
    return this.httpService.get(`/share-spaces/${id}`);
  }

  public addImage(data: {
    tag_id?: number;
    is_cover?: boolean;
    space_id?: number;
    path?: string;
  }) {
    return this.httpService.post(`/users/spaces/images`, data);
  }

  public deleteImage(id: number) {
    return this.httpService.delete(`/users/spaces/images/${id}`);
  }

  public deleteOriginalImage(id: number) {
    return this.httpService.delete(`/users/spaces/originalimages/${id}`);
  }

  public updateImage(id: number, data: any) {
    return this.httpService.put(`/users/spaces/images/${id}`, data);
  }

  public getUnavailableDates(id: number) {
    return this.httpService
      .get(`/spaces/${id}/unavailability`)
      .pipe(map((res: { data: any }) => res.data));
  }
  public getPresignedUrl(name, type) {
    return this.httpService.post(`/spaces/aws/presigned`, { name, type });
  }
  public addUnavailableDates(
    id: number,
    data: { dates: [{ started_at: string; ended_at: string }] }
  ) {
    return this.httpService.post(`/spaces/${id}/unavailability`, data);
  }

  public deleteUnavailableDates(id: number) {
    return this.httpService.delete(`/unavailability/${id}`);
  }

  public updateSpaceCompleted(id: number) {
    return this.httpService.put(`/space-details-completed/${id}`, id);
  }

  public viewedSpace(spaceData) {
    return this.httpService.post(`/storeViewSpace/`, spaceData);
  }

  public updateSpaceList() {
    this.httpService.get(`/users/me/spaces`).subscribe((res: any) => {
      this.spaceListUpdate.next(res.data);
    });
  }

  public updateSpaceCompletionRate(space_id, percentage) {
    const spaceData = {
      id: space_id,
      percentage: percentage,
    };

    this.httpService
      .post(`/space-percentage`, spaceData)
      .subscribe((res: any) => {});
  }

  public homeActionUpdate() {
    this.homeActionUpdateEvent.emit();
  }

  public getSpacesCount() {
    return this.httpService.get(`/get-space-count/5`);
  }

  public getSpaceFilter(filter) {
    return this.httpService.get(`/advance-search-spaces${filter}`);
  }

  // public updateWishList(spaceid: number){
  // 	this.wishlistUpdated.next(spaceid);
  // }

  public postReview(id: number, data: any) {
    return this.httpService.post(`/spaces/${id}/reviews`, data);
  }
  public colseReviewTask(id: number) {
    return this.httpService.post(`/review-user-task-dismiss/${id}`, id);
  }

  public getReviewList() {
    return this.httpService.get(`/review-types`);
  }

  public getHomePage() {
    return this.httpService.get(`/homepage`);
  }

  public getSpaceDeeplink(spaceId) {
    return this.httpService.get(`/deeplink/${spaceId}`);
  }

  public getSpaceInsights(spaceId) {
    return this.httpService.get(`/space-insights/${spaceId}`);
  }

  public getPopupViewed(data) {
    return this.httpService.post('/popup-viewed', data);
  }

  public getcreaterecipent(id, data) {
    return this.httpService.post(`/booking/${id}/create-recipient`, data);
  }

  public postCheckbox(spaceId, data) {
    return this.httpService.post(`/home-checked/${spaceId}`, data);
  }

  public checkStateAvailable(data) {
    return this.httpService.post(`/spaces/checkDateAvailable`, data);
  }

  public getconfirmBooking(swapId) {
    return this.httpService.get(`/spaces/data/${swapId}`);
  }

  public shareBookingData(data) {
    return this.httpService.post(`/spaces/share-booking-data`, data);
  }

  public getTrips() {
    return this.httpService.get(`/users/me/trips`);
  }

  public getMywallet() {
    return this.httpService.get(`/users/me/my-wallet`);
  }

  public viewSpace(id: number) {
    return this.httpService.get(`/view-spaces/${id}`);
  }

  public getReferenceId(id: number) {
    return this.httpService.get(`/spaces/reference-data/${id}`);
  }

  public getUnavailabilityStay(id) {
    return this.httpService.get(`/space/${id}/unavailability-stays`);
  }

  public getSpaceVariablePrices(id, startMonth, startYear) {
    return this.httpService.get(
      `/variable-price-calendar/${id}/${startMonth}/${startYear}`
    );
  }

  public postSaveaddress(data) {
    return this.httpService.post(`/user/home/save-address`, data);
  }

  public getSpaceDetailsWithChatGpt(data: any = []) {
    return this.httpService.post(`/user/home/create-chatgpt-description`, data);
  }

  public getHsg_assistant(id) {
    return this.httpService.get(`/user/home/create-overview/${id}`);
  }

  public getHomeByStyleList(searchQuery?: string, category?: number) {
    return this.httpService.get(
      `/home-by-style-list-results/${category || 'all'}${searchQuery ?? ''}`
    );
  }

  public getHomeByStyleMap(searchQuery?: string, category?: number) {
    return this.httpService.get(
      `/home-by-style-map-results/${category || 'all'}${searchQuery ?? ''}`
    );
  }

  public searchSpace(searchQuery?: any) {
    let _query = '';
    if (searchQuery) {
      _query = new HttpParams({ fromObject: searchQuery }).toString();
    }
    return this.httpService.get(`/v2/search-space?${_query}`);
  }
}
