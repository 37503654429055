import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Cacheable } from "ts-cacheable";
import { IDashboardResponse } from "../interfaces/dashboard";
import { HttpService } from "./http.service";

@Injectable({
	providedIn: "root"
})
export class BoosterService {

    public listPagination = {
        page: 1,
        search: '',
        total : 0,
        per_page : 30,
    };

    boost_quantity = 1;

	constructor(private httpService: HttpService) { }

	// @Cacheable()
	public getMyTokens() {
		return this.httpService.get(`/users/me/my-token`) as Observable<IDashboardResponse>;
	}

    public purchaseBooster(data: any) {
        return this.httpService.post(`/users/me/add-boost-budget`, data);
    }

    public getBoosterBudget() {
        return this.httpService.get(`/get-boost-budgets`)
    }

    public boostSpace(data: any) {
        return this.httpService.post(`/users/me/space-boost`, data)
    }

    public getMySpaceList(query :any) {
        return this.httpService.get(`/users/me/space-list?page=${query?.page}`)
    }

    public getBoostCount() {
        return this.httpService.get(`/users/me/boost-count`)
    }

	
}
