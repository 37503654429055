<div class="background_wrapper">

    <div class="logo_header">
        <div class="intro_brand">
            <img src="/assets/new-logo/app-logo-dark.svg" alt="">  
            <!-- <img alt="" src="/assets/images/onboard/logo.svg"> -->
            <!-- <span class="brand_title">Holiday Swap</span> -->
        </div>

        <div>
            <app-language-selector></app-language-selector>
        </div>
    </div>

    <div class="app_container_register">
        <!-- ============================== SIGN UP FORM ============================== -->
        <div class="content_area signup_form">
            <!-- <div class="previous-arrow" (click)="closeSignupModal()" [ngClass]="{'back-override': selectedLanguage == 'ar'}">
                <i class="fa fa-arrow-left common-arrow-style" aria-hidden="true"></i>
                <span>Back</span>
            </div> -->

            <!-- content header strt -->
            <div class="page-title" [ngClass]="{'form-field-override': selectedLanguage == 'ar'}">
                <h1>{{"You're almost done signing up" | translate}}</h1>
            </div>

            <!-- <app-panel *ngIf="error?.length > 0" class="m-t-20 m-b-20" background="#bd4239" textColor="white">
                {{error}}
            </app-panel> -->

            <!-- content header end -->
            <div class="sign_up_form">
                <form (ngSubmit)="onSignup()" ngNativeValidate autocomplete="new-password">


                    <div class="form-field" [ngClass]="{'form-field-override': selectedLanguage == 'ar'}">
                        <label>{{"What's your first name?" | translate}}</label>
                        <input type="text" [(ngModel)]="user.first_name" (ngModelChange)="firstNameValidation()"
                            [ngModelOptions]="{standalone: true}" placeholder="{{'Enter your first name' | translate}}"
                            required>
                        <p class="red-alert" *ngIf="submitted && !firstnameBlank">{{"Please enter at least 2 characters"
                            | translate}}</p>
                        <p>{{"Make sure it matches the first name on Government ID." | translate}}</p>
                    </div>

                    <div class="form-field" [ngClass]="{'form-field-override': selectedLanguage == 'ar'}">
                        <label>{{"What's your last name?" | translate}}</label>
                        <input type="text" [(ngModel)]="user.last_name" (ngModelChange)="lastNameValidation()"
                            [ngModelOptions]="{standalone: true}" placeholder="{{'Enter your last name' | translate}}"
                            required autocomplete="new-password">
                        <p class="red-alert" *ngIf="submitted && !lastnameBlank"> {{"Please enter at least 2 characters"
                            | translate}}</p>
                        <p>{{"Make sure it matches the last name on Government ID." | translate}}</p>
                    </div>

                    <button type="button" [disabled]="!termsOne" class="btn_large btn_transparant" (click)="onSignup()">{{"Continue" | translate}}</button>
                </form>
            </div>
        </div>
        <!-- =========================== ********* ============================== -->
    </div>
</div>