
<div class="share_btnBox">
    <div class="web_buttons" *ngIf="cleverTabService.isWebView">
        <button type="button" class="shareBtn twitterBtn" [disabled]="!!(!pennyCode)" (click)="socialShareModal()">
            <i class="fa fa-twitter twitter-icon" aria-hidden="true"></i>
            <p>{{"TWEET YOUR RESULTS"}}</p>
        </button>
        <div class="arrow-down"></div>
    </div>
    <div class="web_buttons" *ngIf="!cleverTabService.isWebView">
        <button class="shareBtn twitterBtn" type="button" shareButton="twitter" [description]="shareMessage" [title]="'Holidayswap'" [url]="customUrl" [disabled]="!!(!pennyCode)" (click)="shareCalled()">
            <i class="fa fa-twitter twitter-icon" aria-hidden="true"></i>
            <p>{{"TWEET YOUR RESULTS"}}</p>
        </button>
        <div class="arrow-down"></div>
    </div>
</div>
