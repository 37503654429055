<div class="relative">

    <app-select *ngIf="input" placeholder="Select payment method" [template]="selectTemplate" [items]="cards"
        [selected]="active" (selectedChange)="onSelect($event)">
        <ng-template #selectTemplate let-item="item">
            <div class="card-select ">
                <div class="card-select-brand">
                    <app-card-brand-svg [brand]="item?.card?.brand"></app-card-brand-svg>
                </div>

                <div class="card-select-details">

                    <div class="last-4">
                        xxxx-xxxx-xxxx-{{ item?.card?.last4 }}

                    </div>
                </div>
            </div>
        </ng-template>
    </app-select>

    <div *ngIf="!input" class="cards">
        <div appRipple (click)="onSelect(card)" class="card {{ active === card.id ? 'selected' :'' }} "
            *ngFor="let card of cards">
            <div class="card-brand">
                <app-card-brand-svg [brand]="card.brand"></app-card-brand-svg>
            </div>

            <div class="card-details">
                <div class="name">
                    {{ card?.billing_details?.name }}
                </div>
                <div class="last-4">
                    xxxx-xxxx-xxxx-{{ card.last4 }}
                </div>
            </div>

            <div *ngIf="editable" class="card-actions">
                <app-dropdown #dropdown [config]="{right: true, up: true}" [header]="headerTemplate">
                    <ng-template #headerTemplate let-item="item">
                        <div (click)="item.onToggle()" class="dropdown-action-item" >
                            <svg
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="currentColor" stroke-width="2" class="ai ai-MoreVerticalFill">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2a2 2 0 1 1 0 4 2 2 0 0 1 0-4z" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 10a2 2 0 1 1 0 4 2 2 0 0 1 0-4z" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 18a2 2 0 1 1 0 4 2 2 0 0 1 0-4z" />
                            </svg></div>
                    </ng-template>

                    <div class="dropdown-action-list">
                        <a (click)="onRemove(card)">{{"Remove card" | translate}}</a>
                    </div>
                </app-dropdown>
            </div>

            <div *ngIf="!editable" class="card-actions">
                <svg *ngIf="active !== card.id " xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" class="ai ai-Circle">
                    <circle cx="12" cy="12" r="10" />
                </svg>

                <svg *ngIf="active === card.id " xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" class="ai ai-CircleCheck">
                    <path d="M8 12.5l3 3 5-6" />
                    <circle cx="12" cy="12" r="10" />
                </svg>
                <!--i *ngIf="active !== card.id " class="feather-circle"></i>
				<i *ngIf="active === card.id " class="feather-check-circle"></i-->
            </div>
        </div>
    </div>

    <app-loading [loading]="loading"></app-loading>
    <div class="add-card">
        <div (click)="state === 1;bottomSheetAdd.toggle()">{{ "Add new card" | translate }}</div>
    </div>

    <!-- <app-add-card *ngIf="state === 1 " [loaded]="state === 1" (onSuccess)="onCardAdded()"></app-add-card> -->

     <!-- <app-new-stripe-add-card *ngIf="state === 1 " [loaded]="state === 1" (onSuccess)="onCardAdded()"></app-new-stripe-add-card> -->


</div>

<app-bottom-sheet #bottomSheetAdd="bottomSheet" [defaultHeight]="'100%'">
	<div bottomSheet-header>{{"Enter Credit or Debit card details" | translate}}</div>
	<div bottomSheet-body *ngIf="bottomSheetAdd._visible">
	
        <app-new-stripe-add-card [loaded]="true" (onSuccess)="addCardSuccess()"></app-new-stripe-add-card>
		
	</div>

</app-bottom-sheet>