import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import isMobile from '../../../../shared/utils/isMobile';
import { AuthService } from '../../../../shared/services/auth.service';
import { HttpService } from '../../../../shared/services/http.service';
import { UserService } from '../../../../shared/services/user.service';
import { ClevertapService } from '../../../../shared/services/clevertap.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-home-detail-sign-in',
  templateUrl: './home-detail-sign-in.component.html',
  styleUrls: ['./home-detail-sign-in.component.scss'],
})
export class HomeDetailSignInComponent implements OnInit {
  form!: FormGroup;

  isSubmitting = false;

  userData: any;

  showPassword = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private httpService: HttpService,
    private userService: UserService,
    private clevertapService: ClevertapService,
    private toastr: ToastrService,
  ) {
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }

  async handleSignUp(authData: any) {
    if (!authData.email || !authData.password || this.isSubmitting) {
      return;
    }

    try {
      const emailVerify: any = await this.authService.checkMailVerification(authData.email).toPromise();

      if (emailVerify?.isRegistered) {
        try {
          await this.authService.hsLogin(authData).toPromise();
          this.toastr.success('You\'ve logged in successfully!');
        } catch (e) {
          this.toastr.error(e, 'Error');
        }
      } else {
        try {
          await this.authService.hsRegister(authData).toPromise();
          await this.authService.hsLogin(authData).toPromise();
          this.toastr.success('You\'ve registered successfully!');
        } catch (e) {
          console.error(e);
          this.toastr.error(e, 'Error');
        }
      }
    } catch (e) {
      console.error(e);
      this.toastr.error(e, 'Error');
    } finally {
      this.isSubmitting = false;
    }
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  getUserData(responseLoginData) {
    this.userService.getProfile(responseLoginData.user_id).subscribe((response: any) => {
      if (Object.keys(response.data).length > 0) {
        this.userData = response.data;
        this.getUserData(response);
        localStorage.setItem('sociallyLoggedin', '0');
        this.clevertapService.setClevertap_id();
        this.clevertapService.userProfileLogin(this.userData, ((this.userData.is_notification_on == 1) ? true : false));
        setTimeout(() => {
          this.clevertapService.updateClevertapId(this.clevertapService.getClevertap_id());
        }, 4000);
      }
    }, (err) => {

    }).add((() => {

    }));
  }


  onSubmit() {
    const values = this.form.value;

    const authData = {
      ...values,
      email: values?.username,
      source: isMobile.iOS() ? 'IOS' : isMobile.windows() ? 'windows' : isMobile.android() ? 'android' : isMobile.blackBerry() ? 'blackBery' : '',
      grant_type: 'password',
      client_id: '1',
      scope: '*',
    };

    this.handleSignUp(authData);
  }
}
