import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WelcomePopupRoutingModule } from './welcome-popup-routing.module';
import { WelcomePopupComponent } from './pages/welcome-popup/welcome-popup.component';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [
    WelcomePopupComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    WelcomePopupRoutingModule
  ],
  exports: [
    WelcomePopupComponent
  ]
})
export class WelcomePopupModule { }
