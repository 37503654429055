import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { SharedModule } from '../../shared/shared.module';

import { ExploreSpaceRoutingModule } from './explore-space-routing.module';
import { ExploreHomeSpacesComponent } from './pages/explore-home-spaces/explore-home-spaces.component';
import { SearchAddressBoxComponent } from './component/search-address-box/search-address-box.component';
import { FeaturedExploreSpacesComponent } from './component/featured-explore-spaces/featured-explore-spaces.component';
import { FeaturedRegionsComponent } from './component/featured-regions/featured-regions.component';
import { SearchHomesComponent } from './pages/search-homes/search-homes.component';
import { MapViewListSpacesComponent } from './pages/map-view-list-spaces/map-view-list-spaces.component';
import { ResultMapViewSpacesComponent } from './component/result-map-view-spaces/result-map-view-spaces.component';
import { MapViewHomeCardsComponent } from './component/map-view-home-cards/map-view-home-cards.component';
import { SearchFilterListHomesComponent } from './pages/search-filter-list-homes/search-filter-list-homes.component';
import { AddSpaceLandingComponent } from './pages/add-space-landing/add-space-landing.component';
import { WelcomePopupComponent } from 'src/app/modals/welcome-popup/pages/welcome-popup/welcome-popup.component';
import { WelcomePopupModule } from 'src/app/modals/welcome-popup/welcome-popup.module';
import { MyHomesComponent } from './component/my-homes/my-homes.component';
import { EmptyMyHomesComponent } from './component/empty-my-homes/empty-my-homes.component';
import { ExploreScreenComponent } from './pages/explore-screen/explore-screen.component';
import { PopularStayComponent } from './pages/popular-stay/popular-stay.component';
import { TrendingRegionComponent } from './pages/trending-region/trending-region.component';
import { KnowledgeRegionComponent } from './component/knowledge-region/knowledge-region.component';
import { PopularStayCardsComponent } from './component/popular-stay-cards/popular-stay-cards.component';
import { TrendingRegionCardsComponent } from './component/trending-region-cards/trending-region-cards.component';
import { MapViewListCardComponent } from './pages/map-view-list-card/map-view-list-card.component';
import { MapViewHomeCardsListComponent } from './component/map-view-home-cards-list/map-view-home-cards-list.component';
import { SwiperModule } from 'swiper/angular';
import Swiper, { Autoplay } from 'swiper';
import { ExploreScreenPopupsComponent } from './component/explore-screen-popups/explore-screen-popups.component';
import { LearnAndTokenComponent } from './component/learn-and-token/learn-and-token.component';
import { NewMapResultViewComponent } from './component/new-map-result-view/new-map-result-view.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { ExploreFilterModalComponent } from './component/explore-filter-modal/explore-filter-modal.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ExploreHeroComponent } from './component/explore-hero/explore-hero.component';
import { ExploreCardsComponent } from './component/explore-cards/explore-cards.component';
import { ReviewSectionComponent } from './component/review-section/review-section.component';
import { ExploreNavigationComponent } from './component/explore-navigation/explore-navigation.component';
import { SearchFormMobileComponent } from './component/search-form-mobile/search-form-mobile.component';
import { SearchHomeDetailsComponent } from './pages/search-home-details/search-home-details.component';
import { HomeReviewsComponent } from './component/home-reviews/home-reviews.component';
import { HomeDetailsComponent } from './pages/home-details/home-details.component';
import { FaqComponent } from './pages/faq/faq.component';
import { HomeDetailSignInComponent } from './component/home-detail-sign-in/home-detail-sign-in.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { HowItWorksComponent } from './pages/how-it-works/how-it-works-component.component';
import { AuthModule } from '../auth/auth.module';

Swiper.use([Autoplay]);

@NgModule({
  declarations: [
    ExploreHomeSpacesComponent,
    SearchAddressBoxComponent,
    FeaturedExploreSpacesComponent,
    FeaturedRegionsComponent,
    SearchHomesComponent,
    MapViewListSpacesComponent,
    ResultMapViewSpacesComponent,
    MapViewHomeCardsComponent,
    SearchFilterListHomesComponent,
    AddSpaceLandingComponent,
    MyHomesComponent,
    EmptyMyHomesComponent,
    ExploreScreenComponent,
    PopularStayComponent,
    TrendingRegionComponent,
    KnowledgeRegionComponent,
    PopularStayCardsComponent,
    TrendingRegionCardsComponent,
    ExploreScreenPopupsComponent,
    MapViewListCardComponent,
    MapViewHomeCardsListComponent,
    LearnAndTokenComponent,
    NewMapResultViewComponent,
    ExploreFilterModalComponent,
    ExploreHeroComponent,
    ExploreCardsComponent,
    ReviewSectionComponent,
    ExploreNavigationComponent,
    SearchFormMobileComponent,
    SearchHomeDetailsComponent,
    HomeReviewsComponent,
    HomeDetailsComponent,
    FaqComponent,
    HomeDetailSignInComponent,
    HowItWorksComponent,
    AboutUsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ExploreSpaceRoutingModule,
    WelcomePopupModule,
    SwiperModule,
    GooglePlaceModule,
    NgxSliderModule,
    NgxPaginationModule,
    AuthModule,
  ],
  exports: [SearchHomesComponent],
})
export class ExploreSpaceModule {}
