import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { BottomSheetComponent } from "src/app/shared/components/bottom-sheet/bottom-sheet.component";
import { AuthService } from "src/app/shared/services/auth.service";
import { CardService } from "src/app/shared/services/card.service";
import { LanguageService } from "../../services/language.service";
import { PaymentOptionsComponent } from "../payment-options/payment-options.component";

@Component({
	selector: "app-payment-cards",
	templateUrl: "./payment-cards.component.html",
	styleUrls: ["./payment-cards.component.scss"]
})
export class PaymentCardsComponent implements OnInit {
	public cards = [];
	public active = {};
	public loading = true;
	@Input() public editable = true;
	@Input() public input = false;

	@Output() public onSelected = new EventEmitter();
	@ViewChild("bottomSheet") bottomSheet: BottomSheetComponent;
	@ViewChild("bottomSheetAdd") bottomSheetAdd: BottomSheetComponent;
	@ViewChild('paymentOptions') paymentOptions: PaymentOptionsComponent;

	selectedLanguage = "en";
	public state = 0;
	constructor(
		private cardService: CardService,
		public authService: AuthService,
		private languageService: LanguageService) { }

	ngOnInit(): void {
		this.getPaymentMethods();

		this.languageService.languageUpdates.subscribe(
			(lang) => {
				this.selectedLanguage = lang;
			}
		)
		this.selectedLanguage = this.languageService.GetLanguage();
	}

	openBottomSheet() {
		this.bottomSheet.open();
	}

	public getPaymentMethods() {
		this.loading = true;
		this.cardService.get().subscribe((response: { data: [] }) => {
			this.cards = response.data;

		}, (err) => {

		}).add(() => {
			this.loading = false;
		});
	}

	public onRemove(card) {
		this.loading = true;
		this.cardService.delete(card.id).subscribe((response) => {
			this.getPaymentMethods();
		}, (err) => {

		}).add(() => {
			this.loading = false;
		});
	}

	public onCardAdded() {
		this.loading = true;
		this.cards = [];
		// this.bottomSheet.close();
		this.state = 0;
		this.getPaymentMethods();

	}

	public onSelect(card) {

		this.active = card.id;
		this.onSelected.emit(card.id);
	}

	addNewCardToggle(event) {
		this.bottomSheetAdd.toggle()
		if(event == true) {
			this.state = 1;
		}
		else {
			this.state = 0;
		}
	}

	addCardSuccess() {
		this.bottomSheetAdd.toggle()
		this.state = 0;
		// this.paymentOptions.updateCards();
		this.getPaymentMethods();
	}

}
