import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";


@Injectable({
  providedIn: "root"
})
export class WindowScrollingService {
  private _isScrollDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isScrollDisabled$ = this._isScrollDisabled$.asObservable();

  get isScrollDisabled(): boolean {
    return this._isScrollDisabled$.value;
  }

  constructor() {
  }

  /* disable scrolling for App */
  public disable(): void {
    this._isScrollDisabled$.next(true);
  }

  /* enable scrolling for App */
  public enable(): void {
    this._isScrollDisabled$.next(false);
  }
}
