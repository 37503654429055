<div class="welcome_popup_wrapper">
    <div #canvas></div>
    <div class="token-payment-header" [class.float-right] = "isDesktopView">
        <div class="logo-section" *ngIf="!isDesktopView" [routerLink]="['/']">
            <img class="holiday-logo" src="/assets/logos/holidayswap-logo.png">
            <p class="holidayswap-txt">Holiday Swap</p>
        </div>
        <div class="close_wrapper" *ngIf="!isDesktopView" (click)="back()">
            <img class="close-icon" src="/assets/icons/close_icon.png" >
        </div>
        <div class="close_wrapper" *ngIf="isDesktopView" (click)="closePopup()">
            <img class="close-icon" src="/assets/icons/close_icon.png" >
        </div>
    </div>

    <div class="welcome_popup_body">
        <div class="section">
            <p class="text-18">{{"Saving you" | translate}}</p>
            <p class="text-60 text-orange font-bold flexed_paragraph"><label class="yellow_line"></label> 90% <label class="yellow_line"></label></p>
            <div class="text-16 yellow_background travel_acc"><p class="flexed_paragraph"><label class="black_line"></label>{{"On travel accommodations" | translate}} <label class="black_line"></label></p></div>
        </div>

        <div class="section">
            <p>{{"Complete Your Profile and Earn" | translate}}</p>
            <p class="text-30 text-orange font-bold flexed_paragraph"><label class="yellow_line"></label>{{"Free $200" | translate}}<label class="yellow_line"></label></p>
            <p>{{"In Free Travel HS Points" | translate}}</p>
        </div>

        <div class="section">
            <p class="text-16">{{"Host Your Home To Earn" | translate}}</p>
            <p class="text-30 text-orange font-bold flexed_paragraph"><label class="yellow_line"></label>{{"Free" | translate}}<label class="yellow_line"></label></p>
            <p class="text-16"> {{"Travel HS Points" | translate}}</p>
        </div>

        <div class="section">
            <p class="text-16">{{"Purchase Travel HS Points" | translate}}</p>
            <p class="text-24 text-orange font-bold flexed_paragraph"><label class="yellow_line"></label>{{"1 HS Point = $1" | translate}}<label class="yellow_line"></label></p>
            <div class="text-18 yellow_background offer"><p>{{"20% off NOW" | translate}}</p></div>
        </div>
    </div>
    <div class="footer_button">
        <button class="block-button" *ngIf="!isDesktopView" (click) = "closeWelcome()">{{"Got it!" | translate}}</button>
        <button class="block-button" *ngIf="isDesktopView" (click) = "closePopup()">{{"Got it!" | translate}}</button>
    </div>
</div>


