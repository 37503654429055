import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Channel } from 'pusher-js';
import { Observable, Subscription } from "rxjs";
import { PusherService } from 'src/app/shared/services/pusher.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { UserService } from 'src/app/shared/services/user.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ClevertapService } from 'src/app/shared/services/clevertap.service';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { CookieService } from 'ngx-cookie-service';
import { VerifyEmailTimerService } from 'src/app/shared/services/verifyEmailTimer.service';
import sha256 from 'crypto-js/sha256';
import hex from 'crypto-js/enc-hex';

@Component({
	selector: 'app-verify-account',
	templateUrl: './verify-account.component.html',
	styleUrls: ['./verify-account.component.scss']
})
export class VerifyAccountComponent implements OnInit {
	public loading = false;
	public hideCounter: boolean = false;
	verifyInterval;
	emailInterval;
	cDateMillisecs: any;
	tDateMillisecs: any
	currentDate: any;
	targetDate: any;
	oldDate: any;
	difference: any;
	seconds: any;
	minutes: any;
	hours: any;
	days: any;
	form: FormGroup;
	localMin = 20;
	localSec = 0;
	user: any = [];
	currentUserObserv: Subscription;
	public notificationChannel: Channel;
	isVerified: boolean = false;
	enableResend: boolean = false;
	timerMinute = 20;
	signup_res: any = JSON.parse(localStorage.getItem('signUpRes'));
	public redirect_to: string;
	public fragment: string;
	public queryParams;
	showSeparator: boolean = false

	constructor(
		private router: Router,
		private userService: UserService,
		private pusherService: PusherService,
		private snackbarService: SnackbarService,
		private authService: AuthService,
		private clevertapService: ClevertapService,
		private googleAnalyticsService: GoogleAnalyticsService,
		private notificationService: NotificationService,
		private cookieService: CookieService,
		private route: ActivatedRoute,
		public verifyEmailTimeService: VerifyEmailTimerService
	) {
		this.user = JSON.parse(localStorage.getItem('userDataTEST'));
		if (localStorage.getItem("redirect_to")) {
			this.redirect_to = localStorage.getItem("redirect_to");
		}
		if (localStorage.getItem('fragment')) {
			this.fragment = localStorage.getItem("fragment");
		}
		if (localStorage.getItem('queryParams')) {
			this.queryParams = localStorage.getItem("queryParams");
		}
		let redirectPath = this.route.snapshot.queryParams.redirect_to;
		if (redirectPath) {
			this.redirect_to = redirectPath;
			localStorage.setItem('redirect_to', redirectPath);
			localStorage.setItem('fragment', this.fragment);
			localStorage.setItem('queryParams', this.queryParams);
		}
		// this.getMe();
		this.getEmailVerified();

	}

	ngOnInit(): void {


		if (localStorage.getItem('verifyLocalMin') === 'undefined' && localStorage.getItem("verifyLocalSec") === 'undefined') {
			this.setStorage(this.localMin, this.localSec)
		} else if (localStorage.getItem('verifyLocalMin') != 'null' && localStorage.getItem("verifyLocalSec") != 'null') {
			this.localMin = JSON.parse(localStorage.getItem('verifyLocalMin'));
			this.localSec = JSON.parse(localStorage.getItem('verifyLocalSec'));
		}
		else {
			this.resetCouter();
		}



		setTimeout(() => {
			this.user = JSON.parse(localStorage.getItem('userDataTEST'))
			this.notificationChannel = this.pusherService.pusher.subscribe(`verification-channel${this.user?.id}`);
			this.notificationChannel.bind("email-verified-event", (res) => {

				this.getMe();
				history.pushState(null, '')
				// this.snackbarService.show(res.message, res.title);

				// this.inappNotifyBadge = true;
			}, (err) => {

			});
		}, 3000)

		// this
		// 	.googleAnalyticsService
		// 	.eventEmitter("SU_VerifyEmail", "SignUp", "SU_App_Verify");


		setTimeout(() => {
			const verifyLocalMin = localStorage.getItem('verifyLocalMin')
			const verifyLocalSec = localStorage.getItem('verifyLocalSec')

			if (verifyLocalMin) {
				this.minutes =  parseInt(verifyLocalMin) < 10 ? '0' + verifyLocalMin:verifyLocalMin
			}
			if (verifyLocalSec) {
				this.seconds = parseInt(verifyLocalSec) < 10 ? '0' + verifyLocalSec:verifyLocalSec
			}

			// this.minutes = parseInt(verifyLocalMin);
			// this.seconds = parseInt(verifyLocalSec)
		}, 1000)

	}

	onLogin() {

		// generate hashed email for AppsFlyer
		var hash = sha256(this.signup_res?.username);
		let hashedEmail = hash.toString(hex);
		// Fire the AppsFlyer event
		(window as any).AF('pba', 'setCustomerUserId', hashedEmail);

		// Fire the AppsFlyer event
		(window as any).AF('pba', 'event', {eventType: 'EVENT', eventName: 'register_email_verified', eventValue: {
			"description": "Email verified"
		}});

		if (localStorage.getItem("redirectFrom") == 'signUp') {
			localStorage.setItem('email_verified', "true");
			this.notifyme();

			this.router.navigate(["/auth/nearly-there"], {queryParams : this.route.snapshot.queryParams, queryParamsHandling :'merge'});
		} else {

			this.loading = false;
			this.notifyme();
			localStorage.setItem("sociallyLoggedin", "0");
			// this.clevertapId = clevertap.getCleverTapID();

			// this.router.navigate([{ outlets: { modal: ["modal", "settings", 'token-details'] } }]);
			// this.router.navigate([!response.onboarding ? "/setup" : "/dashboard"]);

			// if(this.redirect_to != null && this.redirect_to != "undefined") {
			// 	this.router.navigate([this.redirect_to], {fragment : fragment, queryParams: queryParams })
			// 	// if(this.redirect_to.includes("promo") ) {
			// 	// }
			// 	// else {
			// 	// 	this.router.navigate(["/"]);
			// 	// }
			// }
			// else {
			setTimeout(() => {

				this.router.navigate(["/"], {queryParams : this.route.snapshot.queryParams, queryParamsHandling :'merge'});
			}, 1000)
			// }
		}
		// if(this.clevertapService.isWebView){

		// }

		// else{
		// 	this
		// 	.googleAnalyticsService
		// 	.eventEmitter("congrats_signup_web", "Clicks", "/congrats-signup");
		// }

		this
			.googleAnalyticsService
			.eventEmitter("SU_VerifiedEmail", "SignUp", "SU_VerifySuccess");

	}

	public notifyme() {  // Turn on notifications


		this.authService.login({
			...this.signup_res,
			grant_type: "password",
			scope: "*",
		}).subscribe((response: any) => {

			this.cookieService.set('onboarding-popup', '1');
			localStorage.setItem("sociallyLoggedin", "0");
			this.notificationService.upadateNotificationStatus(1, this.signup_res.id).subscribe(
				(res) => {

				}
			);
			this.clevertapService.userProfileLogin(response.roleUser, true);
			this.clevertapService.setClevertap_id();
			this.clevertapService.updateClevertapId(this.clevertapService.getClevertap_id());
			// this.router.navigate(["/"]);
			// localStorage.removeItem('signUpRes');

		}, (err) => {

		});
	}

	getEmailVerified() {
		if (this.signup_res?.user_name != '' && this.signup_res?.password != '') {
			this.authService.checkMailVerification(this.signup_res?.username).subscribe((resp: any) => {
				if (!resp.isVerified) {
					this.userService.resendEmail(this.signup_res?.username).subscribe((res: any) => {

					})
					this.counterStart();
					this.setVerticationInterval();
				} else {
					this.onLogin();
				}
			})
		}
	}

	getMe() {
		this.userService.getMe().subscribe((res: any) => {

			this.user = res.data;
			this.isVerified = res.data.email_verified;
			if (this.isVerified) {

				let locat = location.href;
				if (!!window.location.pathname && window.location.pathname !== '/') {
					history.replaceState(null, '', location.pathname);
					history.pushState(null, '', locat);
				}
				this.hideCounter = true;
				this.stopTimer();

				this.clearVerificationInterval();
			} else {
				this.counterStart();
				this.setVerticationInterval();
			}
		})
	}

	counterStart() {
		if (!this.verifyInterval) {
			var duration = moment.duration({ 'minutes': this.localMin, 'seconds': this.localSec });

			this.currentDate = new Date();
			this.targetDate = moment(this.currentDate).add(duration);
			this.cDateMillisecs = moment(this.currentDate).valueOf();
			this.tDateMillisecs = moment(this.targetDate).valueOf();
			this.difference = this.tDateMillisecs - this.cDateMillisecs;

			if (this.verifyEmailTimeService.setTimeout === null || this.verifyEmailTimeService.setTimeout === undefined) {
				this.verifyInterval = setInterval(() => {
					// clearInterval(this.myInterval);
					this.offerTimer();


				}, 1000);

				this.verifyEmailTimeService.setTimeout = this.verifyInterval
			} else {
				this.verifyInterval = this.verifyEmailTimeService.setTimeout
			}
		}
	}

	setVerticationInterval() {
		this.emailInterval = setInterval(() => {
			this.checkVerification();
		}, 5000)
	}

	clearVerificationInterval() {
		clearInterval(this.emailInterval);
	}

	checkVerification() {
		this.authService.checkMailVerification(this.signup_res.username).subscribe((resp: any) => {

			if (resp.isVerified == true) {

				this.isVerified = true;
				localStorage.setItem('email_verified', "true");
				let locat = location.href;
				if (!!window.location.pathname && window.location.pathname !== '/') {
					history.replaceState(null, '', location.pathname);
					history.pushState(null, '', locat);
				}
				this.hideCounter = true;
				this.stopTimer();

				this.clearVerificationInterval();
			}
		})
	}


	offerTimer() {

		this.difference = moment.duration(this.difference - 1000, 'milliseconds');

		if (this.difference <= 0) {
			this.minutes = this.difference.minutes();
			this.seconds = this.difference.seconds();

			this.hideCounter = true;
			this.enableResend = true;
			this.stopTimer()
			// this.closeVerification();
			// this.counterStart();
			return;
		} else {
			this.hideCounter = false;
			this.minutes = this.difference.minutes();
			this.seconds = this.difference.seconds();
			this.minutes = this.minutes < 10 ? '0' + this.minutes : this.minutes;
			this.seconds = this.seconds < 10 ? '0' + this.seconds : this.seconds;
			if (document.getElementById('minsVerify'))
				document.getElementById('minsVerify').innerText = this.minutes;
			if (document.getElementById('secondsVerify'))
				document.getElementById('secondsVerify').innerText = this.seconds;
			this.setStorage(parseInt(this.minutes), parseInt(this.seconds))
		}
	}

	stopTimer() {
		// this.localMin = 0;
		// this.localSec = 0;
		clearInterval(this.verifyInterval);
		this.verifyInterval = undefined;
		this.verifyEmailTimeService.clearSetTimeout()
		this.verifyEmailTimeService.setTimeout = undefined
		localStorage.removeItem('verifyLocalSec');
		localStorage.removeItem('verifyLocalMin');


	}

	resetCouter() {
		this.localMin = this.timerMinute;
		this.localSec = 0;

		this.setStorage(JSON.stringify(this.localMin), JSON.stringify(this.localSec));
	}

	setStorage(minutes, seconds) {

		localStorage.setItem('verifyLocalMin', JSON.stringify(minutes));
		localStorage.setItem('verifyLocalSec', JSON.stringify(seconds))
	}

	closeVerification() {
		this.stopTimer();
		if (!this.isVerified) {
			this.router.navigate(['/auth/intro'], {queryParams : this.route.snapshot.queryParams, queryParamsHandling :'merge'});
			// this.userService.deactivateAccount("Email not verified while register").subscribe((res) => {
			// 	this.router.navigate(['/auth/intro']);

			// }, (err) => {

			// })
		}
	}

	resendEmail() {
		// this.stopTimer();
		this.userService.resendEmail(this.signup_res.username).subscribe((res: any) => {
			this.snackbarService.show({
				message: res.message,
				type: 'success',
				config: {
					class: 'centered'
				}
			})
			this.resetCouter();
			// this.setStorage(2, 0)
			this.counterStart();
			// setTimeout(() => {
			// }, 500)
			this.enableResend = false;
			// this.hideCounter = false;
		})
	}
}
