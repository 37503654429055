import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from 'src/app/shared/services/language.service';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
declare var $: any;
@Component({
  selector: 'app-onboarding-intro',
  templateUrl: './onboarding-intro.component.html',
  styleUrls: ['./onboarding-intro.component.scss']
})
export class OnboardingIntroComponent implements OnInit {
  public activeIndex = 0;
  public selectedLanguage = "en";
  public config: SwiperOptions = {
		virtual: false,
    effect: 'slide',
    // slideEffect: {
    //   crossFade: true
    // },
		autoplay: {
			delay: 5000,
      disableOnInteraction:false,
		},
    // pagination : true ,
    pagination: {

      clickable: true,
      dynamicBullets: true
  },
    speed:1000,
    loop:true,
		spaceBetween: 0,
  };
    @ViewChild("swiper", { static: false }) swiper?: SwiperComponent;
  constructor(private router: Router, private route : ActivatedRoute, private languageService: LanguageService) {
    localStorage.setItem('redirect_to', this.route.snapshot.queryParams.redirect_to)
   }

    ngOnInit(): void {
      let loadLang = this.languageService.GetLanguage() + '';

		if (loadLang == "null" || loadLang == "") {
			this.selectedLanguage = "en";
		}
		else {
			this.selectedLanguage = loadLang;
		}


		this.languageService.languageUpdates.subscribe(
			(lang) => {

				this.selectedLanguage = lang;
			}
		)
    }

}
