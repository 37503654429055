<div class="form-item">
	<div class="overview" *ngFor="let date of dates; let i = index;">
		<div appRipple class="row">
			<div class="title">{{"From" | translate}}</div>
			<div class="value">{{ date.started_at | moment: 'MMM DD, YYYY' }}</div>
		</div>
		<div appRipple class="row" class="row">
			<div class="title">{{"To" | translate}}</div>
			<div class="value">{{ date.ended_at | moment: 'MMM DD, YYYY' }}</div>
		</div>
		<div class="delete_time" (click)="onRemove(i)">
			<i class="fa fa-trash"></i>
		</div>
	</div>


	<!-- <div class="action" (click)="toggleDateRangePicker()">
		{{"Add Dates" | translate}}
	</div> -->

	<div class="action" [dropdownTriggerFor]="dropdown">
		{{"Add Dates" | translate}}
	</div>

  <app-drop-panel [className]="'app-home-unavailablity-calendar'" #dropdown (doneClick)="onDateAdded()">
		<app-calendar *ngIf="unavailable" [unavailable]="unavailable" [min]="minDate"
                  [destroy]="true" [alignMode]="isPWA || isMobile ? 'list' : 'slide'"
                  (dateChange)="changeDateSelection($event)"
                  mode="range"></app-calendar>

	</app-drop-panel>
</div>
