<!-- <div class="view_list_button">
    <div class="map_view_btn btn_large" [class.align-items-center]="isDesktopView">
        <button type="button" (click)="goToViewList()">{{"View List" | translate}}</button>
        <div class="advance_search_link" *ngIf="isDesktopView && advanceBtn">
            <a [routerLink]="['/search-list']">{{"Advance search" | translate}}</a>
        </div>
    </div>
</div> -->

<div class="scroll_area map-view-area pro" [class.desktopView]="!isDesktopView">
  <!-- <h5 class="numbers_text" *ngIf="!isDesktopView && spaceList.length>0">{{spaceList.length}}
      {{spaceList.length>1?'homes':'home'}}</h5> -->
  <h5 class="numbers_text" *ngIf="!isDesktopView && spaceList.length > 0">
    {{ total }} {{ total === 1 ? "listing" : "listings" }}
  </h5>
  <h5 class="numbers_text" *ngIf="!isDesktopView && spaceList.length <= 0">
    {{ "No homes to display" | translate }}
  </h5>
  <div class="title_nox d-flex align-items-center pb-3" *ngIf="isDesktopView">
    <h5 *ngIf="spaceList.length > 0">
      {{ total }}
      {{ total === 1 ? "listing" : "listings" }}
    </h5>
    <!-- <h5 *ngIf="spaceList.length>0">{{spaceList.length}} {{spaceList.length>1?'homes':'home'}}</h5> -->
    <h5 *ngIf="spaceList.length <= 0">
      {{ "No homes to display" | translate }}
    </h5>
    <div
      class="filter-search-btn navbar navbar-expand-sm settings-nav-head"
      [class.arabic]="selectedLanguage == 'ar'"
    >
      <!--      <button type="button" class="trigger-filter" (click)="openFilterModal()">-->
      <!--        <img alt="" src="/assets/images/icons/filterBtn.svg">-->
      <!--      </button>-->
    </div>
  </div>
  <div id="card-list-homes" class="card_list">
    <!-- <p class="empty_list_text" *ngIf="spaceList.length<=0">{{'No homes to display'|translate}}</p> -->
    <div class="text-center">
      <div *ngIf="btnLoad" class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <ul *ngIf="!btnLoad" class="list-unstyled mb-0">
      <li *ngFor="let space of filterSpaceList(spaceList); index as i">
        <div class="popular_widget_main">
          <div class="popular_widget_item" (click)="navigateToHome(space.id)">
            <div class="card">
              <div class="popular_image">
                <img alt="" class="lazyload" [lazyLoad]="space.webp_image" />
                <!-- [routerLink]="['/','view-home','home-details-ibs', space.id]" -->
              </div>
              <div class="card-body">
                <div class="popular_title">
                  <h5 class="card-title" *ngIf="space?.title">
                    {{
                      space?.title.length > 40
                        ? space?.title?.substring(0, 40)
                        : space?.title
                    }}
                    {{ space?.title?.length > 40 ? "..." : "" }}
                  </h5>
                </div>
                <span *ngIf="space?.country || space?.city"
                  >&nbsp;{{ space?.country ? space?.country + "," : " " }}
                  {{ space?.city }}</span
                >

                <span *ngIf="space?.address?.country || space?.address?.city"
                  >&nbsp;{{
                    space?.address?.country
                      ? space?.address?.country + ","
                      : " "
                  }}
                  {{ space?.address?.city }}</span
                >
                <div class="popular_des">
                  <span
                    >{{ space?.bedrooms }}
                    {{
                      (space?.bedrooms > 1 ? "bedrooms" : "bedroom") | translate
                    }}
                    {{ space?.guests }}
                    {{ (space?.guests > 1 ? "guests" : "guest") | translate }}
                  </span>
                  <span *ngIf="space?.rating?.total_reviews > 0"
                    >{{ space?.rating?.total_reviews }}
                    {{
                      space?.rating?.total_reviews == 1
                        ? "review"
                        : ("reviews" | translate)
                    }}</span
                  >
                </div>
                <div class="popular_footer">
                  <span
                    class="explore_price"
                    [class.striked]="isPremiumMember"
                    *ngIf="
                      space?.hosting_tokens > 0 &&
                      space?.purpose != 'swap' &&
                      space?.payment_type_id != 561
                    "
                    ><img src="/assets/images/Token_Plane.png" />
                    <span class="home-price">{{
                      flooringNumber(space?.hosting_tokens) | number
                    }}</span>
                    <span class="pl-5 pernight">
                      {{ "per night" | translate }}</span
                    >
                    <!-- <s *ngIf="isPremiumMember">{{ space?.hosting_tokens }}</s> -->
                  </span>

                  <span
                    class="explore_price"
                    *ngIf="
                      space?.hosting_amount >= 0 &&
                      space?.purpose != 'swap' &&
                      space?.payment_type_id == 561
                    "
                  >
                    <span class="home-price"
                      >${{
                        flooringNumber(space?.hosting_amount) | number
                      }}</span
                    >
                    <span class="pl-5 pernight">
                      {{ "per night" | translate }}</span
                    >
                    <!-- <s *ngIf="isPremiumMember">{{ space?.hosting_tokens }}</s> -->
                  </span>
                  <span class="swap_show" *ngIf="space?.purpose == 'swap'">
                    <img src="/assets/images/new_swap_icon.svg" alt="" />
                    <span>{{ "Swap" | translate }}</span>
                  </span>
                  <div class="popular_action">
                    <div>
                      <!-- <li><a><img src="/assets/images/send_ic.svg" ></a></li> -->
                      <div *ngIf="space?.is_favourited">
                        <a (click)="onLike($event, space, i)"
                          ><i class="fa fa-heart" aria-hidden="true"></i
                        ></a>
                      </div>
                      <div *ngIf="!space?.is_favourited">
                        <a (click)="onLike($event, space, i)"
                          ><i class="fa fa-heart-o" aria-hidden="true"></i
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--              <div class="offer_footer"-->
              <!--                   *ngIf="space?.hosting_tokens > 0 && space?.purpose != 'swap' && space?.payment_type_id != 561 ">-->
              <!--                <div class="text_part">-->
              <!--                  <span>{{ "Subscribed Users" | translate }} &nbsp;&nbsp;&nbsp;</span>-->
              <!--                  <p><span class="text_red text_big">20%</span> <span class="text_red">{{ "Discount" |-->
              <!--                    translate }}</span></p>-->
              <!--                </div>-->
              <!--                <div class="ammount_part">-->
              <!--                                    <span class="explore_price"><img alt="" src="/assets/images/Token_Plane.png">{{-->
              <!--                                      getPersentage(space?.hosting_tokens) }}</span>-->
              <!--                  <span class="black_text pl-5"> {{"per night" | translate}}</span>-->
              <!--                </div>-->
              <!--              </div>-->
            </div>

            <!-- (i % 4) == 0 && ((i + 12) % 4 == 0) -->
            <!-- ngIf="indexArray.indexOf(i) -->

            <!--            <div class="card" *ngIf="space.type === 'BUY_TOKEN'">-->
            <!--              <div class="card-body">-->
            <!--                <div class="sign_up_premium_description">-->
            <!--                  <img src="../../../../../assets/images/knowledge_slide2.png" alt="token_coin"/>-->
            <!--                  <p class="para1">{{"Oh, " | translate}}<span class="highlight">{{"the amazing places-->
            <!--                    you’ll go! " | translate}}</span>{{"Top up your HS Points today and visit these-->
            <!--                    places and more!" | translate}}</p>-->
            <!--                </div>-->
            <!--                <div class="d-flex justify-content-center" (click)="goToTokenPurchase()">-->
            <!--                  <button type="button" class="add_home_btn list_home">{{"Top up HS Points" |-->
            <!--                    translate}}</button>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->

            <div class="card cta" *ngIf="space.type === 'LIST_HOME'">
              <div class="card-body">
                <div class="sign_up_premium_description para-content">
                  <img
                    src="../../../../../assets/images/onboard/card_coin.svg"
                    alt=""
                  />
                  <p class="para1">
                    {{ "Rent or own, list your home and we’ll" | translate }}
                    <span class="highlight">{{
                      "gift you up to $100 more in HS points" | translate
                    }}</span
                    >. {{ "Earn, save, travel for" | translate }}
                    <b>{{
                      "a lot
                    Less" | translate
                    }}</b
                    >!
                  </p>
                </div>
                <div class="d-flex justify-content-center">
                  <button
                    type="button"
                    class="add_home_btn list_home"
                    (click)="listHomes()"
                  >
                    {{
                      "List
                    your home" | translate
                    }}
                  </button>
                </div>
              </div>
            </div>
            <!-- <ng-container *ngFor="let space of spaceList; index as i">
                <li class="dropdown-item" *ngIf="(i % 4) == 0">
                    <div class="card">
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nemo nam sed totam alias? Rem perspiciatis reprehenderit, molestiae cumque maiores corrupti, sapiente quis similique aspernatur illum quod molestias, aliquam qui velit.</p>
                    </div>
                </li>
              </ng-container> -->
          </div>
        </div>
      </li>
    </ul>

    <div *ngIf="!btnLoad" class="center">
      <ng-container
        *ngFor="
          let item of spaceList
            | paginate
              : {
                  id: 'map-view-home-cards-list',
                  itemsPerPage: pagination.pageSize,
                  currentPage: pagination.page,
                  totalItems: pagination.total
                }
        "
      ></ng-container>
      <pagination-controls
        id="map-view-home-cards-list"
        [maxSize]="7"
        [directionLinks]="false"
        (pageChange)="onPageChange($event)"
        (pageBoundsCorrection)="onPageBoundsCorrection($event)"
      ></pagination-controls>
    </div>
  </div>
</div>

<!--<app-explore-filter-modal-->
<!--  #filterModal-->
<!--  (onFilter)="handleSearchByAdditionalFilter($event)"-->
<!--  (onClear)="handleClearSearch()"-->
<!--&gt;</app-explore-filter-modal>-->

<div *ngIf="popoupBackdrop" class="bix__modal-backdrop"></div>
<div class="add-home-form-modal" *ngIf="openAddHomeForm">
  <div class="close-section">
    <div class="skip-and-close">
      <span class="close-popup" (click)="addSpaceModal.exitClick()">
        <img alt="close icon" src="/assets/images/cancel_ic.svg"
      /></span>
    </div>
  </div>

  <div class="add_home_sheet h-100">
    <app-add-home-modal
      #addSpaceModal
      (exitModal)="closeAddFormPopup()"
    ></app-add-home-modal>
  </div>
</div>
