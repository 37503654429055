import { Injectable } from "@angular/core";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { Observable, Subscriber } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { HttpService } from "./http.service";
interface PaymentResponse {
	payment_id?: string;
	requires_action?: boolean;
	payment_intent_client_secret?: string;
}
@Injectable({
	providedIn: "root"
})
export class StripeService {
	public stripe: Stripe;
	constructor(private httpService: HttpService) {

	}

	public loadStripe() {
		loadStripe(environment.stripe).then((stripe) => {
			this.stripe = stripe;
		});
	}

	public payment2(payload: { bundle_id?: number, payment_method?: string }) {
		return new Observable((observer: Subscriber<{ status: boolean }>) => {
			this.httpService.post(`/users/me/tokens/payment-intent`, payload).subscribe((response: any) => {


				if (response.message === "ok") {
					observer.next({
						status: true,
					});
					observer.complete();
					return;
				}

				this.stripe.confirmCardPayment(response.data.client_secret).then((result) => {
					if (result.error) {
						observer.error(result.error.message);
					}
					this.httpService.post(`/users/me/tokens`, {
						bundle_id: payload.bundle_id,
					}).subscribe(() => {
						observer.next({
							status: true,
						});
					}, (err) => {
						observer.error(err);
					}).add(() => {
						observer.complete();
					});
				});

			});
		});
	}

	public getIntent(payload: { bundle_id?: number, payment_method?: string }) {

		return this.httpService.post(`/users/me/tokens/payment-intent`, payload);
	}

	public getIntentTokens(token_price?: number, payment_method?: string) {
		let payload = {
			"token_price": token_price,
			"payment_method": payment_method
		};
		return this.httpService.post(`/users/me/tokens/payment-intent-tokens`, payload);
	}

	public createToken(card) {
		return this.stripe.createToken(card);
	// 	this.stripe.createToken((card)).then(function() {
	// 		return card;
	// 	})
	}
}
