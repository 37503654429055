import { animate, animateChild, group, query, style, transition, trigger } from "@angular/animations";
import { Location } from "@angular/common";
import { Component, HostListener, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";

@Component({
	selector: "app-modal-layout",
	templateUrl: "./modal-layout.component.html",
	styleUrls: ["./modal-layout.component.scss"],
	animations: [
		trigger("routeAnimation", [

			transition("* <=> *", [

				query(":enter, :leave", style({
					position: "absolute",
					left: 0,
					right: 0,
					width: "100%",

				}), {
					optional: true,
				}),
				group([
					query(":enter", [

						style({ transform: "translateY(100%)" }),
						animate("260ms ease-in-out", style({ transform: "translateY(0%)" })),
						animateChild()

					], { optional: true }),
					query(":leave", [

						animate("260ms ease-in-out", style({ transform: "translateY(100%)" })),
						animateChild()
					],
						{ optional: true }),
				]),

			])


		])]
})
export class ModalLayoutComponent implements OnInit {

	destroy = new Subject<any>();
	currentDialog = null;
	hasHistory: boolean = false;
	constructor(
		private location: Location,
		private router: Router,
	) {
		this.hasHistory = this.router.navigated;
	}

	ngOnInit(): void {
	}

	@HostListener("document:keydown.escape", ["$event"])
	public onKeydownHandler(evt: KeyboardEvent) {
		this.close();
	}

	public close() {
		// this.location.back();
		if(this.hasHistory) {
			this.location.back();
		} else {
			this.router.navigateByUrl('/');
		}

	}

}
