import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";

@Injectable({
	providedIn: "root"
})
export class CardService {

	constructor(private httpService: HttpService) { }

	public get() {
		return this.httpService.get(`/users/me/payment-methods`);
	}
	public delete(id: string) {
		return this.httpService.delete(`/users/me/payment-methods/${id}`);
	}

	public create(data: { payment_method_id: string, full_name: string }) {
		return this.httpService.post(`/users/me/payment-methods`, data);
	}
}
