<div class="swiper_popular_wrapper">
  <swiper #swiper [config]="config" (slideChange)="onSlideChange()">
    <ng-container *ngFor="let space of featuredSpaces; let i = index">
      <ng-template swiperSlide>
        <ng-container>
          <div class="popular_widget_item">
            <div class="card">
              <div class="popular_image">
                <img
                  class="card-img-top lazyload pointer"
                  [lazyLoad]="space.ipad_image"
                  (click)="navigateToHome(space.id)"
                />
              </div>
              <div class="card-body">
                <div class="pointer" (click)="navigateToHome(space.id)">
                  <div class="popular_title">
                    <div class="popular_card_title">
                      {{ space?.title }}
                    </div>

                    <!--                                      <div class="rating">-->
                    <!--                                        <img src="/assets/images/star_ic.svg">-->
                    <!--                                        <span>{{ space?.moderator_review_rating }}</span>-->
                    <!--                                      </div>-->
                  </div>
                  <span
                    class="card-location"
                    *ngIf="space?.address?.country || space?.address?.city"
                    >&nbsp;{{
                      space?.address?.country
                        ? space?.address?.country + ","
                        : " "
                    }}
                    {{ space?.address?.city }}</span
                  >

                  <div class="popular_des">
                    <span class="card-desc"
                      >{{ space?.bedrooms }}
                      {{
                        (space?.bedrooms > 1 ? "bedrooms" : "bedroom")
                          | translate
                      }}
                      . {{ space?.guests }}
                      {{
                        (space?.guests > 1 ? "guests" : "guest") | translate
                      }}</span
                    >
                  </div>
                </div>

                <div class="popular_footer">
                  <div class="popular_action">
                    <ul>
                      <li *ngIf="space?.is_favourited">
                        <a (click)="onLike(space, i)"
                          ><i class="fa fa-heart" aria-hidden="true"></i
                        ></a>
                      </li>
                      <li *ngIf="!space?.is_favourited">
                        <a (click)="onLike(space, i)"
                          ><i class="fa fa-heart-o" aria-hidden="true"></i
                        ></a>
                      </li>
                    </ul>
                  </div>
                  <span
                    class="explore_price"
                    [class.striked]="isPremiumMember"
                    *ngIf="
                      space?.hosting_tokens > 0 &&
                      space?.purpose != 'swap' &&
                      space.payment_type_id != 561
                    "
                  >
                    <span class="card-price">{{
                      space?.hosting_tokens | number
                    }}</span>
                    <span class="pl-5 per-night">
                      {{ "per night" | translate }}</span
                    >
                  </span>
                  <span
                    class="explore_price"
                    *ngIf="
                      space?.hosting_amount >= 0 &&
                      space?.purpose != 'swap' &&
                      space.payment_type_id == 561
                    "
                  >
                    <span class="card-price"
                      >${{ space?.hosting_amount | number }}</span
                    >
                    <span class="pl-5 per-night">
                      {{ "per night" | translate }}</span
                    >
                  </span>
                  <span class="swap_show" *ngIf="space?.purpose == 'swap'">
                    <img src="/assets/images/new_swap_icon.svg" alt="" />
                    <span>{{ "Swap" | translate }}</span>
                  </span>
                </div>
              </div>
              <div
                class="offer_footer"
                *ngIf="
                  space?.hosting_tokens > 0 &&
                  space?.purpose != 'swap' &&
                  space.payment_type_id != 561
                "
              >
                <div class="text_part">
                  <span
                    >{{
                      "Subscribed Users" | translate
                    }}
                    &nbsp;&nbsp;&nbsp;</span
                  >
                  <p>
                    <span class="text_red text_big">20%</span>
                    <span class="text_red">{{ "Discount" | translate }}</span>
                  </p>
                </div>
                <div class="ammount_part">
                  <span class="explore_price">{{
                    getPersentage(space?.hosting_tokens)
                  }}</span>
                  <span class="black_text pl-5">
                    {{ "per night" | translate }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-template>
    </ng-container>
  </swiper>

  <div *ngIf="featuredSpaces.length < 1"></div>
</div>
