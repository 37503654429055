import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from "./http.service";
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from "../../../environments/environment";

@Injectable({
	providedIn: 'root'
})
export class WiseService {
	public API_ENDPOINT = environment.api.base;
	constructor(private httpService: HttpService, private http: HttpClient) { }

  // public createWiseRecipent(data){
	// 	return this.httpService.post(`/create-wise-account`,data)
	// }

  public getcreaterecipent(id, data){
		// return this.httpService.post(`/booking/${id}/create-recipient`,data)

    return this.http.post(`${this.API_ENDPOINT}/booking/${id}/create-recipient`, data, { headers: this.httpService.headers() }).pipe(
			catchError(this.handleError), map((res) => res));
	}

  public createWiseRecipent(data) {
    return this.http.post(`${this.API_ENDPOINT}${"/create-wise-account"}`, data, { headers: this.httpService.headers() }).pipe(
			catchError(this.handleError), map((res) => res));
  }

  private handleError(error: HttpErrorResponse) {

		if (error.error instanceof ErrorEvent) {
		} else {
			return throwError(
				error.error);
		}
		return throwError(
			"Something bad happened; please try again later.");
	}

  public getCheckWiseRecipient() {
    return this.httpService.post(`/recipient-wise-check`, {});
  }

  public getWiseAccount() {
    return this.httpService.get(`/get-wise-account`);
  }

  public deleteWiseAccount(id) {
    return this.httpService.delete(`/delete-wise-account/${id}`);
  }

  public setDefaultWiseAccount(id) {
    return this.httpService.put(`/set-default-wise-account/${id}`,{});
  }

  public getDetailsByID(id){
    return this.httpService.get(`/view-wise-account/${id}`)
  }

  public checkUserBankAccount(){
    return this.httpService.get(`/check-user-bank-account`);
  }

}
