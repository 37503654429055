<div class="background_wrapper">
  <div class="logo_header">
    <div class="intro_brand" routerLink="/">
      <img src="/assets/new-logo/app-logo-dark.svg" alt="" />
      <!-- <img src="/assets/images/onboard/logo.svg"> -->
      <!-- <span class="brand_title">Holiday Swap</span> -->
    </div>
    <div class="pointer" (click)="closeSubscription()">
      <img src="../../../../../assets/images/onboard/close.svg" alt="" />
    </div>
  </div>
  <div class="app_container">
    <div class="content_area">
      <div class="page-title">
        <p>{{ "Try Premium Today!" | translate }}</p>
        <p>{{ "Act now and:" | translate }}</p>
      </div>
      <ul class="premium_benefits">
        <li>{{ "Receive $200 in HS Points for free!" | translate }}</li>
        <li>{{ "No commitments, cancel any time" | translate }}</li>
        <li>{{ "Save over 90% on accommodation costs" | translate }}</li>
        <li>{{ "Earn thousands by listing your home" | translate }}</li>
      </ul>

      <div class="subscriptions_container">
        <div class="subscription_radio_list">
          <div class="radio_button_wrapper prechecked">
            <div class="relative_wrapper">
              <label>
                <p class="sub_name">
                  <span class="highlight">{{
                    "start your 14-day free trial" | translate
                  }}</span>
                </p>
                <p class="sub_title">
                  {{ "$10 Monthly thereafter, starting on" | translate }} <br />
                  {{ today_after14 | moment : "MMM DD, YYYY" }}
                </p>
              </label>
            </div>
          </div>
        </div>
        <div [formGroup]="form">
          <ng-container
            *ngFor="let subscription of subscriptionList; let i = index"
          >
            <div class="subscription_radio_list">
              <div
                class="radio_button_wrapper"
                [class.checked]="this.form.get('type').value == subscription.id"
              >
                <div class="relative_wrapper">
                  <input
                    type="radio"
                    id="{{ subscription?.planName }}"
                    formControlName="type"
                    name="type"
                    [value]="subscription.id"
                    hidden
                    [checked]="this.form.get('type').value == subscription.id"
                  />
                  <label for="{{ subscription.planName }}">
                    <p class="sub_name">
                      {{ subscription?.planName + " premium" | translate }}
                    </p>
                    <p class="sub_title">
                      ${{ subscription.price / 100 }} {{ "per" | translate }}
                      {{ subscription.interval | translate
                      }}{{
                        (subscription.interval == "Month"
                          ? ", cancel any time"
                          : ""
                        ) | translate
                      }}
                      <span
                        class="highlight"
                        *ngIf="subscription.interval == 'Year'"
                        >{{ "(Save $20)" | translate }}</span
                      >
                    </p>
                  </label>
                </div>
                <img
                  class="star_image"
                  src="../../../../../assets/images/onboard/star.svg"
                  alt=""
                />
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="footer_wrapper">
      <div class="timer_wrapper" [hidden]="userService.hideCounter">
        <div class="discount_outer">
          <div class="discount-time-box">
            <!-- <p class="discount-text">20% discount ends in:</p> -->
            <div class="timer-wrap">
              <div class="timeDiv">
                <h3 id="mins">{{ userService.minutes }}</h3>
              </div>
              <div class="separater">:</div>
              <div class="timeDiv">
                <h3 id="seconds">{{ userService.seconds }}</h3>
              </div>
              <div class="content">
                <p>{{ "to Receive $200 Free in HS Points" | translate }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="login_link">
        <button
          type="button"
          class="btn_large btn_transparant"
          (click)="openPaymentPopup()"
        >
          {{ "Start your free trial now" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<app-loading [absolute]="true" [loading]="loading"></app-loading>

<app-bottom-sheet
  (closed)="onClearSubscription()"
  #bottomSheet="bottomSheet"
  [defaultHeight]="'70%'"
>
  <div bottomSheet-body *ngIf="bottomSheet._visible">
    <app-payment-options
      #paymentOptions
      [selected]="selected"
      [item]="{
        id: selected?.id,
        name: 'Subscription',
        type: 'subscription',
        price: selected?.price / 100,
        quantity: selected?.interval
      }"
      (onPaymentSuccessEmit)="onPaymentSuccess($event)"
      (onSuccess)="onPaymentSuccess($event)"
      (onAddNewCardEmit)="addNewCardToggle($event)"
    ></app-payment-options>

    <!--
		<div class="token-payment_main-div">
			<div class="token-payment-header">
				<div class="logo-section" [routerLink]="['/']">
					<img class="holiday-logo" src="/assets/logos/holidayswap-logo.png">
					<p class="holidayswap-txt">Holiday Swap</p>
				</div>
			</div>

			<div class="buy-options">
				<p class="buy-tokens-txt">{{"Subscription" | translate}}</p>

				<div class="options-section">
					<button class="credit-payment-btn" (click)="showCardPayment()">
						<img src="/assets/icons/credit-card-icon.png">
						<span class="payment-type-txt">{{"Credit Card" | translate}}</span>
					</button>

					<app-payment-request-button
						[item]="{ type: 'subscription', id: selected?.id, quantity: selected?.interval, price: selected?.price / 100 }"
						(paymentSuccess)="onPaymentSuccess($event)"
						(paymentLoading)="loading = $event"
					></app-payment-request-button>
				</div>
			</div>

			<p class="save-money-txt">
				{{"You are about to save money on your travels!" | translate}}
			</p>

			<div *ngIf="cardPayment">
				<app-pay (onSuccess)="onPaymentSuccess($event)"
				  [item]="{id: selected?.id, name: 'Subscription', type: 'subscription', price: selected?.price / 100, quantity: selected?.interval}">
		     	</app-pay>
			</div>

		</div> -->
  </div>
</app-bottom-sheet>

<app-bottom-sheet #bottomSheetAdd="bottomSheet" [defaultHeight]="'100%'">
  <div bottomSheet-header>
    {{ "Enter Credit or Debit card details" | translate }}
  </div>
  <div bottomSheet-body *ngIf="bottomSheetAdd._visible">
    <p class="add_card_details">
      {{
        "Please enter your credit card details to register your Premium Trial and secure"
          | translate
      }}
    </p>
    <app-new-stripe-add-card
      [loaded]="true"
      (onSuccess)="addCardSuccess()"
    ></app-new-stripe-add-card>
  </div>
</app-bottom-sheet>
