import { DeviceDetectorService } from 'ngx-device-detector';
import { Component, OnInit, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DropPanelComponent } from "src/app/shared/components/drop-panel/drop-panel.component";
import { LanguageService } from "src/app/shared/services/language.service";
import { RxwebValidators } from "@rxweb/reactive-form-validators";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HttpService } from '../../services/http.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
@Component({
  selector: 'app-phone-number-input',
  templateUrl: './phone-number-input.component.html',
  styleUrls: ['./phone-number-input.component.scss']
})
export class PhoneNumberInputComponent implements OnInit {

  @Input() public onChange: string;
	@Output() public onChangeChange = new EventEmitter();
	@Output() public onChangeInput = new EventEmitter();
	@Input() public phoneCode : string;
	@Input() public phone_no : string;
	public valid = false;
	isMobile = false;
	// public phoneNumber = new FormControl();
	public form : FormGroup;

	public search: string;
	public country = { "name": "United States of America","flag": "🇺🇸","code": "US","dial_code": "+1" };
	public countries = [
		{
		 "name": "Afghanistan",
		 "flag": "🇦🇫",
		 "code": "AF",
		 "dial_code": "+93"
		},
		{
		 "name": "Åland Islands",
		 "flag": "🇦🇽",
		 "code": "AX",
		 "dial_code": "+358"
		},
		{
		 "name": "Albania",
		 "flag": "🇦🇱",
		 "code": "AL",
		 "dial_code": "+355"
		},
		{
		 "name": "Algeria",
		 "flag": "🇩🇿",
		 "code": "DZ",
		 "dial_code": "+213"
		},
		{
		 "name": "American Samoa",
		 "flag": "🇦🇸",
		 "code": "AS",
		 "dial_code": "+1684"
		},
		{
		 "name": "Andorra",
		 "flag": "🇦🇩",
		 "code": "AD",
		 "dial_code": "+376"
		},
		{
		 "name": "Angola",
		 "flag": "🇦🇴",
		 "code": "AO",
		 "dial_code": "+244"
		},
		{
		 "name": "Anguilla",
		 "flag": "🇦🇮",
		 "code": "AI",
		 "dial_code": "+1264"
		},
		{
		 "name": "Antarctica",
		 "flag": "🇦🇶",
		 "code": "AQ",
		 "dial_code": "+672"
		},
		{
		 "name": "Antigua and Barbuda",
		 "flag": "🇦🇬",
		 "code": "AG",
		 "dial_code": "+1268"
		},
		{
		 "name": "Argentina",
		 "flag": "🇦🇷",
		 "code": "AR",
		 "dial_code": "+54"
		},
		{
		 "name": "Armenia",
		 "flag": "🇦🇲",
		 "code": "AM",
		 "dial_code": "+374"
		},
		{
		 "name": "Aruba",
		 "flag": "🇦🇼",
		 "code": "AW",
		 "dial_code": "+297"
		},
		{
		 "name": "Australia",
		 "flag": "🇦🇺",
		 "code": "AU",
		 "dial_code": "+61"
		},
		{
		 "name": "Austria",
		 "flag": "🇦🇹",
		 "code": "AT",
		 "dial_code": "+43"
		},
		{
		 "name": "Azerbaijan",
		 "flag": "🇦🇿",
		 "code": "AZ",
		 "dial_code": "+994"
		},
		{
		 "name": "Bahamas",
		 "flag": "🇧🇸",
		 "code": "BS",
		 "dial_code": "+1242"
		},
		{
		 "name": "Bahrain",
		 "flag": "🇧🇭",
		 "code": "BH",
		 "dial_code": "+973"
		},
		{
		 "name": "Bangladesh",
		 "flag": "🇧🇩",
		 "code": "BD",
		 "dial_code": "+880"
		},
		{
		 "name": "Barbados",
		 "flag": "🇧🇧",
		 "code": "BB",
		 "dial_code": "+1246"
		},
		{
		 "name": "Belarus",
		 "flag": "🇧🇾",
		 "code": "BY",
		 "dial_code": "+375"
		},
		{
		 "name": "Belgium",
		 "flag": "🇧🇪",
		 "code": "BE",
		 "dial_code": "+32"
		},
		{
		 "name": "Belize",
		 "flag": "🇧🇿",
		 "code": "BZ",
		 "dial_code": "+501"
		},
		{
		 "name": "Benin",
		 "flag": "🇧🇯",
		 "code": "BJ",
		 "dial_code": "+229"
		},
		{
		 "name": "Bermuda",
		 "flag": "🇧🇲",
		 "code": "BM",
		 "dial_code": "+1441"
		},
		{
		 "name": "Bhutan",
		 "flag": "🇧🇹",
		 "code": "BT",
		 "dial_code": "+975"
		},
		{
		 "name": "Bolivia",
		 "flag": "🇧🇴",
		 "code": "BO",
		 "dial_code": "+591"
		},
		{
		 "name": "Bosnia and Herzegovina",
		 "flag": "🇧🇦",
		 "code": "BA",
		 "dial_code": "+387"
		},
		{
		 "name": "Botswana",
		 "flag": "🇧🇼",
		 "code": "BW",
		 "dial_code": "+267"
		},
		{
		 "name": "Bouvet Island",
		 "flag": "🇧🇻",
		 "code": "BV",
		 "dial_code": "+47"
		},
		{
		 "name": "Brazil",
		 "flag": "🇧🇷",
		 "code": "BR",
		 "dial_code": "+55"
		},
		{
		 "name": "British Indian Ocean Territory",
		 "flag": "🇮🇴",
		 "code": "IO",
		 "dial_code": "+246"
		},
		{
		 "name": "Brunei Darussalam",
		 "flag": "🇧🇳",
		 "code": "BN",
		 "dial_code": "+673"
		},
		{
		 "name": "Bulgaria",
		 "flag": "🇧🇬",
		 "code": "BG",
		 "dial_code": "+359"
		},
		{
		 "name": "Burkina Faso",
		 "flag": "🇧🇫",
		 "code": "BF",
		 "dial_code": "+226"
		},
		{
		 "name": "Burundi",
		 "flag": "🇧🇮",
		 "code": "BI",
		 "dial_code": "+257"
		},
		{
		 "name": "Cambodia",
		 "flag": "🇰🇭",
		 "code": "KH",
		 "dial_code": "+855"
		},
		{
		 "name": "Cameroon",
		 "flag": "🇨🇲",
		 "code": "CM",
		 "dial_code": "+237"
		},
		{
		 "name": "Canada",
		 "flag": "🇨🇦",
		 "code": "CA",
		 "dial_code": "+1"
		},
		{
		 "name": "Cape Verde",
		 "flag": "🇨🇻",
		 "code": "CV",
		 "dial_code": "+238"
		},
		{
		 "name": "Cayman Islands",
		 "flag": "🇰🇾",
		 "code": "KY",
		 "dial_code": "+345"
		},
		{
		 "name": "Central African Republic",
		 "flag": "🇨🇫",
		 "code": "CF",
		 "dial_code": "+236"
		},
		{
		 "name": "Chad",
		 "flag": "🇹🇩",
		 "code": "TD",
		 "dial_code": "+235"
		},
		{
		 "name": "Chile",
		 "flag": "🇨🇱",
		 "code": "CL",
		 "dial_code": "+56"
		},
		{
		 "name": "China",
		 "flag": "🇨🇳",
		 "code": "CN",
		 "dial_code": "+86"
		},
		{
		 "name": "Christmas Island",
		 "flag": "🇨🇽",
		 "code": "CX",
		 "dial_code": "+61"
		},
		{
		 "name": "Cocos (Keeling) Islands",
		 "flag": "🇨🇨",
		 "code": "CC",
		 "dial_code": "+61"
		},
		{
		 "name": "Colombia",
		 "flag": "🇨🇴",
		 "code": "CO",
		 "dial_code": "+57"
		},
		{
		 "name": "Comoros",
		 "flag": "🇰🇲",
		 "code": "KM",
		 "dial_code": "+269"
		},
		{
		 "name": "Congo",
		 "flag": "🇨🇬",
		 "code": "CG",
		 "dial_code": "+242"
		},
		{
		 "name": "Congo, The Democratic Republic of the Congo",
		 "flag": "🇨🇩",
		 "code": "CD",
		 "dial_code": "+243"
		},
		{
		 "name": "Cook Islands",
		 "flag": "🇨🇰",
		 "code": "CK",
		 "dial_code": "+682"
		},
		{
		 "name": "Costa Rica",
		 "flag": "🇨🇷",
		 "code": "CR",
		 "dial_code": "+506"
		},
		{
		 "name": "Cote d'Ivoire",
		 "flag": "🇨🇮",
		 "code": "CI",
		 "dial_code": "+225"
		},
		{
		 "name": "Croatia",
		 "flag": "🇭🇷",
		 "code": "HR",
		 "dial_code": "+385"
		},
		{
		 "name": "Cuba",
		 "flag": "🇨🇺",
		 "code": "CU",
		 "dial_code": "+53"
		},
		{
		 "name": "Cyprus",
		 "flag": "🇨🇾",
		 "code": "CY",
		 "dial_code": "+357"
		},
		{
		 "name": "Czech Republic",
		 "flag": "🇨🇿",
		 "code": "CZ",
		 "dial_code": "+420"
		},
		{
		 "name": "Denmark",
		 "flag": "🇩🇰",
		 "code": "DK",
		 "dial_code": "+45"
		},
		{
		 "name": "Djibouti",
		 "flag": "🇩🇯",
		 "code": "DJ",
		 "dial_code": "+253"
		},
		{
		 "name": "Dominica",
		 "flag": "🇩🇲",
		 "code": "DM",
		 "dial_code": "+1767"
		},
		{
		 "name": "Dominican Republic",
		 "flag": "🇩🇴",
		 "code": "DO",
		 "dial_code": "+1849"
		},
		{
		 "name": "Ecuador",
		 "flag": "🇪🇨",
		 "code": "EC",
		 "dial_code": "+593"
		},
		{
		 "name": "Egypt",
		 "flag": "🇪🇬",
		 "code": "EG",
		 "dial_code": "+20"
		},
		{
		 "name": "El Salvador",
		 "flag": "🇸🇻",
		 "code": "SV",
		 "dial_code": "+503"
		},
		{
		 "name": "Equatorial Guinea",
		 "flag": "🇬🇶",
		 "code": "GQ",
		 "dial_code": "+240"
		},
		{
		 "name": "Eritrea",
		 "flag": "🇪🇷",
		 "code": "ER",
		 "dial_code": "+291"
		},
		{
		 "name": "Estonia",
		 "flag": "🇪🇪",
		 "code": "EE",
		 "dial_code": "+372"
		},
		{
		 "name": "Ethiopia",
		 "flag": "🇪🇹",
		 "code": "ET",
		 "dial_code": "+251"
		},
		{
		 "name": "Falkland Islands (Malvinas)",
		 "flag": "🇫🇰",
		 "code": "FK",
		 "dial_code": "+500"
		},
		{
		 "name": "Faroe Islands",
		 "flag": "🇫🇴",
		 "code": "FO",
		 "dial_code": "+298"
		},
		{
		 "name": "Fiji",
		 "flag": "🇫🇯",
		 "code": "FJ",
		 "dial_code": "+679"
		},
		{
		 "name": "Finland",
		 "flag": "🇫🇮",
		 "code": "FI",
		 "dial_code": "+358"
		},
		{
		 "name": "France",
		 "flag": "🇫🇷",
		 "code": "FR",
		 "dial_code": "+33"
		},
		{
		 "name": "French Guiana",
		 "flag": "🇬🇫",
		 "code": "GF",
		 "dial_code": "+594"
		},
		{
		 "name": "French Polynesia",
		 "flag": "🇵🇫",
		 "code": "PF",
		 "dial_code": "+689"
		},
		{
		 "name": "French Southern Territories",
		 "flag": "🇹🇫",
		 "code": "TF",
		 "dial_code": "+262"
		},
		{
		 "name": "Gabon",
		 "flag": "🇬🇦",
		 "code": "GA",
		 "dial_code": "+241"
		},
		{
		 "name": "Gambia",
		 "flag": "🇬🇲",
		 "code": "GM",
		 "dial_code": "+220"
		},
		{
		 "name": "Georgia",
		 "flag": "🇬🇪",
		 "code": "GE",
		 "dial_code": "+995"
		},
		{
		 "name": "Germany",
		 "flag": "🇩🇪",
		 "code": "DE",
		 "dial_code": "+49"
		},
		{
		 "name": "Ghana",
		 "flag": "🇬🇭",
		 "code": "GH",
		 "dial_code": "+233"
		},
		{
		 "name": "Gibraltar",
		 "flag": "🇬🇮",
		 "code": "GI",
		 "dial_code": "+350"
		},
		{
		 "name": "Great Britain",
		 "flag": "🇬🇧",
		 "code": "GB",
		 "dial_code": "+44"
		},
		{
		 "name": "Greece",
		 "flag": "🇬🇷",
		 "code": "GR",
		 "dial_code": "+30"
		},
		{
		 "name": "Greenland",
		 "flag": "🇬🇱",
		 "code": "GL",
		 "dial_code": "+299"
		},
		{
		 "name": "Grenada",
		 "flag": "🇬🇩",
		 "code": "GD",
		 "dial_code": "+1473"
		},
		{
		 "name": "Guadeloupe",
		 "flag": "🇬🇵",
		 "code": "GP",
		 "dial_code": "+590"
		},
		{
		 "name": "Guam",
		 "flag": "🇬🇺",
		 "code": "GU",
		 "dial_code": "+1671"
		},
		{
		 "name": "Guatemala",
		 "flag": "🇬🇹",
		 "code": "GT",
		 "dial_code": "+502"
		},
		{
		 "name": "Guernsey",
		 "flag": "🇬🇬",
		 "code": "GG",
		 "dial_code": "+44"
		},
		{
		 "name": "Guinea",
		 "flag": "🇬🇳",
		 "code": "GN",
		 "dial_code": "+224"
		},
		{
		 "name": "Guinea-Bissau",
		 "flag": "🇬🇼",
		 "code": "GW",
		 "dial_code": "+245"
		},
		{
		 "name": "Guyana",
		 "flag": "🇬🇾",
		 "code": "GY",
		 "dial_code": "+592"
		},
		{
		 "name": "Haiti",
		 "flag": "🇭🇹",
		 "code": "HT",
		 "dial_code": "+509"
		},
		{
		 "name": "Heard Island and Mcdonald Islands",
		 "flag": "🇭🇲",
		 "code": "HM",
		 "dial_code": "+672"
		},
		{
		 "name": "Holy See (Vatican City State)",
		 "flag": "🇻🇦",
		 "code": "VA",
		 "dial_code": "+379"
		},
		{
		 "name": "Honduras",
		 "flag": "🇭🇳",
		 "code": "HN",
		 "dial_code": "+504"
		},
		{
		 "name": "Hong Kong",
		 "flag": "🇭🇰",
		 "code": "HK",
		 "dial_code": "+852"
		},
		{
		 "name": "Hungary",
		 "flag": "🇭🇺",
		 "code": "HU",
		 "dial_code": "+36"
		},
		{
		 "name": "Iceland",
		 "flag": "🇮🇸",
		 "code": "IS",
		 "dial_code": "+354"
		},
		{
		 "name": "India",
		 "flag": "🇮🇳",
		 "code": "IN",
		 "dial_code": "+91"
		},
		{
		 "name": "Indonesia",
		 "flag": "🇮🇩",
		 "code": "ID",
		 "dial_code": "+62"
		},
		{
		 "name": "Iran, Islamic Republic of Iran",
		 "flag": "🇮🇷",
		 "code": "IR",
		 "dial_code": "+98"
		},
		{
		 "name": "Iraq",
		 "flag": "🇮🇶",
		 "code": "IQ",
		 "dial_code": "+964"
		},
		{
		 "name": "Ireland",
		 "flag": "🇮🇪",
		 "code": "IE",
		 "dial_code": "+353"
		},
		{
		 "name": "Isle of Man",
		 "flag": "🇮🇲",
		 "code": "IM",
		 "dial_code": "+44"
		},
		{
		 "name": "Israel",
		 "flag": "🇮🇱",
		 "code": "IL",
		 "dial_code": "+972"
		},
		{
		 "name": "Italy",
		 "flag": "🇮🇹",
		 "code": "IT",
		 "dial_code": "+39"
		},
		{
		 "name": "Jamaica",
		 "flag": "🇯🇲",
		 "code": "JM",
		 "dial_code": "+1876"
		},
		{
		 "name": "Japan",
		 "flag": "🇯🇵",
		 "code": "JP",
		 "dial_code": "+81"
		},
		{
		 "name": "Jersey",
		 "flag": "🇯🇪",
		 "code": "JE",
		 "dial_code": "+44"
		},
		{
		 "name": "Jordan",
		 "flag": "🇯🇴",
		 "code": "JO",
		 "dial_code": "+962"
		},
		{
		 "name": "Kazakhstan",
		 "flag": "🇰🇿",
		 "code": "KZ",
		 "dial_code": "+7"
		},
		{
		 "name": "Kenya",
		 "flag": "🇰🇪",
		 "code": "KE",
		 "dial_code": "+254"
		},
		{
		 "name": "Kiribati",
		 "flag": "🇰🇮",
		 "code": "KI",
		 "dial_code": "+686"
		},
		{
		 "name": "Korea, Democratic People's Republic of Korea",
		 "flag": "🇰🇵",
		 "code": "KP",
		 "dial_code": "+850"
		},
		{
		 "name": "Korea, Republic of South Korea",
		 "flag": "🇰🇷",
		 "code": "KR",
		 "dial_code": "+82"
		},
		{
		 "name": "Kosovo",
		 "flag": "🇽🇰",
		 "code": "XK",
		 "dial_code": "+383"
		},
		{
		 "name": "Kuwait",
		 "flag": "🇰🇼",
		 "code": "KW",
		 "dial_code": "+965"
		},
		{
		 "name": "Kyrgyzstan",
		 "flag": "🇰🇬",
		 "code": "KG",
		 "dial_code": "+996"
		},
		{
		 "name": "Laos",
		 "flag": "🇱🇦",
		 "code": "LA",
		 "dial_code": "+856"
		},
		{
		 "name": "Latvia",
		 "flag": "🇱🇻",
		 "code": "LV",
		 "dial_code": "+371"
		},
		{
		 "name": "Lebanon",
		 "flag": "🇱🇧",
		 "code": "LB",
		 "dial_code": "+961"
		},
		{
		 "name": "Lesotho",
		 "flag": "🇱🇸",
		 "code": "LS",
		 "dial_code": "+266"
		},
		{
		 "name": "Liberia",
		 "flag": "🇱🇷",
		 "code": "LR",
		 "dial_code": "+231"
		},
		{
		 "name": "Libyan Arab Jamahiriya",
		 "flag": "🇱🇾",
		 "code": "LY",
		 "dial_code": "+218"
		},
		{
		 "name": "Liechtenstein",
		 "flag": "🇱🇮",
		 "code": "LI",
		 "dial_code": "+423"
		},
		{
		 "name": "Lithuania",
		 "flag": "🇱🇹",
		 "code": "LT",
		 "dial_code": "+370"
		},
		{
		 "name": "Luxembourg",
		 "flag": "🇱🇺",
		 "code": "LU",
		 "dial_code": "+352"
		},
		{
		 "name": "Macao",
		 "flag": "🇲🇴",
		 "code": "MO",
		 "dial_code": "+853"
		},
		{
		 "name": "Macedonia",
		 "flag": "🇲🇰",
		 "code": "MK",
		 "dial_code": "+389"
		},
		{
		 "name": "Madagascar",
		 "flag": "🇲🇬",
		 "code": "MG",
		 "dial_code": "+261"
		},
		{
		 "name": "Malawi",
		 "flag": "🇲🇼",
		 "code": "MW",
		 "dial_code": "+265"
		},
		{
		 "name": "Malaysia",
		 "flag": "🇲🇾",
		 "code": "MY",
		 "dial_code": "+60"
		},
		{
		 "name": "Maldives",
		 "flag": "🇲🇻",
		 "code": "MV",
		 "dial_code": "+960"
		},
		{
		 "name": "Mali",
		 "flag": "🇲🇱",
		 "code": "ML",
		 "dial_code": "+223"
		},
		{
		 "name": "Malta",
		 "flag": "🇲🇹",
		 "code": "MT",
		 "dial_code": "+356"
		},
		{
		 "name": "Marshall Islands",
		 "flag": "🇲🇭",
		 "code": "MH",
		 "dial_code": "+692"
		},
		{
		 "name": "Martinique",
		 "flag": "🇲🇶",
		 "code": "MQ",
		 "dial_code": "+596"
		},
		{
		 "name": "Mauritania",
		 "flag": "🇲🇷",
		 "code": "MR",
		 "dial_code": "+222"
		},
		{
		 "name": "Mauritius",
		 "flag": "🇲🇺",
		 "code": "MU",
		 "dial_code": "+230"
		},
		{
		 "name": "Mayotte",
		 "flag": "🇾🇹",
		 "code": "YT",
		 "dial_code": "+262"
		},
		{
		 "name": "Mexico",
		 "flag": "🇲🇽",
		 "code": "MX",
		 "dial_code": "+52"
		},
		{
		 "name": "Micronesia, Federated States of Micronesia",
		 "flag": "🇫🇲",
		 "code": "FM",
		 "dial_code": "+691"
		},
		{
		 "name": "Moldova",
		 "flag": "🇲🇩",
		 "code": "MD",
		 "dial_code": "+373"
		},
		{
		 "name": "Monaco",
		 "flag": "🇲🇨",
		 "code": "MC",
		 "dial_code": "+377"
		},
		{
		 "name": "Mongolia",
		 "flag": "🇲🇳",
		 "code": "MN",
		 "dial_code": "+976"
		},
		{
		 "name": "Montenegro",
		 "flag": "🇲🇪",
		 "code": "ME",
		 "dial_code": "+382"
		},
		{
		 "name": "Montserrat",
		 "flag": "🇲🇸",
		 "code": "MS",
		 "dial_code": "+1664"
		},
		{
		 "name": "Morocco",
		 "flag": "🇲🇦",
		 "code": "MA",
		 "dial_code": "+212"
		},
		{
		 "name": "Mozambique",
		 "flag": "🇲🇿",
		 "code": "MZ",
		 "dial_code": "+258"
		},
		{
		 "name": "Myanmar",
		 "flag": "🇲🇲",
		 "code": "MM",
		 "dial_code": "+95"
		},
		{
		 "name": "Namibia",
		 "flag": "🇳🇦",
		 "code": "NA",
		 "dial_code": "+264"
		},
		{
		 "name": "Nauru",
		 "flag": "🇳🇷",
		 "code": "NR",
		 "dial_code": "+674"
		},
		{
		 "name": "Nepal",
		 "flag": "🇳🇵",
		 "code": "NP",
		 "dial_code": "+977"
		},
		{
		 "name": "Netherlands",
		 "flag": "🇳🇱",
		 "code": "NL",
		 "dial_code": "+31"
		},
		{
		 "name": "Netherlands Antilles",
		 "code": "AN",
		 "dial_code": "+599",
		 "flag": "",
		},
		{
		 "name": "New Caledonia",
		 "flag": "🇳🇨",
		 "code": "NC",
		 "dial_code": "+687"
		},
		{
		 "name": "New Zealand",
		 "flag": "🇳🇿",
		 "code": "NZ",
		 "dial_code": "+64"
		},
		{
		 "name": "Nicaragua",
		 "flag": "🇳🇮",
		 "code": "NI",
		 "dial_code": "+505"
		},
		{
		 "name": "Niger",
		 "flag": "🇳🇪",
		 "code": "NE",
		 "dial_code": "+227"
		},
		{
		 "name": "Nigeria",
		 "flag": "🇳🇬",
		 "code": "NG",
		 "dial_code": "+234"
		},
		{
		 "name": "Niue",
		 "flag": "🇳🇺",
		 "code": "NU",
		 "dial_code": "+683"
		},
		{
		 "name": "Norfolk Island",
		 "flag": "🇳🇫",
		 "code": "NF",
		 "dial_code": "+672"
		},
		{
		 "name": "Northern Mariana Islands",
		 "flag": "🇲🇵",
		 "code": "MP",
		 "dial_code": "+1670"
		},
		{
		 "name": "Norway",
		 "flag": "🇳🇴",
		 "code": "NO",
		 "dial_code": "+47"
		},
		{
		 "name": "Oman",
		 "flag": "🇴🇲",
		 "code": "OM",
		 "dial_code": "+968"
		},
		{
		 "name": "Pakistan",
		 "flag": "🇵🇰",
		 "code": "PK",
		 "dial_code": "+92"
		},
		{
		 "name": "Palau",
		 "flag": "🇵🇼",
		 "code": "PW",
		 "dial_code": "+680"
		},
		{
		 "name": "Palestinian Territory, Occupied",
		 "flag": "🇵🇸",
		 "code": "PS",
		 "dial_code": "+970"
		},
		{
		 "name": "Panama",
		 "flag": "🇵🇦",
		 "code": "PA",
		 "dial_code": "+507"
		},
		{
		 "name": "Papua New Guinea",
		 "flag": "🇵🇬",
		 "code": "PG",
		 "dial_code": "+675"
		},
		{
		 "name": "Paraguay",
		 "flag": "🇵🇾",
		 "code": "PY",
		 "dial_code": "+595"
		},
		{
		 "name": "Peru",
		 "flag": "🇵🇪",
		 "code": "PE",
		 "dial_code": "+51"
		},
		{
		 "name": "Philippines",
		 "flag": "🇵🇭",
		 "code": "PH",
		 "dial_code": "+63"
		},
		{
		 "name": "Pitcairn",
		 "flag": "🇵🇳",
		 "code": "PN",
		 "dial_code": "+64"
		},
		{
		 "name": "Poland",
		 "flag": "🇵🇱",
		 "code": "PL",
		 "dial_code": "+48"
		},
		{
		 "name": "Portugal",
		 "flag": "🇵🇹",
		 "code": "PT",
		 "dial_code": "+351"
		},
		{
		 "name": "Puerto Rico",
		 "flag": "🇵🇷",
		 "code": "PR",
		 "dial_code": "+1939"
		},
		{
		 "name": "Qatar",
		 "flag": "🇶🇦",
		 "code": "QA",
		 "dial_code": "+974"
		},
		{
		 "name": "Romania",
		 "flag": "🇷🇴",
		 "code": "RO",
		 "dial_code": "+40"
		},
		{
		 "name": "Russia",
		 "flag": "🇷🇺",
		 "code": "RU",
		 "dial_code": "+7"
		},
		{
		 "name": "Rwanda",
		 "flag": "🇷🇼",
		 "code": "RW",
		 "dial_code": "+250"
		},
		{
		 "name": "Reunion",
		 "flag": "🇷🇪",
		 "code": "RE",
		 "dial_code": "+262"
		},
		{
		 "name": "Saint Barthelemy",
		 "flag": "🇧🇱",
		 "code": "BL",
		 "dial_code": "+590"
		},
		{
		 "name": "Saint Helena, Ascension and Tristan Da Cunha",
		 "flag": "🇸🇭",
		 "code": "SH",
		 "dial_code": "+290"
		},
		{
		 "name": "Saint Kitts and Nevis",
		 "flag": "🇰🇳",
		 "code": "KN",
		 "dial_code": "+1869"
		},
		{
		 "name": "Saint Lucia",
		 "flag": "🇱🇨",
		 "code": "LC",
		 "dial_code": "+1758"
		},
		{
		 "name": "Saint Martin",
		 "flag": "🇲🇫",
		 "code": "MF",
		 "dial_code": "+590"
		},
		{
		 "name": "Saint Pierre and Miquelon",
		 "flag": "🇵🇲",
		 "code": "PM",
		 "dial_code": "+508"
		},
		{
		 "name": "Saint Vincent and the Grenadines",
		 "flag": "🇻🇨",
		 "code": "VC",
		 "dial_code": "+1784"
		},
		{
		 "name": "Samoa",
		 "flag": "🇼🇸",
		 "code": "WS",
		 "dial_code": "+685"
		},
		{
		 "name": "San Marino",
		 "flag": "🇸🇲",
		 "code": "SM",
		 "dial_code": "+378"
		},
		{
		 "name": "Sao Tome and Principe",
		 "flag": "🇸🇹",
		 "code": "ST",
		 "dial_code": "+239"
		},
		{
		 "name": "Saudi Arabia",
		 "flag": "🇸🇦",
		 "code": "SA",
		 "dial_code": "+966"
		},
		{
		 "name": "Senegal",
		 "flag": "🇸🇳",
		 "code": "SN",
		 "dial_code": "+221"
		},
		{
		 "name": "Serbia",
		 "flag": "🇷🇸",
		 "code": "RS",
		 "dial_code": "+381"
		},
		{
		 "name": "Seychelles",
		 "flag": "🇸🇨",
		 "code": "SC",
		 "dial_code": "+248"
		},
		{
		 "name": "Sierra Leone",
		 "flag": "🇸🇱",
		 "code": "SL",
		 "dial_code": "+232"
		},
		{
		 "name": "Singapore",
		 "flag": "🇸🇬",
		 "code": "SG",
		 "dial_code": "+65"
		},
		{
		 "name": "Slovakia",
		 "flag": "🇸🇰",
		 "code": "SK",
		 "dial_code": "+421"
		},
		{
		 "name": "Slovenia",
		 "flag": "🇸🇮",
		 "code": "SI",
		 "dial_code": "+386"
		},
		{
		 "name": "Solomon Islands",
		 "flag": "🇸🇧",
		 "code": "SB",
		 "dial_code": "+677"
		},
		{
		 "name": "Somalia",
		 "flag": "🇸🇴",
		 "code": "SO",
		 "dial_code": "+252"
		},
		{
		 "name": "South Africa",
		 "flag": "🇿🇦",
		 "code": "ZA",
		 "dial_code": "+27"
		},
		{
		 "name": "South Sudan",
		 "flag": "🇸🇸",
		 "code": "SS",
		 "dial_code": "+211"
		},
		{
		 "name": "South Georgia and the South Sandwich Islands",
		 "flag": "🇬🇸",
		 "code": "GS",
		 "dial_code": "+500"
		},
		{
		 "name": "Spain",
		 "flag": "🇪🇸",
		 "code": "ES",
		 "dial_code": "+34"
		},
		{
		 "name": "Sri Lanka",
		 "flag": "🇱🇰",
		 "code": "LK",
		 "dial_code": "+94"
		},
		{
		 "name": "Sudan",
		 "flag": "🇸🇩",
		 "code": "SD",
		 "dial_code": "+249"
		},
		{
		 "name": "Suriname",
		 "flag": "🇸🇷",
		 "code": "SR",
		 "dial_code": "+597"
		},
		{
		 "name": "Svalbard and Jan Mayen",
		 "flag": "🇸🇯",
		 "code": "SJ",
		 "dial_code": "+47"
		},
		{
		 "name": "Swaziland",
		 "flag": "🇸🇿",
		 "code": "SZ",
		 "dial_code": "+268"
		},
		{
		 "name": "Sweden",
		 "flag": "🇸🇪",
		 "code": "SE",
		 "dial_code": "+46"
		},
		{
		 "name": "Switzerland",
		 "flag": "🇨🇭",
		 "code": "CH",
		 "dial_code": "+41"
		},
		{
		 "name": "Syrian Arab Republic",
		 "flag": "🇸🇾",
		 "code": "SY",
		 "dial_code": "+963"
		},
		{
		 "name": "Taiwan",
		 "flag": "🇹🇼",
		 "code": "TW",
		 "dial_code": "+886"
		},
		{
		 "name": "Tajikistan",
		 "flag": "🇹🇯",
		 "code": "TJ",
		 "dial_code": "+992"
		},
		{
		 "name": "Tanzania, United Republic of Tanzania",
		 "flag": "🇹🇿",
		 "code": "TZ",
		 "dial_code": "+255"
		},
		{
		 "name": "Thailand",
		 "flag": "🇹🇭",
		 "code": "TH",
		 "dial_code": "+66"
		},
		{
		 "name": "Timor-Leste",
		 "flag": "🇹🇱",
		 "code": "TL",
		 "dial_code": "+670"
		},
		{
		 "name": "Togo",
		 "flag": "🇹🇬",
		 "code": "TG",
		 "dial_code": "+228"
		},
		{
		 "name": "Tokelau",
		 "flag": "🇹🇰",
		 "code": "TK",
		 "dial_code": "+690"
		},
		{
		 "name": "Tonga",
		 "flag": "🇹🇴",
		 "code": "TO",
		 "dial_code": "+676"
		},
		{
		 "name": "Trinidad and Tobago",
		 "flag": "🇹🇹",
		 "code": "TT",
		 "dial_code": "+1868"
		},
		{
		 "name": "Tunisia",
		 "flag": "🇹🇳",
		 "code": "TN",
		 "dial_code": "+216"
		},
		{
		 "name": "Turkey",
		 "flag": "🇹🇷",
		 "code": "TR",
		 "dial_code": "+90"
		},
		{
		 "name": "Turkmenistan",
		 "flag": "🇹🇲",
		 "code": "TM",
		 "dial_code": "+993"
		},
		{
		 "name": "Turks and Caicos Islands",
		 "flag": "🇹🇨",
		 "code": "TC",
		 "dial_code": "+1649"
		},
		{
		 "name": "Tuvalu",
		 "flag": "🇹🇻",
		 "code": "TV",
		 "dial_code": "+688"
		},
		{
		 "name": "Uganda",
		 "flag": "🇺🇬",
		 "code": "UG",
		 "dial_code": "+256"
		},
		{
		 "name": "Ukraine",
		 "flag": "🇺🇦",
		 "code": "UA",
		 "dial_code": "+380"
		},
		{
		 "name": "United Arab Emirates",
		 "flag": "🇦🇪",
		 "code": "AE",
		 "dial_code": "+971"
		},
		{
		 "name": "United Kingdom",
		 "flag": "UK",
		 "code": "UK",
		 "dial_code": "+44"
		},
		{
		 "name": "United States of America",
		 "flag": "🇺🇸",
		 "code": "US",
		 "dial_code": "+1"
		},
		{
		 "name": "Uruguay",
		 "flag": "🇺🇾",
		 "code": "UY",
		 "dial_code": "+598"
		},
		{
		 "name": "Uzbekistan",
		 "flag": "🇺🇿",
		 "code": "UZ",
		 "dial_code": "+998"
		},
		{
		 "name": "Vanuatu",
		 "flag": "🇻🇺",
		 "code": "VU",
		 "dial_code": "+678"
		},
		{
		 "name": "Venezuela, Bolivarian Republic of Venezuela",
		 "flag": "🇻🇪",
		 "code": "VE",
		 "dial_code": "+58"
		},
		{
		 "name": "Vietnam",
		 "flag": "🇻🇳",
		 "code": "VN",
		 "dial_code": "+84"
		},
		{
		 "name": "Virgin Islands, British",
		 "flag": "🇻🇬",
		 "code": "VG",
		 "dial_code": "+1284"
		},
		{
		 "name": "Virgin Islands, U.S.",
		 "flag": "🇻🇮",
		 "code": "VI",
		 "dial_code": "+1340"
		},
		{
		 "name": "Wallis and Futuna",
		 "flag": "🇼🇫",
		 "code": "WF",
		 "dial_code": "+681"
		},
		{
		 "name": "Yemen",
		 "flag": "🇾🇪",
		 "code": "YE",
		 "dial_code": "+967"
		},
		{
		 "name": "Zambia",
		 "flag": "🇿🇲",
		 "code": "ZM",
		 "dial_code": "+260"
		},
		{
		 "name": "Zimbabwe",
		 "flag": "🇿🇼",
		 "code": "ZW",
		 "dial_code": "+263"
		}
	   ]
		;


	public mask = "(000) 000-0000";
	public masks = [
		{
			"mask": "0000",
			"startsWith": "247",
			"country": "AC",
			"lazy": true
		},
		{
			"mask": "000-000",
			"startsWith": "376",
			"country": "AD",
			"lazy": true
		},
		{
			"mask": "00-000-0000",
			"startsWith": "9715",
			"country": "AE",
			"lazy": true
		},
		{
			"mask": "0-000-0000",
			"startsWith": "971",
			"country": "AE",
			"lazy": true
		},
		{
			"mask": "00-000-0000",
			"startsWith": "93",
			"country": "AF",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "1268",
			"country": "AG",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "1264",
			"country": "AI",
			"lazy": true
		},
		{
			"mask": "(000)000-000",
			"startsWith": "355",
			"country": "AL",
			"lazy": true
		},
		{
			"mask": "00-000-000",
			"startsWith": "374",
			"country": "AM",
			"lazy": true
		},
		{
			"mask": "000-0000",
			"startsWith": "599",
			"country": "AN",
			"lazy": true
		},
		{
			"mask": "000-0000",
			"startsWith": "599",
			"country": "AN",
			"lazy": true
		},
		{
			"mask": "0000-0000",
			"startsWith": "5999",
			"country": "AN",
			"lazy": true
		},
		{
			"mask": "(000)000-000",
			"startsWith": "244",
			"country": "AO",
			"lazy": true
		},
		{
			"mask": "000-000",
			"startsWith": "6721",
			"country": "AQ",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "54",
			"country": "AR",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "1684",
			"country": "AS",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "43",
			"country": "AT",
			"lazy": true
		},
		{
			"mask": "0-0000-0000",
			"startsWith": "61",
			"country": "AU",
			"lazy": true
		},
		{
			"mask": "000-0000",
			"startsWith": "297",
			"country": "AW",
			"lazy": true
		},
		{
			"mask": "00-000-00-0",
			"startsWith": "994",
			"country": "AZ",
			"lazy": true
		},
		{
			"mask": "00-00000",
			"startsWith": "387",
			"country": "BA",
			"lazy": true
		},
		{
			"mask": "00-0000",
			"startsWith": "387",
			"country": "BA",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "1246",
			"country": "BB",
			"lazy": true
		},
		{
			"mask": "00-000-000",
			"startsWith": "880",
			"country": "BD",
			"lazy": true
		},
		{
			"mask": "(000)000-000",
			"startsWith": "32",
			"country": "BE",
			"lazy": true
		},
		{
			"mask": "00-00-0000",
			"startsWith": "226",
			"country": "BF",
			"lazy": true
		},
		{
			"mask": "(000)000-000",
			"startsWith": "359",
			"country": "BG",
			"lazy": true
		},
		{
			"mask": "0000-0000",
			"startsWith": "973",
			"country": "BH",
			"lazy": true
		},
		{
			"mask": "00-00-0000",
			"startsWith": "257",
			"country": "BI",
			"lazy": true
		},
		{
			"mask": "00-00-0000",
			"startsWith": "229",
			"country": "BJ",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "1441",
			"country": "BM",
			"lazy": true
		},
		{
			"mask": "000-0000",
			"startsWith": "673",
			"country": "BN",
			"lazy": true
		},
		{
			"mask": "0-000-0000",
			"startsWith": "591",
			"country": "BO",
			"lazy": true
		},
		{
			"mask": "(00)0000-0000",
			"startsWith": "55",
			"country": "BR",
			"lazy": true
		},
		{
			"mask": "(00)0000-0000",
			"startsWith": "557",
			"country": "BR",
			"lazy": true
		},
		{
			"mask": "(00)00000-0000",
			"startsWith": "559",
			"country": "BR",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "1242",
			"country": "BS",
			"lazy": true
		},
		{
			"mask": "00-000-000",
			"startsWith": "97517",
			"country": "BT",
			"lazy": true
		},
		{
			"mask": "0-000-000",
			"startsWith": "975",
			"country": "BT",
			"lazy": true
		},
		{
			"mask": "00-000-000",
			"startsWith": "267",
			"country": "BW",
			"lazy": true
		},
		{
			"mask": "(00)000-00-0",
			"startsWith": "375",
			"country": "BY",
			"lazy": true
		},
		{
			"mask": "000-0000",
			"startsWith": "501",
			"country": "BZ",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "1",
			"country": "CA",
			"lazy": true
		},
		{
			"mask": "(000)000-000",
			"startsWith": "243",
			"country": "CD",
			"lazy": true
		},
		{
			"mask": "00-00-0000",
			"startsWith": "236",
			"country": "CF",
			"lazy": true
		},
		{
			"mask": "00-000-0000",
			"startsWith": "242",
			"country": "CG",
			"lazy": true
		},
		{
			"mask": "00-000-0000",
			"startsWith": "41",
			"country": "CH",
			"lazy": true
		},
		{
			"mask": "00-000-000",
			"startsWith": "225",
			"country": "CI",
			"lazy": true
		},
		{
			"mask": "00-000",
			"startsWith": "682",
			"country": "CK",
			"lazy": true
		},
		{
			"mask": "0-0000-0000",
			"startsWith": "56",
			"country": "CL",
			"lazy": true
		},
		{
			"mask": "0000-0000",
			"startsWith": "237",
			"country": "CM",
			"lazy": true
		},
		{
			"mask": "(000)0000-0000",
			"startsWith": "86",
			"country": "CN",
			"lazy": true
		},
		{
			"mask": "(000)0000-000",
			"startsWith": "86",
			"country": "CN",
			"lazy": true
		},
		{
			"mask": "00-00000-00000",
			"startsWith": "86",
			"country": "CN",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "57",
			"country": "CO",
			"lazy": true
		},
		{
			"mask": "0000-0000",
			"startsWith": "506",
			"country": "CR",
			"lazy": true
		},
		{
			"mask": "0-000-0000",
			"startsWith": "53",
			"country": "CU",
			"lazy": true
		},
		{
			"mask": "(000)00-00",
			"startsWith": "238",
			"country": "CV",
			"lazy": true
		},
		{
			"mask": "000-0000",
			"startsWith": "599",
			"country": "CW",
			"lazy": true
		},
		{
			"mask": "00-000-000",
			"startsWith": "357",
			"country": "CY",
			"lazy": true
		},
		{
			"mask": "(000)000-000",
			"startsWith": "420",
			"country": "CZ",
			"lazy": true
		},
		{
			"mask": "(0000)000-0000",
			"startsWith": "49",
			"country": "DE",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "49",
			"country": "DE",
			"lazy": true
		},
		{
			"mask": "(000)00-0000",
			"startsWith": "49",
			"country": "DE",
			"lazy": true
		},
		{
			"mask": "(000)00-000",
			"startsWith": "49",
			"country": "DE",
			"lazy": true
		},
		{
			"mask": "(000)00-00",
			"startsWith": "49",
			"country": "DE",
			"lazy": true
		},
		{
			"mask": "000-000",
			"startsWith": "49",
			"country": "DE",
			"lazy": true
		},
		{
			"mask": "00-00-00-0",
			"startsWith": "253",
			"country": "DJ",
			"lazy": true
		},
		{
			"mask": "00-00-00-00",
			"startsWith": "45",
			"country": "DK",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "1767",
			"country": "DM",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "1809",
			"country": "DO",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "1829",
			"country": "DO",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "1849",
			"country": "DO",
			"lazy": true
		},
		{
			"mask": "00-000-0000",
			"startsWith": "213",
			"country": "DZ",
			"lazy": true
		},
		{
			"mask": "00-000-0000",
			"startsWith": "593",
			"country": "EC",
			"lazy": true
		},
		{
			"mask": "0-000-0000",
			"startsWith": "593",
			"country": "EC",
			"lazy": true
		},
		{
			"mask": "0000-0000",
			"startsWith": "372",
			"country": "EE",
			"lazy": true
		},
		{
			"mask": "000-0000",
			"startsWith": "372",
			"country": "EE",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "20",
			"country": "EG",
			"lazy": true
		},
		{
			"mask": "0-000-000",
			"startsWith": "291",
			"country": "ER",
			"lazy": true
		},
		{
			"mask": "(000)000-000",
			"startsWith": "34",
			"country": "ES",
			"lazy": true
		},
		{
			"mask": "00-000-0000",
			"startsWith": "251",
			"country": "ET",
			"lazy": true
		},
		{
			"mask": "(000)000-00-0",
			"startsWith": "358",
			"country": "FI",
			"lazy": true
		},
		{
			"mask": "00-00000",
			"startsWith": "679",
			"country": "FJ",
			"lazy": true
		},
		{
			"mask": "00000",
			"startsWith": "500",
			"country": "FK",
			"lazy": true
		},
		{
			"mask": "000-0000",
			"startsWith": "691",
			"country": "FM",
			"lazy": true
		},
		{
			"mask": "000-000",
			"startsWith": "298",
			"country": "FO",
			"lazy": true
		},
		{
			"mask": "00000-0000",
			"startsWith": "262",
			"country": "FR",
			"lazy": true
		},
		{
			"mask": "(000)000-000",
			"startsWith": "33",
			"country": "FR",
			"lazy": true
		},
		{
			"mask": "00-0000",
			"startsWith": "508",
			"country": "FR",
			"lazy": true
		},
		{
			"mask": "(000)000-000",
			"startsWith": "590",
			"country": "FR",
			"lazy": true
		},
		{
			"mask": "0-00-00-0",
			"startsWith": "241",
			"country": "GA",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "1473",
			"country": "GD",
			"lazy": true
		},
		{
			"mask": "(000)000-000",
			"startsWith": "995",
			"country": "GE",
			"lazy": true
		},
		{
			"mask": "00000-0000",
			"startsWith": "594",
			"country": "GF",
			"lazy": true
		},
		{
			"mask": "(000)000-000",
			"startsWith": "233",
			"country": "GH",
			"lazy": true
		},
		{
			"mask": "000-00000",
			"startsWith": "350",
			"country": "GI",
			"lazy": true
		},
		{
			"mask": "00-00-00",
			"startsWith": "299",
			"country": "GL",
			"lazy": true
		},
		{
			"mask": "(000)00-00",
			"startsWith": "220",
			"country": "GM",
			"lazy": true
		},
		{
			"mask": "00-000-000",
			"startsWith": "224",
			"country": "GN",
			"lazy": true
		},
		{
			"mask": "00-000-0000",
			"startsWith": "240",
			"country": "GQ",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "30",
			"country": "GR",
			"lazy": true
		},
		{
			"mask": "0-000-0000",
			"startsWith": "502",
			"country": "GT",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "1671",
			"country": "GU",
			"lazy": true
		},
		{
			"mask": "0-000000",
			"startsWith": "245",
			"country": "GW",
			"lazy": true
		},
		{
			"mask": "000-0000",
			"startsWith": "592",
			"country": "GY",
			"lazy": true
		},
		{
			"mask": "0000-0000",
			"startsWith": "852",
			"country": "HK",
			"lazy": true
		},
		{
			"mask": "0000-0000",
			"startsWith": "504",
			"country": "HN",
			"lazy": true
		},
		{
			"mask": "00-000-000",
			"startsWith": "385",
			"country": "HR",
			"lazy": true
		},
		{
			"mask": "00-00-0000",
			"startsWith": "509",
			"country": "HT",
			"lazy": true
		},
		{
			"mask": "(000)000-000",
			"startsWith": "36",
			"country": "HU",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "628",
			"country": "ID",
			"lazy": true
		},
		{
			"mask": "00-000-00",
			"startsWith": "62",
			"country": "ID",
			"lazy": true
		},
		{
			"mask": "00-000-000",
			"startsWith": "62",
			"country": "ID",
			"lazy": true
		},
		{
			"mask": "00-000-0000",
			"startsWith": "62",
			"country": "ID",
			"lazy": true
		},
		{
			"mask": "(000)000-000",
			"startsWith": "628",
			"country": "ID",
			"lazy": true
		},
		{
			"mask": "(000)000-00-00",
			"startsWith": "628",
			"country": "ID",
			"lazy": true
		},
		{
			"mask": "(000)000-000",
			"startsWith": "353",
			"country": "IE",
			"lazy": true
		},
		{
			"mask": "00-000-0000",
			"startsWith": "9725",
			"country": "IL",
			"lazy": true
		},
		{
			"mask": "0-000-0000",
			"startsWith": "972",
			"country": "IL",
			"lazy": true
		},
		{
			"mask": "(0000)000-000",
			"startsWith": "91",
			"country": "IN",
			"lazy": true
		},
		{
			"mask": "000-0000",
			"startsWith": "246",
			"country": "IO",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "964",
			"country": "IQ",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "98",
			"country": "IR",
			"lazy": true
		},
		{
			"mask": "000-0000",
			"startsWith": "354",
			"country": "IS",
			"lazy": true
		},
		{
			"mask": "(000)0000-000",
			"startsWith": "39",
			"country": "IT",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "1876",
			"country": "JM",
			"lazy": true
		},
		{
			"mask": "0-0000-0000",
			"startsWith": "962",
			"country": "JO",
			"lazy": true
		},
		{
			"mask": "00-0000-0000",
			"startsWith": "81",
			"country": "JP",
			"lazy": true
		},
		{
			"mask": "(000)000-000",
			"startsWith": "81",
			"country": "JP",
			"lazy": true
		},
		{
			"mask": "000-000000",
			"startsWith": "254",
			"country": "KE",
			"lazy": true
		},
		{
			"mask": "(000)000-000",
			"startsWith": "996",
			"country": "KG",
			"lazy": true
		},
		{
			"mask": "00-000-000",
			"startsWith": "855",
			"country": "KH",
			"lazy": true
		},
		{
			"mask": "00-000",
			"startsWith": "686",
			"country": "KI",
			"lazy": true
		},
		{
			"mask": "00-00000",
			"startsWith": "269",
			"country": "KM",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "1869",
			"country": "KN",
			"lazy": true
		},
		{
			"mask": "000-000-0000",
			"startsWith": "850191",
			"country": "KP",
			"lazy": true
		},
		{
			"mask": "00-000-000",
			"startsWith": "850",
			"country": "KP",
			"lazy": true
		},
		{
			"mask": "000-0000-000",
			"startsWith": "850",
			"country": "KP",
			"lazy": true
		},
		{
			"mask": "000-000",
			"startsWith": "850",
			"country": "KP",
			"lazy": true
		},
		{
			"mask": "0000-0000",
			"startsWith": "850",
			"country": "KP",
			"lazy": true
		},
		{
			"mask": "0000-0000000000000",
			"startsWith": "850",
			"country": "KP",
			"lazy": true
		},
		{
			"mask": "00-000-0000",
			"startsWith": "82",
			"country": "KR",
			"lazy": true
		},
		{
			"mask": "0000-0000",
			"startsWith": "965",
			"country": "KW",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "1345",
			"country": "KY",
			"lazy": true
		},
		{
			"mask": "(000)000-00-0",
			"startsWith": "76",
			"country": "KZ",
			"lazy": true
		},
		{
			"mask": "(000)000-00-0",
			"startsWith": "77",
			"country": "KZ",
			"lazy": true
		},
		{
			"mask": "(0000)000-000",
			"startsWith": "85620",
			"country": "LA",
			"lazy": true
		},
		{
			"mask": "00-000-000",
			"startsWith": "856",
			"country": "LA",
			"lazy": true
		},
		{
			"mask": "00-000-000",
			"startsWith": "961",
			"country": "LB",
			"lazy": true
		},
		{
			"mask": "0-000-000",
			"startsWith": "961",
			"country": "LB",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "1758",
			"country": "LC",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "423",
			"country": "LI",
			"lazy": true
		},
		{
			"mask": "00-000-0000",
			"startsWith": "94",
			"country": "LK",
			"lazy": true
		},
		{
			"mask": "00-000-000",
			"startsWith": "231",
			"country": "LR",
			"lazy": true
		},
		{
			"mask": "0-000-0000",
			"startsWith": "266",
			"country": "LS",
			"lazy": true
		},
		{
			"mask": "(000)00-000",
			"startsWith": "370",
			"country": "LT",
			"lazy": true
		},
		{
			"mask": "(000)000-000",
			"startsWith": "352",
			"country": "LU",
			"lazy": true
		},
		{
			"mask": "00-000-000",
			"startsWith": "371",
			"country": "LV",
			"lazy": true
		},
		{
			"mask": "00-000-000",
			"startsWith": "218",
			"country": "LY",
			"lazy": true
		},
		{
			"mask": "00-000-0000",
			"startsWith": "21821",
			"country": "LY",
			"lazy": true
		},
		{
			"mask": "00-0000-000",
			"startsWith": "212",
			"country": "MA",
			"lazy": true
		},
		{
			"mask": "(000)000-000",
			"startsWith": "377",
			"country": "MC",
			"lazy": true
		},
		{
			"mask": "00-000-000",
			"startsWith": "377",
			"country": "MC",
			"lazy": true
		},
		{
			"mask": "0000-0000",
			"startsWith": "373",
			"country": "MD",
			"lazy": true
		},
		{
			"mask": "00-000-000",
			"startsWith": "382",
			"country": "ME",
			"lazy": true
		},
		{
			"mask": "00-00-00000",
			"startsWith": "261",
			"country": "MG",
			"lazy": true
		},
		{
			"mask": "000-0000",
			"startsWith": "692",
			"country": "MH",
			"lazy": true
		},
		{
			"mask": "00-000-000",
			"startsWith": "389",
			"country": "MK",
			"lazy": true
		},
		{
			"mask": "00-00-0000",
			"startsWith": "223",
			"country": "ML",
			"lazy": true
		},
		{
			"mask": "00-000-000",
			"startsWith": "95",
			"country": "MM",
			"lazy": true
		},
		{
			"mask": "0-000-000",
			"startsWith": "95",
			"country": "MM",
			"lazy": true
		},
		{
			"mask": "000-000",
			"startsWith": "95",
			"country": "MM",
			"lazy": true
		},
		{
			"mask": "00-00-0000",
			"startsWith": "976",
			"country": "MN",
			"lazy": true
		},
		{
			"mask": "0000-0000",
			"startsWith": "853",
			"country": "MO",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "1670",
			"country": "MP",
			"lazy": true
		},
		{
			"mask": "(000)00-00-0",
			"startsWith": "596",
			"country": "MQ",
			"lazy": true
		},
		{
			"mask": "00-00-0000",
			"startsWith": "222",
			"country": "MR",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "1664",
			"country": "MS",
			"lazy": true
		},
		{
			"mask": "0000-0000",
			"startsWith": "356",
			"country": "MT",
			"lazy": true
		},
		{
			"mask": "000-0000",
			"startsWith": "230",
			"country": "MU",
			"lazy": true
		},
		{
			"mask": "000-0000",
			"startsWith": "960",
			"country": "MV",
			"lazy": true
		},
		{
			"mask": "0-000-000",
			"startsWith": "2651",
			"country": "MW",
			"lazy": true
		},
		{
			"mask": "0-0000-0000",
			"startsWith": "265",
			"country": "MW",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "52",
			"country": "MX",
			"lazy": true
		},
		{
			"mask": "00-00-0000",
			"startsWith": "52",
			"country": "MX",
			"lazy": true
		},
		{
			"mask": "00-000-0000",
			"startsWith": "60",
			"country": "MY",
			"lazy": true
		},
		{
			"mask": "(000)000-000",
			"startsWith": "60",
			"country": "MY",
			"lazy": true
		},
		{
			"mask": "00-000-000",
			"startsWith": "60",
			"country": "MY",
			"lazy": true
		},
		{
			"mask": "0-000-000",
			"startsWith": "60",
			"country": "MY",
			"lazy": true
		},
		{
			"mask": "00-000-000",
			"startsWith": "258",
			"country": "MZ",
			"lazy": true
		},
		{
			"mask": "00-000-0000",
			"startsWith": "264",
			"country": "NA",
			"lazy": true
		},
		{
			"mask": "00-0000",
			"startsWith": "687",
			"country": "NC",
			"lazy": true
		},
		{
			"mask": "00-00-0000",
			"startsWith": "227",
			"country": "NE",
			"lazy": true
		},
		{
			"mask": "000-000",
			"startsWith": "6723",
			"country": "NF",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "234",
			"country": "NG",
			"lazy": true
		},
		{
			"mask": "00-000-000",
			"startsWith": "234",
			"country": "NG",
			"lazy": true
		},
		{
			"mask": "00-000-00",
			"startsWith": "234",
			"country": "NG",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "234",
			"country": "NG",
			"lazy": true
		},
		{
			"mask": "0000-0000",
			"startsWith": "505",
			"country": "NI",
			"lazy": true
		},
		{
			"mask": "00-000-0000",
			"startsWith": "31",
			"country": "NL",
			"lazy": true
		},
		{
			"mask": "(000)00-000",
			"startsWith": "47",
			"country": "NO",
			"lazy": true
		},
		{
			"mask": "00-000-000",
			"startsWith": "977",
			"country": "NP",
			"lazy": true
		},
		{
			"mask": "000-0000",
			"startsWith": "674",
			"country": "NR",
			"lazy": true
		},
		{
			"mask": "0000",
			"startsWith": "683",
			"country": "NU",
			"lazy": true
		},
		{
			"mask": "(000)000-000",
			"startsWith": "64",
			"country": "NZ",
			"lazy": true
		},
		{
			"mask": "00-000-000",
			"startsWith": "64",
			"country": "NZ",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "64",
			"country": "NZ",
			"lazy": true
		},
		{
			"mask": "00-000-000",
			"startsWith": "968",
			"country": "OM",
			"lazy": true
		},
		{
			"mask": "000-0000",
			"startsWith": "507",
			"country": "PA",
			"lazy": true
		},
		{
			"mask": "(000)000-000",
			"startsWith": "51",
			"country": "PE",
			"lazy": true
		},
		{
			"mask": "00-00-00",
			"startsWith": "689",
			"country": "PF",
			"lazy": true
		},
		{
			"mask": "(000)00-000",
			"startsWith": "675",
			"country": "PG",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "63",
			"country": "PH",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "92",
			"country": "PK",
			"lazy": true
		},
		{
			"mask": "(000)000-000",
			"startsWith": "48",
			"country": "PL",
			"lazy": true
		},
		{
			"mask": "00-000-0000",
			"startsWith": "970",
			"country": "PS",
			"lazy": true
		},
		{
			"mask": "00-000-0000",
			"startsWith": "351",
			"country": "PT",
			"lazy": true
		},
		{
			"mask": "000-0000",
			"startsWith": "680",
			"country": "PW",
			"lazy": true
		},
		{
			"mask": "(000)000-000",
			"startsWith": "595",
			"country": "PY",
			"lazy": true
		},
		{
			"mask": "0000-0000",
			"startsWith": "974",
			"country": "QA",
			"lazy": true
		},
		{
			"mask": "00000-0000",
			"startsWith": "262",
			"country": "RE",
			"lazy": true
		},
		{
			"mask": "00-000-0000",
			"startsWith": "40",
			"country": "RO",
			"lazy": true
		},
		{
			"mask": "00-000-0000",
			"startsWith": "381",
			"country": "RS",
			"lazy": true
		},
		{
			"mask": "(000)000-00-00",
			"startsWith": "7",
			"country": "RU",
			"lazy": true
		},
		{
			"mask": "0(000)000-00-00",
			"startsWith": "8",
			"country": "RU",
			"lazy": true
		},
		{
			"mask": "(000)000-000",
			"startsWith": "250",
			"country": "RW",
			"lazy": true
		},
		{
			"mask": "0-0000-0000",
			"startsWith": "9665",
			"country": "SA",
			"lazy": true
		},
		{
			"mask": "0-000-0000",
			"startsWith": "966",
			"country": "SA",
			"lazy": true
		},
		{
			"mask": "000-0000",
			"startsWith": "677",
			"country": "SB",
			"lazy": true
		},
		{
			"mask": "00000",
			"startsWith": "677",
			"country": "SB",
			"lazy": true
		},
		{
			"mask": "0-000-000",
			"startsWith": "248",
			"country": "SC",
			"lazy": true
		},
		{
			"mask": "00-000-0000",
			"startsWith": "249",
			"country": "SD",
			"lazy": true
		},
		{
			"mask": "00-000-0000",
			"startsWith": "46",
			"country": "SE",
			"lazy": true
		},
		{
			"mask": "0000-0000",
			"startsWith": "65",
			"country": "SG",
			"lazy": true
		},
		{
			"mask": "0000",
			"startsWith": "290",
			"country": "SH",
			"lazy": true
		},
		{
			"mask": "0000",
			"startsWith": "290",
			"country": "SH",
			"lazy": true
		},
		{
			"mask": "00-000-000",
			"startsWith": "386",
			"country": "SI",
			"lazy": true
		},
		{
			"mask": "(000)000-000",
			"startsWith": "421",
			"country": "SK",
			"lazy": true
		},
		{
			"mask": "00-000000",
			"startsWith": "232",
			"country": "SL",
			"lazy": true
		},
		{
			"mask": "0000-000000",
			"startsWith": "378",
			"country": "SM",
			"lazy": true
		},
		{
			"mask": "00-000-0000",
			"startsWith": "221",
			"country": "SN",
			"lazy": true
		},
		{
			"mask": "00-000-000",
			"startsWith": "252",
			"country": "SO",
			"lazy": true
		},
		{
			"mask": "0-000-000",
			"startsWith": "252",
			"country": "SO",
			"lazy": true
		},
		{
			"mask": "0-000-000",
			"startsWith": "252",
			"country": "SO",
			"lazy": true
		},
		{
			"mask": "000-0000",
			"startsWith": "597",
			"country": "SR",
			"lazy": true
		},
		{
			"mask": "000-000",
			"startsWith": "597",
			"country": "SR",
			"lazy": true
		},
		{
			"mask": "00-000-0000",
			"startsWith": "211",
			"country": "SS",
			"lazy": true
		},
		{
			"mask": "00-00000",
			"startsWith": "239",
			"country": "ST",
			"lazy": true
		},
		{
			"mask": "00-00-0000",
			"startsWith": "503",
			"country": "SV",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "1721",
			"country": "SX",
			"lazy": true
		},
		{
			"mask": "00-0000-000",
			"startsWith": "963",
			"country": "SY",
			"lazy": true
		},
		{
			"mask": "00-00-0000",
			"startsWith": "268",
			"country": "SZ",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "1649",
			"country": "TC",
			"lazy": true
		},
		{
			"mask": "00-00-00-0",
			"startsWith": "235",
			"country": "TD",
			"lazy": true
		},
		{
			"mask": "00-000-000",
			"startsWith": "228",
			"country": "TG",
			"lazy": true
		},
		{
			"mask": "00-000-0000",
			"startsWith": "66",
			"country": "TH",
			"lazy": true
		},
		{
			"mask": "00-000-000",
			"startsWith": "66",
			"country": "TH",
			"lazy": true
		},
		{
			"mask": "00-000-0000",
			"startsWith": "992",
			"country": "TJ",
			"lazy": true
		},
		{
			"mask": "0000",
			"startsWith": "690",
			"country": "TK",
			"lazy": true
		},
		{
			"mask": "000-0000",
			"startsWith": "670",
			"country": "TL",
			"lazy": true
		},
		{
			"mask": "000-00000",
			"startsWith": "67077",
			"country": "TL",
			"lazy": true
		},
		{
			"mask": "000-00000",
			"startsWith": "67078",
			"country": "TL",
			"lazy": true
		},
		{
			"mask": "0-000-0000",
			"startsWith": "993",
			"country": "TM",
			"lazy": true
		},
		{
			"mask": "00-000-000",
			"startsWith": "216",
			"country": "TN",
			"lazy": true
		},
		{
			"mask": "00000",
			"startsWith": "676",
			"country": "TO",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "9",
			"country": "TR",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "1868",
			"country": "TT",
			"lazy": true
		},
		{
			"mask": "000000",
			"startsWith": "68890",
			"country": "TV",
			"lazy": true
		},
		{
			"mask": "00000",
			"startsWith": "6882",
			"country": "TV",
			"lazy": true
		},
		{
			"mask": "0-0000-0000",
			"startsWith": "886",
			"country": "TW",
			"lazy": true
		},
		{
			"mask": "0000-0000",
			"startsWith": "886",
			"country": "TW",
			"lazy": true
		},
		{
			"mask": "00-000-0000",
			"startsWith": "255",
			"country": "TZ",
			"lazy": true
		},
		{
			"mask": "(00)000-00-00",
			"startsWith": "380",
			"country": "UA",
			"lazy": true
		},
		{
			"mask": "(000)000-000",
			"startsWith": "256",
			"country": "UG",
			"lazy": true
		},
		{
			"mask": "00-0000-0000",
			"startsWith": "44",
			"country": "UK",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "1",
			"country": "US",
			"lazy": true
		},
		{
			"mask": "0-000-00-0",
			"startsWith": "598",
			"country": "UY",
			"lazy": true
		},
		{
			"mask": "00-000-0000",
			"startsWith": "998",
			"country": "UZ",
			"lazy": true
		},
		{
			"mask": "0-000-00000",
			"startsWith": "396698",
			"country": "VA",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "1784",
			"country": "VC",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "58",
			"country": "VE",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "1284",
			"country": "VG",
			"lazy": true
		},
		{
			"mask": "(000)000-0000",
			"startsWith": "1340",
			"country": "VI",
			"lazy": true
		},
		{
			"mask": "00-0000-000",
			"startsWith": "84",
			"country": "VN",
			"lazy": true
		},
		{
			"mask": "(000)0000-000",
			"startsWith": "84",
			"country": "VN",
			"lazy": true
		},
		{
			"mask": "00-00000",
			"startsWith": "678",
			"country": "VU",
			"lazy": true
		},
		{
			"mask": "00000",
			"startsWith": "678",
			"country": "VU",
			"lazy": true
		},
		{
			"mask": "00-0000",
			"startsWith": "681",
			"country": "WF",
			"lazy": true
		},
		{
			"mask": "00-0000",
			"startsWith": "685",
			"country": "WS",
			"lazy": true
		},
		{
			"mask": "000-000-000",
			"startsWith": "967",
			"country": "YE",
			"lazy": true
		},
		{
			"mask": "0-000-000",
			"startsWith": "967",
			"country": "YE",
			"lazy": true
		},
		{
			"mask": "00-000-000",
			"startsWith": "967",
			"country": "YE",
			"lazy": true
		},
		{
			"mask": "00-000-0000",
			"startsWith": "27",
			"country": "ZA",
			"lazy": true
		},
		{
			"mask": "00-000-0000",
			"startsWith": "260",
			"country": "ZM",
			"lazy": true
		},
		{
			"mask": "0-000000",
			"startsWith": "263",
			"country": "ZW",
			"lazy": true
		},
		{
			"mask": "0000000000000",
			"startsWith": "",
			"country": "unknown",
			"lazy": true
		}
	];

	public placeholder = "000000000000000";
	selectedLanguage = "en";
	@ViewChild("selectCountry") public dropdown: DropPanelComponent;
	constructor(private httpService: HttpService,private deviceService: DeviceDetectorService,  private languageService: LanguageService, private fb : FormBuilder,private googleAnalyticsService: GoogleAnalyticsService) {
		this.countries.sort(function(a, b) {
			if(a.name > b.name) {
				return 1;
			}
			else {
				return -1;
			}
		})
		this.form = this.fb.group({
			phoneNumber : ['', [Validators.required, RxwebValidators.pattern({expression:{'phoneNumber': /^[0-9]{4,15}$/}, message:'Phone number must be 4 to 15 digits' })]]
		})
	 }

	ngOnInit(): void {

		if(this.phoneCode && this.phone_no) {
			this.form.get("phoneNumber").setValue(this.phone_no)
		}
		this.isMobile = this.deviceService.isMobile();
		this.languageService.languageUpdates.subscribe(
			(lang)=>{
			  this.selectedLanguage = lang;
			}
		  )
	  this.selectedLanguage = this.languageService.GetLanguage();

	  this.form.get('phoneNumber').valueChanges.subscribe((val) => {


		this.valid = this.form.get('phoneNumber').valid;
		if(this.valid) {
			if (this.country && this.country.dial_code) {
				this.onChangeChange.emit(`${this.country.dial_code}${this.form.get('phoneNumber').value}`);
				this.onChangeInput.emit({country_code: this.country.code, code : this.country.dial_code, phone:this.form.get('phoneNumber').value})
			}
		}
	  })

	}

	public onEmit($event) {
		// this.valid = this.phoneNumber.valid;
		// if (this.country && this.country.dial_code) {
		// 	this.onChangeChange.emit(`${this.country.dial_code}${this.phoneNumber.value}`);
		// }
	}

	public onSelect(country) {
		this.country = country;


		const mask = this.masks.filter((m) => m.country === this.country.code);

		if (mask) {

			const format = mask.map((m) => m.mask);

			if (format.length > 0) {
				this.mask = format.join("||");

			} else {
				this.mask = format[0];

			}

			// this.placeholder = format[0];
		}
		// this.mask =

		this.dropdown._close();
	}

	numberOnly(event): boolean {
		const charCode = (event.which) ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
		}
		return true;

  }

  verifyMobile(){
	this
        .googleAnalyticsService
        .eventEmitter("LH_VerifyMobile", "ListHome", "LH_App_VerifyMobile");
  }

  update(verification) {

	this.phone_no = verification?.phone_no;
	this.phoneCode = verification?.code;
	if(this.phoneCode && this.phone_no) {
		this.form.get("phoneNumber").setValue(this.phone_no);
		let countryValue = this.countries.filter(country => {
			return country.dial_code == this.phoneCode
		})

		this.country = countryValue[0];

	}
  }

}
