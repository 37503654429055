import { ActivatedRoute } from '@angular/router';
// import { SpaceService } from './../../../shared/services/space.service';
// import { HttpErrorResponse, HttpEvent } from '@angular/common/http';
// import { Injectable } from '@angular/core';
// import {
//   Router, Resolve,
//   RouterStateSnapshot,
//   ActivatedRouteSnapshot
// } from '@angular/router';
// import { Observable,} from 'rxjs';


// @Injectable()
// export class AddHomeGuard implements Resolve<any> {

//   constructor(private spaceService: SpaceService, private router: Router) { 
//   }

//   resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<HttpEvent<any>> {
//     let isDesktopView: boolean = (window.screen.width > 1024);
//     const payload = {};
//     this.spaceService.create(payload).subscribe((response: { data?: any }) => {
//         const navigationExtras = { queryParams: { id: response.data.id } };
//             let commands;
//             if (isDesktopView) {
//               commands = ["/spaces/add-home"];
//             } else {
//               commands = [
//                 {
//                   outlets: {
//                     modal: ["modal", "spaces", "add-home"],
//                   },
//                 },
//               ];
//             }
//             this.router.navigate(commands, navigationExtras);
//       });
//       return false
//     // return this.adminService.getAdminDashboardData().pipe(
//     //     map((response: any) => {
//     //         return response.data;
//     //     }), 
//     // );
//   }

// }

import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { SpaceService } from '../../../shared/services/space.service';

@Injectable({
	providedIn: "root"
})
export class AddHomeAuthGuard implements CanActivate {
    paramId;
	constructor(
        private router: Router, 
        private spaceService: SpaceService,
        private route: ActivatedRoute,
        ) { 
        this.route.params.subscribe((params) => {
            this.paramId = params.id
          
          });
    }
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
            if(this.paramId){
                return true
            }else{
                // return false;
                return this._handleRequest();
            }
            
	}

	private _handleRequest() {
        let isDesktopView: boolean = (window.screen.width > 1024);
        const payload = {};
        this.spaceService.create(payload).subscribe((response: { data?: any }) => {
            // const navigationExtras = { queryParams: { id: response.data.id } };
            let commands;
            if (isDesktopView) {
                    commands = ["/spaces/add-home/"+response.data.id];
                } else {
                    commands = [{
                        outlets: {
                            modal: ["modal", "spaces", "add-home", response.data.id],
                        },
                    }];
                }
                    this.router.navigate(commands);
                });
		return false;
	}

}
