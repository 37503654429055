import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";


import { trigger, transition, animate, style } from "@angular/animations";
import { SnackbarService } from "../../services/snackbar.service";
import { ISnackbar } from "../../interfaces/snackbar";

@Component({
	selector: "app-snackbar",
	templateUrl: "./snackbar.component.html",
	styleUrls: ["./snackbar.component.scss"],
	animations: [
		trigger("state", [
			transition(":enter", [
				// style({ bottom: "0px", transform: "translate(0%, 0%) scale(0.3)" }),
				animate("150ms cubic-bezier(0, 0, 0.2, 1)", style({
					// transform: "translate(0%, 0%) scale(1)",
					opacity: 1,
					bottom: "20px"
				})),
			]),
			transition(":leave", [
				animate("150ms cubic-bezier(0.4, 0.0, 1, 1)", style({
					// transform: "translate(0%, 0%) scale(0.3)",
					opacity: 0,
					bottom: "-100px"
				}))
			])
		])
	]
})
export class SnackbarComponent implements OnInit, OnDestroy {
	public show = false;
	public snack: ISnackbar = {};
	private snackbarSubscription: Subscription;
	private _timeout: any;
	public snack_class = 'block';
	constructor(private snackbarService: SnackbarService) { }

	ngOnInit() {

		this.snackbarSubscription = this.snackbarService.snackbarState
			.subscribe(
				(state: ISnackbar) => {
					this.show = true;
					this.snack = state;
					this.snack_class = this.snack?.config?.class;



					if (this._timeout) {
						clearTimeout(this._timeout);
					}

					this._timeout = setTimeout(() => {
						this.show = false;
					}, state.duration);
				});
	}

	public onAction() {
		if (this.snack.actionCallback) {
			this.snack.actionCallback();
			this.onClose();
		}
	}

	public onClose() {
		if (this._timeout) {
			clearTimeout(this._timeout);
		}

		this.show = false;
	}

	ngOnDestroy() {
		this.snackbarSubscription.unsubscribe();
	}

}
