import { Injectable, Output } from "@angular/core";
import { map } from "rxjs/operators";
import { MessagingService } from "src/app/core/services/messaging.service";
import { HttpService } from "./http.service";
import { UserService } from 'src/app/shared/services/user.service';
import { Subject } from "rxjs";


@Injectable({
	providedIn: "root"
})
export class NotificationService {
	selectedFriend: any;

	@Output() public updateNotifyBadge = new Subject<boolean>();
	@Output() public updateTripNotifyBadge = new Subject<boolean>();

	constructor(private httpService: HttpService, private userService: UserService) { }

	public getNotifications(queryParams:any = { page : 1}) {
		let myId = localStorage.getItem("id");


		return this.httpService.get(`/get-notification/${myId}?page=${queryParams?.page}`);//.pipe(map((res: { data: any }) => res.data));
	}

	public sendFCMNotification(message: any) {

		let notifiactionData = {};

		this.userService.getFriendToken(this.getSelectedFriend().id).subscribe(
			(res: any) => {
				if (res.data.fcm_token == null) return;

				notifiactionData = {
					"title": message.title,
					"message": message.text,
					"fcm_token": res.data.fcm_token,
					"friendid": this.getSelectedFriend().id
				};


				this.httpService.post(`/users/me/send-notification/`, notifiactionData).subscribe((response: any) => {

				});
			}
		);
	}

	public sendMeANotification(message: any) {
		let notifiactionData = {
			"title": message.title,
			"message": message.text,
			"fcm_token": this.userService.getCurrentUser().fcm_token,
			"friendid": this.userService.getCurrentUser().id
		};

		this.httpService.post(`/users/me/send-notification/`, notifiactionData).subscribe((response: any) => {

		});
	}

	public setFriend(friendData) {
		localStorage.setItem("selectedFriend", JSON.stringify(friendData));
		this.selectedFriend = friendData;
	}

	public getSelectedFriend() {
		if (!this.selectedFriend) this.selectedFriend = JSON.parse(localStorage.getItem("selectedFriend"));
		return this.selectedFriend;
	}

	public removeFriend() {
		localStorage.setItem("selectedFriend", null);
	}

	public getToken(id) {
		this.userService.getFriendToken(id).subscribe(
			(res: any) => {

			}
		);
	}

	public removeToken() {
		this.httpService.post(`/users/me/fcm-token`, { "token": null }).subscribe((response: any) => {

		});
	}

	public readNotifications() {
		let myId = localStorage.getItem("id");
		this.httpService.post(`/update-notification`, { "user_id": myId }).subscribe((response: any) => {

			// this.updateNotifyBadge.next(false);
		});
	}

	public readNotificationsStatus(){
		let myId = localStorage.getItem("id");
		this.httpService.post(`/update-notification-status`, { "user_id": myId }).subscribe((response: any) => {

			this.updateNotifyBadge.next(false);
		});
	}

	public readNotificationsById(notif_id: number) {
		//let myId = localStorage.getItem("id");

		this.httpService.get(`/read_notification/${notif_id}`).subscribe((response: any) => {

		});
		// this.httpService.post(`/read-per-notification`, { "user_id": myId, "notif_id": notif_id }).subscribe((response: any) => {

		// });
	}

	public getUnreadNotificationStatus() {
		let myId = localStorage.getItem("id");
		return this.httpService.get(`/notification-read-status/${myId}`);
	}
	public getNotificationStatus() {
		const myId = localStorage.getItem("id");
		return this.httpService.get(`/notification-status/${myId}`);
	}

	public getSwapNotifications(notification_id) {
		return this.httpService.get(`/notification/swap/${notification_id}`)
	}
	public readNotification(notification_id) {
		return this.httpService.get(`/read_notification/${notification_id}`)
	}

	public upadateNotificationStatus(status: number, id: number) {

		return this.httpService.put(`/notification-status/`, { "user_id": id, "status": status });
	}
	public getConfirmSwap(notification_id) {
		return this.httpService.get(`/notification/swap/${notification_id}`)
	}


	getTripNotifications() {
		const myId = localStorage.getItem("id");
		return this.httpService.get(`/get-trip-notification/${myId}`);
	}

	readTripNotifications() {
		let myId = localStorage.getItem("id");
		this.httpService.post(`/update-trip-notification`, { "user_id": myId }).subscribe((response: any) => {

			this.updateTripNotifyBadge.next(false);
		});
	}

	public upadateMessageNotificationStatus(status: number, user_id: number, friend_id: number) {

		return this.httpService.post(`/save-msg-notification-status`, { "user_id": user_id, "friend_id": friend_id, "status": status });
	}
}
