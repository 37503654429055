import { DownloadComponent } from './redirections/download/download.component';
// import { DeepLinkGuard } from './shared/guards/deeplink.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { ModalLayoutComponent } from './layouts/modal-layout/modal-layout.component';
import { PublicLayoutComponent } from './layouts/public-layout/public-layout.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { HttpLoaderFactory } from './shared/shared.module';
import { HomeDetailsComponent } from './features/explore-spaces-section/pages/home-details/home-details.component';
import { SwipeGuard } from './features/swipe/guards/swipe.guard';
import { TipaltiPaymentDetailsComponent } from './shared/components/tipalti-payment-details/tipalti-payment-details.component';
import { ExploreScreenComponent } from './features/explore-spaces-section/pages/explore-screen/explore-screen.component';

const routes: Routes = [
  {
    path: 'legal',
    component: PublicLayoutComponent,
    loadChildren: () =>
      import('./modals/legal/legal.module').then((m) => m.LegalModule),
  },
  {
    path: 'referal-link',
    loadChildren: () =>
      import('./features/test-share/test-share.module').then(
        (m) => m.TestShareModule
      ),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./features/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'maintenance',
    loadChildren: () =>
      import('./features/maintenance/maintenance.module').then(
        (m) => m.MaintenanceModule
      ),
  },
  {
    path: 'setup',
    loadChildren: () =>
      import('./features/setup/setup.module').then((m) => m.SetupModule),
    canActivate: [AuthGuard],
  },

  {
    path: '',
    component: PublicLayoutComponent,
    loadChildren: () =>
      import('./features/explore-spaces-section/explore-space.module').then(
        (m) => m.ExploreSpaceModule
      ),
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./features/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'prizes',
        loadChildren: () =>
          import('./shared/components/prizes/prizes.module').then(
            (m) => m.PrizesModule
          ),
      },
      {
        path: 'my-homes',
        loadChildren: () =>
          import('./features/my-homes/my-homes.module').then(
            (m) => m.MyHomesModule
          ),
      },
      {
        path: 'dashboard-profile',
        loadChildren: () =>
          import('./features/dashboard-new/dashboard-new.module').then(
            (m) => m.DashboardNewModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'swipe',
        canActivate: [SwipeGuard],
        canActivateChild: [SwipeGuard],
        loadChildren: () =>
          import('./features/swipe/swipe.module').then((m) => m.SwipeModule),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./modals/settings/settings.module').then(
            (m) => m.SettingsModule
          ),
      },
      {
        path: 'swaps',
        loadChildren: () =>
          import('./features/swaps/swaps.module').then((m) => m.SwapsModule),
      },
      {
        path: 'inbox',
        loadChildren: () =>
          import('./features/inbox/inbox.module').then((m) => m.InboxModule),
      },
      {
        path: 'me',
        loadChildren: () =>
          import('./features/my-profile/my-profile.module').then(
            (m) => m.MyProfileModule
          ),
      },
      {
        path: 'view-home',
        loadChildren: () =>
          import(
            './features/confirm-and-pay/confirm-pay/confirm-pay.module'
          ).then((m) => m.ConfirmPayModule),
      },
      {
        path: 'confirm-and-pay',
        loadChildren: () =>
          import(
            './features/confirm-and-pay/confirm-pay/confirm-pay.module'
          ).then((m) => m.ConfirmPayModule),
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('./modals/notifications/notifications.module').then(
            (m) => m.NotificationsModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'swipe-homes',
        loadChildren: () =>
          import('./features/swipe-homes/swipe-homes.module').then(
            (m) => m.SwipeHomesModule
          ),
      },
      {
        path: 'search-list',
        loadChildren: () =>
          import('./features/search-list/search-list.module').then(
            (m) => m.SearchListModule
          ),
      },
      {
        path: 'verify',
        loadChildren: () =>
          import('./modals/verify/verify.module').then((m) => m.VerifyModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'spaces',
        loadChildren: () =>
          import('./modals/spaces/spaces.module').then((m) => m.SpacesModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'messages/:id',
        loadChildren: () =>
          import('./modals/messages/messages.module').then(
            (m) => m.MessagesModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'progress-tracker',
        loadChildren: () =>
          import('./features/progress-tracker/progress-tracker.module').then(
            (m) => m.ProgressTrackerModule
          ),
      },
      {
        path: 'ratings',
        loadChildren: () =>
          import('./features/ratings/ratings.module').then(
            (m) => m.RatingsModule
          ),
      },
      {
        path: 'legal',
        loadChildren: () =>
          import('./modals/legal/legal.module').then((m) => m.LegalModule),
      },
      {
        path: 'global-leaderboard',
        loadChildren: () =>
          import(
            './features/global-leaderboard/global-leaderboard.module'
          ).then((m) => m.GlobalLeaderboardModule),
      },
      {
        path: 'promo/:id',
        loadChildren: () =>
          import('./modals/promo/promo.module').then((m) => m.PromoModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'billing',
        loadChildren: () =>
          import('./features/billing/billing.module').then(
            (m) => m.BillingModule
          ),
      },
      {
        path: 'email-verified',
        loadChildren: () =>
          import('./modals/email-verified/email-verified.module').then(
            (m) => m.EmailVerifiedModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'add-home-deeplink',
        loadChildren: () =>
          import('./features/add-home-deeplink/add-home-deeplink.module').then(
            (m) => m.AddHomeDeeplinkModule
          ),
      },
      // { path: "dashboard-new", loadChildren: () => import("./features/dashboard-new/dashboard-new.module").then(m => m.DashboardNewModule), canActivate: [AuthGuard]},
      {
        path: 'dashboard-new',
        loadChildren: () =>
          import('./features/dashboard-profile/dashboard-profile.module').then(
            (m) => m.DashboardProfileModule
          ),
      },
      {
        path: 'view-listing',
        loadChildren: () =>
          import('./features/view-listing/view-listing.module').then(
            (m) => m.ViewListingModule
          ),
        canActivate: [AuthGuard],
      },
      // { path: "add-listing", loadChildren: () => import("./features/add-listing/add-listing.module").then(m => m.AddListingModule)}
    ],
  },
  {
    path: 'user-home/:id',
    component: HomeDetailsComponent,
    //  path: "user-home", loadChildren: () => import("./features/confirm-and-pay/confirm-pay/confirm-pay.module").then(m => m.ConfirmPayModule),
  },
  {
    path: 'banking-information',
    loadChildren: () =>
      import(
        './features/bank-details-capture/bank-details-capture.module'
      ).then((m) => m.BankDetailsCaptureModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'modal',
    component: ModalLayoutComponent,
    outlet: 'modal',
    children: [
      {
        path: 'profile/:id',
        loadChildren: () =>
          import('./modals/profile/profile.module').then(
            (m) => m.ProfileModule
          ),
      },
      {
        path: 'verify',
        loadChildren: () =>
          import('./modals/verify/verify.module').then((m) => m.VerifyModule),
        canActivate: [AuthGuard],
      },

      {
        path: 'settings',
        loadChildren: () =>
          import('./modals/settings/settings.module').then(
            (m) => m.SettingsModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'spaces',
        loadChildren: () =>
          import('./modals/spaces/spaces.module').then((m) => m.SpacesModule),
        canActivate: [AuthGuard],
      },

      {
        path: 'notifications',
        loadChildren: () =>
          import('./modals/notifications/notifications.module').then(
            (m) => m.NotificationsModule
          ),
        canActivate: [AuthGuard],
      },

      {
        path: 'messages/:id',
        loadChildren: () =>
          import('./modals/messages/messages.module').then(
            (m) => m.MessagesModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'questions',
        loadChildren: () =>
          import('./modals/questions/questions.module').then(
            (m) => m.QuestionsModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'swaps',
        loadChildren: () =>
          import('./modals/swaps/swaps.module').then((m) => m.SwapsModule),
        canActivate: [AuthGuard],
      },
      // { path: "welcome-popup", loadChildren: () => import("./modals/welcome-popup/welcome-popup.module").then(m => m.WelcomePopupModule)},
    ],
  },
  {
    path: 'tipalti-pay',
    component: TipaltiPaymentDetailsComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'appdownload',
    component: DownloadComponent,
  },

  {
    path: '',
    component: PublicLayoutComponent,
    children: [{ path: ':region', component: ExploreScreenComponent }],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      onSameUrlNavigation: 'reload',
      // preloadingStrategy: PreloadAllModules,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
