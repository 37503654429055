import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class FacebookPixelService {
  private loadOk:boolean = false;

  constructor() { }

  load() {
    if (!this.loadOk) {
      (function (f: any, b, e, v, n, t, s) {
        if (f.fbq) return; n = f.fbq = function () {
          n.callMethod ?
            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        }; if (!f._fbq) f._fbq = n;
        n.push = n; n.loaded = !0; n.version = '2.0'; n.queue = []; t = b.createElement(e); t.async = !0;
        t.src = v; s = b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t, s)
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
      (window as any).fbq('init', '187161740421890');
      (window as any).fbq('track', 'PageView');
      this.loadOk = true;

    }
    else {
      (window as any).fbq('track', 'PageView');

    }
  }
}










