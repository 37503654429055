import {
  Component,
  OnInit,
  Input,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { SwiperOptions } from 'swiper';

declare var $: any;

@Component({
  selector: 'app-grid-image-viewer',
  templateUrl: './grid-image-viewer.component.html',
  styleUrls: ['./grid-image-viewer.component.scss'],
})
export class GridImageViewerComponent implements OnInit, OnDestroy {
  @Input() images: any;

  realIndex: number = 1;
  activeIndex: number = 0;

  public config: SwiperOptions = {
    slidesPerView: 1,
    virtual: true,
    spaceBetween: 0,
    pagination: false,
    autoplay: false,
    observer: true,
    observeParents: true,
    parallax: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    on: {
      realIndexChange: (swiper) => {
        this.realIndex = swiper.realIndex + 1;
      },
    },
  };

  isOpenDetailView: boolean = false;

  @HostListener('document:click', ['$event.target'])
  onMouseClick(htmlElement: HTMLElement) {
    if (htmlElement.className.includes('detail-viewer-wrapper')) {
      this.isOpenDetailView = false;
      document.removeEventListener('keydown', this.preventScroll);
      $('.app-content').css('overflow', 'auto');
    }
  }

  constructor() {}

  ngOnInit(): void {}

  preventScroll(e) {
    // Check if the pressed key is an arrow key (up, down, left, or right)
    if (
      e.key === 'ArrowUp' ||
      e.key === 'ArrowDown' ||
      e.key === 'ArrowLeft' ||
      e.key === 'ArrowRight'
    ) {
      // Prevent the default scrolling behavior
      e.preventDefault();
    }
  }

  openDetailView(index: any) {
    if (!this.isOpenDetailView) {
      this.activeIndex = index;
      this.realIndex = 1;
      this.isOpenDetailView = true;
      document.addEventListener('keydown', this.preventScroll);
      $('.app-content').css('overflow', 'hidden');
    }
  }

  ngOnDestroy(): void {
    document.removeEventListener('keydown', this.preventScroll);
    $('.app-content').css('overflow', 'auto');
  }

  getSwiperConfig(): any {
    return {
      ...this.config,
      initialSlide: this.activeIndex,
    };
  }

  handleButtonClick(buttonId: string) {
    if (buttonId === 'button-prev') {
      const btn = document.getElementById('button-prev');
      if (btn) {
        btn.click();
      }
    }
    if (buttonId === 'button-next') {
      const btn = document.getElementById('button-next');
      if (btn) {
        btn.click();
      }
    }
  }

  // Listen for keyboard events
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    // Check if the pressed key is an arrow key
    if (event.key === 'ArrowLeft') {
      this.handleButtonClick('button-prev');
    } else if (event.key === 'ArrowRight') {
      this.handleButtonClick('button-next');
    }
  }
}
