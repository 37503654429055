<div class="token-payment_main-div">
  <div class="buy-options">
    <p class="buy-tokens-txt">{{ "Pay with" | translate }}</p>

    <div class="options-section">
      <!-- <button class="credit-payment-btn" (click)="showCardPayment()">
                <img src="/assets/icons/credit-card-icon.png">
                <span class="payment-type-txt">{{"Credit Card" | translate}}</span>
            </button> -->
      <p *ngIf="cards.length <= 0">
        {{
          "Please add Credit or Debit card to continue the payment" | translate
        }}
      </p>
      <div class="cards">
        <ng-container *ngFor="let card of cards">
          <div
            (click)="onSelect(card)"
            class="card {{ active === card.id ? 'selected' : '' }} "
          >
            <div class="card-brand">
              <app-card-brand-svg [brand]="card.brand"></app-card-brand-svg>
            </div>

            <div class="card-details">
              <div class="name">
                {{ card?.billing_details?.name }}
              </div>
              <div class="last-4">xxxx-xxxx-xxxx-{{ card.last4 }}</div>
            </div>
            <div *ngIf="active === card.id" class="card-actions">
              <svg
                width="15"
                height="11"
                viewBox="0 0 15 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.0596 0.342805L5.37784 8.19246L1.94092 4.67871C1.49802 4.22591 0.775078 4.22591 0.332176 4.67871C-0.110725 5.13151 -0.110725 5.87062 0.332176 6.32342L4.57334 10.6594C4.79652 10.8876 5.08717 11 5.37759 11C5.66824 11 5.95865 10.8878 6.18183 10.6594L14.6678 1.98693C15.1107 1.53413 15.1107 0.795026 14.6678 0.342224C14.2212 -0.114117 13.502 -0.114117 13.0591 0.342477L13.0596 0.342805Z"
                  fill="#FF6D2E"
                />
              </svg>

              <!-- <svg *ngIf="active === card.id " xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="ai ai-CircleCheck">
                                <path d="M8 12.5l3 3 5-6" />
                                <circle cx="12" cy="12" r="10" />
                            </svg> -->
              <!--i *ngIf="active !== card.id " class="feather-circle"></i>
                            <i *ngIf="active === card.id " class="feather-check-circle"></i-->
            </div>
          </div>
        </ng-container>

        <!-- <div (click)="selectPaypalPayment()" class="card {{ active === 'paypal' ? 'selected' :'' }} ">
                    <div class="card-brand">
                        <img src="/assets/icons/paypal-color.png">
                    </div>
        
                    <div class="card-details">
                        <div class="name">
                            {{"Use PayPal account" | translate}}
                        </div>
                        <div class="last-4">
                        </div>
                    </div>
                    <div *ngIf="!editable" class="card-actions">
                      
                        <svg *ngIf="active === 'paypal' " width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.0596 0.342805L5.37784 8.19246L1.94092 4.67871C1.49802 4.22591 0.775078 4.22591 0.332176 4.67871C-0.110725 5.13151 -0.110725 5.87062 0.332176 6.32342L4.57334 10.6594C4.79652 10.8876 5.08717 11 5.37759 11C5.66824 11 5.95865 10.8878 6.18183 10.6594L14.6678 1.98693C15.1107 1.53413 15.1107 0.795026 14.6678 0.342224C14.2212 -0.114117 13.502 -0.114117 13.0591 0.342477L13.0596 0.342805Z" fill="#FF6D2E"/>
                        </svg>
                    </div>
                </div> -->

        <div *ngIf="paySelected && type == 'subscription'" class="pay_wrapper">
          <button class="pay-button" (click)="onPay()">
            {{ "Pay" | translate }}
          </button>
        </div>
      </div>

      <!-- <app-payment-request-button 
                [item]="{ type: 'subscription', id: selected?.id, quantity: selected?.interval, price: selected?.price / 100 }" 
                (paymentSuccess)="onPaymentSuccess($event)"
                (paymentLoading)="loading = $event">
            </app-payment-request-button> -->
    </div>
  </div>
  <!-- 
    <p class="save-money-txt">
        {{"You are about to save money on your travels!" | translate}}
    </p> -->

  <!-- <div *ngIf="cardPayment">
        <app-pay (onSuccess)="onPaymentSuccess($event)"
          [item]="{id: selected?.id, name: 'Subscription', type: 'subscription', price: selected?.price / 100, quantity: selected?.interval}">
         </app-pay>
    </div> -->

  <app-loading [loading]="loading"></app-loading>

  <div class="fixed-footer" [class.ios-fix]="checkIOS">
    <div>
      <p>{{ "Add Payment Method" | translate }}</p>
      <p class="highlight">{{ "Credit or Debit card" | translate }}</p>
    </div>
    <div>
      <button (click)="addNewCard()" class="add-button">
        {{ "Add" | translate }}
      </button>
    </div>
  </div>
</div>
