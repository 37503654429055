<div class="flexible_wrapper">
  <div class="flexible_outerscroll">
    <h2 class="card_title">Flexible plans?</h2>
    <div class="flexible_content">
      <h3 class="cardsub_title">Choose stay length:</h3>

      <div class="stay_RadioButton_outer">
        <form [formGroup]="radiobtnGroup">
          <ng-container *ngFor="let day of days">
            <div class="calender_Radiobtn">
              <input
                type="radio"
                name="monthCheckboxGroup"
                value="{{ day.days }}"
                id="{{ day.id }}"
                formarrayname="days"
                (change)="ondaysCheckboxChange($event, 'days')"
                [checked]="day.isActive"
              />
              <label for="{{ day.id }}">{{ day.text }}</label>
            </div>
          </ng-container>
        </form>
      </div>

      <div class="year-group">
        <ng-container *ngFor="let year of years">
          <div>
            <h5 class="cardsub_title">
              {{ year }}
            </h5>

            <div class="calender_outerWrapper">
              <form [formGroup]="checkboxGroup" class="calender_Checkbox">
                <ng-container *ngFor="let month of months; let i = index">
                  <div
                    class="calender_Checkboxgroup"
                    *ngIf="
                      (currentMonth <= i + 1 && currentYear <= year) ||
                      currentYear < year
                    "
                  >
                    <input
                      type="checkbox"
                      name="calenderCheckboxgroup"
                      value="{{ month.value }}-{{ year }}"
                      [checked]="matchChecked(month.value, year)"
                      id="{{ month.text }}-{{ year }}"
                      formarrayname="months"
                      (change)="onMonthsCheckboxChange($event, 'months')"
                    />
                    <label for="{{ month.text }}-{{ year }}">{{
                      month.text
                    }}</label>
                  </div>
                </ng-container>
              </form>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="Flexible_Footer">
    <button
      type="button"
      class="cancelBtn"
      [disabled]="clearButtonClicked"
      (click)="clearFlexible(); closeFlex()"
    >
      {{ "Clear" | translate }}
    </button>
    <button type="button" class="searchBtn" (click)="searchViewList()">
      {{ "Select" | translate }}
    </button>
  </div>
</div>

<app-loading [loading]="loading"></app-loading>
