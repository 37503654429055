import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-google-country-dropdown',
  templateUrl: './google-country-dropdown.component.html',
  styleUrls: ['./google-country-dropdown.component.scss']
})
export class GoogleCountryDropdownComponent implements OnInit {
  @Input() list:any = [];
  @Input() placeholder  = "select";
  @Input() search: boolean = false;
  @Input() outsideClick: boolean = false;
  @Input() selected: string = "";
  // two way binding for input text
  inputItem = '';
  selected_code = ''
  // enable or disable visiblility of dropdown
  listHidden = true;
  showError = false;
  selectedIndex = -1;
  oldList:any = [];
  selectedItem = '';

  // the list to be shown after filtering
  filteredList: any = [];

  constructor() {};
  @Output() public onSelect = new EventEmitter();

  ngOnInit() {
    this.filteredList = this.list;


    if(this.outsideClick == true) {
      this.listHidden = true;
    }

    if(this.selected != "" && this.selected != undefined && typeof this.selected !== 'undefined') {
      this.inputItem = this.selected.toLowerCase();
      this.selectedItem  =this.selected;
      // this.listHidden = false;
      // this.filteredList = this.list.filter((item) => {
      //   // return item.name;
      //   return item.name.toLowerCase().startsWith(this.inputItem.toLowerCase())
      // });




    }
    // else{
    //   this.showError = true;
    // }
  }

  // modifies the filtered list as per input
  getFilteredList() {

    this.listHidden = false;
    // this.selectedIndex = 0;

    if (!this.listHidden && this.inputItem !== undefined) {
      this.oldList = this.list;
      this.filteredList = this.list.filter((item) => {
        // return item.name;
        return item.name.toLowerCase().startsWith(this.inputItem.toLowerCase())
      });
    //   this.showError = true;

    //   alert(11)
    // }else if(this.listHidden && this.inputItem == null || this.inputItem == ''){
    //   this.showError = false;
    //   alert(22)
    // }else{
    //   this.showError = false;
    //   alert(33)
    }
  }

  clearSelected() {
    this.filteredList.forEach(item => item.selected = false);
  }


  // select highlighted item when enter is pressed or any item that is clicked
  selectItem(ind) {


    this.inputItem = this.filteredList[ind].name;
    this.selectedItem = this.filteredList[ind].name;
    this.selected_code = this.filteredList[ind].code;
    this.listHidden = true;
    this.selectedIndex = ind;

    this.filteredList.forEach((item)=> item.selected = false);
    this.filteredList[ind].selected = true;


    this.filteredList = this.oldList;

    this.onSelect.emit({name: this.inputItem});
    this.inputItem = "" ;
    this.showError = false;
  }

  // navigate through the list of items
  onKeyPress(event) {
    if (!this.listHidden) {
      if (event.key === 'Escape') {
        this.selectedIndex = -1;
        this.toggleListDisplay();
      }

      if (event.key === 'Enter') {
        // this.toggleListDisplay();
        this.selectItem(this.selectedIndex);
      }
      if (event.key === 'ArrowDown') {
        this.listHidden = false;
        this.selectedIndex =
          (this.selectedIndex + 1) % this.filteredList.length;
        if (this.filteredList.length > 0 && !this.listHidden) {
          document
            .getElementsByTagName('list-item')
            [this.selectedIndex].scrollIntoView();
        }
      } else if (event.key === 'ArrowUp') {
        this.listHidden = false;
        if (this.selectedIndex <= 0) {
          this.selectedIndex = this.filteredList.length;
        }
        this.selectedIndex =
          (this.selectedIndex - 1) % this.filteredList.length;

        if (this.filteredList.length > 0 && !this.listHidden) {
          document
            .getElementsByTagName('list-item')
            [this.selectedIndex].scrollIntoView();
        }
      }
    }
  }

  // show or hide the dropdown list when input is focused or moves out of focus
  toggleListDisplay() {
    this.listHidden = !this.listHidden;

    if (this.listHidden == false) {
      // this.selectedIndex = -1;
      this.listHidden = false;
      this.getFilteredList();

    } else {
      // helps to select item by clicking
      setTimeout(() => {
        // this.selectItem(this.selectedIndex);
        this.listHidden = true;

        // if (!this.list.includes(this.inputItem)) {
        //   this.showError = true;
        //   this.filteredList = this.list;
        // } else {
        //   this.showError = false;
        // }

        if (!this.list.includes(this.inputItem)) {
          this.showError = true;
          this.filteredList = this.list;
        } else {
          this.showError = false;
        }
      }, 500);
    }
  }

  update() {

    this.listHidden = true;

  }

}
