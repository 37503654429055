import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { map } from "rxjs/operators";
import { Cacheable } from "ts-cacheable";
import { HttpService } from "./http.service";

@Injectable({
	providedIn: "root"
})
export class SubscriptionService {
	public static cacheBuster$ = new Subject<void>();

	constructor(private httpService: HttpService) { }

	@Cacheable({
		cacheBusterObserver: SubscriptionService.cacheBuster$,
	})
	public getAvailableSubscriptions() {
		return this.httpService.get(`/subscriptions`).pipe((map((response: any) => response.data)));
	}

	@Cacheable()
	public getSubscriptionHistory() {
		return this.httpService.get(`/users/me/subscriptions`);
	}

	public subscribe(data: { item_id: number, payment_method: string, bonus_token: boolean }) {
		return this.httpService.post(`/users/me/subscriptions`, data);
	}

	public update(subscriptionId: string, payment_method: string) {

		return this.httpService.put(`/users/me/subscriptions/change`, { item_id: subscriptionId, payment_method : payment_method});
	}

	public cancel() {
		return this.httpService.delete(`/users/me/subscriptions`);
	}
}
