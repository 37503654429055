import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-sociallogin-signup',
  templateUrl: './sociallogin-signup.component.html',
  styleUrls: ['./sociallogin-signup.component.scss']
})
export class SocialloginSignupComponent implements OnInit {
  public loading = false;
  public lastnameBlank = false;
  public firstnameBlank = false;
  submitted: boolean = false;
  public selectedLanguage = "en";
  public termsOne = true;
  public error: string;

  constructor(private router: Router,
    private userService: UserService,
    private route: ActivatedRoute
    ) { }

  ngOnInit(): void {
  }

  public user: { first_name?: string, last_name?: string } = {
    first_name: "",
    last_name: "",
  };


  handleSkip() {
    this.router.navigate(["/auth/nearly-there"], {queryParams : this.route.snapshot.queryParams, queryParamsHandling :'merge'});
  }

  firstNameValidation() {
    this.firstnameBlank = this.user.first_name.length > 1 ? true : false;
  }

  lastNameValidation() {
    this.lastnameBlank = this.user.last_name.length > 1 ? true : false;
  }

  public onSignup() {
    this.submitted = true;
    this.error = "";

    this.userService.saveUserLogin({
      first_name: this.user.first_name,
      last_name: this.user.last_name,

    }).subscribe((response) => {

      this.router.navigate(["/auth/nearly-there"], {queryParams : this.route.snapshot.queryParams, queryParamsHandling :'merge'});
    }, (err) => {
      this.error = err;
    }).add(() => {
      this.loading = false;
    });


  }
}

