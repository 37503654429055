import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ClevertapService } from "../../services/clevertap.service";
import { SnackbarService } from "../../services/snackbar.service";
import { StripeService } from "../../services/stripe.service";
import { UserService } from "../../services/user.service";
import { TranslateService } from '@ngx-translate/core';
@Component({
	selector: "app-pay",
	templateUrl: "./pay.component.html",
	styleUrls: ["./pay.component.scss"]
})
export class PayComponent implements OnInit {

	@Input() public item: { type?: "subscription" | "bundle", id?: number, price?: number, quantity?: number, name?: string } = {};
	@Output() public onSuccess = new EventEmitter();
	@Output() public onPaymentSelection = new EventEmitter();
	@Output() public onError = new EventEmitter();

	public payment: any = { method : 1};
	public confirmPurchase_btnTxt = "Confirm";
	public loading = false;
	public paymentSelectionConfirm = false;
	public paymentSelection = true;
	public processingPayment = false;
	public showCard = true;
  isabondedPremium = false;
  userEmail;
	constructor(private stripeService: StripeService,
		        private snackbarService: SnackbarService,
            private clevertapService: ClevertapService,
            private userService: UserService,
			public translate: TranslateService
            ) { }

	ngOnInit(): void {
		this.confirmPurchase_btnTxt = "Confirm";
		this.processingPayment = false;
		this.paymentSelection = true;
		this.showCard = true;
    let userDataStorage = localStorage.getItem("userData");
    let userData = JSON.parse(userDataStorage);
    this.userEmail = userData?.email;
	}

	public onPay() {
    this.isabondedPremium = true;
    localStorage.setItem("bundleConfirm" , "1");
		if (this.item.type === "bundle") {
			return this._handleTokenPurchase();
		}
		// handle subscription
		this.confirmPurchase_btnTxt = this.translate.instant("Processing");
		this._handleSubscription();

	}

	public onMethod(method: number) {
		this.payment.method = method;
	}

	public onPaymentMethod(paymentMethodId: string) {
		this.payment.card = paymentMethodId;
	}

	public onWalletPaymentSuccess(e) {
		this.onSuccess.emit(e);
	}

	private _handleSubscription() {

		//if (this.processingPayment) return;

		this.processingPayment = true;
		this.loading = true;

		this.onSuccess.emit({
			method: this.payment.card,
			id: this.item.id,
		});
		this.loading = false;
		/*this.stripeService.getIntent({
			bundle_id: this.item.id,
			payment_method: this.payment.card,
		}).subscribe(async (response: any) => {
			if (!response.requires_action) {
				this._onSuccess();
				return;
			}

			const { paymentIntent, error: confirmError } = await this.stripeService.stripe.confirmCardPayment(
				response.data.client_secret,
				{ payment_method: this.payment.card },
				{ handleActions: false }
			);

			if (confirmError) {
				this.loading = false;
				this.onError.emit(confirmError.message);
			} else {
				if (paymentIntent.status === "requires_action") {
					const { error } = await this.stripeService.stripe.confirmCardPayment(response.data.client_secret);
					if (error) {
						this.loading = false;
						this.onError.emit(error.message);
					} else {
						// The payment has succeeded.
						this._onSuccess();
					}
				} else {
					this._onSuccess();
				}
			}

			this._onSuccess();
		});*/
	}

	private _handleTokenPurchase() {
		this.loading = true;
		this.stripeService.getIntent({
			bundle_id: this.item.id,
			payment_method: this.payment.card,
		}).subscribe(async (response: any) => {
			if (!response.requires_action) {
				this._onSuccess();
				return;
			}

			const { paymentIntent, error: confirmError } = await this.stripeService.stripe.confirmCardPayment(
				response.data.client_secret,
				{ payment_method: this.payment.card },
				{ handleActions: false }
			);

			if (confirmError) {
				this.loading = false;
				this.confirmPurchase_btnTxt = "Retry";
				this.onError.emit(confirmError.message);
			} else {
				if (paymentIntent.status === "requires_action") {
					const { error } = await this.stripeService.stripe.confirmCardPayment(response.data.client_secret);
					if (error) {
						this.loading = false;
						this.confirmPurchase_btnTxt = "Retry";
						this.onError.emit(error.message);
					} else {
						// The payment has succeeded.
						this._onSuccess();
					}
				} else {
					this._onSuccess();
				}
			}

			this._onSuccess();
		}, (err) => {
			this.confirmPurchase_btnTxt = "Retry";
			this.loading = false;
			this.snackbarService.show({
				message: this.translate.instant("Transaction failed"),
				type: "error",
			});
		}), (err) => {
			this.loading = false;
			this.snackbarService.show({
				message: this.translate.instant("Transaction failed"),
				type: "error",
			});
			this.confirmPurchase_btnTxt = "Retry";
		};
	}

	private _onSuccess() {
		this.onSuccess.emit(this.item.id);
	}

	public selectPaymentConfirm() {
		this.paymentSelectionConfirm = true;
		this.paymentSelection = false;
		this.showCard = false;
		//this.onPaymentSelection.emit(true);
	}

  ngOnDestroy(): void {

    if(!this.isabondedPremium) {

      let premiumData = {
        email:this.userEmail,
      }
      this.clevertapService.pushCleverTapEvent('abandoned_premium_purchase', premiumData);
      this.userService.isAbondedPremiumCheck=true;
  }
 }

}
