import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment'; 

// import { FirebaseDynamicLinks } from 'firebase-dynamic-links';

@Injectable({
  providedIn: 'root'
})
export class DeeplinkService {
  web_api_key = "AIzaSyC_aQNmd5jg0T2R_NVmNzY41in4YnNdprs";
  // firebaseDynamicLinks;

  constructor(private http: HttpClient) {
    // this.firebaseDynamicLinks = new FirebaseDynamicLinks("AIzaSyC_aQNmd5jg0T2R_NVmNzY41in4YnNdprs");
  }

  createDynamicLink(id: number) {

    let dynamicLinkInfo = {
      domainUriPrefix: 'https://holidayswap.page.link',
      link: `https://app.staging.holidayswap.com/?id=${id}`,
      androidInfo: {
        androidPackageName: 'com.holidayswap',
      },
      iosInfo: {
        iosBundleId:'com.holidayswapping.HolidaySwappingApp',
      },
    };
    let jsonDL = JSON.stringify(dynamicLinkInfo);

    return this.http.post(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${this.web_api_key}`, jsonDL);

    // return await this.firebaseDynamicLinks.createLink({
    //   dynamicLinkInfo: {
    //     domainUriPrefix: 'https://holidayswap.page.link',
    //     link: `https://app.staging.holidayswap.com/?id=${id}`,
    //     androidInfo: {
    //       androidPackageName: 'com.holidayswap',
    //     },
    //     iosInfo: {
    //       iosBundleId: 'com.holidayswapping.HolidaySwappingApp',
    //     },
    //   },
    // });
  }

}
