<div class="grid-image-view">
  <div class="main-grid">
    <div class="grid-box" (click)="openDetailView(0)">
      <img [lazyLoad]="images[0]?.webp_image" alt="">
    </div>
    <span class="chip">1 / {{ images.length }}</span>
  </div>
  <div class="sub-grid">
    <div class="grid-box" (click)="openDetailView(1)">
      <img *ngIf="images[1]" [lazyLoad]="images[1].webp_image" alt="">
    </div>
  </div>
  <div class="sub-grid">
    <div class="grid-box" (click)="openDetailView(2)">
      <img *ngIf="images[2]" [lazyLoad]="images[2].webp_image" alt="">
    </div>
  </div>
  <div class="sub-grid">
    <div class="grid-box" (click)="openDetailView(3)">
      <img *ngIf="images[3]" [lazyLoad]="images[3].webp_image" alt="">
    </div>
  </div>
  <div class="sub-grid">
    <div class="grid-box" (click)="openDetailView(4)">
      <img *ngIf="images[4]" [lazyLoad]="images[4].webp_image" alt="">
    </div>
  </div>
</div>

<div class="detail-viewer-wrapper" *ngIf="isOpenDetailView">
  <div class="viewer-content anchor">
    <span class="chip">{{ realIndex }} / {{ images.length }}</span>
    <swiper [config]="getSwiperConfig()">
      <ng-container *ngFor="let image of images; index as i">
        <ng-template swiperSlide>
          <div class="space-slider-image anchor">

            <img class="lazyload anchor" [lazyLoad]="image.image_url" alt="">

            <!-- <div class="space-slider-image-tag">
             {{ image?.tag?.name }}
         </div> -->
          </div>

        </ng-template>
      </ng-container>
    </swiper>
    <span class="swiper-button-prev anchor" id="button-prev">
      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
  d="M16.666 10.7285C16.666 10.8796 16.6113 11.0255 16.512 11.1393C16.4128 11.2531 16.2756 11.3271 16.126 11.3477L16.041 11.3535H5.05435L9.02352 15.306C9.13622 15.4171 9.20259 15.5668 9.20922 15.7249C9.21585 15.8831 9.16225 16.0378 9.05924 16.1579C8.95624 16.2781 8.81149 16.3547 8.65421 16.3723C8.49694 16.3898 8.33885 16.3471 8.21185 16.2527L8.14185 16.1919L3.10018 11.1719C2.9885 11.0602 2.92303 10.9105 2.91685 10.7527L2.91602 10.7285V10.7044C2.91649 10.6921 2.91733 10.6799 2.91852 10.6677L2.91602 10.7285C2.91631 10.5944 2.95953 10.4638 3.03935 10.356L3.04435 10.3494C3.06128 10.3273 3.07965 10.3064 3.09935 10.2869L8.14102 5.26435C8.25231 5.15047 8.40288 5.08335 8.56198 5.07672C8.72108 5.07008 8.87671 5.12442 8.9971 5.22865C9.11749 5.33287 9.19356 5.47912 9.20977 5.63753C9.22599 5.79594 9.18113 5.95457 9.08435 6.08102L9.02352 6.15019L5.05602 10.1027H16.041C16.2068 10.1027 16.3657 10.1685 16.483 10.2857C16.6002 10.403 16.666 10.5619 16.666 10.7277V10.7285Z"
  fill="#FF6D2E"/>
</svg>

    </span>
    <span class="swiper-button-next anchor" id="button-next">
<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
  d="M3.33398 10.2715C3.33399 10.1204 3.38869 9.97453 3.48796 9.8607C3.58723 9.74688 3.72436 9.67285 3.87398 9.65231L3.95898 9.64648H14.9457L10.9765 5.69398C10.8638 5.58288 10.7974 5.43317 10.7908 5.27505C10.7842 5.11694 10.8377 4.96219 10.9408 4.84205C11.0438 4.72191 11.1885 4.64532 11.3458 4.62774C11.5031 4.61015 11.6612 4.65288 11.7882 4.74731L11.8582 4.80814L16.8998 9.82814C17.0115 9.93982 17.077 10.0895 17.0831 10.2473L17.084 10.2715V10.2956C17.0835 10.3079 17.0827 10.3201 17.0815 10.3323L17.084 10.2715C17.0837 10.4056 17.0405 10.5362 16.9606 10.644L16.9557 10.6506C16.9387 10.6727 16.9203 10.6936 16.9007 10.7131L11.859 15.7356C11.7477 15.8495 11.5971 15.9166 11.438 15.9233C11.2789 15.9299 11.1233 15.8756 11.0029 15.7714C10.8825 15.6671 10.8064 15.5209 10.7902 15.3625C10.774 15.2041 10.8189 15.0454 10.9156 14.919L10.9765 14.8498L14.944 10.8973H3.95898C3.79322 10.8973 3.63425 10.8315 3.51704 10.7143C3.39983 10.597 3.33398 10.4381 3.33398 10.2723V10.2715Z"
  fill="#FF6D2E"/>
</svg>


    </span>
  </div>
</div>
