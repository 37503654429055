import { NgForOfContext } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { SlideUpToggleAnimation } from '../../animations/slide-up';
import { ClevertapService } from '../../services/clevertap.service';

declare var $: any;

@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss'],
  animations: [SlideUpToggleAnimation],
  exportAs: 'bottomSheet',
})
export class BottomSheetComponent implements OnInit {
  @Input() defaultHeight = '';
  @Input() maxHeight = '400';
  @Input() minHeight = 'auto';
  @Input() minWidth = '100%';
  @Input() closeHeader = true;
  @Input() defaultMaxHeight = '';

  @Input() public header: TemplateRef<any>;
  @Output() public closed: EventEmitter<any> = new EventEmitter<any>();

  public moving = false;
  public deltaY = 0;
  public _visible = false;
  userEmail;
  @ViewChild('bottomSheetContainer') public bottomSheetContainer: ElementRef;
  @ContentChild('[bottomSheet-title]') bottomSheetTitle: any;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private clevertapService: ClevertapService
  ) {}

  hasBottomSheetTitle(): boolean {
    return !!this.bottomSheetTitle;
  }

  public ngOnInit() {
    this.maxHeight = this.maxHeight ? this.maxHeight : '400';
    this.minHeight = this.minHeight ? this.minHeight : 'auto';
    let userDataStorage = localStorage.getItem('userData');
    let userData = JSON.parse(userDataStorage);
    this.userEmail = userData?.email;
  }

  /**
   * Opens bottom sheet component
   */
  public open() {
    this._visible = true;
    this.changeDetector.detectChanges();
    $('.app-navigation').addClass('hide');
  }

  /**
   * Closes bottom sheet component
   */
  public close() {
    this._visible = false;
    this.closed.emit();
    this.changeDetector.detectChanges();
    // abandoned_token_purchase event while closing the modal
    let isOpened = localStorage.getItem('tokenBundle');
    let isConfirm = localStorage.getItem('bundleConfirm');
    if (isOpened && Number(isConfirm) != 1) {
      let tokenData = {
        email: this.userEmail,
      };
      this.clevertapService.pushCleverTapEvent(
        'abandoned_token_purchase',
        tokenData
      );
      localStorage.removeItem('tokenBundle');
      localStorage.removeItem('bundleConfirm');
    }

    $('.app-navigation').removeClass('hide');
  }

  /**
   * Toggles bottom sheet component
   */
  public toggle() {
    this._visible = !this._visible;
    this.changeDetector.detectChanges();
  }

  public onPan(event) {
    const delta = event.deltaY;

    if (event.deltaY > 0) {
      this.deltaY = delta;
    }

    if (event.isFinal) {
      const height = this.bottomSheetContainer.nativeElement.clientHeight;
      if (this.deltaY > height / 2.5) {
        this.close();
      } else {
        this.deltaY = 0;
      }
      // this.deltaY = 0;
    }

    if (this._visible) {
      this.bottomSheetContainer.nativeElement.style = `transform: translateY(${this.deltaY}px)`;
    }

    // this.changeDetector.detectChanges();
  }
}
