<div class="google-map-wrapper" id="gmapUserSpace" style=`height:${height};`>
    <!-- <google-map  
        [options]="options"
        [width]="''"
        [height]="height"
    >
        <map-marker *ngFor="let markerPosition of markerPositions"
            [position]="markerPosition"
            [options]="markerOptions">
        </map-marker>
    
    </google-map> -->
</div>
