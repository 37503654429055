<div class="home-detail-main app_container home_space-mobile">
  <!-- image section with header -->
  <div class="explore_header">
    <div class="explore_left">
      <img (click)="pop()" src="/assets/images/back_arrow.svg" />
    </div>

    <!-- <div class="explore_right">
        <img (click)="socialShareModal()" src="/assets/images/send_ic.svg">
    </div> -->
  </div>

  <div class="home-prop-img">
    <!-- <img src="/assets/images/detail_img.jpg"> -->
    <!-- {{space.images|json}} -->
    <swiper [config]="config">
      <ng-container *ngFor="let image of space.images; index as i">
        {{ image | json }}
        <ng-template swiperSlide>
          <div class="space-slider-image">
            <img
              class="lazyload d-none d-md-block"
              [lazyLoad]="image.desktop_image"
              alt=""
            />
            <img
              class="lazyload d-md-none"
              [lazyLoad]="image.ipad_image"
              alt=""
            />
            <app-social-share
              [homeId]="spaceId"
              [imgUrl]="_albums[selectedCoverImage]?.src"
              [shareMessage]="shareMessage + space.deeplink_url"
              [deeplinkUrl]="space.deeplink_url"
            ></app-social-share>
            <!-- <div class="space-slider-image-tag">
                        {{ image?.tag?.name }}
                    </div> -->
          </div>
        </ng-template>
      </ng-container>
    </swiper>
  </div>
  <!-- image section with header -->
  <div class="home-detail-content">
    <!-- property-intro-start -->
    <div class="property-intro">
      <div class="left-section">
        <h2>{{ space.title }}</h2>
        <span
          class="gray_text"
          *ngIf="space?.address?.country || space?.address?.city"
        >
          <img src="/assets/images/mark_ic.svg" />
          {{
            space?.address?.country
              ? space?.address?.country + " , "
              : "
          "
          }}
          {{ space?.address?.city }}
        </span>
        <!-- <span class="gray_text"><i class="fa fa-star" aria-hidden="true"></i><span class="orange_txt">4.2</span> (22 reviews)</span> -->
      </div>
      <div class="right-section">
        <!--                <div class="right_inner" >-->
        <!--                <h2><img src="/assets/images/Token_Plane.png">{{ space.hosting_tokens }}</h2>-->
        <!--                <span class="gray_text">{{"/per night" | translate}}</span>-->
        <!--                </div>-->
      </div>
    </div>
    <!-- property-intro-end -->
    <!-- overview section start -->
    <div class="overview-section">
      <div class="section_label">{{ "Overview" | translate }}</div>
      <div class="facility_section">
        <ul>
          <li><img src="/assets/images/user_bl.svg" />{{ space?.guests }}</li>
          <li><img src="/assets/images/bad_bl.svg" />{{ space?.bedrooms }}</li>
          <li>
            <img src="/assets/images/shower_bl.svg" />{{ space?.bathrooms }}
          </li>
          <!-- <li><img src="/assets/images/home_bl.svg">Studio</li> -->
        </ul>
      </div>
      <p class="section_description">{{ space?.description }}</p>
    </div>
    <!-- overview section end -->
    <!-- map section start -->
    <div class="map-section">
      <div class="map_header">
        <div class="section_label">{{ "Map Location" | translate }}</div>
        <!--<a>{{"Direction" | translate}}</a>-->
      </div>
      <div class="location_map">
        <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1251822.1217861257!2d4.158466276504734!3d52.20768204530646!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c609c3db87e4bb%3A0xb3a175ceffbd0a9f!2sNetherlands!5e0!3m2!1sen!2sin!4v1646731027996!5m2!1sen!2sin" width="100%" height="260" style="border:0;" allowfullscreen="" loading="lazy"></iframe> -->
      </div>
    </div>
    <!-- map section start -->
    <!-- feature section start -->
    <div class="feature_section">
      <div class="property-intro">
        <div class="left-section">
          <!-- <div class="section_label">{{"Features" | translate}}</div> -->
          <div class="facility_section">
            <!-- <ul>
              <li *ngFor="let feature of spaceFeatures">

                {{ feature?.name | translate}}
              </li>

            </ul> -->
            <!-- <img src="/assets/images/wifi_ic.svg"><br> -->
            <!-- <li><img src="/assets/images/ac_n.svg"><br>AC</li>
                            <li><img src="/assets/images/bad_bl.svg"><br>Bed</li> -->
          </div>
        </div>
        <div class="right-section">
          <div class="right_inner">
            <div class="section_label">{{ "Instant booking" | translate }}</div>
            <div class="toggle-switch">
              <span class="toggle_label" *ngIf="space?.is_instant == '1'">{{
                "On" | translate
              }}</span>
              <span class="toggle_label" *ngIf="space?.is_instant == '0'">{{
                "Off" | translate
              }}</span>
              <label class="switch">
                <input
                  type="checkbox"
                  disabled
                  [checked]="space?.is_instant == '1'"
                />
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- feature section end -->
    <!-- review section start -->
    <!-- <div class="reviews-section">
            <div class="map_header">
                <div class="section_label">Reviews</div>
                <a>See all reviews</a>
            </div>
            <div class="review-slider">
                <div class="review-item">
                    <div class="review-des">
                        <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur euismod sed turpis non interdum..”</p>
                    </div>
                    <div class="review-footer">
                        <div class="review-left">
                            <div class="author_img">
                                <img src="../../../../../assets/images/2.jpg">
                            </div>
                            <div class="author-des">
                                <span class="author_name">Json Statom</span>
                                <span class="author_date">02 - 06, 2022</span>
                            </div>
                        </div>
                        <div class="review-right">
                            <div class="review_rating">
                                <i aria-hidden="true" class="fa fa-star"></i>&nbsp;&nbsp;4.2
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    <!-- review section start -->

    <div class="space-option-box">
      <div id="accordion">
        <div class="card">
          <div class="card-header">
            <a
              class="collapsed btn"
              data-bs-toggle="collapse"
              href="#collapseFive"
            >
              <span class="heading-option-style">
                {{ "Hosted by" | translate }}
                {{ space?.user?.first_name }} </span
              ><i class="fa fa-angle-right toggle-icon-style"></i>
            </a>
          </div>
          <div id="collapseFive" class="collapse" data-bs-parent="#accordion">
            <div class="card-body">
              <div class="space-host">
                <div class="picture">
                  <!--img [src]="space?.user?.image_url" /-->
                  <app-avatar
                    height="100"
                    width="100"
                    [picture]="space?.user?.image_url"
                    [name]="space?.user?.first_name"
                    [verified]="space?.user?.is_verified"
                  >
                  </app-avatar>
                </div>

                <div class="name">
                  {{ space?.user?.first_name }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="card">
          <div class="card-header">
            <a class="collapsed btn" data-bs-toggle="collapse" href="#collapseThree">
              <span class="heading-option-style"> {{"Home details" | translate}} </span><i
                class='fa fa-angle-right toggle-icon-style'></i>
            </a>
          </div>
          <div id="collapseThree" class="collapse" data-bs-parent="#accordion">
            <div class="card-body">
              <div class="space-features">

                <ng-container *ngFor="let section of sections">
                  <div (click)="expand[section.key] = !expand[section.key]" appRipple
                    class="space-features-header {{ expand[section.key] ? 'active' : '' }}">
                    <div class="feature icon" [innerHTML]="section.icon">
                    </div>

                    <div class="name">
                      {{ section.name | translate}}
                    </div>

                    <div class="arrow">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="ai ai-ChevronRight">
                        <path d="M8 4l8 8-8 8" />
                      </svg>
                    </div>
                  </div>

                  <div class="space-features-items" *ngIf="expand[section.key]">
                    <div class="item" *ngFor="let item of space[section.key]">
                      {{ item.name | translate}}
                    </div>

                  </div>
                </ng-container>

              </div>

            </div>
          </div>
        </div> -->

        <div class="card">
          <div class="card-header">
            <a
              class="collapsed btn"
              data-bs-toggle="collapse"
              href="#collapseSix"
            >
              <span class="heading-option-style">
                {{ "Cancellation Policy" | translate }} </span
              ><i class="fa fa-angle-right toggle-icon-style"></i>
            </a>
          </div>
          <div id="collapseSix" class="collapse" data-bs-parent="#accordion">
            <div class="card-body card-override">
              <h6>{{ "Cancellations of Booking Contracts" | translate }}</h6>

              <p>
                {{
                  "Cancellations of Booking Contracts Hosts and Guests One"
                    | translate
                }}<b>{{ "Guarantee" | translate }}</b
                >{{
                  "Cancellations of Booking Contracts Hosts and Guests two"
                    | translate
                }}
              </p>

              <p>
                {{ "Cancellations of Booking Contracts User One" | translate }}
                <b>{{ "Guarantee" | translate }}</b
                >{{ "Cancellations of Booking Contracts User Two" | translate
                }}<b>{{ "Guarantee Terms" | translate }}</b
                >.
                {{
                  "Cancellations of Booking Contracts User Three" | translate
                }}
              </p>

              <!-- <div>
                  <h6>{{"Insurance in Case of Cancellations" | translate}}</h6>
                  <p>
                      {{"Insurance in Case of Cancellations Paragragh" | translate}}
                  </p>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="plans_access" *ngIf="isEdit">
      <button type="button" class="btn_large" (click)="goToEditSpace()">
        {{ "Edit home" | translate }}
      </button>
    </div>
  </div>
</div>

<!-- <div id="socialshare-modal" *ngIf="shareModalStatus">
    <div>
        <i (click)="socialShareModal()" class="fa fa-times share-close-icon"></i>
    </div>

    <div>
        <i class="fa fa-facebook-square fb-icon" aria-hidden="true" shareButton="facebook"></i>
        <i class="fa fa-twitter twitter-icon" aria-hidden="true" shareButton="twitter"></i>
        <i class="fa fa-pinterest pinterest-icon" aria-hidden="true" shareButton="pinterest"></i>
        <i class="fa fa-whatsapp whatsapp-icon" aria-hidden="true" shareButton="whatsapp"></i>
        <i class="fa fa-linkedin linkedIn-icon" aria-hidden="true" shareButton="linkedin"></i>
    </div>
</div> -->

<div class="home_space-desktop">
  <!-- <app-header title="" [back]="true" [static]="false" [background_color]="'white'"></app-header> -->

  <div class="breadcrumbs">
    <div class="breadcrumbs_wrapper">
      <!-- breadcrumbs -->
      <breadcrumbs [breadcrumb]="items"></breadcrumbs>
    </div>
  </div>
  <div class="home_space_wrapper">
    <div class="container">
      <div class="sticky_header">
        <div class="explore_left">
          <img (click)="pop()" src="/assets/images/back_arrow.svg" />
        </div>
        <div
          class="home_header_section"
          [ngClass]="{
            'home_header_section-override': selectedLanguage == 'ar'
          }"
        >
          <div class="address_info">
            <h1 class="user_name">{{ space.title }}</h1>

            <p
              class="location_info"
              *ngIf="space?.address?.country || space?.address?.city"
            >
              <img src="/assets/images/mark_ic.svg" />
              {{
                space?.address?.country
                  ? space?.address?.country + " , "
                  : "
              "
              }}
              {{ space?.address?.city }}
            </p>
            <!--            <div class="rating_item" *ngIf="space?.reviews?.average>0">-->
            <!--              <img src="/assets/images/star_ic.svg">-->
            <!--              {{space?.reviews?.average}}-->
            <!--              <span class="ms-2">{{space?.reviews?.total_reviews}} {{space?.reviews?.total_reviews <= 1 ? "review" : "reviews" | translate}}</span>-->
            <!--            </div>-->
            <div
              class="token_icon"
              *ngIf="space.purpose != null && space.purpose.name != 'swap'"
            >
              {{ space.hosting_tokens }}
              <span
                class="gray_text"
                [ngClass]="{ 'gray_text-override': selectedLanguage == 'ar' }"
                >{{ "per night" | translate }}
              </span>
            </div>
          </div>
          <div
            class="header_left_section"
            [ngClass]="{
              'header_left_section-override': selectedLanguage == 'ar'
            }"
          >
            <!-- <a class="send_btn"><img src="/assets/icons/share-icon.svg"> share</a> -->
            <div class="share send_btn mr-20">
              <app-social-share
                [homeId]="spaceId"
                [imgUrl]="_albums[selectedCoverImage]?.src"
                [shareMessage]="shareMessage + space.deeplink_url"
                [deeplinkUrl]="space.deeplink_url"
              ></app-social-share>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="gallerview_wrapper">
          <div class="fullview_image h-100">
            <img
              [src]="_albums[selectedCoverImage]?.src"
              alt=""
              #spaceCoverImg
              (click)="openLightbox()"
            />
            <span class="img_count"
              >{{ selectedCoverImage + 1 }} / {{ _albums.length }}
            </span>
          </div>

          <div class="">
            <!-- <ng-container  *ngFor="let image of _albums; let i=index" >
                  <div class="thumb_item"  (click)="open(i)" [style.backgroundImage]="'url('+image.thumb+')'">
                  </div> -->
            <!-- <div class="thumb_item" style="background-image:url('/assets/images/thumbview.png')">
                    <div class="more_view">
                      show all
                    </div>
                  </div> -->
            <!-- </ng-container> -->

            <swiper [config]="desktopSwiperConfig">
              <ng-container
                *ngFor="let image of _albums; index as i; last as last"
              >
                <ng-template swiperSlide>
                  <div
                    class="space-slider-image"
                    (click)="selectedCoverImage = i"
                  >
                    <img class="lazyload" [lazyLoad]="image.src" alt="" />
                    <div
                      class="more_view"
                      (click)="openLightbox(0)"
                      *ngIf="
                        last &&
                        _albums.length > desktopSwiperConfig.slidesPerView
                      "
                    >
                      show all
                    </div>
                  </div>
                </ng-template>
              </ng-container>
            </swiper>
          </div>
        </div>

        <div class="overview_wrapper">
          <h4 class="mb_16 text_16 font_bold">{{ "Overview" | translate }}</h4>
          <p *ngIf="!space?.guests || !space?.bedrooms || !space?.bathrooms">
            No overviews to show.
          </p>

          <div class="overview_outer mb_16">
            <span class="small_chip" *ngIf="space?.guests">
              <!-- <img src="/assets/icons/user.svg" alt=""> -->
              <svg
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.99922 6.10019C7.7153 6.10019 8.40206 5.81573 8.90841 5.30938C9.41475 4.80304 9.69922 4.11628 9.69922 3.4002C9.69922 2.68411 9.41475 1.99736 8.90841 1.49101C8.40206 0.984659 7.7153 0.700195 6.99922 0.700195C6.28313 0.700195 5.59638 0.984659 5.09003 1.49101C4.58368 1.99736 4.29922 2.68411 4.29922 3.4002C4.29922 4.11628 4.58368 4.80304 5.09003 5.30938C5.59638 5.81573 6.28313 6.10019 6.99922 6.10019ZM0.699219 14.2002C0.699219 13.3729 0.862173 12.5536 1.17878 11.7893C1.49538 11.0249 1.95944 10.3304 2.54445 9.74542C3.12945 9.16041 3.82396 8.69636 4.58831 8.37975C5.35266 8.06315 6.17189 7.90019 6.99922 7.90019C7.82655 7.90019 8.64577 8.06315 9.41012 8.37975C10.1745 8.69636 10.869 9.16041 11.454 9.74542C12.039 10.3304 12.5031 11.0249 12.8197 11.7893C13.1363 12.5536 13.2992 13.3729 13.2992 14.2002H0.699219Z"
                />
              </svg>

              {{ space?.guests }}
            </span>
            <span class="small_chip" *ngIf="space?.bedrooms">
              <!-- <img src="/assets/icons/user.svg" alt=""> -->
              <svg
                width="16"
                height="11"
                viewBox="0 0 16 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.9643 4.28577C14.4286 4.28577 14.4286 4.28577 14.4286 4.82148V6.32145H1.57143V0.535716C1.57143 0 1.57143 0 1.03571 0C0.5 0 0.5 0 0.5 0.535716V10.1786C0.5 10.7143 0.5 10.7143 1.03571 10.7143C1.57143 10.7143 1.57143 10.7143 1.57143 10.1786V8.57146H14.4286V10.1786C14.4286 10.7143 14.4286 10.7143 14.9643 10.7143C15.5 10.7143 15.5 10.7143 15.5 10.1786V4.82144C15.5 4.28573 15.5 4.28573 14.9643 4.28573V4.28577ZM3.63929 4.47859C4.42147 4.47859 4.97857 3.92148 4.97857 3.1393C4.97857 2.36787 4.42147 1.80001 3.63929 1.80001C2.86786 1.80001 2.3 2.36787 2.3 3.1393C2.3 3.92148 2.86786 4.47859 3.63929 4.47859ZM13.3571 4.76797C13.3571 3.25725 12.2643 2.14289 9.93929 2.14289H8.40718C6.0821 2.14289 5.26793 4.73583 5.00008 5.35718H13.3572L13.3571 4.76797Z"
                />
              </svg>

              {{ space?.bedrooms }}
            </span>
            <span
              class="small_chip"
              *ngIf="space?.bathrooms"
              [ngClass]="{ 'small_chip-override': selectedLanguage == 'ar' }"
            >
              <!-- <img src="/assets/icons/user.svg" alt=""> -->
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.77867 5.00094H5.52267C5.52267 0.629296 12.1964 0.629296 12.1964 5.00094V14H13.9287L13.9404 5.00094C13.9521 -1.67279 3.79031 -1.66116 3.77868 5.00094H3.77867ZM1.151 10.1283C0.337049 11.5002 0 12.1281 0 12.7443C0 13.5931 0.639384 13.9999 1.151 13.9999C1.6394 13.9999 2.26712 13.5929 2.26712 12.7443C2.26712 12.1281 1.95326 11.5236 1.151 10.1283ZM4.65067 10.1283C3.84843 11.5002 3.49967 12.1281 3.49967 12.7443C3.49967 13.5931 4.15077 13.9884 4.65067 13.9884C5.15058 13.9884 5.80167 13.5931 5.80167 12.7443C5.80167 12.1281 5.47613 11.5236 4.65067 10.1283ZM8.15035 10.1283C7.3481 11.4887 7.03423 12.1049 7.03423 12.7326C7.03423 13.593 7.66211 13.9882 8.15035 13.9882C8.66192 13.9882 9.30134 13.593 9.30134 12.7326C9.30134 12.1048 8.97581 11.5002 8.15035 10.1283ZM1.17434 8.65164H8.12709C8.72009 8.65164 8.72009 8.65164 8.72009 8.05863V7.83771C8.72009 3.14053 0.581417 3.14053 0.581417 7.83771V8.05863C0.581417 8.65164 0.581417 8.65164 1.17442 8.65164H1.17434Z"
                  fill="#23262F"
                />
              </svg>

              {{ space?.bathrooms }}
            </span>
            <!-- <span class="small_chip">

                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.63579 1.06429C8.46701 0.895565 8.23814 0.800781 7.99949 0.800781C7.76084 0.800781 7.53196 0.895565 7.36319 1.06429L1.06319 7.36429C0.899245 7.53403 0.80853 7.76137 0.810581 7.99735C0.812631 8.23333 0.907284 8.45906 1.07415 8.62593C1.24102 8.79279 1.46675 8.88745 1.70273 8.8895C1.9387 8.89155 2.16605 8.80083 2.33579 8.63689L2.59949 8.37319V14.3006C2.59949 14.5393 2.69431 14.7682 2.86309 14.937C3.03187 15.1058 3.26079 15.2006 3.49949 15.2006H5.29949C5.53818 15.2006 5.7671 15.1058 5.93588 14.937C6.10467 14.7682 6.19949 14.5393 6.19949 14.3006V12.5006C6.19949 12.2619 6.29431 12.033 6.46309 11.8642C6.63187 11.6954 6.86079 11.6006 7.09949 11.6006H8.89949C9.13818 11.6006 9.3671 11.6954 9.53588 11.8642C9.70467 12.033 9.79949 12.2619 9.79949 12.5006V14.3006C9.79949 14.5393 9.89431 14.7682 10.0631 14.937C10.2319 15.1058 10.4608 15.2006 10.6995 15.2006H12.4995C12.7382 15.2006 12.9671 15.1058 13.1359 14.937C13.3047 14.7682 13.3995 14.5393 13.3995 14.3006V8.37319L13.6632 8.63689C13.8329 8.80083 14.0603 8.89155 14.2962 8.8895C14.5322 8.88745 14.758 8.79279 14.9248 8.62593C15.0917 8.45906 15.1863 8.23333 15.1884 7.99735C15.1904 7.76137 15.0997 7.53403 14.9358 7.36429L8.63579 1.06429Z"
                    fill="#23262F" />
                </svg>


                studio
              </span> -->
          </div>
          <p class="overview_info">{{ space?.description }}</p>
        </div>
        <div class="map-section">
          <div class="map_header1 d-flex justify-content-between mb_16">
            <div class="section_label">{{ "Map Location" | translate }}</div>
            <!-- <a class="text_btn">{{"Direction" | translate}}</a> -->
          </div>
          <div class="location_map">
            <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1251822.1217861257!2d4.158466276504734!3d52.20768204530646!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c609c3db87e4bb%3A0xb3a175ceffbd0a9f!2sNetherlands!5e0!3m2!1sen!2sin!4v1646731027996!5m2!1sen!2sin" width="100%" height="260" style="border:0;" allowfullscreen="" loading="lazy"></iframe> -->
          </div>
        </div>
        <div class="facility_outer">
          <div class="instant_book mb_16">
            <div>
              <!-- <div class="section_label mb_16">{{"Features" | translate}}</div>

              <p *ngIf="spaceFeatures.length<1 || spaceFeatures==null">{{ "No features to show." | translate }}</p>
              <ul>
                <li class="facility_box" *ngFor="let feature of spaceFeatures">

                  {{ feature?.name }}
                </li>

              </ul> -->

              <!-- <img src="{{feature?.image_url}}"><br> -->
              <!-- <li class="facility_box"><img src="/assets/images/ac_n.svg"><br>AC</li> -->
              <!-- <li class="facility_box"><img src="/assets/images/bad_bl.svg"><br>Bed</li> -->
            </div>
            <div class="right-section">
              <div class="right_inner">
                <div class="section_label">
                  {{ "Instant booking" | translate }}
                </div>
                <div class="toggle-switch">
                  <span class="toggle_label" *ngIf="space?.is_instant == '1'">{{
                    "On" | translate
                  }}</span>
                  <span class="toggle_label" *ngIf="space?.is_instant == '0'">{{
                    "Off" | translate
                  }}</span>
                  <label class="switch">
                    <input
                      type="checkbox"
                      disabled
                      [checked]="space?.is_instant == '1'"
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="reviews_outer mb_36">
          <div class="reviews_header d-flex justify-content-between mb_16">
            <div class="section_label">{{ "Reviews" | translate }}</div>
            <a
              class="text_btn"
              [ngClass]="{ no_cursor: space?.reviews?.data <= 0 }"
              >{{ "See all reviews" | translate }}</a
            >
          </div>
          <p *ngIf="space?.reviews?.data < 1">
            {{ "No reviews to show." | translate }}
          </p>

          <div class="bix-row user_reviews">
            <div
              class="col-md-4 col-sm-auto col-12 mb_36"
              *ngFor="let item of reviewes"
            >
              <div class="review_box">
                <p class="mb_24">
                  {{ item.comment }}
                </p>
                <div
                  class="user_details d-flex align-items-center justify-content-between"
                >
                  <!-- <app-avatar  width="40" height="40" [picture]="item.reviewer.image_url"
                     [name]="item.reviewer.first_name">
                </app-avatar> -->
                  <div class="user_image d-flex">
                    <div class="image_box">
                      <img
                        [src]="item.reviewer.image_url"
                        alt=""
                        (error)="onImgError($event)"
                      />
                    </div>
                    <div>
                      <p class="name_of_user">{{ item.reviewer.first_name }}</p>
                      <span class="date_of">{{
                        item.reviewer.last_active_at
                      }}</span>
                    </div>
                  </div>
                  <!--                  <div class="rating_item" *ngIf="item.rating > 0">-->
                  <!--                    <img src="/assets/images/star_ic.svg">-->
                  <!--                    {{item.rating}}-->
                  <!--                  </div>-->
                </div>
              </div>
            </div>
            <!-- <div class="col-4 mb_36">
                <div class="review_box">
                  <p class="mb_24">
                    “Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur euismod sed turpis non interdum..”
                  </p>
                  <div class="user_details d-flex align-items-center justify-content-between">
                    <app-avatar  width="40" height="40" [picture]="'/assets/images/user.png'"
                    [name]="user">
                </app-avatar>
                <div class="rating_item">
                  <img src="/assets/images/star_ic.svg">
                  4
                </div>
                  </div>

                </div>
              </div>
              <div class="col-4 mb_36">
                <div class="review_box">
                  <p class="mb_24">
                    “Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur euismod sed turpis non interdum..”                </p>
                  <div class="user_details d-flex align-items-center justify-content-between">
                    <app-avatar  width="40" height="40" [picture]="'/assets/images/user.png'"
                    [name]="user">
                </app-avatar>
                <div class="rating_item">
                  <img src="/assets/images/star_ic.svg">
                  4
                </div>
                  </div>

                </div>
              </div> -->
          </div>
          <div class="space-option-box">
            <div id="accordion">
              <div class="card">
                <div class="card-header">
                  <a
                    class="collapsed btn"
                    data-bs-toggle="collapse"
                    href="#collapseFive"
                  >
                    <span class="heading-option-style">
                      {{ "Hosted by" | translate }}
                      {{ space?.user?.first_name }}
                    </span>
                    <img src="/assets/icons/right-arrow.svg" alt="" />
                  </a>
                </div>
                <div
                  id="collapseFive"
                  class="collapse"
                  data-bs-parent="#accordion"
                >
                  <div class="card-body">
                    <div class="space-host">
                      <div class="picture">
                        <!--img [src]="space?.user?.image_url" /-->
                        <app-avatar
                          height="100"
                          width="100"
                          [picture]="space?.user?.image_url"
                          [name]="space?.user?.first_name"
                          [verified]="space?.user?.is_verified"
                        >
                        </app-avatar>
                      </div>

                      <div class="name">
                        {{ space?.user?.first_name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="card">
                <div class="card-header">
                  <a class="collapsed btn" data-bs-toggle="collapse" href="#collapseThree">
                    <span class="heading-option-style"> {{"Home details" | translate}} </span><img
                      src="/assets/icons/right-arrow.svg" alt="">
                  </a>
                </div>
                <div id="collapseThree" class="collapse" data-bs-parent="#accordion">
                  <div class="card-body">
                    <div class="space-features">

                      <ng-container *ngFor="let section of sections">
                        <div (click)="expand[section.key] = !expand[section.key]" appRipple
                          class="space-features-header {{ expand[section.key] ? 'active' : '' }}">
                          <div class="feature icon" [innerHTML]="section.icon">
                          </div>

                          <div class="name">
                            {{ section.name | translate}}
                          </div>

                          <div class="arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                              fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" class="ai ai-ChevronRight">
                              <path d="M8 4l8 8-8 8" />
                            </svg>
                          </div>
                        </div>

                        <div class="space-features-items" *ngIf="expand[section.key]">
                          <div class="item" *ngFor="let item of space[section.key]">
                            {{ item.name | translate}}
                          </div>

                        </div>
                      </ng-container>

                    </div>

                  </div>
                </div>
              </div> -->

              <div class="card">
                <div class="card-header">
                  <a
                    class="collapsed btn"
                    data-bs-toggle="collapse"
                    href="#collapseSix"
                  >
                    <span class="heading-option-style">
                      {{ "Cancellation Policy" | translate }} </span
                    ><img src="/assets/icons/right-arrow.svg" alt="" />
                  </a>
                </div>
                <div
                  id="collapseSix"
                  class="collapse"
                  data-bs-parent="#accordion"
                >
                  <div class="card-body card-override">
                    <h6>
                      {{ "Cancellations of Booking Contracts" | translate }}
                    </h6>

                    <p>
                      {{
                        "Cancellations of Booking Contracts Hosts and Guests One"
                          | translate
                      }}<b>{{ "Guarantee" | translate }}</b
                      >{{
                        "Cancellations of Booking Contracts Hosts and Guests two"
                          | translate
                      }}
                    </p>

                    <p>
                      {{
                        "Cancellations of Booking Contracts User One"
                          | translate
                      }}
                      <b>{{ "Guarantee" | translate }}</b
                      >{{
                        "Cancellations of Booking Contracts User Two"
                          | translate
                      }}<b>{{ "Guarantee Terms" | translate }}</b
                      >.
                      {{
                        "Cancellations of Booking Contracts User Three"
                          | translate
                      }}
                    </p>

                    <!--
                    <div>
                        <h6>{{"Insurance in Case of Cancellations" | translate}}</h6>
                        <p>
                            {{"Insurance in Case of Cancellations Paragragh" | translate}}
                        </p>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          type="button"
          class="btn_large"
          (click)="goToEditSpace()"
          *ngIf="isEdit"
        >
          {{ "Edit home" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
<app-loading [loading]="loading"></app-loading>

<!-- add home form popup -->
<div *ngIf="openAddHomeForm" class="bix__modal-backdrop"></div>
<div class="add-home-form-modal" *ngIf="openAddHomeForm">
  <div class="close-section">
    <div class="skip-and-close">
      <span class="close-popup" (click)="addSpaceModal.exitClick()">
        <img alt="close icon" src="/assets/images/cancel_ic.svg"
      /></span>
    </div>
  </div>

  <div class="add_home_sheet h-100">
    <app-add-home-modal
      #addSpaceModal
      (exitModal)="closeAddFormPopup()"
      [editData]="space"
    ></app-add-home-modal>
  </div>
</div>
