<div class="popup-container" *ngIf="showPopup">
    <div class="popup-container--two">
        <div class="Found_Penny_wrapper">

            <img class="close-icon" src="/assets/icons/cancel_ic_white.svg" alt="close icon" (click)="closePopup()">

            <h2 class="Found_Title">
                YOU’VE FOUND PENNY!
            </h2>

            <div class="win_coins">
                WIN <sup class="subscript">$</sup>500
            </div>

            <div class="win_coins">
                WIN <sup class="subscript">$</sup>500
            </div>

            <div class="win_coins">
                WIN <sup class="subscript">$</sup>500
            </div>

            <div class="win_coins transparent">
                WIN <sup class="subscript">$</sup>500
            </div>

            <div class="piggy_image">
                <img src="/assets/images/piggy 1.png" alt="piggy image">
            </div>

            <div class="win_card">
                <img src="/assets/images/win500.svg" alt="win $500">
            </div>
            <!-- <div class="Tooltip">
                <div class="Tooltip_wrapper">
                    <button class="shareBtn twitterBtn" type="button" shareButton="twitter">
                        <img src="../../../../assets/images/Twitter_icon.png" alt="twitter image">
                    </button>

                    <p class="Tooltip_text">
                        TWEET YOUR RESULTS
                    </p>
                </div>
            </div> -->
            <div class="twitter-Tooltip">
                <app-twitter-share [pennyCode]="pennyUser?.code"></app-twitter-share>
            </div>

            <div *ngIf="!!pennyUser?.code" class="Twitter_code">
                {{ pennyUser.code }}
            </div>
        </div>
    </div>
</div>

