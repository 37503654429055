import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "src/app/shared/services/auth.service";
import { HttpService } from "src/app/shared/services/http.service";
import { LanguageService } from "src/app/shared/services/language.service";
import { SnackbarService } from "src/app/shared/services/snackbar.service";
import { environment } from "src/environments/environment";

@Component({
	selector: "app-reset",
	templateUrl: "./reset.component.html",
	styleUrls: ["./reset.component.scss"]
})
export class ResetComponent implements OnInit {
	public user: { email?: string, password?: string, confirm_password?: string } = {
	};

	public error: string;
	public confirmed: boolean = false;
	public redirect: any;
	public token: string;
	public step = 0;
	public loading = false;
	public userEmail:any;
	public baseUrl = environment.base;
	public isExpired = false;
	public hidePass = true;
	public hideConfirmPass = true;
	constructor(private authService: AuthService, private route: ActivatedRoute,
		private router: Router, private languageService: LanguageService,
		private snackbarService : SnackbarService,public translate: TranslateService
		) {
		if(localStorage.getItem("lang") == null) {
			this.languageService.SetLanguage("en");
		}
	}

	public ngOnInit() {
		this.route.queryParams.subscribe((params) => {
			if (params.token) {
				this.step = 2;
				this.token = params.token;
			}



		});

		this.authService.userEmail(this.token).subscribe((result: any)=>{

			// this.error = result.message;
			if(result?.message!= '' && typeof result.message != 'undefined'){
				this.snackbarService.show({
					message : result.message,
					type: "success",
					config : {
						class : 'centered'
					}
				})
			}

			this.userEmail = result;
			if (result.status == "0") this.isExpired = true;
			else this.isExpired = false;
		});

		if(localStorage.getItem("lang") == null) {
			this.languageService.SetLanguage("en");
		}
	}

	public onReset() {
		this.loading = true;
		this.authService.forgotPassword({
			email: this.user.email,
		}).subscribe((response) => {
			this.error = "";
			this.step = 3;
		}, (err) => {
			// this.error = err;
			if(err!=''){
				this.snackbarService.show({
					message : this.translate.instant(err),
					type: "success",
					config : {
						class : 'centered'
					}
				})
			}

		}).add(() => {
			this.loading = false;
		});
	}

	public onSubmit() {
		if (this.isExpired) {
			// this.error = "Password link is expired";
			this.snackbarService.show({
				message : this.translate.instant("Password link is expired"),
				type: "success",
				config : {
					class : 'centered'
				}
			})
			return;
	    }
		if (this.user.password !== this.user.confirm_password) {
			// this.error = "Passwords do not match";
			this.snackbarService.show({
				message : this.translate.instant("Passwords do not match"),
				type: "success",
				config : {
					class : 'centered'
				}
			})
			return;
		}
		this.loading = true;
		this.error = "";
		this.authService.resetPassword({
			email: this.userEmail.email,
			password: this.user.password,
			token: this.token,
		}).subscribe((response) => {
			this.router.navigate(["/auth/login"], {queryParams : this.route.snapshot.queryParams, queryParamsHandling :'merge'});
		}, (err) => {

			// this.error = err;
			if(err!=''){
				this.snackbarService.show({
					message : err,
					type: "success",
					config : {
						class : 'centered'
					}
				})
			}
		}).add(() => {
			this.loading = false;
		});
	}

	public backStep(){
		this.step = 0;
	}

	toggleHidePassword() {
		this.hidePass = !this.hidePass;
	}

	toggleHideConfirmPassword () {
		this.hideConfirmPass = !this.hideConfirmPass;
	}

}
