export const environment = {
	production: true,
	base: "https://app.dev.holidayswap.com",
	payments_enabled: true,
	clever_tap_id: "TEST-7ZW-R4K-WW6Z",
	envPrefix: "dev",
	paypal_client_id: "AVmxi6IJm_65ruf7QDNvoYr0lrcaUdQ67YMILOjOlW57g7IEBVxXpBBqX-DSuDqhU10cX2-QWinbvGN6",
	recaptcha: {
		siteKey: '6Lcd4ZYoAAAAAOUz88a-XXCjondrd3LcYmoPOXrW',
	},
	api: {
		base: "https://dev-api.holidayswap.com",
		version: "V1",
		client_id: "1"
	},
	social: {
		facebook: "1409065102481459",
		google: "87485270973-5kee4ookmmg6rq9oa1bao57elaeloa7j.apps.googleusercontent.com"
	},
	pusher: {
		app_key: "0879f64866fe82007dcd",
		auth_endpoint: `https://dev-api.holidayswap.com/broadcasting/auth`,
		cluster: "eu",
	},
	mapbox: {
		key: "pk.eyJ1IjoiaG9saWRheXN3YXAiLCJhIjoiY2t2YXltc3prMDcxYzJvcW5lbmh6Nmg3OSJ9.Gp8bk7jP4l5Pzliq9iyF3A",
		endpoint: "https://api.mapbox.com/geocoding/v5/mapbox.places",
	},
	firebase: {
		apiKey: "AIzaSyCPrQrG53Vv53lWwVnnSHQGPc4Q9bXu100",
		authDomain: "holidayswap-85aa1.firebaseapp.com",
		databaseURL: "https://holidayswap-85aa1.firebaseio.com",
		projectId: "holidayswap-85aa1",
		storageBucket: "holidayswap-85aa1.appspot.com",
		messagingSenderId: "87485270973",
		appId: "1:87485270973:web:51701b13e1a618acbca0d4",
		measurementId: "G-4MEMGGPVVX"
	},
	tipalti: {
		// base: "https://ui2.sandbox.tipalti.com/payeedashboard/home",
		// privateKey : "iU5Xu1HTxe7UohqYPRFHQL463iAIyl2Rc0H5XGiP+cbV7degLENcqUcS8ArOBnlQ",
		// payer:"HolidaySwapSB"

		base: "https://ui2.tipalti.com/payeedashboard/home",
		privateKey: "DUFk6W3I+3BOBWUpw/AnefGvX8j4/lyem7pGDs5IwbN7J27CZ8+0X4KjXPpmQgoR",
		payer: "HolidaySwap",
	},
	facebook: {
		accountId: '',
		version: '17.0',
		token: ''
	},
	stripe: "pk_test_sQjWReJa27Sfz7aekMHVPJyS",
	AWS_ACCESS_KEY: "AKIATR6ZL25UON6S5MT6",
	AWS_SECRET_KEY: "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",
	AWS_REGION: "us-east-1",
	AWS_BUCKET: "holidayswap-local",
	MAINTENANCE_MODE: false,
	shareasale: {
		merchantID: "146899"
	}
};
