import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CONSOLE_LEVELS } from '@sentry/utils';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { SwapService } from '../../services/swap.service';

@Component({
  selector: 'app-flexible-dates',
  templateUrl: './flexible-dates.component.html',
  styleUrls: ['./flexible-dates.component.scss'],
})
export class FlexibleDatesComponent implements OnInit {
  public days = [
    { text: '2 days', days: 2, id: 'day1', isActive: true },
    { text: '3 days', days: 3, id: 'day2', isActive: false },
    { text: '4 days', days: 4, id: 'day3', isActive: false },
    { text: '5 days', days: 5, id: 'day4', isActive: false },
    { text: '6 days', days: 6, id: 'day5', isActive: false },
    { text: '1 week', days: 7, id: 'day6', isActive: false },
    { text: '2 weeks', days: 14, id: 'day7', isActive: false },
  ];
  public months = [
    { value: '01', text: 'Jan' },
    { value: '02', text: 'Feb' },
    { value: '03', text: 'Mar' },
    { value: '04', text: 'Apr' },
    { value: '05', text: 'May' },
    { value: '06', text: 'Jun' },
    { value: '07', text: 'Jul' },
    { value: '08', text: 'Aug' },
    { value: '09', text: 'Sep' },
    { value: '10', text: 'Oct' },
    { value: '11', text: 'Nov' },
    { value: '12', text: 'Dec' },
  ];

  public filter: any = {
    purpose_ids: 522,
    ended_at: '',
    started_at: '',
    homeRating: 3,
    totalBookingDays: '',
    guestNumber: 1,
    days: [],
    months: [],
  };
  public loading: boolean = false;
  searchDate: any = '';
  public currentMonth = Number(moment().format('MM'));
  public currentYear = moment().format('yyyy');
  public nextYear = moment().add(1, 'year').format('yyyy');
  public years = [this.currentYear, this.nextYear];
  form: FormGroup;
  checkboxGroup: FormGroup;
  radiobtnGroup: FormGroup;
  @Output() public onFlexibleSelectEmit = new EventEmitter();
  @Input() public selectedDays;
  @Input() public selectedMonths = [];
  @Output() public searchViewListEmit = new EventEmitter();
  @Output() public onClearEmit = new EventEmitter();
  @Output() public onCloseEmit = new EventEmitter();
  clearButtonClicked: boolean = false;

  constructor(
    private fb: FormBuilder,
    private cookieService: CookieService,
    private cd: ChangeDetectorRef,
    private swapService: SwapService
  ) {
    this.radiobtnGroup = fb.group({
      days: new FormArray([]),
    });
    this.checkboxGroup = fb.group({
      months: new FormArray([]),
    });
    if (this.cookieService.get('searchFilter')) {
      this.filter = JSON.parse(this.cookieService.get('searchFilter'));
    }
  }

  ngOnInit(): void {
    const selectedValues = this.radiobtnGroup.controls['days'] as FormArray;
    if (this.selectedDays) {
      selectedValues.push(new FormControl({ days: this.selectedDays }));
    }

    const selectedMonth = this.checkboxGroup.controls['months'] as FormArray;
    this.selectedMonths.map((month) => {
      selectedMonth.push(new FormControl(month));
    });

    // this.days.map((day) => {
    //   if(day.days == this.selectedDays) {
    //     day.isActive = true;
    //   }
    // })

    setTimeout(() => {
      this.days.map((day) => {
        if (day.days == this.selectedDays) {
          day.isActive = true;
        }
      });
    }, 300);
  }

  ngOnChange() {}

  ondaysCheckboxChange(event: any, formName) {
    const selectedValues = this.radiobtnGroup.controls[formName] as FormArray;
    selectedValues.controls = [];

    if (event.target.checked) {
      selectedValues.push(new FormControl({ days: event.target.value }));
      this.selectedDays = event.target.value;
    } else {
      const index = selectedValues.controls.findIndex(
        (x) => x.value === event.target.value
      );
      selectedValues.removeAt(index);
    }
    const selectedMonth = this.checkboxGroup.controls['months'] as FormArray;
    this.selectedMonths.map((month) => {
      selectedMonth.push(new FormControl(month));
    });

    this.onFlexibleSelectEmit.emit({
      days: selectedValues?.value[0]?.days,
      months: selectedMonth.value,
    });
  }

  onMonthsCheckboxChange(event: any, formName) {
    const selectedValues = this.radiobtnGroup.controls['days'] as FormArray;
    selectedValues.removeAt(0);

    if (this.selectedDays) {
      selectedValues.push(new FormControl({ days: this.selectedDays }));
    }
    const selectedMonth = this.checkboxGroup.controls[formName] as FormArray;
    if (event.target.checked) {
      selectedMonth.push(new FormControl(event.target.value));
    } else {
      const index = selectedMonth.controls.findIndex(
        (x) => x.value === event.target.value
      );
      selectedMonth.removeAt(index);
    }

    this.onFlexibleSelectEmit.emit({
      days: selectedValues?.value[0]?.days,
      months: selectedMonth.value,
    });
    // this.filter.months = selectedMonth.value;
  }

  matchChecked(month, year) {
    let filterValue = this.selectedMonths.filter(
      (res) => res == month + '-' + year
    );
    if (filterValue.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  update(selected_days, selected_months) {
    // this.loading = true;
    if (this.selectedMonths.length == 0) {
      // this.checkboxGroup.controls['months'].setValue('')
      const selectedMonth = this.checkboxGroup.controls['months'] as FormArray;
      selectedMonth.clear();
    }
    this.selectedDays = selected_days;
    this.selectedMonths = selected_months;

    setTimeout(() => {
      this.days.map((day) => {
        if (day.days == this.selectedDays) {
          day.isActive = true;
        } else {
          day.isActive = false;
        }
      });
    }, 1000);

    const selectedMonth = this.checkboxGroup.controls['months'] as FormArray;
    let previous_months = this.months;

    setTimeout(() => {
      this.months = previous_months;
      this.loading = false;
    }, 150);
    this.cd.detectChanges();

    this.resetDays();
  }

  searchViewList() {
    this.searchViewListEmit.emit();
    // localStorage.setItem('searchFilters', '" "')
  }

  clearFlexible() {
    const selectedValues = this.radiobtnGroup.controls['days'] as FormArray;
    const selectedMonth = this.checkboxGroup.controls['months'] as FormArray;
    this.swapService.swap = {};
    selectedValues.clear();
    selectedMonth.clear();
    let daysoverride = this.days.map((day) => ({ ...day, isActive: false }));
    this.days = daysoverride;
    this.selectedDays = 2;
    this.selectedMonths = [];
    this.filter.started_at = '';
    this.filter.ended_at = '';

    localStorage.setItem('flexibleSelect', 'false');
    this.resetFilter();

    this.clearButtonClicked = true;

    setTimeout(() => {
      this.clearButtonClicked = false;
    }, 1000);
  }

  resetFilter() {
    this.filter.days = 2;
    this.filter.months = [];

    this.cookieService.set('searchFilter', JSON.stringify(this.filter));
    localStorage.setItem('searchFilters', JSON.stringify(this.filter));
    this.onClearEmit.emit();
  }

  closeFlex() {
    this.onCloseEmit.emit();
    this.clearFlexible();
  }

  resetDays() {
    const selectedValues = this.radiobtnGroup.controls['days'] as FormArray;
    // if(this.selectedDays) {
    //   selectedValues.push(new FormControl({days : this.selectedDays}));
    // }

    // const selectedMonth = (this.checkboxGroup.controls['months'] as FormArray);
    // this.selectedMonths.map((month) => {
    //   selectedMonth.push(new FormControl(month));
    // })

    // setTimeout(() => {
    //   this.days.map((day) => {
    //     if(day.days == this.selectedDays) {
    //       day.isActive = true;
    //     }
    //   })
    // }, 300)
  }
}
