<div class="introWrapper">
    <div class="logoBox">
        <div class="intro_brand">
            <img src="/assets/new-logo/app-logo-dark.svg" alt="">
            <!-- <img src="/assets/images/onboard/logo.svg"> -->
            <!-- <span class="brand_title">Holiday Swap</span> -->
        </div>
    </div>
    <div class="intro_container">
        <div class="betweenBox">
            <div class="slideWrapper">

                <swiper #swiper [config]="config" (swiper)="onSwiper($event)"
                    (slideChangeTransitionStart)="slideChange($event)">
                    <ng-template swiperSlide>
                        <div class="swiper_outerwarpper">
                            <div class="swiper_whitebackground">
                                <div class="swiper_imageBox">
                                    <img src="/assets/images/intro-new/slide1.png" alt="">
                                </div>
                                <div class="slider_rewardbox">
                                    <img src="../../../../../assets/images/intro-new/slide1_reward.png" alt="">
                                    <img src="../../../../../assets/images/intro-new/slide1_reward1.png" alt="">
                                </div>
                                <div class="swiper_textOuter">
                                    <!-- <h2 class="swiper_header">
                            Make new memories while <span>spending less</span>
                            </h2> -->
                                    <p class="slider_text">
                                        {{"Mi Casa, Su Casa! Join millions of travellers around the world" | translate}}
                                        <b>{{"earning" | translate}}</b> {{"thousands, sharing their homes and" | translate}} <b>{{"saving" | translate}}</b> {{"money during their travels!" | translate}}
                                    </p>
                                </div>

                                <div class="swiper_button">
                                    <button type="button" (click)="handleSignUp('listHome',1)" class="btn_block"
                                        *ngIf="this.activeIndex == 0">{{"Sign up and travel free" |  translate}}</button>
                                </div>
                            </div>
                        </div>
                    </ng-template>


                    <ng-template swiperSlide>
                        <div class="swiper_outerwarpper">
                            <div class="swiper_whitebackground">
                                <div class="swiper_imageBox">
                                    <img src="/assets/images/intro-new/slide2.png" alt="">
                                </div>
                                <div class="slider_rewardbox">
                                    <img src="../../../../../assets/images/intro-new/slide2_reward1.png" alt="">
                                    <img src="../../../../../assets/images/intro-new/slide2_reward2.png" alt="">
                                </div>
                                <div class="swiper_textOuter">
                                    <!-- <h2 class="swiper_header">
                            Worry less, <span>live more</span>
                            </h2> -->
                                    <p class="slider_text">
                                        {{"Whether you rent or own, list your space and" | translate}} <b>{{"get paid for hosting guests!" | translate}}</b> <br>
                                    </p>
                                </div>

                                <div class="swiper_button">
                                    <button type="button" (click)="handleSignUp('listHome',2)" class="btn_block"
                                        *ngIf="this.activeIndex == 1">{{"Sign up and list my home" | translate}}</button>
                                </div>
                            </div>
                        </div>
                    </ng-template>

                    <ng-template swiperSlide>
                        <div class="swiper_outerwarpper">
                            <div class="swiper_whitebackground">
                                <div class="swiper_imageBox">
                                    <img src="/assets/images/intro-new/slide3.png" alt="">
                                </div>
                                <div class="slider_rewardbox">
                                    <img src="../../../../../assets/images/intro-new/slide3_reward1.png" alt="">
                                    <img src="../../../../../assets/images/intro-new/slide3_reward2.png" alt="">
                                </div>
                                <div class="swiper_textOuter third_slide">
                                    <!-- <h2 class="swiper_header">
                            Enjoy <span>quick results</span> & reliable transactions
                            </h2> -->
                                    <div class="slider_text">
                                        <p class="mb-0"><b>{{ 'FULL ACCESS & 1000 POINTS' | translate }}</b></p>
                                        {{ "Subscribe to Premium and get FULL access with 1000 Holiday Swap Points as a gift from us!" | translate }}
                                    </div>
                                </div>
                                <div class="swiper_button">
                                    <button type="button" (click)="handleSignUp('tryPremium',3)" class="btn_block"
                                        *ngIf="this.activeIndex == 2">{{"Sign up and try premium" | translate}}</button>
                                </div>
                            </div>
                        </div>
                    </ng-template>


                </swiper>

            </div>
            <div class="swiper_button_wrapper">
                <p>{{"Already have an account?" | translate}} <span (click)="handleSignIn()">{{"Sign in" |
                        translate}}</span></p>
            </div>

        </div>
        <div class="intro_oval"></div>
    </div>

</div>
