<div class="header-streak">
  <p>Daily streak!</p>
  <img class="bg" src="/assets/images/icons/streaks.png" />
  <img
    class="step-2"
    *ngIf="currentStreak > 1"
    src="/assets/images/icons/success_step.svg"
  />
  <img
    class="step-3"
    *ngIf="currentStreak > 2"
    src="/assets/images/icons/success_step.svg"
  />
  <img
    class="step-4"
    *ngIf="currentStreak > 3"
    src="/assets/images/icons/success_step.svg"
  />
  <button (click)="navigatePrize()">Vouchers and Prizes</button>
</div>
