<div class="breadcrumbs_wrapper">
  <!-- breadcrumbs -->
  <breadcrumbs [breadcrumb]="items"></breadcrumbs>
</div>
<div class="app_container">
  <div class="explore_header">
    <!-- header left brand start -->
    <div class="explore_left">
      <div class="back_btn">
        <span (click)="pop()"><img src="/assets/images/back_arrow.svg" /></span>
      </div>
    </div>
    <!-- header left brand start -->
    <!-- <div class="explore-right">
          <ul>
              <li class="notification"><a><img src="/assets/images/noti_ic_bl.svg"><span class="noti_badge"></span></a></li>

          </ul>
         </div> -->
    <!-- <app-country-notification-header [backLink]="'/'"> </app-country-notification-header> -->
    <!-- <app-country-notification-header > </app-country-notification-header> -->
  </div>
  <!-- search listing start -->

  <div class="search-list-view">
    <div class="page-title">
      <h1>{{ "User Homes" | translate }}</h1>
      <!-- <button class="btn_orange" (click)="goToAddHome()" type="button">{{"User homes" | translate}}</button> -->
    </div>
    <div class="popular_widget_main">
      <!-- empty data content start -->
      <div class="empty-home-list" *ngIf="spaces.length == 0">
        <img src="../../../../../assets/images/emoticons_ic.svg" />
        <span class="empty-heading">{{
          "No homes added... yet!" | translate
        }}</span>
        <!-- <span class="empty_tagline">{{"Add new homes and start earning!" | translate}}</span> -->
        <!-- <div class="add_home_empty_btn">
                      <button type="button" (click)="goToAddHome()">{{"Add home" | translate}}</button>
                  </div> -->
      </div>
      <!-- empty data content End -->
      <div class="row_inner">
        <!-- search item start      -->
        <div
          class="popular_widget_item col-md-4 col-lg-4 col-sm-6 col-xs-12"
          *ngFor="let space of spaces; let i = index"
        >
          <div class="card">
            <div class="popular_image">
              <!-- <img src="/assets/images/4.jpg"> -->
              <img
                class="lazyload"
                (click)="goToUserSpaceDetails(space)"
                [lazyLoad]="space.image_url"
              />

              <div class="popular_action">
                <ul>
                  <!-- <li><a><img src="/assets/images/send_ic.svg" ></a></li> -->
                  <!-- <li><a><button type="button" (click)="editSpace(space)">{{"Edit" | translate}}</button></a></li> -->
                </ul>
              </div>
            </div>
            <div class="card-body" (click)="goToUserSpaceDetails(space)">
              <div class="popular_title">
                <h5 class="card-title">{{ space?.title }}</h5>
                <!--                              <span class="rating_item" *ngIf="space?.rating?.average > 0"><img src="/assets/images/star_ic.svg" > -->
                <!--                              <span class="rating_list">{{ space?.rating?.average }} </span>-->
                <!--                              </span>-->
              </div>
              <div class="popular_des">
                <span
                  >{{ space?.bedrooms }}
                  {{
                    (space?.bedrooms > 1 ? "Bedrooms" : "Bedroom") | translate
                  }}
                  . {{ space?.guests }}
                  {{ (space?.guests > 1 ? "guests" : "guest") | translate }}
                </span>
                <span *ngIf="space?.rating?.total_reviews > 0"
                  >{{ space?.rating?.total_reviews }}
                  {{
                    space?.rating?.total_reviews == 1
                      ? "review"
                      : ("reviews" | translate)
                  }}</span
                >
              </div>
              <hr />
              <div class="popular_footer">
                <span
                  class="explore_price"
                  *ngIf="
                    space?.hosting_tokens > 0 &&
                    space?.purpose != 'swap' &&
                    space.payment_type_id != 561
                  "
                >
                  {{ space?.hosting_tokens | number }}
                  <span class="gray_text pl-5">
                    {{ "per night" | translate }}</span
                  >
                </span>

                <span
                  class="explore_price"
                  *ngIf="
                    space?.hosting_amount >= 0 &&
                    space?.purpose != 'swap' &&
                    space.payment_type_id == 561
                  "
                >
                  <label class="tan-label" *ngIf="space?.tan_home_id != null">{{
                    "From " | translate
                  }}</label>
                  <span>${{ space?.hosting_amount | number }}</span>
                  <span class="gray_text pl-5">
                    {{ "per night" | translate }}</span
                  >
                </span>

                <span class="swap_show" *ngIf="space?.purpose == 'swap'">
                  <img src="/assets/images/new_swap_icon.svg" alt="" />
                  <span>{{ "Swap" | translate }}</span>
                </span>
                <span
                  class="space-address"
                  *ngIf="space?.address?.country || space?.address?.city"
                  ><img src="/assets/images/mark_gray_ic.svg" />&nbsp;{{
                    space?.address?.country
                      ? space?.address?.country + ", "
                      : " "
                  }}
                  {{ space?.address?.city }}</span
                >
              </div>

              <p class="home-complete-msg" *ngIf="spaceStatusMsg != ''">
                {{ spaceStatusMsg }}
              </p>
            </div>
          </div>
        </div>
        <!-- search item end      -->
        <!-- search item start      -->
        <!-- <div class="popular_widget_item col-md-4 col-lg-4 col-sm-6 col-xs-12">
              <div class="card">
                  <div class="popular_image">
                  <img src="/assets/images/4.jpg">
                  <div class="popular_action">
                      <ul>
                          <li><a><img src="/assets/images/send_ic.svg" ></a></li>
                          <li><a><button type="button">Edit</button></a></li>
                      </ul>
                  </div>
                  </div>
                  <div class="card-body">
                      <div class="popular_title">
                          <h5 class="card-title">Little cotton house</h5>
                          <span class="rating_item"><img src="/assets/images/star_ic.svg" >&nbsp;&nbsp;4.2</span>
                      </div>
                      <div class="popular_des">
                          <span>3 bedrooms . 6 guests </span>
                          <span>22 reviews</span>
                      </div>
                      <hr>
                      <div class="popular_footer">
                          <span><img src="/assets/images/mark_gray_ic.svg" >&nbsp;United States, Joshua Tree</span>
                          <span class="explore_price"><img  src="/assets/images/Token_Plane.png">$1,280</span>
                      </div>
                  </div>
                </div>
          </div> -->
        <!-- search item end      -->
        <!-- search item start      -->
        <!-- <div class="popular_widget_item col-md-4 col-lg-4 col-sm-6 col-xs-12">
              <div class="card">
                  <div class="popular_image">
                  <img src="/assets/images/4.jpg">
                  <div class="popular_action">
                      <ul>
                          <li><a><img src="/assets/images/send_ic.svg" ></a></li>
                          <li><a><button type="button">Edit</button></a></li>
                      </ul>
                  </div>
                  </div>
                  <div class="card-body">
                      <div class="popular_title">
                          <h5 class="card-title">Little cotton house</h5>
                          <span class="rating_item"><img src="/assets/images/star_ic.svg" >&nbsp;&nbsp;4.2</span>
                      </div>
                      <div class="popular_des">
                          <span>3 bedrooms . 6 guests </span>
                          <span>22 reviews</span>
                      </div>
                      <hr>
                      <div class="popular_footer">
                          <span><img src="/assets/images/mark_gray_ic.svg" >&nbsp;United States, Joshua Tree</span>
                          <span class="explore_price"><img  src="/assets/images/Token_Plane.png">$1,280</span>
                      </div>
                  </div>
              </div>
          </div> -->
        <!-- search item end -->
      </div>
    </div>
  </div>
</div>

<!-- add home form popup -->
<div *ngIf="popupbackdrop" class="bix__modal-backdrop"></div>
<div class="add-home-form-modal" *ngIf="openAddHomeForm">
  <div class="close-section">
    <div class="skip-and-close">
      <span class="close-popup" (click)="addSpaceModal.exitClick()">
        <img alt="close icon" src="/assets/images/cancel_ic.svg"
      /></span>
    </div>
  </div>

  <div class="add_home_sheet h-100">
    <app-add-home-modal
      #addSpaceModal
      (exitModal)="closeAddFormPopup()"
      [editData]="editHomeData"
    ></app-add-home-modal>
  </div>
</div>

<app-loading [absolute]="true" [loading]="loading"></app-loading>
