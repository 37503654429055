import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class LocationService {
	public position: BehaviorSubject<{ latitude: any, longitude: any }> = new BehaviorSubject({ latitude: 0, longitude: 0 });
	constructor() { }

	public getLocation(): Promise<{ latitude: number, longitude: number }> {
		return new Promise((resolve, reject) => {
			navigator.geolocation.getCurrentPosition(
				(position) => {

					this.position.next({
						latitude: position.coords.latitude,
						longitude: position.coords.longitude
					});

					resolve({
						latitude: position.coords.latitude,
						longitude: position.coords.longitude,
					});
				},

				(error) => {
					this.position.next({
						latitude: 41.3359375,
						longitude: 51.0722341
					});
					reject(error);
				}
			);
		});
	}

}
