import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ClevertapService } from 'src/app/shared/services/clevertap.service';
import { ExploreSpacesService } from 'src/app/shared/services/explore-spaces.service';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { SpaceService } from 'src/app/shared/services/space.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-popular-stay',
  templateUrl: './popular-stay.component.html',
  styleUrls: ['./popular-stay.component.scss']
})
export class PopularStayComponent implements OnInit {
  loading : boolean = false;
  featuredSpaces: any = [];
  userData: any = [];
  isPremiumMember: boolean = false;
  wishlist = [];
  userEmail;

  items = [
		{
			name: 'home',
			link: '/'
		},
		{
			label: 'Popular Homes'
		}
	]

  constructor(
    private exploreService: ExploreSpacesService,
    private spaceService: SpaceService,
    private notificationService: NotificationService,
    private userService: UserService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private clevertapService: ClevertapService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private router: Router,
    private snackbarService: SnackbarService
  ) {
    let userFullData = localStorage.getItem("userDataTEST");
		this.userData = JSON.parse(userFullData);
		if(this.userData){
			this.isPremiumMember = this.userData.subscription?.planName!='Lite' && this.userData.subscription?.is_trial == 1;
		}else{
			this.userService.currentUserSubject.subscribe(data=>{
				this.userData = data;
				this.isPremiumMember = this.userData.subscription?.planName!='Lite' && this.userData.subscription?.is_trial == 1;
			})
      // this.isPremiumMember = this.userData.subscription?.planName =='Lite' || this.userData.subscription?.is_trial == 0 ;

		}
    this.getFeaturedSpaces();
  }

  ngOnInit(): void {
    let userDataStorage = localStorage.getItem("userData");
    let userData = JSON.parse(userDataStorage);



    this.userEmail = userData?.email;
    this.userService.currentUser.subscribe((user) => {
      this.wishlist = user.favourited;
    })
  }

  public navigateToHome(id) {
    if(!id) return;

    localStorage.setItem("navUrl", "PopularHomes");
    if(this.clevertapService.isWebView){
      this
      .googleAnalyticsService
      .eventEmitter("home_clicks_app", "Clicks", "/view-home/home-details-ibs/"+id);
    }else{
      this
       .googleAnalyticsService
       .eventEmitter("home_clicks_web", "Clicks", "/view-home/home-details-ibs/"+id);
    }

      this.router.navigateByUrl("/view-home/home-details-ibs/"+id);
      if(this.wishlist.length <= 0) {
        let searchData = {
          email :this.userEmail
        }
        this.clevertapService.pushCleverTapEvent('wishlist',searchData);
      }
    }

  public getFeaturedSpaces() {
		this.loading = true;

		this.exploreService.getFeaturedSpaces().subscribe((res: any) => {
			this.loading = false;

			this.featuredSpaces = res.data;


		}, (err) => {
			this.loading = false;


		}).add(() => {
		});
	}

  getPersentage(amount){
    let discount = Math.round(parseInt(amount)*20/100);
    let offerAmt = amount - discount;
    return offerAmt;
  }

  public onLike(space,index) {

    this.spaceService.likeSpace(space.id, space.is_favourited).subscribe((response) => {
    //	space.is_favourited = !this.space.is_favourited;
      this.featuredSpaces[index].is_favourited = !space.is_favourited;


      if(this.featuredSpaces[index].is_favourited) {
        this.snackbarService.show({
          message: this.translate.instant("This home has been saved to your favourites!"),
          type: "success",
        });
      }
      else {
        this.snackbarService.show({
          message: this.translate.instant("Removed home from your favourites"),
          type: "success",
        });
      }

      // let message = {
      // 	"title": "Someone liked your space",
      // 	"text": 'Someone added your space in his favourite list'
      // };
      //if (space.is_favourited) this.notificationService.sendFCMNotification(message);


      let _tempuser = {
        "name": (this.notificationService.getSelectedFriend().first_name),
        "email": this.notificationService.getSelectedFriend().email,
        "friendId": this.notificationService.getSelectedFriend().id,
        "userId": this.userService.getCurrentUser().id,
        "title": "Your home was liked!",
        "notificationType": 4,
        "user_identity": this.notificationService.getSelectedFriend().clevertap_identity,
        "clevertap_id": this.notificationService.getSelectedFriend().clevertap_id,
        "message": (this.userService.getCurrentUser().first_name) + " liked your home. See if it's a match!"
      };

      this.clevertapService.sendNotification(_tempuser);

    }, (err) => {
      this.toastr.error(this.translate.instant(err), "Error");

    });
  }

}
