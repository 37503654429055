<div class="back-arrow">
  <!--    <app-back-button-common> </app-back-button-common>-->
  <span class="icon_img" (click)="handleBackByClosing()">
    <img src="assets/images/icons/x-close-icon.svg" alt="x-close"/>
  </span>
</div>

<!-- <div class="brand_header">
    <app-notification-header> </app-notification-header>
</div> -->
<!-- <div class="app_container"> -->
<!-- style="background-image: url('assets/images/search-bg.jpg')" -->
<div class="search-area-main">
  <div class="modal-body">
    <div class="app_container">
      <!-- content area start -->
      <div class="content_area">
        <!-- content header strt -->
        <div class="content_header">
          <h2 class="text-center">
            {{
              focusedElement
                ? TITLE[focusedElement]
                : ("Find your next stay" | translate)
            }}
          </h2>
          <!--                    <div class="search_area">-->
          <!--                        <div class="search_form">-->
          <!--                            <input type="text" id="search-address" class="form-input"  [ngClass]="{'search-address-override': selectedLanguage == 'ar'}" [(ngModel)]="location.full_address" (ngModelChange)="autoCompleteAddress($event)" placeholder="{{'Where are you going?' | translate}}" autofocus>-->
          <!--                            <button title="button" type="button" class="search_btn"><img alt="" src="/assets/images/loc_wh_ic.svg"></button>-->
          <!--                        </div>-->
          <!--                        <div class="results {{ locations.length > 0 ? 'visible' : '' }}">-->
          <!--                            <ul>-->
          <!--                                <li *ngFor="let location of locations" (click)="onSelect(location)">-->
          <!--                                    {{location.full_address}}-->
          <!--                                </li>-->
          <!--                            </ul>-->
          <!--                        </div>-->
          <!--                    </div>-->

          <div class="desktopFilter">
            <form (submit)="searchViewList()">
              <div class="row">
                <div class="col form_input_outer">
                  <!-- <label for="" class="label_txt">{{'Where to?' | translate}}</label> -->
                  <!-- <input type="text" class="input_field" name=""> -->
                  <div class="input-map-search">
                    <div class="input-group has_icon">
                      <!-- <input type="text" ngx-google-places-autocomplete class="form-control input_field"
                          (focus)="location.full_address?'':resultHide=false"
                          [ngModelOptions]="{standalone: true}"
                          [(ngModel)]="location.full_address"
                          (ngModelChange)="autoCompleteAddress($event)"
                          placeholder="{{'Search for homes' | translate}}"
                          aria-label="Recipient's username"> -->
                      <input
                        ngx-google-places-autocomplete
                        class="form-control search_input_field search_input_field_mobile"
                        [ngModelOptions]="{ standalone: true }"
                        [(ngModel)]="location.full_address"
                        (onAddressChange)="autoCompleteAddress($event)"
                        placeholder="{{
                          'Search for places to stay' | translate
                        }}"
                        [options]="inputoptions"
                        autocorrect="off"
                        (focus)="
                          handleFocusingOnElement(FormElementsEnum.Location)
                        "
                        (blur)="handleFocusingOnElement(null)"
                        (keydown.enter)="searchViewList()"
                        (keydown.enter)="autoCompleteAddressByEnter()"
                      />
                      <span class="icon_img"
                      ><img
                        style="background:white;"
                        src="assets/images/icons/location-marker-secondary.svg"
                        alt=""
                      /></span>
                    </div>
                    <div
                      class="results"
                      [class.visible]="locations.length > 0"
                      [hidden]="resultHide"
                    >
                      <ul class="mb-0">
                        <li
                          *ngFor="let location of locations"
                          (click)="onSelect(location, true)"
                        >
                          {{ location.full_address }}
                        </li>
                      </ul>
                      <ul
                        class="mb-0"
                        *ngIf="
                          locations.length <= 0 && historyLocations.length > 0
                        "
                      >
                        <li class="titleTxt">
                          {{ "Recent searches" | translate }}
                        </li>
                        <li
                          *ngFor="let location of historyLocations"
                          (click)="onSelect(location, false)"
                        >
                          {{ location.full_address }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col form_input_outer">
                  <div
                    class="drop_bg_layer"
                    *ngIf="showCalender || showFlexiablePlan"
                    (click)="closeClender()"
                  ></div>
                  <div class="calender_wrap">
                    <!-- <label for="" class="label_txt">{{'When?' | translate}}</label> -->
                    <div
                      class="input-group has_icon"
                      (click)="showCalenderFun()"
                    >
                      <input
                        type="text"
                        class="form-control search_input_field text-overflow"
                        [placeholder]="'Select dates' | translate"
                        readonly
                        name=""
                        autocorrect="off"
                        [ngModelOptions]="{ standalone: true }"
                        [(ngModel)]="searchDate"
                        (focus)="
                          handleFocusingOnElement(FormElementsEnum.Dates)
                        "
                        (keydown.enter)="searchViewList()"
                      />
                      <span class="icon_img calender"
                      ><img
                        src="assets/images/icons/calendar-secondary.svg"
                        alt=""
                      /></span>
                    </div>
                    <div class="calender_dropBox" [hidden]="!showCalender">
                      <h3 class="calender_dropBox_title">Choose Dates:</h3>
                      <app-calendar
                        #appCalendar
                        (dateChange)="onDateSelected($event)"
                        [alignMode]="'list'"
                        mode="range"
                        [startDate]="filter.started_at"
                        [endDate]="filter.ended_at"
                        [destroy]="false"
                        [min]="minDate"
                        [max]="maxDate"
                      >
                      </app-calendar>

                      <div class="calenderFooter">
                        <button
                          *ngIf="!tempDate?.end || !tempDate?.start"
                          type="button"
                          class="btn flexible_btn"
                          (click)="imFlex()"
                        >
                          {{ "I'm Flexible" | translate }}
                        </button>
                        <button
                          *ngIf="tempDate?.end && tempDate?.start"
                          type="button"
                          class="btn flexible_btn"
                          (click)="clearCalendar()"
                        >
                          {{ "Clear" | translate }}
                        </button>
                        <button
                          *ngIf="!isDesktopView"
                          type="button"
                          class="calBtn selectBtn"
                          (click)="updateDate()"
                        >
                          {{ "Select" | translate }}
                        </button>
                      </div>
                    </div>

                    <div class="Flexible_dropBox" [hidden]="!showFlexiablePlan">
                      <app-flexible-dates
                        #flexibleDates
                        (searchViewListEmit)="closeClender()"
                        (onFlexibleSelectEmit)="onFlexibleSelect($event)"
                        (onClearEmit)="clearEventEmit()"
                        [selectedDays]="selectedDays"
                        [selectedMonths]="selectedMonths"
                        (onCloseEmit)="closeFlex()"
                      ></app-flexible-dates>
                    </div>
                  </div>
                </div>

                <div class="col form_input_outer">
                  <!-- <label for="" class="label_txt">{{'Add Guests' | translate}}</label> -->
                  <div
                    class="input-group has_icon"
                    #bottomSheetAnchorRef
                    (click)="openGuestSheet()"
                  >
                    <input
                      type="text"
                      class="form-control search_input_field text-overflow"
                      [placeholder]="'Guests' | translate"
                      readonly
                      [value]="
                        filter.guestNumber
                          ? filter.guestNumber + ' Guests'
                          : 'Guests'
                      "
                      autocorrect="off"
                      (focus)="handleFocusingOnElement(FormElementsEnum.Guests)"
                      (keydown.enter)="searchViewList()"
                    />
                    <span class="icon_img">
                      <img
                        src="assets/images/icons/user-secondary.svg"
                        alt="user-secondary"
                      />
                    </span>
                  </div>
                  <!--                                    <div class="guestButton">-->
                  <!--                                      {{'Number of guests' | translate}}-->
                  <!--                                        <div class="rightBox">-->
                  <!--                                            <button type="button" title="btn" class="btn addBtn"-->
                  <!--                                                (click)="updateGuests('less')"><img-->
                  <!--                                                    src="assets/images/icons/minus-icon.svg" alt=""></button>-->
                  <!--                                            <span-->
                  <!--                                                [class.smallText]="!guestNumber">{{guestNumber?guestNumber:'Any' | translate}}</span>-->
                  <!--                                            <button type="button" title="btn" class="btn addBtn"-->
                  <!--                                                (click)="updateGuests('add')"><img-->
                  <!--                                                    src="assets/images/icons/plus-icon.svg" alt=""></button>-->
                  <!--                                        </div>-->

                  <!--                                    </div>-->
                </div>
                <div class="w-100 mb-4"></div>
                <div class="col"></div>
                <!--                                <div class="col">-->
                <!--                                    <app-star-rating-slider (starSelect)="starValChange($event)">-->
                <!--                                    </app-star-rating-slider>-->
                <!--                                </div>-->
                <div class="col"></div>
                <div class="w-100 mb-3"></div>
                <div class="col"></div>
                <div class="col">
                  <button type="submit" class="searchBtn">
                    {{ "Search" | translate }}
                  </button>
                </div>
                <div class="col"></div>
                <!-- <div class="col-auto align-self-end">

                </div> -->
              </div>
            </form>
            <!--                        <div class="popular_regions">-->
            <!--                            <h3>{{"Let us help" | translate}} <span>{{"plan your next trip!" | translate}}</span></h3>-->
            <!--                            <p>{{"Here are some places you might like based on your previous searches!" | translate}}-->
            <!--                            </p>-->
            <!--                            <ul class="place_list mb-0 list-unstyled">-->
            <!--                                <ng-container *ngFor="let space of featuredLocations; index as i">-->
            <!--                                    <li *ngIf="i < 5" (click)="searchResults(space?.city,space?.longitude,space?.latitude,space?.country,space?.full_address)">-->
            <!--                                        <div class="place_card">-->
            <!--                                            <img alt="img" class="lazyload" [lazyLoad]="space.image_url" defaultImage="/assets/images/default-cover.jpg" />-->
            <!--                                            &lt;!&ndash; <h5>{{(space?.city != space?.country)?-->
            <!--                                                ((space?.city)? (space?.city+','):('')):''}}-->
            <!--                                                {{space?.country}}</h5> &ndash;&gt;-->
            <!--&lt;!&ndash; space?.country &ndash;&gt;-->
            <!--                                               <h5>-->
            <!--                                                {{(!space?.city)? (space?.street ? space.street : '') : (space?.city)}}{{(space?.street == space?.country || space?.city == space?.country)?'':((!space.city && !space.street) ? space.country :', '+space?.country)}}-->
            <!--                                                </h5>-->

            <!--                                            &lt;!&ndash; {{(space?.city!=space?.country)?-->
            <!--                                                ((space?.city)?(space?.city+','):(space?.street+',')):-->
            <!--                                                ''}}{{space?.country}} &ndash;&gt;-->
            <!--                                        </div>-->
            <!--                                    </li>-->
            <!--                                </ng-container>-->
            <!--                            </ul>-->
            <!--                        </div>-->
          </div>
        </div>
        <!-- content header end -->
      </div>
    </div>
  </div>
</div>
<!-- </div> -->

<div *ngIf="!showCalender && !showFlexiablePlan" class="bottom_action">
  <button class="clear_button" (click)="clearFilter()">Clear</button>

  <button class="search_button" (click)="searchViewList()">
    {{ "Search" | translate }}
  </button>
</div>

<div #bottomSheetRef>
  <div *ngIf="showGuestBottomSheet" class="guest_bottom_sheet">
    <h2>How many guests?</h2>
    <div class="guest_bottom_sheet_item">
      <div>
        <p class="guest_type">Adults</p>
        <p class="guest_type_description">12+ years</p>
      </div>
      <div class="guest_item_action">
        <button
          type="button"
          title="btn"
          class="guest-control-btn"
          (click)="handleChangeGuests('adults', -1)"
        >
          <img src="assets/images/icons/minus-icon.svg" alt=""/>
        </button>
        <span [class.smallText]="!guests.adults">{{ guests.adults }}</span>
        <button
          type="button"
          title="btn"
          class="guest-control-btn"
          (click)="handleChangeGuests('adults', 1)"
        >
          <img src="assets/images/icons/plus-icon.svg" alt=""/>
        </button>
      </div>
    </div>
    <div class="guest_bottom_sheet_item">
      <div>
        <p class="guest_type">Children</p>
        <p class="guest_type_description">2 - 11 years</p>
      </div>
      <div class="guest_item_action">
        <button
          type="button"
          title="btn"
          class="guest-control-btn"
          (click)="handleChangeGuests('children', -1)"
        >
          <img src="assets/images/icons/minus-icon.svg" alt=""/>
        </button>
        <span [class.smallText]="!guests.children">{{ guests.children }}</span>
        <button
          type="button"
          title="btn"
          class="guest-control-btn"
          (click)="handleChangeGuests('children', 1)"
        >
          <img src="assets/images/icons/plus-icon.svg" alt=""/>
        </button>
      </div>
    </div>
    <div class="guest_bottom_sheet_item">
      <div>
        <p class="guest_type">Infants</p>
        <p class="guest_type_description">Under 2 years</p>
      </div>
      <div class="guest_item_action">
        <button
          type="button"
          title="btn"
          class="guest-control-btn"
          (click)="handleChangeGuests('infants', -1)"
        >
          <img src="assets/images/icons/minus-icon.svg" alt=""/>
        </button>
        <span [class.smallText]="!guests.infants">{{ guests.infants }}</span>
        <button
          type="button"
          title="btn"
          class="guest-control-btn"
          (click)="handleChangeGuests('infants', 1)"
        >
          <img src="assets/images/icons/plus-icon.svg" alt=""/>
        </button>
      </div>
    </div>
    <div class="guest_bottom_sheet_item">
      <div>
        <p class="guest_type">Pets</p>
        <p class="guest_type_description">
          Dogs, Cats, small non-dangerous animals
        </p>
      </div>
      <div class="guest_item_action">
        <button
          type="button"
          title="btn"
          class="guest-control-btn"
          (click)="handleChangeGuests('pets', -1)"
        >
          <img src="assets/images/icons/minus-icon.svg" alt=""/>
        </button>
        <span [class.smallText]="!guests.pets">{{ guests.pets }}</span>
        <button
          type="button"
          title="btn"
          class="guest-control-btn"
          (click)="handleChangeGuests('pets', 1)"
        >
          <img src="assets/images/icons/plus-icon.svg" alt=""/>
        </button>
      </div>
    </div>
  </div>
</div>
