import { Component, ElementRef, Input, OnInit } from "@angular/core";
import { UserService } from '../../services/user.service';
import { IPennyUser } from "../../interfaces/user";

@Component({
  selector: 'app-penny-popup',
  templateUrl: './penny-popup.component.html',
  styleUrls: ['./penny-popup.component.scss']
})
export class PennyPopupComponent implements OnInit {
  @Input() public spaceId = 0;
  userEmail: string;
  pennyUser: IPennyUser;
  showPopup: boolean = false;

  constructor(
    private elementRef: ElementRef,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    let userDataStorage = localStorage.getItem("userData");
    let userData = JSON.parse(userDataStorage);
    this.userEmail = userData?.email;
    this.userService.checkPennyUser(this.spaceId ,this.userEmail).subscribe((response: { data: IPennyUser }) => {
      if(response.data) {
        this.pennyUser = response.data;
        this.showPopup = (!!(!this.pennyUser.popup_status) && this.pennyUser.isPennyHome);
        if(!this.pennyUser?.code) {
          this.userService.pennyUserRegister({ email: this.userEmail }).subscribe((response: { data: IPennyUser }) => {
            this.pennyUser = response.data;
          })
        }
      }
    });
  }

  closePopup() {
    this.userService.pennyUserUpdatePopup({ email: this.userEmail, popup_status: 1 }).subscribe();
    const popupContainer = this.elementRef.nativeElement.querySelector('.popup-container');
    popupContainer.style.display = 'none';
  }
}
