import {
  Component,
  OnInit,
  ElementRef,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { FilterService } from 'src/app/shared/services/filter.service';

declare var $: any;

@Component({
  selector: 'app-explore-filter-modal',
  templateUrl: './explore-filter-modal.component.html',
  styleUrls: ['./explore-filter-modal.component.scss'],
})
export class ExploreFilterModalComponent implements OnInit {
  @Output() public onFilter = new EventEmitter();
  @Output() public onClear = new EventEmitter();

  public visible: boolean = false;

  priceBottomValue: number = 0;
  priceTopValue: number = 1500;
  priceOption: Options = {
    floor: 0,
    ceil: 1500,
    hideLimitLabels: true,
    hidePointerLabels: true,
    pushRange: true,
  };
  placeTypes: string = '';
  activeBedrooms: string = 'Any';
  activeBathrooms: string = 'Any';
  activeButtons = [false, false, false];
  private previousFilters: any;

  constructor(
    private elementRef: ElementRef,
    private filterService: FilterService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.previousFilters) {
      this.priceBottomValue = this.previousFilters.minPrice;
      this.priceTopValue = this.previousFilters.maxPrice;
      this.placeTypes = this.previousFilters.placeTypes;
      this.activeBedrooms = this.previousFilters.activeBedrooms;
      this.activeBathrooms = this.previousFilters.activeBathrooms;
      this.activeButtons = [...this.previousFilters.activeButtons];
      this.filterService.updateExploreFilters({
        minPrice: this.priceBottomValue,
        maxPrice: this.priceTopValue,
      });
    }
  }

  handleChangePlaceType(value: string) {
    this.placeTypes = value;
  }

  handleButtonClick(event: any, index: number) {
    this.activeButtons[index] = event.target.checked;
  }

  onChangeMinimumPrice(event) {
    if (event.target.value >= 0) {
      this.priceBottomValue = event.target.value;
    }
  }

  onChangeMaximumPrice(event) {
    if (event.target.value >= 0) {
      this.priceTopValue = event.target.value;
    }
  }

  clearFilter() {
    this.activeBathrooms = 'Any';
    this.activeBedrooms = 'Any';
    this.activeButtons = [false, false, false];
    this.priceTopValue = 1500;
    this.priceBottomValue = 0;
    this.placeTypes = '';
    this.previousFilters = {
      minPrice: 0,
      maxPrice: 1500,
      placeTypes: '',
      activeBedrooms: 'Any',
      activeBathrooms: 'Any',
      activeButtons: [false, false, false],
    };
    this.onClear.emit();
    this.filterService.updateExploreFilters({
      minPrice: this.priceBottomValue,
      maxPrice: this.priceTopValue,
    });
    this.onFilter.emit({
      place_types: this.placeTypes || undefined,
      hosting_amounts: `${this.priceBottomValue},${this.priceTopValue}`,
      bedrooms: this.activeBedrooms === 'Any' ? undefined : this.activeBedrooms,
      bathrooms:
        this.activeBathrooms === 'Any' ? undefined : this.activeBathrooms,
    });
  }

  handleSubmit() {
    this.previousFilters = {
      minPrice: this.priceBottomValue,
      maxPrice: this.priceTopValue,
      placeTypes: this.placeTypes,
      activeBedrooms: this.activeBedrooms,
      activeBathrooms: this.activeBathrooms,
      activeButtons: [...this.activeButtons],
    };

    this.filterService.updateExploreFilters({
      minPrice: this.priceBottomValue,
      maxPrice: this.priceTopValue,
      bedrooms: this.activeBedrooms === 'Any' ? undefined : this.activeBedrooms,
      bathrooms:
        this.activeBathrooms === 'Any' ? undefined : this.activeBathrooms,
      placeTypes: this.placeTypes || undefined,
    });

    this.onFilter.emit({
      place_types: this.placeTypes || undefined,
      hosting_amounts: `${this.priceBottomValue},${this.priceTopValue}`,
      bedrooms: this.activeBedrooms === 'Any' ? undefined : this.activeBedrooms,
      bathrooms:
        this.activeBathrooms === 'Any' ? undefined : this.activeBathrooms,
    });
    this.visible = false;
  }

  public open() {
    this.visible = true;
    $('.app-navigation').addClass('hide');

    this.cdr.detectChanges();

    // Move the element only after change detection has been completed
    const elementToMove = document.getElementById('explore-filter-modal');

    // Check if the element exists
    if (elementToMove) {
      document.body.appendChild(elementToMove);
    }
  }

  public close() {
    $('.app-navigation').removeClass('hide');
    if (this.previousFilters) {
      this.priceBottomValue = this.previousFilters.minPrice;
      this.priceTopValue = this.previousFilters.maxPrice;
      this.placeTypes = this.previousFilters.placeTypes;
      this.activeBedrooms = this.previousFilters.activeBedrooms;
      this.activeBathrooms = this.previousFilters.activeBathrooms;
      this.activeButtons = [...this.previousFilters.activeButtons];

      this.filterService.updateExploreFilters({
        minPrice: this.priceBottomValue,
        maxPrice: this.priceTopValue,
      });
    } else {
      this.activeBathrooms = 'Any';
      this.activeBedrooms = 'Any';
      this.activeButtons = [false, false, false];
      this.priceTopValue = 1500;
      this.priceBottomValue = 0;
      this.placeTypes = '';
      this.previousFilters = {
        minPrice: 0,
        maxPrice: 1500,
        placeTypes: '',
        activeBedrooms: 'Any',
        activeBathrooms: 'Any',
        activeButtons: [false, false, false],
      };
      this.filterService.updateExploreFilters({
        minPrice: this.priceBottomValue,
        maxPrice: this.priceTopValue,
        bedrooms:
          this.activeBedrooms === 'Any' ? undefined : this.activeBedrooms,
        bathrooms:
          this.activeBathrooms === 'Any' ? undefined : this.activeBathrooms,
        placeTypes: this.placeTypes || undefined,
      });
    }
    this.visible = false;
  }

  onSliderChange(event: any): void {}
}
