import { ToastrService } from 'ngx-toastr';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ThemeService } from './core/services/theme.service';
import { UpdateService } from './core/services/update.service';
import { StripeService } from './shared/services/stripe.service';
import { GlobalCacheConfig } from 'ts-cacheable';
import { LocalStorageStrategy } from 'ts-cacheable';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/shared/services/language.service';
import { HttpService } from './shared/services/http.service';
import { ClevertapService } from './shared/services/clevertap.service';
import { MessagingService } from './core/services/messaging.service';
import { DeeplinkService } from './shared/services/deeplink.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UserService } from './shared/services/user.service';
import { v4 as uuidv4 } from 'uuid';
declare var $: any;
declare global {
  interface Window {
    clearBadge: any;
  }
}
declare let gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public showInstallMessage = false;
  selectedLanguage = 'en';
  languageDirection = 'ltr';
  showPopupEmail: boolean = false;
  sentSucces: boolean = false;
  showMaxHomePopup: boolean = false;
  // _windowNavigator: any;
  // platform_status: number; // 0- browser, 1- standalone, 2- web-view

  @ViewChild('mainContent', { static: true }) mainContent: ElementRef;

  constructor(
    private stripeService: StripeService,
    private themeService: ThemeService,
    private updateService: UpdateService,
    private languageService: LanguageService,
    private translate: TranslateService,
    private httpService: HttpService,
    private clevertapService: ClevertapService,
    private messagingService: MessagingService,
    private deeplinkService: DeeplinkService,
    private router: Router,
    private userService: UserService,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) {
    this.stripeService.loadStripe();

    this.router.events.subscribe((event) => {
      let key;
      if (environment.envPrefix == 'prod') {
        key = 'G-6FQ07RR52D';
      } else {
        key = 'G-181C213316';
      }
      if (event instanceof NavigationEnd) {
        $('.app-navigation').removeClass('hide');
        $('.app-content').removeClass('addHomeModal');
        this.mainContent.nativeElement.scrollTop = 0;
        gtag('config', key, {
          page_path: event.urlAfterRedirects,
        });
        this.userService
          .postAnalytics(event.urlAfterRedirects, '')
          .subscribe((res) => {});
      }
    });

    this.translate.addLangs(['en', 'it', 'es', 'pt', 'tr']);
    let loadLang = this.languageService.GetLanguage() + '';

    if (loadLang == 'null' || loadLang == '') {
      this.translate.setDefaultLang('en');
      this.translate.use('en');
      this.selectedLanguage = 'en';
      this.languageDirection = 'ltr';
    } else {
      this.translate.setDefaultLang(loadLang);
      this.translate.use(loadLang);
      this.selectedLanguage = loadLang;
      if (loadLang == 'ar') this.languageDirection = 'rtl';
      else this.languageDirection = 'ltr';
    }

    this.languageService.languageDirChange.subscribe((direction) => {
      this.languageDirection = direction;
    });
    let prevWebView = localStorage.getItem('deviceIdentity');
    if (prevWebView && prevWebView == 'mobile_device') {
      this.handleWebView();
    }
    this.route.queryParams.subscribe(({ isWebview }: any) => {
      if (isWebview) {
        localStorage.setItem('deviceIdentity', 'mobile_device');
        this.handleWebView();
      }
    });
  }

  preventScroll(e) {
    // Check if the pressed key is an arrow key (up, down, left, or right)
    if (
      e.key === 'ArrowUp' ||
      e.key === 'ArrowDown' ||
      e.key === 'ArrowLeft' ||
      e.key === 'ArrowRight'
    ) {
      // Prevent the default scrolling behavior
      e.preventDefault();
    }
  }

  ngOnDestroy(): void {
    document.removeEventListener('keydown', this.preventScroll);
  }

  ngOnInit() {
    // const isIos = () => {
    // 	const userAgent = window.navigator.userAgent.toLowerCase();
    // 	return /iphone | ipad | ipod/.test(userAgent);
    // };
    // Detects if device is in standalone mode
    // const isInStandaloneMode = () => ("standalone" in window.navigator) && ((window as any).navigator.standalone);

    // if (isIos() && !isInStandaloneMode()) {
    //   this.showInstallMessage = true;
    // }

    this.userService.verifyMail.subscribe((data: any) => {
      if (data) {
        this.sentSucces = false;
        this.showPopupEmail = true;
        document.addEventListener('keydown', this.preventScroll);
      }
    });

    this.userService.maxAddHomeReached.subscribe((data: any) => {
      if (data) {
        this.showMaxHomePopup = true;
      }
    });

    this.clevertapService.initializeClevertap();
    // this.messagingService.askPermission();
    // setTimeout(() => {
    // 	this.clevertapService.subscribeNotification();
    // }, 10000);

    // let notifiactionData = {
    // 	"title": "message.title",
    // 	"message": "message.text",
    // 	"fcm_token": "eTkFRXvPGEOY_KutNgOWAp:APA91bE_Mry6bVCzrGukP1qNkNxm_iD2JcDkNpK5dS60JWJxsWZiNOpCAPO0edkvijCnRnO-18vJSllWgPxMuPJn44l0-h1LROeOuJ2L2chZQiz0-9p-Mmto9vScXn551omOAHSywWf5",
    // 	"friendid": ""
    // };
    // if (this.platform_status != 2) { // If this is not a web-view
    // 	this.clevertapService.initializeClevertap();
    // 	// this.messagingService.askPermission();
    // 	setTimeout(() => {

    // 		this.clevertapService.subscribeNotification();
    // 		// this.clevertapService.anonymousProfile();
    // 	}, 10000);
    // }
    // else {  // If this is a web-view

    // 	// This below function will listner and get data from Flutter app

    // 	// Flutter app will listen this below function
    // 	(<any>window).flutter_CT_channel = new MessageChannel();
    // 	(<any>window).flutter_CT_channel.postMessage('Hello from Angular holiday team (received)');
    // }

    // this.deeplinkService.createDynamicLink(3).subscribe((res)=>{

    // })

    // this.router.events.subscribe(response => {
    // 	if (response instanceof NavigationEnd) {

    // 	}
    // })
  }

  // Handle messages received on port1
  // onMessage(e) {

  // }

  public closeEmailPopup() {
    this.showPopupEmail = false;
    document.removeEventListener('keydown', this.preventScroll);
  }

  ngAfterViewInit() {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    };
    window.addEventListener('resize', appHeight);
    window.addEventListener('orientationchange', appHeight);
    window.addEventListener('ns-bridge-ready', (e) => {
      if ((window as any).nsWebViewBridge) {
        (window as any).nsWebViewBridge.emit('theme', {
          color: this.themeService.theme === 'dark' ? '#1a1a1a' : '#ffffff',
        });
        (window as any).nsWebViewBridge.emit('show-toolbar', false);
      }
    });

    appHeight();
  }

  clearBadgeCount = () => {
    this.userService.getMe().subscribe((res: any) => {
      this.userService.clearBadgeCount({
        email: res.data.email,
        clevertap_id: res.data.clever_tap_id,
      });
    });
  };

  verifyEmail() {
    let email = JSON.parse(localStorage.getItem('userData'))?.email;
    if (email) {
      this.userService
        .emailVerification({
          email: email,
        })
        .subscribe(
          (response: { status: boolean }) => {
            this.sentSucces = true;
            localStorage.setItem('verifyLocalMin', JSON.stringify(20));
            localStorage.setItem('verifyLocalSec', JSON.stringify(0));
          },
          (error) => {
            this.showPopupEmail = false;
            document.removeEventListener('keydown', this.preventScroll);

            this.toastr.error(
              'Too many attempts, Please try again later.',
              'Error'
            );
          }
        );
    }
  }

  handleWebView() {
    this.clevertapService.isWebView = true;
    const token = localStorage.getItem('token');
    if (token) {
      this.userService.getMe().subscribe(
        (res: any) => {
          this.clevertapService.updateClevertapLoginLoggedUser(res);
          this.clearBadgeCount();
          // window.clearBadge = this.clearBadgeCount;
          let quedCTEvents = localStorage.getItem('QuedCTEvents') as any;
          if (quedCTEvents) {
            quedCTEvents = JSON.parse(quedCTEvents);
            quedCTEvents.forEach((event) =>
              this.clevertapService.pushCleverTapEvent(event.event, event.data)
            );
            localStorage.removeItem('QuedCTEvents');
          }
          let quedRedirection = localStorage.getItem('qued_redirection');
          let quedRedirectionParams = JSON.parse(
            localStorage.getItem('qued_redirection_params')
          );
          if (quedRedirection) {
            this.router.navigate([quedRedirection], {
              queryParams: quedRedirectionParams,
            });
            localStorage.removeItem('qued_redirection');
            localStorage.removeItem('qued_redirection_params');
          }
        },
        (err: any) => {}
      );
    }
  }

  closeMaxHomePopup() {
    this.showMaxHomePopup = false;
  }
}
