import { AfterViewInit, Input } from '@angular/core';
import { ElementRef, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ExploreSpacesService } from 'src/app/shared/services/explore-spaces.service';
import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { MapboxService } from 'src/app/shared/services/mapbox.service';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from 'src/app/shared/services/language.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ClevertapService } from 'src/app/shared/services/clevertap.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import * as moment from 'moment';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FlexibleDatesComponent } from 'src/app/shared/components/flexible-dates/flexible-dates.component';
import { ICreateInstantPay } from 'src/app/shared/interfaces/swap';
import { SwapService } from 'src/app/shared/services/swap.service';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { CalendarComponent } from '../../../../shared/components/calendar/calendar.component';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from '../../../../shared/services/http.service';

declare var $: any;

export function debounce(delay: number = 300): MethodDecorator {
  return (
    _target: any,
    _propertyKey: string,
    descriptor: PropertyDescriptor
  ) => {
    let timeout = null;

    const original = descriptor.value;

    descriptor.value = function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => original.apply(this, args), delay);
    };

    return descriptor;
  };
}

enum FormElements {
  Location,
  Dates,
  Guests,
}

@Component({
  selector: 'app-search-homes',
  templateUrl: './search-homes.component.html',
  styleUrls: ['./search-homes.component.scss'],
})
export class SearchHomesComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() public isInHeader: boolean;
  isDesktopView: boolean = window.screen.width >= 1024;
  featuredLocations: any;
  addressCicked: boolean = false;
  searchedLocation: any;
  @ViewChild('searchSpaceInput', { static: false })
  searchSpaceInput: ElementRef;
  @ViewChild('flexibleDates') flexibleDates: FlexibleDatesComponent;
  @ViewChild('appCalendar') appCalendar: CalendarComponent;
  @ViewChild('placesRef') placesRef!: GooglePlaceDirective;
  @ViewChild('calendarWrapper') calendarWrapper: ElementRef;
  @ViewChild('guestBottomSheet') guestBottomSheet: ElementRef;

  public minDate = moment().toDate();
  public maxDate = moment().add(11, 'months').toDate();

  @HostListener('window:resize', ['$event'])
  handleResize(event: KeyboardEvent) {
    this.isDesktopView = window.screen.width >= 1024;
  }
  resultHide = true;
  _showCalender = new BehaviorSubject(false);
  get showCalender() {
    return this._showCalender.value;
  }
  set showCalender(value: boolean) {
    this._showCalender.next(value);
  }
  showFlexiablePlan = false;
  locationIsFocused = false;
  public locations = [];
  public selectedDays;
  public selectedMonths = [];

  public historyLocations = [];
  searchDate: any = '';
  guestNumber: number = 2;
  guests = {
    adults: 0,
    children: 0,
    infants: 0,
    pets: 0,
  };
  showFilterMob = false;
  public spaceList = [];
  public location: any = {};
  tempDate: any;
  // fullAddress : any;
  public selectedLanguage = 'en';
  public filter: any = {
    purpose_ids: 522,
    ended_at: '',
    started_at: '',
    totalBookingDays: '',
    guestNumber: 0,
    homeRating: 3,
    days: [],
    months: [],
  };
  public days = [
    { text: '2 days', days: 2, id: 'day1', isActive: false },
    { text: '3 days', days: 3, id: 'day2', isActive: false },
    { text: '4 days', days: 4, id: 'day3', isActive: false },
    { text: '5 days', days: 5, id: 'day4', isActive: false },
    { text: '6 days', days: 6, id: 'day5', isActive: false },
    { text: '1 week', days: 7, id: 'day6', isActive: false },
    { text: '2 weeks', days: 14, id: 'day7', isActive: false },
  ];
  public months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  public currentMonth = Number(moment().format('MM'));
  public currentYear = moment().format('yyyy');
  public nextYear = moment().add(1, 'year').format('yyyy');
  public years = [this.currentYear, this.nextYear];
  form: FormGroup;
  checkboxGroup: FormGroup;
  radiobtnGroup: FormGroup;
  userEmail;
  setFlexible: boolean = false;
  public swap: ICreateInstantPay = {
    type: 'host',
    your_swap: {
      guests: 1,
    },
    their_swap: {
      guests: 1,
    },
    token: 0,
  };
  public totalBookingDays = 1;
  // inputoptions = {
  //  types : ['administrative_area_level_1', 'country', 'postal_code']
  // }

  inputoptions: Options = new Options({
    // types: ['geocode'],
    fields: [
      'formatted_address',
      'geometry',
      'place_id',
      'address_components',
      'type',
    ],
  });

  hasHistory: boolean;

  focusedElement: FormElements | null = null;
  FormElementsEnum = FormElements;

  _showGuestBottomSheet = new BehaviorSubject(false);
  get showGuestBottomSheet() {
    return this._showGuestBottomSheet.value;
  }
  set showGuestBottomSheet(value: boolean) {
    this._showGuestBottomSheet.next(value);
  }

  TITLE = {
    [FormElements.Location]: 'Find your next stay',
    [FormElements.Dates]: 'What dates would you like to book?',
    [FormElements.Guests]: 'Who is coming?',
  };

  @ViewChild('bottomSheetRef') bottomSheetRef: ElementRef<HTMLDivElement>;
  @ViewChild('bottomSheetAnchorRef')
  bottomSheetAnchorRef: ElementRef<HTMLDivElement>;
  @ViewChild('calendarAnchorRef')
  calendarAnchorRef: ElementRef<HTMLDivElement>;

  isMobileView: boolean = window.screen.width < 769;
  private scrollTimeout: any;

  constructor(
    private cookieService: CookieService,
    private mapboxService: MapboxService,
    private router: Router,
    private route: ActivatedRoute,
    private languageService: LanguageService,
    private clevertapService: ClevertapService,
    private exploreService: ExploreSpacesService,
    private toastr: ToastrService,
    private filterService: FilterService,
    private userService: UserService,
    private swapService: SwapService,
    private fb: FormBuilder,
    public translate: TranslateService,
    private snackbarService: SnackbarService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private locationPath: Location,
    private httpService: HttpService
  ) {
    // this.getFeaturedLocations();
    // this.getSearchHistory();
    this.radiobtnGroup = fb.group({
      days: new FormArray([]),
    });
    this.checkboxGroup = fb.group({
      months: new FormArray([]),
    });
    this.hasHistory = this.router.navigated;
    this._showCalender.subscribe((value) => {
      if (value) {
        const properties = document.getElementById('search_area');
        const mainApp = document.getElementById('main-app');

        mainApp.scrollTo({
          top: Math.min(
            window.innerHeight / 2 - properties.clientHeight + 40,
            500
          ),
          behavior: 'smooth',
        });
      }
    });

    this._showGuestBottomSheet.subscribe((value) => {
      if (value) {
        const properties = document.getElementById('search_area');
        const mainApp = document.getElementById('main-app');

        mainApp.scrollTo({
          top: Math.min(
            window.innerHeight / 2 - properties.clientHeight + 40,
            500
          ),
          behavior: 'smooth',
        });
      }
    });
  }

  @HostListener('window:scroll', ['$event'])
  @HostListener('window:wheel', ['$event'])
  @HostListener('window:touchmove', ['$event'])
  onScroll(event: Event): void {
    if (this.router.url === '/map-view-list') {
      return;
    }
    $('.pac-container').css('visibility', 'hidden');
    clearTimeout(this.scrollTimeout);
    this.scrollTimeout = setTimeout(() => {
      const newTop =
        $('#input-location').offset().top + $('#input-location').outerHeight();
      $('.pac-container').css('top', newTop + 'px');
      const heroSection = document.getElementById('banner-hero-section');
      const scrollThreshold = heroSection.clientHeight;
      const currentScroll = document.getElementById('main-app').scrollTop;
      if (currentScroll > scrollThreshold - 200) {
        $('.pac-container').css('visibility', 'hidden');
      } else {
        $('.pac-container').css('visibility', 'visible');
      }
    }, 200);
  }

  ngOnInit(): void {
    $('#search-address').focus();

    this.languageService.languageUpdates.subscribe((lang) => {
      this.selectedLanguage = lang;
    });
    this.selectedLanguage = this.languageService.GetLanguage();
    let userDataStorage = localStorage.getItem('userData');
    let userData = JSON.parse(userDataStorage);
    this.userEmail = userData?.email ?? '';

    if (this.cookieService.get('searchFilter')) {
      if (this.router.url === '/map-view-list') {
        this.filter = JSON.parse(this.cookieService.get('searchFilter'));
        if (this.filter.started_at && this.filter.ended_at) {
          this.searchDate =
            moment(this.filter.started_at).format('ll') +
            ' - ' +
            moment(this.filter.ended_at).format('ll');
        }
        if (!this.filter.started_at && !this.filter.ended_at) {
          this.selectedDays = this.filter.days;
          this.selectedMonths = this.filter.months;
          this.patchMonths();
        }
        this.guestNumber = this.filter.guestNumber;
        this.guests = JSON.parse(this.cookieService.get('guestFilter'));
      }
    }

    if (this.filter?.days) {
      this.selectedDays = this.filter?.days;
    }
    if (this.filter?.months) {
      this.selectedMonths = this.filter?.months;
    }

    if (this.cookieService.get('search-address')) {
      this.location = JSON.parse(this.cookieService.get('search-address'));
    }

    this.route.queryParams.subscribe((params) => {
      if (params?.full_address) {
        this.location.full_address = params.full_address;
        this.location.longitude = params.longitude;
        this.location.latitude = params.latitude;
        localStorage.setItem('searchlocation', JSON.stringify(params));
        this.cookieService.set('search-address', JSON.stringify(params));
        this.addressCicked = true;
        this.searchViewList();
      }

      this.calcTotalGuests(this.guests);
    });
  }

  ngAfterViewInit() {}

  handleFocusingOnElement(element: FormElements | null) {
    this.focusedElement = element;
  }

  handleFocusOnInputLocation() {
    const inputElement = document.getElementById('input-location');
    if (inputElement) {
      inputElement.focus();
    }
  }

  handleFocusOnLocation(focused: boolean) {
    this.locationIsFocused = focused;
  }

  @HostListener('document:mousedown', ['$event.target'])
  onClick(htmlElement: HTMLElement) {
    if (
      !this.bottomSheetAnchorRef.nativeElement.contains(htmlElement) &&
      !this.bottomSheetRef.nativeElement.contains(htmlElement)
    ) {
      this.showGuestBottomSheet = false;
    }

    if (this.bottomSheetRef.nativeElement.contains(htmlElement)) {
      this.focusedElement = FormElements.Guests;
    }

    if (!this.calendarWrapper.nativeElement.contains(htmlElement)) {
      this.showCalender = false;
    }
  }

  openGuestSheet() {
    this.showGuestBottomSheet = true;
  }

  starValChange(event) {
    this.filter.homeRating = event;
  }

  public onSelect(location, save = false) {
    this.locations = [];
    this.location = location;
    this.resultHide = true;
    this.addressCicked = true;
    localStorage.setItem('searchlocation', JSON.stringify(this.location));
    // localStorage.setItem("searchFilter",this.filter);
    if (save) {
      this.searchedLocation = location;

      // this.saveHistory(location);
    }
  }
  public saveHistory() {
    let loc =
      this.searchedLocation?.city ||
      this.searchedLocation?.full_address.split(',')[0];
    this.googleAnalyticsService.eventEmitter(
      'SR_ExplorePage',
      'Search',
      'SR_' + loc
    );
    let obj = { ...this.searchedLocation, homeRating: this.filter.homeRating };

    if (this.httpService.ACCESS_TOKEN) {
      this.exploreService.saveSearchHistory(obj).subscribe((res) => {});
    }
  }

  autoCompleteAddressByEnter() {
    if (this.addressCicked) {
      this.searchViewList();
    }
  }

  @debounce(300)
  public autoCompleteAddress(query) {
    let formatted_address = query?.formatted_address;
    this.location.full_address = query?.formatted_address;
    this.resultHide = false;
    this.addressCicked = true;
    let searchAddressComponents = query?.address_components;
    let searchGeometry = query?.geometry;

    this._patchAddress(
      searchAddressComponents,
      searchGeometry,
      formatted_address
    );
  }

  _patchAddress(searchAddressComponents, geometry, formatted_address) {
    let address: any = {};
    let street_number = '';
    let street_route = '';
    $.each(searchAddressComponents, function () {
      if (this.types[0] == 'postal_code') {
        address.postcode = this.long_name;
      }

      if (this.types[0] == 'administrative_area_level_1') {
        address.state = this.long_name;
        // stateSet = true;
      }

      if (this.types[0] == 'administrative_area_level_2') {
        address.state = this.long_name;
        // stateSet = true;
      }

      if (this.types[0] == 'country') {
        address.country = this.long_name;
      }

      if (this.types[0] == 'colloquial_area' && this.types[1] == 'locality') {
        address.city = this.long_name;
      }

      if (this.types[0] == 'sublocality_level_1') {
        address.city = this.long_name;
      }

      if (this.types[0] == 'postal_town') {
        address.city = this.long_name;
      }

      if (this.types[0] == 'locality') {
        // address.line_2 = this.long_name;
        address.city = this.long_name;
      }

      if (this.types[0] == 'street_number') {
        street_number = this.long_name;
      }
      if (this.types[0] == 'route') {
        street_route = this.long_name;
      }
    });

    address.full_address = formatted_address;
    address.longitude = geometry?.location.lng();
    address.latitude = geometry?.location.lat();

    if (street_number != '' && street_route != '') {
      address.street = street_number + ' ' + street_route;
    } else if (street_number == '' && street_route != '') {
      address.street = street_route;
    }

    if (address?.length > 0) {
      this.locations = address;
      this.location = address;
    }
    this.location = address;

    this.onSelect(this.location);

    // return address;
  }

  private _parseResult(feature) {
    const address: any = {};
    if (feature.place_type[0] == 'place') {
      for (const result of feature.context) {
        if (result.id.includes('postcode')) {
          address.postcode = result.text;
        }
        if (result.id.includes('locality')) {
          address.line_2 = result.text;
        }
        if (result.id.includes('place')) {
          address.city = result.text;
        }
        if (result.id.includes('district')) {
          address.county = result.text;
        }
        if (result.id.includes('region')) {
          address.state = result.text;
        }
        if (result.id.includes('country')) {
          address.country = result.text;
        }
      }
    }
    // First line of address
    if (feature.text || feature.address) {
      address.street =
        (feature.address ? feature.address + ' ' : '') +
        (feature.text ? feature.text : '');
    }

    if (feature.place_type[0] == 'locality') {
      for (const result of feature.context) {
        if (result.id.includes('postcode')) {
          address.postcode = result.text;
        }
        if (result.id.includes('place')) {
          address.city = result.text;
        }
        if (result.id.includes('locality')) {
          address.line_2 = result.text;
        }
        if (result.id.includes('district')) {
          address.county = result.text;
        }
        if (result.id.includes('region')) {
          address.state = result.text;
        }
        if (result.id.includes('country')) {
          address.country = result.text;
        }
      }
    }
    if (feature.place_type[0] == 'neighborhood') {
      for (const result of feature.context) {
        if (result.id.includes('postcode')) {
          address.postcode = result.text;
        }
        if (result.id.includes('place')) {
          address.city = result.text;
        }
        if (result.id.includes('locality')) {
          address.line_2 = result.text;
        }
        if (result.id.includes('district')) {
          address.county = result.text;
        }
        if (result.id.includes('region')) {
          address.state = result.text;
        }
        if (result.id.includes('country')) {
          address.country = result.text;
        }
      }
    }
    if (feature.place_type[0] == 'address') {
      for (const result of feature.context) {
        if (result.id.includes('postcode')) {
          address.postcode = result.text;
        }
        if (result.id.includes('place')) {
          address.city = result.text;
        }
        if (result.id.includes('locality')) {
          address.city = result.text;
        }
        if (result.id.includes('district')) {
          address.county = result.text;
        }
        if (result.id.includes('region')) {
          address.state = result.text;
        }
        if (result.id.includes('country')) {
          address.country = result.text;
        }
      }
    }
    if (feature.place_type[0] == 'region') {
      for (const result of feature.context) {
        if (result.id.includes('postcode')) {
          address.postcode = result.text;
        }
        if (result.id.includes('place')) {
          address.city = result.text;
        }
        if (result.id.includes('district')) {
          address.county = result.text;
        }
        if (result.id.includes('region')) {
          address.state = result.text;
        }
        if (result.id.includes('country')) {
          address.country = result.text;
        }
      }
    }

    if (feature.id.includes('place')) {
      address.city = feature.text;
    }
    if (feature.id.includes('region')) {
      address.state = feature.text;
    }
    if (feature.id.includes('address')) {
      let houseNumber = feature.address;
      let position = feature.place_name.search(houseNumber);
      if (position == 0) {
        address.street =
          (feature.address ? feature.address + ' ' : '') + feature.text;
      } else {
        address.street =
          feature.text + (feature.address ? ' ' + feature.address : '');
      }
    }
    if (feature.id.includes('country')) {
      address.country = feature.text;
    }
    address.type = feature.place_type[0];
    // Full Address
    if (feature.place_name) {
      address.full_address = feature.place_name;
    }
    // Coordinates
    if (feature.center) {
      address.longitude = feature.center[0];
      address.latitude = feature.center[1];
    }

    return address;
  }

  onEnterKey() {
    this.searchViewList();
  }

  searchViewList() {
    if (this.location.full_address || this.location.city) {
      this.filter.guestNumber = this.guestNumber;
      this.searchedLocation = this.location;
      let loc =
        this.location?.city || this.location?.full_address?.split(',')[0];

      this.googleAnalyticsService.eventEmitter(
        'SR_ExplorePage',
        'Search',
        'SR_' + loc
      );

      this.saveHistory();
      this.showFlexiablePlan = false;
      localStorage.setItem('searchFilters', JSON.stringify(this.filter));
      localStorage.setItem('searchlocation', JSON.stringify(this.location));

      if (this.location.full_address) {
        this.cookieService.delete('search-address');
        this.filterService.purposeIds = 522;
        this.cookieService.set('search-address', JSON.stringify(this.location));

        this.cookieService.set('searchFilter', JSON.stringify(this.filter));

        // localStorage.setItem("navUrl","SearchList")
        if (this.isInHeader) {
          this.refresh();
        } else {
          this.router.navigate(['/map-view-list']);
        }

        localStorage.removeItem('Error');
        localStorage.removeItem('currentMapZoomLevel');
      } else {
        this.snackbarService.show({
          message: this.translate.instant(
            'Please select from the search list.'
          ),
          type: 'success',
          config: {
            class: 'centered',
          },
        });
      }
      this.userService
        .postAnalytics(this.router.url, 'explore_search_home_button')
        .subscribe((res) => {});
    } else {
      this.snackbarService.show({
        message: this.translate.instant('Please fill the place to stay.'),
        type: 'success',
        config: {
          class: 'centered',
        },
      });
    }
  }

  public clearFilter() {
    this.filter = {
      purpose_ids: 522,
      ended_at: '',
      started_at: '',
      totalBookingDays: '',
      guestNumber: 0,
      homeRating: 3,
      days: [],
      months: [],
    };

    this.location = {};

    this.appCalendar.resetDates();

    this.searchDate = '';

    this.guests = {
      adults: 0,
      children: 0,
      infants: 0,
      pets: 0,
    };
  }

  public getFeaturedLocations() {
    this.exploreService
      .similarSearch()
      .subscribe(
        (res: any) => {
          this.featuredLocations = res.data;
        },
        (err) => {}
      )
      .add(() => {});
  }
  public getSearchHistory() {
    this.exploreService.getSearchHistory().subscribe((res: any) => {
      this.historyLocations = res.data;
    });
  }

  searchResults(regionCity, longitude, latitude, country, full_address) {
    if (!full_address) {
      if (regionCity == null) {
        this.location.full_address = country;
      }
      if (country == null) {
        this.location.full_address = regionCity;
      }
      if (regionCity != null && country != null) {
        this.location.full_address = regionCity + ', ' + country;
      }
    }

    this.location.city_name = regionCity == null ? '' : regionCity;
    this.location.country = country == null ? '' : country;
    this.location.longitude = longitude;
    this.location.latitude = latitude;
    // this.guestNumber = guestNumber;
    this.cookieService.delete('search-address');
    localStorage.setItem('searchlocation', JSON.stringify(this.location));

    this.cookieService.set('search-address', JSON.stringify(this.location));
    this.cookieService.set('full_address', JSON.stringify(this.location));
    localStorage.setItem('guest', JSON.stringify(this.guestNumber));
    localStorage.setItem('savedSearch', '1');
    if (this.isInHeader) {
      this.refresh();
    } else {
      this.router.navigate(['/map-view-list']);
    }
  }

  refresh() {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/map-view-list']));
  }

  showCalenderFun() {
    this.showCalender = true;
    this.showFlexiablePlan = false;
  }
  closeClender() {
    this.showCalender = false;
    this.showFlexiablePlan = false;
  }

  clearCalendar() {
    this.tempDate = {};
    this.filter.ended_at = '';
    this.filter.started_at = '';
    this.appCalendar.resetDates();
    this.searchDate = '';
  }
  updateDate() {
    if (this.tempDate) {
      this.filter.ended_at = this.tempDate.end;
      this.filter.started_at = this.tempDate.start;
      this.filter.totalBookingDays = this.tempDate.days;
      if (this.tempDate.end == this.tempDate.start) {
        this.filter.ended_at = moment(this.tempDate.end).add(1, 'days');
      }
      if (this.tempDate.end) {
        this.searchDate =
          moment(this.tempDate.start).format('ll') +
          ' - ' +
          moment(this.filter.ended_at).format('ll');
      }
    }
    this.showCalender = false;
  }

  public onDateSelected(date) {
    this.tempDate = date;

    if (this.isDesktopView) {
      this.filter.ended_at = date.end;
      this.filter.started_at = date.start;
      this.filter.totalBookingDays = date.days;
      if (date.end == date.start) {
        this.filter.ended_at = moment(date.end).add(1, 'days');
      }
      if (date.end) {
        this.searchDate =
          moment(date.start).format('ll') +
          ' - ' +
          moment(this.filter.ended_at).format('ll');
      }
    }

    this.filter.days = [];
    this.filter.months = [];
    this.cookieService.set('searchFilter', JSON.stringify(this.filter));
    localStorage.setItem('searchFilters', JSON.stringify(this.filter));
    this.clearEventEmit();
    this.patchDatesToSwap(date, true);
  }

  patchDatesToSwap(date, me: boolean) {
    let tempDate = {
      ended_at: date.end,
      started_at: date.start,
      totalBookingDays: date.days,
    };
    this.swapService.saveBookingDates(tempDate);

    if (me) {
      this.swap.your_swap.ended_at = date.end;
      this.swap.your_swap.started_at = date.start;
      this.totalBookingDays = date.days;
    } else {
      this.swap.their_swap.ended_at = date.end;
      this.swap.their_swap.started_at = date.start;
    }
    this.swapService.swap = this.swap;
  }
  imFlex() {
    this.patchMonths();
    if (!this.setFlexible) {
      this.selectedDays = 2;
      this.searchDate = '';
    }
    this.showCalender = false;
    this.showFlexiablePlan = true;
    localStorage.setItem('flexibleSelect', 'true');
    this.filter.started_at = '';
    this.filter.ended_at = '';
    this.flexibleDates.update(this.selectedDays, this.selectedMonths);
  }

  patchMonths() {
    let selected_days = '';
    let selected_months = '';

    if (
      this.selectedDays?.length <= 0 ||
      typeof this.selectedDays?.length == undefined
    ) {
      this.selectedDays = 2;
    }

    if (this.selectedDays == 7) {
      selected_days = '1 week';
    } else if (this.selectedDays == 14) {
      selected_days = '2 weeks';
    } else {
      selected_days = this.selectedDays + ' days';
    }

    if (this.selectedMonths?.length > 0) {
      this.selectedMonths.map((month) => {
        const [mon, year] = month.split('-');
        let new_month = moment(new Date(+year, +mon - 1)).format('MMM-YY');
        if (selected_months != '') {
          selected_months = selected_months + ', ' + new_month;
        } else {
          selected_months = selected_months + ' ' + new_month;
        }
      });
    }
    let flexible_txt = '';
    if (
      (this.selectedDays?.length > 0 ||
        typeof this.selectedDays?.length != undefined) &&
      this.selectedMonths?.length > 0
    ) {
      flexible_txt = selected_days + ',' + selected_months;
    } else if (this.selectedMonths?.length <= 0) {
      flexible_txt = selected_days;
    } else {
      flexible_txt = selected_months;
    }

    if (localStorage.getItem('flexibleSelect') == 'true') {
      this.searchDate = flexible_txt;
      this.setFlexible = true;
    }
  }

  updateGuests(type) {
    if (type == 'add' && this.guestNumber < 20) {
      this.guestNumber++;
    }
    if (type == 'less' && this.guestNumber > 0) {
      this.guestNumber--;
    }
    this.filter.guestNumber = this.guestNumber;
  }

  onFlexibleSelect(event: any) {
    let selected_days = '';
    let selected_months = '';

    this.filter.days = event.days;
    this.filter.months = event.months;
    if (event.days == 7) {
      selected_days = '1 week';
    } else if (event.days == 14) {
      selected_days = '2 weeks';
    } else {
      selected_days = event.days + ' days';
    }

    if (event.months.length > 0) {
      event.months.map((month) => {
        const [mon, year] = month.split('-');
        let new_month = moment(new Date(+year, +mon - 1)).format('MMM-YY');
        if (selected_months != '') {
          selected_months = selected_months + ', ' + new_month;
        } else {
          selected_months = selected_months + ' ' + new_month;
        }
      });
    }
    let flexible_txt = '';
    if (event.days) {
      if (selected_months) {
        flexible_txt = selected_days + ',' + selected_months;
      } else {
        flexible_txt = selected_days;
      }
    } else {
      flexible_txt = selected_months;
    }
    this.searchDate = flexible_txt;
    this.setFlexible = true;
  }

  clearEventEmit() {
    if (localStorage.getItem('searchFilters')) {
      this.filter = JSON.parse(localStorage.getItem('searchFilters'));
    }

    if (this.filter?.days) {
      this.selectedDays = this.filter?.days;
    }
    if (this.filter?.months) {
      this.selectedMonths = this.filter?.months;
    }
    this.flexibleDates.update(this.selectedDays, this.selectedMonths);
    this.setFlexible = false;
    this.patchMonths();
  }

  closeFlex() {
    this.showFlexiablePlan = false;
    this.showCalender = true;
  }

  handleBackByClosing() {
    if (this.hasHistory) {
      this.locationPath.back();
    } else {
      this.router.navigateByUrl('/');
    }
  }

  AddressChange(address: any) {
    // this.formattedaddress = address.formatted_address
    let address_location = new google.maps.LatLngAltitude({
      lat: address.geometry.location.lat(),
      lng: address.geometry.location.lng(),
    });
    // this.addMarker(address_location, this.map);
  }

  calcTotalGuests(guests: Record<string, number>) {
    const total = Object.values(guests).reduce((sum, currentValue) => {
      return (sum += currentValue);
    }, 0);
    this.guestNumber = total;
    this.filter = {
      ...this.filter,
      guestNumber: total,
    };
    this.cookieService.set('guestFilter', JSON.stringify(guests));
  }

  handleChangeGuests(field: string, changes: number) {
    if ((changes > 0 && this.guests[field] < 20) || changes < 0) {
      this.guests = {
        ...this.guests,
        [field]: Math.max(this.guests[field] + changes, 0),
      };
      this.calcTotalGuests(this.guests);
    }
  }

  ngOnDestroy() {
    $('.app-navigation').removeClass('hide');
  }

  protected readonly focus = focus;
}
