import { ClevertapService } from 'src/app/shared/services/clevertap.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { environment } from 'src/environments/environment';
import { Input, OnChanges } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { TokenService } from 'src/app/shared/services/token.service';

@Component({
  selector: 'app-twitter-share',
  templateUrl: './twitter-share.component.html',
  styleUrls: ['./twitter-share.component.scss']
})
export class TwitterShareComponent implements OnInit {
  @Input() homeId: number;
  @Input() shareUrl: string='';
  @Input() imgUrl: string='';
  @Input() webUrl:string='';
  @Input() shareMessage:string;
  @Input() deeplinkUrl:string='';
  @Input() float: boolean=true;
  @Input() congratsShare : boolean = false;
  @Input() ImgUrl ='/assets/images/send_ic.svg';
  @Input() spaceImg="";
  @Input() pennyCode="";

  public baseUrl = environment.base;
  shareModalStatus = false;
  customUrl: string = "https://holidayswap.com";
  public selectedLanguage = "en";

  constructor(
    private languageService: LanguageService,
    public cleverTabService: ClevertapService,
    public tokenService:TokenService,
    ) { }

  ngOnInit(): void {

    // if (this.homeId) this.customUrl = this.deeplinkUrl;
    // if (this.shareUrl) this.customUrl = this.baseUrl + "/" + this.shareUrl;

    this.shareMessage = `I found #PennyThePig and I'm in to win $500 from @HolidaySwapApp! 🏆🐷 Follow the clues and find out where in the world Penny is hiding! #Giveaway #CashPrize #${this.pennyCode}`;
    if(this.webUrl) this.customUrl = this.webUrl;


    let loadLang = this.languageService.GetLanguage() + '';

    if (loadLang == "null" || loadLang == "") {
      this.selectedLanguage = "en";
    }
    else {
      this.selectedLanguage = loadLang;
    }

    this.languageService.languageUpdates.subscribe(
      (lang) => {
        this.selectedLanguage = lang;
      }
    )
  }

  public socialShareModal(){
      // if ((window as any).nsWebViewBridge) {
      //     (window as any).nsWebViewBridge.emit("socialShareModal", true);
      // }


      // alert(this.shareMessage)
      // if(this.cleverTabService.isWebView) {
        const args = [{message:this.shareMessage, url: this.customUrl, deeplink:this.customUrl, imgurl:this.customUrl, socialType:'system'}];

        if(window.flutter_inappwebview) {
          window.flutter_inappwebview.callHandler('shareMedia', ...args).then((val) => {

          });
          this.shareCalled();
        }else {

        }
      // }else{
      //   this.shareModalStatus = !this.shareModalStatus;
      // }

	}
  public shareCalled(){
    // this.tokenService.shareTokens("Bonus", 32, this.homeId, 10, "Bonus on social share").subscribe(
		// 	(res) => {

		// 	}
		// )
  }

  ngOnChanges(): void {

    // if (this.homeId) this.customUrl = this.deeplinkUrl;
    // if (this.shareUrl) this.customUrl = this.baseUrl + "/" + this.shareUrl;


  }


}
