<div class="autocomplete {{ search ? 'search' : '' }}">
	<div (click)="show()" class="input select {{_open ? 'focus' : ''}} ">
		<ng-container *ngIf="selected !== undefined">
			<ng-container *ngIf="multiple">
				<ul class="multiple-items">
					<li *ngFor="let item of _selectedItems; let i = index" (click)="onRemoveItem(i)">
						<ng-container *ngIf="key">
							{{item[key]}}
						</ng-container>

						<ng-container *ngIf="!key">
							{{item}}
						</ng-container>
					</li>
				</ul>
			</ng-container>

			<ng-container *ngIf="!multiple && _selectedItems.length === 1">

				<ng-container [ngTemplateOutlet]="template ? template : basic"
					[ngTemplateOutletContext]="{item:_selectedItems[0]}">
				</ng-container>

				<ng-template #basic>
					<ng-container *ngIf="key">
						{{_selectedItems[0][key]}}
					</ng-container>

					<ng-container *ngIf="!key">
						{{_selectedItems[0]}}
					</ng-container>
				</ng-template>

			</ng-container>

		</ng-container>


		<ng-container *ngIf="!_selectedItems || _selectedItems.length === 0">
			<span class="placeholder" style="opacity: 0.4;">{{placeholder}}</span>
		</ng-container>

		<input style="width: 0; height: 0; opacity: 0; position: absolute;" [required]="required"
			[(ngModel)]="_selectedItems" />

	</div>


	<div class="results {{_open ? 'visible' : '' }}">
		<input id="searchField" *ngIf="search" #searchField placeholder="Search" class="input "
			[ngModelOptions]="{standalone: true}" [(ngModel)]="_search " autocomplete="off"
			(ngModelChange)="onSearch($event)" />
		<button *ngIf="_selectedItems?.length > 0" (click)="onClear()" class="button default close-item"><span
				uk-icon="close"></span></button>

		<div class="items">

			<ng-container *ngFor="let item of items | filter: _search : key ">
				<div class="item " *ngIf="multiple ? !_selectedItems.includes(item) : true" (click)="onSelect(item)">

					<ng-container [ngTemplateOutlet]="template ? template : basic"
						[ngTemplateOutletContext]="{item:item}">
					</ng-container>

					<ng-template #basic>
						<ng-container *ngIf="key">
							{{item[key]}}
						</ng-container>

						<ng-container *ngIf="!key">
							{{item}}
						</ng-container>
					</ng-template>
				</div>
			</ng-container>

		</div>

	</div>