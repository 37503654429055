import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-drop-panel',
  templateUrl: './drop-panel.component.html',
  styleUrls: ['./drop-panel.component.scss'],
})
export class DropPanelComponent {
  @ViewChild('scrollTarget') scrollTarget: ElementRef;

  @ViewChild(TemplateRef) templateRef: TemplateRef<any>;

  @Output() closed = new EventEmitter<void>();

  @Output() doneClick = new EventEmitter<void>();

  @Input() public close = true;

  @Input() public open = false;

  @Input() className: string = '';

  @Input() closable = true;

  constructor() {
  }

  public _close() {
    this.closed.emit();
    this.doneClick.emit();
  }

  ngAfterViewInit() {
    this.open = true;
  }

  ngOnDestroy() {
    this.open = false;
  }
}
