<div class="ModalContainer" *ngIf="show" [class.top-60px]="this.isLogged">
  <div class="ModalWrapper">
    <div class="Modal">
      <img
        class="CloseIcon"
        src="/assets/images/Close.svg"
        (click)="close()"
        alt="close icon"
      />

      <div class="ModalPig" *ngIf="step === 'dailyRewardStep'">
        <img class="PennyPig" [src]="videos[currentStreak - 1]" />

        <p class="DailyRewardsHeading"><span>Daily login</span> rewards!</p>
        <p class="DailyRewardsDesc">
          <span>Sign in/sign up to win</span> more points daily and earn the
          weekly prize!
        </p>
        <div class="DailyRewardsContainer">
          <div
            *ngFor="let item of dailyRewardsItems; let idx = index"
            class="DailyRewardsItem"
          >
            <img
              alt="Daily Coin"
              src="{{
                currentStreak < idx + 1
                  ? '/assets/images/DailyCoin2.svg'
                  : '/assets/images/SmallPig.png'
              }}"
            />
            <div *ngIf="currentStreak < idx + 1">Day {{ idx + 1 }}</div>
          </div>
        </div>
        <button
          type="button"
          (click)="claimTokens()"
          class="primaryBtn mb-5 signinBtn"
        >
          Claim HS Points
        </button>
        <p (click)="close()" class="DailyRewardsClose">Maybe later</p>
      </div>

      <div class="ModalAuth" *ngIf="step === 'authStep'">
        <p class="ModalHeading HeadingAuth">
          Sign up or sign in to <br />claim your HS Points!
        </p>
        <form class="signin-form" [formGroup]="authForm">
          <div class="form-field">
            <input
              type="email"
              formControlName="username"
              placeholder="{{ 'Enter your email' | translate }}"
              required
              maxlength="255"
            />
            <p
              class="input-error"
              *ngIf="authForm.controls?.['username']?.touched && authForm.controls?.['username']?.errors?.['required']"
            >
              {{ "Email is required" | translate }}
            </p>
            <p
              class="input-error"
              *ngIf="authForm.controls?.['username']?.touched && authForm.controls?.['username']?.errors?.['email']"
            >
              {{ "Invalid email address" | translate }}
            </p>
          </div>

          <div class="form-field position-relative">
            <input
              [type]="showPassword ? 'text' : 'password'"
              formControlName="password"
              placeholder="{{ 'Password' | translate }}"
              required
              maxlength="255"
              class="password-input"
            />
            <span class="show-password" (click)="toggleShowPassword()">
              <i
                class="fa fa-eye-slash"
                aria-hidden="true"
                *ngIf="!showPassword"
              ></i>
              <i class="fa fa-eye" aria-hidden="true" *ngIf="showPassword"></i>
            </span>
            <p
              class="input-error"
              *ngIf="authForm.controls?.['password']?.touched && authForm.controls?.['password']?.errors?.['required']"
            >
              {{ "Password is required" | translate }}
            </p>
          </div>

          <button
            [disabled]="isSubmitting"
            type="submit"
            class="primary-button"
            (mousedown)="continueHandler($event)"
          >
            <i *ngIf="isSubmitting" class="fa fa-spinner fa-spin"></i>
            {{ "Continue" | translate }}
          </button>

          <div class="forgot-password">
            <a class="forgot-link" routerLink="/auth/reset">
              {{ "Forgot my password" | translate }}
            </a>
          </div>

          <p class="divider-text">{{ "or continue with" | translate }}</p>

          <app-social-sign-in-popup
            (onClose)="show = false"
            [hiddenApple]="true"
          ></app-social-sign-in-popup>

          <div class="form-check checkbox-wrapper">
            <label class="form-check-label agree_text">
              By signing in or creating an account, you agree with our
              <a
                class="terms-url"
                href="https://www.holidayswap.com/legal/terms-of-service"
                target="_blank"
              >
                {{ "Terms of Service" | translate }}
              </a>
            </label>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
