import { Injectable, Inject } from '@angular/core';
@Injectable({
    providedIn: 'root'
})
export class VerifyEmailTimerService {
    setTimeout = undefined



    clearSetTimeout = () => {
        if (this.setTimeout !== undefined) {
            clearInterval(this.setTimeout)
        }
    }
}