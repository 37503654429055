import { Injectable, TemplateRef, ViewChild } from "@angular/core";
import { map } from "rxjs/operators";
import { HttpService } from "src/app/shared/services/http.service";
import { getMessaging, getToken, onMessage, Messaging, isSupported } from "firebase/messaging";
import { initializeApp } from "@firebase/app";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { BehaviorSubject, Subject } from "rxjs";
import { getAnalytics } from "firebase/analytics";

@Injectable({
	providedIn: "root"
})
export class MessagingService {
	public messaging: Messaging;
	public token: String;
	public notifcationUpdate = new Subject<any>();

	constructor(private httpService: HttpService, private router: Router) {
		// this.askPermission();

		window.addEventListener("ns-bridge-ready", (e) => {
			if ((window as any).nsWebViewBridge) {
				(window as any).nsWebViewBridge.on("push-notification", (data) => {
					this._onMessage(data);
				});
			}
		});
	}

	public async askPermission() {

		try {
			const app = initializeApp({
				apiKey: "AIzaSyCPrQrG53Vv53lWwVnnSHQGPc4Q9bXu100",
				authDomain: "holidayswap-85aa1.firebaseapp.com",
				databaseURL: "https://holidayswap-85aa1.firebaseio.com",
				projectId: "holidayswap-85aa1",
				storageBucket: "holidayswap-85aa1.appspot.com",
				messagingSenderId: "87485270973",
				appId: "1:87485270973:web:51701b13e1a618acbca0d4",
				measurementId: "G-4MEMGGPVVX"
			});

			if (!isSupported()) {


				return;
			}

			this.messaging = getMessaging(app);

			const token = await getToken(this.messaging, {
				vapidKey: "BFLTQyuyGcWd54lR4k40_p4eHqWHrzrlaisYKJ82M_g5czWKBEMrJf1I5VZzzgzzZ5ng0WP1kcpba6CaOTe0DL8",
			});


			if (!token) {


				return;
			}

			this.token = token;
			this.saveToken();

			onMessage(this.messaging, this._onMessage.bind(this));
		} catch (error) {

		}

	}

	private _onMessage(payload) {

		this.notifcationUpdate.next(payload);
	}

	public updateBadgeStatus(payload){

		this.notifcationUpdate.next(payload);
	}

	public getMyToken() {
		return this.token;
	}

	private saveToken() {
		this.httpService.post(`/users/me/fcm-token`, { "token": this.getMyToken() }).subscribe((response: any) => {

		});
	}

	private getFirebaseAnalytics(){
		const analytics = getAnalytics();
	}
}
