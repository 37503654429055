<div
  class="phone-input"
  [ngClass]="{ 'phone-no-dirOverride': selectedLanguage == 'ar' }"
>
  <div class="country">
    <div
      class="action"
      [ngClass]="{ 'dialcode-dirOverride': selectedLanguage == 'ar' }"
      [dropdownTriggerFor]="selectCountry"
    >
      <span class="flagBox mb-3px {{ country.code.toLowerCase() }}"></span>
      <span class="country_code">{{ country.code }}</span
      >{{ country.dial_code }}
    </div>
  </div>
  <div class="phone-number" [formGroup]="form">
    <!-- <input #input required class="input" [formControl]="phoneNumber" (ngModelChange)="onEmit($event)" type="tel"
		placeholder="{{ placeholder }}" [mask]="mask" [validation]="true"> -->
    <input
      type="text"
      class="input"
      (keypress)="numberOnly($event)"
      (focus)="verifyMobile()"
      min="9"
      max="15"
      formControlName="phoneNumber"
      (change)="onEmit($event)"
    />
  </div>
</div>
<div class="error_text">
  <small
    class="form-text text-danger"
    *ngIf="
      form.controls.phoneNumber?.touched &&
      form.controls.phoneNumber?.hasError('required')
    "
    >{{ "Phone number is required" | translate }}<br
  /></small>
  <small class="form-text text-danger" *ngIf="form.controls.phoneNumber?.errors"
    >{{ form.controls.phoneNumber.errors?.phoneNumber?.message | translate
    }}<br
  /></small>
</div>
<app-drop-panel [close]="false" #selectCountry>
  <div class="dialog">
    <div class="search">
      <input
        class="input"
        autocomplete="new-password"
        autocapitalize="off"
        placeholder="Country name"
        [(ngModel)]="search"
        type="search"
      />
    </div>

    <div class="list">
      <ng-container
        *ngFor="let country of countries | filter : { name: search }"
      >
        <a (click)="onSelect(country)">
          <span class="flagBox {{ country.code.toLowerCase() }}"></span>
          <span class="country_code"
            >{{ country.name }} {{ country.dial_code }}</span
          >
        </a>
        <div class="divider no-margin"></div>
      </ng-container>
    </div>
  </div>
</app-drop-panel>

<!--pre>{{ countries | json }}</pre-->
