<div [ngStyle]="{  'width.px': width, 'height.px': height}" class="user-avatar">


	<ng-container *ngIf="picture">
		<img *ngIf="picture != '/assets/images/placeholder-img.jpg'" [ngStyle]="{  'border-radius': borderRadius }" class="lazyload"
			[ngClass]="{'cursor-pointer': clickable}"
			[defaultImage]="'/assets/images/default-profile.svg'" [height]="height" [width]="width"
			[lazyLoad]="picture + '?' + timeStamp"
			/>

			<img  *ngIf="picture == '/assets/images/placeholder-img.jpg'" [ngStyle]="{  'border-radius': borderRadius }"
			[ngClass]="{'cursor-pointer': clickable}"
			[defaultImage]="'/assets/images/default-profile.svg'" [height]="height" [width]="width"
			src="/assets/images/default-avatar.svg" />

		<span *ngIf="verified" class="gg-check-o"></span>
	</ng-container>
	<ng-container *ngIf="!picture && name">
		<div [ngStyle]="{ 'width.px': width, 'height.px': height, 'border-radius': borderRadius }" class="box">
			{{ name[0] }}
		</div>
		<span *ngIf="verified" class="gg-check-o"></span>
	</ng-container>
</div>