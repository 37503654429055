<div
  *ngIf="checkedEmail"
  class="add_home_form_wrapper"
  [class.onSuccess]="activeStep > 10"
>
  <div class="progress_bar_wrapper" *ngIf="activeStep <= 10">
    <p>{{ "Step" | translate }} {{ stepNumber }} {{ "of" | translate }} 6</p>
    <div class="progress_bar">
      <span [ngStyle]="{ width: progressWidth }"></span>
    </div>
  </div>

  <div class="add_form_body" id="add-body">
    <div class="form_detail_inner image_upload_wrapper" *ngIf="activeStep == 1">
      <h4 class="step_title">{{ "List your home" | translate }}</h4>
      <p class="step_detail" *ngIf="outsideViewImage.image_url">
        These are looking great! We’ve placed you’re best photos to be first,
        but feel free to drag them around to your liking!
      </p>

      <div class="image_form">
        <div
          class="image_box home_view"
          (click)="openImgPopup(0, 555, outsideViewImage.image_url)"
        >
          <img
            [src]="
              outsideViewImage.image_url
                ? outsideViewImage.image_url
                : placeholderImg
            "
            alt="place holder image"
          />
          <span *ngIf="!outsideViewImage.image_url"
            >Tap here to upload your images</span
          >
          <div *ngIf="outsideViewImage.image_url" class="floating-edit">
            <img src="../../../../assets/images/edit.svg" alt="" />
          </div>
        </div>
        <div
          class="image_box living_room"
          (click)="openImgPopup(1, 544, livingRoomImages.image_url)"
        >
          <img
            [src]="
              livingRoomImages.image_url
                ? livingRoomImages.image_url
                : placeholderImg
            "
            alt="place holder image"
          />
          <div *ngIf="livingRoomImages.image_url" class="floating-edit">
            <img src="../../../../assets/images/edit.svg" alt="" />
          </div>
        </div>
        <div
          class="image_box beadroom"
          (click)="openImgPopup(2, 541, bedRoomImages.image_url)"
        >
          <img
            [src]="
              bedRoomImages.image_url ? bedRoomImages.image_url : placeholderImg
            "
            alt="place holder image"
          />
          <div *ngIf="bedRoomImages.image_url" class="floating-edit">
            <img src="../../../../assets/images/edit.svg" alt="" />
          </div>
        </div>
        <div
          class="image_box kitchen"
          (click)="openImgPopup(3, 552, kitchenImages.image_url)"
        >
          <img
            [src]="
              kitchenImages.image_url ? kitchenImages.image_url : placeholderImg
            "
            alt="place holder image"
          />
          <div *ngIf="kitchenImages.image_url" class="floating-edit">
            <img src="../../../../assets/images/edit.svg" alt="" />
          </div>
        </div>
        <div
          class="image_box bathroom"
          (click)="openImgPopup(4, 542, bathRoomImages.image_url)"
        >
          <img
            [src]="
              bathRoomImages.image_url
                ? bathRoomImages.image_url
                : placeholderImg
            "
            alt="place holder image"
          />
          <div *ngIf="bathRoomImages.image_url" class="floating-edit">
            <img src="../../../../assets/images/edit.svg" alt="" />
          </div>
        </div>
        <div
          class="image_box guest_room"
          (click)="openImgPopup(5, 551, guestRoomImages.image_url)"
        >
          <img
            [src]="
              guestRoomImages.image_url
                ? guestRoomImages.image_url
                : placeholderImg
            "
            alt="place holder image"
          />
          <div *ngIf="guestRoomImages.image_url" class="floating-edit">
            <img src="../../../../assets/images/edit.svg" alt="" />
          </div>
        </div>
      </div>
    </div>

    <!-- travel oprions -->
    <div
      class="form_detail_inner travel_option_wrapper"
      *ngIf="activeStep == 2"
    >
      <div>
        <div class="option_radioList_outer">
          <div class="availability_header mb-30">
            <div>
              <h4 class="step_title paytype_title mt-40">
                What would you like the nightly rate for your listing to be?
              </h4>
              <h4 class="step_sub_title">
                {{ "Please enter the rate or use the slider:" | translate }}
              </h4>
              <p class="recommend-amount">
                Recommended amount - <span>$110 per night</span>
              </p>
            </div>
            <div
              class="slider_area"
              [class.disabled_section]="
                space.payment_type_id == 561 || space.purpose_id == 521
              "
            >
              <div
                class="range__container input-field-div"
                [ngClass]="{
                  'input-token_ar-override': selectedLanguage == 'ar'
                }"
              >
                <input
                  type="number"
                  class="token-manual-input-field"
                  placeholder="000"
                  [(ngModel)]="space.hosting_tokens"
                  (input)="
                    sliderTokenUpdate(
                      tokenSliderInput,
                      space.hosting_tokens,
                      'token'
                    )
                  "
                  (change)="handleInputValidation(space, 'hosting_tokens', 500)"
                  (keydown)="checkRegEx($event)"
                />
                <span class="token-price-border">{{
                  space.hosting_tokens == 1
                    ? "HS Point"
                    : ("HS Points" | translate)
                }}</span>
              </div>
              <div class="range-token-slider">
                <input
                  class="range-slider__range"
                  [style.background]="slideBg"
                  type="range"
                  min="1"
                  max="500"
                  #tokenSliderInput
                  [(ngModel)]="space.hosting_tokens"
                  (input)="
                    sliderTokenUpdate(
                      $event.target,
                      space.hosting_tokens,
                      'token'
                    )
                  "
                  id="token-slider-input"
                />
                <span class="range-slider__value">{{
                  space.hosting_tokens
                }}</span>
              </div>
            </div>

            <div
              class="slider_area"
              [class.disabled_section]="
                space.payment_type_id == 562 || space.purpose_id == 521
              "
            >
              <div
                class="range__container input-field-div"
                [ngClass]="{
                  'input-token_ar-override': selectedLanguage == 'ar'
                }"
              >
                <input
                  type="number"
                  class="token-manual-input-field"
                  placeholder="000"
                  min="1"
                  max="5000"
                  [(ngModel)]="space.hosting_amount"
                  (input)="
                    sliderTokenUpdate(
                      amountSliderInput,
                      space.hosting_amount,
                      'amount'
                    )
                  "
                  (change)="
                    handleInputValidation(space, 'hosting_amount', 5000)
                  "
                  (keydown)="checkRegEx($event)"
                />
                <span class="token-price-border"
                  >{{
                    space.hosting_amount == 1
                      ? "Dollar"
                      : ("Dollars" | translate)
                  }}
                  {{ "(USD)" | translate }}</span
                >
              </div>
              <div class="range-token-slider range-amount">
                <input
                  class="range-slider__range"
                  [style.background]="amountSliderBg"
                  type="range"
                  min="1"
                  max="5000"
                  #amountSliderInput
                  [(ngModel)]="space.hosting_amount"
                  (input)="
                    sliderTokenUpdate(
                      $event.target,
                      space.hosting_amount,
                      'amount'
                    )
                  "
                  id="amount-slider-input"
                />
                <span class="range-slider__value">{{
                  space.hosting_amount
                }}</span>
              </div>
            </div>
            <div>
              <p class="sub">
                {{
                  "At Holiday Swap, we believe in full transparency" | translate
                }}
                <b>{{ "(no hidden fees!)" | translate }}</b>
                {{
                  "please remember to factor in cleaning and other costs into your total."
                    | translate
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- select address in map -->
    <div
      class="form_detail_inner map_select_wrapper"
      [hidden]="activeStep != 3"
    >
      <h4 class="step_title">
        {{ "Where is your home located?" | translate }}
      </h4>
      <div class="searchInput_box">
        <input
          ngx-google-places-autocomplete
          class="form-control input_field"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="location.full_address"
          (onAddressChange)="autoCompleteAddress($event)"
          (ngModelChange)="addressChange($event)"
          placeholder="{{ 'Enter your home address' | translate }}"
          [options]="inputoptions"
        />
        <div
          class="results"
          [class.visible]="locations.length > 0"
          [hidden]="resultHide"
        >
          <ul class="mb-0">
            <li *ngFor="let location of locations" (click)="onSelect(location)">
              {{ location.full_address }}
            </li>
          </ul>
        </div>
      </div>
      <div class="mapOuter">
        <app-add-home-google-maps #addHomeGooglemap></app-add-home-google-maps>
      </div>
    </div>

    <div
      class="form_detail_inner address_confirm_wrapper"
      [hidden]="activeStep != 4"
    >
      <h4 class="step_title">
        {{ "Please confirm that we’ve got your address right!" | translate }}
      </h4>
      <div class="addres_form_box">
        <form [formGroup]="addressForm" autocomplete="off">
          <div
            class="from-group mb-20"
            [class.dirty]="
              addressForm.get('street_1').invalid &&
              (addressForm.get('street_1').dirty ||
                addressForm.get('street_1').touched)
            "
          >
            <label>{{ "Street*" | translate }}</label>
            <input
              type="text"
              autocomplete="false"
              formControlName="street_1"
              class="form-control"
            />
            <ng-container
              *ngIf="
                addressForm.get('street_1').invalid &&
                (addressForm.get('street_1').dirty ||
                  addressForm.get('street_1').touched)
              "
            >
              <span
                class="error_txt"
                *ngIf="addressForm.get('street_1').hasError('required')"
                >{{
                  "Street
                                required" | translate
                }}</span
              >
              <span
                class="error_txt"
                *ngIf="
                  addressForm.get('street_1').dirty &&
                  !addressForm.get('street_1').hasError('required')
                "
                >{{ "Invalid Address" | translate }}</span
              >
            </ng-container>
          </div>

          <div class="from-group mb-20">
            <input
              type="text"
              autocomplete="false"
              formControlName="street_2"
              class="form-control"
            />
            <ng-container
              *ngIf="
                addressForm.get('street_2').invalid &&
                (addressForm.get('street_2').dirty ||
                  addressForm.get('street_2').touched)
              "
            >
              <span
                class="error_txt"
                *ngIf="addressForm.get('street_2').dirty"
                >{{ "Invalid Address" | translate }}</span
              >
            </ng-container>
          </div>

          <div
            class="from-group mb-20"
            [class.dirty]="
              addressForm.get('city').invalid &&
              (addressForm.get('city').dirty || addressForm.get('city').touched)
            "
          >
            <label>{{ "City*" | translate }}</label>
            <input
              type="text"
              autocomplete="false"
              formControlName="city"
              [readonly]="location.city"
              required="location.city"
              class="form-control"
            />
            <ng-container
              *ngIf="
                addressForm.get('city').invalid &&
                (addressForm.get('city').dirty ||
                  addressForm.get('city').touched)
              "
            >
              <span
                class="error_txt"
                *ngIf="addressForm.get('city').hasError('required')"
                >{{
                  "City
                                required" | translate
                }}</span
              >
              <span
                class="error_txt"
                *ngIf="
                  addressForm.get('city').dirty &&
                  !addressForm.get('city').hasError('required')
                "
                >{{ "Invalid City" | translate }}</span
              >
            </ng-container>
          </div>

          <div class="row mb-20">
            <div class="col">
              <div
                class="from-group"
                [class.dirty]="
                  addressForm.get('state').invalid &&
                  (addressForm.get('state').dirty ||
                    addressForm.get('state').touched)
                "
              >
                <label>{{ "State/Prov*" | translate }}</label>
                <input
                  type="text"
                  autocomplete="false"
                  formControlName="state"
                  [readonly]="location.state"
                  required="location.state"
                  class="form-control"
                />
                <ng-container
                  *ngIf="
                    addressForm.get('state').invalid &&
                    (addressForm.get('state').dirty ||
                      addressForm.get('state').touched)
                  "
                >
                  <span
                    class="error_txt"
                    *ngIf="addressForm.get('state').hasError('required')"
                    >{{
                      "State
                                        required" | translate
                    }}</span
                  >
                  <span
                    class="error_txt"
                    *ngIf="
                      addressForm.get('state').dirty &&
                      !addressForm.get('state').hasError('required')
                    "
                    >{{ "Invalid State" | translate }}</span
                  >
                </ng-container>
              </div>
            </div>

            <div class="col-auto small_input">
              <div
                class="from-group"
                [class.dirty]="
                  addressForm.get('postcode_1').invalid &&
                  (addressForm.get('postcode_1').dirty ||
                    addressForm.get('postcode_1').touched)
                "
              >
                <label>{{ "Post Code*" | translate }}</label>
                <input
                  type="text"
                  autocomplete="false"
                  formControlName="postcode_1"
                  class="form-control"
                />
                <ng-container
                  *ngIf="
                    addressForm.get('postcode_1').invalid &&
                    (addressForm.get('postcode_1').dirty ||
                      addressForm.get('postcode_1').touched)
                  "
                >
                  <span
                    class="error_txt"
                    *ngIf="addressForm.get('postcode_1').hasError('required')"
                    >{{ "Post Code required" | translate }}</span
                  >
                  <span
                    class="error_txt"
                    *ngIf="addressForm.get('postcode_1').hasError('numeric')"
                    >{{ "Only number allowed" | translate }}</span
                  >
                </ng-container>
              </div>
            </div>
          </div>

          <!-- <div class="from-group mb-20"
                        [class.dirty]="addressForm.get('country').invalid && (addressForm.get('country').dirty || addressForm.get('country').touched)">
                        <label>{{"Country/Region*" | translate}}</label>
                        <input type="text" autocomplete="false" formControlName="country"
                            [readonly]="location.country" required="location.country" class="form-control">
                        <ng-container
                            *ngIf="addressForm.get('country').invalid && (addressForm.get('country').dirty || addressForm.get('country').touched)">
                            <span class="error_txt"
                                *ngIf="addressForm.get('country').hasError('required')">{{'Country
                                required'|translate}}</span>
                        </ng-container>
                    </div> -->

          <!-- New design  -->
          <div class="row mb-20">
            <div class="col from-group">
              <div class="form-field">
                <label>{{ "Country/Region*" | translate }}</label>
                <app-google-country-dropdown
                  [list]="countries"
                  #countriesRef
                  [search]="true"
                  [outsideClick]="outsideClick"
                  [placeholder]="
                    selectedCountry || 'Select country' | translate
                  "
                  [selected]="selectedCountry"
                  (onSelect)="onSelectCountry($event)"
                >
                </app-google-country-dropdown>
                <p
                  class="error_txt margin-text"
                  *ngIf="
                    addressForm.get('country').invalid &&
                    (addressForm.get('country').touched ||
                      addressForm.get('country').dirty)
                  "
                >
                  {{ "Please select a country" | translate }}
                </p>

                <!-- <p class="error_txt"
                                    *ngIf="addressForm.controls['country'].invalid && addressForm.controls['country'].touched">
                                    {{"Please select any country" | translate}}
                                </p> -->

                <ng-container *ngFor="let item of serverErrors; let i = index">
                  <p
                    class="error_txt"
                    *ngIf="
                      item?.path == 'country' || item?.path == 'countryCode'
                    "
                  >
                    {{ item?.message }}
                  </p>
                </ng-container>

                <!-- <p class="error_txt"
                                    *ngIf="addressForm.get('country').hasError('required') && addressForm.get('country').touched">
                                    {{"Please select any country" | translate}}
                                </p>

                                <ng-container *ngFor="let item of serverErrors; let i = index">
                                    <p class="error_txt" *ngIf="item?.path == 'country' || item?.path == 'countryCode'">
                                        {{item?.message}}</p>
                                </ng-container> -->
              </div>
            </div>
          </div>
        </form>

        <div class="location-confirmation">
          <h5>Show your specific location</h5>
          <p>
            Make it clear to guests where your place is located. We’ll only
            share your address after they’ve made a reservation.
          </p>
        </div>

        <div class="submapOuter">
          <app-google-map-view
            [latitude]="location?.latitude"
            [longitude]="location?.longitude"
            [height]="'224px'"
            [zoom]="7"
            #addHomeConfirm
          >
          </app-google-map-view>
        </div>
      </div>
    </div>

    <div
      class="form_detail_inner map_select_wrapper hidden_scroll"
      *ngIf="activeStep == 5"
    >
      <h4 class="step_title">
        {{ "Is the pin in the right spot?" | translate }}
      </h4>
      <p class="step-sub--title">
        Your address is only shared with guests only after they have made a
        reservation.
      </p>
      <!-- <div class="searchInput_box">
                    <input ngx-google-places-autocomplete
                    class="form-control input_field"
                    [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="location.full_address"
                    (onAddressChange)="autoCompleteAddress($event)"
                    (ngModelChange) = "addressChange($event)"
                    placeholder="{{'Enter your home address' | translate}}"
                    [options]="inputoptions"
                    /> -->
      <!-- <div class="results" [class.visible]="locations.length>0" [hidden]="resultHide">
                    <ul class="mb-0">
                        <li *ngFor="let location of locations" (click)="onSelect(location)">
                            {{location.full_address}}
                        </li>
                    </ul>
                </div> -->
      <!-- </div> -->
      <div class="searchInput_box searchInput_box_PinMap">
        <input
          disabled="disabled"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="location.full_address"
          (onAddressChange)="autoCompleteAddress($event)"
        />
      </div>

      <div class="pin-spot--map">
        <app-custom-address-map
          (passUpdatedLocation)="UpdatedPinAdd($event)"
          [markerUpdated]="markerUpdated"
          [longitude]="this.addressForm.get('longitude').value"
          [latitude]="this.addressForm.get('latitude').value"
          [address]="combainedAddress"
          #pinSpotLocation
        >
        </app-custom-address-map>
      </div>
    </div>

    <!-- Home type and home description with chatGPT -->

    <div
      class="form_details_inner home_type_wrapper"
      [hidden]="activeStep != 6"
    >
      <p class="type_title">
        {{ "Great! Now, what type of place will guests have?" | translate }}
      </p>
      <div>
        <label class="type_label" for="title">{{
          "Please select one option:" | translate
        }}</label>
        <div class="type_list">
          <ng-container *ngFor="let type of homeTypes">
            <div class="form-control-radio">
              <input
                type="radio"
                name="hometype"
                [id]="'types' + type.id"
                [value]="type.id"
                [(ngModel)]="place_type"
                (ngModelChange)="placeTypeChange($event)"
                [checked]="place_type == type.id"
              />
              <label [for]="'types' + type.id">
                <span class="name">{{ type.name }}</span>
                <span class="desc">{{ type.desc }}</span>
              </label>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div
      class="form_details_inner address_confirm_wrapper home_type_wrapper"
      [hidden]="activeStep != 7"
    >
      <p class="type_title">
        {{
          "Please give us a bit of detail to describe your listing." | translate
        }}
      </p>
      <form [formGroup]="hometypeForm" autocomplete="off">
        <div class="addres_form_box">
          <div class="from-group mb-30">
            <label class="font-600">{{
              "How many beds, bathrooms & guests?" | translate
            }}</label>
            <div class="row">
              <div class="col">
                <input
                  type="number"
                  min="1"
                  max="20"
                  formControlName="bedrooms"
                  class="form-control roomInput"
                  [class.inputError]="
                    hometypeForm.get('bedrooms').invalid &&
                    (hometypeForm.get('bedrooms').dirty ||
                      hometypeForm.get('bedrooms').touched)
                  "
                />
              </div>
              <div class="col">
                <input
                  type="number"
                  min="1"
                  max="20"
                  formControlName="bathrooms"
                  class="form-control bathInput"
                  [class.inputError]="
                    hometypeForm.get('bathrooms').invalid &&
                    (hometypeForm.get('bathrooms').dirty ||
                      hometypeForm.get('bathrooms').touched)
                  "
                />
              </div>
              <div class="col">
                <input
                  type="number"
                  min="1"
                  max="20"
                  formControlName="guests"
                  class="form-control guestInput"
                  [class.inputError]="
                    hometypeForm.get('guests').invalid &&
                    (hometypeForm.get('guests').dirty ||
                      hometypeForm.get('guests').touched)
                  "
                />
              </div>
            </div>
            <ng-container
              *ngIf="
                (hometypeForm.get('bedrooms').invalid &&
                  (hometypeForm.get('bedrooms').dirty ||
                    hometypeForm.get('bedrooms').touched)) ||
                (hometypeForm.get('bathrooms').invalid &&
                  (hometypeForm.get('bathrooms').dirty ||
                    hometypeForm.get('bathrooms').touched)) ||
                (hometypeForm.get('guests').invalid &&
                  (hometypeForm.get('guests').dirty ||
                    hometypeForm.get('guests').touched))
              "
            >
              <span
                class="error_txt"
                *ngIf="
                  hometypeForm.get('bedrooms').hasError('required') ||
                  hometypeForm.get('bathrooms').hasError('required') ||
                  hometypeForm.get('guests').hasError('required') ||
                  hometypeForm.get('bedrooms').hasError('maxNumber') ||
                  hometypeForm.get('bathrooms').hasError('maxNumber') ||
                  hometypeForm.get('guests').hasError('maxNumber') ||
                  hometypeForm.get('bedrooms').hasError('minNumber') ||
                  hometypeForm.get('bathrooms').hasError('minNumber') ||
                  hometypeForm.get('guests').hasError('minNumber') ||
                  hometypeForm.get('guests').hasError('numeric') ||
                  hometypeForm.get('bathrooms').hasError('numeric') ||
                  hometypeForm.get('bedrooms').hasError('numeric')
                "
                >{{
                  "Please
                                add details" | translate
                }}</span
              >
              <!-- <span class="error_txt" *ngIf="(hometypeForm.get('bedrooms').hasError('maxNumber')) || (hometypeForm.get('bathrooms').hasError('maxNumber')) || (hometypeForm.get('guests').hasError('maxNumber'))">{{'Max number allowed is 20.'|translate}}</span> -->
            </ng-container>
          </div>
        </div>
      </form>

      <div class="places_list_wrapper">
        <div class="features_check_outer">
          <form [formGroup]="aminitiesForm">
            <h5 class="featuresTitle">
              {{ "Your place is (pick more than one):" | translate }}
            </h5>
            <ng-container
              *ngFor="
                let place_list of categories.places_or_listing_picks;
                let i = index
              "
            >
              <div class="featureCheckBox">
                <input
                  type="checkbox"
                  [id]="'place_list' + i"
                  formArrayName="places_or_listing_picks"
                  [value]="place_list.id"
                  [checked]="place_list.isActive"
                  (change)="
                    onFeatureCheckboxChange($event, 'places_or_listing_picks')
                  "
                />
                <label [for]="'place_list' + i">{{
                  place_list.name | translate
                }}</label>
              </div>
            </ng-container>
            <ng-container *ngIf="places_listing_error != ''">
              <span class="error_txt">{{
                places_listing_error | translate
              }}</span>
            </ng-container>
          </form>
        </div>
      </div>
    </div>

    <div
      class="form_details_inner home_type_wrapper"
      [hidden]="activeStep != 8"
    >
      <p class="type_title">
        {{ "What’s your neighbourhood like?" | translate }}
      </p>

      <div class="places_list_wrapper">
        <div class="features_check_outer">
          <form [formGroup]="aminitiesForm">
            <h5 class="featuresTitle">
              {{ "Your neighbourhood is (pick more than one):" | translate }}
            </h5>
            <ng-container
              *ngFor="
                let neighbourhood of categories.neighbourhood_picks;
                let i = index
              "
            >
              <div class="featureCheckBox">
                <input
                  type="checkbox"
                  [id]="'neighbourhood' + i"
                  formArrayName="neighbourhood_picks"
                  [value]="neighbourhood.id"
                  [checked]="neighbourhood.isActive"
                  (change)="
                    onFeatureCheckboxChange($event, 'neighbourhood_picks')
                  "
                />
                <label [for]="'neighbourhood' + i">{{
                  neighbourhood.name | translate
                }}</label>
              </div>
            </ng-container>
            <ng-container *ngIf="neighbourhood_error != ''">
              <span class="error_txt">{{
                neighbourhood_error | translate
              }}</span>
            </ng-container>
          </form>
        </div>
      </div>
    </div>

    <div
      class="form_details_inner home_type_wrapper"
      [hidden]="activeStep != 9"
    >
      <p class="type_title">
        {{ "Amazing! How’s this sound for your home listing?" | translate }}
      </p>

      <p class="sub_title">Feel free to edit, of course!</p>

      <form [formGroup]="homeDetailForm" autocomplete="off">
        <div class="addres_form_box">
          <div
            class="from-group mb-30"
            [class.dirty]="
              homeDetailForm.get('title').invalid &&
              (homeDetailForm.get('title').dirty ||
                homeDetailForm.get('title').touched)
            "
          >
            <label>{{ "Home Title*" | translate }}</label>
            <input
              type="text"
              autocomplete="false"
              class="form-control"
              formControlName="title"
            />
            <ng-container
              *ngIf="
                homeDetailForm.get('title').invalid &&
                (homeDetailForm.get('title').dirty ||
                  homeDetailForm.get('title').touched)
              "
            >
              <span
                class="error_txt"
                *ngIf="homeDetailForm.get('title').hasError('required')"
                >{{
                  "Title
                                required" | translate
                }}</span
              >
              <span
                class="error_txt"
                *ngIf="homeDetailForm.get('title').hasError('minLength')"
                >{{
                  "Title
                                should be at least 10 characters." | translate
                }}</span
              >
              <span
                class="error_txt"
                *ngIf="homeDetailForm.get('title').hasError('maxLength')"
                >{{
                  "Title
                                should be at most 500 characters." | translate
                }}</span
              >
            </ng-container>
          </div>
          <div
            class="from-group mb-30"
            [class.dirty]="
              homeDetailForm.get('description').invalid &&
              (homeDetailForm.get('description').dirty ||
                homeDetailForm.get('description').touched)
            "
          >
            <label>{{ "Overview*" | translate }}</label>
            <textarea
              description=""
              autocomplete="false"
              formControlName="description"
              rows="10"
              class="form-control text-area mn-h-350"
              placeholder="{{ 'Tell us a little about your home' | translate }}"
            ></textarea>
            <ng-container
              *ngIf="
                homeDetailForm.get('description').invalid &&
                (homeDetailForm.get('description').dirty ||
                  homeDetailForm.get('description').touched)
              "
            >
              <span
                class="error_txt"
                *ngIf="homeDetailForm.get('description').hasError('required')"
                >{{
                  "Description
                                required" | translate
                }}</span
              >
              <span
                class="error_txt"
                *ngIf="homeDetailForm.get('description').hasError('minLength')"
                >{{
                  "Description should be
                                at least 50 characters." | translate
                }}</span
              >
              <span
                class="error_txt"
                *ngIf="homeDetailForm.get('description').hasError('maxLength')"
                >{{
                  "Description should be
                                at most 5000 characters." | translate
                }}</span
              >
            </ng-container>
          </div>
        </div>
      </form>
      <div class="availability_header mb-30">
        <label>{{ "When will your home be unavailable?" | translate }}</label>
        <p class="sub">
          {{ "Add dates when you are not available" | translate }}
        </p>
        <app-home-unavailablity-date
          [space]="space.id"
          [(dates)]="space.dates"
          (datesChange)="onDatesSelected($event)"
        ></app-home-unavailablity-date>
      </div>
      <div
        class="availability_header"
        *ngIf="space.purpose_id != 521 && space.payment_type_id != 561"
      >
        <div class="toggle_area">
          <label>{{ "Allow instant booking" | translate }}</label>
          <div class="toggle-switch">
            <label class="switch">
              <input
                type="checkbox"
                (click)="setSpaceInstantType()"
                [checked]="space?.is_instant == '1'"
              />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        <p>
          {{
            "Allow your home to be booked instantly with automatic confirmation. Guests love the convenience
                    and on average hosts earn more HS Points from instant bookings."
              | translate
          }}
        </p>
      </div>

      <div *ngIf="description_loading" class="transparent_loader">
        <div class="load-wrapper">
          <div class="lds-ellipsis center">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <p>
            {{
              "We're crafting a captivating title and description just for you. Stay tuned!"
            }}
          </p>
        </div>
      </div>
    </div>

    <!-- <div class="form_detail_inner address_confirm_wrapper" [hidden]="activeStep!=10">
            <h4 class="step_title">{{"Almost done! Please tell us a bit about your home in your own words." |
                translate}}</h4>

        </div> -->
    <!-- phone validation part -->
    <div
      class="form_detail_inner phone_verificatin_wrapper"
      *ngIf="activeStep == 10"
    >
      <h4 class="step_title" *ngIf="!isPhoneVerified">
        {{
          "Please provide us with your Mobile number so that we can confirm your bookings."
            | translate
        }}
      </h4>
      <h4 class="step_title" *ngIf="isPhoneVerified">
        {{ "You are verified." | translate }}
      </h4>
      <div class="phone_verify_outer">
        <ng-container *ngIf="!isPhoneVerified">
          <div class="from-group">
            <label>{{ "Please enter your mobile number*" | translate }}</label>
            <app-phone-number-input
              #input
              [(onChange)]="verification.phone"
              (onChangeInput)="onPhonenumberChange($event)"
            ></app-phone-number-input>
          </div>
          <button
            class="phone-verification-btn popup_btn"
            (click)="otpInputs.clearOTP(); onRequest()"
            [disabled]="!input.valid"
            [class.pe-none]="btn_block || sentTime > 2"
          >
            {{
              (sentTime < 1
                ? "Send
                            confirmation code"
                : "Resend confirmation code"
              ) | translate
            }}
          </button>

          <div class="resend_box" *ngIf="!resendHide">
            <div class="resendTxt">
              {{ "Resend code in" | translate }} {{ remaining }}
            </div>
            <!-- <button  class="resendBtn txtBtn"> {{"Resend" | translate}} </button> -->
          </div>
          <div [hidden]="!hideOtpInput">
            <p class="otp_labeltext">
              {{ "Please enter the validation number we’ve sent*" | translate }}
            </p>
            <app-phone-otp-input
              #otpInputs
              (otpChange)="patchOtp($event)"
            ></app-phone-otp-input>
          </div>
        </ng-container>
        <ng-container *ngIf="isPhoneVerified">
          <h5>Ph: {{ userData.contact_no }}</h5>
        </ng-container>
      </div>
    </div>
    <div class="form_detail_inner succes_wrapper" *ngIf="activeStep == 11">
      <h2 class="successTitle" *ngIf="!space.id">
        {{ "Thank you for listing your home!" | translate }}
      </h2>
      <h2 class="successTitle" *ngIf="space.id">
        {{ "Thank you for updating!" | translate }}
      </h2>
      <div class="earned-section">
        <img class="coin-1" alt="img" src="/assets/images/left-coin.png" />
        <img class="coin-2" src="/assets/images/right-coin.png" />
        <p>HolidaySwap</p>
      </div>
      <h4 class="step_title">
        {{ "Below are some important next steps that you can do!" | translate }}
      </h4>
      <div class="listing-rating-section">
        <div class="first-section">
          <div class="rating-info">
            <p class="heading">
              We’re starting your listing off with
              <span class="primary">3 stars</span>!
            </p>
            <p>
              To get a higher rating, place the finishing touches in your
              listing details!
            </p>
          </div>
          <img src="/assets/images/cup.png" />
        </div>
        <button (click)="viewListing()">Go to your listing</button>
      </div>
      <div
        class="succes_bottomBox flex-wrap text-center"
        *ngIf="activeStep > 10"
      >
        <div class="share_textBox-1">
          <img src="/assets/images/float-coins.png" alt="" />
          <p>
            {{
              "You can also share this amazing news with your friends and family."
                | translate
            }}
          </p>
        </div>
        <div class="booking-share">
          <img src="/assets/images/congrats-space.png" alt="congrats" />
          <h2>
            {{ "Share this home" | translate }}
            <br class="d-block d-lg-none" />
            {{ "with your friends" | translate }}
          </h2>

          <span class="booking-share-button">
            <app-social-share
              [float]="false"
              [homeId]="space.id"
              [ImgUrl]="'../../../../../../assets/images/icons/share_icon.png'"
              [shareMessage]="shareMessage + '\n' + space.deeplink_url"
              [deeplinkUrl]="space.deeplink_url"
            ></app-social-share>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="add_form_footer" *ngIf="activeStep <= 10">
    <button type="button" class="btn exitBtn" (click)="moveStep('prev')">
      {{ (activeStep == 1 ? "Exit" : "Back") | translate }}
    </button>
    <button
      type="button"
      class="btn nextBtn"
      [class.pe-none]="add_btn_block"
      (click)="moveStep('next')"
    >
      <span *ngIf="activeStep == 1">{{ "Nightly rate" | translate }}</span>
      <span *ngIf="activeStep == 2">{{ "Add address" | translate }}</span>
      <span *ngIf="activeStep == 3">{{ "Confirm address" | translate }}</span>
      <span *ngIf="activeStep == 4">{{ "Confirm address" | translate }}</span>
      <span *ngIf="activeStep == 5">{{ "Home Details" | translate }}</span>
      <span *ngIf="activeStep == 6">{{ "Next" | translate }}</span>
      <span *ngIf="activeStep == 7">{{ "Next" | translate }}</span>
      <span *ngIf="activeStep == 8">{{ "Next" | translate }}</span>
      <span *ngIf="activeStep == 9">{{ "Mobile number" | translate }}</span>
      <span *ngIf="activeStep == 10">{{ "Finish up!" | translate }}</span>
      <img src="assets/icons/next_arrow.svg" alt="next arrow" />
    </button>
  </div>
  <div class="imageUploadPopup" *ngIf="showImg">
    <div class="popupHeader">
      <button type="button" class="backBtn" (click)="showImg = false">
        <img alt="img" src="/assets/images/back_arrow.svg" />
      </button>
      <div class="action_btns">
        <button type="button" class="actionBtn" (click)="updateImg.click()">
          {{ (clickedImg.imgUrl ? "Switch" : "Upload") | translate }}
          <input
            type="file"
            name=""
            accept=".png, .jpg, .jpeg"
            #updateImg
            hidden
            (change)="picImageFile($event)"
          />
        </button>
        <button
          type="button"
          class="actionBtn"
          *ngIf="clickedImg.imgUrl && clickedImg.tagId != 555"
          (click)="removeImg = true"
        >
          {{ "Remove" | translate }}
        </button>
      </div>
    </div>
    <div class="imageDisplay">
      <img
        [src]="clickedImg.imgUrl ? clickedImg.imgUrl : placeholderImg"
        (click)="updateImg.click()"
        alt=""
      />
    </div>
    <div class="imgDisplay_footer">
      <button class="updateImgBtn popup_btn" (click)="updateImgBtn()">
        {{ "Update Image" | translate }}
      </button>
    </div>
  </div>

  <div class="remove_alretBackdrop" *ngIf="removeImg"></div>
  <div class="removeAlretPopup" *ngIf="removeImg">
    <button class="closeBtn" (click)="removeImg = false">
      <img alt="close icon" src="/assets/images/cancel_ic.svg" />
    </button>
    <img src="/assets/icons/remove_icon.svg" alt="" class="imgIcon" />
    <h5>{{ "Remove Image?" | translate }}</h5>
    <p>
      {{
        "Are you sure you want to remove Image? you can't undo this action"
          | translate
      }}
    </p>
    <div class="text-center">
      <button class="btn removeBtn" (click)="onRemove()">
        {{ "Remove Image" | translate }}
      </button>
      <br />
      <button class="txtBtn btn cancelBtn" (click)="removeImg = false">
        {{ "Cancel" | translate }}
      </button>
    </div>
  </div>

  <div class="image_croper" *ngIf="cropEnabled">
    <div class="popupHeader">
      <button type="button" class="backBtn" (click)="closeCroper()">
        <img alt="img" src="/assets/images/back_arrow.svg" />
      </button>
    </div>
    <p class="cropper_header">
      {{ "You can adjust the size of your image here!" | translate }}
    </p>
    <div class="cropDisplay">
      <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [containWithinAspectRatio]="false"
        [aspectRatio]="4 / 3"
        [onlyScaleDown]="true"
        [roundCropper]="false"
        [resizeToWidth]="550"
        [style.display]="showCropper ? null : 'none'"
        format="png"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (loadImageFailed)="loadImageFailed()"
      >
      </image-cropper>
    </div>
    <div class="imgDisplay_footer">
      <button class="cancel_btn btn" (click)="closeCroper()">
        {{ "Cancel" | translate }}
      </button>
      <button class="update_btn btn" (click)="uploadSpaceImage()">
        {{ "Update" | translate }}
      </button>
    </div>
  </div>

  <!-- loadign -->
  <div class="loading absolute" *ngIf="loading">
    <div class="spinner">
      <div class="logo">
        <svg
          width="1024px"
          height="1024px"
          viewBox="0 0 113 99"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <title>Group 5@1x</title>
          <g
            id="Symbols"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="Group-5">
              <polygon
                id="Fill-1"
                fill="#F29000"
                points="56.4671512 98.4529581 112.578221 26.2249349 71.8009884 0.453051163"
              ></polygon>
              <polygon
                id="Fill-2"
                fill="#FED900"
                points="40.9330674 0.000262790698 0.000788372093 26.1137744 56.453486 98.4626814 56.453486 98.4574256 56.4587419 98.4521698 41.0145326 0.0607046512"
              ></polygon>
              <polygon
                id="Fill-3"
                fill="#F29000"
                points="56.4576907 98.4529581 56.4734581 98.4398186 56.5365279 12.604493 56.4471791 12.6754465 40.9320163 0.00105116279"
              ></polygon>
              <polygon
                id="Fill-4"
                fill="#E84C1E"
                points="56.4482302 12.6749209 56.4666256 98.4471767 71.8004628 0.455153488"
              ></polygon>
            </g>
          </g>
        </svg>
      </div>
      <!--div class="bounce1"></div>
		<div class="bounce2"></div>
		<div class="bounce3"></div-->
    </div>
  </div>
</div>
