import { Component, OnInit, Input, HostListener } from '@angular/core';
import { SpaceService } from 'src/app/shared/services/space.service';

import clevertap from 'clevertap-web-sdk';
import { ClevertapService } from 'src/app/shared/services/clevertap.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-home-spaces',
  templateUrl: './home-spaces.component.html',
  styleUrls: ['./home-spaces.component.scss'],
})
export class HomeSpacesComponent implements OnInit {
  @Input() public user = {};
  public spaces = [];
  public loading = true;
  isLastPageHomeEdit = false;
  openAddHomeForm: boolean = false;
  popupbackdrop: boolean = false;
  editHomeData: any;
  spaceStatusMsg = '';
  user_id;
  items = [
    {
      name: 'home',
      link: '/',
    },
    {
      label: 'User homes',
    },
  ];
  isDesktopView: boolean = window.screen.width > 1024;
  @HostListener('window:resize', ['$event'])
  handleResize(event: KeyboardEvent) {
    this.isDesktopView = window.screen.width > 1024;
  }
  hasHistory: boolean = false;
  constructor(
    private spaceService: SpaceService,
    private clevertapService: ClevertapService,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute
  ) {
    this.hasHistory = this.router.navigated;
    this.route.params.subscribe((params) => {
      this.user_id = params.id;
    });
  }

  ngOnInit(): void {
    this.getSpaces();
    this.clevertapService.profileEvent('My profile - your homes clicked');
    this.spaceService.homeActionUpdateEvent.subscribe(() => {
      this.getSpaces();
      this.isLastPageHomeEdit = true;
      localStorage.removeItem('spaceDetails');
    });
    localStorage.removeItem('spaceDetails');
  }

  public getSpaces() {
    this.loading = true;
    this.spaceService
      .getUserSpaces(this.user_id)
      .subscribe(
        (data) => {
          this.spaces = data;

          if (this.spaces[0]?.is_space_percentage < 70)
            this.spaceStatusMsg = '';
          else this.spaceStatusMsg = '';

          this.loading = false;
        },
        (err) => {}
      )
      .add(() => {
        this.loading = false;
      });
  }

  public goToUserSpaceDetails(space) {
    //const navigationExtras = { queryParams: { id: space.id } };
    let commands;

    if (this.isDesktopView) {
      commands = ['/view-home/home-details-ibs/' + space.id];
    } else {
      commands = ['/view-home/home-details-ibs/' + space.id];
      // commands = [
      //   {
      //     outlets: {
      //       modal: ["modal", "spaces", "home-space-details", space.id],
      //     },
      //   },
      // ];
    }
    this.router.navigate(commands);
  }

  public editSpace(space) {
    // this.editHomeData = space;
    let spaceData = { ...space };
    if (spaceData) {
      this.editHomeData = spaceData;
    } else {
      this.editHomeData = undefined;
    }
    this.router.navigate([`/spaces/edit-home/${space.id}`]);
    // this.goToAddHome();
    // // const navigationExtras = { queryParams: { id: space.id } };
    // let commands;

    // if(this.isDesktopView) {
    //   commands = ['/spaces/add-home/'+space.id]
    // } else {

    //   commands = [
    //     {
    //       outlets: {
    //         modal: ["modal", "spaces", "add-home", space.id],
    //       },
    //     },
    //   ];

    // }

    // // localStorage.setItem('home-page-url', JSON.stringify(this.swap));
    // this.router.navigate(commands);
  }
  backdropToggle(status: boolean) {
    this.popupbackdrop = status;
  }
  closeAddFormPopup() {
    this.openAddHomeForm = false;
    this.backdropToggle(false);
  }

  public goToAddHome() {
    this.openAddHomeForm = true;
    this.backdropToggle(true);

    // if (this.spaces.length == 0) {
    //   //this.router.navigate([{ outlets: { modal: ["modal", "spaces", 'form'] } }]);
    //   this.router.navigate(["/add-space-intro"]);
    // }
    // else {
    //   //	this.router.navigate(["/add-space-intro"]);
    //   //	this.router.navigate([{ outlets: { modal: ["modal", "spaces", this.spaces[0].id] } }]);
    //   // const navigationExtras = { queryParams: { id: this.spaces[0].id } };
    //   let commands;

    //   if(this.isDesktopView) {
    //     commands = ['/spaces/add-home']
    //   } else {

    //     commands = [
    //       {
    //         outlets: {
    //           modal: ["modal", "spaces", "add-home"],
    //         },
    //       },
    //     ];

    //   }

    //   this.router.navigate(commands);
    // }
  }

  public pop() {
    if (this.isLastPageHomeEdit) {
      this.router.navigate(['/dashboard']);
      // this.location.back();
      // this.location.back();
    } else {
      if (this.hasHistory) {
        this.location.back();
      } else {
        this.router.navigateByUrl('/');
      }
    }
  }
}
