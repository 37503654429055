import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-star-reviews',
  templateUrl: './star-reviews.component.html',
  styleUrls: ['./star-reviews.component.scss']
})
export class StarReviewsComponent implements OnInit {
  public selectedLanguage: string = '';
   @Input('star') star:number = 0; // ngmodel or sttatic val
   @Input('control') control: FormControl;


   @Input ('colorClass') colorClass;

   @Input('clickable') clickable: boolean = false;
   @Input('showLabel') showLabel: boolean = false;

   @Input('size') iconSize: 'sm' | 'md' | 'lg' | 'xl'= 'md';

   @Input() inactive: boolean = false;
   @Output() onSelection = new EventEmitter();


   starList: any = [
     {
       value:1,
       label:'terrible'
     },
     {
       value:2,
       label:'bad'
     },
     {
      value:3,
      label:'okay'
    },
    {
      value:4,
      label:'good'
    },
    {
      value:5,
      label:'great'
    }
   ];

   clickIncreament: number = 0;

  constructor(
    private languageService: LanguageService,
  ) {
    this.selectedLanguage = this.languageService.GetLanguage();
    this.languageService.languageUpdates.subscribe((lang)=>this.selectedLanguage=lang);
  }

  ngOnInit(): void {
    if((this.control && this.control.value == 1) || (this.star && this.star == 1)){
      this.clickIncreament = 1;
    }
  }

 selectedStar(star) {

   if(star > 1) this.clickIncreament = 0;
   if(this.control) {
    this.control.setValue(star);
  } else {
    this.star = star;
  }

  if((this.control && this.control.value == 1) || this.star == 1) {
    this.clickIncreament++;
    if(this.clickIncreament==2) this.resetStar();
 }

 this.onSelection.emit(star);
 }


 resetStar() {
  // if(this.control.value==1 || this.star==1) {
    if(this.control) {
      this.control.setValue(0)
    } else {
      this.star = 0;
    }
  // }
  this.clickIncreament = 0;
}
}
