import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ClevertapService } from 'src/app/shared/services/clevertap.service';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-adventure-selection',
  templateUrl: './adventure-selection.component.html',
  styleUrls: ['./adventure-selection.component.scss']
})
export class AdventureSelectionComponent implements OnInit {
  public loading = false;
  public featuredLocations : any = [];
  public selectedLanguage = 'en';
  public selectedRegions: any = [];
  constructor(
    private router : Router,
    private route : ActivatedRoute,
    private userService : UserService,
    private snackbarService : SnackbarService,
    private clevertapService: ClevertapService,
		private googleAnalyticsService: GoogleAnalyticsService,
    public translate: TranslateService
  ) {
    this.route.data.subscribe((res:any) => {
      this.featuredLocations = res.trendingList;
      this.featuredLocations.map((location) => {
       Object.assign(location, {checked : false})
      })
    })
  }

  ngOnInit(): void {
    // this
    // .googleAnalyticsService
    // .eventEmitter("SU_Regions", "SignUp", "SU_App_Regions");

    // Fire the AppsFlyer event
		(window as any).AF('pba', 'event', {eventType: 'EVENT', eventName: 'register_adventure_screen', eventValue: {
			"description": "Adventure selection viewed"
		}});
  }

  searchResults(event, location) {


    let name = location.name.split(' ').join('_');
    this
			.googleAnalyticsService
			.eventEmitter("SU_RegionSelect", "SignUp", "SU_Chose_"+name);


  }

  handleRegions() {
    this.selectedRegions = [];
    this
			.googleAnalyticsService
			.eventEmitter("SU_RegionsComplete", "SignUp", "SU_RegionsSuccess");

    // Fire the AppsFlyer event
    (window as any).AF('pba', 'event', {eventType: 'EVENT', eventName: 'register_adventure_selected', eventValue: {
      "description": "Adventure selection - SUCCESS"
    }});

    this.featuredLocations.map((location) => {
      if(location.checked == true) {
        this.selectedRegions.push(location.id)
      }
    })



    if(this.selectedRegions.length > 0) {

      this.userService.updateKeenCity(this.selectedRegions).subscribe((res) => {
        this.snackbarService.show({
          message: this.translate.instant("Selected regions updated"),
          type: "success",
          config: {
            class : 'centered'
          }
        });

      }, (err) => {

      } )
      this.router.navigate(["/auth/congrats-signup"], {queryParams : this.route.snapshot.queryParams, queryParamsHandling :'merge'});
    } else {
      this.router.navigate(["/auth/congrats-signup"], {queryParams : this.route.snapshot.queryParams, queryParamsHandling :'merge'});
    }




    // else{
		// 	this
		// 	.googleAnalyticsService
		// 	.eventEmitter("congrats_signup_web", "Clicks", "/congrats-signup");
		// }

  }

  handleSkip() {
    // Fire the AppsFlyer event
    (window as any).AF('pba', 'event', {eventType: 'EVENT', eventName: 'register_adventure_skiped', eventValue: {
      "description": "Adventure selection - SKIP"
    }});

    this.router.navigate(["/auth/congrats-signup"], {queryParams : this.route.snapshot.queryParams, queryParamsHandling :'merge'});
  }

}
