<div class="explore-hero-section" id="banner-hero-section">
  <img
    class="banner-image"
    [src]="
      'assets/images/banner/Home-Hero-Banner-' +
      (region.params ? region.label.toLowerCase() : 'default') +
      '.png'
    "
    (error)="imgError($event)"
    alt="home-hero-banner"
  />

  <div class="explore-hero-section-container">
    <div class="explore-hero-section-content">
      <h1 class="explore-hero-title" *ngIf="!region.params">
        Discover your
        <br />
        next holiday stay
      </h1>
      <h1 class="explore-hero-title" *ngIf="region.params">
        Welcome to {{ region.label }}
      </h1>
      <app-search-homes *ngIf="!region.params"></app-search-homes>
      <div
        class="mobile-search-home"
        (click)="goToMobileSearch()"
        *ngIf="!region.params"
      >
        <p>Search for places to stay</p>
        <img
          class="search-icon"
          src="assets/images/icons/Search_primary.svg"
          alt="search-icon"
        />
      </div>
    </div>
  </div>
</div>
