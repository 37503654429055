import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WelcomeComponent } from 'src/app/shared/components/welcome/welcome.component';
import { WelcomePopupComponent } from './pages/welcome-popup/welcome-popup.component';

const routes: Routes = [
  {path: "", component: WelcomeComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WelcomePopupRoutingModule { }
