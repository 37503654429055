<div class="explore-hero-section">
  <img
    class="banner-image"
    src="../../../../../assets/images/How-It-Work-Banner.jpg"
    alt="home-hero-banner"
  />

  <div class="explore-hero-section-container">
    <div class="explore-hero-section-content">
      <h1 class="explore-hero-title">Start your journey with us</h1>
      <h5 style="font-weight: 500" class="mb-5">
        Register your account now and get full access with 1,000 Holiday Swap
        points as a gift from us! Book or swap homes around the world and stay
        for less.
      </h5>
    </div>
  </div>
</div>
<section class="mapSearch mapSearchCustom">
  <img
    class="mapSearchImage"
    src="/assets/images/Explore-The-Best.png"
    alt="left background image"
  />
  <div class="mapSearchContainer">
    <h2 class="mapSearchHeader">Explore <span>the best!</span></h2>

    <a routerLink="/search-homes" class="search-the-globe-btn"
      >Search the globe...</a
    >
  </div>
</section>

<div class="contentSwitch">
  <img src="../../../../../assets/images/Host-Your-home.jpg" />
  <div class="contentSwitchContent">
    <h2 class="contentSwitchContentTitle">Host <span>homes</span></h2>
    <p class="">
      Whether you rent or own, list your home, become a host and earn thousands
      of HS Points as you share your home with like-minded travellers. Then, use
      the points earned to book homes around the world for less!
    </p>
  </div>
  <div class="circle-divider">
    <svg
      id="host-your-home"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 104 91"
      shape-rendering="geometricPrecision"
      text-rendering="geometricPrecision"
    >
      <path
        id="host-your-home-s-path1"
        d="M77.7666,33.4794L50.4461,11.9743c-3.3469-2.63241-8.2034-2.63241-11.5504,0L11.5798,33.4749c-.9589.7541-1.5053,1.8685-1.5053,3.0603v40.4623c0,2.1731,1.8276,3.9611,4.0708,3.9611h18.3166c2.2432,0,4.0709-1.7874,4.0709-3.9611v-25.6093h16.2803v25.6093c0,2.1731,1.8276,3.9611,4.0709,3.9611h18.3165c2.2433,0,4.0709-1.7874,4.0709-3.9611v-40.4623c-.0036-1.1918-.5501-2.3063-1.5054-3.0568l.0006.001Zm-2.569,43.539h-18.3166v-25.6484c0-2.173-1.8276-3.9223-4.0708-3.9223h-16.2804c-2.2432,0-4.0709,1.7494-4.0709,3.9223v25.6484h-18.3134v-40.4833L41.466,15.0345c1.8562-1.4653,4.5591-1.4618,6.4159,0L75.2024,36.5351l-.0006,40.4833h-.0042Z"
        fill="#23262f"
      ></path>
      <circle
        id="host-your-home-s-circle1"
        r="18"
        transform="translate(75.8594 32.7384)"
        fill="#ff6200"
      ></circle>
      <g
        id="host-your-home-u-clip-group"
        clip-path="url(#host-your-home-u-clipping-paths)"
      >
        <g
          id="host-your-home-s-path2_to"
          transform="translate(75.859398,31.740899)"
        >
          <path
            id="host-your-home-s-path2"
            d="M72.5694,27.4484l2.29-2.3v9.59c0,.2652.1053.5196.2929.7071.1875.1876.4419.2929.7071.2929s.5195-.1053.7071-.2929c.1875-.1875.2929-.4419.2929-.7071v-9.59l2.29,2.3c.0929.0938.2035.1681.3254.2189s.2526.0769.3846.0769.2627-.0261.3846-.0769c.1218-.0508.2324-.1251.3254-.2189.0937-.0929.1681-.2035.2189-.3254.0507-.1219.0769-.2526.0769-.3846s-.0262-.2627-.0769-.3846c-.0508-.1218-.1252-.2324-.2189-.3254l-4-4c-.0951-.091-.2073-.1624-.33-.21-.2435-.1-.5166-.1-.76,0-.1228.0476-.2349.119-.33.21l-4,4c-.0933.0933-.1672.204-.2177.3258s-.0764.2524-.0764.3842c0,.1319.026.2624.0764.3843s.1244.2325.2177.3257c.0932.0933.2039.1672.3257.2177s.2524.0764.3843.0764.2624-.026.3842-.0764.2325-.1244.3258-.2177Z"
            transform="translate(-75.859398,-31.740899)"
            fill="#fff"
          ></path>
        </g>
        <path
          id="host-your-home-u-arrow"
          d="M84.8594,31.7384c-.2652,0-.5196.1054-.7071.2929-.1876.1876-.2929.4419-.2929.7071v6c0,.2652-.1054.5196-.2929.7071-.1876.1876-.4419.2929-.7071.2929h-14c-.2652,0-.5196-.1053-.7071-.2929-.1876-.1875-.2929-.4419-.2929-.7071v-6c0-.2652-.1054-.5195-.2929-.7071-.1876-.1875-.4419-.2929-.7071-.2929s-.5196.1054-.7071.2929c-.1876.1876-.2929.4419-.2929.7071v6c0,.7957.316,1.5587.8787,2.1213.5626.5627,1.3256.8787,2.1213.8787h14c.7956,0,1.5587-.316,2.1213-.8787.5626-.5626.8787-1.3256.8787-2.1213v-6c0-.2652-.1054-.5195-.2929-.7071-.1876-.1875-.4419-.2929-.7071-.2929Z"
          fill="#fff"
        ></path>
        <clipPath id="host-your-home-u-clipping-paths">
          <circle
            id="host-your-home-u-copy-of-circle"
            r="18"
            transform="translate(75.8594 32.7384)"
            fill="#ff6200"
          ></circle>
        </clipPath>
      </g>
    </svg>
  </div>
</div>

<div class="contentSwitch reverse">
  <img src="../../../../../assets/images/Earn-Tokens.jpg" />
  <div class="contentSwitchContent">
    <h2 class="contentSwitchContentTitle">Earn <span>points</span></h2>
    <p class="">
      Earn HS Points by hosting or unlocking achievements in-app, and book homes
      around the world for LESS!
    </p>
  </div>
  <div class="circle-divider">
    <svg
      id="swap-your-home-new"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 111 114"
      shape-rendering="geometricPrecision"
      text-rendering="geometricPrecision"
    >
      <path
        _ngcontent-mxb-c257=""
        id="swap-your-home-new-s-path1"
        d="M84.3077,45.4939L56.0132,23.9889c-3.4663-2.6325-8.4959-2.6325-11.9622,0L15.7613,45.4894c-.9931.7541-1.559,1.8686-1.559,3.0603v40.4623c0,2.1731,1.8927,3.9611,4.216,3.9611h18.9695c2.3232,0,4.216-1.7874,4.216-3.9611v-25.6093h16.8607v25.6093c0,2.1731,1.8928,3.9611,4.216,3.9611h18.9696c2.3232,0,4.216-1.7874,4.216-3.9611v-40.4623c-.0038-1.1918-.5697-2.3063-1.5591-3.0568l.0007.001ZM81.647,89.0329h-18.9695v-25.6483c0-2.1731-1.8928-3.9223-4.216-3.9223h-16.8608c-2.3232,0-4.2159,1.7494-4.2159,3.9223v25.6483h-18.9664v-40.4833L46.7129,27.049c1.9224-1.4653,4.7216-1.4618,6.6446,0L81.652,48.5496l-.0006,40.4833h-.0044Z"
        fill="#23262f"
      ></path>
      <g
        _ngcontent-mxb-c257=""
        id="swap-your-home-new-s-g1"
        transform="translate(.139869 0)"
      >
        <ellipse
          _ngcontent-mxb-c257=""
          id="swap-your-home-new-s-ellipse1"
          rx="19"
          ry="19"
          transform="translate(78.3676 38.7529)"
          fill="#ff6200"
        ></ellipse>
        <g
          _ngcontent-mxb-c257=""
          id="swap-your-home-new-u-mask-group"
          mask="url(#swap-your-home-new-u-masks)"
        >
          <g
            _ngcontent-mxb-c257=""
            id="swap-your-home-new-s-g2"
            transform="translate(.67865-1.043651)"
          >
            <g
              _ngcontent-mxb-c257=""
              id="swap-your-home-new-s-g3_to"
              style="
                offset-path: path(
                  'M77.52845,33.5339C70.554743,32.267508,54.681481,38.315,49.215681,50.840305'
                );
                offset-rotate: 0deg;
              "
            >
              <g
                _ngcontent-mxb-c257=""
                id="swap-your-home-new-s-g3_tr"
                transform="rotate(0)"
              >
                <g
                  _ngcontent-mxb-c257=""
                  id="swap-your-home-new-s-g3"
                  transform="translate(-77.52845,-33.5339)"
                >
                  <path
                    _ngcontent-mxb-c257=""
                    id="swap-your-home-new-s-path2"
                    d="M85.3326,37.0508c-.4297-1.115-1.1601-2.1119-2.123-2.8976-.9628-.7858-2.1268-1.3348-3.3833-1.5958s-2.5645-.2256-3.802.1031-2.3641.9397-3.2748,1.7763"
                    fill="none"
                    stroke="#fff"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    _ngcontent-mxb-c257=""
                    id="swap-your-home-new-s-path3"
                    d="M73.3491,28.7528L69.7243,34.815l6.2783,3.5"
                    fill="none"
                    stroke="#fff"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </g>
              </g>
            </g>
            <g
              _ngcontent-mxb-c257=""
              id="swap-your-home-new-u-copy-of-group_to"
              style="
                offset-path: path(
                  'M102.377328,50.893601C103.889928,45.051724,96.201278,33.529998,77.53,33.53'
                );
                offset-rotate: 0deg;
              "
            >
              <g
                _ngcontent-mxb-c257=""
                id="swap-your-home-new-u-copy-of-group_tr"
                transform="rotate(70.295791)"
              >
                <g
                  _ngcontent-mxb-c257=""
                  id="swap-your-home-new-u-copy-of-group"
                  transform="translate(-77.52845,-33.5339)"
                >
                  <path
                    _ngcontent-mxb-c257=""
                    id="swap-your-home-new-s-path4"
                    d="M85.3326,37.0508c-.4297-1.115-1.1601-2.1119-2.123-2.8976-.9628-.7858-2.1268-1.3348-3.3833-1.5958s-2.5645-.2256-3.802.1031-2.3641.9397-3.2748,1.7763"
                    fill="none"
                    stroke="#fff"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    _ngcontent-mxb-c257=""
                    id="swap-your-home-new-s-path5"
                    d="M73.3491,28.7528L69.7243,34.815l6.2783,3.5"
                    fill="none"
                    stroke="#fff"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </g>
              </g>
            </g>
            <g
              _ngcontent-mxb-c257=""
              id="swap-your-home-new-s-g4_to"
              style="
                offset-path: path(
                  'M77.7813,46.112502C97.455067,43.54642,109.471109,28.7528,110.289627,20.796551'
                );
                offset-rotate: 0deg;
              "
            >
              <g
                _ngcontent-mxb-c257=""
                id="swap-your-home-new-s-g4_tr"
                transform="rotate(0)"
              >
                <g
                  _ngcontent-mxb-c257=""
                  id="swap-your-home-new-s-g4"
                  transform="translate(-77.7813,-46.112501)"
                >
                  <path
                    _ngcontent-mxb-c257=""
                    id="swap-your-home-new-u-copy-of-path"
                    d="M82.4921,45.3327c-.9107.8366-2.0373,1.4477-3.2748,1.7763s-2.5455.3642-3.802.1031-2.4204-.81-3.3833-1.5957c-.9629-.7858-1.6933-1.7827-2.123-2.8977"
                    fill="none"
                    stroke="#fff"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    _ngcontent-mxb-c257=""
                    id="swap-your-home-new-s-path6"
                    d="M82.275,50.8403l3.3786-6.1933-6.4141-3.2623"
                    fill="none"
                    stroke="#fff"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </g>
              </g>
            </g>
            <g
              _ngcontent-mxb-c257=""
              id="swap-your-home-new-u-copy-of-group2_to"
              style="
                offset-path: path(
                  'M55.7813,16.112502C54.962781,29.384796,62.39255,47.453324,77.78,46.11'
                );
                offset-rotate: 0deg;
              "
            >
              <g
                _ngcontent-mxb-c257=""
                id="swap-your-home-new-u-copy-of-group2_tr"
                transform="rotate(111.002389)"
              >
                <g
                  _ngcontent-mxb-c257=""
                  id="swap-your-home-new-u-copy-of-group2"
                  transform="translate(-77.7813,-46.112501)"
                >
                  <path
                    _ngcontent-mxb-c257=""
                    id="swap-your-home-new-u-copy-of-path2"
                    d="M82.4921,45.3327c-.9107.8366-2.0373,1.4477-3.2748,1.7763s-2.5455.3642-3.802.1031-2.4204-.81-3.3833-1.5957c-.9629-.7858-1.6933-1.7827-2.123-2.8977"
                    fill="none"
                    stroke="#fff"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    _ngcontent-mxb-c257=""
                    id="swap-your-home-new-s-path7"
                    d="M82.275,50.8403l3.3786-6.1933-6.4141-3.2623"
                    fill="none"
                    stroke="#fff"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </g>
              </g>
            </g>
          </g>
          <mask
            _ngcontent-mxb-c257=""
            id="swap-your-home-new-u-masks"
            mask-type="alpha"
          >
            <ellipse
              _ngcontent-mxb-c257=""
              id="swap-your-home-new-u-copy-of-ellipse"
              rx="19"
              ry="19"
              transform="translate(78.3676 38.7529)"
              fill="#ff6200"
            ></ellipse>
          </mask>
        </g>
      </g>
    </svg>
  </div>
</div>

<div class="contentSwitch">
  <img src="../../../../../assets/images/Travel-World.jpg" />
  <div class="contentSwitchContent">
    <h2 class="contentSwitchContentTitle">Travel <span>the world</span></h2>
    <p class="">
      Avoid expensive accommodation costs whilst travelling and travel the world
      for less!
    </p>
  </div>
  <div class="circle-divider">
    <svg
      id="travel-the-world"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 104 91"
      shape-rendering="geometricPrecision"
      text-rendering="geometricPrecision"
    >
      <path
        id="travel-the-world-s-path1"
        d="M44.5378,79.7202c17.489,0,31.6666-14.1777,31.6666-31.6667s-14.1776-31.6667-31.6666-31.6667-31.6667,14.1777-31.6667,31.6667s14.1776,31.6667,31.6667,31.6667Z"
        fill="none"
        stroke="#23262f"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path
        id="travel-the-world-s-path2"
        d="M12.8711,48.0533h63.3333"
        fill="none"
        stroke="#23262f"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path
        id="travel-the-world-s-path3"
        d="M44.5378,16.3868c7.9207,8.6715,12.422,19.9248,12.6666,31.6667-.2446,11.7419-4.7459,22.9952-12.6666,31.6667-7.9208-8.6715-12.4221-19.9248-12.6667-31.6667.2446-11.7419,4.7459-22.9952,12.6667-31.6667v0Z"
        fill="none"
        stroke="#23262f"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <circle
        id="travel-the-world-s-circle1"
        r="18"
        transform="translate(74.8711 28.3868)"
        fill="#ff6200"
      ></circle>
      <g
        id="travel-the-world-u-clip-group"
        clip-path="url(#travel-the-world-u-clipping-paths)"
      >
        <g
          id="travel-the-world-u-copy-of-group-2_to"
          transform="translate(73.870291,27.3868)"
        >
          <g
            id="travel-the-world-u-copy-of-group-2"
            transform="translate(-74.871002,-28.239928)"
            clip-path="url(#travel-the-world-u-clip0_1211_2444)"
          >
            <g id="travel-the-world-s-g1">
              <path
                id="travel-the-world-s-path4"
                d="M83.8189,22.2384l-2.716,12.4532c-.2049.8789-.7393,1.0976-1.4987.6835l-4.1384-2.9648-1.9969,1.8672c-.2209.2148-.4058.3945-.8317.3945l.2974-4.0976l7.6701-6.7383c.3334-.2891-.0724-.4492-.5184-.1602l-9.4821,5.8047-4.0821-1.2422c-.888-.2695-.9041-.8633.1848-1.2773l15.9669-5.9805c.7393-.2695,1.3862.1602,1.1451,1.2578Z"
                fill="#fff"
              ></path>
            </g>
            <clipPath id="travel-the-world-u-clip0_1211_2444">
              <rect
                id="travel-the-world-s-rect1"
                width="18"
                height="20"
                rx="0"
                ry="0"
                transform="translate(65.8711 18.3868)"
                fill="#fff"
              ></rect>
            </clipPath>
          </g>
        </g>
        <g
          id="travel-the-world-u-01_to"
          transform="translate(47.871909,46.3868)"
        >
          <g
            id="travel-the-world-u-01"
            transform="translate(-74.871002,-28.239928)"
            clip-path="url(#travel-the-world-u-clip0_1211_24442)"
          >
            <g id="travel-the-world-s-g2">
              <path
                id="travel-the-world-s-path5"
                d="M83.8189,22.2384l-2.716,12.4532c-.2049.8789-.7393,1.0976-1.4987.6835l-4.1384-2.9648-1.9969,1.8672c-.2209.2148-.4058.3945-.8317.3945l.2974-4.0976l7.6701-6.7383c.3334-.2891-.0724-.4492-.5184-.1602l-9.4821,5.8047-4.0821-1.2422c-.888-.2695-.9041-.8633.1848-1.2773l15.9669-5.9805c.7393-.2695,1.3862.1602,1.1451,1.2578Z"
                fill="#fff"
              ></path>
            </g>
            <clipPath id="travel-the-world-u-clip0_1211_24442">
              <rect
                id="travel-the-world-s-rect2"
                width="18"
                height="20"
                rx="0"
                ry="0"
                transform="translate(65.8711 18.3868)"
                fill="#fff"
              ></rect>
            </clipPath>
          </g>
        </g>
        <clipPath id="travel-the-world-u-clipping-paths">
          <circle
            id="travel-the-world-u-copy-of-circle"
            r="18"
            transform="translate(74.8711 28.3868)"
            fill="#ff6200"
          ></circle>
        </clipPath>
      </g>
    </svg>
  </div>
</div>

<section class="weAreLookingFor">
  <h2 class="contentSwitchContentTitle text-left">
    Spend your <span>next vacation </span> in these <span>amazing places!</span>
    <img
      class="coin-icon"
      src="../../../../../assets/icons/HolidaySwap-Coins.svg"
    />
  </h2>
  <div>
    <app-homes-card-component
      *ngIf="featureSpace.length > 0"
      [featuredSpaces]="featureSpace"
    ></app-homes-card-component>
  </div>
</section>

<div class="contentSwitch reverse">
  <img src="../../../../../assets/images/Power-Of-Prmium.jpg" />
  <div class="contentSwitchContent">
    <h2 class="contentSwitchContentTitle">
      Ready to unleash the <span>power of premium</span>
    </h2>
    <p class="">
      Subscribe to Holiday Swap premium and never pay a booking fee again!
      You'll save thousands when travelling with unlimited home swaps for only
      $10 per month.
    </p>
    <a
      class="premiumButton"
      routerLink="/auth/intro"
      [queryParams]="{ redirect_to: '/settings/subscriptions' }"
      >Go Premium</a
    >
  </div>
</div>

<div class="contentSwitch appContent">
  <img src="../../../../../assets/images/Download-App.png" />
  <div class="contentSwitchContent">
    <h2 class="contentSwitchContentTitle">
      Download the <span>app now!</span>
    </h2>
    <p class="">
      Get the Holiday Swap app and join millions of happy traveller's!
    </p>
    <div
      class="d-flex justify-content-center justify-content-lg-start downloadAppWithQrLinks"
    >
      <a
        href="https://apps.apple.com/gb/app/holiday-swa-p-home-exchange/id1313713150"
        target="_blank"
        rel="noreferrer"
        class="downloadAppWithQrLink"
      >
        <img
          src="../../../../../assets/images/Apple-Store-New.png"
          alt="Apple store"
          class="downloadAppWithQrLinksApple"
        />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.holidayswap&hl=en&gl=US"
        style="margin-left: 8px"
        target="_blank"
        rel="noreferrer"
        class="downloadAppWithQrLink"
      >
        <img
          src="../../../../../assets/images/Play-Store-New.png"
          alt="Play store"
          class="downloadAppWithQrLinksApple"
        />
      </a>
    </div>
  </div>
</div>

<div class="contentSwitch">
  <img
    class="get-in-touch-hero"
    src="../../../../../assets/images/Get-in-Touch.jpg"
  />
  <div class="contentSwitchContent">
    <div class="get-in-touch-wrapper">
      <h2 class="contentSwitchContentTitle">Get <span>In Touch </span></h2>

      <form [formGroup]="contactForm" (ngSubmit)="onSubmitContact()">
        <div class="row">
          <div class="col-md-6">
            <input
              class="w-100 form-input"
              placeholder="First Name"
              formControlName="first_name"
            />
            <div class="input-error-wrapper">
              <div
                class="input-error"
                *ngIf="contactForm.controls?.['first_name']?.touched && contactForm.controls?.['first_name']?.errors?.['required']"
              >
                First Name is required.
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <input
              class="w-100 form-input"
              placeholder="Last Name"
              formControlName="last_name"
            />
            <div class="input-error-wrapper">
              <div
                class="input-error"
                *ngIf="contactForm.controls?.['last_name']?.touched && contactForm.controls?.['last_name']?.errors?.['required']"
              >
                Last Name is required.
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <input
              class="w-100 form-input"
              placeholder="Email"
              formControlName="email"
            />
            <div class="input-error-wrapper">
              <div
                class="input-error"
                *ngIf="contactForm.controls?.['email']?.touched && contactForm.controls?.['email']?.errors?.['required']"
              >
                Email is required.
              </div>
              <div
                class="input-error"
                *ngIf="contactForm.controls?.['email']?.touched && contactForm.controls?.['email']?.errors?.['email']"
              >
                Invalid email address
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <input
              class="w-100 form-input"
              placeholder="Phone number"
              formControlName="phone"
              type="tel"
            />
            <div class="input-error-wrapper">
              <div
                class="input-error"
                *ngIf="contactForm.controls?.['phone']?.touched && contactForm.controls?.['phone']?.errors?.['required']"
              >
                Phone is required.
              </div>
              <div
                class="input-error"
                *ngIf="contactForm.controls?.['phone']?.touched && contactForm.controls?.['phone']?.errors?.['pattern']"
              >
                Phone nubmer is valid.
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <textarea
              class="w-100 form-input"
              rows="5"
              placeholder="Message"
              formControlName="message"
            ></textarea>
            <div class="input-error-wrapper">
              <div
                class="input-error"
                *ngIf="contactForm.controls?.['message']?.touched && contactForm.controls?.['message']?.errors?.['required']"
              >
                Message is required.
              </div>
              <div
                class="input-error"
                *ngIf="contactForm.controls?.['message']?.touched && contactForm.controls?.['message']?.errors?.['minlength']"
              >
                Please enter at least 10 character
              </div>
            </div>
          </div>
          <div class="col-md-12 d-flex justify-content-end form-action">
            <button type="submit" class="primary-button">Get In Touch</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
